import { BaseAPI } from '@apis/_api';
import { getBrand } from '@helpers/brand.utils';
import {
  CreateOrderResponse,
  Order,
  OrderRequest,
  UnSubmittedOrderSearchRequest,
  UnsubmittedOrderSearchResponse,
} from '@apis/models/orders.api.model';
import { translateOffice } from '@apis/office.api';
import { translateAgent } from '@apis/agent.api';

export const Errors = {
  '30000': 'Initiating office required',
  '30001': 'Initiating agent required',
  '30002': 'Required to select who the agent represents',
  '30003': 'Buyer first name required',
  '30053': 'Buyer last name required',
  '30004': 'Seller first name required',
  '30054': 'Seller last name required',
  '30005': 'CoBuyer first name required',
  '30055': 'CoBuyer last name required',
  '30006': 'CoSeller first name required',
  '30056': 'CoSeller last name required',
  '30010': 'Buyer name is required when agent represents buyer or both',
  '30011': 'Seller name is required when agent represents seller or both',
  '30012': 'Buyer name is required when closing date entered',
  '30013': 'Closing date is required when buyer name entered',
  '30014': 'Cooperating agent required.',
  '30015':
    'Cooperating agent cannot be same as initiating agent unless agent represents both buyer and seller. Please update your selection for Agent Information.',
  '30016': 'Closing date is required',
  '30017':
    'Please select a closing date that is within 90 days in the past to 365 days in the future',
  '30018': 'Something went wrong. Please reselect the plan to continue.',
  '30019': 'Cooperating office required',
  '30020': 'Closing office required',
};

export const GetUserError = (err) => {
  if (Errors[err.code]) {
    return Errors[err.code];
  }
  return err.message;
};
export class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Brand-Name': getBrand(),
        },
      },
      true,
      suppressErrors,
    );
  }

  public async create(order: OrderRequest): Promise<CreateOrderResponse | any> {
    return this.axios
      .post<any, CreateOrderResponse>(`${this.getHost()}/orders/`, order)
      .catch((err) => {
        if (err?.response?.data) {
          return err.response.data;
        }
        throw err;
      });
  }

  public async update(id: string, order: OrderRequest): Promise<void | any> {
    return this.axios.put<any, void>(`${this.getHost()}/orders/${id}`, order).catch((err) => {
      if (err?.response?.data) {
        return err.response.data;
      }
      throw err;
    });
  }

  public async createContract(order: OrderRequest, orderID: string): Promise<any> {
    return this.axios.post<any, any>(`${this.getHost()}/orders/${orderID}/submit`, order);
  }

  public async get(id: string): Promise<Order> {
    return this.axios
      .get<any, Order>(`${this.getHost()}/orders/${id}`)
      .then((order) => translateOrder(order));
  }

  public async searchAbandonedOrders(
    body: UnSubmittedOrderSearchRequest,
  ): Promise<UnsubmittedOrderSearchResponse> {
    return this.axios
      .post<
        any,
        UnsubmittedOrderSearchResponse
      >(`${this.getHost()}/orders/search/unsubmitted-orders`, body)
      .then((res) => {
        res.results = res.results.map(translateOrder);
        return res;
      });
  }
}

export function translateOrder(order: Order): Order {
  if (order) {
    order = new Order(order);

    if (order.details?.officeAgents?.initiating?.office) {
      order.details.officeAgents.initiating.office = translateOffice(
        order.details.officeAgents.initiating.office,
      );
    }
    if (order.details?.officeAgents?.cooperating?.office) {
      order.details.officeAgents.cooperating.office = translateOffice(
        order.details.officeAgents.cooperating.office,
      );
    }
    if (order.details?.officeAgents?.closing?.office) {
      order.details.officeAgents.closing.office = translateOffice(
        order.details.officeAgents.closing.office,
      );
    }
    if (order.details?.officeAgents?.initiating?.agent) {
      order.details.officeAgents.initiating.agent = translateAgent(
        order.details.officeAgents.initiating.agent,
      );
    }
    if (order.details?.officeAgents?.cooperating?.agent) {
      order.details.officeAgents.cooperating.agent = translateAgent(
        order.details.officeAgents.cooperating.agent,
      );
    }
    if (order.details?.officeAgents?.closing?.agent) {
      order.details.officeAgents.closing.agent = translateAgent(
        order.details.officeAgents.closing.agent,
      );
    }
  }
  return order;
}

const OrdersApi = new API();
export const OrdersApiSuppressErrors = new API(true);
export default OrdersApi;
