import React from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';

export interface ModalConfirmCoverageProps extends ModalProps {
  onAccept?: () => void;
  onDecline?: () => void;
}

const ModalConfirmCoverage: React.FC<ModalConfirmCoverageProps> = (props) => {
  const onAccept = () => {
    props.onAccept?.();
  };

  const onDecline = () => {
    props.onDecline?.();
  };

  const actions = [
    <Button
      key="submit"
      id="coverage-modal--btn-accept"
      label="Accept"
      width="full"
      labelAlign="center"
      size="medium"
      onClick={onAccept}
    />,
    <Button
      key="cancel"
      id="coverage-modal--btn-cancel"
      variant="ghost"
      label="Decline"
      width="full"
      labelAlign="center"
      size="medium"
      onClick={onDecline}
    />,
  ];

  return (
    <Modal
      id={props.id}
      heading={props.heading}
      canClose={true}
      actions={actions}
      {...props}
      onClose={onDecline}
    >
      {props.children}
    </Modal>
  );
};

export default ModalConfirmCoverage;
