import React from 'react';
import { Button, ContextMenu, IconNavArrowDown, Text } from '@ftdr/blueprint-components-react';
import { PLANS_AND_PRICES_LABELS } from '@templates/misc/PlansAndPricesTemplate';
import { DwellingType } from '@apis/models';
import { removeTags, toCurrency, toPercentage } from '@helpers/utils';
import { isMobileView } from '@utils';
import { DiscountLabel } from '@components/drawer/subcomponents/MakePaymentStripe';

export interface QuoteSummaryItem {
  /** the pvid or the cvgId */
  id: string;
  name: string;
  price: number;
}

export interface QuoteSummarySelection {
  plan: QuoteSummaryItem;
  coverages: QuoteSummaryItem[];
  tax: {
    price: number;
    rate: number;
    taxableAmount: number;
  };
  total: number;
  sellersCoverage: {
    total: number;
    days: number;
    amount: number;
  };
  hasSellersCoverage?: boolean;
  discountAmount: number;
  militaryDiscountApplied: boolean;
}

export interface QuoteSummaryProps {
  quoteSummary: QuoteSummarySelection;
  residenceType: DwellingType;
  zipCode: string;
  sellersCoverage: boolean;
  disablePadding?: boolean;
  isLoading?: boolean;
}

export interface CardQuoteSummaryProps extends QuoteSummaryProps {
  onClickShareQuoteButton?: () => void;
  onClickSaveQuoteButton?: () => void;
  onClickStartOrderButton?: () => void;
}

const CardQuoteSummary: React.FC<CardQuoteSummaryProps> = ({
  onClickShareQuoteButton,
  onClickSaveQuoteButton,
  onClickStartOrderButton,
  ...props
}) => {
  const menuItems = [
    { label: 'Start Order', onClick: onClickStartOrderButton, id: 'start-order-open-drawer' },
    { label: 'Save Quote', onClick: onClickSaveQuoteButton, id: 'save-quote-open-drawer' },
    { label: 'Share Quote', onClick: onClickShareQuoteButton, id: 'share-quote-open-drawer' },
  ];
  return (
    <div className="bg-white border border-gray-300 rounded-3 shadow-1 w-full py-5">
      <QuoteSummary {...props} />

      <div className="flex flex-wrap mx-4 pt-4 justify-center">
        {isMobileView() ? (
          <>
            {menuItems.map((menuItem) => (
              <div key={menuItem.label} className="w-full mt-2">
                <Button
                  onClick={() => menuItem.onClick?.()}
                  label={menuItem.label}
                  width="full"
                  size="medium"
                  id={menuItem.id}
                  disabled={props.isLoading}
                />
              </div>
            ))}
          </>
        ) : (
          <div id="plansAndPricesContext" className="w-full mt-2 max-w-1/4">
            <ContextMenu
              className="w-full"
              menuItems={menuItems}
              menuSizeStyle={{ width: '286px' }}
            >
              <Button
                label="Select Action"
                width="full"
                size="medium"
                id="select-action"
                disabled={props.isLoading}
                labelAlign="justified"
                endIcon={<IconNavArrowDown />}
              />
            </ContextMenu>
          </div>
        )}
      </div>
    </div>
  );
};

CardQuoteSummary.defaultProps = {
  isLoading: false,
};

export const QuoteSummary: React.FC<QuoteSummaryProps> = (props) => {
  function normalizeResidenceType(rt) {
    if (rt) {
      const residenceType = rt.toUpperCase();
      switch (residenceType) {
        case 'SINGLEFAMILYRESIDENCE':
        case 'SINGLE_FAMILY':
        case 'SFR':
          return 'Single Family Residence';
        case 'CONDOMINIUM':
        case 'CONDO':
          return 'Condominium';
        case 'TOWNHOUSE':
        case 'TOWNHOME':
        case 'TWNH':
          return 'Townhouse';
        case 'DUPLEX':
        case 'DUP':
          return 'Duplex';
        case 'TRIPLEX':
        case 'TRIP':
          return 'Triplex';
        case 'FOURPLEX':
        case 'FOUR_PLEX':
        case 'FOUR':
          return 'Fourplex';
        case 'MOBILEHOME':
        case 'MOBILE_HOME':
        case 'MH':
          return 'Mobile Home';
        default:
          return rt;
      }
    }
    return rt;
  }

  const lineItemContainerClassName = 'flex flex-nowrap flex-row justify-between items-center';

  const LineItem: React.FC<{ id: string }> = ({ id, children }) => {
    return (
      <div id={id} className={lineItemContainerClassName}>
        {children}
      </div>
    );
  };

  return (
    <div className={props?.disablePadding ? 'px-0' : 'px-6'}>
      <Text variant="heading-04" className="mt-0 mb-2" color="primary">
        {PLANS_AND_PRICES_LABELS.QUOTE_SUMMARY_HEADER}
      </Text>
      {props.residenceType ? (
        <LineItem id="quote-summary-residence-type">
          <Text variant="label">Residence Type: </Text>
          <Text variant="caption">{normalizeResidenceType(props.residenceType)}</Text>
        </LineItem>
      ) : null}
      {props.zipCode ? (
        <LineItem id="quote-summary-zip-code">
          <Text variant="label">Zip Code: </Text>
          <Text variant="caption">{props.zipCode}</Text>
        </LineItem>
      ) : null}

      {props.sellersCoverage ? (
        <div id="quote-summary-product-sellers-coverage">
          <div className={lineItemContainerClassName}>
            <Text variant="label">Seller's Coverage: </Text>
            {props.quoteSummary?.sellersCoverage?.total ? (
              <Text variant="caption">
                {toCurrency(props.quoteSummary?.sellersCoverage?.total || 0)}
              </Text>
            ) : (
              <Text variant="caption">Included</Text>
            )}
          </div>
        </div>
      ) : null}

      {props.quoteSummary?.sellersCoverage?.amount > 0 ? (
        <div className="ml-2">
          <Text variant="caption" color="gray">
            Fee ({toCurrency(props.quoteSummary?.sellersCoverage?.amount || 0)}/day for{' '}
            {props.quoteSummary?.sellersCoverage?.days} days)
          </Text>
        </div>
      ) : null}

      <br />

      {props.quoteSummary?.plan?.name ? (
        <LineItem id="quote-summary-product-quote">
          <Text variant="label">{props.quoteSummary?.plan?.name}</Text>
          <Text variant="label">{toCurrency(props.quoteSummary?.plan?.price || 0)}</Text>
        </LineItem>
      ) : null}

      {props.quoteSummary?.coverages?.map((c) =>
        c.name ? (
          <LineItem key={c.id} id={`quote-summary-coverage-${c.id}`}>
            <Text variant="caption">{removeTags(c.name)} </Text>
            <Text variant="label">{toCurrency(c.price || 0)}</Text>
          </LineItem>
        ) : null,
      )}

      {props.quoteSummary?.tax ? (
        <LineItem id="quote-summary-quotetax">
          <div className="flex space-x-2 items-center">
            <Text variant="caption">Tax </Text>
            {props.quoteSummary.tax.taxableAmount > 0 && props.quoteSummary.tax.price > 0 && (
              <Text
                variant="helper-text"
                color="gray"
              >{`${toPercentage(props.quoteSummary.tax.rate)} of ${toCurrency(props.quoteSummary.tax.taxableAmount)}`}</Text>
            )}
          </div>
          <Text variant="label">{toCurrency(props.quoteSummary.tax.price || 0)}</Text>
        </LineItem>
      ) : null}

      {props.quoteSummary?.discountAmount > 0 ? (
        <LineItem id="quote-summary-discount">
          <div className="flex space-x-2 items-center">
            <Text variant="caption">
              <DiscountLabel
                label={
                  props.quoteSummary.militaryDiscountApplied
                    ? 'Military Discount Applied'
                    : undefined
                }
              />{' '}
            </Text>
          </div>
          <Text variant="label">{toCurrency(0 - props.quoteSummary.discountAmount)}</Text>
        </LineItem>
      ) : null}

      <hr />

      {props.quoteSummary ? (
        <LineItem id="quote-summary-quotetotal">
          <Text variant="label">Annual Total: </Text>
          <Text variant="label">{toCurrency(props.quoteSummary?.total || 0)}</Text>
        </LineItem>
      ) : null}
    </div>
  );
};

export default CardQuoteSummary;
