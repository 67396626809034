import { BaseAPI } from './_api';
import { UserDetails } from '@app/models/profile.model';
class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public getPasswordRestLink(email: string, brand: string): Promise<string> {
    return this.axios
      .get<any, any>(`${this.getHost()}/admin/passwordLink?brand=${brand}&email=${email}`)
      .then((res) => {
        return res.link;
      })
      .catch((e) => {
        console.log('Get Password Reset Link failed.', e);
        return '';
      });
  }

  public async deleteFusionAuth(user: UserDetails): Promise<boolean> {
    return this.axios
      .delete<any, any>(`${this.getHost()}/admin/deleteCipUser/${user.authID}`)
      .then((res) => {
        return true;
      })
      .catch((e) => {
        console.error(e.response);
        return false;
      });
  }
}

const AdminApi = new API();
export default AdminApi;
