import { OrderPricingData, OrderPricingDetails } from '@apis/models/contract.api.model';
import { InitiatingOffice } from './emailsender.api.model';
import { OrderWorkflow } from '@constants/app.constants';

export const RESourcePageNewOrder = OrderWorkflow.NewOrder;
export const RESourcePagePlansPrices = OrderWorkflow.PlansPrices;

export enum DwellingType {
  SingleFamilyResidence = 'SINGLEFAMILYRESIDENCE',
  Condominium = 'CONDOMINIUM',
  Townhouse = 'TOWNHOUSE',
  Duplex = 'DUPLEX',
  Triplex = 'TRIPLEX',
  Fourplex = 'FOURPLEX',
  MobileHome = 'MOBILEHOME',
  MultiUnitPlex = 'MULTIUNITPLEX',
}

export interface ProductFiltersRequest {
  brand: string;
  zip: string;
  state: string;
  typeOfResidence: DwellingType;
  squareFootage: number;
  age: number;
  franchiseCode: string;
  roProductsOnly?: boolean;
  contractListTimestamp: string;
}

export interface ProductFilters {
  terms: number[];
  sellersCoverage: boolean;
  acCoverage: boolean;
}

export interface ProductAvailabilityByLocationRequest {
  zip: string;
  state: string;
}

export interface ProductAvailabilityByLocationResponse {
  plansAvailableInLocation: boolean;
}

export interface ProductAvailabilityRequest {
  office: {
    id: string;
  };
  property: ProductProperty;
  options: {
    tenant: string;
    sellersCoverage: boolean;
    acCoverage: boolean;
    contractTermMonths: number;
    /** format YYYY-MM-DD */
    contractListTimestamp: string;
    roProductsOnly?: boolean;
  };
}

export interface ProductAvailabilityResponse {
  products: Product[];
}

export interface ProductProperty {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  zipPlus4?: string;
  residenceType: string;
  age: number;
  squareFootage: number;
}

export interface Product {
  id: string;
  starPVID: string;
  starOldPV: string;
  name: string;
  serviceFees: number;
  maxGuestUnits: number;
  contractTermMonths: number;
  listingTermInDays: number;
  effectiveDate: string;
  expirationDate: string;
  acCoverage: boolean;
  sellersCoverage: boolean;
  meta: {
    externalDescription: string;
    serviceFees: string[];
    guestSuites: {
      min: number;
      max: number;
    };
    listingTerm: {
      length: number;
      timeUnit: string;
    };
    salesGroup: string[];
  };
  isOldRE1Product: boolean;
}

export interface ProductCoverages {
  included: ProductCoverage[];
  optional: ProductCoverage[];
  group: ProductGroup[];
}

export interface ProductCoverage {
  id: string;
  name: string;
  tags: string[];
  starCoverageId: string;
}

export interface ProductGroup {
  starGroupId: string;
  name: string;
  maxQuantity: string;
}

export interface ProductAndCoveragesResponse {
  product: Product;
  coverages: ProductCoverages;
}

export interface ProductPricingOptions {
  contractTermMonths: number;
  listingTermInDays: number;
  earnixRuleID: number;
  initiatingOfficeID: string;
  initiatingOfficeFranchiseCode: string;
  specialDiscounts?: string[];
}

export interface ProductPricingRequest {
  property: ProductProperty;
  options: ProductPricingOptions;
}

export interface ProductPricingResponse {
  product: {
    id: string;
    price: number;
  };
  guestUnitPrice: number;
  optionalCoverages: {
    id: string;
    price: number;
    priceWithGuest: number;
  }[];
  groupCoverages: {
    id: string;
    price: number;
    priceWithGuest: number;
  }[];
  earnixRuleID: number;
}

export interface ProductPricingDetailsRequest {
  property: ProductProperty;
  options: ProductPricingOptions;
  selected: PricingSelection;
}

export interface ProductPricingDetailsSaveQuoteRequest extends ProductPricingDetailsRequest {
  initiatingOffice: InitiatingOffice;
  propertyAddressUUID: string;
  sourcePage: string;
}

export interface PricingSelection {
  productID: string;
  /** @deprecated: used for legacy products only in 2022 when guest units are part of product tier instead of coverage. */
  guestUnitQuantity?: number;
  optionalCoverages: PricingSelectionItem[];
  groupCoverages: PricingSelectionItem[];
}

export interface PricingSelectionItem {
  id: string;
  quantity: number;
}

export interface ProductPricingDetailsResponse {
  optionalCoverages: PricingItem[];
  groupCoverages: PricingItem[];
  pricing: OrderPricingData;
  details: OrderPricingDetails;
  earnixRuleID: number;
  appliedSpecialDiscounts: string[];
}

export interface PricingItem {
  id: string;
  price: number;
}

export interface CreateQuoteResponse {
  quoteID: string;
  orderID: string;
}
