import React from 'react';
import { AbstractNewOrderStep, wrapNavigation } from '@pages/order/AbstractNewOrderStep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderStep3Template } from '@templates/order/NewOrderStep3Template';
import { NewOrderContextData, NewOrderMetaData } from '@context/NewOrderContext/model';
import Layout from '@components/layout/Layout';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import OrdersApi from '@apis/orders.api';
import cloneDeep from 'lodash/cloneDeep';

class NewOrderStep3 extends AbstractNewOrderStep {
  constructor(props) {
    super(props);
  }

  getCurrentStep(): NewOrderStep {
    return NewOrderStep.BuyerSellerInfo;
  }

  async handleOrderUpdate(
    updatedData: NewOrderContextData,
    updatedMeta = this.context.metaData,
  ): Promise<{ orderID: string; success: boolean }> {
    let orderID = this.context.metaData.orderID;
    let orderUpdateComplete = false;
    try {
      useGlobalOverlaySpinner().showSpinner(true, 'updating order...');
      const orderRequest = this.context.toOrderRequest(updatedData, updatedMeta);
      if (!orderID) {
        console.info('order will be created...');
        const orderResponse = await OrdersApi.create(orderRequest);
        console.info('orderid created', orderResponse.orderID);
        orderID = orderResponse.orderID;
        orderUpdateComplete = true;
      } else if (this.context.hasOrderRequestChanged(orderRequest)) {
        console.info('order will be updated...', orderID);
        await OrdersApi.update(orderID, orderRequest);
        console.info('orderid updated', orderID);
        orderUpdateComplete = true;
      } else {
        console.info('no changes on the order found. proceed without updates', orderID);
        orderUpdateComplete = true;
      }
    } catch (e) {
      console.error('error occurred processing order', e);
    } finally {
      useGlobalOverlaySpinner().showSpinner(false);
    }

    return { orderID, success: orderUpdateComplete };
  }

  async saveData(
    updatedData: NewOrderContextData,
    updatedMeta: NewOrderMetaData,
  ): Promise<{ data: NewOrderContextData; meta: NewOrderMetaData }> {
    const postUpdateData: NewOrderContextData = cloneDeep(updatedData);
    const postUpdateMeta: NewOrderMetaData = cloneDeep(updatedMeta);

    const { success, orderID } = await this.handleOrderUpdate(postUpdateData, postUpdateMeta);
    if (!success) {
      throw new Error('failed to save order data');
    }
    postUpdateMeta.orderID = orderID;
    this.context.setNewOrderData(updatedData);

    return { data: postUpdateData, meta: postUpdateMeta };
  }

  async submitPage(updatedData: NewOrderContextData) {
    // ga event
    // for pages, set the context data, and navigate to a different step
    // if the changes on the page conflicts with later parts of the page (e.g. changing property address requires change in plans)
    //  then update the context based on what's necessary
    // we'd also want to create an order or update the order, depending on if the context contains the order id or not
    // for order submit, call the api to submit the order, and complete the order

    try {
      const { data } = await this.saveData(updatedData, this.context.metaData);

      // if user has entered buyer name but closing date not set, we want to navigate them back to plan
      if (data.buyerInfo.firstName && !data.productFilters.projectedClosingDate) {
        this.navigateToStep(NewOrderStep.PlanCoverage);
        return;
      }
      this.navigateToStep(NewOrderStep.ReviewSubmit);
    } catch (e) {
      console.error('failed to save order data', e);
      return;
    }
  }

  async editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData) {
    // ga event
    // for pages, set the data based off of what is being updated, then navigate to step
    if (updatedData) {
      try {
        await this.saveData(updatedData, this.context.metaData);
      } catch (e) {
        console.error('failed to save order data', e);
        return;
      }
    }
    this.navigateToStep(step);
  }

  cancelPage() {
    // ga event
    this.context.resetNewOrderData();
    this.navigateToDashboard();
  }

  render() {
    return (
      <Layout isLoggedIn={true} slug="newOrderStep3" containerClassName="py-5">
        <NewOrderStep3Template
          currentStep={this.getCurrentStep()}
          latestStep={this.context.latestStep}
          savedData={this.context.newOrderData}
          metaData={this.context.metaData}
          userProfile={this.context.userProfile}
          submitPage={(updatedData) => this.submitPage(updatedData)}
          cancelPage={() => this.cancelPage()}
          editAnotherStep={(step, updateData) => this.editAnotherStep(step, updateData)}
        />
      </Layout>
    );
  }
}

export default wrapNavigation(NewOrderStep3);
