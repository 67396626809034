import {
  WarrantyLinkCheck,
  WarrantylinkContract,
  WarrantylinkContractResponse,
} from '@apis/models';
import WarrantylinkApi from '@apis/warrantylink.api';
import React, { useEffect, useState } from 'react';
import WarrantyLinkPaymentsTemplate from '@templates/warrantylink/WarrantyLinkPaymentsTemplate';
import { formatDateFromString, generateDataSubSets, toCurrency } from '@helpers/utils';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs from '@app/locales/en';
import Layout from '@components/layout/Layout';
import { ContentBox } from '@components/layout/ContentBox';
import { useCurrentRoute } from 'react-navi';

/** The # of contracts to load at a time, in case that the wlk request has too many contracts */
const CONTRACT_DATA_LOAD_SIZE = 50;

const WarrantylinkPayments: React.FC = () => {
  const { showSpinner } = useGlobalOverlaySpinner();
  const { addWarningToQueue } = useGlobalAlert();

  const [payments, setPayments] = useState<WarrantyLinkCheck[]>([]);
  /** TODO: refactor isLoading and isLoadingContracts names for better readability */
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingContracts, setIsLoadingContracts] = useState(true);

  useEffect(() => {
    loadPayments();
  }, []);

  useEffect(() => {
    if (isLoading) {
      showSpinner(true, 'loading payments');
    } else {
      showSpinner(false);
    }
  }, [isLoading]);

  async function loadPayments(): Promise<any> {
    try {
      setIsLoading(true);
      setIsLoadingContracts(true);
      const paymentsResponse = await WarrantylinkApi.getWarrantyLinkPayments();

      // Sort by date and update the date formats for display
      const checks: WarrantyLinkCheck[] = paymentsResponse?.checks
        ?.sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
        .map((check) => ({
          ...check,
          date: formatDateFromString(check.date),
          contracts: check.contracts.map((contract) => ({
            ...contract,
            submissionDate: formatDateFromString(contract.submissionDate),
          })),
        }));
      setIsLoading(false);

      // Load the contract data
      await loadContractDetails(checks);
      setIsLoadingContracts(false);
    } catch (e) {
      console.error(e);
      addWarningToQueue({ ...msgs.PAYMENT_CHECKS_LOAD_FAILURE });
    } finally {
      setIsLoading(false);
      setIsLoadingContracts(false);
    }
  }

  /** For a given wlk check, obtain the list of contract ids */
  function getContractIDsInCheck(check: WarrantyLinkCheck): string[] {
    return check.contracts.map((contract) => contract.id);
  }

  /** For a given list of checks, load in the additional contract data necessary.
   * TODO: Add typing to this. Not as necessary right now as the AccordionItemPayment is not typescript yet.
   */
  async function loadContractDetails(checks: WarrantyLinkCheck[]): Promise<any[]> {
    let checksWithContractDetails: any[] = [];

    // The checks and contract ids appended to be loaded
    let loadingChecks: WarrantyLinkCheck[] = [];
    let loadingContractIDs: string[] = [];

    const addContractToPaymentState = async () => {
      const checkNumbers = loadingChecks.map((c) => c.checkNumber); // for logging
      console.log(
        `Retrieving ${loadingContractIDs.length} contracts for checks ${checkNumbers.join()}...`,
      );
      const wlkContracts: WarrantylinkContract[] = await getWLKContracts(loadingContractIDs);

      checksWithContractDetails = [
        ...checksWithContractDetails,
        ...mapContractsToPaymentCheck(loadingChecks, wlkContracts),
      ];
      setPayments(checksWithContractDetails);
    };

    for await (const check of checks) {
      if (loadingContractIDs.length > CONTRACT_DATA_LOAD_SIZE) {
        await addContractToPaymentState();
        loadingChecks = [];
        loadingContractIDs = [];
      }

      // Appending the data records to list
      loadingChecks = [...loadingChecks, check];
      loadingContractIDs = [...loadingContractIDs, ...getContractIDsInCheck(check)];
    }

    // Do the final load, if any
    if (loadingContractIDs.length > 0) {
      await addContractToPaymentState();
    }

    // For debugging purposes
    console.debug(`total of ${checks.length} checks, loaded ${checksWithContractDetails.length}`);
    console.debug(
      `total of ${checks.reduce((acc, c) => (acc += c.contracts.length), 0)}, loaded ${checksWithContractDetails.reduce((acc, c) => (acc += c.contracts.length), 0)}`,
    );

    return checksWithContractDetails;
  }

  /** Obtain wlk contract details for a list of contract ids */
  async function getWLKContracts(contractIDs: string[]): Promise<WarrantylinkContract[]> {
    if (contractIDs.length === 0) {
      console.warn('no contracts provided in request. Ignoring.');
      return;
    }

    let wlkContracts: WarrantylinkContract[] = [];

    // Create subset calls to avoid overflowing # of contracts
    const subsets = generateDataSubSets(contractIDs, CONTRACT_DATA_LOAD_SIZE);
    console.log(`expecting to make ${subsets.length} requests`);

    let calls: Promise<WarrantylinkContractResponse>[] = [];
    for (const subset of subsets) {
      const searchRequest = {
        includeDetail: true,
        contractId: subset.join(),
      };
      calls = [...calls, WarrantylinkApi.searchWarrantyLinkContracts(searchRequest)];
    }

    // Concurrently make all the subset requests at once
    await Promise.all(calls).then((responses) => {
      responses.forEach((response) => {
        wlkContracts = [...wlkContracts, ...response.contracts];
      });
    });

    return wlkContracts;
  }

  /** Takes in  */
  function mapContractsToPaymentCheck(
    checks: WarrantyLinkCheck[],
    wlkContractDetails: WarrantylinkContract[],
  ): any[] {
    return checks.map((check) => {
      const newCheck = { ...check };
      newCheck.contracts = newCheck.contracts.map((contract) => {
        contract.amount = toCurrency(Number(contract.amount));
        const newContracts = {
          address: undefined,
          agent: undefined,
          officeAddress: undefined,
          office: undefined,
          buyer: undefined,
          seller: undefined,
          ...contract,
        };
        const matchingContract = wlkContractDetails.find(
          (contract) => contract.id === newContracts.id,
        );
        if (matchingContract) {
          newContracts.address = matchingContract.address;
          newContracts.officeAddress = matchingContract.initiatingOffice.officeAddress;
          newContracts.office = matchingContract.initiatingOffice;
          newContracts.agent = matchingContract.initiatingOffice.agentName;
          newContracts.buyer = matchingContract.warrantylink.form.buyer;
          newContracts.seller = matchingContract.warrantylink.form.seller;
        }
        return newContracts;
      });
      return newCheck;
    });
  }

  return (
    <Layout isLoggedIn={true} slug="warrantyLink">
      <WarrantyLinkPaymentsTemplate
        warrantyLinkChecks={payments}
        isLoading={isLoading || isLoadingContracts}
        preFilter={useCurrentRoute().data}
      />
    </Layout>
  );
};

export default WarrantylinkPayments;
