import { PlansAndPricesParamEnum } from '../queryParamParserService/plansAndPricesQueryParams.service';
import {
  deepLinkActions,
  isCategoryValid,
  isFieldValid,
  isFieldValueValid,
  isLocationValid,
} from './deepLink.models';

const DEEPLINK_FULL = 'deepLinkFullLocation';

class DeepLink {
  public actions: deepLinkActions;

  init() {
    if (window.location.search) {
      const query = new URLSearchParams(window.location.search);
      const location = query.get('deepLinkLocation');
      const category = query.get('deepLinkCategory');
      const field = query.get('deepLinkField');
      const fieldValue = query.get('deepLinkFieldValue');

      this.actions = {
        deepLinkLocation: isLocationValid(location) ? location : null,
        deepLinkCategory: isCategoryValid(category) ? category : null,
        deepLinkField: isFieldValid(field) ? field : null,
        deepLinkFieldValue: isFieldValueValid(fieldValue) ? fieldValue : null,
      };

      if (location || category || field || fieldValue || this.isValidParams(query)) {
        localStorage.setItem(DEEPLINK_FULL, window.location.pathname + window.location.search);
      }
    }
  }

  //whitelisted params. Required to set up the deeplink URL in registration flow
  isValidParams(query: any) {
    let isValid = false;
    const residencetype = query.get(PlansAndPricesParamEnum.RESIDENT_TYPE);
    const zipcode = query.get(PlansAndPricesParamEnum.ZIP_CODE);
    const sellerscoverage = query.get(PlansAndPricesParamEnum.SELLERS_COVERAGE);

    if (residencetype || zipcode || sellerscoverage) {
      isValid = true;
    }

    return isValid;
  }

  /** Must be called by the consumer once it is completed using this service. Failure to do so will result in unwanted behavior. */
  reset() {
    this.actions = {
      deepLinkLocation: null,
      deepLinkCategory: null,
      deepLinkField: null,
      deepLinkFieldValue: null,
    };

    localStorage.removeItem(DEEPLINK_FULL);
  }

  GetDeepLinkString() {
    return localStorage.getItem(DEEPLINK_FULL);
  }
}

const DeepLinkService = new DeepLink();
export default DeepLinkService;
