import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import QuickLinkCancelOrder, {
  QuickLinkCancelOrderProps,
} from '@components/drawer/subcomponents/QuickLinkCancelOrder';

type IProps = Required<Pick<DrawerProps, 'isActive' | 'onClose'>> & QuickLinkCancelOrderProps;

const DrawerQLCancelOrder: React.FC<IProps> = (props) => {
  return (
    <Drawer {...props} className="w-224" useDefaultWidth={false}>
      {props.isActive && <QuickLinkCancelOrder onClose={props.onClose} />}
    </Drawer>
  );
};

export default DrawerQLCancelOrder;
