import React from 'react';
import { Input, InputProps, MaskedInput, MaskedInputProps } from '@ftdr/blueprint-components-react';

interface OwnInputWithValidationProps extends Omit<InputProps, ''> {
  /** is called when onChange() has occurred. Used as a simplification of the call. The value of the input will be set. */
  onChangeValue: (value: string) => void;
  /** validation function against the value on the input.  Returns error message, otherwise blank string if no error. */
  validateValue: (value: string) => string;
  /** function used to clear the error message using the set state. */
  onClearError: () => void;
  /** when validateValue evaluates as error, then this function is called */
  onError: (error: string) => void;
  /** transform the value before it gets passed down in onChangeValue and onChange. Useful for any formatting/cleanup. */
  formatChangeValue?: (value: string) => string;
}

export type InputWithValidationProps = OwnInputWithValidationProps & Omit<InputProps, ''>;
export type MaskedInputWithValidationProps = OwnInputWithValidationProps &
  Omit<MaskedInputProps, ''>;

abstract class BaseInputWithValidation<
  P extends OwnInputWithValidationProps,
> extends React.Component<P> {
  static defaultProps = {
    formatChangeValue: (value) => value,
  };

  protected constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  /** pass in the input element that is using this abstract class. */
  abstract get InputElement(): typeof Input | typeof MaskedInput;

  /** when input has changes done, clear the input error */
  protected onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e) {
      this.props.onClearError();
      e.target.value = this.props.formatChangeValue(e.target.value);
      this.props.onChangeValue(e.target.value);
      this.props.onChange?.(e);
    }
  }

  /** when input has been blurred, apply a validation function against the value. */
  protected onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    e.target.value = this.props.formatChangeValue(e.target.value);
    const error = this.props.validateValue(e.target.value);
    if (error) {
      this.props.onError(error);
    }
    this.props.onBlur?.(e);
  }

  render = () => (
    <this.InputElement onChange={this.onInputChange} onBlur={this.onInputBlur} {...this.props} />
  );
}

export class InputWithValidation extends BaseInputWithValidation<InputWithValidationProps> {
  constructor(props) {
    super(props);
  }

  get InputElement(): typeof Input | typeof MaskedInput {
    return Input;
  }
}

export class MaskedInputWithValidation extends BaseInputWithValidation<MaskedInputWithValidationProps> {
  constructor(props) {
    super(props);
  }

  get InputElement(): typeof Input | typeof MaskedInput {
    return MaskedInput;
  }
}

export default InputWithValidation;
