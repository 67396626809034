import ZestyApi from '@apis/zesty.apis';
import { ToggleContent } from '@apis/models/zesty.api.model';
import { getConfig } from '@services/app.config.loader';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';

export enum Features {
  NewOrderRedesign = 'NewOrderRedesign', // TODO: ARE-10336 remove usage
  NewOrderRedesignDevButton = 'NewOrderRedesignDevButton', // TODO: ARE-10336 remove usage and the button itself.
  MyQuotes = 'My Quotes', // TODO: ARE-11039 remove usage
  MyOrdersActionSwitcher = 'MyOrdersActionSwitcher',
  CheckAbandonedOrders = 'CheckAbandonedOrders', // TODO: mention a story to remove usage
  RenewalEmails = 'Renewal Emails',
  CCAdminAbandonedOrders = 'CCAdminAbandonedOrders', // TODO: ARE-11812 remove usage
  EditFlowMilitaryDiscount = 'EditFlowMilitaryDiscount',
  Ahs2024BdsTheme = 'AHS 2024 BDS Theme',
}

let featureMap: ToggleContent[] = [];

export async function initFeatureToggles() {
  featureMap = await ZestyApi.GetToggles();
}

export function GetFeature(featureName: Features): ToggleContent {
  return featureMap?.find((i) => i.feature === featureName);
}

export function IsFeatureEnabled(featureName: Features): boolean {
  return GetFeature(featureName)?.status === 1 && IsFeatureEnabledInBranchOnly(featureName);
}

function IsFeatureEnabledInBranchOnly(featureName: Features): boolean {
  try {
    const env = getConfig('env');
    const feature = GetFeature(featureName);
    return feature?.status && (!feature?.branchonly || env === 'branch');
  } catch (e) {
    console.error(e);
    return false;
  }
}

export enum Theme {
  AhsWebApp = "ahs-web-app",
  Ahs2024 = "ahs-2024",
}

export function IsTheme(themeName: Theme) {
  const brand = getBrand();
  let theme = Theme.AhsWebApp;
  if (brand === Brand.AHS && IsFeatureEnabled(Features.Ahs2024BdsTheme)) {
    theme = Theme.Ahs2024;
  }
  return themeName === theme;
}
