import { ORDER_CARD_TITLE } from '@constants/newOrder-constants';

export interface INewOrderStepDeterminer<OrderType> {
  getCurrentNewOrderStep(order: OrderType): string;
  getCurrentNewOrderWorkflow(order: OrderType): string[];
  getLatestOrderWithLatestStep(orders: OrderType[]): OrderType;
  isStepConsideredComplete(order: OrderType, step: string): boolean;
}

export const getNewOrderStepLabelOrdered = (
  isREAgentUser: boolean,
  representing: string,
): string[] => {
  const steps: string[] = [];

  // Step 1-3 differs based on the user role
  if (!isREAgentUser) {
    steps.push(
      ORDER_CARD_TITLE.AGENT_INFO,
      ORDER_CARD_TITLE.PROPERTY_ADDRESS,
      ORDER_CARD_TITLE.PROPERTY_DETAILS,
    );
  } else {
    steps.push(
      ORDER_CARD_TITLE.PROPERTY_ADDRESS,
      ORDER_CARD_TITLE.PROPERTY_DETAILS,
      ORDER_CARD_TITLE.AGENT_INFO,
    );
  }

  // The customer information order differs based on the represents selection
  switch (representing?.toLocaleLowerCase()) {
    case 'buyer':
      steps.push(ORDER_CARD_TITLE.BUYER_INFO, ORDER_CARD_TITLE.SELLER_INFO);
      break;
    case 'seller':
    case 'both':
    default:
      steps.push(ORDER_CARD_TITLE.SELLER_INFO, ORDER_CARD_TITLE.BUYER_INFO);
      break;
  }

  // The last two cards are always the same
  steps.push(ORDER_CARD_TITLE.CLOSING_INFO, ORDER_CARD_TITLE.PLANS_AND_COVERAGES);

  return steps;
};
