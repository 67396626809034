import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
  CancelTokenSource,
} from 'axios';
import { getBrand } from '@helpers/brand.utils';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import merge from 'lodash/merge';
import { getConfig } from '../app.config.loader';
import { AppErrorResponse } from '@apis/utils/api.errors';
import { addSentryBreadCrumb } from '@app/core/sentry.service';
import { XHR__API_CALL_TRACE_ID } from '@constants/sentry-breadcrumb.constants';

export interface Options {
  cancelToken?: CancelToken;
}

const HEADER_KEYS = {
  TraceID: 'x-b3-traceid',
};

/** Default configuration for all axios instance  */
const DEFAULT_AXIOS_CONFIG: AxiosRequestConfig = {
  timeout: 40000,
  headers: {
    'X-Brand-Name': getBrand(),
  },
};

let authJWT: string;

export function populateJWT(newJWT: string) {
  authJWT = newJWT;
}

interface BaseAPIConfig {
  suppressResponseErrorNotification?: boolean;
  suppressAllErrorNotifications?: boolean;
  useCurrentUserJWT?: boolean;
  returnCancelErrorAsNull?: boolean;
  /** used if you want to have a general cancel token for the entire api */
  cancelToken?: CancelToken;
}

/** Abstract class for APIs */
export abstract class BaseAPI {
  /** Axios instance for http requests */
  protected axios: AxiosInstance;
  private serviceName: string;
  private useDefaultConfig: boolean;
  private customDefaultConfig: AxiosRequestConfig;

  /** request config that is only used for one request call */
  private oneTimeAxiosRequestConfig: AxiosRequestConfig = null;

  private config: BaseAPIConfig = {};

  /** Service name is the name to look up in client-configuration
   * @param configServiceName the key to look up in client-configuration
   * @param useJWT if the auth token from login should be included in request
   * @param config override defaults to Axios requests
   * @param useDefaultConfig whether or not to use default config for axios
   * @param suppressErrors on 5xx responses, do not auto-display errors into global alerts
   */
  protected constructor(
    configServiceName: string,
    useJWT: boolean = false,
    config: AxiosRequestConfig = {},
    useDefaultConfig = true,
    suppressErrors = false,
  ) {
    this.serviceName = configServiceName;
    this.customDefaultConfig = config;
    this.useDefaultConfig = useDefaultConfig;
    this.config.useCurrentUserJWT = useJWT;
    this.config.suppressResponseErrorNotification = suppressErrors;
    this.config.returnCancelErrorAsNull = true; // TODO: this is the default behavior atm, but it is not preferred. Should adjust when we normalize cancellation logic (i.e. page navigation).
    this.axios = this.newInstance(config, useDefaultConfig);
  }

  getCancelTokenSource(): CancelTokenSource {
    return Axios.CancelToken.source();
  }

  /** creates a brand new cancel token source to be applied to any API call. To be used in conjunction with withRequestConfigAs() to set a cancel token */
  public createNewCancelTokenSource(): CancelTokenSource {
    let cancel: Canceler;
    const token = new Axios.CancelToken((c) => (cancel = c));
    return { token, cancel };
  }

  /** from a given error message from a catch, check if it is any type of cancellation error */
  public isCancellationError(err: any): boolean {
    return Axios.isCancel(err);
  }

  /** Returns the host url for this API by the serviceName. Use for requests.
   *  @param override if given, uses that config instead of the service name. Meant for temp usages
   */
  protected getHost(override?: string): string {
    let configName = this.serviceName;
    // Override the API service name default
    if (override) {
      configName = override;
    }

    const host = getConfig(configName);
    if (!host) {
      throw TypeError(`Host not found for service: ${configName}`);
    }
    return host;
  }

  /** creates a new cloned instance of the API with new config settings */
  public new(config: BaseAPIConfig = {}): this {
    const cloned: this = Object.create(
      Object.getPrototypeOf(this),
      Object.getOwnPropertyDescriptors(this),
    );

    cloned.config = cloned.mergeConfigs(cloned.config, config);
    // re-sets the axios instance, to re-set the interceptors
    cloned.axios = cloned.newInstance(cloned.customDefaultConfig, cloned.useDefaultConfig);

    return cloned;
  }

  /** apply a one-time custom request config separate from the default.
   * Only certain configs are allowed to be set with this method to prevent unintended effects. */
  public withRequestConfigAs(config: Pick<AxiosRequestConfig, 'timeout' | 'cancelToken'>): this {
    this.oneTimeAxiosRequestConfig = config as AxiosRequestConfig;
    return this;
  }

  private mergeConfigs<T>(c1: T, c2: T): T {
    return merge({}, c1, c2);
  }

  private newInstance(config: AxiosRequestConfig, useDefaultConfig = true): AxiosInstance {
    const mergedConfig = this.mergeConfigs(useDefaultConfig ? DEFAULT_AXIOS_CONFIG : {}, config);
    const instance = Axios.create(mergedConfig);
    this._InstallInterceptors(instance);
    return instance;
  }

  /** adds a one time request config interceptor to the API.  If the oneTimeAxiosRequestConfig is set, then it takes precedence over the default config.
   * Once used for the request, it is immediately removed, so be sure to set the config for each request. */
  private _InstallOneTimeRequestConfigInterceptor(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      if (this.oneTimeAxiosRequestConfig) {
        console.log('custom config detected');
        config = this.mergeConfigs(config, this.oneTimeAxiosRequestConfig);
        config = {
          ...config,
          ...this.oneTimeAxiosRequestConfig,
        };
        this.oneTimeAxiosRequestConfig = null;
      }
      return config;
    });
  }

  private _InstallRequestInterceptors(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      // Clone the config in order to modify it
      const copyConfig = { ...config };

      // Add the auth token to header, if found
      if (this.config.useCurrentUserJWT) {
        const token = authJWT;
        if (token) {
          copyConfig.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      if (this.config.cancelToken) {
        copyConfig.cancelToken = this.config.cancelToken;
      }

      return copyConfig;
    });
    this._InstallOneTimeRequestConfigInterceptor(instance);
  }

  private _InstallResponseInterceptors(instance: AxiosInstance): void {
    // load trace id to sentry
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        const traceID = res.headers[HEADER_KEYS.TraceID];
        addSentryBreadCrumb(XHR__API_CALL_TRACE_ID(traceID));
        return res;
      },
      (err: AxiosError) => {
        if (err.response) {
          const traceID = err.response.headers[HEADER_KEYS.TraceID];
          addSentryBreadCrumb(XHR__API_CALL_TRACE_ID(traceID));
        }
        throw err;
      },
    );
    // handle response
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        return res.data;
      },
      (err: AxiosError) => {
        const { addErrorToQueue: _globalAlertError } = useGlobalAlert();

        // override the function by making it check the config prior to doing it
        const addErrorToQueue: typeof _globalAlertError = (msg) => {
          if (!this.config.suppressAllErrorNotifications) {
            _globalAlertError(msg);
          }
        };

        if (err.response) {
          const traceId = err.response.headers[HEADER_KEYS.TraceID];
          console.error('Response failed:', err.response, traceId);

          if (err.response.status >= 500 && !this.config.suppressResponseErrorNotification) {
            // TODO: temp fix as part of ARE-9016. To be removed with follow-up ticket.
            if (err.response.data?.errors?.[0].code !== '20080') {
              addDetailErrorResponseToQueue(err);
            }
          }
        } else if (err.request) {
          console.error('No response:', err.request);
          addErrorToQueue({ message: `An error occurred when making the request: ${err.message}` });
        } else if (Axios.isCancel(err)) {
          // short-circuit as this is an expected error
          console.info('Request cancelled', err);
          // TODO: this short-circuit causes bad behavior and should be thrown to the consumer to handle appropriately.
          //  Otherwise consumer needs to always check for null and make an assumption it is due to cancel and not from API
          if (this.config.returnCancelErrorAsNull) {
            return;
          }
          throw err;
        } else {
          console.error('Service failed:', err.message);
          addErrorToQueue({
            message: `An error occurred when making the service call ${err.message}`,
          });
        }
        throw err;

        // TODO Make this change later to avoid API's returning an error string object
        // For now, API Services can reference and return error.response
        // return Promise.reject(err.response);
      },
    );
  }
  private _InstallInterceptors(instance: AxiosInstance): void {
    try {
      if (instance) {
        this._InstallRequestInterceptors(instance);
        this._InstallResponseInterceptors(instance);
      }
    } catch (e) {
      console.error('Unable to install axios interceptors', e);
    }
  }
}

function addDetailErrorResponseToQueue(err: AxiosError): void {
  const { addErrorToQueue } = useGlobalAlert();
  const traceId = err.response.headers[HEADER_KEYS.TraceID];

  if (err?.response) {
    let msg = `${err.message}`;
    const payload = {
      'Trace-ID': traceId,
    };
    try {
      const contentType: string = err.response.headers?.['content-type'];

      if (contentType.toLowerCase().includes('text/plain')) {
        msg = err.response?.data;
      }
      if (contentType.toLowerCase().includes('application/json')) {
        // Check if it is our app's response error
        if (err.response.data.errors?.length > 0) {
          if (err.response.data.errors.length > 1) {
            console.warn('multiple errors returned in response. only displaying first one');
          }
          msg = err.response.data?.errors?.[0].message;
        }
      }
    } catch (e) {
      console.error('failed to make a detailed error response', e);
    }

    addErrorToQueue({
      message: `An error occurred when receiving the response: ${msg}`,
      payload,
    });
  }
}

/** when catching the failed api promise, returns the api response, if available. Otherwise, undefined.
 * it is important to check whether the error response is defined before continuing with any error response handling. */
export function getAppErrorResponse(err: any): AppErrorResponse {
  const data = err?.response?.data;
  return data && new AppErrorResponse(data);
}

/** when catching the failed api promise, returns the api response, if available. Otherwise, undefined.
 * it is important to check whether the error response is defined before continuing with any error response handling. */
export function hasAppErrorResponse(err: any): boolean {
  return getAppErrorResponse(err) !== undefined;
}

/** when catching the failed api promise, api response may have an http status, if available. Otherwise, undefined. */
export function getStatusCode(err: any): number {
  return err?.status;
}
