import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumber } from '@helpers/utils';
import { Button, Text } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';

const ENTER = 13;
const UP = 38;
const DOWN = 40;

// itemList = List of Items to select from
// itemKey = unique identifier from within that list
// itemSelected = Default selected item from the list, as text
const OfficeAutoComplete = ({
  itemList,
  itemKey,
  listKey,
  itemSelected,
  children,
  openOfficeSearch,
  showSuggestions = false,
}) => {
  const initObj = {
    // The active selection's index
    activeSuggestion: -1,
    // The suggestions that match the user's input
    filteredSuggestions: itemList,
  };
  const [state, setState] = useState(initObj);
  const ulRef = useRef();
  let suggestionsListComponent;

  useEffect(() => {
    setState({
      activeSuggestion: -1,
      filteredSuggestions: itemList,
    });
  }, [itemList]);

  // Event fired when the user clicks on a suggestion
  const onClickSuggestion = (e) => {
    // Update the user input and reset the rest of the state
    setState({
      activeSuggestion: -1,
      filteredSuggestions: [],
    });
    itemSelected([e]);
  };

  const scrollListItems = (idx) => {
    if (idx === -1) return;

    const nodes = ulRef.current.children;
    const itemHeight = nodes[idx].offsetHeight;
    const { scrollTop } = ulRef.current;
    const viewport = scrollTop + ulRef.current.offsetHeight;
    const itemOffset = itemHeight * idx;

    if (itemOffset < scrollTop || itemOffset + itemHeight > viewport) {
      ulRef.current.scrollTop = itemOffset;
    }
  };

  const onClickOfficeSearch = () => {
    openOfficeSearch();
  };

  // Event fired when the user presses a key down
  const onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === ENTER && state.activeSuggestion > -1) {
      const activeItem = filteredSuggestions[activeSuggestion];
      setState({
        ...state,
        activeSuggestion: -1,
        filteredSuggestions: [],
        userInput: activeItem,
      });
      itemSelected([activeItem]);
      e.preventDefault();
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === UP) {
      if (activeSuggestion === 0) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion - 1 });
      scrollListItems(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === DOWN) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion + 1 });
      scrollListItems(activeSuggestion + 1);
    }
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        onKeyDown: (e) => onKeyDown(e),
      });
    });
  };

  if (showSuggestions) {
    suggestionsListComponent = (
      <ul
        className="suggestions select-options-list"
        ref={ulRef}
        tabIndex={0}
        id="officeSuggestionDropdown"
      >
        {state.filteredSuggestions.map((suggestion, index) => {
          let className;

          // Flag the active suggestion with a class
          if (index === state.activeSuggestion) {
            className = 'suggestion-active';
          }

          const highlightClassName =
            index === state.activeSuggestion
              ? 'select-options-list-item-highlighted'
              : 'select-options-list-item-default';

          return (
            <li
              className={classNames([
                !suggestion.active && 'disabled',
                className,
                'office-autocomplete-suggestion border-b border-gray-300',
                'select-options-list-item',
                highlightClassName,
              ])}
              key={listKey ? suggestion[listKey] : suggestion}
              onClick={() => onClickSuggestion(suggestion)}
            >
              <div className="office-autocomplete-primary">
                <Text variant="heading-06">
                  {suggestion.name} {!suggestion.active && '(Inactive)'}
                </Text>
              </div>
              <Text className="ml-1" variant="helper-text" color="gray">
                {suggestion.address.address1} {suggestion.address.address2}{' '}
                {suggestion.address.city} {suggestion.address.state}, {suggestion.address.zip}
              </Text>
              <Text className="ml-1" variant="helper-text" color="gray">
                {formatPhoneNumber(suggestion.phone)}
              </Text>
            </li>
          );
        })}
        <div className="my-2 mx-1 p-2">
          <Button
            id="wb_openSearchDrawer"
            variant="outlined"
            size="small"
            label="Search Offices"
            onClick={onClickOfficeSearch}
          />
        </div>
      </ul>
    );
  } else {
    suggestionsListComponent = '';
  }

  return (
    <>
      {renderChildren()}
      {suggestionsListComponent}
    </>
  );
};

export default OfficeAutoComplete;
