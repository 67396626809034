import React from 'react';
import Modal, { ModalCloseType } from './Modal';
import SplashScreenOverlay from '@components/modal/subcomponents/SplashScreenOverlay';

const ModalSplashScreenOverlay = (props) => {
  return (
    <Modal
      isActive={props.isActive}
      fullContentArea={true}
      className="force-max-w-full"
      onCloseByOutsideClick={() => props.onDialogClose(ModalCloseType.OutsideClick)}
      onCloseByXButton={() => props.onDialogClose(ModalCloseType.XButton)}
      onCloseByEscapeKey={() => props.onDialogClose(ModalCloseType.EscapeKey)}
      isModalDialog={false}
      closeOnOutsideClick={true}
      showBackdrop={true}
    >
      {props.isActive && <SplashScreenOverlay {...props} />}
    </Modal>
  );
};

export default ModalSplashScreenOverlay;
