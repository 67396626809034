import React from 'react';

interface props {
  authService: any;
}

export default class SSORedirect extends React.Component<props, any> {
  async componentDidMount() {
    await this.props.authService.login('Corporate');
  }

  render() {
    return <div>Redirecting...</div>;
  }
}
