import Modal from '@components/modal/Modal';
import React from 'react';
import ServiceUnavailable from '@components/modal/subcomponents/ServiceUnavailable';

const ModalServiceUnavailable = (props) => {
  return (
    <Modal id={props.id} isActive={props.isActive} onClose={props.onClose} heading={props.heading}>
      {props.isActive && <ServiceUnavailable />}
    </Modal>
  );
};

export default ModalServiceUnavailable;
