import React, { Component } from 'react';
import { isWarrantyLinkBroker } from '@services/helpers/profile.offices.helper';
import AdminApi from '@apis/admin.api';
import { AuthType, UserDetails } from '@app/models/profile.model';
import { EditButton } from '@components/button/EditButton';
import ProfileApi from '@apis/profile.api';
import { isEmailValid } from '@services/validation/ValidationRules';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import ModalSiteAdminRetrievePasswordResetLink from '@components/modal/ModalSiteAdminRetrievePasswordResetLink';
import { Button, Input, Select } from '@ftdr/blueprint-components-react';
import ModalChoice from '@components/modal/ModalChoice';
import msgs from '@app/locales/en';
import { accountToOfficeTypeDictionary } from '@constants/dictionaries';

interface state {
  passwordLink: string;
  isModalActive: boolean;
  isEditingAgent: boolean;
  newAgent: string;
  isEditingEmail: boolean;
  newEmail: string;
  isNewEmailValid: boolean;
  isEditingUserType: boolean;
  newUserType: string;
  showDeleteModal: boolean;
}

interface props {
  user: UserDetails;
  updateUser: any;
  removeUser: any;
}

export default class ViewUserDetails extends Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      passwordLink: '',
      isModalActive: false,
      isEditingAgent: false,
      newAgent: props.user.agentID,
      isEditingEmail: false,
      newEmail: props.user.email,
      isNewEmailValid: true,
      isEditingUserType: false,
      newUserType: props.user.userType,
      showDeleteModal: false,
    };
  }

  userTypeSelectOptions = (oldType: string) => {
    const officeType = accountToOfficeTypeDictionary[oldType];
    return Object.keys(accountToOfficeTypeDictionary)
      .filter((type) => accountToOfficeTypeDictionary[type] == officeType)
      .map((type) => {
        return {
          id: `user-type-${type}`,
          label: type,
          value: type,
        };
      });
  };

  getPasswordResetLink = () => {
    AdminApi.getPasswordRestLink(this.props.user.email, this.props.user.brand).then((res) => {
      this.setState({ passwordLink: res, isModalActive: true });
    });
  };

  toggleModal = () => {
    this.setState({ isModalActive: !this.state.isModalActive });
  };

  handleAgentChange = (event) => {
    this.setState({ newAgent: event.target.value });
  };

  toggleEditAgent = () => {
    this.setState({
      newAgent: this.props.user.agentID,
      isEditingAgent: !this.state.isEditingAgent,
    });
  };

  saveAgentId = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      roleID: this.state.newAgent,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.agentID = this.state.newAgent;
          this.props.updateUser(tempUser);
        }
        this.toggleEditAgent();
      })
      .catch((e) => {
        this.toggleEditAgent();
        console.error(e);
      });
  };

  handleUserTypeChange = (event) => {
    this.setState({ newUserType: event.value });
  };

  toggleEditUserType = () => {
    this.setState({
      newUserType: this.props.user.userType,
      isEditingUserType: !this.state.isEditingUserType,
    });
  };

  saveUserType = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      roleIDType: this.state.newUserType,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.userType = this.state.newUserType;
          this.props.updateUser(tempUser);
        }
        this.toggleEditUserType();
      })
      .catch((e) => {
        const errors = e.response.data.errors?.map((e) => e.message).join(', ');
        useGlobalAlert().addErrorToQueue({
          message: `The user type could not be updated due to: ${errors}`,
        });
      });
  };

  handleEmailChange = (event) => {
    this.setState({
      newEmail: event.target.value,
      isNewEmailValid: isEmailValid(event.target.value),
    });
  };

  toggleEditEmail = () => {
    this.setState({
      newEmail: this.props.user.email,
      isEditingEmail: !this.state.isEditingEmail,
      isNewEmailValid: isEmailValid(this.props.user.email),
    });
  };

  saveEmail = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      email: this.state.newEmail,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.email = this.state.newEmail;
          this.props.updateUser(tempUser);
        }
        this.toggleEditEmail();
      })
      .catch((e) => {
        const errors = e.response.data.errors?.map((e) => e.message).join(', ');
        useGlobalAlert().addErrorToQueue({
          message: `The users email could not be updated due to: ${errors}`,
        });
      });
  };

  deleteUser = () => {
    AdminApi.deleteFusionAuth(this.props.user).then((success) => {
      this.setState({ showDeleteModal: false });

      if (success) {
        useGlobalAlert().addSuccessToQueue(msgs.DELETE_FA_USER_SUCCESS(this.props.user.faEmail));
        this.props.removeUser(this.props.user.userID);
      } else {
        useGlobalAlert().addErrorToQueue(msgs.DELETE_FA_USER_FAILURE(this.props.user.faEmail));
      }
    });
  };

  render() {
    const { user } = this.props;
    const {
      isModalActive,
      passwordLink,
      isEditingAgent,
      newAgent,
      isEditingEmail,
      newEmail,
      isNewEmailValid,
      isEditingUserType,
      newUserType,
    } = this.state;

    const userTypeOptions = this.userTypeSelectOptions(newUserType);
    const selectedUserTypeOption = userTypeOptions.find((option) => option.value == newUserType);

    return (
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-3/4 md-max:mb-4 px-4">
          <h1 className="h3" id="orderDetail.header.address">
            {user.firstName} {user.lastName}
          </h1>
          <Button
            variant="filled"
            id="pwd-reset"
            label="PW Reset Link"
            size="small"
            onClick={this.getPasswordResetLink}
          />
          {!user.profileID && user.authIDType === AuthType.FusionAuth && user.authID && (
            <Button
              variant="filled"
              id="delete-user"
              label="Delete User"
              size="small"
              color="error"
              className="ml-4"
              onClick={() => this.setState({ showDeleteModal: true })}
            />
          )}
          <p className="md:mb-1">
            <div className="w-full pb-2 flex">
              <Input
                type="text"
                id="user_email"
                name="Email"
                label="Email"
                placeholder="Email"
                onChange={this.handleEmailChange}
                onBlur={null}
                value={newEmail}
                disabled={!isEditingEmail}
                error={isNewEmailValid ? '' : 'Valid email required'}
                formField={true}
                formFieldMessageId="email-error"
                className="min-w-82"
              />

              <EditButton
                id="wb_admin-email-edit"
                isEditing={isEditingEmail}
                isDisabled={false}
                onClick={this.toggleEditEmail}
                outerClassName="mt-5 ml-4"
              />
              {isEditingEmail && (
                <div className="ml-6 mt-5">
                  <Button
                    className="mt-5"
                    variant="outlined"
                    id="wb_admin-email-save"
                    label="Save"
                    size="small"
                    onClick={this.saveEmail}
                    disabled={!isNewEmailValid}
                  />
                </div>
              )}
            </div>
            <br />
            Web ID: {user.faEmail}
            <br />
            Verified: {user.emailVerified.toString()}
            <br />
            Phone Number: {user.phone}
            <br />
            <div className="w-full flex">
              <Select
                label="User Type"
                formField={true}
                placeholder="User Type"
                selected={selectedUserTypeOption}
                onSelect={this.handleUserTypeChange}
                options={userTypeOptions}
                disabled={!isEditingUserType}
              />

              <EditButton
                id="wb_admin-usertype-edit"
                isEditing={isEditingUserType}
                isDisabled={false}
                onClick={this.toggleEditUserType}
                outerClassName="mt-5 ml-4"
              />
              {isEditingUserType && (
                <div className="ml-6 mt-5">
                  <Button
                    className="mt-5"
                    variant="outlined"
                    id="wb_admin-save-usertype"
                    label="Save"
                    size="small"
                    onClick={this.saveUserType}
                  />
                </div>
              )}
            </div>
            <br />
            User ID: {user.userID}
            <br />
            <div className="w-full flex">
              <Input
                type="text"
                id="wb_admin-agent"
                name="Agent ID"
                placeholder="Agent ID"
                label="Agent ID"
                onChange={this.handleAgentChange}
                onBlur={null}
                value={newAgent}
                disabled={!isEditingAgent}
                formField={true}
                className="min-w-82"
              />
              <EditButton
                id="wb_admin-agent-edit"
                isEditing={isEditingAgent}
                isDisabled={false}
                onClick={this.toggleEditAgent}
                outerClassName="mt-5 ml-4"
              />
              {isEditingAgent && (
                <div className="ml-6 mt-5">
                  <Button
                    className="mt-5"
                    variant="outlined"
                    id="wb_admin-save-agent"
                    label="Save"
                    size="small"
                    onClick={this.saveAgentId}
                  />
                </div>
              )}
            </div>
          </p>

          <p id="offices" className="mb-1">
            Offices:
            <ul>
              {user.offices &&
                user.offices.map((office) => (
                  <li key={office.id} style={{ paddingLeft: '10px' }}>
                    {office.type} {office.id}
                  </li>
                ))}
            </ul>
          </p>

          <p className="mb-1">
            Registration Date: {user.registrationDate} <br />
            Brand: {user.brand} <br />
            Last Active: {user.lastActiveDate}
            <br />
            Last Password Update: {user.lastPasswordUpdate || 'None'}
            <br />
            Warranty Link Broker: {isWarrantyLinkBroker(user as any).toString()}
            <br />
          </p>
        </div>
        <ModalChoice
          id="delete-user-modal"
          heading="Are you sure you want to delete this user’s account?"
          onConfirm={this.deleteUser}
          onClose={() => this.setState({ showDeleteModal: false })}
          isActive={this.state.showDeleteModal}
          yesText="Delete it"
          noText="Keep it"
        />
        <ModalSiteAdminRetrievePasswordResetLink
          isActive={isModalActive}
          onClose={this.toggleModal}
          passwordLink={passwordLink}
        />
      </div>
    );
  }
}
