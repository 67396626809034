import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '@utils';
import NotFound from './NotFound';

type SearchProps = {
  options: any;
  optionComponent?: JSX.Element;
  query?: string;
  fullWidth?: boolean;
  hideDropdown?: boolean;
  inputForm: JSX.Element;
};

const Search = ({
  options,
  optionComponent,
  query,
  fullWidth,
  hideDropdown,
  inputForm,
}: SearchProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (query) {
      setIsOpen(true);
    }
  }, [options]);

  useEffect(() => {
    if (hideDropdown) {
      setIsOpen(false);
    }
  }, [hideDropdown]);
  const optionListWidth = divRef?.current?.offsetWidth;

  return (
    <div ref={divRef} className={fullWidth ? 'w-full max-w-full' : ''}>
      {inputForm}
      <ul
        style={{
          overflowY: 'scroll',
          maxHeight: '400px',
          width: optionListWidth,
          zIndex: 39,
        }}
        id="property-search-list"
        className={classNames(['absolute bg-white select-options-list', !isOpen && 'hidden'])}
      >
        {options.length > 0 ? optionComponent : <NotFound />}
      </ul>
    </div>
  );
};

export default Search;
