import React from 'react';
import { formatDate } from '@helpers/utils';
import { CLOSING_DATE_RANGE } from '@constants/formField-constants';
import { DateTimeInput } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';

export interface InvalidClosingDateProps {
  id?: string;
  listingCreationDate?: Date;
  existingClosingDate: Date;
  selectedClosingDate: Date;
  onSelectClosingDate: (date: Date) => void;
}

const InvalidClosingDate: React.FC<InvalidClosingDateProps> = (props) => (
  <div>
    <div id={`${props.id}--summary`}>
      The closing date entered,
      <span id={`${props.id}--existing-closing-date`} className="font-bold">
        {' '}
        {formatDate(props.existingClosingDate)}{' '}
      </span>
      is not valid. The closing date should be within
      <span id={`${props.id}--acceptable-closing-date-min`} className="font-bold">
        {' '}
        {formatDate(CLOSING_DATE_RANGE(props.listingCreationDate).min)}{' '}
      </span>
      and
      <span id={`${props.id}--acceptable-closing-date-min`} className="font-bold">
        {' '}
        {formatDate(CLOSING_DATE_RANGE(props.listingCreationDate).max)}{' '}
      </span>
      .
    </div>
    <div id={`${props.id}--instruction`}>Please enter a valid date.</div>
    <hr />
    <div id={`${props.id}--form`}>
      <DateTimeInput
        id={`${props.id}--form--closing-date`}
        name="projectedClosingDate"
        label={ORDER_FORM_FIELDS.PROJECTED_CLOSING_DATE}
        required={true}
        onlyDate={true}
        datePicker={true}
        autoComplete="off"
        selectedDate={props.selectedClosingDate}
        onDateSelect={props.onSelectClosingDate}
        minDate={CLOSING_DATE_RANGE(props.listingCreationDate).min}
        maxDate={CLOSING_DATE_RANGE(props.listingCreationDate).max}
      />
    </div>
  </div>
);

InvalidClosingDate.defaultProps = {
  id: 'invalid-closing-date',
  listingCreationDate: null,
};

export default InvalidClosingDate;
