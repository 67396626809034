import React, { useState } from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button, IconRefreshDouble } from '@ftdr/blueprint-components-react';
import { DASHBOARD_VERIFY_WLK } from '@constants/ga-events.constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { toFormattedNumber } from '@helpers/utils';
import DrawerOrdersWLKVerification from '@components/drawer/DrawerOrdersWLKVerification';
import { hasContractPermissions } from '@helpers/permissions.utils';
import { Permissions } from '@constants/dictionaries';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

const CardDashBoardActionWLKVerification = (props: ICardDashBoardActionProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [canEditWLKContracts, setCanEditWLKContracts] = useState(false);
  const wlkRequiredPermissions = [Permissions.WLKSubmit];

  const handleVerifyWLK = () => {
    fireGAEvent(DASHBOARD_VERIFY_WLK);
    setIsDrawerOpen(true);
    hasContractPermissions(wlkRequiredPermissions).then((result) => {
      setCanEditWLKContracts(result);
    });
  };

  return (
    <>
      {!props.hide && (
        <>
          <CardDashboardAction
            isManualFetch={props.isManualFetch}
            fetchCount={props.fetchCount}
            type={
              props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention
            }
            heading={props.count === null ? null : toFormattedNumber(props.count)}
            subHeading="Pending WarrantyLink Verification"
            action={
              <Button
                label="Verify Orders"
                size="medium"
                startIcon={<IconRefreshDouble />}
                disabled={!props.count}
                onClick={() => handleVerifyWLK()}
              />
            }
          />

          <DrawerOrdersWLKVerification
            isActive={isDrawerOpen}
            onUpdateOrderCount={props.updateCount}
            onClose={() => setIsDrawerOpen(false)}
            initialTotalContracts={props.count}
            canEditWLKContracts={canEditWLKContracts}
          />
        </>
      )}
    </>
  );
};

export default CardDashBoardActionWLKVerification;
