/** ===============================================================
 *   COVERAGE DEPENDENCIES RULES
 *   Coverages are enabled or disabled depending on if certain
 *   coverages are selected or unselected. This logic consolidates
 *   this logic here.
 *
 *   THIS LOGIC IS DEPRECATED ON 4/1/22 WITH NEW PRODUCTS.
 *   THIS WOULD ONLY BE APPLICABLE FOR HSA PRODUCTS AND AHS PRODUCTS
 *     BEFORE 4/1/22 DATE.
 *  =============================================================== */

/**
 * Special coverage selection business rules
 */
interface CoverageDependencyRule {
  /** General description for dev's sake */
  description: string;
  /** Coverages that cannot be selected until one of the dependent coverages are added */
  addOnCoverageIds: number[];
  /** Coverages that must be selected in order to enable the others */
  dependentCoverageIds: number[];
  /** Explains the state the add on coverage ids should be when no dependent coverages are selected */
  addOnDisabled: boolean;
}

/**
 * Legacy system uses hard coded IDs for their checks.
 * Leaving the logic here for now.
 */
export class CoverageDependencyHandler {
  // Special business rules by ID from legacy
  private CoverageIDSets = {
    /**
     * Coverage IDs with seller's coverage.
     * Has "Seller" in description.  "Seller AC, Ductwork, Heat".
     * But there are other coverages with "Seller" that are not on this list.
     */
    SELLERS_COVERAGE_IDS: [66],

    /**
     * Group IDS with seller's coverage
     * Has "SELLER" in description (SELLER HEAT & AIR/HEAT PUMP)
     * No other groups than these exist so far.
     */
    SELLERS_COVREAGE_GROUP_IDS: [136972, 137262, 137042, 137012, 137062, 137132],
    // 5-STAR, 7-STAR groups
    // Has "STAR", however IDs are not unique (can have 2 rows with "5 STAR UPGRADE" and "7 STAR UPGRADE" both)
    // and 137032 has "SILVER UPGRADE"
    // No other group coverages have "UPGRADE" than these
    N_STAR_UPGRADE_GROUP_IDS: [
      137002, 137002, 137022, 137032, 137032, 137052, 137072, 137072, 137092, 137112, 137112,
      137142, 137222, 137242, 137362, 137372,
    ],
  };

  private CoverageDependencyRules: CoverageDependencyRule[] = [
    {
      description: 'If any spa is added, then enable additional spa',
      dependentCoverageIds: [
        12, // Pool/Spa Common Equip
        19, // Pool/Inground Spa Shared Equipment
        300, // Inground Spa Only
        2911, // Spa Only
        104181, // Pool/Spa Common Equipment
        104471, // Spa Only
        114542, // Saltwater Pool & Inground Spa Shared Equipment
        114552, // Saltwater Pool & Spa Shared Equipment
      ],
      addOnCoverageIds: [
        305, // Inground Additional Spa
        104481, // Additional Spa
      ],
      addOnDisabled: false,
    },
    {
      description:
        'If any pool/inground spa with equipment selected, disable Pool/Inground Spa Only',
      dependentCoverageIds: [
        19, // Pool/Inground Spa Shared Equipment
        114542, // Saltwater Pool & Inground Spa Shared Equipment
        114522, // Saltwater Pool Equipment
      ],
      addOnCoverageIds: [
        300, // Inground Spa Only
        366, // Pool only
      ],
      addOnDisabled: true,
    },
    {
      description: 'If Pool/Inground Spa Only selected, disable pool/inground spa with equipment',
      dependentCoverageIds: [
        300, // Inground Spa Only
        366, // Pool only
      ],
      addOnCoverageIds: [
        19, // Pool/Inground Spa Shared Equipment
        114542, // Saltwater Pool & Inground Spa Shared Equipment
        114522, // Saltwater Pool Equipment
      ],
      addOnDisabled: true,
    },
  ];

  isSellersCoverageByCvgId(cvgId: string): boolean {
    return this.CoverageIDSets.SELLERS_COVERAGE_IDS.includes(Number(cvgId));
  }

  isSellersCoverageByGroupId(groupId: string): boolean {
    return this.CoverageIDSets.SELLERS_COVREAGE_GROUP_IDS.includes(Number(groupId));
  }

  /** for a given coverage id, search whether this coverage is an add on coverage dependent on another coverage.
   * If so, returns the dependency rules for the coverage to check the dependency rules. Otherwise, returns null. */
  getCoverageDependencyRuleByCvgId(cvgId: string | number): CoverageDependencyRule {
    return this.CoverageDependencyRules.find((cvgDependency) =>
      cvgDependency.addOnCoverageIds.includes(+cvgId),
    );
  }

  /** for a given coverage id and selected coverage ids, determine whether or not the coverage with the id should be disabled.
   * If so, returns true if the coverage should be disabled. When disabling, the coverage should be removed from selection as well.
   * */
  shouldCoverageBeDisabled(
    cvgId: string | number,
    selectedCoverageIds: string[] | number[],
  ): boolean {
    const rules = this.getCoverageDependencyRuleByCvgId(cvgId);
    if (rules) {
      const dependentCoverageSelected = selectedCoverageIds.some((id) =>
        rules.dependentCoverageIds.includes(+id),
      );
      return dependentCoverageSelected ? rules.addOnDisabled : !rules.addOnDisabled;
    }
    return false;
  }
}
