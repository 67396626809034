import {
  CustomerType,
  OrderAddressInfoFormData,
  OrderAgentInfoFormData,
  OrderClosingInfoFormData,
  OrderCustomerFormData,
  OrderOfficeAgentData,
  OrderPlanInfoFormData,
  OrderProductFiltersFormData,
  OrderPropertyInfoFormData,
  OrderSendDocumentsFormData,
} from '@app/models/order/order.model';
import { DEFAULT_AGE } from '@services/helpers';

export interface NewOrderContextData {
  initiatingAgent: OrderOfficeAgentData;
  cooperatingAgent: OrderOfficeAgentData;
  closingAgent: OrderOfficeAgentData;

  agentInfo: OrderAgentInfoFormData;
  addressInfo: OrderAddressInfoFormData;
  propertyInfo: OrderPropertyInfoFormData;
  planInfo: OrderPlanInfoFormData;
  productFilters: OrderProductFiltersFormData;
  buyerInfo: OrderCustomerFormData;
  coBuyerInfo: OrderCustomerFormData;
  sellerInfo: OrderCustomerFormData;
  coSellerInfo: OrderCustomerFormData;
  closingInfo: OrderClosingInfoFormData;
  sendDocuments: OrderSendDocumentsFormData;
}

/** data that are stored separately for reasons it being necessary for other operations */
export interface NewOrderMetaData {
  orderID: string;
  quoteID: string;

  /** used for step2. if set, then displayed to the user to select from. should be cleared when user leaves step2 */
  planReselectionReason: string;
}

export const defaultMetaData: NewOrderMetaData = {
  orderID: '',
  quoteID: '',
  planReselectionReason: '',
};

/** default context data should define. If we want to not default to any value, use null or blank string */
export const defaultContextData: NewOrderContextData = {
  initiatingAgent: {
    agentID: '',
    agentName: '',
    officeID: '',
    officeName: '',
    agentEmail: '',
    franchiseCode: '',
  },
  cooperatingAgent: {
    agentID: '',
    agentName: '',
    officeID: '',
    officeName: '',
    agentEmail: '',
    franchiseCode: '',
  },
  closingAgent: {
    agentID: '',
    agentName: '',
    officeID: '',
    officeName: '',
    agentEmail: '',
    franchiseCode: '',
  },
  agentInfo: {
    represents: null,
    selectROOption: false,
  },
  addressInfo: {
    zip: '',
    streetAddress: '',
    city: '',
    state: '',
    unit: '',
    hasVerified: false,
    addressUUID: '',
  },
  propertyInfo: {
    mlsNumber: '',
    age: DEFAULT_AGE,
    residenceType: '',
    squareFootage: null,
  },
  planInfo: {
    selectedPlanID: '',
    selectedPlanName: '',
    selectedPlanPrice: null,
    selectedPlanListingTermInDays: null,
    selectedOptionalCoverages: [],
    selectedGroupCoverages: [],
    pricingRequest: null,
    pricingResponse: null,
  },
  buyerInfo: {
    customerType: CustomerType.Buyer,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zip: '',
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    hasVerified: false,
    addressUUID: '',
  },
  coBuyerInfo: {
    customerType: CustomerType.CoBuyer,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zip: '',
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    hasVerified: false,
    addressUUID: '',
  },
  sellerInfo: {
    customerType: CustomerType.Seller,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zip: '',
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    hasVerified: false,
    addressUUID: '',
  },
  coSellerInfo: {
    customerType: CustomerType.CoSeller,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zip: '',
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    hasVerified: false,
    addressUUID: '',
  },
  closingInfo: {
    closingFileNumber: '',
    closingAgent: {
      officeID: '',
      franchiseCode: '',
      agentID: '',
      agentName: '',
      officeName: '',
      agentEmail: '',
    },
  },
  sendDocuments: {
    recipients: [],
  },
  productFilters: {
    acCoverage: null,
    sellersCoverage: null,
    term: null,
    projectedClosingDate: null,
  },
};
