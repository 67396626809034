import list from '@services/officelistdata.mock';
import * as app from '@app/models';
import { officeTypeDictionary } from '@constants/dictionaries';
import { alphabetizeBy, toTitleCase } from '@helpers/utils';
import { BaseAPI } from './_api';
import { Office, OfficeList, OfficeSearchRequest, OfficeTypeCode } from './models';

// TODO Cleanup
// TODO This class has multiple functions that do the same thing, just with different names
// This needs to be cleaned up and calls refactored to use the same function
export class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  /** Return a single office with the given id */
  // TODO: Have type param be more strongly typed
  public async searchOfficeById(
    id: string,
    type = app.OfficeType.RealEstate.toString(),
  ): Promise<app.Office> {
    return this.getOfficeList([{ id, type }]).then((res) => {
      return res?.offices?.[0] ? mapToAppOffice(res.offices[0]) : undefined;
    });
  }

  /** @deprecated
   * TODO: This doesn't seem to be used at all (correctly). Should be using the getOfficeList instead */
  public async getMyOffices(params: string[]): Promise<any> {
    return this.axios
      .get(`${this.getHost()}/office/list`, { params })
      .then((res) => {
        return res;
      })
      .catch(() => {
        return {
          offices: list,
        };
      });
  }

  private getUniqueOffices(offices: Office[]): Office[] {
    const result: { [key: string]: Office } = offices.reduce(
      (data: { [key: string]: Office }, office: Office) => {
        if (!data[office.id]) {
          data[office.id] = office;
        }
        return data;
      },
      {},
    );

    return Object.values(result);
  }

  public async getOfficeList(offices, getCounts = false): Promise<OfficeList> {
    offices.forEach((office) => {
      office.type = officeTypeDictionary[office.type];
    });

    const body = { requestedOffices: offices, includeCounts: getCounts };
    return this.axios
      .post<any, OfficeList>(`${this.getHost()}/office/getList`, body)
      .then((res) => {
        if (res && res.offices) {
          res.offices = this.getUniqueOffices(res.offices).map(translateOffice);
        }
        return res;
      })
      .catch((e) => {
        console.error(`Error when fetching offices by list of ids: ${e}`);
        return { offices: [] };
      });
  }

  public async getAppOfficeList(offices): Promise<app.Office[]> {
    return (await this.getOfficeList(offices)).offices.map(mapToAppOffice);
  }

  public async searchOffice(reqObj: OfficeSearchRequest): Promise<OfficeList> {
    const params = reqObj;
    if (!params.type) {
      params.type = 'RE';
    }
    return this.axios
      .get<any, OfficeList>(`${this.getHost()}/office/search`, { params })
      .then((res) => {
        if (res) {
          if (res.offices.length) {
            res.offices = res.offices.map(translateOffice);
            res.offices = res.offices.sort(alphabetizeBy((office) => office.name.toUpperCase()));
            return res;
          }
        }
      })
      .catch((err) => {
        return null;
      });
  }
}

const OfficeApi = new API();
export default OfficeApi;

/** Perform any translations on the data, such as titlecase */
export function translateOffice(office: Office): Office {
  if (office) {
    office.address = office.address && {
      ...office.address,
      address1: toTitleCase(office.address.address1),
      address2: toTitleCase(office.address.address2),
      city: toTitleCase(office.address.city),
    };
    office.name = toTitleCase(office.name);
    office.warrantyLinkStatus = toTitleCase(office.warrantyLinkStatus);
  }
  return office;
}

function mapToAppOffice(office: Office): app.Office {
  office = translateOffice(office);
  return {
    id: office.id,
    name: office.name,
    address: {
      streetAddress: `${office.address.address1} ${office.address.address2}`.trim(),
      city: office.address.city,
      state: office.address.state,
      zip: office.address.zip,
    },
    contact: {
      phones: {
        fax: office.phones.fax,
        office: office.phones.office,
      },
    },
    details: {
      compositeSalesRank: office.compositeSalesRank,
      franchiseCode: office.franchiseCode,
    },
    meta: {
      type: getAppOfficeType(office.type),
    },
    type: getAppOfficeType(office.type),
  };
}

function getAppOfficeType(officeType: OfficeTypeCode): app.OfficeType {
  switch (officeType) {
    case undefined:
      return undefined;
    case OfficeTypeCode.RealEstate:
      return app.OfficeType.RealEstate;
    case OfficeTypeCode.ClosingCompany:
      return app.OfficeType.ClosingCompany;
    default:
      throw TypeError(`Unknown office type: ${officeType}`);
  }
}
