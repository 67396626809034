import React from 'react';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { WorkflowStepStatus } from '@ftdr/blueprint-components-react';

interface Props {
  currentStep: NewOrderStep;
}

const StepName = {
  [NewOrderStep.AgentPropertyInfo]: 'Agent & Property Info',
  [NewOrderStep.PlanCoverage]: 'Plans & Coverage',
  [NewOrderStep.BuyerSellerInfo]: 'Buyer & Seller Info',
  [NewOrderStep.ReviewSubmit]: 'Send Documents',
};

const NewOrderStepWayfinder: React.FC<Props> = ({ currentStep }) => {
  const stepLabels = Object.values(StepName);

  const getStepStatus = (stepIndex: number): WorkflowStepStatus => {
    if (currentStep === stepIndex) {
      return 'in-progress';
    }
    if (currentStep > stepIndex) {
      return 'completed';
    }
    return 'not-started';
  };

  return (
    <div className="hidden md:flex">
      <div className="wayFinder">
        <div className={`steps-container relative flex items-center justify-between`}>
          {stepLabels.map((step, idx) => (
            <div key={step} className={`stepStatusIndicator ${getStepStatus(idx)}`}>
              <div className={`items-center justify-center stepLabel`}>{step}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewOrderStepWayfinder;
