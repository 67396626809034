import React, { useContext, useState } from 'react';
import LogoAHS from '@static/logo.svg';
import LogoHSA from '@static/logo-hsa.png';
import { useThemeContext } from '@ThemeContext';
import { Button } from '@ftdr/blueprint-components-react';
import { hardLogout } from '@helpers/auth.utils';
import Path from '@constants/paths';
import { PROFILE_ERROR } from '@constants/formField-constants';
import { isSmallView } from '@utils';

interface Props {}

const ProfileError: React.FC<Props> = () => {
  const theme = useThemeContext();

  const isSmall = isSmallView();

  return (
    <div id="profileError" className="container pt-16">
      <div className="flex justify-center">
        <img
          data-testid={`logo-${theme}`}
          className="h-16"
          src={theme === 'hsa' ? LogoHSA : LogoAHS}
        />
      </div>
      <h3 className="text-center mt-14 h3">An Error Has Occurred</h3>
      <p className="mt-5 text-center">
        {PROFILE_ERROR}
        <a className="underline" href={Path.ContactUs}>
          support team
        </a>
        .
      </p>

      <div className="flex items-center justify-center mt-10">
        <Button
          id="verify-logout-btn"
          variant="outlined"
          size="small"
          onClick={hardLogout}
          label="Back to Sign Up"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
        />
      </div>
    </div>
  );
};

export default ProfileError;
