import React, { useEffect, useState } from 'react';
import { classNames } from '@utils';

// Components
import CardNewOrder from '@components/card/CardNewOrder';
import { getBrand } from '@helpers/brand.utils';
import useForm from '@helpers/UseForm';
import { API as _PropertyApi } from '@services/apis/property.api';
import ModalIneligibleDwellingSize from '@components/modal/ModalIneligibleDwellingSize';
import {
  DEFAULT_AGE,
  getSquareFootageRangeValue,
  isAgeNewConstruction,
  NEW_CONSTRUCTION_AGE,
  SQUARE_FOOTAGE_VALUES,
} from '@services/helpers';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import * as c from '@constants/formField-constants';
import {
  NEW_ORDER_LABELS,
  ORDER_CARD_TITLE,
  ORDER_FORM_FIELDS,
} from '@constants/newOrder-constants';
import {
  IconCheckCircledCheckedFilled,
  Input,
  RadioGroup,
  Select,
  Text,
} from '@ftdr/blueprint-components-react';

import { didPropertyDetailsChange } from '@helpers/order.utils';
import {
  getFormInputErrorId,
  getFormRadioErrorId,
  getFormSelectErrorId,
} from '@storybook/addon-links';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__PROPERTY_DETAILS_START } from '@constants/ga-events.constants';
import ModalNoPlansFound from '@components/modal/ModalNoPlansFound';

export const validateCardNewOrderPropertyDetails = (values, isTouched) => {
  const errors = {};

  if (!isTouched) return errors;

  if (isTouched.residenceType) {
    if (!values.residenceType) {
      errors.residenceType = c.RESIDENT_TYPE_REQUIRED;
    }
  }

  if (isTouched.squareFootage) {
    if (!values.squareFootage) {
      errors.squareFootage = c.SQUARE_FOOTAGE_REQUIRED;
    }
  }

  if (isTouched.newConstruction || isTouched.notNewConstruction) {
    if (!values.newConstruction && !values.notNewConstruction) {
      errors.newConstruction = c.NEW_CONSTRUCTION_REQUIRED;
    }
  }

  return errors;
};

const CardNewOrderPropertyDetails = ({ isEditing, setIsEditing, ...props }) => {
  const PropertyApi = new _PropertyApi();
  const brandLocation = getBrand();
  const { values, errors, handleBlur, handleSubmit, setValues, setErrors, forceTouchAll } = useForm(
    validateCardNewOrderPropertyDetails,
    brandLocation === 'AHS'
      ? {
          residenceType: '',
          squareFootage: '',
          newConstruction: '',
          notNewConstruction: '',
          propertyChanged: false,
        }
      : {
          residenceType: '',
          newConstruction: '',
          notNewConstruction: '',
          propertyChanged: false,
        },
  );
  const [showPlansUnavailableModal, setShowPlansUnavailableModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [loadingPropertyDetails, setLoadingPropertyDetails] = useState(false);
  const { showSpinner } = useGlobalOverlaySpinner();

  // Condition to check AHS or HSA domain. We only allow square footage in AHS for now.
  const allowSquareFootage = brandLocation === 'AHS';
  const _squareFootageValues =
    brandLocation === 'AHS'
      ? {
          less: 'Less than 5,000 sq. ft.',
          between: '5,000 to 9,999 sq. ft.',
          above: '10,000 sq. ft. and above',
        }
      : {};
  const squareFootageValues = Object.keys(_squareFootageValues).reduce((options, key) => {
    options.push({
      label: _squareFootageValues[key],
      value: key,
    });
    return options;
  }, []);

  const _residenceTypeValues = {
    singleFamilyResidence: 'Single Family Residence',
    condominium: 'Condominium',
    townhouse: 'Townhouse',
    duplex: 'Duplex',
    triplex: 'Triplex',
    fourplex: 'Fourplex',
    mobileHome: 'Mobile Home',
  };

  const residenceTypeValues = Object.keys(_residenceTypeValues).reduce((options, key) => {
    options.push({
      label: _residenceTypeValues[key],
      value: key,
    });
    return options;
  }, []);

  const newConstructionValues = [
    {
      label: 'Yes',
      value: 'newConstruction',
    },
    {
      label: 'No',
      value: 'notNewConstruction',
    },
  ];

  const [newConstructionOptionValues, setNewConstructionOptionValues] = useState({
    newConstruction: false,
    notNewConstruction: false,
  });

  useEffect(() => {
    if (props.orderId) {
      fireGAEvent(ORDER__PROPERTY_DETAILS_START(props.orderId));
    }
  }, [props.orderId]);

  async function getPropertyDetails(address, existingValuesToSet) {
    try {
      const propertyDetails = await PropertyApi.getPropertyDetailsByAddress(address);

      if (propertyDetails) {
        console.log('property data returned, pre-filling property');
        const isNewConstruction = isAgeNewConstruction(propertyDetails.age);

        setNewConstructionOptionValues({
          newConstruction: isNewConstruction,
          notNewConstruction: !isNewConstruction,
        });

        const squarefootageValue = getSquareFootageRangeValue(propertyDetails.squareFootage);
        setValues({
          ...existingValuesToSet,
          residenceType: dwellingTypeDictionary[propertyDetails.typeOfResidence],
          newConstruction: isNewConstruction,
          notNewConstruction: !isNewConstruction,
          age: isNewConstruction ? NEW_CONSTRUCTION_AGE : DEFAULT_AGE,
          squareFootage: squarefootageValue,
        });

        // if square footage is above, then display modal
        if (brandLocation === 'AHS' && squarefootageValue === 'above') {
          setIsModalActive(true);
        } else if (brandLocation === 'HSA' && propertyDetails.squareFootage > 15000) {
          setIsModalActive(true);
        }
      }
    } catch (error) {
      setLoadingPropertyDetails(false);
      console.error('Unable to fetch property details', error);
      // we want to default to not new construction if it is not set yet
      const { newConstruction, notNewConstruction } = values;
      if (!newConstruction && !notNewConstruction) {
        console.log('new construction not set, defaulting to "NO"');
        setNewConstruction(false);
      }
    } finally {
      setLoadingPropertyDetails(false);
    }
  }

  useEffect(() => {
    let valuesForPropertyUpdate = { ...values };
    if (props.values) {
      valuesForPropertyUpdate = { ...props.values };
      setValues(valuesForPropertyUpdate);

      if (props.values.newConstruction || props.values.notNewConstruction) {
        setNewConstructionOptionValues({
          newConstruction: props.values.newConstruction,
          notNewConstruction: props.values.notNewConstruction,
        });
      }
    }

    // this if condition (isEditing) may need to be wrapped around the above as well
    if (isEditing) {
      if (props.propertyAddr) {
        // Do not get property details again if user has made saved changes already.
        if (!props.values) {
          setLoadingPropertyDetails(true);
          getPropertyDetails(props.propertyAddr, props.values);
        } else {
          setLoadingPropertyDetails(false);
          console.log('ignoring address update, as changes have been made');
        }
      }
    }
  }, [isEditing, props.values, props.propertyAddr]);

  /** if not editing anymore, clear state */
  useEffect(() => {
    if (!isEditing) {
      setErrors({});
    }
  }, [isEditing]);

  const onNewConstructionOptionChange = (value) => {
    const event = {
      target: {
        name: value,
        value,
        checked: true,
      },
    };

    handleBlur(event); // re-run validation to clear error msg
    const update = {
      newConstruction: false,
      notNewConstruction: false,
      [event.target.name]: event.target.checked,
    };
    setNewConstruction(update.newConstruction);
  };

  /**
   * updates the new construction radio selection and the form states
   * @param isNewConstruction
   */
  const setNewConstruction = (isNewConstruction) => {
    const update = {
      newConstruction: isNewConstruction,
      notNewConstruction: !isNewConstruction,
    };
    setNewConstructionOptionValues(update);

    setValues({
      ...values,
      ...update,
      age: update.newConstruction ? NEW_CONSTRUCTION_AGE : DEFAULT_AGE,
      typeOfResidence: values.residenceType,
    });
  };

  const handlesquarefootageClose = (squareFootageValue) => {
    console.log(squareFootageValue);

    setValues({
      ...values,
      squareFootage:
        brandLocation === 'AHS'
          ? getSquareFootageRangeValue(squareFootageValue)
          : squareFootageValue,
    });
    setIsModalActive(false);
  };

  const onMLSChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onResidenceTypeSelect = (item) => {
    handleBlur({
      target: {
        name: 'residenceType',
        value: item.value,
      },
    });
  };

  const onSquareFootageSelect = (item) => {
    handleBlur({
      target: {
        name: 'squareFootage',
        value: item.value,
      },
    });
    if (item.value === 'above') {
      setIsModalActive(true);
    }
  };

  const handleStartOver = () => {
    setIsModalActive(false);
    setValues({
      ...values,
      squareFootage: '',
    });
  };

  const handleSave = (event) => {
    const { hasErrors } = forceTouchAll();
    if (hasErrors) {
      setIsCollapsed(false);
      setIsEditing(true);
      event.preventDefault(); // Inside handleSubmit() already, but need to put it here too for now
      return;
    }

    handleSubmit(event);
    // checking is object empty for errors and values objects.
    if (!Object.keys(errors).length && Object.keys(values).length > 1) {
      let propertyChanged = false;
      setIsCollapsed(!isCollapsed);
      setIsEditing(false);

      let updatedPropertyDetailsValues = { ...values, propertyChanged };

      if (props.values) {
        const savedProperty = props.values;
        updatedPropertyDetailsValues.propertyChanged = didPropertyDetailsChange(
          values,
          savedProperty,
        );
        setValues(updatedPropertyDetailsValues);
        props.handleSubmit(updatedPropertyDetailsValues);
      } else {
        props.handleSubmit(values);
      }

      props.setValues({ propertyDetails: updatedPropertyDetailsValues });
    } else {
      setIsCollapsed(false);
      setIsEditing(true);
    }
  };

  const data = {
    values,
    setOptionValues: setNewConstructionOptionValues,
    setValues,
    handleSubmit: handleSave,
    optionValues: newConstructionOptionValues,
  };

  useEffect(() => {
    if (props.isRealtorOwned && newConstructionOptionValues.newConstruction === true) {
      setShowPlansUnavailableModal(true);
    }
  }, [loadingPropertyDetails]);

  return (
    <>
      <CardNewOrder
        title={ORDER_CARD_TITLE.PROPERTY_DETAILS}
        isEditing={isEditing}
        toggleEditing={() => setIsEditing(!isEditing)}
        isCollapsed={isCollapsed}
        toggleCollapsed={() => {
          setIsCollapsed(!isCollapsed);
          setIsEditing(false);
        }}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        onCancel={props.onCancel}
        data={data}
        cancelModal={props.cancelModal}
      >
        {loadingPropertyDetails
          ? showSpinner(true, 'Loading Property Details')
          : showSpinner(false)}
        {!isEditing ? (
          <div
            className={classNames([
              'sm:flex sm:flex-1',
              props.isSmall && 'sm:-mx-4',
              isCollapsed && 'xs-max:hidden',
            ])}
          >
            <div className="sm:w-1/3 sm:px-4">
              <Text variant="label">{ORDER_FORM_FIELDS.RESIDENCE_TYPE}</Text>
              <Text id="card-new-order-property_details__new-construction-text">
                {_residenceTypeValues[values.residenceType]}
                {values.newConstruction && (
                  <div className="flex items-center space-x-2">
                    <IconCheckCircledCheckedFilled color="interactive" />
                    <Text>{ORDER_FORM_FIELDS.NEW_CONSTRUCTION}</Text>
                  </div>
                )}
              </Text>
            </div>

            {brandLocation === 'AHS' && (
              <div className="sm:w-1/3 xs-max:mt-3">
                <Text variant="label">{ORDER_FORM_FIELDS.SQUARE_FOOTAGE}</Text>
                <Text id="card-new-order-property_details__square-footage-text">
                  {_squareFootageValues[values.squareFootage]}
                </Text>
              </div>
            )}

            <div className="sm:w-1/3 xs-max:mt-3">
              <Text variant="label">{ORDER_FORM_FIELDS.MLS}</Text>
              <Text
                style={{ overflowWrap: 'break-word' }}
                id="card-new-order-property_details__mls-number-text"
              >
                {values.mlsNumber}
              </Text>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap -mx-4">
            <div className="w-full sm:w-1/2 px-4 py-2">
              <Select
                formField={true}
                formFieldMessageId={getFormSelectErrorId('wb_pdResidenceType')}
                id="wb_pdResidenceType"
                name="residenceType"
                label={ORDER_FORM_FIELDS.RESIDENCE_TYPE}
                placeholder={ORDER_FORM_FIELDS.RESIDENCE_TYPE__PLACEHOLDER}
                required={true}
                options={residenceTypeValues}
                selected={
                  values.residenceType
                    ? residenceTypeValues.find((o) => o.value === values.residenceType)
                    : null
                }
                onSelect={onResidenceTypeSelect}
                error={errors.residenceType}
                disabled={values.isEditing}
              />
            </div>

            {allowSquareFootage && (
              <div className="w-full sm:w-1/2 px-4 py-2">
                <Select
                  formField={true}
                  formFieldMessageId={getFormSelectErrorId('wb_pdSquareFootage')}
                  id="wb_pdSquareFootage"
                  name="squareFootage"
                  label={ORDER_FORM_FIELDS.SQUARE_FOOTAGE}
                  placeholder={ORDER_FORM_FIELDS.SQUARE_FOOTAGE__PLACEHOLDER}
                  required={true}
                  options={squareFootageValues}
                  selected={
                    values.squareFootage
                      ? squareFootageValues.find((o) => o.value === values.squareFootage)
                      : null
                  }
                  onSelect={onSquareFootageSelect}
                  error={errors.squareFootage}
                  disabled={values.isEditing}
                />
              </div>
            )}

            <div className="w-full sm:w-1/2 px-4 py-2">
              <Input
                formField={true}
                formFieldMessageId={getFormInputErrorId('wb_pdMLSNumber')}
                id="wb_pdMLSNumber"
                name="mlsNumber"
                label={ORDER_FORM_FIELDS.MLS}
                labelInfo={NEW_ORDER_LABELS.HELP_TEXT__OPTIONAL}
                placeholder={ORDER_FORM_FIELDS.MLS__PLACEHOLDER}
                autoComplete="off"
                value={values.mlsNumber || ''}
                maxLength={c.MLS_NUMBER_MAX_LENGTH}
                onChange={onMLSChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="w-full px-4 py-2 -mb-1">
              <RadioGroup
                formField={true}
                formFieldMessageId={getFormRadioErrorId('wb_pdNewConstruction')}
                id="wb_pdNewConstruction"
                name="newConstruction"
                label={ORDER_FORM_FIELDS.NEW_CONSTRUCTION__QUESTION}
                labelInfo={NEW_ORDER_LABELS.HELP_TEXT__OPTIONAL}
                radios={newConstructionValues}
                value={Object.keys(newConstructionOptionValues).find(
                  (k) => newConstructionOptionValues[k] === true,
                )}
                onChange={onNewConstructionOptionChange}
                error={!values.newConstruction ? errors.newConstruction : ''}
                disabled={values.isEditing || props.isRealtorOwned}
              />
            </div>
          </div>
        )}
      </CardNewOrder>

      <ModalIneligibleDwellingSize
        isActive={isModalActive}
        sqFtMax={
          brandLocation === 'AHS' ? SQUARE_FOOTAGE_VALUES.AHS.max : SQUARE_FOOTAGE_VALUES.HSA.max
        }
        onClose={(coverageValue) => handlesquarefootageClose(coverageValue)}
        startOver={handleStartOver}
      />
      <ModalNoPlansFound
        isActive={showPlansUnavailableModal}
        onClose={() => setShowPlansUnavailableModal(false)}
      />
    </>
  );
};

export default CardNewOrderPropertyDetails;
