export const UTM_PARAMETERS_PERSIST_STORAGE_KEY = 'utmParametersPersist';
const UTM_PARAMETERS_PERSIST_LIST = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

class UtmParameters {
  init() {
    if (window.location.search) {
      const utmQueryString = this.extractUtmQueryString(
        window.location.search,
        UTM_PARAMETERS_PERSIST_LIST,
      );
      if (utmQueryString) {
        localStorage.setItem(
          UTM_PARAMETERS_PERSIST_STORAGE_KEY,
          `${window.location.origin}?${utmQueryString}`,
        );
      }
    }
  }

  extractUtmQueryString(url, utmParameterList) {
    const query = new URLSearchParams(url);
    return utmParameterList
      .reduce((output, key) => {
        const value = query.get(key);
        return value ? [...output, `${key}=${value}`] : output;
      }, [])
      .join('&');
  }

  reset() {
    localStorage.removeItem(UTM_PARAMETERS_PERSIST_STORAGE_KEY);
  }
}

const UtmParametersService = new UtmParameters();
export default UtmParametersService;
