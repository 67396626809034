import React from 'react';

import * as c from '@constants/formField-constants';
import ProfileContext from '../../context/ProfileContext/index';
import { Button, Input, Link, Radio, Text } from '@ftdr/blueprint-components-react';
import {
  isAccountTypeValid,
  isNarIdentificationNumberValid,
  isPhoneNumberValid,
} from '@services/validation/ValidationRules';
import Path from '@constants/paths';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';
import { brandFullNameDictionary } from '@constants/dictionaries';
import ExternalLinks from '@constants/external-links';

interface props {
  type: string;
  handleSubmit: any;
  handleCancel: any;
  narAccountOptions: any;
  accountTypeOptions: any;
  accountTypeValues: any;
  userIsCorporate: boolean;
}

interface state {
  radioFieldStatus: string;
  tempUserInfo: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    narId: string;
    officeType: string;
    accountType: string;
  };
  errorFields: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accountType: string;
    narId: string;
  };
  canSave: boolean;
  phoneNumberMasked: string;
}

class RegistrationTemplate extends React.Component<props, state> {
  static contextType = ProfileContext;

  constructor(props) {
    super(props);
    this.state = {
      radioFieldStatus: null,
      tempUserInfo: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        narId: null,
        officeType: null,
        accountType: null,
      },
      errorFields: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        accountType: null,
        narId: null,
      },
      canSave: false,
      phoneNumberMasked: null,
    };
  }

  async componentDidMount() {
    const { profile } = this.context;
    if (profile) {
      const tempUserInfo = { ...this.state.tempUserInfo };
      tempUserInfo.accountType = profile.roleIDType;
      this.setState({ radioFieldStatus: profile.roleIDType, tempUserInfo });
    }
  }

  componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>) {
    if (this.shouldValidateForm(prevState)) {
      this.validateForm();
    }

    if (prevState.tempUserInfo.phoneNumber !== this.state.tempUserInfo.phoneNumber) {
      this.updatePhoneNumberWithMask();
    }
  }

  setTempUserInfo(editField: string, value: string) {
    if (editField === 'narId' && (value === null || !value.length)) {
      this.setErrorField('narId', null);
    }
    const updatedTempUserInfo = { ...this.state.tempUserInfo };
    updatedTempUserInfo[editField] = value;
    this.setState({ tempUserInfo: updatedTempUserInfo });
  }

  setErrorField = (editField: string, value: string) => {
    const updatedErrors = { ...this.state.errorFields };
    updatedErrors[editField] = value;
    this.setState({ errorFields: updatedErrors });
  };

  updatePhoneNumberWithMask() {
    const groomedPhone = this.state.tempUserInfo.phoneNumber
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneWithMask = !groomedPhone[2]
      ? groomedPhone[1]
      : `(${groomedPhone[1]}) ${groomedPhone[2]}${groomedPhone[3] ? `-${groomedPhone[3]}` : ''}`;
    this.setState({ phoneNumberMasked: phoneWithMask });
  }

  shouldValidateForm(prevState): boolean {
    return (
      prevState.tempUserInfo.firstName !== this.state.tempUserInfo.firstName ||
      prevState.tempUserInfo.lastName !== this.state.tempUserInfo.lastName ||
      prevState.tempUserInfo.phoneNumber !== this.state.tempUserInfo.phoneNumber ||
      prevState.tempUserInfo.accountType !== this.state.tempUserInfo.accountType ||
      prevState.tempUserInfo.narId !== this.state.tempUserInfo.narId ||
      prevState.errorFields.firstName !== this.state.errorFields.firstName ||
      prevState.errorFields.lastName !== this.state.errorFields.lastName ||
      prevState.errorFields.phoneNumber !== this.state.errorFields.phoneNumber ||
      prevState.errorFields.accountType !== this.state.errorFields.accountType ||
      prevState.errorFields.narId !== this.state.errorFields.narId
    );
  }

  doErrorsExist(errorFields): boolean {
    return (
      !!errorFields.firstName ||
      !!errorFields.lastName ||
      !!errorFields.phoneNumber ||
      !!errorFields.accountType ||
      !!errorFields.narId
    );
  }

  validateForm() {
    const areAnyRequiredFieldsEmpty: boolean =
      !this.state.tempUserInfo.firstName ||
      !this.state.tempUserInfo.lastName ||
      !this.state.tempUserInfo.phoneNumber ||
      !this.state.tempUserInfo.accountType;

    this.setState({
      canSave: !this.doErrorsExist(this.state.errorFields) && !areAnyRequiredFieldsEmpty,
    });
  }

  radioHandler(status) {
    this.setState({ radioFieldStatus: status });
    this.setTempUserInfo('accountType', status);
  }

  handleAccountTypeChange(accountType) {
    if (isAccountTypeValid(accountType)) {
      this.setErrorField('accountType', '');
    } else {
      this.setErrorField('accountType', c.ACCOUNT_TYPE_REQUIRED);
    }
  }

  /**
   * Used for setting the error fields and validating the form
   * @param editField string corresponding to each input field name
   */
  handleBlur(editField: string) {
    let fieldError: string = '';
    let isError: boolean = false;
    switch (editField) {
      case 'firstName':
        isError =
          !this.state.tempUserInfo.firstName ||
          this.state.tempUserInfo.firstName.length > c.FIRST_NAME_MAX_LENGTH;
        fieldError = isError ? c.FIRST_NAME_REQUIRED : '';
        break;
      case 'lastName':
        isError =
          !this.state.tempUserInfo.lastName ||
          this.state.tempUserInfo.lastName.length > c.LAST_NAME_MAX_LENGTH;
        fieldError = isError ? c.LAST_NAME_REQUIRED : '';
        break;
      case 'phoneNumber':
        isError = !isPhoneNumberValid(this.state.tempUserInfo.phoneNumber);
        fieldError = isError ? c.INVALID_PHONE_NUMBER : '';
        break;
      case 'narId':
        isError = !isNarIdentificationNumberValid(this.state.tempUserInfo.narId);
        fieldError = isError ? c.INVALID_NAR_ID : '';
        break;
      default:
        console.error('Error: Invalid field: ', editField);
        break;
    }

    this.setErrorField(editField, fieldError);
  }

  handleRadioChange(event, accountOption) {
    this.radioHandler(event);
    this.handleAccountTypeChange(accountOption);
  }

  render() {
    const { radioFieldStatus } = this.state;
    const brand = getBrand();
    return (
      <>
        <h1 className="h3">
          {this.props.type === 'self' ? 'Create Your Profile' : 'Add New Employee'}
        </h1>
        <p className="mt-2 text-primary-400">
          {this.props.type === 'self'
            ? 'Register for an account to place and manage orders.'
            : 'When you add an employee to your office, you can create and manage orders for them.'}
        </p>

        <div>
          <div className="h4 mb-4">
            <strong>Personal Information</strong>
          </div>
          <div className="flex flex-col space-y-4 max-w-1/3">
            <Input
              formField
              required
              showClearButton
              className="max-w-1/3"
              id="Reg-Fname"
              label="First Name"
              placeholder="First Name"
              value={this.state.tempUserInfo.firstName}
              onChange={(e) => {
                this.setTempUserInfo('firstName', e.target.value);
              }}
              onBlur={() => this.handleBlur('firstName')}
              error={this.state.errorFields.firstName}
              maxLength={c.FIRST_NAME_MAX_LENGTH}
            />
            <Input
              formField
              required
              showClearButton
              className="max-w-1/3"
              id="Reg-Lname"
              disabled={false}
              label="Last Name"
              placeholder="Last Name"
              value={this.state.tempUserInfo.lastName}
              onChange={(e) => {
                this.setTempUserInfo('lastName', e.target.value);
              }}
              onBlur={() => this.handleBlur('lastName')}
              error={this.state.errorFields.lastName}
              maxLength={c.LAST_NAME_MAX_LENGTH}
            />
            <Input
              formField
              required
              showClearButton
              className="max-w-1/3"
              id="Reg-Phnum"
              disabled={false}
              label="Phone Number"
              placeholder="Phone Number"
              value={this.state.phoneNumberMasked}
              onChange={(e) => {
                this.setTempUserInfo('phoneNumber', e.target.value);
              }}
              onBlur={() => this.handleBlur('phoneNumber')}
              error={this.state.errorFields.phoneNumber}
              maxLength={c.PHONE_WITH_MASK_MAX_LENGTH}
            />
          </div>

          <div className="max-w-1/3 mt-4">
            <Text variant="caption" color="gray">
              {`I understand that ${brandFullNameDictionary[brand]}
              may use my mobile number to communicate with me concerning its services and my clients' use of those services. See our `}
              <Link
                className="link-color-interactive"
                href={brand === Brand.AHS ? ExternalLinks.AHS.privacyPolicy : ExternalLinks.HSA.privacyPolicy}
              >
                Privacy Policy
              </Link>
              .
            </Text>
          </div>

          <div className="h4 mt-4">
            <strong>Account Type</strong>
          </div>
          <p>Which of these best describes your role?</p>
          {!this.props.userIsCorporate &&
            Object.keys(this.props.narAccountOptions).map((narAccountOption) => (
              <div key={narAccountOption}>
                <Radio
                  id={`Radio-AccountType-${narAccountOption}`}
                  label={this.props.narAccountOptions[narAccountOption]}
                  value={narAccountOption}
                  required={true}
                  checked={narAccountOption === radioFieldStatus}
                  onChange={(event) => this.handleRadioChange(event.target.value, narAccountOption)}
                />

                {narAccountOption === radioFieldStatus && (
                  <div className="mb-4 ml-8">
                    <Input
                      formField
                      className="w-80 mt-2"
                      id="Reg-NarId"
                      label="NAR Identification Number (optional)"
                      placeholder="NAR Identification Number (optional)"
                      value={this.state.tempUserInfo.narId}
                      error={this.state.errorFields.narId}
                      onChange={(e) => this.setTempUserInfo('narId', e.target.value)}
                      onBlur={() => this.handleBlur('narId')}
                    />
                  </div>
                )}
              </div>
            ))}

          {Object.keys(this.props.accountTypeOptions).map((accountOption) => (
            <div key={accountOption}>
              <Radio
                id={`Radio-AccountType-${accountOption}`}
                label={this.props.accountTypeOptions[accountOption]}
                value={accountOption}
                required={false}
                checked={accountOption === radioFieldStatus}
                onChange={(event) => this.handleRadioChange(event.target.value, accountOption)}
              />
            </div>
          ))}

          <div className="flex justify-end mt-12">
            <Button
              className="mr-4"
              variant="ghost"
              size="large"
              color="interactive"
              onClick={this.props.handleCancel}
              label="Cancel"
              id="btn-Cancel"
            />
            <Button
              id="btn-Continue"
              variant="filled"
              size="large"
              color="interactive"
              onClick={() => this.props.handleSubmit(this.state.tempUserInfo)}
              disabled={!this.state.canSave}
              label="Continue"
            />
          </div>
        </div>
      </>
    );
  }
}

export default RegistrationTemplate;
