import React from 'react';
import Layout from '@components/layout/Layout';
import Template from '@templates/FAQ/FAQTemplate';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import ProfileContext from '../../context/ProfileContext/index';
import { ContentBox } from '@components/layout/ContentBox';

interface props {
  FAQs: FAQ[];
  FAQCategories: FAQCategory[];
}

export default class FAQAuth extends React.Component<props, never> {
  static contextType = ProfileContext;

  render() {
    return (
      <Layout isLoggedIn={true} slug="faq">
        <ContentBox title="FAQs">
          <Template
            FAQs={this.props.FAQs}
            FAQCategories={this.props.FAQCategories}
            RoleIDType={this.context.profile?.roleIDType}
          />
        </ContentBox>
      </Layout>
    );
  }
}
