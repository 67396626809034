import { BaseAPI } from './_api';

interface IQualifiedSuccess {
  success: boolean;
  details: string[];
}

class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }

  public upload(formData: FormData): Promise<IQualifiedSuccess> {
    return this.axios
      .post<any, any>(`${this.getHost()}/admin/contract/upload`, formData)
      .then((res) => {
        console.log('Success', res);
        return { success: true, details: [] };
      })
      .catch((e) => {
        console.log('submitting form failed.', e);
        return { success: false, details: e.response.data.errors.map((error) => error.message) };
      });
  }
}

const AdminUploadApi = new API();
export default AdminUploadApi;
