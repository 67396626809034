import React, { useEffect, useState } from 'react';
import ContactUsTemplate from '@templates/contact/ContactUsTemplate';
import HeaderNoAuth from '@components/general/HeaderNoAuth';
import ProofOfWorkApi from '@apis/proofOfWork.api';

export const ContactUsNoAuth: React.FC = () => {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    fetchGuestToken().then((success) => {
      if (!success) {
        setToken('');
      }
    });
  }, []);

  async function fetchGuestToken(): Promise<boolean> {
    let response = await ProofOfWorkApi.GetGuestTokenChallenge();
    if (response === undefined) {
      return false;
    }

    response = await ProofOfWorkApi.GetGuestToken(response);
    if (response === undefined) {
      return false;
    }

    setToken(response);
    return true;
  }

  return (
    <>
      <HeaderNoAuth />
      <ContactUsTemplate
        email=""
        roleType="Unknown"
        fullName=""
        isAuthed={false}
        guestToken={token}
      />
    </>
  );
};

export default ContactUsNoAuth;
