import { Address } from '@apis/models/address.api.model';
import { ContractPlanCoverages, ContractPricing } from '@apis/models/contract.api.model';
import { Office } from '@apis/models/office.api.model';
import { Agent } from '@apis/models/agent.api.model';
import { isBefore } from 'date-fns/fp';

export const AbandonedOrderStatuses = ['NEW', 'ABANDONED'];

interface Phone {
  phone?: string;
  phoneType?: string;
}

export interface Customer {
  firstName?: string;
  lastName?: string;
  email?: string;
  addressUUID?: string;
  phones?: Phone[];
}

interface Customers {
  buyer?: Customer;
  cobuyer?: Customer;
  seller?: Customer;
  coseller?: Customer;
}

export interface OfficeAgent {
  officeID?: string;
  agentID?: string;
  represents?: string;
  agentFirstName?: string;
  agentLastName?: string;
  agentEmail?: string;
}

interface Offices {
  initiatingOfficeRepresents?: string;
  initiatingOffice?: OfficeAgent;
  cooperatingOffice?: OfficeAgent;
  closingOffice?: OfficeAgent;
}

export interface EmailRecipient {
  email?: string;
  recipient?: string;
}

export interface EmailDocument {
  invoice?: EmailRecipient[];
  confirmation?: EmailRecipient[];
}

export interface OrderRequest {
  propertyAddressUUID?: string;
  quoteID?: string;
  estimatedClosingDate?: string; // YYYY-MM-DD
  escrowNumber?: string;
  mlsValue?: string;
  offices?: Offices;
  customers?: Customers;
  emailDocuments?: EmailDocument;
  sourcePage?: string;
  isShareQuote?: boolean;
}

export interface CreateOrderResponse {
  orderID: string;
  status: string;
  errors?: any[];
}

export interface SearchOrderRequest {
  meta: {
    page: number;
    limit: number;
    sortBy: string;
    asc: boolean;
  };
  data: {
    query: string;
    order: {
      ids: any[];
      statuses: any[];
    };
    offices: any[];
    agents: any[];
  };
}

export interface UnSubmittedOrderSearchRequest {
  criteria: {
    propertyAddressID?: string;
    sourceApplication?: string;
    sourceApplicationFlow?: string;
    statuses?: string[];
    createdDate?: {
      start: Date;
      end: Date;
    };
    initiatingOfficeAgent?: {
      officeID?: string;
      agentID?: string;
    };
  };
  meta: {
    page?: number;
    limit?: number;
    sortBy?: string;
    asc?: boolean;
  };
}

export interface UnsubmittedOrderSearchResponse {
  meta: {
    page: number;
    limit: number;
    total: number;
  };
  results: Order[];
}

export interface OrderDetails {
  propertyDetails?: PropertyDetails;
  planAndCoverages?: ContractPlanCoverages;
  pricing?: ContractPricing;
  customerMailingAddresses?: OrderCustomerMailingAddresses;
  officeAgents?: OrderOfficeAgents;
}

export interface PropertyDetails {
  residenceType?: string;
  squareFootage?: number;
  yearBuilt?: number;
}

export interface OrderCustomerMailingAddresses {
  buyer?: Address;
  cobuyer?: Address;
  seller?: Address;
  coseller?: Address;
}

export interface OrderOfficeAgents {
  initiating?: OrderOfficeAgent;
  cooperating?: OrderOfficeAgent;
  closing?: OrderOfficeAgent;
}

export interface OrderOfficeAgent {
  office?: Office;
  agent?: Agent;
}

interface OrderUtilFunctions {
  getRepresents(): string;
  getUpdateDate(): Date;
  getExpirationDate(): Date;
  isOrderExpired(today: Date): boolean;
  getClosingDate(): Date;
  getQuoteID(): string;
  hasBuyerInfo(): boolean;
  hasSellerInfo(): boolean;
  hasClosingInfo(): boolean;
}

export class Order implements OrderUtilFunctions {
  orderID?: string;
  status?: string;
  contractID?: string;
  sourceApplication?: string;
  tenantID?: string;
  attributes?: { [key: string]: string };
  lastModified?: string;
  createdDate?: string;
  quoteExpiration?: string;
  orderExpiration?: string;

  order?: OrderRequest;
  propertyAddress?: Address;
  details?: OrderDetails;

  constructor(props?: Partial<Order>) {
    Object.assign(this, props);
  }

  getRepresents(): string {
    return (
      this.order?.offices?.initiatingOfficeRepresents?.toLocaleLowerCase() ||
      this.order?.offices?.initiatingOffice?.represents?.toLocaleLowerCase() ||
      ''
    );
  }

  getUpdateDate(): Date {
    return this.lastModified ? new Date(this.lastModified) : null;
  }

  getClosingDate(): Date {
    return this.order?.estimatedClosingDate ? new Date(this.order?.estimatedClosingDate) : null;
  }

  getExpirationDate(): Date {
    const expiration = this.orderExpiration || this.quoteExpiration;
    return expiration ? new Date(expiration) : null;
  }

  getQuoteID(): string {
    return this.order?.quoteID;
  }

  hasBuyerInfo(): boolean {
    return !!this.order?.customers?.buyer?.firstName;
  }

  hasClosingInfo(): boolean {
    return (
      !!this.order?.offices?.closingOffice?.officeID ||
      !!this.order?.escrowNumber ||
      !!this.getClosingDate()
    );
  }

  hasSellerInfo(): boolean {
    return !!this.order?.customers?.seller?.firstName;
  }

  isOrderExpired(today: Date = new Date()): boolean {
    const expirationDate = this.getExpirationDate();
    return expirationDate ? isBefore(today, expirationDate) : false;
  }
}
