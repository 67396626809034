import { Contract, ContractDateType, OfficeTypeCode, REOrderSearchResponse } from '@apis/models';
import { convertDateToCST, formatDate, formatDateFromString, toTitleCase } from '@app/helpers/utils';
import { denormalizeStreetAddress1 } from '@services/helpers';
import { translateOffice } from '../office.api';
import { translateAgent } from '../agent.api';
import { AgentType } from '../models/agent.api.model';

/** Take care of title casing */
export function translateContract(contract: Contract): Contract {
  if (contract) {
    contract.summary.address = contract.summary.address && {
      ...contract.summary.address,
      address1: toTitleCase(contract.summary.address.address1),
      address2: toTitleCase(contract.summary.address.address2),
      city: toTitleCase(contract.summary.address.city),
    };
    contract.summary.agentName = toTitleCase(contract.summary.agentName);
    contract.summary.officeName = toTitleCase(contract.summary.officeName);

    if (!contract.detail) {
      // console.warn("not loading any details atm.")
      return contract;
    }

    contract.detail.property = contract.detail.property && {
      ...contract.detail.property,
      streetAddress: toTitleCase(contract.detail.property.streetAddress),
      streetAddress2: toTitleCase(contract.detail.property.streetAddress2),
      city: toTitleCase(contract.detail.property.city),
      streetName: toTitleCase(contract.detail.property.streetName),
    };

    if (contract.detail.initiatingOfficeAgent) {
      contract.detail.initiatingOfficeAgent.office = translateOffice(
        contract.detail.initiatingOfficeAgent.office,
      );
      contract.detail.initiatingOfficeAgent.agent = translateAgent(
        contract.detail.initiatingOfficeAgent.agent,
      );
    }
    if (contract.detail.cooperatingOfficeAgent) {
      contract.detail.cooperatingOfficeAgent.office = translateOffice(
        contract.detail.cooperatingOfficeAgent.office,
      );
      contract.detail.cooperatingOfficeAgent.agent = translateAgent(
        contract.detail.cooperatingOfficeAgent.agent,
      );
    }
    if (contract.detail.closingOfficeAgent) {
      contract.detail.closingOfficeAgent.office = translateOffice(
        contract.detail.closingOfficeAgent.office,
      );
      contract.detail.closingOfficeAgent.agent = translateAgent(
        contract.detail.closingOfficeAgent.agent,
      );
    }

    // TODO: Customers?
  }
  return contract;
}

/** Any data that is missing from the app layer, we can update it here for now until the logic is moved over.
 * General rule of thumbs:
 *  1. if you have to access the data in 'meta', the logic should be handled and added to either 'summary' or 'detail'.
 *  2. if it is specific business stuff, it should live in app layer. Front end just wants to display it.
 */
export function translateToOrderModel(contract: Contract): Contract {
  contract = translateContract(contract);

  if (!contract.detail) {
    // console.warn("not loading any details atm.")
    return contract;
  }
  const { meta } = contract;
  // Set represents
  for (const office of meta.offices) {
    const { represents, initiating, typeCode } = office;
    // Initiating Office
    if (initiating === 'Y' && typeCode === 'RE') {
      contract.detail.initiatingOfficeAgent.represents = represents;
    }
    // Cooperating Office
    else if (initiating !== 'Y' && typeCode === 'RE') {
      contract.detail.cooperatingOfficeAgent.represents = represents;
    }
    // Closing Office
    else if (initiating !== 'Y' && typeCode === 'CC') {
      contract.detail.closingOfficeAgent.represents = represents;
    }
    // Something is wrong here...
    else {
      console.warn('Unrecognized office', office);
    }
  }
  // Represents BOTH can sometimes be separated for initiating/cooperating office/agent,
  // so we need to remove cooperating office in that case and set initiating office to represent 'BOTH'
  if (contract.detail.initiatingOfficeAgent && contract.detail.cooperatingOfficeAgent) {
    // If offices are the same
    if (
      contract.detail.initiatingOfficeAgent.office.id ===
      contract.detail.cooperatingOfficeAgent.office.id
    ) {
      // If agents exist and are the same
      if (
        contract.detail.initiatingOfficeAgent.agent &&
        contract.detail.cooperatingOfficeAgent.agent &&
        contract.detail.initiatingOfficeAgent.agent.realEstateAgentID ===
        contract.detail.cooperatingOfficeAgent.agent.realEstateAgentID
      ) {
        // That means they are the same office/agent. Remove cooperating agent and set initiating office to represent both
        contract.detail.initiatingOfficeAgent.represents = 'BOTH';
        contract.detail.cooperatingOfficeAgent = null;
      }
    }
  }

  // Property Detail
  const { details } = contract.meta;
  const mlsTypeCode = 'MLS#';
  const mlsDetail = details.find((d) => d.type === mlsTypeCode);
  contract.detail.mlsNumber = mlsDetail ? mlsDetail.value : '';

  // Closing Info
  const closingFileTypeCode = 'ESCROW';
  const closingFileNumber = details.find((d) => d.type === closingFileTypeCode);
  contract.detail.closingFileNumber = closingFileNumber ? closingFileNumber.value : '';

  // Imporant Dates
  const { dates } = contract.meta;
  const dateLookup = {
    ENTRYDATE: dates.find((d) => d.type === ContractDateType.EntryDate),
    LISTDATE: dates.find((d) => d.type === ContractDateType.ListingDate),
    LISTCOVEFF: dates.find((d) => d.type === ContractDateType.ListingCoverageEffectiveDate),
    EFFDATE: dates.find((d) => d.type === ContractDateType.EffectiveDate),
    ESTCOE: dates.find((d) => d.type === ContractDateType.EstimatedClosingOfEscrow),
  };

  contract.detail.importantDates = {
    creationDate: dateLookup.ENTRYDATE ? formatDateFromString(dateLookup.ENTRYDATE.effective) : '',
    listingExpirationDate: dateLookup.LISTDATE
      ? formatDateFromString(dateLookup.LISTDATE.expiration)
      : '',
    sellersCoverageEffectiveDate: dateLookup.LISTCOVEFF
      ? formatDateFromString(dateLookup.LISTCOVEFF.effective)
      : '',
    sellersCoverageExpirationDate: dateLookup.LISTCOVEFF
      ? formatDateFromString(dateLookup.LISTCOVEFF.expiration)
      : '',
    projectedClosingDate: dateLookup.ESTCOE
      ? formatDateFromString(dateLookup.ESTCOE.effective)
      : '',
    effectiveDate: dateLookup.EFFDATE ? formatDateFromString(dateLookup.EFFDATE.effective) : '',
    closingDate: dateLookup.EFFDATE ? formatDateFromString(dateLookup.EFFDATE.expiration) : '',
    expirationDate: contract.summary.expirationDate || '',
    listDate: dateLookup.LISTDATE.effective ? dateLookup.LISTDATE.effective : '',
  };

  // Customer address
  for (const c of contract.detail.customers) {
    if (c.address) {
      const { streetNumber, streetName, streetDirection, unitNumber } = c.address;
      c.address.address1 = denormalizeStreetAddress1(streetNumber, streetDirection, streetName);
      c.address.address2 = unitNumber;
    }
  }

  return contract;
}

/** Override new order error message to be more user friendly. Key is search text, Value is replacement */
const NewOrderErrorDictionary = {
  'office.id required': 'Office Required.',
  'office.represents must be one of the following': 'Agent Represent Required.',
  'office.agent required': 'Agent Required.',
  'property.state is required': 'Property State Required.',
  'property.type_of_residence must be added': 'Type of Residence Required.',
};

/**
 * Handle new order error messages, doing translation.
 * New order is handled differently from the other endpoints, so we don't have error codes to leverage.
 * So this will be a find and replace using a dictionary and return a single string, messages separated by \n
 */
export function translateNewOrderErrorMessages(result: any): any {
  if (result && result.contract) {
    return result;
  }

  let msgs: string[] = [];
  let msg: string;

  // Put message in an array
  if (result.transaction_information) {
    msg = result.transaction_information.errorMessage;
    msgs = [msg];
  } else {
    msg = result;
    msgs = msg.split('\n');
  }

  // Iterate through each msg and translate them when a match is found.
  Object.keys(NewOrderErrorDictionary).forEach((key) => {
    msgs = msgs.map((m) => {
      return m.includes(key) ? NewOrderErrorDictionary[key] : m;
    });
  });

  // Join the errors with new line in between
  return msgs.join('\n');
}

export function sortByDate(arrayToSort: DateSortable[]): any {
  return arrayToSort.sort(
    (a, b) => new Date(b.dateTimeStamp).getTime() - new Date(a.dateTimeStamp).getTime(),
  );
}

export interface DateSortable {
  dateTimeStamp: string | number;
}


export const translateREOrderSearchResponse = (response: REOrderSearchResponse): REOrderSearchResponse => {
  response?.orders?.forEach((order) => {
    if (order?.warrantylink?.deadlineDate) {
      order.warrantylink.deadlineDate = convertDateToCST(order.warrantylink.deadlineDate);
    }
  })
  return response;
}