import React from 'react';
import Layout from '@components/layout/Layout';
import { useNavigation } from 'react-navi';
import Path from '@constants/paths';
import ContactUsSuccessTemplate from '@templates/contact/ContactUsSuccessTemplate';

const ContactUsAuthSuccess = () => {
  const navigation = useNavigation();

  return (
    <Layout isLoggedIn={true}>
      <ContactUsSuccessTemplate
        isButtonVisible={true}
        onClick={() => navigation.navigate(Path.Dashboard)}
      />
    </Layout>
  );
};
export default ContactUsAuthSuccess;
