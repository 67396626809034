import * as Sentry from '@sentry/react';
import ProfileModel from '@app/models/profile.model';
import { Breadcrumb } from '@sentry/react';
import { getConfig } from '@services/app.config.loader';
import { getBrand } from '@helpers/brand.utils';

export function loadSentryIO() {
  try {
    Sentry.init({
      dsn: getConfig('SENTRY_IO_DSN'),
      integrations: [
        new Sentry.BrowserTracing(), // performance monitoring
        new Sentry.Dedupe(), // dedupes events based on stack traces and fingerprints
      ],
      tracesSampleRate: 0.1,
      environment: getSentryEnv(),
      maxBreadcrumbs: 50,
      beforeBreadcrumb: beforeBreadCrumb,
    });
    Sentry.setTag('brand', getBrand());
  } catch (e) {
    console.error('failed to initialize sentry.io', e);
  }
}

const beforeBreadCrumb = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint,
): Sentry.Breadcrumb => {
  try {
    if (breadcrumb.category === 'ui.click') {
      // for BDS buttons, it uses a span label with a button parent wrapper.
      const isBDSButton =
        hint.event.target.localName === 'span' &&
        hint.event.target.offsetParent?.localName === 'button';
      // for BDS checkbox, uses div for label and label for parent wrapper.
      const isBDSCheckbox =
        hint.event.target.localName === 'div' &&
        hint.event.target.offsetParent?.localName === 'label';
      if (isBDSButton || isBDSCheckbox) {
        const $label = hint.event.target;
        const $button = $label.offsetParent;
        const buttonID = $button.id || '';
        const buttonLabel = $label.innerText;
        breadcrumb.message = `button${buttonID && '#' + buttonID} > ${breadcrumb.message}[innerText="${buttonLabel}"]`;
      }
    }
  } catch (e) {
    console.error(
      'error occurred when making modifications to breadcrumb. leaving breadcrumb alone',
      e,
    );
  }

  return breadcrumb;
};

export function addProfileSentryTags(profile: ProfileModel): void {
  try {
    if (profile) {
      Sentry.configureScope((scope) => {
        scope.setTags({
          'profile.profileID': profile.profileID,
          'profile.offices.length': profile.offices?.length,
          'profile.roleIDType': profile.roleIDType,
          'profile.roleID': profile.roleID,
          'profile.authID': profile.authID,
        });
        scope.setUser({
          id: profile.profileID,
        });
      });
    }
  } catch (e) {
    console.error('failed to load sentry user profile tags', e);
  }
}

export function clearProfileSentryTags(): void {
  try {
    Sentry.setUser(null);
  } catch (e) {
    console.error('failed to clear sentry tags', e);
  }
}

export function addSentryBreadCrumb(breadcrumb: Breadcrumb): void {
  try {
    Sentry.addBreadcrumb(breadcrumb);
  } catch (e) {
    console.error('failed to add sentry breadcrumb', e);
  }
}

export function getSentryEnv(): string {
  let env = getConfig('env');

  // override the environment if running code in localhost
  const { hostname } = window.location;
  if (hostname.indexOf('localhost') >= 0) {
    env = 'localhost';
  }
  return env;
}
