import React, { useEffect, useState } from 'react';
import { DateTimeInput, RadioGroup } from '@ftdr/blueprint-components-react';
import { DateFormat, dateTimeWithTimeZone, formatDate } from '@helpers/utils';

const ConfirmWLRemoval = (props) => {
  const [removeMode, setRemoveMode] = useState('auto');
  const [effectiveDate, setEffectiveDate] = useState(new Date());

  useEffect(() => {
    let dateString = '';
    if (removeMode === 'manual') {
      const centralDateTime = dateTimeWithTimeZone(
        effectiveDate.getFullYear(),
        effectiveDate.getMonth(),
        effectiveDate.getDate(),
        23,
        59,
        59,
        'America/Chicago',
      );
      dateString = formatDate(centralDateTime, DateFormat.ISO8601_Timestamp);
    }
    props.onDateChange(dateString);
  }, [removeMode, effectiveDate]);

  return (
    <>
      <p>When would you like this account to be removed?</p>
      <RadioGroup
        radios={[
          { value: 'auto', label: 'Immediately' },
          { value: 'manual', label: 'End of day (central time) on specific date' },
        ]}
        label=""
        value={removeMode}
        onChange={setRemoveMode}
      />
      {removeMode === 'manual' && (
        <DateTimeInput
          label=""
          onlyDate={true}
          datePicker={true}
          onDateSelect={setEffectiveDate}
          selectedDate={effectiveDate}
        />
      )}
    </>
  );
};

export default ConfirmWLRemoval;
