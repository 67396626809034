import ProfileApi from '@apis/profile.api';

export async function hasAdminPermissions(permissions: string[]) {
  const response = await ProfileApi.getPermission();
  return permissions.every((permission) => {
    return response.Admin.includes(permission);
  });
}

export async function hasContractPermissions(permissions: string[]) {
  const response = await ProfileApi.getPermission();
  return permissions.every((permission) => {
    return response.Contract.includes(permission);
  });
}
