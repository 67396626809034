import { Panel, Text } from '@ftdr/blueprint-components-react';
import { addressObjectToString } from '@services/helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Contract, ContractSummary } from '@apis/models';
import { formatDateFromString } from '@helpers/utils';
import Mark from 'mark.js';
import { breakpoints, isViewInRange } from '@utils';
import { CustomerTypes, orderStatusDictionary } from '@constants/dictionaries';

interface internalProps {
  contract: ContractSummary;
  idPrefix: string;
  onClickContract?: any;
  actions?: any[];
  leftActions?: any[];
  showBuyer?: boolean;
  showExpiryDate?: boolean;
  showSubmissionDeadline?: boolean;
  showStatus?: boolean;
  /** text used for highlighting phrases on the contract summary. */
  query?: string;
  showWLKDeadline?: boolean;
  contractDetails?: Contract;
  disabled?: boolean;
}

const PanelContractSummary = (props: internalProps) => {
  const [buyerName, setBuyerName] = useState<string>('');
  const isMobile = isViewInRange(0, breakpoints.md);
  const container = useRef<HTMLDivElement | null>(null);

  const performMark = useCallback(() => {
    if (container?.current && props.query) {
      const instance = new Mark(container.current);
      instance.unmark({
        done() {
          instance.mark(props.query.split(' '), {
            separateWordSearch: false,
            diacritics: true,
            debug: false,
            exclude: ['.DO-NOT-HIGHLIGHT'],
            className: 'font-bold',
          });
        },
      });
    }
  }, [props.query]);

  useEffect(() => {
    performMark();
  }, [props.query, props.contract]);

  useEffect(() => {
    if (props.contractDetails?.detail?.customers) {
      const buyers = props.contractDetails.detail.customers.filter(
        (c) => c.type === CustomerTypes.Buyer,
      );
      if (buyers.length) {
        setBuyerName(`${buyers[0]?.firstName} ${buyers[0]?.lastName}`);
      }
    }
  }, [props.contractDetails]);

  return (
    <Panel
      border="1"
      borderColor="gray-300"
      rounded="sm"
      padding="none"
      shadow={true}
      className="rounded bg-white shadow-1 border-2 mb-4 w-full"
    >
      <div
        ref={container}
        className={`flex justify-start items-center w-full py-3 pl-4 pr-4 md:pl-6 text-left text-sm md:text-base bg-transparent ${props.onClickContract && 'cursor-pointer'}`}
        id={props.idPrefix}
        onClick={() => props.onClickContract(props.contract)}
      >
        {/* TODO Clean up the Div bloat... This code was copied from elsewhere to start componentization, but time constraints prevented a full cleanup effort */}
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full pl-3 max-w-19/20 overflow-hidden">
            <div className="flex md:flex-row md-max:flex-col ">
              {isMobile &&
                props.leftActions?.length > 0 &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              {!isMobile &&
                props.leftActions?.length > 0 &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              <div
                className="flex md:flex-row md-max:flex-col "
                onClick={() => props.onClickContract(props.contract)}
              >
                <div className="min-w-max w-fit">
                  <div className="float-left flex flex-row">
                    <div className="flex flex-col w-full ">
                      <Text variant="label" color="gray" className="mr-6 nowrap DO-NOT-HIGHLIGHT">
                        ORDER NUMBER
                      </Text>
                      <Text variant="caption" color={props.disabled ? "gray" : "black"}>
                        {props.contract.id}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row float-right">
                    {isMobile &&
                      !props.disabled &&
                      props.actions?.length > 0 &&
                      props.actions.map((action) => {
                        return (
                          <div key={action} className="ml-4">
                            {action}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="flex flex-col w-fit">
                  <Text variant="label" color="gray" className="pr-48 nowrap DO-NOT-HIGHLIGHT">
                    PROPERTY ADDRESS
                  </Text>
                  <Text variant="caption" color={props.disabled ? "gray" : "black"} className="overflow-visible">
                    {addressObjectToString(props.contract.address)}
                  </Text>
                </div>

                {(props.showExpiryDate === undefined || props.showExpiryDate === true) && (
                  <div className="flex flex-col nowrap pr-6 w-fit">
                    <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                      EXPIRY DATE
                    </Text>
                    <Text variant="caption" color={props.disabled ? "gray" : "secondary"}>
                      {formatDateFromString(props.contract.expirationDate)}
                    </Text>
                  </div>
                )}

                {props.showSubmissionDeadline && (
                  <div className="flex flex-col nowrap pr-6 w-fit">
                    <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                      SUBMISSION DEADLINE
                    </Text>
                    <Text variant="caption" color="secondary">
                      {formatDateFromString(props.contract.warrantylink?.deadlineDate)}
                    </Text>
                  </div>
                )}

                {props.showStatus && (
                  <div className="flex flex-col nowrap pr-6 w-fit">
                    <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                      STATUS
                    </Text>
                    <Text variant="caption" color={props.disabled ? "gray" : "black"}>
                      {orderStatusDictionary[props.contract.realEstateStatus]}
                    </Text>
                  </div>
                )}

                {props.showBuyer && props.contractDetails && (
                  <div className="flex flex-col w-full nowrap pr-6">
                    <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                      BUYER
                    </Text>
                    <Text variant="caption" color={props.disabled ? "gray" : "black"}>
                      {buyerName}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            { props.disabled && <Text variant="caption" color="black">You have reached the limit of promo emails to send for this contract.</Text> }
          </div>
          {!isMobile &&
            !props.disabled &&
            props.actions?.length > 0 &&
            props.actions.map((action) => {
              return (
                <div key={action} className="flex flex-row mr-4 h-fit md-max:mr-4">
                  {action}
                </div>
              );
            })}
        </div>
      </div>
    </Panel>
  );
};

export default PanelContractSummary;
