import React from 'react';
import Layout from '@components/layout/Layout';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import { useCurrentRoute } from 'react-navi';
import { Panel } from '@ftdr/blueprint-components-react';

export interface NewOrderCompleteRouteData {
  contractID: string;
  newOrderData: NewOrderContextData;
}

const NewOrderComplete: React.FC = (props) => {
  const routeData = useCurrentRoute().data;
  return (
    <Layout isLoggedIn={true}>
      <div>tbc, complete the page template</div>

      <Panel>
        <pre>{JSON.stringify(routeData, null, 2)}</pre>
      </Panel>
    </Layout>
  );
};

export default NewOrderComplete;
