import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import PlanPriceViewQuote, {
  PlanPriceViewQuoteProps,
} from '@components/drawer/subcomponents/PlanPriceViewQuote';
import { Quote } from '@apis/models/quote.api.model';

export interface DrawerPlanPriceViewQuoteProps
  extends Required<Pick<DrawerProps, 'isActive' | 'onClose'>>,
    Omit<PlanPriceViewQuoteProps, 'emailQuoteCall'> {
  selectedOrder: Quote;
  onShareQuote: (shareQuoteProps) => void;
  onConvertToOrder: (selectedContract) => void;
  onCancelQuote: (cancelQuoteProps) => void;
  onViewOrder: (contractID) => void;
}

const DrawerPlanPriceViewQuote: React.FC<DrawerPlanPriceViewQuoteProps> = (props) => {
  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <PlanPriceViewQuote
          selectedOrder={props.selectedOrder}
          onClose={props.onClose}
          onShareQuote={props.onShareQuote}
          onConvertToOrder={props.onConvertToOrder}
          onCancelQuote={props.onCancelQuote}
          onViewOrder={props.onViewOrder}
        />
      )}
    </Drawer>
  );
};

export default DrawerPlanPriceViewQuote;
