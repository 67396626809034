// This is added in webpack as an alias to prevent the additional errors from being shown each time it is referenced.
export function linkTo(a, b) {
  // Makes the linter stop giving warnings
  if (a === b) {
    // Do nothing
  }
}

export default linkTo;

/**
 * @deprecated we want to switch to using the generated BDS id instead.  This only applies to migrated usages
 * @param formFieldId
 * @return {`wb_error-${string}`}
 */
export const getFormRadioErrorId = (formFieldId) => {
  return `wb_error-${formFieldId}`;
};
/**
 * Helper function to get the id of the radio options for reverse-compatibility with QA testing.
 * @deprecated only used this for existing FormRadio usage being migrated. Don't use this for any other usage.
 * @param parentId - FormRadio.id
 * @param optionKey - FormRadio.options[#][key]
 */
export const getFormRadioOptionId = (parentId, optionKey) => {
  return `${parentId}-${optionKey}`;
};
/**
 * Helper function to convert the SF FormRadio.options into BDS RadioGroup.radio
 * @deprecated only used this for existing FormRadio usage being migrated. Don't use this for any other usage.
 * @param parentId - FormRadio.id
 * @param radioOptionsObj - FormRadio.options
 */
export const convertOptionsObjToRadioGroupOptions = (parentId, radioOptionsObj) => {
  return Object.keys(radioOptionsObj).map((key) => ({
    id: getFormRadioOptionId(parentId, key),
    label: radioOptionsObj[key],
    value: key,
  }));
};
/**
 * Helper function to convert the SF FormCheckbox.values into BDS CheckboxGroup.values
 * @deprecated only used this for existing FormCheckbox usage being migrated. Don't use this for any other usage.
 * @param radioValuesObj - FormCheckbox.values
 */
export const convertValuesToRadioGroupValue = (radioValuesObj) => {
  return Object.keys(radioValuesObj).find((key) => radioValuesObj[key] === true);
};
/**
 * Helper function to convert the BDS.RadioGroup.onChange to SF.FormRadio.onClick.
 * Bridges the gap for reverse-compatibility for QA testing.
 * @deprecated only use this for existing FormSelect usage being migrated. Don't use this for any other new usage.
 * @param selectedValue - RadioGroup, the param value from RadioGroup.onChange
 * @param onClickFunc - FormRadio.onClick
 * @return ChangeEvent<HTMLInputElement> - the change event that FormRadio.onClick uses
 */
export const convertRadioGroupOnChangeToOnClick = (selectedValue, onClickFunc) => {
  return onClickFunc({
    target: {
      name: selectedValue,
      value: selectedValue,
      checked: true,
    },
  });
};
/**
 * @deprecated we want to switch to using the generated BDS id instead.  This only applies to migrated usages
 * @param formFieldId
 * @return {`wb_error-${string}`}
 */
export const getFormSelectErrorId = (formFieldId) => {
  return `wb_error-${formFieldId}`;
};
/**
 * @deprecated we want to switch to using the generated BDS id instead.  This only applies to migrated usages
 * @param formFieldId
 * @return {`wb_error-${string}`}
 */
export const getFormInputErrorId = (formFieldId) => {
  return `wb_error-${formFieldId}`;
};
