import Modal from '@components/modal/Modal';
import React from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import NewOrderIncompleteRequest from './subcomponents/NewOrderIncompleteRequest';

const ModalNewOrderIncompleteRequest = (props) => {
  const actions = [
    <Button
      key="submit"
      id={props.buttonId}
      size="medium"
      label="Okay"
      onClick={() => props.onClose()}
    />,
  ];

  return (
    <Modal
      id={props.id}
      isActive={props.isActive}
      onClose={props.onClose}
      heading="Request is incomplete"
      actions={actions}
    >
      {props.isActive && <NewOrderIncompleteRequest message={props.message} />}
    </Modal>
  );
};

export default ModalNewOrderIncompleteRequest;
