import { ACCOUNT_ERROR } from '@constants/formField-constants';
import Path from '@constants/paths';
import React from 'react';

const NewOrderAccountFailure = () => {
  return (
    <div>
      {ACCOUNT_ERROR}
      <a className="underline" href={Path.ContactUs}>
        support team
      </a>
      .
    </div>
  );
};

export default NewOrderAccountFailure;
