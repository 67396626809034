import { isIE } from '@helpers/utils';
import React, { useEffect } from 'react';
import useGlobalAlert from '@app/core/GlobalAlertModal';

const NonSupportIECheck = () => {
  const { addWarningToQueue } = useGlobalAlert();

  useEffect(() => {
    if (isIE()) {
      addWarningToQueue({
        id: 'modal--non-support-ie',
        title: 'Internet Explorer',
        messageHTML: (
          <p>
            We are unable to guarantee optimal performance of our site on Internet Explorer. Please
            consider trying our site on another browser type.
          </p>
        ),
      });
    }
  }, []);

  return <></>;
};

export default NonSupportIECheck;
