import React, { useEffect } from 'react';
import {
  Drawer as BDSDrawer,
  IconButton,
  IconNavArrowLeft,
  IconNavArrowRight,
} from '@ftdr/blueprint-components-react';
import classNames from 'classnames';

export interface DrawerProps {
  /** id of the drawer. child elements will use this id */
  id?: string; // TODO: Should not be optional. Update the other drawers first
  /** when true, drawer is rendered and visible */
  isActive?: boolean;
  /** trigger when drawer is closed. Hint: should set active state to false */
  onClose?: () => void;
  /** Set the className for the content container */
  className?: string;
  /** Set the className for the sliding content */
  slidingContentClassName?: string;

  /** when set to true, the width of the drawer will be set to the default value.
   * otherwise, when false, the width grows with content width and can be controlled with className.
   * Use sparingly, only certain Drawers would want to change its size (i.e. always full width drawers)
   * @default: true */
  useDefaultWidth?: boolean;
}

interface HandleProps {
  id?: string;
  drawerOpen: boolean;
  onClose: () => void;
}

const Handle: React.FC<HandleProps> = (props) => {
  return (
    <div className="flex h-full items-center -mr-4 pr-1">
      <IconButton
        id={`${props.id}-close-btn`}
        variant="filled"
        color="white"
        size="small"
        icon={props.drawerOpen ? <IconNavArrowRight /> : <IconNavArrowLeft />}
        label="Continue"
        onClick={props.onClose}
        className="shadow-raised rounded-100 border-1 border-gray-300 drawer-handle"
      />
    </div>
  );
};

const Drawer: React.FC<DrawerProps> = (props) => {
  let lastOpenedTime = Date.now();

  useEffect(() => {
    if (props.isActive) {
      lastOpenedTime = Date.now();
    }
  }, [props.isActive]);

  const onCloseWithTimeCheck = () => {
    if (Date.now() - lastOpenedTime > 200) {
      props.onClose();
    }
  };

  return (
    <BDSDrawer
      id={props.id}
      className={classNames([
        props.useDefaultWidth && 'w-full max-w-224',
        props.className,
        !props.isActive ? 'translate-x-full' : null,
      ])}
      open={props.isActive}
      onClose={onCloseWithTimeCheck}
      slideFrom="right"
      showBackdrop={true}
      handle={
        props.isActive && props.onClose ? (
          <Handle drawerOpen={props.isActive} onClose={props.onClose} />
        ) : null
      }
      slidingContent={
        <div
          className={classNames([
            'md-max:py-7',
            'md-max:px-4',
            'lg:p-12',
            props.slidingContentClassName,
          ])}
        >
          {props.children}
        </div>
      }
      contentContainerClassName="shadow-floating bg-white md:max-w-screen md-max:mobile-drawer"
      slidingContentContainerClassName="overflow-scroll"
      closeOnEscapeKey={true}
      closeOnOutsideClick={true}
    />
  );
};

Drawer.defaultProps = {
  onClose: () => {
    /* Do nothing */
  },
  isActive: false,
  useDefaultWidth: true,
};

export default Drawer;
