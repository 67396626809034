import React from 'react';
import SubmitSuccess from '../../components/submitSuccess/SubmitSuccess';
import { Text } from '@ftdr/blueprint-components-react';

const SUCCESS_TITLE = 'Request successfully submitted';
const SUCCESS_MESSAGE = 'We’ve received your request and we’ll be in touch.';
const SUCCESS_BUTTON_TEXT = 'Back to Orders';

interface IContactUsSuccessProps {
  isButtonVisible: boolean;
  onClick?: () => void;
}

const ContactUsSuccessTemplate: React.FC<IContactUsSuccessProps> = (
  props: IContactUsSuccessProps,
) => {
  return (
    <div id="contact-us-success" className="container py-10">
      <Text id="contact-us-success-title" variant="heading-03">
        Contact Us
      </Text>
      <div className="py-4">
        <SubmitSuccess
          title={SUCCESS_TITLE}
          message={SUCCESS_MESSAGE}
          buttonText={SUCCESS_BUTTON_TEXT}
          isButtonVisible={props.isButtonVisible}
          onClick={props.onClick}
        />
      </div>
    </div>
  );
};

export default ContactUsSuccessTemplate;
