import React from 'react';
import CustomerInfoInput from '@components/input/CustomerInfoInput';

const ContractDetails = (props) => {
  return (
    <CustomerInfoInput
      contractStatus={props.contract.status}
      initialBuyer={props.contract.buyer}
      initialSeller={props.contract.seller}
      onSave={null}
      isDisabled={true}
      isMobile={false}
      onSaveAndCancel={null}
    />
  );
};

export default ContractDetails;
