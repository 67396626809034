import React from 'react';
import { formatDateFromString } from '@helpers/utils';
import { Text, IconCalendar } from '@ftdr/blueprint-components-react';

export interface Props {
  /** date types and the date value */
  dates: { [key: string]: string };
  /** Taken separately from dates, as it changes */
  expirationDate: string;
}

export const CardViewOrderDates: React.FC<Props> = (props) => {
  const rows = [
    {
      name: 'Order',
      effectiveDate: formatDateFromString(props.dates?.listDate), //For Order row display LISTDATE.
      expirationDate: formatDateFromString(props.dates?.listingExpirationDate),
    },
    {
      name: "Seller's Coverage",
      effectiveDate: formatDateFromString(props.dates?.sellersCoverageEffectiveDate),
      expirationDate: formatDateFromString(props.dates?.sellersCoverageExpirationDate),
    },
    {
      name: 'Contract',
      effectiveDate: formatDateFromString(props.dates?.effectiveDate), //For Contract row display EFFDATE.
      expirationDate: formatDateFromString(
        props.dates?.effectiveDate ? props.dates?.expirationDate : '',
      ),
    },
  ];

  const rowView = rows.map((row, index) => {
    const effectiveDate = row.effectiveDate !== '';
    const expirationDate = row.expirationDate !== '';
    if (effectiveDate || expirationDate) {
      return (
        <>
          <tr key={index} className="border-1 border-gray-300">
            <td
              id="name"
              style={{ wordWrap: 'break-word' }}
              className="py-2 border-1 border-gray-300 text-sm text-center"
            >
              {row.name}
            </td>
            <td
              id="effectiveDate"
              style={{ wordWrap: 'break-word' }}
              className="py-2 border-1 border-gray-300 text-sm text-center"
            >
              {row.effectiveDate}
            </td>
            <td
              id="expirationDate"
              style={{ wordWrap: 'break-word' }}
              className="py-2 border-1 border-gray-300 text-sm text-center"
            >
              {row.expirationDate}
            </td>
          </tr>
        </>
      );
    }
    return <></>;
  });

  return (
    <div className="card mt-8 p-4">
      <Text className="h5 font-bold" variant="heading-06">
        <IconCalendar
          title="calendar"
          size={18}
          color="gray"
          className="inline-block max-w-full align-middle -mt-1 mr-2"
        />
        Important Dates
      </Text>

      <Text id="order-entry-date" className="m-2 h5 font-bold" variant="caption">
        Order Entry Date: &nbsp;
        <span className="font-normal">{props.dates?.creationDate}</span>
      </Text>

      <Text id="projected-closing-date" className="m-2 h5 font-bold" variant="caption">
        Projected Closing Date: &nbsp;
        <span className="font-normal">{props.dates?.projectedClosingDate}</span>
      </Text>

      <table id="important-dates-table" style={{ tableLayout: 'fixed' }} className="w-full">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-1 border-1 border-gray-300">&nbsp;</th>
            <th style={{ wordWrap: 'break-word' }} className="py-2 border-1 border-gray-300">
              <Text variant="label">Effective Date</Text>
            </th>
            <th style={{ wordWrap: 'break-word' }} className="py-2 border-1 border-gray-300">
              <Text variant="label">Expiration Date</Text>
            </th>
          </tr>
        </thead>

        <tbody id="important-dates-tablebody" className="text-sm border-1 border-gray-300">
          {rowView}
        </tbody>
      </table>
    </div>
  );
};
