import React, { useState } from 'react';
import DateRangeSelectionModal from '@components/dateRange/DateRangeSelectionModal';
import { FilterOperation, FilterType } from '@constants/dashboardFilters';
import { formatDate } from '@helpers/utils';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import { Accordion, AccordionSection } from '@ftdr/blueprint-components-react';
import { DateFilterButton } from '@components/button/DateFilterButton';
import { dateTypes } from '@constants/dates';
import { FilterChangeEvent, FilterTagItem } from '@components/misc/misc.models';
import { MIN_ABANDONED_ORDER_DATE_RANGE } from '@constants/formField-constants';

interface Props {
  addFilter: (label: string, event: FilterChangeEvent) => void;
  activeFilters: FilterTagItem[];
  statusMenuType: StatusMenuType;
}

const OrdersAbandonedFiltering: React.FC<Props> = (props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const dateRenderer = () => {
    return <DateFilterButton id="list_date_filter" onClick={() => setShowDatePicker(true)} />;
  };

  // TODO Consider moving this into the DateRange component directly
  const handleDateRangeFilter = (dateType: string, presetTitle: string, start, end) => {
    if (start && end && dateType) {
      const startDateStr = formatDate(start);
      const endDateStr = formatDate(end);

      props.addFilter(undefined, {
        operation: FilterOperation.ADD,
        type: FilterType.DATE,
        payload: {
          date: {
            start,
            end,
            // overriding and hardcoding because DateRangeSelectionModal defaults it to ESTCOE
            type: dateTypes.EFFDATE,
            presetTitle,
          },
        },
        label: `Entry Date: ${startDateStr} - ${endDateStr}`,
      });
    }
  };

  return (
    <>
      <Accordion mode="multiple">
        <AccordionSection label="Date Range" headerRenderer={dateRenderer} />
      </Accordion>
      <DateRangeSelectionModal
        open={showDatePicker}
        initialValues={
          props.activeFilters.filter((filter) => filter.type === FilterType.DATE)[0]?.payload.date
        }
        statusMenuType={props.statusMenuType}
        onChange={handleDateRangeFilter}
        onClose={() => setShowDatePicker(false)}
        minDate={MIN_ABANDONED_ORDER_DATE_RANGE}
      />
    </>
  );
};

export default OrdersAbandonedFiltering;
