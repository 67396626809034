import { Address } from '@apis/models/address.api.model';

export interface AgentSearchRequest {
  firstName?: string;
  lastName?: string;
  activeInactive?: string;
  email?: string;
  phone?: string;
  officeId?: string;
  officeType?: string;
}

export enum AgentType {
  RealEstate = 'RealEstate',
  ClosingCompany = 'ClosingCompany',
}

export interface AgentList {
  agentsList: Agent[];
}

export interface AgentSearchResponse {
  agentsList: AgentSearchRecord[];
  errors: string[];
}

export interface AgentPhone {
  phoneType: string;
  phoneNumber: string;
  extension: string;
}

export interface Agent {
  realEstateAgentID: string;
  agentType: AgentType;
  active: boolean;
  narID: string;
  firstName: string;
  lastName: string;
  phoneNumbers: AgentPhone[];
  email: string;
  officeID: string;
  archivedOfficeID: string[];
  licenseID?: string;
  userID?: string;
  checkExistingAgents?: boolean;
  createAgentMeta?: { [key: string]: string };
}

export interface AgentSearchRecord {
  active: boolean;
  agentType: string;
  archivedOfficeID?: string[];
  email: string;
  firstName: string;
  lastName: string;
  narID?: string;
  officeID: string;
  phoneNumbers: {
    phoneType?: string;
    phoneNumber: string;
    extension?: string;
  }[];
  realEstateAgentID?: string;
  checkExistingAgents?: boolean;
  createAgentMeta?: {
    existingAgent: string;
    movedOffice: string;
  };
}

export interface AgentMatchSearchRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  tenant?: string;
  agentType?: string;
}

export interface ExactMatchSearchResponse {
  winner?: AgentSearchRecord;
  nonWinner?: AgentSearchRecord[];
  errors?: any;
}

export interface PossibleMatchSearchResponse {
  results?: PossibleAgent[];
  errors?: any;
}

export interface PossibleAgent {
  agentID?: string;
  agentType?: string;
  officeID?: string;
  officeName?: string;
  officeAddress?: Address;
  tenant?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phones?: AgentPhone[];
  email?: string;
  webLoginID?: string;
}

export interface AgentOfficeMoveRequest {
  agentID: string;
  officeID: string;
  typeCode: string;
}

export interface AgentOfficeRequest {
  agentID: string;
  officeID: string;
}

export interface AgentAuditRequest {
  createAgentRequest: AgentSearchRecord;
  sourceApplication: string;
  matched?: AgentOfficeRequest; //For exact match or select fuzzy match
  userAction?: string;
  creation?: AgentOfficeRequest; //For no match or fuzzy match ignored
  fuzzyAgentIDs?: string[];
}

export interface AgentAuditResponse {
  id?: string;
  errors?: any;
}
