import React, { useEffect, useState, useRef } from 'react';
import {
  Notification,
  Text,
  Link,
  IconButton,
  IconCancel,
  CollapsibleText,
} from '@ftdr/blueprint-components-react';
import { JUST_LOGGED_IN_BANNER_KEY } from '@helpers/localStorage.utils';
import ZestyApi from '@apis/zesty.apis';
import { getBrand } from '@helpers/brand.utils';
import debounce from 'lodash/debounce';

const BannerNotification = () => {
  useEffect(() => {
    initBannerText();
  }, []);
  const [showButton, setShowButton] = React.useState(true);
  const containerRef = useRef(null);
  const [isBannerActive, setIsBannerActive] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');
  const [textExpanded, setTextExpanded] = useState(false);
  const initBannerText = () => {
    ZestyApi.GetBannerText().then((res) => {
      if (getBrand() === 'AHS') {
        const resFiltered = res.filter(
          (item) => item.authenticated && item.masterswitch && item.AHS,
        );
        if (resFiltered.length > 0) {
          setIsBannerActive(true);
          setBannerMessage(resFiltered[0].message);
        }
      } else if (getBrand() === 'HSA') {
        const resFiltered = res.filter(
          (item) => item.authenticated && item.masterswitch && item.HSA,
        );
        if (resFiltered.length > 0) {
          setIsBannerActive(true);
          setBannerMessage(resFiltered[0].message);
        }
      }
    });
  };
  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };
    const checkButtonAvailability = () => {
      if (containerRef.current) {
        setShowButton(hasClamping(containerRef.current));
      }
    };
    const debouncedCheck = debounce(checkButtonAvailability, 50);
    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);
    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef, bannerMessage]);
  const toggleText = () => {
    setTextExpanded(!textExpanded);
  };
  return (
    <div className="bannerNotification">
      {localStorage.getItem(JUST_LOGGED_IN_BANNER_KEY) && isBannerActive && (
        <div className="relative flex">
          <Notification className="w-full" showStatusLabel={false} status="info">
            <CollapsibleText
              as="span"
              lineClamp={1}
              className="max-w-full"
              state={textExpanded ? 'expanded' : 'collapsed'}
            >
              <Text
                ref={containerRef}
                id="bannerText"
                variant="body-long"
                className="mr-4"
                dangerouslySetInnerHTML={{ __html: bannerMessage }}
              />
              {showButton ? (
                <Link
                  onClick={toggleText}
                  color="interactive"
                  underline="always"
                  className="color-primary-300"
                >
                  {textExpanded ? 'less' : 'learn more'}
                </Link>
              ) : (
                <></>
              )}
            </CollapsibleText>
            <IconButton
              onClick={() => (
                setIsBannerActive(false), localStorage.removeItem(JUST_LOGGED_IN_BANNER_KEY)
              )}
              variant="ghost"
              size="small"
              label="Dismiss"
              icon={<IconCancel />}
              className="ghost notification-floating-close notification-floating-medium-close"
            />
          </Notification>
        </div>
      )}
    </div>
  );
};
export default BannerNotification;
