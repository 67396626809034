import { BaseAPI } from './_api';
import {
  SaveAndEmailQuoteRequest,
  ShareDocumentEmailMessage,
  SharePlanAndPriceRequest,
  SharePlanAndPriceResponse,
} from '@apis/models/emailsender.api.model';

class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public shareDocument(shareDocumentEmailMessage: ShareDocumentEmailMessage): Promise<boolean> {
    const body: ShareDocumentEmailMessage = {
      email: shareDocumentEmailMessage.email,
      link: shareDocumentEmailMessage.link,
      filename: shareDocumentEmailMessage.filename,
      fileType: shareDocumentEmailMessage.fileType,
    };
    return this.axios
      .post<any, any>(`${this.getHost()}/share-documents`, body)
      .then((res) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  }

  public sharePlanAndPrice(req: SharePlanAndPriceRequest): Promise<SharePlanAndPriceResponse> {
    return this.axios.post<any, any>(`${this.getHost()}/share/plan-and-price`, req);
  }

  public saveAndEmailQuote(req: SaveAndEmailQuoteRequest): Promise<SharePlanAndPriceResponse> {
    return this.axios.post<any, any>(`${this.getHost()}/plans-and-prices/order`, req);
  }
}

const SalesMaterialApi = new API();
export default SalesMaterialApi;
