import React from 'react';
import { OptionalCoverageIconValue, ZestyWYSIWYGField } from '@apis/models/zesty.api.model';
import {
  IconCheckboxChecked,
  IconCheckboxUnchecked,
  IconCoolingLightBackground,
  IconDishwasherLightBackground,
  IconElectronicsLightBackground,
  IconGuestHouseLightBackground,
  IconHeatingLightBackground,
  IconHomeWarrantyLightBackground,
  IconMicrowaveLightBackground,
  IconOperatorMono,
  IconPoolSpaLightBackground,
  IconProps,
  IconRangeOvenLightBackground,
  IconRefrigeratorLightBackground,
  IconRoofCoverageLightBackground,
  IconSepticPumpLightBackground,
  IconShieldAssurancesLightBackground,
  IconTubMotorLightBackground,
  IconWasherLightBackground,
  IconWaterHeaterLightBackground,
  IconWellPumpLightBackground,
  Text,
} from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';

export interface OptionalCoverageItem {
  /** the cvg id */
  id: string;
  name: ZestyWYSIWYGField;
  subtext: ZestyWYSIWYGField;
  icon: string;
  price: number;
  isGroupCoverage?: boolean;
}

export interface PropsCardPlanPriceCoverage extends OptionalCoverageItem {
  selected?: boolean;
  onSelect: SelectCoverageHandler;
}

type SelectCoverageHandler = (item: OptionalCoverageItem) => void;

const CardPlanPriceCoverage: React.FC<PropsCardPlanPriceCoverage> = (props) => {
  const getIcon = () => {
    let CoverageIcon;
    const iconProps: IconProps = {
      size: 96,
    };

    switch (props.icon?.toLowerCase()) {
      case OptionalCoverageIconValue.Electronics:
        CoverageIcon = IconElectronicsLightBackground;
        break;
      case OptionalCoverageIconValue.GuestHouse:
        CoverageIcon = IconGuestHouseLightBackground;
        break;
      case OptionalCoverageIconValue.HomeWarranty:
        CoverageIcon = IconHomeWarrantyLightBackground;
        break;
      case OptionalCoverageIconValue.PoolSpa:
        CoverageIcon = IconPoolSpaLightBackground;
        break;
      case OptionalCoverageIconValue.Refrigerator:
        CoverageIcon = IconRefrigeratorLightBackground;
        break;
      case OptionalCoverageIconValue.RoofCoverage:
        CoverageIcon = IconRoofCoverageLightBackground;
        break;
      case OptionalCoverageIconValue.SepticPump:
        CoverageIcon = IconSepticPumpLightBackground;
        break;
      case OptionalCoverageIconValue.WellPump:
        CoverageIcon = IconWellPumpLightBackground;
        break;
      case OptionalCoverageIconValue.Cooling:
        CoverageIcon = IconCoolingLightBackground;
        break;
      case OptionalCoverageIconValue.Dishwasher:
        CoverageIcon = IconDishwasherLightBackground;
        break;
      case OptionalCoverageIconValue.Heating:
        CoverageIcon = IconHeatingLightBackground;
        break;
      case OptionalCoverageIconValue.Microwave:
        CoverageIcon = IconMicrowaveLightBackground;
        break;
      case OptionalCoverageIconValue.RangeOven:
        CoverageIcon = IconRangeOvenLightBackground;
        break;
      case OptionalCoverageIconValue.ShieldAssurances:
        CoverageIcon = IconShieldAssurancesLightBackground;
        break;
      case OptionalCoverageIconValue.TubMotor:
        CoverageIcon = IconTubMotorLightBackground;
        break;
      case OptionalCoverageIconValue.Washer:
        CoverageIcon = IconWasherLightBackground;
        break;
      case OptionalCoverageIconValue.WaterHeater:
        CoverageIcon = IconWaterHeaterLightBackground;
        break;
      default:
        CoverageIcon = IconOperatorMono;
        iconProps.color = 'gray';
        break;
    }
    return <CoverageIcon {...iconProps} />;
  };

  const getCheckbox = () => {
    const CheckboxIcon = props.selected ? IconCheckboxChecked : IconCheckboxUnchecked;
    return <CheckboxIcon color={'interactive'} />;
  };

  return (
    <div
      id={props.id}
      className={classNames([
        'card-plan-price--coverage bg-white p-2 md:p-6 shadow-2 rounded',
        'border-3',
        props.selected ? 'border-interactive' : 'border-white',
      ])}
      onClick={() => props.onSelect(props)}
    >
      <div className="flex flex-row space-x-4">
        <div id={`${props.id}--checkbox`} className="flex items-center">
          {getCheckbox()}
        </div>
        <div id={`${props.id}--icon`} className="hidden md:flex items-center -my-6">
          {getIcon()}
        </div>
        <div className="flex flex-col flex-1 md:space-y-1">
          <Text
            id={`${props.id}--heading`}
            color="primary"
            className="my-0 zesty-html"
            dangerouslySetInnerHTML={{ __html: props.name }}
          />
          <Text
            id={`${props.id}--heading--caption`}
            className="card-plan-price--coverage--caption my-0 zesty-html"
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
        </div>
        <Text id={`${props.id}--price`} className="font-bold">
          ${props.price}
        </Text>
      </div>
    </div>
  );
};

export default CardPlanPriceCoverage;
