import React, { useContext, useEffect, useState } from 'react';
import ModalSplashScreenOverlay from '@components/modal/ModalSplashScreenOverlay';
import ZestyApi from '@apis/zesty.apis';
import { getBrand } from '@helpers/brand.utils';
import { useNavigation } from 'react-navi';
import { fireGAEvent } from '@app/core/tracking.service';
import { SPLASH_SCREEN_EVENT } from '@constants/ga-events.constants';
import { ModalCloseType } from '@components/modal/Modal';
import { Brand } from '@constants/brands';
import { ZestySplashScreenLearnMoreBehavior } from '@apis/models/zesty.api.model';
import ProfileContext from '@context/ProfileContext';
import { hasOfficesInState } from '@services/helpers/profile.offices.helper';

export const clearSoftSplashScreenDismissals = () => {
  const keysToRemove = Object.keys(localStorage).filter((key) =>
    key.includes('splashScreenDismissed-session'),
  );
  keysToRemove.forEach((key) => localStorage.removeItem(key));
};

const SplashScreenLauncher = (props) => {
  const { profile } = useContext(ProfileContext);

  const ACTION_LEARN_MORE = 'Learn More';
  const ACTION_CLOSE_OVERLAY = 'Close Overlay';
  const ACTION_DONT_SHOW_ME = "Don't Show Me";

  const [isSplashScreenDataFetched, setIsSplashScreenDataFetched] = useState(false);
  const [splashScreenData, setSplashScreenData] = useState(null);
  const [isDismissed, setIsDismissed] = useState(false);
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);

  const navigation = useNavigation();

  const splashScreenDismissedKey = (zuid, sessionOnly = false) => {
    return `splashScreenDismissed${sessionOnly ? '-session' : ''}-${zuid}`;
  };

  const checkHardDismissal = (zuid) => {
    const storedFlag = localStorage.getItem(splashScreenDismissedKey(zuid));
    return storedFlag === 'true';
  };

  const checkSoftDismissal = (zuid) => {
    const storedFlag = localStorage.getItem(splashScreenDismissedKey(zuid, true));
    return storedFlag === 'true';
  };

  const doHardDismissal = (zuid) => {
    setIsDismissed(true);
    localStorage.setItem(splashScreenDismissedKey(zuid), 'true');
    fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_CLOSE_OVERLAY));
    fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_DONT_SHOW_ME));
  };

  const doSoftDismissal = (zuid) => {
    setIsDismissed(true);
    localStorage.setItem(splashScreenDismissedKey(zuid, true), 'true');
    fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_CLOSE_OVERLAY));
  };

  const handleDialogClose = (closeType) => {
    /*
    if(closeType === 'outside-click') { // we don't care if the box is checked and we don't want to fire any GA events (the original ticket requirement) if we keep this, remove 'outside-click' from the block below
      setIsDismissed(true);
      return;
    }
    */
    if (
      closeType === ModalCloseType.XButton ||
      ModalCloseType.OutsideClick ||
      ModalCloseType.EscapeKey ||
      ModalCloseType.LearnMore
    ) {
      if (isDontShowAgainChecked) {
        doHardDismissal(splashScreenData.zuid);
      } else {
        doSoftDismissal(splashScreenData.zuid);
      }
    }
  };

  const handleLearnMore = () => {
    if (splashScreenData.learn_more_behavior === ZestySplashScreenLearnMoreBehavior.UrlInSameTab) {
      navigation.navigate(splashScreenData.linked_url);
      fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_LEARN_MORE));
    }
    if (splashScreenData.learn_more_behavior === ZestySplashScreenLearnMoreBehavior.UrlInNewTab) {
      window.open(splashScreenData.linked_url, '_blank');
      fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_LEARN_MORE));
    }
    if (splashScreenData.learn_more_behavior === ZestySplashScreenLearnMoreBehavior.MediaInNewTab) {
      window.open(splashScreenData.linked_media[0].url, '_blank');
      fireGAEvent(SPLASH_SCREEN_EVENT(splashScreenData.nickname, ACTION_LEARN_MORE));
    }
  };

  const fetchSplashScreenData = () => {
    ZestyApi.GetSplashScreen().then((res) => {
      const now = new Date();
      const brand = getBrand();

      const filteredResults = res.filter(
        (item) =>
          item.time_period_begin.getTime() <= now.getTime() &&
          item.time_period_end.getTime() >= now.getTime() &&
          ((item.ahs_enabled && brand === Brand.AHS) ||
            (item.hsa_enabled && brand === Brand.HSA)) &&
          !checkHardDismissal(item.zuid) &&
          !checkSoftDismissal(item.zuid) &&
          (hasOfficesInState(profile, item.states?.toUpperCase().split(',')) ||
            item.states?.length === 0),
      );

      if (filteredResults.length > 0) {
        setSplashScreenData(filteredResults[0]);
        setIsDismissed(false);
      } else {
        setSplashScreenData(null);
        setIsDismissed(true);
      }

      setIsSplashScreenDataFetched(true);
    });
  };

  useEffect(() => {
    fetchSplashScreenData();
  }, []);

  return isSplashScreenDataFetched && splashScreenData !== null && !isDismissed ? (
    <ModalSplashScreenOverlay
      isActive={true}
      imageUrl={splashScreenData.background_image[0].url}
      headline={splashScreenData.headline}
      bodyText={splashScreenData.body_text}
      onDontShowAgainChange={setIsDontShowAgainChecked}
      onDialogClose={handleDialogClose} // gets called when the dialog is closed by click outside, x button, learn more
      onLearnMore={handleLearnMore} // gets called when learn more is clicked (after onDialogClose is called)
    />
  ) : (
    <></>
  );
};

export default SplashScreenLauncher;
