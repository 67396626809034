import React from 'react';

const RemoveClosingInfo = () => {
  return (
    <p>
      Clearing closing information will also remove all buyer information. Do you want to proceed?
    </p>
  );
};

export default RemoveClosingInfo;
