import React, { useEffect, useState } from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';

import contractApiService from '@apis/contract.api';
import { msgsWParams } from '@app/locales/en';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS,
  ORDER__SEND_DOCUMENTS,
} from '@constants/ga-events.constants';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import SendDocuments, {
  GetLatestRecipients,
  Recipient,
  Type as DocumentType,
} from '@components/SendDocuments';
import { getUniqueDocumentTypes } from '@helpers/order.utils';
import ContractApi from '@apis/contract.api';
import { ContractModelVariant } from '@app/models/contract.model';
import { Contract } from '@apis/models';

export type DrawerNotificationsProps = DrawerProps &
  Required<Pick<DrawerProps, 'isActive'>> & {
    contractId: any;
    newOrderData: any;
    userDetails: any;
    cooperatingAgentCustom?: any;
  };

const DrawerNotifications: React.FC<DrawerNotificationsProps> = (props) => {
  const [isContractFetched, setIsContractFetched] = useState(false);

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [contract, setContract] = useState<Contract>(undefined);

  const { addSuccessToQueue } = useGlobalAlert();

  const makeEmailDocumentsCall = (recipients: any): Promise<any> => {
    const docEmails = recipients
      .filter((recipient) => recipient.selectedTypes.length > 0)
      .map(({ email, selectedTypes }) => {
        return { email, docs: selectedTypes };
      });
    const request = {
      docEmails,
    };
    return contractApiService
      .emailContractDocuments(request, Number(props.contractId))
      .then((res) => {
        if (res) {
          const uniqueDocTypes = getUniqueDocumentTypes(recipients);
          fireGAEvent(DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS(uniqueDocTypes));

          // Dispaly success message for invoice if needed
          if (uniqueDocTypes.includes('Invoice')) {
            addSuccessToQueue(
              msgsWParams.DOCUMENTS_SENT_SUCCESS_BY_DOC_TYPE(request.docEmails, 'invoice'),
            );
          }
          // Dispaly success message for confirmation if needed
          if (uniqueDocTypes.includes('Confirmation')) {
            addSuccessToQueue(
              msgsWParams.DOCUMENTS_SENT_SUCCESS_BY_DOC_TYPE(request.docEmails, 'confirmation'),
            );
          }
        }
      })
      .finally(() => handleCloseNotifications());
  };
  const handleCloseNotifications = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.contractId) {
      if (!props.newOrderData) {
        ContractApi.getContractDetails([props.contractId], true, true)
          .then((fetchedContracts) => {
            if (fetchedContracts[0]) {
              setContract(fetchedContracts[0]);
              setRecipients(
                GetLatestRecipients(
                  fetchedContracts[0],
                  ContractModelVariant.API,
                  recipients,
                  props.userDetails.email,
                  `${props.userDetails.firstName} ${props.userDetails.lastName}`,
                ),
              );
            }
          })
          .finally(() => setIsContractFetched(true));
      } else {
        setContract(props.newOrderData);
        const contractRecipientData = {
          ...props.newOrderData,
          cooperatingAgentInvited: props.cooperatingAgentCustom,
        };
        setRecipients(
          GetLatestRecipients(
            contractRecipientData,
            ContractModelVariant.API,
            recipients,
            props.userDetails.email,
            `${props.userDetails.firstName} ${props.userDetails.lastName}`,
          ),
        );
      }
    }
  }, [props.contractId, props.newOrderData]);

  return (
    <Drawer {...props}>
      {props.isActive && (
        <SendDocuments
          recipients={recipients}
          allowedTypes={[DocumentType.Confirmation, DocumentType.Invoice]}
          onChange={setRecipients}
          onSave={() => makeEmailDocumentsCall(recipients)}
          onCancel={() => handleCloseNotifications()}
          newOrderData={props.newOrderData}
        />
      )}
    </Drawer>
  );
};

export default DrawerNotifications;
