import React, { ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  IconSearch,
  Input,
  Pagination,
  Radio,
  Select,
  Text,
} from '@ftdr/blueprint-components-react';
import CardRadio from '@components/card/CardRadio';
import CardRadioItem from '@components/card/CardRadioItem';
import { isZipValid, validateZipCode } from '@services/validation/ValidationRules';
import useForm, { ValidateFc } from '@helpers/UseForm';
import OfficeApi from '@services/apis/office.api';
import { getBrand } from '@helpers/brand.utils';
import { deleteUndefinedValues, formatPhoneNumber } from '@helpers/utils';
import * as c from '@constants/formField-constants';
import { PER_PAGE_TEN_RECORDS } from '@constants/pagination-constants';
import * as paginationActions from '@services/pagination/Pagination';
import { searchCityStateByZip } from '@services/zip.service';
import { classNames, isMobileView } from '@utils';
import { officeTypeFullFormDictionary } from '@constants/dictionaries';
import ProfileContext from '../../context/ProfileContext';
import { usStatesForBDSSelect } from '@constants/us-states';
import { getFormInputErrorId } from '@storybook/addon-links';
import { Office, OfficeList, OfficeSearchRequest } from '@apis/models';
import PhoneInput, { validatePhoneInput } from '@components/input/PhoneInput';
import { OfficeType } from '@app/models';

enum OfficeSearchType {
  CityState = 'citystate',
  Zip = 'zip',
  Phone = 'phone',
  DistributorID = 'distributorid',
}

interface OfficeItem extends Office {
  readOnly?: boolean;
}

interface Props {
  typeCode: string;
  excludeBrandSearch?: boolean;
  ignoreReadOnly?: boolean;
  officeButtons?: any;
  setSelectedOffices?: any;
  onCancel?: any;
  multiSelect?: boolean;
}

interface SearchOfficeFormData {
  zipCode: string;
  searchCity: string;
  searchState: string;
  phone: string;
  distributorID: string;
}

/** pagination logic was embedded into the values when it shouldn't need to be. Should clean that up */
type FormOfficeSearchValues = SearchOfficeFormData & any;

const validateOfficeForm =
  (officeSearchType: OfficeSearchType): ValidateFc<SearchOfficeFormData> =>
    (values, isTouched) => {
      let errors: typeof values = {};

      switch (officeSearchType) {
        case OfficeSearchType.Zip:
          if (isTouched.zipCode) {
            errors.zipCode = validateZipCode(values.zipCode);
          }
          break;
        case OfficeSearchType.CityState:
          if (isTouched.searchCity && !values.searchCity) {
            errors.searchCity = c.CITY_REQUIRED;
          }
          if (isTouched.searchState && !values.searchState) {
            errors.searchState = c.STATE_REQUIRED;
          }
          break;
        case OfficeSearchType.Phone:
          errors.phone = validatePhoneInput(values.phone, true);
          break;
        case OfficeSearchType.DistributorID:
          if (isTouched.distributorID && !values.distributorID) {
            errors.distributorID = c.DISTRIBUTOR_ID_REQUIRED;
          }
          break;
        default:
          console.warn('searchType validation not implemented:', officeSearchType);
          break;
      }

      errors = deleteUndefinedValues(errors, true);

      return errors;
    };

const FormOfficeSearch: React.FC<Props> = (props) => {
  const { profile } = useContext(ProfileContext);

  const [officeSearchType, setOfficeSearchType] = useState(OfficeSearchType.CityState);
  const [doesZipExist, setDoesZipExist] = useState(true);

  const { values, errors, handleBlur, setErrors, setValues, forceTouchAll, handleSubmit } =
    useForm<FormOfficeSearchValues>(validateOfficeForm(officeSearchType), {
      searchState: '',
      searchCity: '',
      zipCode: '',
      phone: '',
      distributorID: '',
    });

  const [state, setState] = [values, setValues]; // de-dup the values/states to reduce clutter

  const [hasResults, setHasResults] = useState(false);
  const [filterOfficeList, setFilterOfficeList] = useState([]);
  const [officeList, setOfficeList] = useState<OfficeItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  /** The search request that was completed on CTA click, used for display purposes */
  const [searchRequest, setSearchRequest] = useState<OfficeSearchRequest>({});

  // for pagination purpose - current ten records list to be displayed & initial active page
  const [currentFilterOfficeList, setCurrentFilterOfficeList] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(PER_PAGE_TEN_RECORDS);

  const resultsContainer = useRef(null);

  const searchOffice: React.FormEventHandler = (e) => {
    e.preventDefault();

    // check UseForm errors
    handleSubmit(e);
    const { errors: formErrors, hasErrors } = forceTouchAll();
    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    const brand = props.excludeBrandSearch ? undefined : getBrand(); // Ignore brand in our search if not necessary

    const requestObj: OfficeSearchRequest = {
      type: props.typeCode,
      brand,
      zip: state.zipCode,
      city: state.searchCity,
      state: state.searchState,
      phone: state.phone,
    };

    let officeSearch: Promise<OfficeList>;
    if (state.distributorID !== '') {
      officeSearch = OfficeApi.getOfficeList([
        {
          id: state.distributorID,
          type: props.typeCode || OfficeType.RealEstate,
        },
      ]).then((res) => {
        return {
          offices: res.offices.filter((o) => {
            return String(o.active) === 'true' && (props.excludeBrandSearch || brand === o.brand);
          }),
        };
      });
    } else {
      officeSearch = OfficeApi.searchOffice(requestObj);
    }

    setIsLoading(true);
    officeSearch
      .then((res) => {
        setIsLoading(false);
        setSearchRequest(requestObj);

        if (res !== null && Object.keys(res).length && res.offices.length > 0) {
          let profileOffices = [];
          if (profile.offices) {
            profileOffices = [...profileOffices, ...profile.offices];
          }
          if (profile.workedWithOffices) {
            profileOffices = [...profileOffices, ...profile.workedWithOffices];
          }

          let officeResults: OfficeItem[] = res.offices;

          if (!props.ignoreReadOnly) {
            officeResults = res.offices.map((office) => {
              const officeItem: OfficeItem = office;
              if (
                profileOffices.find(
                  (o) =>
                    office.id === o.id &&
                    officeTypeFullFormDictionary[office.type] ===
                    officeTypeFullFormDictionary[o.type],
                )
              ) {
                officeItem.readOnly = true;
              }
              return officeItem;
            });
          }

          setHasResults(true);
          setOfficeList(officeResults);
          setFilterOfficeList(officeResults);
          setErrors({ ...errors, zipCode: '' });

          // Initial ten records -- for pagination purpose
          const totalPageCount = paginationActions.setTotalPageCount(
            officeResults.length,
            itemsPerPage,
          );

          setCurrentFilterOfficeList(officeResults.slice(0, itemsPerPage));
          setValues({
            ...values,
            filterOfficeList: officeResults,
            officeList: officeResults,
            TotalPages: totalPageCount,
          });
          if (props.officeButtons) props.officeButtons(true);
        } else {
          setHasResults(false);
          setSearchClicked(true);
          if (props.officeButtons) props.officeButtons(true);
          switch (officeSearchType) {
            case OfficeSearchType.Zip:
              setErrors({
                zipCode: c.INVALID_ZIP_FOR_OFFICE,
              });
              break;
            case OfficeSearchType.CityState:
              setErrors({
                searchCity: c.INVALID_CITY_STATE_FOR_OFFICE,
              });
              break;
            case OfficeSearchType.Phone:
              setErrors({
                phone: c.INVALID_PHONE_FOR_OFFICE,
              });
              break;
            case OfficeSearchType.DistributorID:
              setErrors({
                distributorID: c.INVALID_DISTRIBUTOR_ID_FOR_OFFICE,
              });
              break;
            default:
              console.warn('Missing error response handling for type', officeSearchType);
          }
        }
      })
      .catch((err) => {
        console.error('office search failed', err);
        setIsLoading(false);
        setHasResults(false);
        setSearchClicked(true);
        if (props.officeButtons) props.officeButtons(true);
      });
  };

  const [isReadOnlyOffice, setIsReadOnlyOffice] = useState(false);

  const selectedOffice = (idx) => {
    if (currentFilterOfficeList[idx].readOnly) {
      setIsReadOnlyOffice(true);
    } else {
      props.setSelectedOffices(currentFilterOfficeList[idx]);
    }
  };

  const filterOfficeByName = (event) => {
    const value = event.target.value.toLocaleLowerCase();
    const update = officeList.filter((item) => {
      // search by company name, address & phone number
      const searchStr = `${item.name} ${item.phones.office} ${item.address.address1} ${item.address.address2} ${item.address.city}
            ${item.address.state} ${item.address.zip} ${item.id}`;

      return searchStr.toLocaleLowerCase().includes(value);
    });

    setFilterOfficeList(update || officeList);

    // set current ten records to be dspalyed --for pagination purpose
    setCurrentFilterOfficeList(update.slice(0, itemsPerPage) || officeList.slice(0, itemsPerPage));
    setActivePage(0); // setting active page to fist page after every search
  };

  // Pagination
  // -- set total page count for pagination--
  useEffect(() => {
    if (filterOfficeList.length) {
      const totalPageCount = paginationActions.setTotalPageCount(
        filterOfficeList.length,
        itemsPerPage,
      );
      setValues({ ...values, TotalPages: totalPageCount });
      setCurrentFilterOfficeList(filterOfficeList.slice(0, itemsPerPage));
      setActivePage(0);
    }
  }, [filterOfficeList]);

  // -- get current 10 records of active(current) page
  useEffect(() => {
    paginationActions.getAcivePageData(
      filterOfficeList,
      activePage,
      itemsPerPage,
      setCurrentFilterOfficeList,
    );
  }, [activePage]);

  const handleZipChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event) {
      let cleanedZip = event.target.value;

      cleanedZip = cleanedZip?.match(/\d+/g)?.join() || '';

      if (cleanedZip.length <= 5) {
        setState({ ...state, zipCode: cleanedZip });
      }
    }
    setErrors({ ...errors, zipCode: '' });
  };

  const handleDistributorIDChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event) {
      let cleanedDistributorID = event.target.value;
      cleanedDistributorID = cleanedDistributorID?.match(/\d+/g)?.join() || '';
      setState({ ...state, distributorID: cleanedDistributorID });
    }
    setErrors({ ...errors, distributorID: '' });
  };

  const handleCityChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event) {
      setState({ ...state, searchCity: event?.target.value || '' });
    }
    setErrors({ ...errors, searchCity: '' });
  };

  const onStateSelect = (item) => {
    setState({ ...state, searchState: item.value });
    setErrors({ ...errors, searchState: '' });
  };

  useEffect(() => {
    setDoesZipExist(true);
    if (state.zipCode) {
      if (isZipValid(state.zipCode)) {
        // check if zip code is valid or not
        searchCityStateByZip(state.zipCode).then((res) => {
          if (!res) setDoesZipExist(false);
        });
      } else {
        setDoesZipExist(false);
      }
    }
  }, [state.zipCode]);

  /** On click, clear out the state fields as needed */
  const onRadioOfficeSearchClick = (searchType: OfficeSearchType) => {
    setOfficeSearchType(searchType);
    clearInputsAndErrorsBySelectedSearch(searchType);
  };

  const clearInputsAndErrorsBySelectedSearch = (searchType: OfficeSearchType) => {
    const resetTo = (
      stateToKeep: React.SetStateAction<Partial<any>>,
      errorsToKeep: React.SetStateAction<Partial<any>>,
    ) => {
      setState({
        ...state,
        searchCity: '',
        searchState: '',
        zipCode: '',
        phone: '',
        distributorID: '',
        ...stateToKeep,
      });
      setErrors({
        ...errors,
        searchCity: '',
        searchState: '',
        zipCode: '',
        phone: '',
        distributorID: '',
        ...errorsToKeep,
      });
    };
    switch (searchType) {
      case OfficeSearchType.CityState:
        resetTo(
          { searchCity: state.searchCity, searchState: state.searchState },
          { searchCity: errors.searchCity, searchState: errors.searchState },
        );
        break;
      case OfficeSearchType.Zip:
        resetTo({ zipCode: state.zipCode }, { zipCode: errors.zipCode });
        break;
      case OfficeSearchType.Phone:
        resetTo({ phone: state.phone }, { phone: errors.phone });
        break;
      case OfficeSearchType.DistributorID:
        resetTo({ distributorID: state.distributorID }, { distributorID: errors.distributorID });
        break;
      default:
        console.warn('searchType not implemented:', searchType);
        break;
    }
  };

  const isMobile = isMobileView();

  const ResultCount = () => {
    const resultCount = filterOfficeList.length;
    const resultLabel = resultCount === 1 ? 'result' : 'results';
    const isLocationSearch = searchRequest.zip || searchRequest.city;
    const locationString = searchRequest.zip
      ? searchRequest.zip
      : `${searchRequest.city}, ${searchRequest.state}`;

    return (
      <Text variant="body-short" id="wb_resultCount">
        {resultCount} {resultLabel} found {isLocationSearch ? ` in ${locationString}` : ''}
      </Text>
    );
  };

  return (
    <>
      <Dialog
        header="Information"
        modal={true}
        open={isReadOnlyOffice}
        actions={[
          <Button
            key="action"
            size="small"
            label="OK"
            onClick={() => setIsReadOnlyOffice(false)}
          />,
        ]}
        onClose={() => setIsReadOnlyOffice(false)}
      >
        <div>{c.OFFICE_ALREADY_ON_ACCOUNT}</div>
      </Dialog>

      <form onSubmit={searchOffice}>
        {/* City and State Search */}
        <SearchOptionContainer isSelected={officeSearchType === OfficeSearchType.CityState}>
          <SearchRadioContainer>
            <Radio
              className="py-1"
              id="radio--office-search--city-state"
              name="radio--office-search"
              label="City & State"
              checked={officeSearchType === OfficeSearchType.CityState}
              onChange={(event) => {
                event.preventDefault();
                onRadioOfficeSearchClick(OfficeSearchType.CityState);
              }}
            />
          </SearchRadioContainer>
          <SearchFieldsContainer>
            <Input
              className="w-full"
              formField={true}
              formFieldMessageId={getFormInputErrorId('wb_city')}
              id="wb_city"
              name="searchCity"
              label="City"
              hideLabel={true}
              placeholder="City"
              size="medium"
              disabled={officeSearchType !== OfficeSearchType.CityState}
              value={state.searchCity}
              error={errors.searchCity}
              onChange={handleCityChange}
              onBlur={handleBlur}
            />
            <div className="m-2" />
            <Select
              className="flex-none md:max-w-1/3"
              formField={true}
              formFieldMessageId={getFormInputErrorId('wb_state')}
              id="wb_state"
              name="searchState"
              label="State"
              hideLabel={true}
              placeholder="State"
              disabled={officeSearchType !== OfficeSearchType.CityState}
              error={errors.searchState}
              options={usStatesForBDSSelect}
              selected={
                state.searchState
                  ? usStatesForBDSSelect.find((o) => o.value === state.searchState)
                  : null
              }
              onSelect={onStateSelect}
            />
          </SearchFieldsContainer>
        </SearchOptionContainer>

        {/* Search by ZIP */}
        <SearchOptionContainer isSelected={officeSearchType === OfficeSearchType.Zip}>
          <SearchRadioContainer>
            <Radio
              className="py-1"
              id="radio--office-search--zip"
              label="ZIP Code"
              name="radio--office-search"
              checked={officeSearchType === OfficeSearchType.Zip}
              onChange={() => onRadioOfficeSearchClick(OfficeSearchType.Zip)}
            />
          </SearchRadioContainer>
          <SearchFieldsContainer>
            <Input
              formField={true}
              formFieldMessageId={getFormInputErrorId('wb_zipCodeAgentInfoOfc')}
              id="wb_zipCodeAgentInfoOfc"
              name="zipCode"
              label="ZIP Code"
              hideLabel={true}
              placeholder="ZIP Code"
              size="medium"
              inputMode="numeric"
              disabled={officeSearchType !== OfficeSearchType.Zip}
              value={state.zipCode}
              error={!doesZipExist ? c.INVALID_ZIP_CODE : errors.zipCode}
              onBlur={handleBlur}
              onChange={handleZipChange}
            />
          </SearchFieldsContainer>
        </SearchOptionContainer>

        {/* Search by Phone */}
        <SearchOptionContainer isSelected={officeSearchType === OfficeSearchType.Phone}>
          <SearchRadioContainer>
            <Radio
              className="py-1"
              id="radio--office-search--phone"
              label="Phone"
              name="radio--office-search"
              checked={officeSearchType === OfficeSearchType.Phone}
              onChange={() => onRadioOfficeSearchClick(OfficeSearchType.Phone)}
            />
          </SearchRadioContainer>
          <SearchFieldsContainer>
            <PhoneInput
              formField={true}
              id="input--phone"
              name="phone"
              label="Phone"
              hideLabel={true}
              placeholder="Phone"
              disabled={officeSearchType !== OfficeSearchType.Phone}
              value={state.phone}
              error={errors.phone}
              onClearError={() => setErrors({ ...errors, phone: null })}
              onError={(err) => setErrors({ ...errors, phone: err })}
              onChangeValue={(phone) => setState({ ...state, phone })}
            />
          </SearchFieldsContainer>
        </SearchOptionContainer>

        {/* Search by Distributor ID */}
        <SearchOptionContainer isSelected={officeSearchType === OfficeSearchType.DistributorID}>
          <SearchRadioContainer>
            <Radio
              className="py-1"
              id="radio--office-search--distributorid"
              label="Distributor ID"
              name="radio--office-search"
              checked={officeSearchType === OfficeSearchType.DistributorID}
              onChange={() => onRadioOfficeSearchClick(OfficeSearchType.DistributorID)}
            />
          </SearchRadioContainer>
          <SearchFieldsContainer>
            <Input
              formField={true}
              id="input--distributorid"
              name="distributorid"
              label="Distributor ID"
              hideLabel={true}
              placeholder="Distributor ID"
              disabled={officeSearchType !== OfficeSearchType.DistributorID}
              value={state.distributorID}
              error={errors.distributorID}
              onBlur={handleBlur}
              onChange={handleDistributorIDChange}
            />
          </SearchFieldsContainer>
        </SearchOptionContainer>

        {/* Cancel and Search Buttons */}
        <div className=" -mx-2">
          <div className="w-full flex md:items-end justify-end">
            {props.onCancel && (
              <div className="sm-max:pt-4 md:w-48 p-2">
                <Button
                  labelAlign="center"
                  width="full"
                  id="wb_cancelBtn"
                  label="Cancel"
                  onClick={props.onCancel}
                  size="medium"
                  variant="ghost"
                />
              </div>
            )}
            <div className="sm-max:pt-4 md:w-48 p-2">
              <Button
                labelAlign="center"
                width="full"
                id="wb_searchBtn"
                label="Search"
                size="medium"
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>

      {/* Results */}
      <div ref={resultsContainer} className="mt-8">
        {hasResults ? (
          <>
            <ResultCount />
            <div className="md:flex md:items-end">
              <Input
                id="wb_companyName"
                name="filter-by-company"
                placeholder={
                  isMobile
                    ? 'Search'
                    : 'Search this area by Company Name, Address, Phone or Distributor ID'
                }
                startEnhancer={<IconSearch color="gray" />}
                className="w-full px-2 flex-1"
                onChange={filterOfficeByName}
                label=""
              />
            </div>

            <div className="mt-4">
              <CardRadio
                id="office_list_results"
                onClick={selectedOffice}
                multiSelect={props.multiSelect}
              >
                {currentFilterOfficeList.map((item) => {
                  return (
                    <CardRadioItem
                      key={item.id}
                      readOnly={item.readOnly}
                      title={item.name}
                      leftContent={
                        <p className={classNames([item.readOnly ? 'text-gray-400' : ''])}>
                          {[item.address.address1 || '', item.address.address2 || '']
                            .filter((a) => a)
                            .join(', ')}
                          <br />
                          {`${item.address.city}, ${item.address.state} ${item.address.zip}`}
                        </p>
                      }
                      centerContent={
                        <p className={classNames([item.readOnly ? 'text-gray-400' : ''])}>
                          {formatPhoneNumber(item.phones.office)}
                        </p>
                      }
                      rightContent={
                        <p className={classNames([item.readOnly ? 'text-gray-400' : ''])}>
                          Dist. ID: {item.id}
                        </p>
                      }
                    />
                  );
                })}
              </CardRadio>
            </div>
          </>
        ) : (
          <div className="text-center">
            {!isLoading && !hasResults && searchClicked && (
              <>
                <IconSearch size={120} className="mb-2 inline" />
                <br />
                <Text variant="body-short">
                  <b>No Results Found</b>
                </Text>
              </>
            )}
            {isLoading && (
              <Text variant="body-short">
                <b>Loading...</b>
              </Text>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-6">
        {hasResults && currentFilterOfficeList.length && values.TotalPages > 1 ? (
          <Pagination
            itemsPerPage={itemsPerPage}
            itemsPerPageOptions={[{ value: '10', label: '10' }]}
            onItemsPerPageChange={(ippOption) => {
              setItemsPerPage(parseInt(ippOption.value, 10));
              setActivePage(0);
            }}
            onPageChange={(pageNum) => setActivePage(pageNum - 1)}
            totalItems={filterOfficeList.length}
            page={activePage + 1}
            hideViewAll={true}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const SearchOptionContainer = ({ children, isSelected }) => (
  <div
    className={`md:flex md:items-end FormOfficeSearch_Option ${isSelected ? 'FormOfficeSearch_Option_Selected' : ''}`}
  >
    <div className="w-full md:items-end">{children}</div>
  </div>
);

const SearchRadioContainer = ({ children }) => (
  <div className="sm-max:-mt-2 md:mb-1">{children}</div>
);

const SearchFieldsContainer = ({ children }) => (
  <div className="flex sm-max:flex-wrap sm-max:flex-col flex-1 ml-7 mb-4 FormOfficeSearch_Fields">
    {children}
  </div>
);

export default FormOfficeSearch;
