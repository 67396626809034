import React from 'react';
import { Text } from '@ftdr/blueprint-components-react';
import { addressObjectToString } from '@services/helpers';

const InactiveOffice = (props) => {
  return (
    <div>
      <Text variant="body-short">This office is no longer available for use in the system. </Text>
      <Text variant="body-short">
        <div>{props.inactiveOffice.name}</div>
        <div>{addressObjectToString(props.inactiveOffice.address)}</div>
        <div>Office ID: {props.inactiveOffice.id}</div>
      </Text>
    </div>
  );
};

export default InactiveOffice;
