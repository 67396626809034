import React, { Component } from 'react';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import { Button, IconMail, Accordion, AccordionSection } from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import { fireGAEvent } from '@app/core/tracking.service';
import { FAQ__CONTACT_US } from '@constants/ga-events.constants';

interface state {}

interface props {
  FAQs: FAQ[];
  FAQCategories: FAQCategory[];
  RoleIDType: string;
}

export default class FAQTemplate extends Component<props, state> {
  elementRefs = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  createRef = (element, id) => {
    this.elementRefs[id] = element;
  };

  handleQuestion = (id) => {
    this.elementRefs[id].scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  sortByField = (a, b) => {
    return a.sort - b.sort;
  };

  handleRequestSupport = () => {
    console.info('navigating to contact-us page');
    fireGAEvent(FAQ__CONTACT_US(this.props.RoleIDType));
    window.location.assign(Path.ContactUs);
  };

  render() {
    return (
      <div className="px-0 mx-0">
        <div className="-m-2 flex flex-wrap noFlexWrap1024 mx-0 card p-0">
          <div className="w-80 prewrap" style={{ position: 'sticky' }}>
            <Accordion mode="multiple">
              {this.props.FAQCategories.sort(this.sortByField).map((category) => (
                <AccordionSection
                  key={category.id}
                  label={category.name}
                  className="shadow-none text-interactive-color pl-2"
                >
                  <ul className="ml-3 p-4">
                    {this.props.FAQs.filter((faq) => faq.categoryID === category.id)
                      .sort(this.sortByField)
                      .map((faq) => (
                        <li key={faq.id} className="prewrap mb-4">
                          <a
                            className="text-interactive-color text-base font-bold"
                            onClick={() => this.handleQuestion(faq.id)}
                          >
                            {faq.question}
                          </a>
                        </li>
                      ))}
                  </ul>
                </AccordionSection>
              ))}
            </Accordion>
            <div className="relative w-full py-3 pl-4 md:pl-6 text-left block pr-16 md:pr-20 ">
              <Button
                id="request_support_nav"
                onClick={this.handleRequestSupport}
                size="small"
                label="Request Support"
                startIcon={<IconMail />}
              />
            </div>
          </div>

          <div className="ml-5">
            {this.props.FAQCategories.sort(this.sortByField).map((category) => (
              <div key={category.id} className="mb-4">
                <div className="text-3xl font-bold faqHeaderColor">{category.name}</div>
                {this.props.FAQs.filter((faq) => faq.categoryID === category.id)
                  .sort(this.sortByField)
                  .map((faq) => (
                    <div
                      id={faq.id}
                      key={faq.id}
                      ref={(element) => this.createRef(element, faq.id)}
                    >
                      <div className="text-lg mt-4 font-bold faqHeaderColor mb-2">
                        {faq.question}
                      </div>
                      <div
                        className="zesty-html"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                    </div>
                  ))}
              </div>
            ))}
            <div className="text-3xl faqHeaderColor">Still have questions?</div>
            <p>Submit a support request.</p>
            <Button
              id="request_support_content"
              onClick={this.handleRequestSupport}
              size="small"
              label="Request Support"
              startIcon={<IconMail />}
            />
          </div>
        </div>
      </div>
    );
  }
}
