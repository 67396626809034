import React from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import RemoveOffice from '@components/modal/subcomponents/RemoveOffice';
import Modal from '@components/modal/Modal';

const ModalRemoveOffice = (props) => {
  const actions = [
    <Button
      key="submit"
      variant="filled"
      size="medium"
      label="Continue"
      onClick={props.onRemove}
    />,
    <Button key="cancel" variant="ghost" size="medium" label="Cancel" onClick={props.onClose} />,
  ];

  return (
    <Modal
      id={props.id || 'remove-office'}
      heading={props.heading || 'Remove Office From Your Account'}
      onClose={props.onClose}
      isActive={props.open}
      actions={actions}
    >
      {props.isActive && <RemoveOffice />}
    </Modal>
  );
};

export default ModalRemoveOffice;
