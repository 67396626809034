import React, { useState } from 'react';

// Components
import Modal from '@components/modal/Modal';
import LegalConfirmation from '@components/modal/subcomponents/LegalConfirmation';

const ModalLegalConfirmation = (props) => {
  const [actions, setActions] = useState([]);

  return (
    <Modal
      id={props.id || 'legal-confirmation'}
      heading={props.heading || 'Customer Data Certification'}
      actions={actions}
      {...props}
    >
      {props.isActive && <LegalConfirmation setActions={setActions} {...props} />}
    </Modal>
  );
};

export default ModalLegalConfirmation;
