import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import React, { useEffect, useState } from 'react';
import OverlaySpinner from '@components/spinner/OverlaySpinner';

export interface GlobalOverlayOpts {
  show: boolean;
  text?: string;
}

export interface GlobalOverlaySpinnerFns {
  showSpinner: (isActive: boolean, text?: string) => void;
}

/** The RxJS subject to assign the state of the overlay */
const overlaySubject = new BehaviorSubject<GlobalOverlayOpts>(undefined);

/** The overlay observable. Filters out falsy, undefined or null values. Subscriptions will receive these changes */
const overlay$ = overlaySubject.pipe(filter(Boolean));

/** Function returns hooks to add a global overlay spinner.
 */
export default function useGlobalOverlaySpinner(): GlobalOverlaySpinnerFns {
  const showSpinner = (isActive: boolean, text?: string): void => {
    overlaySubject.next({ show: isActive, text });
  };

  return { showSpinner };
}

export const GlobalOverlaySpinner: React.FC = () => {
  const [isActive, setIsActive] = useState(false);

  /** Subscribe to the observable to listen overlay changes */
  useEffect(() => {
    const subscription = overlay$.subscribe((opts: GlobalOverlayOpts) => {
      setIsActive(opts.show);
    });
    // Upon destroying the component, clean up the subscription to avoid memory leaks
    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  return <OverlaySpinner id="global-spinner" isActive={isActive} />;
};
