import OfficeAutoComplete from '@components/autocomplete/OfficeAutocomplete';
import { Input } from '@ftdr/blueprint-components-react';
import { getFormInputErrorId } from '@storybook/addon-links';
import React, { useEffect, useState } from 'react';
import { Office } from '@apis/models';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import usaStates from '@constants/us-states';

const InitiatingOfficeInput: React.FC<any> = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<Partial<Office>[]>([]);
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  function handleOfficeSelect(e) {
    setInputValue(e[0].name);
    props.handleOfficeSelect(e);
    setIsDrawerActive(false);
  }

  function handleBlur(event) {
    if (
      !event.relatedTarget ||
      (event.relatedTarget.id !== 'officeSuggestionDropdown' &&
        event.relatedTarget.id !== 'wb_openSearchDrawer')
    ) {
      props.setShowOfficeSuggestions(false);
    }
    if (event.target.value === '' && !event.currentTarget.value && props.values.initOfficeId) {
      props.setValues({
        ...props.values,
        initOfficeId: '',
        initAgentId: '',
        initiatingOffice: '',
        officeId: '',
        agentOffice: '',
      });
    }
    if (!props.values.initOfficeId || event.target.value === '') {
      props.onOfficeError();
    }
  }

  const onFilterChange = (e: React.FormEvent<any>) => {
    setInputValue(e.currentTarget.value);
    props.updateOfficeFilter(e);
  };

  /** Update the filter */
  useEffect(() => {
    let filteredOfficeList: Partial<Office>[] = [];
    if (props.showOfficeSuggestions) {
      filteredOfficeList = props.officeList?.filter((o) => {
        return o.name.toLowerCase().includes(inputValue.toLowerCase());
      });
    }
    if (props.showOnlyActive) {
      // active only offices should be displayed in the office auto suggestions
      filteredOfficeList = filteredOfficeList.filter((o) => o.active);
    }
    setSuggestions(filteredOfficeList);
  }, [inputValue, props.officeList, props.showOfficeSuggestions]);

  useEffect(() => {
    if (!props.selectedOffice) {
      setInputValue('');
    }
  }, [props.selectedOffice]);

  return (
    <>
      <div data-testid="form-office-selection__with-autocomplete" className="w-full relative">
        <OfficeAutoComplete
          itemList={suggestions}
          listKey="id"
          itemKey="name"
          openOfficeSearch={() => setIsDrawerActive(!isDrawerActive)}
          itemSelected={(e) => handleOfficeSelect(e)}
          showSuggestions={props.showOfficeSuggestions}
        >
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(props.id)}
            id={props.id}
            name={props.name}
            label={props.label}
            required={props.required}
            error={props.errors}
            value={inputValue}
            onFocus={props.focusOfficeSelect}
            onChange={onFilterChange}
            onBlur={(e) => handleBlur(e)}
            autoComplete="off"
            disabled={props.isDisabled}
          />
        </OfficeAutoComplete>
      </div>

      <DrawerOfficeSearch
        id="card-new-order-agent-info__drawer-office-search"
        usaStates={usaStates}
        typeCode={props.officeType}
        isActive={isDrawerActive}
        onClose={() => setIsDrawerActive(false)}
        userOfficeIDs={props.setUserOfficeIDs}
        onSubmit={handleOfficeSelect}
      />
    </>
  );
};

export default InitiatingOfficeInput;
