import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSection,
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
  Notification,
  Pagination,
} from '@ftdr/blueprint-components-react';
import DashboardActionHeader from './DashboardActionHeader';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import { ITEMS_PER_PAGE_ACTION } from '@constants/dashBoard-constants';
import _RealEstateOrderApi from '@apis/realestateorder.api';
import { isMobileView } from '@utils';
import AccordionItemMissingBuyerPhone from '@components/accordion/AccordionItemMissingBuyerPhone';
import PanelContractSummaryMissingBuyerPhone from '@components/panel/PanelContractSummaryMissingBuyerPhone';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { SECOND } from '@helpers/utils';

export interface BuyerMissingPhoneResponse {
  id: string;
  propertyAddress: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    propertyId: string;
  };
  buyer: {
    firstName: string;
    lastName: string;
  };
}

const RealEstateOrderApi = _RealEstateOrderApi.new({
  suppressAllErrorNotifications: true,
});

const OrdersMissingBuyerPhone = (props) => {
  const [hasFetchedContractData, setHasFetchedContractData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [pageContractData, setPageContractData] = useState([]);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState([]);
  const [totalContracts, setTotalContracts] = useState(props.initialTotalContracts);
  const [activePage, setActivePage] = useState(1);
  const isMobile = isMobileView();
  const { addErrorToQueue } = useGlobalAlert();

  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [lastCompletedIsSuccess, setLastCompletedIsSuccess] = useState(false);
  const [lastCompletedContractId, setLastCompletedContractId] = useState('');

  const onBuyerPhoneSave = (s, contractId) => {
    setIsNotificationActive(true);
    setLastCompletedIsSuccess(s);
    fetchContractData();
    if (s) {
      setLastCompletedContractId(contractId);
    }
    setAccordionActiveIndex([]);
  };

  const contractHeaderRenderer = (contract) => (renderProps) => {
    const toggleAction = async () => {
      setAccordionActiveIndex(renderProps.active ? [] : [renderProps.index]);
    };

    return (
      <PanelContractSummaryMissingBuyerPhone
        key={renderProps.key}
        missingBuyerPhoneContract={contract}
        idPrefix="order-missing-buyer-phone"
        actions={[
          <IconButton
            key="action"
            label=""
            icon={renderProps.active ? <IconNavArrowUp /> : <IconNavArrowDown />}
            variant="ghost"
            size={isMobile ? 'small' : 'medium'}
            onClick={toggleAction}
          />,
        ]}
        onClickContract={toggleAction}
      />
    );
  };

  /** renders the orders into an accordion. */
  const renderREOrderResults = (orders: BuyerMissingPhoneResponse[]) => (
    <>
      <Accordion
        mode="single"
        id="accordion-orders-missing-buyer-phone"
        activeIndex={accordionActiveIndex}
        onActivate={(e, i) => setAccordionActiveIndex(i)}
        separatorClassName="hidden"
        className="mt-3"
      >
        {orders.map((contract) => (
          <AccordionSection
            className="w-full"
            headerRenderer={contractHeaderRenderer(contract)}
            label=""
            key={contract.id}
          >
            <AccordionItemMissingBuyerPhone
              contract={contract}
              onSave={(bool, contractId) => onBuyerPhoneSave(bool, contractId)}
              onCancel={() => setAccordionActiveIndex([])}
            />
          </AccordionSection>
        ))}
      </Accordion>
    </>
  );

  const fetchContractData = async () => {
    try {
      const offset = (activePage - 1) * ITEMS_PER_PAGE_ACTION;
      const response = await RealEstateOrderApi.getProfileContractsMissingBuyerPhone(
        offset,
        ITEMS_PER_PAGE_ACTION,
      );
      if (response) {
        setPageContractData(response.contracts);
        setTotalContracts(response.count);
        props.onUpdateOrderCount(response.count);
        setHasFetchedContractData(true);
      }
    } catch (error) {
      console.error('Error fetching contract data', error);
      addErrorToQueue(error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    setAccordionActiveIndex([]);
    fetchContractData();
  }, [activePage]);

  return (
    <div className="full-height-card">
      <div className="flex w-full">
        <DashboardActionHeader
          id="drawer-orders-missing-buyer-phone"
          heading="Add buyer phone number"
          totalContractsCount={totalContracts}
        />
      </div>
      {isNotificationActive && (
        <Notification
          inline={true}
          status={lastCompletedIsSuccess ? 'success' : 'error'}
          className="max-w-full mb-4"
          autoCloseDelay={5 * SECOND}
          onClose={() => setIsNotificationActive(false)}
        >
          {lastCompletedIsSuccess ? (
            <span>
              Buyer phone number saved. Please note that it may take a few minutes to reflect these
              changes on the contract {lastCompletedContractId}.{' '}
            </span>
          ) : (
            <span>
              Uh oh, there was a problem updating the buyer phone number. Please try again.
            </span>
          )}
        </Notification>
      )}
      {loadingData && !hasFetchedContractData ? (
        <LoadingIndicator />
      ) : (
        renderREOrderResults(pageContractData)
      )}
      <Pagination
        onItemsPerPageChange={null}
        itemsPerPage={ITEMS_PER_PAGE_ACTION}
        itemsPerPageOptions={[
          { value: ITEMS_PER_PAGE_ACTION.toString(), label: ITEMS_PER_PAGE_ACTION.toString() },
        ]}
        onPageChange={(page) => setActivePage(page)}
        totalItems={totalContracts}
        page={activePage}
        hideViewAll={true}
      />
    </div>
  );
};

export default OrdersMissingBuyerPhone;
