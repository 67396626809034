import {
  Button,
  IconFilter,
  Input,
  Radio,
  Separator,
  Text,
} from '@ftdr/blueprint-components-react';
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { formatPhoneNumber } from '@helpers/utils';
import { PossibleAgent } from '@apis/models';

interface Values {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

interface Props {
  data?: PossibleAgent[];
  subheader?: string;
  header?: string;
  active?: boolean;
  formValues?: Partial<Values>;
  onClose(): void;
  onUseSelectedAgent(agent: PossibleAgent): void;
  onCreateNewAgent(): void;
}

export const filterAgents = (data: PossibleAgent[], text: string): PossibleAgent[] => {
  try {
    if (!data || data.length === 0) {
      return [];
    }
    text = text?.trim().toLocaleLowerCase();
    if (!text) {
      return data;
    }

    // fields to filter against
    return data.filter((agent) =>
      // the fields to filter against
      [
        agent.firstName,
        agent.lastName,
        agent.email,
        agent.phones?.[0]?.phoneNumber,
        formatPhoneNumber(agent.phones?.[0]?.phoneNumber),
        agent.officeName,
        agent.officeAddress?.address1,
        agent.officeAddress?.city,
        agent.officeAddress?.state,
        agent.officeAddress?.zip,
      ]
        .filter((field) => !!field) // remove nulls and blanks
        .map((field) => field.trim().toLocaleLowerCase()) // clear blank spaces and case-insensitive
        .some(
          (field) => text.split(' ').some((token) => field.includes(token)), // allow filtering by many tokens by text spaces
        ),
    );
  } catch (e) {
    console.error('failed to filter agent data', e);
    return data || [];
  }
};

const ModalViewAgentFuzzyMatch = (props: Props) => {
  const [selectedAgentId, setSelectedAgentId] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<PossibleAgent>(null);
  const [filterText, setFilterText] = useState('');
  const [filteredAgentData, setFilteredAgentData] = useState<PossibleAgent[]>([]);

  useEffect(() => {
    setFilteredAgentData(filterAgents(props.data, filterText));
  }, [props.data, filterText]);

  const buttons = [
    <div className="space-x-2" key="buttons">
      <Button
        id="fuzzy-match-create-agent-link"
        variant="ghost"
        labelAlign="center"
        label="Create New Agent"
        disabled={false}
        onClick={() => props.onCreateNewAgent()}
      />
      <Button
        id="fuzzy-match-use-selected-agent-link"
        labelAlign="center"
        label="Use Selected Agent"
        disabled={!selectedAgentId}
        onClick={() => props.onUseSelectedAgent(selectedAgent)}
      />
    </div>,
  ];

  const agentRows = (data: PossibleAgent[]) =>
    data.map((agent, index) => {
      const handleClick = () => {
        setSelectedAgentId(agent.agentID);
        setSelectedAgent(agent);
      };

      const selected = selectedAgentId === agent.agentID;
      const isLast = index === props.data.length - 1;
      const separatorForRow = isLast ? '' : 'border-b border-gray-400';

      const firstNameMatch = props.formValues?.firstName === agent.firstName;
      const firstName = firstNameMatch ? (
        <Text className="text-interactive-color font-bold inline">{agent.firstName}</Text>
      ) : (
        <Text className="font-bold inline">{agent.firstName}</Text>
      );

      const lastNameMatch = props.formValues?.lastName === agent.lastName;
      const lastName = lastNameMatch ? (
        <Text className="text-interactive-color font-bold inline">{agent.lastName}</Text>
      ) : (
        <Text className="inline font-bold">{agent.lastName}</Text>
      );

      const phoneNumberMatch =
        props.formValues?.phoneNumber === formatPhoneNumber(agent.phones[0]?.phoneNumber);
      const phoneNumber = phoneNumberMatch ? (
        <Text variant="caption" className="text-interactive-color">
          {formatPhoneNumber(agent.phones[0]?.phoneNumber)}
        </Text>
      ) : (
        <Text variant="caption">{formatPhoneNumber(agent.phones[0]?.phoneNumber)}</Text>
      );

      const emailNameMatch = props.formValues?.email === agent.email;
      const email = emailNameMatch ? (
        <Text variant="caption" className="text-interactive-color">
          {agent.email}
        </Text>
      ) : (
        <Text variant="caption">{agent.email}</Text>
      );

      return (
        <>
          <div className={`flex ${separatorForRow}`}>
            <div className="flex p-2">
              <Radio
                checked={selected}
                onChange={handleClick}
                value={agent.agentID}
                label={agent.firstName}
                hideLabel={true}
              />
            </div>

            <div className="py-5 w-1/2">
              <div>
                {firstName} {lastName}
              </div>
              <div>{phoneNumber}</div>
              <div>{email}</div>
            </div>

            <div className="py-5 w-1/2">
              <Text className="font-bold">{agent.officeName}</Text>
              <Text variant="caption">
                {agent.officeAddress?.address1}, {agent.officeAddress?.city},{' '}
                {agent.officeAddress?.state} {agent.officeAddress?.zip}
              </Text>
            </div>
          </div>
        </>
      );
    });

  const noResultsContainer = <Text className="py-4 font-bold">No matching agents to display</Text>;

  return (
    <>
      <Modal
        id="view-initiating-agent-fuzzy-match"
        onClose={props.onClose}
        isActive={props.active}
        heading={props.header}
        actions={buttons}
        className="w-5/6 md:w-1/2"
        overwriteBottomMargin={true}
      >
        <Input
          id="fuzzy-match-filter"
          className="py-2"
          label=""
          autoComplete="off"
          placeholder="Filter by name, phone, email, or office..."
          showClearButton={true}
          startEnhancer={<IconFilter />}
          onChange={(e) => setFilterText(e?.target?.value)}
          onClearButtonClick={() => setFilterText('')}
        />
        <Text id="subheader-text">{props.subheader}</Text>
        <Separator orientation="horizontal" className="mt-3 mb-3" />
        <div>
          {filteredAgentData.length > 0 ? agentRows(filteredAgentData) : noResultsContainer}
        </div>
      </Modal>
    </>
  );
};

export default ModalViewAgentFuzzyMatch;
