import React, { useContext, useEffect, useState } from 'react';
import ContractApi from '@apis/contract.api';
import { Panel } from '@ftdr/blueprint-components-react';
import { GetLatestRecipients, Recipient, RecipientContext, Type } from '@components/SendDocuments';
import ProfileContext from '@context/ProfileContext';
import { ContractModelVariant } from '@app/models/contract.model';
import SendRenewal from '@components/drawer/subcomponents/SendRenewal';
import { fireGAEvent } from '@app/core/tracking.service';
import { RENEWALS_CLICKED_SEND_EMAIL, RENEWALS_UPDATE_EMAIL } from '@constants/ga-events.constants';
import contractApiService from '@apis/contract.api';
import { msgsWParams } from '@app/locales/en';
import { RecipientType } from '@app/generators/recipientGenerator';
import { Contract, Customer, UpsertOrderCustomer, UpsertOrderCustomerRequest } from '@apis/models';
import { CustomerTypes } from '@constants/dictionaries';
import { cleanPhone } from '@helpers/utils';
import { DEFAULT_CUSTOMER_PHONE_TYPE } from '@constants/formField-constants';
import useGlobalAlert from '@app/core/GlobalAlertModal';

const AccordionItemSendRenewal = (props) => {
  const [contract, setContract] = useState<Contract>(undefined);
  const [originalRecipients, setOriginalRecipients] = useState<Recipient[]>(undefined);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    if (props.contractDetails) {
      setContract(props.contractDetails);
      setRecipients(
        GetLatestRecipients(
          props.contractDetails,
          ContractModelVariant.API,
          recipients,
          profile.email,
          `${profile.firstName} ${profile.lastName}`,
        ),
      );
    }
  }, [props.contractDetails]);

  useEffect(() => {
    if (contract) {
      const fetchedRecipients = GetLatestRecipients(
        contract,
        ContractModelVariant.API,
        recipients,
        profile.email,
        `${profile.firstName} ${profile.lastName}`,
        RecipientContext.SendRenewal,
      );
      setRecipients(fetchedRecipients);
      if (originalRecipients == undefined) {
        setOriginalRecipients([...fetchedRecipients]);
      }
    }
  }, [contract]);

  const handleClose = () => {
    setRecipients([]);
    props.onCancel();
  };

  const hasEmailChanged = (recipientType) => {
    const oldRecipient = originalRecipients.find((recipient) => recipient.type == recipientType);
    const newRecipient = recipients.find((recipient) => recipient.type == recipientType);

    return oldRecipient?.email !== newRecipient?.email;
  };

  const makeEmailDocumentsCall = (): Promise<any> => {
    const docEmails = recipients
      .filter((recipient) => recipient.selectedTypes.length > 0)
      .map(({ email, selectedTypes }) => ({ email, docs: selectedTypes }));
    const request = {
      docEmails,
    };

    return contractApiService
      .emailContractDocuments(request, Number(contract?.meta?.ID))
      .then((res) => {
        if (res) {
          fireGAEvent(RENEWALS_CLICKED_SEND_EMAIL(contract?.meta?.ID));
          updateContractCustomers();

          if (hasEmailChanged(RecipientType.Buyer)) {
            fireGAEvent(RENEWALS_UPDATE_EMAIL('buyer', contract.summary.id));
          }

          if (hasEmailChanged(RecipientType.CoBuyer)) {
            fireGAEvent(RENEWALS_UPDATE_EMAIL('co-buyer', contract.summary.id));
          }

          props.onCompleteItem(contract?.meta?.ID, true);
        } else {
          props.onCompleteItem(contract?.meta?.ID, false);
        }
      });
  };

  const updateContractCustomers = () => {
    const buyers = recipients.filter(
      (r) => r.type === RecipientType.Buyer && r.selectedTypes.includes(Type.Renewal),
    );
    const cobuyers = recipients.filter(
      (r) => r.type === RecipientType.CoBuyer && r.selectedTypes.includes(Type.Renewal),
    );
    let shouldUpdateBuyer: boolean,
      shouldUpdateCoBuyer: boolean = false;
    let buyer: Recipient,
      cobuyer: Recipient = null;
    let contractCustomerBuyer: Customer,
      contractCustomerCoBuyer: Customer,
      contractCustomerSeller: Customer,
      contractCustomerCoSeller: Customer = null;
    const upsertCustomerRequest: UpsertOrderCustomerRequest = { customers: [] };
    let updateBuyer: UpsertOrderCustomer, updateCoBuyer: UpsertOrderCustomer;

    // set the buyer/cobuyer object to the recipient type if given, there should be a maximum of one given
    if (buyers.length) {
      buyer = buyers[0];
    }
    if (cobuyers.length) {
      cobuyer = cobuyers[0];
    }

    // determine the customer objects and set flag if they should be changed
    contract.detail.customers.forEach((c) => {
      if (c.type === CustomerTypes.Buyer) {
        contractCustomerBuyer = c;
        if (c.email?.address !== buyer?.email) {
          shouldUpdateBuyer = true;
        }
      } else if (c.type === CustomerTypes.CoBuyer) {
        contractCustomerCoBuyer = c;
        if (c.email?.address !== cobuyer?.email) {
          shouldUpdateCoBuyer = true;
        }
      } else if (c.type === CustomerTypes.Seller) {
        contractCustomerSeller = c;
      } else if (c.type === CustomerTypes.CoSeller) {
        contractCustomerCoSeller = c;
      }
    });

    // append an updated object with existing information, change the email if necessary
    if (contractCustomerBuyer) {
      updateBuyer = {
        customerId: contractCustomerBuyer.customerId || '',
        type: CustomerTypes.Buyer,
        firstName: contractCustomerBuyer.firstName || '',
        lastName: contractCustomerBuyer.lastName || '',
        email: contractCustomerBuyer.email
          ? {
              address: contractCustomerBuyer.email.address || '',
              id: contractCustomerBuyer.email.id || '',
            }
          : null,
        phones: contractCustomerBuyer.phones
          ? {
              number: cleanPhone(contractCustomerBuyer.phones.number),
              type: DEFAULT_CUSTOMER_PHONE_TYPE,
            }
          : null,
        address: contractCustomerBuyer.address
          ? {
              address1: contractCustomerBuyer.address.address1 || '',
              address2: contractCustomerBuyer.address.address2 || '',
              city: contractCustomerBuyer.address.cityName || '',
              state: contractCustomerBuyer.address.state || '',
              zip: contractCustomerBuyer.address.zipCode || '',
              propertyId: contractCustomerBuyer.address.propertyID || '',
            }
          : undefined,
      };
      if (shouldUpdateBuyer) {
        updateBuyer.email = buyer?.email
          ? {
              address: buyer?.email || '',
              id: '',
            }
          : null;
      }
      upsertCustomerRequest.customers?.push(updateBuyer);
    }

    // append an updated object with existing information, change the email if necessary
    if (contractCustomerCoBuyer) {
      updateCoBuyer = {
        customerId: contractCustomerCoBuyer.customerId || '',
        type: CustomerTypes.CoBuyer,
        firstName: contractCustomerCoBuyer.firstName || '',
        lastName: contractCustomerCoBuyer.lastName || '',
        email: contractCustomerCoBuyer.email
          ? {
              address: contractCustomerCoBuyer.email.address || '',
              id: contractCustomerCoBuyer.email.id || '',
            }
          : null,
        phones: contractCustomerCoBuyer.phones
          ? {
              number: cleanPhone(contractCustomerCoBuyer.phones.number),
              type: DEFAULT_CUSTOMER_PHONE_TYPE,
            }
          : null,
        address: contractCustomerCoBuyer.address
          ? {
              address1: contractCustomerCoBuyer.address.address1 || '',
              address2: contractCustomerCoBuyer.address.address2 || '',
              city: contractCustomerCoBuyer.address.cityName || '',
              state: contractCustomerCoBuyer.address.state || '',
              zip: contractCustomerCoBuyer.address.zipCode || '',
              propertyId: contractCustomerCoBuyer.address.propertyID || '',
            }
          : undefined,
      };
      if (shouldUpdateCoBuyer) {
        updateCoBuyer.email = cobuyer?.email
          ? {
              address: cobuyer?.email || '',
              id: '',
            }
          : null;
      }
      upsertCustomerRequest.customers?.push(updateCoBuyer);
    }

    // both seller and coseller should always be part of the update with their existing values
    if (contractCustomerSeller) {
      upsertCustomerRequest.customers?.push({
        customerId: contractCustomerSeller.customerId || '',
        type: CustomerTypes.Seller,
        firstName: contractCustomerSeller.firstName || '',
        lastName: contractCustomerSeller.lastName || '',
        email: contractCustomerSeller.email
          ? {
              address: contractCustomerSeller.email.address || '',
              id: contractCustomerSeller.email.id || '',
            }
          : null,
        phones: contractCustomerSeller.phones
          ? {
              number: cleanPhone(contractCustomerSeller.phones.number),
              type: DEFAULT_CUSTOMER_PHONE_TYPE,
            }
          : null,
        address: contractCustomerSeller.address
          ? {
              address1: contractCustomerSeller.address.address1 || '',
              address2: contractCustomerSeller.address.address2 || '',
              city: contractCustomerSeller.address.cityName || '',
              state: contractCustomerSeller.address.state || '',
              zip: contractCustomerSeller.address.zipCode || '',
              propertyId: contractCustomerSeller.address.propertyID || '',
            }
          : undefined,
      });
    }

    // both seller and coseller should always be part of the update with their existing values
    if (contractCustomerCoSeller) {
      upsertCustomerRequest.customers?.push({
        customerId: contractCustomerCoSeller.customerId || '',
        type: CustomerTypes.CoSeller,
        firstName: contractCustomerCoSeller.firstName || '',
        lastName: contractCustomerCoSeller.lastName || '',
        email: contractCustomerCoSeller.email
          ? {
              address: contractCustomerCoSeller.email.address || '',
              id: contractCustomerCoSeller.email.id || '',
            }
          : null,
        phones: contractCustomerCoSeller.phones
          ? {
              number: cleanPhone(contractCustomerCoSeller.phones.number),
              type: DEFAULT_CUSTOMER_PHONE_TYPE,
            }
          : null,
        address: contractCustomerCoSeller.address
          ? {
              address1: contractCustomerCoSeller.address.address1 || '',
              address2: contractCustomerCoSeller.address.address2 || '',
              city: contractCustomerCoSeller.address.cityName || '',
              state: contractCustomerCoSeller.address.state || '',
              zip: contractCustomerCoSeller.address.zipCode || '',
              propertyId: contractCustomerCoSeller.address.propertyID || '',
            }
          : undefined,
      });
    }

    // perform the update if one was created above
    if (
      upsertCustomerRequest?.customers?.length > 0 &&
      (shouldUpdateBuyer || shouldUpdateCoBuyer)
    ) {
      ContractApi.upsertCustomerInfo(contract?.detail?.id, upsertCustomerRequest).catch((err) => {
        console.error('failed to update buyer information', err);
      });
    }
  };

  return (
    <Panel
      border="1"
      borderColor="gray-300"
      rounded="sm"
      padding="none"
      shadow={true}
      className="rounded bg-white shadow-1 border-2 mb-4 -mt-4"
    >
      <div className={contract ? 'pl-6 pr-6 pb-6 pt-3' : 'hidden'}>
        {contract && (
          <div>
            <SendRenewal
              id={props.id}
              contract={contract}
              onClose={handleClose}
              onConfirm={makeEmailDocumentsCall}
              onChange={setRecipients}
              recipients={recipients}
              userDetails={props.userDetails}
              hideHeader={true}
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default AccordionItemSendRenewal;
