import React, { useState } from 'react';

// Components
import { Button, IconWarningCircledFull, Text } from '@ftdr/blueprint-components-react';
import FormOfficeSearch from '@components/form/FormOfficeSearch';

import { useThemeContext } from '@ThemeContext';
import { CANT_FIND_OFFICE_AHS, CANT_FIND_OFFICE_HSA } from '@constants/formField-constants';

const OfficeSearch = (props) => {
  const theme = useThemeContext();
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [officeButtons, setOfficeButtons] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function setOffices(office) {
    if (props.multiSelect) {
      if (selectedOffices && selectedOffices.length) {
        if (selectedOffices.includes(office)) {
          const filteredOffices = selectedOffices.filter((o) => {
            return o.id !== office.id;
          });
          setSelectedOffices(filteredOffices);
          return;
        }
        setSelectedOffices([...selectedOffices, office]);
      } else {
        setSelectedOffices([office]);
      }
    } else {
      setSelectedOffices([office]);
    }
  }

  function onSubmit() {
    setIsSubmitting(true);
    props.onSubmit(selectedOffices);
  }

  return (
    <div className="full-height-card">
      <Text id="wb_findOfcLabel" variant="heading-03">
        Add an Office
      </Text>
      <>
        <div className="my-4 md:mbodyt-8">
          <Text variant="body-short">
            You can search for your office by City and State, ZIP Code, Phone or Distributor ID.
          </Text>
          <Text variant="body-short">Search by:</Text>
        </div>

        <FormOfficeSearch
          usaStates={props.usaStates}
          setSelectedOffices={setOffices}
          typeCode={props.typeCode}
          selectedOffices={selectedOffices}
          multiSelect={props.multiSelect}
          officeButtons={setOfficeButtons}
          excludeBrandSearch={props.excludeBrandSearch}
          userOfficeIDs={props.userOfficeIDs}
          ignoreReadOnly={props.ignoreReadOnly}
          onCancel={props.onCancel}
        />

        {officeButtons && (
          <>
            <div className="mt-6 -mx-4 md:-mx-8">
              <div className="flex flex-wrap justify-end w-full px-4 md:px-8">
                <Button
                  id="wb_addOfcBtn"
                  label="Choose Office"
                  size="small"
                  disabled={isSubmitting || !selectedOffices || selectedOffices.length === 0}
                  onClick={onSubmit}
                />
              </div>
            </div>

            <div className="flex mt-6">
              <IconWarningCircledFull size={20} className="mr-4" />

              <div>
                <Text variant="body-short">
                  <b>Can&apos;t find your office?</b>
                </Text>
                <Text variant="body-short" className="mt-3">
                  {theme === 'hsa' ? CANT_FIND_OFFICE_HSA : CANT_FIND_OFFICE_AHS}
                </Text>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default OfficeSearch;
