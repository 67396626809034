import React from 'react';
import { classNames } from '@utils';
import { Text } from '@ftdr/blueprint-components-react';

export interface TableExtendListingRow {
  title?: string;
  currentDate?: string;
  newDate?: string;
}

interface TableExtendListingRowProps {
  className?: string;
  row?: TableExtendListingRow;
}

const Row: React.FC<TableExtendListingRowProps> = (props) => {
  return (
    <tr className={classNames(['sm-max:block sm-max:py-4', props.className])}>
      <td className="md:py-2 md:pr-8">
        <span className="sm-max:text-primary-400 xs-max:text-base sm-max:text-lg sm-max:font-bold leading-tight">
          {props.row.title}
        </span>
      </td>

      <td className="sm-max:block md:py-2 md:pr-8">
        <span className="md:hidden sm-max:font-bold">Current Set Date: </span>
        <span className="text-gray-600">{props.row.currentDate}</span>
      </td>

      <td className="sm-max:block md:py-2">
        <span className="md:hidden sm-max:font-bold">New Date: </span>
        <span className="md:font-bold">{props.row.newDate}</span>
      </td>
    </tr>
  );
};

interface Props {
  className?: string;
  rows?: TableExtendListingRow[];
}

const TableExtendListing: React.FC<Props> = (props) => {
  return (
    <table className={classNames(['table table--extend-listing w-full', props.className || ''])}>
      <thead className="table__head text-left sm-max:hidden">
        <tr className="table__row table__row--head">
          <th className="w-full pr-8">&nbsp;</th>
          <th className="pr-8 text-xs font-bold text-primary-400 uppercase tracking-wide whitespace-no-wrap">
            <Text variant="label">Current Set Date</Text>
          </th>
          <th className="text-xs font-bold text-primary-400 uppercase tracking-wide whitespace-no-wrap">
            <Text variant="label">New Date</Text>
          </th>
        </tr>
      </thead>

      <tbody className="table__body text-sm">
        {props.rows.map((row, idx) => (
          <Row
            key={`${row.title}-${idx}`}
            className={classNames([props.rows.length !== idx + 1 && 'border-b'])}
            row={row}
          />
        ))}
      </tbody>
    </table>
  );
};

TableExtendListing.defaultProps = {
  rows: [],
};

export default TableExtendListing;
