import React from 'react';
import Drawer from '@components/drawer/Drawer';
import QuickLinkSendDocuments from '@components/drawer/subcomponents/QuickLinkSendDocuments';

const DrawerQLSendDocuments = (props) => {
  return (
    <Drawer {...props} className="w-224" useDefaultWidth={false}>
      {props.isActive && (
        <QuickLinkSendDocuments isActive={props.isActive} onClose={props.onClose} />
      )}
    </Drawer>
  );
};

export default DrawerQLSendDocuments;
