import React, { useEffect, useState } from 'react';
import { Contract } from '@apis/models';
import { addressToString } from '@services/helpers';
import ContractApi from '@apis/contract.api';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__CANCEL } from '@constants/ga-events.constants';
import { EXISTING_ORDER } from '@constants/newOrder-constants';
import {
  COMPANY_NAME_REQUIRED,
  REASON_CANCELLING_REQUIRED,
  REASON_DIFFERENT_PROVIDER_REQUIRED,
  REASON_REQUIRED,
} from '@constants/formField-constants';
import { Formik } from 'formik';
import { Button, IconNavArrowLeft, Input, Select, Text } from '@ftdr/blueprint-components-react';
import ModalCancelOrder from '@components/modal/ModalCancelOrder';
import { getFormInputErrorId, getFormSelectErrorId } from '@storybook/addon-links';

export interface CancelOrderProps {
  /** change the heading of the drawer as needed. */
  heading?: string;
  /** the contract that is being cancelled */
  contract: Contract;
  /** trigger when cancelling the order */
  onOrderCancelled: (contractId: string) => void;
  /** trigger when cancel order failed */
  onOrderCancelFailure?: (contractId: string) => void;
  /** for QL, show and handle back to search button on header */
  onBackToSearch?: () => void;
}

type FormErrors = { [P in keyof FormValues]?: string };

interface FormValues {
  reason: string;
  other?: string;
  differentProvider?: string;
  providerName?: string;
}

const CancelOrder: React.FC<CancelOrderProps> = ({ contract, ...props }) => {
  const invalidMsg = 'This order cannot be cancelled.';

  const INITIAL_FORM_VALUES = {
    reason: '',
    other: '',
    differentProvider: '',
    providerName: '',
  };

  const cancelOptions = {
    BUYNOWTY: 'Buyer declined warranty',
    BUYOTHWTY: 'Buyer/Seller chose different warranty company',
    SLFELTHRU: 'Contract/Sale fell through',
    HOMEOFFMKT: 'Home taken off the market',
    NEGOUTSALE: 'Warranty negotiated out of the sale',
    ONCOVLST: ' Only ordered to cover seller during listing',
    OTHER: 'Other',
  };

  const cancelOptionsForBDSSelect = Object.keys(cancelOptions).reduce((options, key) => {
    options.push({
      id: `cancel-option-${key}`,
      label: cancelOptions[key],
      value: key,
    });
    return options;
  }, []);

  const differentProviderOptions = {
    price: 'Price',
    coverage: 'Coverage',
    contractorOptions: 'Contractor Options',
    agentReationShip: 'Agent relationship',
    corporateRelationShip: 'Corporate relationship',
  };

  const differentProviderOptionsForBDSSelect = Object.keys(differentProviderOptions).reduce(
    (options, key) => {
      options.push({
        id: `different-provider-option-${key}`,
        label: differentProviderOptions[key],
        value: key,
      });
      return options;
    },
    [],
  );

  const [initialValues, setInitialValues] = useState<FormValues>(INITIAL_FORM_VALUES);

  const [isModalActive, setIsModalActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [showDifferentProvider, setShowDifferentProvider] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [address, setAddress] = useState('');

  /** When contract is loaded, refresh the view with information needed */
  useEffect(() => {
    // Reset view
    setIsValid(false);
    setAddress('');

    // Load view if valid
    const isValid = contract?.features?.cancelListing?.isCancellable;

    if (isValid) {
      const { address1, address2, city, state, zip } = contract.summary.address;
      setAddress(addressToString(address1, address2, city, state, zip));
      setIsValid(true);
    }
  }, [contract]);

  const submitForm = (values: FormValues) => {
    setInitialValues(values);
    setIsModalActive(true);
  };

  const handleOrderCancelConfirmed = () => {
    setIsLoading(true);
    const contractId = contract.summary.id;
    const reason = cancelOptions[initialValues.reason];

    ContractApi.cancelListing(contractId, {
      reasonType: initialValues.reason,
      reasonDescription: [
        reason,
        initialValues.other,
        initialValues.differentProvider,
        initialValues.providerName,
      ]
        .filter(Boolean)
        .join('; '),
    })
      .then(async () => {
        props.onOrderCancelled(contractId);
      })
      .catch(() => {
        props.onOrderCancelFailure(contractId);
      })
      .finally(() => {
        setIsModalActive(false);
        setIsLoading(false);
      });
    fireGAEvent(
      ORDER__CANCEL(`${contract.detail.initiatingOfficeAgent.represents} | ${EXISTING_ORDER}`),
    );
  };

  const handleFormChange = (values: FormValues) => {
    const isOther = values.reason === 'OTHER';
    const isDifferentProvider = values.reason === 'BUYOTHWTY';
    setShowOtherField(isOther);
    setShowDifferentProvider(isDifferentProvider);

    if (!isOther) {
      // clean 'other' field value
      setInitialValues({
        ...values,
        other: '',
      });
    }

    if (!isDifferentProvider) {
      // clean 'different provider' field values
      setInitialValues({
        ...values,
        differentProvider: '',
        providerName: '',
      });
    }
  };

  const validateForm = (values: FormValues): FormErrors => {
    handleFormChange(values);
    const errors: FormErrors = {};

    if (!values.reason) {
      errors.reason = REASON_REQUIRED;
    }

    if (showOtherField && !values.other) {
      errors.other = REASON_CANCELLING_REQUIRED;
    }

    if (showDifferentProvider && !values.differentProvider) {
      errors.differentProvider = REASON_DIFFERENT_PROVIDER_REQUIRED;
    }

    if (showDifferentProvider && !values.providerName) {
      errors.providerName = COMPANY_NAME_REQUIRED;
    }

    return errors;
  };

  const onReasonSelect = (item, formikHandler) => {
    formikHandler({
      target: {
        name: 'reason',
        value: item.value,
        lable: item.label,
      },
    });
  };

  const onDifferentProviderReasonSelect = (item, formikHandler) => {
    formikHandler({
      target: {
        name: 'differentProvider',
        value: item.value,
        lable: item.label,
      },
    });
  };

  return (
    <>
      <Text variant="heading-03" color="primary">
        {props.heading}
      </Text>

      <Text variant="body-long" className="mt-4">
        {address}
      </Text>

      {props.onBackToSearch && (
        <Button
          className="mt-4"
          label="Back to search results"
          startIcon={<IconNavArrowLeft />}
          onClick={props.onBackToSearch}
          size="small"
          variant="ghost"
        />
      )}

      {!isValid ? (
        <p>{invalidMsg}</p>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validate={validateForm}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <p className="mt-8">Please let us know why you’re cancelling this order.</p>

              <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                <Select
                  formField={true}
                  formFieldMessageId={getFormSelectErrorId('wb_cancelOrderReason')}
                  id="wb_cancelOrderReason"
                  name="reason"
                  label="Select a reason"
                  required={true}
                  multiSelect={false}
                  options={cancelOptionsForBDSSelect}
                  onSelect={(item) => onReasonSelect(item, handleChange)}
                  selected={
                    values.reason
                      ? cancelOptionsForBDSSelect.find((o) => o.value === values.reason)
                      : null
                  }
                  error={errors.reason}
                />
              </div>

              {showOtherField && (
                <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                  <Input
                    formField={true}
                    formFieldMessageId={getFormInputErrorId('wb_coOther')}
                    id="wb_coOther"
                    name="other"
                    required={true}
                    label="Reason for canceling"
                    labelInfo=""
                    placeholder="Enter"
                    autoComplete="off"
                    value={values.other}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.other}
                  />
                </div>
              )}

              {showDifferentProvider && (
                <>
                  <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                    <Select
                      formField={true}
                      formFieldMessageId={getFormSelectErrorId('wb_cancelOrderDifferentProvider')}
                      id="wb_cancelOrderDifferentProvider"
                      name="differentProvider"
                      label="Reason for choosing different provider"
                      required={true}
                      multiSelect={false}
                      options={differentProviderOptionsForBDSSelect}
                      onSelect={(item) => onDifferentProviderReasonSelect(item, handleChange)}
                      selected={
                        values.differentProvider
                          ? differentProviderOptionsForBDSSelect.find(
                              (o) => o.value === values.differentProvider,
                            )
                          : null
                      }
                      error={errors.differentProvider}
                    />
                  </div>
                  <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                    <Input
                      formField={true}
                      formFieldMessageId={getFormInputErrorId('wb_coProviderName')}
                      id="wb_coProviderName"
                      name="providerName"
                      required={true}
                      label="Which company was selected?"
                      placeholder="Which company was selected?*"
                      value={values.providerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.providerName}
                    />
                  </div>
                </>
              )}

              <div className="mt-12 -mx-4 md:-mx-8">
                <div className="md:flex md:flex-wrap md:justify-end md:items-center w-full px-4 md:px-8">
                  <Button
                    className="sm-max:w-full sm-max:mt-4 md:ml-4"
                    color="error"
                    label="Cancel Order"
                    size="large"
                    shape="rounded"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      <ModalCancelOrder
        id="drawer-cancel-order__modal-cancel-order"
        isActive={isModalActive}
        isButtonDisabled={isLoading}
        onClose={() => setIsModalActive(false)}
        onConfirm={() => handleOrderCancelConfirmed()}
      />
    </>
  );
};

export default CancelOrder;

CancelOrder.defaultProps = {
  heading: 'Cancel an Order',
};
