import React, { useEffect, useState } from 'react';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderProductFiltersFormData } from '@app/models/order/order.model';
import OrderForm from '@components/orders/forms/OrderForm';
import {
  Checkbox,
  DateTimeInput,
  IconInfoEmpty,
  RadioGroup,
} from '@ftdr/blueprint-components-react';
import { Brand } from '@constants/brands';
import Tooltip from '@components/tooltip/Tooltip';
import {
  AC_COVERAGE,
  CLOSING_DATE_RANGE,
  PROJECTED_CLOSING_DATE_OUT_OF_RANGE,
  SELLERS_COVERAGE,
  WARRANTY_TERM_LENGTH,
} from '@constants/formField-constants';
import { getBrand } from '@helpers/brand.utils';
import { cloneDeep } from 'lodash';
import { isNullOrUndefined } from '@helpers/utils';
import { ProductFilters } from '@apis/models';

export enum OrderFormProductFiltersInfoField {
  termLength = 'termLength',
  acCoverage = 'acCoverage',
  sellersCoverage = 'sellersCoverage',
  projectedClosingDate = 'projectedClosingDate',
}

interface OrderFormProductFiltersFieldProps
  extends OrderFormProps<OrderProductFiltersFormData, OrderFormProductFiltersInfoField> {
  enabledFilters: ProductFilters;
  onCompleteFilterSelection: (selectedFilters: OrderProductFiltersFormData) => void;
  showUnknownDateOption: boolean;
  defaultCheckedUnknownDateOption?: boolean;
}

const OrderFormProductFilters: React.FC<OrderFormProductFiltersFieldProps> = (props) => {
  const brand = getBrand();

  const [dateUnknownChecked, setDateUnknownChecked] = useState<boolean>(
    props.defaultCheckedUnknownDateOption,
  );

  useEffect(() => {
    if (isPlanFilterComplete(props.formData)) {
      props.onCompleteFilterSelection(props.formData);
    }
  }, [props.formData]);

  const isPlanFilterComplete = (filters = props.formData): boolean => {
    return (
      filters &&
      Object.keys(filters).every(
        (k) => !isNullOrUndefined(filters[k]) || k === 'projectedClosingDate',
      )
    );
  };

  const onSellersCoverageChange = (includeSellers: string) => {
    const updateSelectedFilters: OrderProductFiltersFormData = {
      ...cloneDeep(props.formData),
      sellersCoverage: includeSellers === 'true',
    };
    props.onUpdateFormData(updateSelectedFilters);
  };

  const onAcCoverageChange = (includeAC: string) => {
    const updateSelectedFilters: OrderProductFiltersFormData = {
      ...cloneDeep(props.formData),
      acCoverage: includeAC === 'true',
    };
    props.onUpdateFormData(updateSelectedFilters);
  };

  const onTermChange = (selection: string) => {
    const updateSelectedFilters: OrderProductFiltersFormData = {
      ...cloneDeep(props.formData),
      term: Number(selection),
    };
    props.onUpdateFormData(updateSelectedFilters);
  };

  const onClosingDateSelect = (closingDate: Date) => {
    const updateSelectedFilters: OrderProductFiltersFormData = {
      ...cloneDeep(props.formData),
      projectedClosingDate: closingDate,
    };
    props.onUpdateFormData(updateSelectedFilters);

    // if closing set successfully, clear error and unset unknown date
    if (closingDate) {
      props.clearFieldError(OrderFormProductFiltersInfoField.projectedClosingDate);
      setDateUnknownChecked(false);
    }
  };

  const onInvalidClosingDate = () => {
    props.onFieldError(
      OrderFormProductFiltersInfoField.projectedClosingDate,
      PROJECTED_CLOSING_DATE_OUT_OF_RANGE(),
    );
    onClearClosingDate(); // clear closing date if invalid
  };

  const onCheckUnknownClosingDate = () => {
    setDateUnknownChecked(!dateUnknownChecked);
    onClearClosingDate(); // clear whenever the checkbox is toggled
  };

  const onClearClosingDate = () => {
    onClosingDateSelect(null);
  };

  return (
    <OrderForm.Row className="md:space-x-8">
      {props.enabledFilters && (
        <>
          <OrderForm.RowItem>
            <DateTimeInput
              id="wb_closingDate"
              name="projectedClosingDate"
              label="Projected Closing Date"
              required={true}
              onlyDate={true}
              datePicker={true}
              autoComplete="off"
              selectedDate={props.formData.projectedClosingDate}
              onFocus={() =>
                props.clearFieldError(OrderFormProductFiltersInfoField.projectedClosingDate)
              }
              onDateSelect={onClosingDateSelect}
              onInvalidDate={onInvalidClosingDate}
              minDate={CLOSING_DATE_RANGE().min}
              maxDate={CLOSING_DATE_RANGE().max}
              error={props.errors.projectedClosingDate}
              disabled={dateUnknownChecked}
              showDateClearButton={true}
            />
            {props.showUnknownDateOption && (
              <div className="mt-2">
                <Checkbox
                  id="dateUnknown"
                  label="Date Currently Unknown"
                  checked={dateUnknownChecked}
                  onChange={() => onCheckUnknownClosingDate()}
                />
              </div>
            )}
          </OrderForm.RowItem>

          <OrderForm.RowItem>
            {props.enabledFilters?.terms && (
              <div className="w-max">
                <RadioGroup
                  formField={true}
                  id="card-new-order-plans-coverage__warranty-term-form"
                  name="warrantyTermLength"
                  label="Warranty Term Length"
                  orientation="horizontal"
                  required={true}
                  secondaryAction={
                    brand === Brand.AHS &&
                    props.enabledFilters?.terms?.length > 1 && (
                      <Tooltip
                        placement="right"
                        content={<p className="text-left">{WARRANTY_TERM_LENGTH}</p>}
                      >
                        <span className="ml-2">
                          <IconInfoEmpty color="interactive" />
                        </span>
                      </Tooltip>
                    )
                  }
                  radios={props.enabledFilters?.terms?.map((term) => ({
                    id: `card-new-order-plans-coverage__warranty-term-form-${term}`,
                    label: `${term} Year`,
                    value: `${term}`,
                  }))}
                  value={props.formData.term ? `${props.formData.term}` : ''}
                  onChange={onTermChange}
                />
              </div>
            )}
          </OrderForm.RowItem>

          <OrderForm.RowItem>
            {props.enabledFilters?.sellersCoverage && (
              <div className="w-max">
                <RadioGroup
                  formField={true}
                  id="card-new-order-plans-coverage__warranty-sellersCoverage-form"
                  name="warrantySellersCoverage"
                  label="Include Sellers Coverage"
                  orientation="horizontal"
                  required={true}
                  secondaryAction={
                    brand === Brand.AHS && (
                      <Tooltip
                        placement="right"
                        content={<p className="text-left">{SELLERS_COVERAGE}</p>}
                      >
                        <span className="ml-2">
                          <IconInfoEmpty color="interactive" />
                        </span>
                      </Tooltip>
                    )
                  }
                  radios={[
                    {
                      id: 'card-new-order-plans-coverage__warranty-sellers-coverage-yes',
                      label: 'Yes',
                      value: 'true',
                    },
                    {
                      id: 'card-new-order-plans-coverage__warranty-sellers-coverage-no',
                      label: 'No',
                      value: 'false',
                    },
                  ]}
                  value={`${props.formData.sellersCoverage}`}
                  onChange={onSellersCoverageChange}
                />
              </div>
            )}
          </OrderForm.RowItem>

          <OrderForm.RowItem>
            {props.enabledFilters?.acCoverage && (
              <div className="w-max">
                <RadioGroup
                  formField={true}
                  id="card-new-order-plans-coverage__warranty-acCoverage-form"
                  name="warrantyAcCoverage"
                  label="A/C included in all plans"
                  orientation="horizontal"
                  required={true}
                  secondaryAction={
                    brand === Brand.AHS && (
                      <Tooltip
                        placement="right"
                        content={<p className="text-left">{AC_COVERAGE}</p>}
                      >
                        <span className="ml-2">
                          <IconInfoEmpty color="interactive" />
                        </span>
                      </Tooltip>
                    )
                  }
                  radios={[
                    {
                      id: 'card-new-order-plans-coverage__warranty-ac-coverage-yes',
                      label: 'Yes',
                      value: 'true',
                    },
                    {
                      id: 'card-new-order-plans-coverage__warranty-ac-coverage-no',
                      label: 'No',
                      value: 'false',
                    },
                  ]}
                  value={`${props.formData.acCoverage}`}
                  onChange={onAcCoverageChange}
                />
              </div>
            )}
          </OrderForm.RowItem>
        </>
      )}
    </OrderForm.Row>
  );
};

OrderFormProductFilters.defaultProps = {
  enabledFilters: {
    terms: [],
    sellersCoverage: null,
    acCoverage: null,
  },
};

export default OrderFormProductFilters;
