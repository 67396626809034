import React from 'react';
import ContactUsAuth from './ContactUsAuth';
import ContactUsNoAuth from './ContactUsNoAuth';
import { useCurrentRoute } from 'react-navi';

interface IContactUsProps {
  isAuthed?: boolean;
  contractId?: string;
  topic?: string;
  emailText?: string;
}

const ContactUs = (props: IContactUsProps) => {
  const route = useCurrentRoute();

  return props.isAuthed ? (
    <ContactUsAuth
      contractId={route?.data?.contractId}
      topic={route?.data?.topic}
      emailText={route?.data?.emailText}
    />
  ) : (
    <ContactUsNoAuth />
  );
};

export default ContactUs;
