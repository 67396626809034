import React from 'react';
import ModalConfirmCoverage, {
  ModalConfirmCoverageProps,
} from '@components/modal/ModalConfirmCoverage';
import RoofRXCoverage from '@components/modal/subcomponents/RoofRXCoverage';

const ModalRoofRXCoverage: React.FC<ModalConfirmCoverageProps> = (props) => (
  <ModalConfirmCoverage id="roof-rx-coverage-modal" heading="Roof Coverage" {...props}>
    {props.isActive && <RoofRXCoverage />}
  </ModalConfirmCoverage>
);

export default ModalRoofRXCoverage;
