import {
  CheckAccountExistsResponse,
  CreateProfileRequest,
  CreateProfileResponse,
  OfficeRequest,
  ProfileErrorResponse,
  ProfileOfficeData,
  ProfilePermissionsResponse,
  UpdateOfficeRequest,
  UserDetailsResponse,
  UserProfileResponse,
} from '@apis/models/profile.api.model';
import ProfileModel, { AuthType, ProfilePermissions, UserDetails } from '@app/models/profile.model';
import { getBrand } from '@helpers/brand.utils';
import { alphabetizeBy, cleanPhone, toTitleCase } from '@helpers/utils';
import { officeTypeFullFormDictionary } from '@constants/dictionaries';
import { deduplicateOffices } from '@services/helpers/profile.offices.helper';
import { BaseAPI } from './_api';

const baseConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Brand-Name': getBrand(),
  },
};

const configServiceName = 'app-ms';
const useJWT = true;

class API extends BaseAPI {
  // TODO Refactor this out, we have a Profile context to handle this
  public user: any;

  constructor() {
    super(configServiceName, useJWT, baseConfig);
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public async createProfile(request: CreateProfileRequest): Promise<CreateProfileResponse> {
    request.phoneNumber = cleanPhone(request.phoneNumber);
    return this.axios.post<any, CreateProfileResponse>(`${this.getHost()}/profile`, request).then(
      () => {
        return { status: 201, statusText: '' };
      },
      (e) => {
        console.log('Create Profile request failed.', e);
        return { status: e.response.status, statusText: e.toString() };
      },
    );
  }

  // Update affiliated offices
  // TODO Refactor this out, duplicated method with updateUsersOfficeDetails
  public async updateOfficesForProfile(request: UpdateOfficeRequest): Promise<unknown> {
    return this.axios
      .post(`${this.getHost()}/profile/offices`, request)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log('Update offices for Profile request failed.', e);
        return undefined;
      });
  }

  public async getUser(): Promise<ProfileModel> {
    return this.axios
      .get<any, UserProfileResponse>(`${this.getHost()}/profile/auth-id`)
      .then((res) => {
        res.offices.sort(alphabetizeBy((office) => office.name));
        res.workedWithOffices.sort(alphabetizeBy((office) => office.name));
        this.setUserProfile = res;
        return translateProfileToModel(res);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // TODO Until we refactor Profile fetching, we need to distinguish between null and undefined for now
          return null;
        }
        console.log('Get Profile request failed.', e);
        return undefined;
      });
  }

  public async updateUser(request: ProfileModel): Promise<ProfileErrorResponse> {
    const requestObj = {
      profileID: request.profileID,
      firstName: request.firstName ? request.firstName : null,
      lastName: request.lastName ? request.lastName : null,
      phoneType: request.phoneType ? request.phoneType : null,
      phoneNumber: request.phoneNumber ? cleanPhone(request.phoneNumber) : null,
      narID: request.narID ? request.narID : null,
      email: request.email ? request.email : null,
      hasConfirmedOffice: request.hasConfirmedOffice ? request.hasConfirmedOffice : null,
      workedWithOffices: request.workedWithOffices
        ? deduplicateOffices(
            request.workedWithOffices.map((office) => {
              return { id: office.id, type: officeTypeFullFormDictionary[office.type] };
            }),
          )
        : null,
      roleID: request.roleID ? request.roleID : null,
    };

    return this.axios
      .patch<any, ProfileErrorResponse>(`${this.getHost()}/profile`, requestObj)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err.response.isAxiosError = true;
        // TODO: Errors need to be handled according to status codes
        return err.response;
      });
  }

  public async adminUpdateProfile(request: ProfileModel): Promise<ProfileErrorResponse> {
    const requestObj = {
      profileID: request.profileID,
      firstName: request.firstName ? request.firstName : null,
      lastName: request.lastName ? request.lastName : null,
      phoneType: request.phoneType ? request.phoneType : null,
      phoneNumber: request.phoneNumber ? cleanPhone(request.phoneNumber) : null,
      narID: request.narID ? request.narID : null,
      email: request.email ? request.email : null,
      hasConfirmedOffice: request.hasConfirmedOffice ? request.hasConfirmedOffice : null,
      workedWithOffices: request.workedWithOffices
        ? deduplicateOffices(
            request.workedWithOffices.map((office) => {
              return { id: office.id, type: officeTypeFullFormDictionary[office.type] };
            }),
          )
        : null,
      roleID: request.roleID ? request.roleID : null,
      roleIDType: request.roleIDType ? request.roleIDType : null,
    };

    return this.axios.patch<any, ProfileErrorResponse>(
      `${this.getHost()}/admin/profile`,
      requestObj,
    );
  }

  public async updateUsersOfficeDetails(request: UpdateOfficeRequest): Promise<any> {
    request.offices = transformToOfficeRequest(request.offices);
    return this.axios
      .post<any, any>(`${this.getHost()}/profile/offices`, request)
      .then(() => {
        // note: here if the api is successful then the res is blank so
        // so returning success as string to let us know in the component
        return 'success';
      })
      .catch((err) => {
        // TODO: Errors need to be handled according to status codes
        console.log('Error in updateUsersOfficeDetails = ', err.response);
        const error = err.response.data;
        if (error && error.errors && error.errors.length > 0) {
          return err.response.data.errors.map((singleError) => singleError.code);
        }
        return null;
      });
  }

  public async checkAccountExists(email: string): Promise<CheckAccountExistsResponse> {
    return this.axios
      .get<any, CheckAccountExistsResponse>(`${this.getHost()}/account?email=${email}`)
      .then(() => {
        return { status: 200, statusText: '' };
      })
      .catch((e) => {
        return { status: 500, statusText: e };
      });
  }

  public getPermission(): Promise<ProfilePermissions> {
    return this.axios
      .get<any, ProfilePermissionsResponse>(`${this.getHost()}/profile/permissions`)
      .then((res) => {
        return translatePermissionsToModel(res);
      })
      .catch((e) => {
        console.log('Get Permissions request failed.', e);
        return {
          Office: [],
          Agent: [],
          Account: [],
          Admin: [],
          Contract: [],
        };
      });
  }

  public searchUsers(searchString): Promise<UserDetails[]> {
    const searchRequest = {
      query: searchString,
    };

    return this.axios
      .post<any, any>(`${this.getHost()}/userSearch`, searchRequest)
      .then((res) => {
        return translateDetailsToModel(res.users);
      })
      .catch((e) => {
        console.log('Search users request failed.', e);
        return [];
      });
  }

  // TODO Refactor this out, we have a Profile context to handle this
  public set setUserProfile(profile: any) {
    this.user = profile;
  }

  // TODO Refactor this out, we have a Profile context to handle this
  public getUserProfile() {
    return this.user;
  }
}

const ProfileApi = new API();
export default ProfileApi;

function translateDetailsToModel(response: UserDetailsResponse[]): UserDetails[] {
  const returnArray: UserDetails[] = [];

  response.forEach((userDetail) => {
    const model: UserDetails = {
      agentID: userDetail.roleID,
      brand: userDetail.brand,
      email: userDetail.email,
      faEmail: userDetail.faEmail,
      emailVerified: userDetail.emailVerified,
      firstName: userDetail.firstName,
      lastActiveDate: new Date(userDetail.lastActiveDate).toString(),
      lastForgotPassword: new Date(userDetail.lastForgotPassword).toString(),
      lastName: userDetail.lastName,
      lastPasswordUpdate: userDetail.lastPasswordChangeDate
        ? new Date(userDetail.lastPasswordChangeDate).toString()
        : null,
      offices: userDetail.offices,
      phone: userDetail.phoneNumber,
      registrationDate: new Date(userDetail.registrationDate).toString(),
      userID: userDetail.AhsUserID,
      userType: userDetail.roleIDType,
      profileID: userDetail.profileID,
      narID: userDetail.narID,
      lastActiveDates: null,
      isWarrantyLinkBroker: null,
      authIDType: AuthType[userDetail.authIDType],
      authID: userDetail.authID,
    };

    returnArray.push(model);
  });

  return returnArray;
}

function translatePermissionsToModel(response: ProfilePermissionsResponse): ProfilePermissions {
  return {
    Office: response.Office || [],
    Account: response.Account || [],
    Agent: response.Agent || [],
    Admin: response.Admin || [],
    Contract: response.Contract || [],
  };
}

function translateProfileToModel(response: UserProfileResponse): ProfileModel {
  return {
    email: response.email,
    firstName: toTitleCase(response.firstName),
    lastName: toTitleCase(response.lastName),
    brand: response.brand,
    narID: response.narID,
    phoneNumber: response.phoneNumber,
    phoneType: response.phoneType,
    roleIDType: response.roleIDType,
    profileID: response.profileID,
    authID: response.authID,
    authIDType: response.authIDType,
    roleID: response.roleID,
    AhsUserID: response.AhsUserID,
    offices: response.offices.map(translateProfileOfficeToModel),
    workedWithOffices: response.workedWithOffices.map(translateProfileOfficeToModel),
    hasConfirmedOffice: response.hasConfirmedOffice,
    active: response.active,
    isIncomplete: !!response.isIncomplete,
  };
}

function translateProfileOfficeToModel(office: ProfileOfficeData): ProfileOfficeData {
  if (office.address) {
    office.address = {
      ...office.address,
      address1: toTitleCase(office.address.address1),
      address2: toTitleCase(office.address.address2),
      city: toTitleCase(office.address.city),
      state: office.address.state,
      zip: office.address.zip,
    };
  }
  return {
    franchiseCode: office.franchiseCode,
    type: office.type,
    id: office.id,
    active: office.active,
    name: toTitleCase(office.name),
    phone: office.phone,
    address: office.address,
    warrantyLinkEligible: office.warrantyLinkEligible,
    userExtensions: office.userExtensions,
  };
}

function transformToOfficeRequest(request: any[]): OfficeRequest[] {
  return request.map((office) => {
    return {
      id: office.id,
      type: office.type,
    };
  });
}
