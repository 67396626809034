import React from 'react';
import Modal from '@components/modal/Modal';
import IncompleteOrderFound, {
  IncompleteOrderFoundProps,
} from '@components/modal/subcomponents/IncompleteOrderFound';
import { Button } from '@ftdr/blueprint-components-react';
import { INCOMPLETE_ORDER_FOUND } from '@app/locales/en';

export interface ModalIncompleteOrderFoundProps extends IncompleteOrderFoundProps {
  isActive: boolean;
  onContinueNewOrder: () => void;
  onContinuePreviousOrder: (orderID: string) => void;
}

const ModalIncompleteOrderFound: React.FC<ModalIncompleteOrderFoundProps> = (props) => {
  const getActions = () => {
    const actions: JSX.Element[] = [
      <Button
        key="continue"
        id="continue-previous-order"
        label="Continue Previous Order"
        onClick={() => props.onContinuePreviousOrder(props.order.orderID)}
        width="full"
        labelAlign="center"
        size="medium"
      />,
      <Button
        key="cancel"
        id="continue-new-order"
        label="Continue Creating New Order"
        onClick={() => props.onContinueNewOrder()}
        width="full"
        labelAlign="center"
        size="medium"
        variant="ghost"
      />,
    ];
    return actions;
  };

  return (
    <Modal
      isActive={props.isActive}
      onClose={props.onContinueNewOrder}
      heading={INCOMPLETE_ORDER_FOUND}
      actions={getActions()}
      closeOnOutsideClick={false}
      canClose={false}
      hideClose={true}
      className="w-full"
      noContentContainer={true}
      noActionsMarginT={true}
      noContentMarginX={true}
    >
      {props.isActive && <IncompleteOrderFound {...props} />}
    </Modal>
  );
};

export default ModalIncompleteOrderFound;
