import { Text } from '@ftdr/blueprint-components-react';
import React from 'react';

const RoofRXCoverage = () => {
  return (
    <div className="flex flex-col space-y-4">
      <Text>
        Exclusive offer by Roof Rx, Inc. to AHS customers. All roof related coverage and services to
        be provided by Roof Rx, Inc.
      </Text>
      <Text variant="caption">Roof Rx Coverage Includes:</Text>
      <ul className="list-disc ml-5 text-sm">
        <li>Preventive maintenance visit with a value of $199</li>
        <li>Repair of covered roof leaks due to normal wear and tear</li>
        <li>
          Comprehensive roof component coverage due to roof leaks include:
          <ul className="list-circle ml-5">
            <li>Repair or replacement of metal flashings</li>
            <li>Missing or broken shingles, tiles, shakes, or flat roof materials</li>
            <li>Repair or replacement of underlayment and felt</li>
            <li>Broken or open pipe seals and vents</li>
          </ul>
        </li>
        <li>$1000 leak coverage per occurrence</li>
        <li>$5000 aggregate leak coverage per contract term</li>
      </ul>

      <Text variant="caption">
        When the Roof RX technician arrives at the home you will pay them a $75 Service Call Fee for
        services other than preventative maintenance.
      </Text>

      <Text variant="caption">
        <b>
          Once purchased, please call Roof Rx at 800-676-8490 with Roof Coverage questions or to
          schedule your preventive maintenance visit, claims or other services.
        </b>
      </Text>
    </div>
  );
};

export default RoofRXCoverage;
