export interface deepLinkActions {
  deepLinkLocation: location | string;
  deepLinkCategory: category | string;
  deepLinkField: field | string;
  deepLinkFieldValue: fieldValue | any;
}

export enum location {}

export enum category {
  buyerInfo = 'buyerInfo',
  missingCooperatingAgent = 'addMCA',
}

export enum field {}

export enum fieldValue {}

// A structure to represent all deep links available on a given page.
// Add a new entry to this interface every time a new deep link is implemented.
export interface deepLinkImplementations {
  myOrders: {
    contractDrawer: {
      buyerInfo: string;
    };
  };
}

export function isLocationValid(locationString: string | location): boolean {
  for (const value in location) {
    if (typeof location[value] === 'string' || locationString === value) {
      return true;
    }
  }

  return false;
}

export function isCategoryValid(categoryString: string | category): boolean {
  for (const value in category) {
    if (typeof category[value] === 'string' || categoryString === value) {
      return true;
    }
  }

  return false;
}

export function isFieldValid(fieldString: string | field): boolean {
  for (const value in field) {
    if (typeof field[value] === 'string' || fieldString === value) {
      return true;
    }
  }

  return false;
}

export function isFieldValueValid(fieldValueParam: any | fieldValue): boolean {
  for (const value in fieldValue) {
    if (value === fieldValueParam) {
      return true;
    }
  }

  return false;
}
