import React, { useEffect, useState } from 'react';
import {
  Button,
  IconInfoEmpty,
  ProgressIndicator,
  RadioGroup,
  Switch,
  Text,
} from '@ftdr/blueprint-components-react';
import { Brand } from '@constants/brands';
import Tooltip from '@components/tooltip/Tooltip';
import {
  AC_COVERAGE,
  SELLERS_COVERAGE,
  WARRANTY_TERM_LENGTH,
} from '@constants/formField-constants';
import CardBox from '@components/card/CardBox';
import { ProductFilters } from '@apis/models';
import { SelectedProductFilters } from '@components/card/CardNewOrderPlansCoverage';
import { getBrand } from '@helpers/brand.utils';
import { classNames } from '@utils';

export interface FormNewOrderPlanFilterProps {
  /** hides all filters (if any) and displays a loading spinner instead */
  isLoadingFilters: boolean;
  /** should prevent the user from toggling sellers selection. applicable for existing contracts that already have sellers coverage. Cannot remove */
  disableSellersSelection: boolean;
  /** indicates what filters should be displayed */
  enabledFilters: ProductFilters;
  /** indicates what the selections are like for the filters */
  selectedFilters: SelectedProductFilters;
  /** fired whenever the seller filter was interacted */
  onChangeSeller: (includeSellers: boolean) => void;
  /** fired whenever the terms filter was interacted */
  onChangeTerms: (termSelection: string) => void;
  /** fired whenever the AC filter was interacted */
  onChangeAC: (includeAC: boolean) => void;
  isPastClosingDate?: boolean;
}

/** Note: children will reside inside the last card box of the filter */
const FormNewOrderPlanFilter: React.FC<FormNewOrderPlanFilterProps> = ({
  isLoadingFilters,
  enabledFilters,
  selectedFilters,
  onChangeSeller,
  onChangeTerms,
  onChangeAC,
  isPastClosingDate,
  ...props
}) => {
  const brand = getBrand();
  const [isTermYearSelectionAllowed, setIsTermYearSelectionAllowed] = useState<boolean>(false);
  let isFirstLoad = true;

  useEffect(() => {
    if (isFirstLoad && selectedFilters?.termYear) {
      isFirstLoad = true;
      onChangeTerms(selectedFilters.termYear.toString());
    }
  }, []);

  useEffect(() => {
    setIsTermYearSelectionAllowed(isPastClosingDate);
  }, [isPastClosingDate]);

  const getSwitchStatus = () => {
    if (isPastClosingDate) {
      return true;
    }
    return props.disableSellersSelection;
  };

  const getRadioStatus = () => {
    if (isTermYearSelectionAllowed) {
      return false;
    }
    return selectedFilters?.includeSellersCoverage;
  };

  return (
    <>
      {isLoadingFilters ? (
        <div className="mt-4 w-full flex flex-col items-center">
          <ProgressIndicator
            variant="circular"
            size="large"
            label="Loading Plan Options..."
            labelPlacement="bottom"
          />
        </div>
      ) : (
        <>
          {enabledFilters?.sellersCoverage && (
            <div className="flex items-center mx-4">
              <Switch
                id="card-new-order-plans-coverage__sellers-coverage-options-desired-form"
                name="sellersCoverage"
                label="Include Seller Coverage Option"
                className="bds-override bds-select--label-variant"
                disabled={getSwitchStatus()}
                labelPlacement="left"
                value="yes"
                on={getRadioStatus()}
                onChange={(e) => onChangeSeller(e.target.checked)}
              />
              {brand === Brand.AHS && (
                <Tooltip
                  placement="right"
                  content={<p className="text-left">{SELLERS_COVERAGE}</p>}
                >
                  <span className="ml-2">
                    <IconInfoEmpty color="interactive" />
                  </span>
                </Tooltip>
              )}
            </div>
          )}

          {enabledFilters && (
            <div className="pt-6">
              <CardBox
                heading="Select Home Buyer's Coverage"
                variant="bordered"
                mobileVariant="none"
                className={classNames([
                  'sm-max:-mx-1 sm-max:border-t-1 border-gray-300',
                  'sm-max:rounded-tl-0 sm-max:rounded-tr-0 sm-max:rounded-bl-0 sm-max:rounded-br-0',
                ])}
              >
                {enabledFilters.terms.length > 0 && (
                  <div className="w-max">
                    <RadioGroup
                      formField={true}
                      formFieldMessageId="wb_error-card-new-order-plans-coverage__warranty-term-form"
                      id="card-new-order-plans-coverage__warranty-term-form"
                      name="warrantyTermLength"
                      label="Warranty Term Length"
                      orientation="horizontal"
                      required={true}
                      secondaryAction={
                        brand === Brand.AHS &&
                        enabledFilters.terms &&
                        enabledFilters.terms.length > 1 && (
                          <Tooltip
                            placement="right"
                            content={<p className="text-left">{WARRANTY_TERM_LENGTH}</p>}
                          >
                            <span className="ml-2">
                              <IconInfoEmpty color="interactive" />
                            </span>
                          </Tooltip>
                        )
                      }
                      radios={enabledFilters.terms.map((term) => ({
                        id: `card-new-order-plans-coverage__warranty-term-form-${term}`,
                        label: `${term} Year`,
                        value: `${term}`,
                      }))}
                      value={selectedFilters?.termYear ? `${selectedFilters.termYear}` : ''}
                      onChange={(e) => {
                        setIsTermYearSelectionAllowed(false);
                        onChangeTerms(e);
                      }}
                    />
                  </div>
                )}

                {enabledFilters.acCoverage && (
                  <>
                    <div className="inline-flex items-center justify-center pr-2">
                      <Text>A/C included in all plans</Text>
                      {brand === Brand.AHS && (
                        <Tooltip
                          placement="right"
                          content={<p className="text-left">{AC_COVERAGE}</p>}
                        >
                          <span className="ml-2">
                            <IconInfoEmpty color="interactive" />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                    <Button
                      id="card-new-order-plans-coverage__remove-ac-link"
                      label={selectedFilters?.includeACCoverage ? 'Remove' : 'Add'}
                      variant="ghost"
                      size="small"
                      onClick={() => onChangeAC(!selectedFilters?.includeACCoverage)}
                    />
                  </>
                )}

                {props.children}
              </CardBox>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FormNewOrderPlanFilter;
