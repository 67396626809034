import React from 'react';
import { AuthProvider } from '@ftdr/use-auth/build';
import { ProfileProvider } from '../ProfileContext';
import { OptimizelyProvider } from '@context/OptimizelyContext';
import { NewOrderProvider } from '@context/NewOrderContext';

const CombinedProvider = ({ children, serviceConfigs }) => (
  <AuthProvider serviceConfigs={serviceConfigs}>
    <OptimizelyProvider>
      <ProfileProvider>
        <NewOrderProvider>{children}</NewOrderProvider>
      </ProfileProvider>
    </OptimizelyProvider>
  </AuthProvider>
);

export default CombinedProvider;
