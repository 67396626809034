import { Office, WarrantylinkAgreement } from '@apis/models';
import React from 'react';
import Drawer, { DrawerProps } from './Drawer';
import SignWlkAgreement from '@components/drawer/subcomponents/SignWLKAgreement';

interface Props extends DrawerProps {
  id: string;
  onAccept: (signerName: string, signerTitle: string) => void;
  onDecline: (signerName: string, signerTitle: string) => void;
  office: Office;
  agreement: WarrantylinkAgreement;
  heading: string;
}

const DrawerWLKAgreements: React.FC<Props> = (props) => {
  return (
    <Drawer
      id={props.id}
      isActive={props.isActive}
      onClose={() => {
        // resetState();
        props.onClose();
      }}
      className="h-full max-h-screen"
      slidingContentClassName="h-full"
    >
      {props.isActive && (
        <SignWlkAgreement
          id={props.id}
          onAccept={props.onAccept}
          onDecline={props.onDecline}
          office={props.office}
          agreement={props.agreement}
          heading={props.heading}
          isActive={props.isActive}
        />
      )}
    </Drawer>
  );
};

export default DrawerWLKAgreements;
