import React from 'react';
import { savedLocationForRedirect } from '@services/callback.service';
import { JUST_LOGGED_IN_BANNER_KEY, JUST_LOGGED_IN_KEY } from '@helpers/localStorage.utils';
import { clearSoftSplashScreenDismissals } from '@components/splashScreen/SplashScreenLauncher';

interface props {
  authService: any;
}

export default class Callback extends React.Component<props, any> {
  async componentDidMount() {
    this.checkAndRedirect();
  }

  /*
   * Custom login code to be executed after pkce Validates the login
   */
  onLoginSuccess = () => {
    localStorage.setItem(JUST_LOGGED_IN_KEY, 'true');
    localStorage.setItem(JUST_LOGGED_IN_BANNER_KEY, 'true');
    clearSoftSplashScreenDismissals();
  };

  checkAndRedirect = async () => {
    if (this.props.authService.oidcUser) {
      window.location.replace(savedLocationForRedirect());
    } else {
      await this.props.authService.callback(this.onLoginSuccess);
    }
  };

  render() {
    return (
      <div>
        <p>Redirecting...</p>
        <p>
          {' '}
          If you are not redirected in 5 seconds, click <a href="/dashboard">here</a>
        </p>
      </div>
    );
  }
}
