import React from 'react';
import { Separator, Tag, Text } from '@ftdr/blueprint-components-react';
import AddressDisplay from '@components/content/AddressDisplay';
import { toAppAddress } from '@apis/address.api';
import { Order } from '@apis/models/orders.api.model';
import { Address } from '@app/models';
import { formatDate } from '@helpers/utils';

export interface IncompleteOrderFoundProps {
  order: Order;
  initiatingOfficeName: string;
  initiatingAgentName: string;
  workflowSteps: string[];
  orderCurrentStep: string;
}

const IncompleteOrderFound: React.FC<IncompleteOrderFoundProps> = (props) => {
  const divider = <Separator gap="sm" orientation="horizontal" className="col-span-2" />;
  const address: Address = props.order && toAppAddress(props.order.propertyAddress);

  const officeName =
    props.order?.details?.officeAgents?.initiating?.office?.name || props.initiatingOfficeName;

  const orderInitAgent = props.order?.details?.officeAgents?.initiating?.agent;
  const agentName = orderInitAgent
    ? `${orderInitAgent.firstName} ${orderInitAgent.lastName}`
    : props.initiatingAgentName;

  const createdDate = formatDate(props.order?.createdDate);

  const stepDescription =
    props.workflowSteps &&
    props.orderCurrentStep &&
    `${props.workflowSteps.indexOf(props.orderCurrentStep)} out of ${props.workflowSteps.length} complete`;

  const stepTag = props.orderCurrentStep && (
    <Tag color="warning" className="font-bold">
      {props.orderCurrentStep}
    </Tag>
  );

  return (
    <div className="grid grid-cols-max">
      {divider}
      {!props.order ? (
        <>
          <div>Unable to load order information.</div>
        </>
      ) : (
        <>
          <Row id="order-property-address" heading="Property Address">
            <AddressDisplay address={address} />
          </Row>
          {divider}
          <Row id="order-initiating-office" heading="Initiating Office">
            {officeName}
          </Row>
          {divider}
          <Row id="order-initiating-agent" heading="Initiating Agent">
            {agentName}
          </Row>
          {divider}
          <Row id="order-created-on" heading="Created On">
            {createdDate}
          </Row>
          {divider}
          <Row id="order-steps" heading="Steps">
            {stepDescription}
          </Row>
          {divider}
          <Row id="order-latest-step" heading="Last Active Step">
            {stepTag}
          </Row>
        </>
      )}
    </div>
  );
};

interface RowProps {
  id: string;
  heading: string;
}

const Row: React.FC<RowProps> = ({ id, heading, children }) => {
  return (
    <>
      <div className="pl-4 pr-8">
        <Text id={`${id}-label`} variant="heading-06">
          {heading}
        </Text>
      </div>
      <div>
        <Text id={`${id}-content`}>{children}</Text>
      </div>
    </>
  );
};

export default IncompleteOrderFound;
