import React from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import classNames from 'classnames';

export interface EditButtonProps {
  id: string;
  isEditing?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  outerClassName?: string;
  buttonClassName?: string;
}

export const EditButton: React.FC<EditButtonProps> = ({
  id,
  isEditing,
  isDisabled,
  onClick,
  outerClassName,
  buttonClassName,
}) => {
  return (
    <div className={classNames(['py-0 flex-none w-14', outerClassName])}>
      <Button
        className={classNames(['mt-5', buttonClassName])}
        variant={isEditing ? 'ghost' : 'outlined'}
        id={id}
        label={isEditing ? 'Cancel' : 'Edit'}
        size="small"
        disabled={isDisabled}
        onClick={onClick}
      />
    </div>
  );
};
