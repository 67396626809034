import React from 'react';
import {
  IconCheckCircledCheckedFilled,
  IconCheckCircledUnchecked,
} from '@ftdr/blueprint-components-react';

export interface InputSelectorProps {
  selected: boolean;
  onSelect: () => void;
}

/** Adds a radio button selection to the beginning of the input element. Input element is a child. */
const InputSelector: React.FC<InputSelectorProps> = (props) => {
  const SelectionIcon = props.selected ? (
    <IconCheckCircledCheckedFilled size={20} color="interactive" onClick={props.onSelect} />
  ) : (
    <IconCheckCircledUnchecked size={20} onClick={props.onSelect} />
  );
  return (
    <div className="flex flex-row space-x-2" onFocus={props.onSelect}>
      {SelectionIcon}
      <div className="flex-1">{props.children}</div>
    </div>
  );
};

export default InputSelector;
