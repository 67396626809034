// gte records of current page getitng displayed
export const getAcivePageData = (list, activePage, PER_PAGE_RECORDS, setCurrentPageRecords) => {
  if (activePage !== 0) {
    let increment = 0;
    for (let i = 0; i < activePage; i += 1) {
      increment += PER_PAGE_RECORDS;
    }
    setCurrentPageRecords(list.slice(0 + increment, PER_PAGE_RECORDS + increment));
  } else {
    setCurrentPageRecords(list.slice(0, PER_PAGE_RECORDS));
  }
};

// set activepage number on pagination next click
export const onPaginationNextClick = (setActivePage, activePageNumber, TotalPages, addFilter) => {
  if (!(activePageNumber === TotalPages - 1)) {
    setActivePage(activePageNumber + 1);
    addFilter(undefined, {
      label: undefined,
      operation: 'pageChange',
      type: 'page',
      payload: { page: activePageNumber + 1 },
    });
  }
};

// set activepage number on pagination back click
export const onPaginationPrevClick = (setActivePage, activePageNumber, addFilter) => {
  if (activePageNumber !== 0) {
    setActivePage(activePageNumber - 1);
    addFilter(undefined, {
      label: undefined,
      operation: 'pageChange',
      type: 'page',
      payload: { page: activePageNumber - 1 },
    });
  }
};

// set activepage number on pagination page number click
export const onPageNumberClick = (setActivePage, activePageNumber, addFilter) => {
  setActivePage(activePageNumber);
  addFilter(undefined, {
    label: undefined,
    operation: 'pageChange',
    type: 'page',
    payload: { page: activePageNumber },
  });
};

// get total page count
export const setTotalPageCount = (list, PER_PAGE_RECORDS) => {
  return Math.ceil(list / PER_PAGE_RECORDS);
};
