import React, { useContext, useEffect, useState } from 'react';
import ProfileContext from '@context/ProfileContext';
import { isProdEnv } from '@helpers/utils';
import { isInternalUser } from '@helpers/profile.utils';
import Layout from '@components/layout/Layout';

/** demo pages are only visible for nonprod environments or internal/siteadmin users */
const DemoPageLayout: React.FC = (props) => {
  const { profile } = useContext(ProfileContext);

  const [showTemplate, setShowTemplate] = useState(false);

  useEffect(() => {
    if (!isProdEnv()) {
      setShowTemplate(true);
    } else if (isInternalUser(profile)) {
      setShowTemplate(true);
    } else {
      setShowTemplate(false);
    }
  }, [profile]);

  return (
    <Layout isLoggedIn={true} slug="demo">
      {showTemplate ? <div className="py-5">{props.children}</div> : <>Access Denied</>}
    </Layout>
  );
};

export default DemoPageLayout;
