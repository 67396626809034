import { BaseAPI } from './_api';

class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }

  public submit(formData: FormData, guestToken?: string): Promise<boolean> {
    return this.axios
      .post<any, any>(
        `${this.getHost()}/contact/submit`,
        formData,
        guestToken
          ? {
              headers: {
                Authorization: `Bearer ${guestToken}`,
              },
            }
          : null,
      )
      .then((res) => {
        console.log('Success', res);
        return true;
      })
      .catch((e) => {
        console.log('submitting form failed.', e);
        return false;
      });
  }
}

const ContactUsApi = new API();
export default ContactUsApi;
