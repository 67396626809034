import React from 'react';
import { Button, IconCalendar } from '@ftdr/blueprint-components-react';

export interface DateFilterButtonProps {
  id: string;
  onClick: () => void;
}

export const DateFilterButton = (props: DateFilterButtonProps) => {
  return (
    <Button
      id={props.id}
      onClick={props.onClick}
      width="full"
      className="pt-2 pb-2 pl-0"
      size="small"
      variant="ghost"
      label="Date Range"
      labelAlign="left"
      startIcon={<IconCalendar className="button-medium-icon ml-0-override mr-0-override" />}
    />
  );
};
