import React from 'react';
import Layout from '@components/layout/Layout';
import Template from '@templates/admin/AdminTemplate';
import { ContentBox } from '@components/layout/ContentBox';

export default class Admin extends React.Component<any, any> {
  render() {
    return (
      <Layout isLoggedIn={true}>
        <ContentBox>
          <Template />
        </ContentBox>
      </Layout>
    );
  }
}
