import ProfileModel from '@app/models/profile.model';
import { UserRoleType } from '@constants/dictionaries';

export function isInternalUser(profile: ProfileModel): boolean {
  return (
    profile &&
    [UserRoleType.FieldSales, UserRoleType.InternalSalesAgent, UserRoleType.SiteAdmin].includes(
      profile.roleIDType as UserRoleType,
    )
  );
}

export function isAgent(profile: ProfileModel): boolean {
  return (
    profile?.roleIDType === UserRoleType.RealEstateAgent ||
    profile?.roleIDType === UserRoleType.ClosingCompanyAgent
  );
}

export function isCCAgent(profile: ProfileModel): boolean {
  return profile?.roleIDType === UserRoleType.ClosingCompanyAgent;
}

export function isCCAdmin(profile: ProfileModel): boolean {
  return profile?.roleIDType === UserRoleType.ClosingCompanyAdmin;
}

export function isREAgent(profile: ProfileModel): boolean {
  return profile?.roleIDType === UserRoleType.RealEstateAgent;
}

export function isCCUser(profile: ProfileModel): boolean {
  return (
    profile &&
    [UserRoleType.ClosingCompanyAgent, UserRoleType.ClosingCompanyAdmin].includes(
      profile.roleIDType as UserRoleType,
    )
  );
}
export function isAllowedRO(profile: ProfileModel): boolean {
  return (
    profile &&
    [UserRoleType.RealEstateAgent, UserRoleType.Broker].includes(profile.roleIDType as UserRoleType)
  );
}

export function isOfficeAdmin(profile: ProfileModel): boolean {
  return (
    profile &&
    [UserRoleType.RealEstateAdmin, UserRoleType.ClosingCompanyAdmin].includes(
      profile.roleIDType as UserRoleType,
    )
  );
}

export function isAdmin(profile: ProfileModel): boolean {
  return (
    profile &&
    [
      UserRoleType.SiteAdmin,
      UserRoleType.RealEstateAdmin,
      UserRoleType.ClosingCompanyAdmin,
      UserRoleType.Broker,
    ].includes(profile.roleIDType as UserRoleType)
  );
}
