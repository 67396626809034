import React from 'react';
import { Button, ContextMenu, IconButton, IconMoreHoriz } from '@ftdr/blueprint-components-react';

const ActionsDropdown = ({ menuItems, isLabelVisible = true }) => {
  return (
    <ContextMenu placement="bottom-start" menuItems={menuItems}>
      {isLabelVisible ? (
        <Button
          label="Actions"
          size="small"
          variant="ghost"
          startIcon={<IconMoreHoriz />}
          id="actions_dropdown"
        />
      ) : (
        <IconButton
          label="Actions"
          size="small"
          variant="ghost"
          icon={<IconMoreHoriz />}
          id="actions_dropdown"
        />
      )}
    </ContextMenu>
  );
};

export default ActionsDropdown;
