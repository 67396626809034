// TODO: This is going outside the pattern that is being established for newer modals.
//  Part of it is because this is an existing modal and migrating to the pattern would be more intensive,
//  but also because having a subcomponent's logic shared with another subcomponent wouldn't work out well.
//  Because of this, this code is written where the common logic is residing in this parent instead of the subcomponent.
//  We may want to explore service injection pattern to allow both approaches to work perhaps.

import Modal from './Modal';
import React, { useEffect, useState } from 'react';
import {
  Office,
  AgentSearchRecord,
  PossibleAgent,
  AgentMatchSearchRequest,
  AgentAuditRequest,
  AgentOfficeRequest,
} from '@apis/models';
import AddAgentToOffice, { NewAgentFormData } from './subcomponents/AddAgentToOffice';
import {
  FUZZY_MATCH_INITIATING_AGENT_MODAL_HEADER,
  FUZZY_MATCH_INITIATING_AGENT_MODAL_SUBHEADER,
} from '@constants/formField-constants';
import ModalViewAgentFuzzyMatch from './ModalViewAgentFuzzyMatch';
import { officeTypeDictionary, officeTypeFullFormDictionary } from '@constants/dictionaries';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import AgentApi from '@apis/agent.api';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { cleanPhone } from '@helpers/utils';
import { getBrand } from '@helpers/brand.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  SELECTED_INITIATING_AGENT_FROM_FUZZY,
  CREATED_NEW_INITIATING_AGENT_FROM_FUZZY,
} from '@constants/ga-events.constants';
import { OrderFlowType } from '@helpers/order.utils';

export interface ModalAddAgentToOfficeProps {
  id: string;
  isActive: boolean;
  /** Should set modal active to false */
  onCancel?: () => void;
  /** Should set modal active to false and handle adding agent to list */
  onSuccess?: (agent: AgentSearchRecord) => void;
  /** The office the agent should be added to */
  office: Office;
  /** The list of agents under the office so additional call is not needed */
  agents: AgentSearchRecord[];
  /** when set to true, would check for and display possible agent matches prior to creating the agent */
  fuzzyCheckEnabled?: boolean;
  /** The flow from which this is called */
  /** sourceFlow?: string */
  /** The flow from which this is called */
  sourceFlow?: OrderFlowType;
}

/** Checks if matching agent exists in office. Returns true if found. */
const checkExistingAgents = (agents: AgentSearchRecord[], values: NewAgentFormData): boolean => {
  // If no agents in list, then treat as false for now.
  if (agents.length === 0) {
    return false;
  }
  return agents.some((a) => {
    const phonenumber = a.phoneNumbers[0] ? a.phoneNumbers[0].phoneNumber : '';
    // Matching name
    const agentNameLookup = `${a.firstName.trim()}${a.lastName.trim()}${a.email.trim()}${phonenumber.trim()}`;
    const formNameLookup = `${values.firstName.trim()}${values.lastName.trim()}${values.email.trim()}${values.phoneNumber.trim()}`;
    if (agentNameLookup.toUpperCase() === formNameLookup.toUpperCase()) {
      return true;
    }
    return false;
  });
};

function buildCreateAgentRequest(
  values: NewAgentFormData,
  office: Office,
  checkExistingAgents: boolean,
): AgentSearchRecord {
  return {
    active: true,
    agentType: office.type,
    officeID: office.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    checkExistingAgents,
    phoneNumbers: values.phoneNumber
      ? [
        {
          phoneNumber: cleanPhone(values.phoneNumber),
          phoneType: 'Mobile',
        },
      ]
      : [],
  };
}

function buildAgentMatchSearchRequest(
  values: NewAgentFormData,
  office: Office,
): AgentMatchSearchRequest {
  return {
    agentType: office?.type,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    tenant: getBrand(),
    phone: cleanPhone(values.phoneNumber),
  };
}

function buildAgentAuditRequest(
  values: NewAgentFormData,
  office: Office,
  checkExistingAgents: boolean,
  userAction: string,
  agentOfficeID: AgentOfficeRequest,
  fuzzyMatchAgentIDs?: string[],
): AgentAuditRequest {
  let reqBody = {};
  switch (userAction) {
    case 'NormalAgentCreation':
      reqBody = { creation: agentOfficeID };
      break;
    case 'ExactMatchFound':
      reqBody = { matched: agentOfficeID };
      break;
    case 'FuzzyMatchSelected':
      reqBody = { matched: agentOfficeID };
      break;
    case 'FuzzyMatchIgnored':
      reqBody = { creation: agentOfficeID };
      break;
    default:
      console.warn('unknown method type');
      break;
  }

  const request: AgentAuditRequest = {
    createAgentRequest: buildCreateAgentRequest(values, office, checkExistingAgents),
    sourceApplication: 'RE Portal',
    userAction: userAction,
    fuzzyAgentIDs: fuzzyMatchAgentIDs,
    ...reqBody,
  };
  return request;
}

const ModalAddAgentToOffice: React.FC<ModalAddAgentToOfficeProps> = (props) => {
  const { addErrorToQueue } = useGlobalAlert();
  const { showSpinner } = useGlobalOverlaySpinner();

  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState<JSX.Element[]>([]);
  const [agentFuzzyMatches, setAgentFuzzyMatches] = useState<PossibleAgent[]>([]);
  const [fuzzyMatchAgentIDs, setFuzzyMatchAgentIDs] = useState<string[]>([]);
  const [showFuzzyMatchModal, setShowFuzzyMatchModal] = useState(false);
  const [formValues, setFormValues] = useState<NewAgentFormData>();

  /** We should not allow the user to proceed on with this modal if there is no office to be added to.
   * Force close the modal.
   */
  useEffect(() => {
    if (props.isActive && !props.office) {
      addErrorToQueue(msgs.ADD_AGENT_OFFICE_MISSING);
      props.onCancel();
    }

    if (props.isActive && !(props.office?.type in officeTypeFullFormDictionary)) {
      addErrorToQueue(msgs.ADD_AGENT_OFFICE_INVALID);
      props.onCancel();
    }
  }, [props.office, props.isActive]);

  const handleCreateAgentCall = async (
    values: NewAgentFormData,
    checkExistingAgents: boolean,
  ): Promise<AgentSearchRecord> => {
    showSpinner(true, 'creating agent...');
    return AgentApi.createAgent(buildCreateAgentRequest(values, props.office, checkExistingAgents))
      .then((agent) => {
        // On success, we want to return the agent to the parent, so it adds the agent to the agent list
        // and select the agent.
        if (agent) {
          props.onSuccess(agent);
        }
        return agent;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  const handleAgentAuditCall = async (
    values: NewAgentFormData,
    office: Office,
    checkExistingAgents: boolean,
    userAction: string,
    agentOfficeID: AgentOfficeRequest,
    fuzzyAgentIDs?: string[],
  ): Promise<void> => {
    await AgentApi.createAudit(
      buildAgentAuditRequest(
        values,
        office,
        checkExistingAgents,
        userAction,
        agentOfficeID,
        fuzzyAgentIDs,
      ),
    )
      .catch((err) => {
        console.error(err);
        throw err;
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  const handleFuzzyCheck = async (values: NewAgentFormData): Promise<void> => {
    //try to find exact match
    showSpinner(true, 'searching for an existing agent...');
    AgentApi.getExactMatch(buildAgentMatchSearchRequest(values, props.office))
      .then((res) => {
        if (res.winner) {
          //exact match found, go to agentAduitCall
          handleAgentAuditCall(values, props.office, true, 'ExactMatchFound', {
            agentID: res.winner.realEstateAgentID,
            officeID: res.winner.officeID,
          });
          handleCreateAgentCall(values, true);
        }
        //no winners go to fuzzy
        if (!res.nonWinner && !res.winner) {
          showSpinner(true, 'loading agent suggestions...');
          AgentApi.getPossibleMatch(buildAgentMatchSearchRequest(values, props.office))
            .then(async (res) => {
              if (res.results) {
                loadAgentFuzzyMatches(res.results);
              }
              if (!res.errors && !res.results) {
                //No exact match found and no fuzzy match found, audit call should be made after create agent call
                const agent = await handleCreateAgentCall(values, false);
                handleAgentAuditCall(values, props.office, false, 'NormalAgentCreation', {
                  agentID: agent.realEstateAgentID,
                  officeID: props.office.id,
                });
              }
            })
            .finally(() => {
              showSpinner(false);
            });
        }
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  const onSubmitForm = async (formData: NewAgentFormData) => {
    setFormValues(formData);
    setLoading(true);
    // Check agents list that there is not a match in name or email for the agent
    if (checkExistingAgents(props.agents, formData)) {
      addErrorToQueue(msgs.AGENT_EXISTS_IN_OFFICE);
      return;
    }
    if (props.fuzzyCheckEnabled) {
      await handleFuzzyCheck(formData);
    } else {
      await handleCreateAgentCall(formData, false);
    }
    setLoading(false);
  };

  const closeFuzzyModal = () => {
    setShowFuzzyMatchModal(false);
  };

  const loadAgentFuzzyMatches = (agentFuzzyMatches: PossibleAgent[]) => {
    setAgentFuzzyMatches(agentFuzzyMatches);
    setShowFuzzyMatchModal(true);
    //store fuzzyMatchAgentIDs for auditCall
    setFuzzyMatchAgentIDs(agentFuzzyMatches?.map((agent) => agent.agentID));
  };

  const onSelectFuzzyAgent = async (agent: PossibleAgent) => {
    fireGAEvent(
      SELECTED_INITIATING_AGENT_FROM_FUZZY(props.fuzzyCheckEnabled ? props.sourceFlow : ''),
    );
    try {
      if (agent.officeID !== props.office.id) {
        console.log('agent will be moved to new office', agent.officeID, props.office.id);

        showSpinner(true, 'updating agent...');
        //fuzzy match found and user selects a fuzzy agent, audit call should be made before moving offices
        handleAgentAuditCall(
          formValues,
          props.office,
          false,
          'FuzzyMatchSelected',
          { agentID: agent.agentID, officeID: props.office.id },
          fuzzyMatchAgentIDs,
        );

        await AgentApi.moveOffice({
          agentID: agent.agentID,
          officeID: props.office.id,
          typeCode: officeTypeDictionary[props.office.type],
        }).finally(() => {
          showSpinner(false);
        });
      }

      closeFuzzyModal();

      // map to the expected agent object instead of fetching latest agent
      props.onSuccess({
        realEstateAgentID: agent.agentID,
        agentType: officeTypeFullFormDictionary[props.office.type],
        active: true,
        firstName: agent.firstName,
        lastName: agent.lastName,
        phoneNumbers: agent.phones || [],
        email: agent.email,
        officeID: props.office.id,
      });
    } catch (e) {
      console.error('failed to select fuzzy agent', e);
    }
  };

  const onFuzzyModalSelectNewAgent = () => {
    fireGAEvent(
      CREATED_NEW_INITIATING_AGENT_FROM_FUZZY(props.fuzzyCheckEnabled ? props.sourceFlow : ''),
    );
    handleCreateAgentCall(formValues, false).then((agent) => {
      closeFuzzyModal(); // close only when successful
      //fuzzy match found but user clicks create new agent, go to auditCall after createAgentCall
      handleAgentAuditCall(
        formValues,
        props.office,
        false,
        'FuzzyMatchIgnored',
        { agentID: agent.realEstateAgentID, officeID: props.office.id },
        fuzzyMatchAgentIDs,
      );
    });
  };

  return (
    <>
      {!showFuzzyMatchModal ? (
        <Modal
          id={props.id}
          heading="Add a New Agent"
          isActive={props.isActive}
          onClose={props.onCancel}
          actions={actions}
        >
          {props.isActive && (
            <AddAgentToOffice
              id={props.id}
              loading={loading}
              setActions={setActions}
              onCancel={props.onCancel}
              onSubmitForm={onSubmitForm}
            />
          )}
        </Modal>
      ) : (
        <ModalViewAgentFuzzyMatch
          data={agentFuzzyMatches}
          active={showFuzzyMatchModal}
          header={FUZZY_MATCH_INITIATING_AGENT_MODAL_HEADER}
          subheader={FUZZY_MATCH_INITIATING_AGENT_MODAL_SUBHEADER}
          onClose={closeFuzzyModal}
          formValues={formValues}
          onUseSelectedAgent={onSelectFuzzyAgent}
          onCreateNewAgent={onFuzzyModalSelectNewAgent}
        />
      )}
    </>
  );
};

ModalAddAgentToOffice.defaultProps = {
  onSuccess: () => {
    /* Do nothing */
  },
  onCancel: () => {
    /* Do nothing */
  },
  agents: [],
};

export default ModalAddAgentToOffice;
