import React from 'react';
import { Text } from '@ftdr/blueprint-components-react';
import Modal from '@components/modal/Modal';
import Coverage from '@components/modal/subcomponents/Coverage';

const ModalCoverage = (props) => {
  const id = `modal-${props.id || `${props.color}-plan`}`;

  return (
    <>
      <Modal
        id={id}
        isActive={props.isActive}
        onClose={props.onClose}
        heading={
          <Text variant="heading-04" className="m-0">
            {props.plan} Coverage Details
          </Text>
        } // TODO: BDS doesn't support JSX.Element header properly, workaround.
      >
        {props.isActive && <Coverage {...props} />}
      </Modal>
    </>
  );
};

export default ModalCoverage;
