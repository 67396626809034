import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import PlanPriceCancelQuote, {
  PlanPriceCancelQuoteProps,
} from '@components/drawer/subcomponents/PlanPriceCancelQuote';
import { Quote } from '@apis/models/quote.api.model';

export interface DrawerPlanPriceCancelQuoteProps
  extends Required<Pick<DrawerProps, 'isActive' | 'onClose'>>,
    Omit<PlanPriceCancelQuoteProps, 'emailQuoteCall'> {
  onSuccess?: () => void;
  selectedOrder: Quote;
  isQuotePage?: boolean;
  onRefreshQuotes?: () => void;
}

const DrawerPlanPriceCancelQuote: React.FC<DrawerPlanPriceCancelQuoteProps> = (props) => {
  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <PlanPriceCancelQuote
          selectedOrder={props.selectedOrder}
          onClose={props.onClose}
          onRefreshQuotes={props.onRefreshQuotes}
        />
      )}
    </Drawer>
  );
};

export default DrawerPlanPriceCancelQuote;
