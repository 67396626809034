import React, { useEffect, useState } from 'react';
import { isEmailValid } from '@services/validation/ValidationRules';

// Components
import Drawer from '@components/drawer/Drawer';
import { Button, Input } from '@ftdr/blueprint-components-react';
import { EMAIL_REQUIRED, INVALID_EMAIL } from '@constants/formField-constants';

const DrawerWLKInvite = (props) => {
  const [email, setEmail] = useState(props.officeEmail);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    setEmail(props.officeEmail);
  }, [props.officeEmail]);

  const handleBlur = (emailInput) => {
    if (!emailInput) {
      setEmailError(EMAIL_REQUIRED);
    } else if (!isEmailValid(emailInput)) {
      setEmailError(INVALID_EMAIL);
    } else {
      setEmailError('');
    }
  };

  return (
    <Drawer {...props}>
      <h1 className="h3">Invite to WarrantyLink</h1>
      <p className="mt-2 sm:text-lg text-primary-400">{props.officeName}</p>

      <div className="w-full mt-12">
        <div className="text-xl font-bold text-primary-400">Send Invitation</div>
        <p className="mb-4">Enter an email address to send the invitation to.</p>
        <Input
          formField
          type="email"
          id="wlk-invite-email"
          label=""
          className="sm-max:pt-0"
          value={email}
          error={emailError}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => handleBlur(e.target.value)}
        />
        <div className="mt-10 -mx-4 md:-mx-8">
          <div className="md:flex md:flex-wrap md:flex-row-reverse md:justify-start md:items-center w-full px-4 md:px-8">
            <div>
              <Button
                id="wlk-send-invitation"
                className="sm-max:block"
                label="Send Invitation"
                variant="filled"
                disabled={emailError !== ''}
                onClick={() => {
                  props.onSubmit(email);
                }}
                width="full"
                labelAlign="center"
              />
            </div>
            <div className="text-center sm-max:mt-4 md:mr-4">
              <Button
                id="wlk-cancel-invitation"
                className="md:w-full sm-max:w-full"
                label="Cancel"
                variant="ghost"
                onClick={() => {
                  props.onClose();
                }}
                width="full"
                labelAlign="center"
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerWLKInvite;
