import React, { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from '@utils';
import { useWindowSize } from 'react-use';
// Components
import CardNewOrder from '@components/card/CardNewOrder';
import {
  convertCheckboxGroupOnChangeToOnClick,
  convertOptionsObjToCheckboxGroupOptions,
  convertValuesToCheckboxGroupValue,
} from '@components/form/FormCheckbox';
import useForm from '@helpers/UseForm';
import * as c from '@constants/formField-constants';
import {
  CLOSING_DATE_RANGE,
  PROJECTED_CLOSING_DATE,
  PROJECTED_CLOSING_DATE_OUT_OF_RANGE,
} from '@constants/formField-constants';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import { DateFormat, formatDate } from '@helpers/utils';
import AgentApi from '@apis/agent.api';
import { officeTypeDictionary } from '@constants/dictionaries';
import {
  getProfileOfficesByType,
  updateOfficesOnSave,
  updateWorkedWithOfficesOnSave,
} from '@services/helpers/profile.offices.helper';
import ModalAddAgentToOffice from '@components/modal/ModalAddAgentToOffice';
import { NOT_APPLICABLE_OPTION, selectSuggestion } from '@services/autosuggest/autosuggest';
import FormOfficeSelection from '@components/form/FormOfficeSelection';
import { ModalAgentVerification } from '@components/modal/ModalAgentVerification';
import { OfficeType } from '@app/models';
import ProfileContext from '../../context/ProfileContext';
import AgentAutosuggest from '../form/AgentAutosuggest';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import isValidDate from 'date-fns/isValid';
import {
  CheckboxGroup,
  DateTimeInput,
  Input,
  Panel,
  RadioGroup,
  Text,
} from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS, ORDER_VIEW_LABELS } from '@constants/newOrder-constants';
import {
  convertOptionsObjToRadioGroupOptions,
  convertRadioGroupOnChangeToOnClick,
  convertValuesToRadioGroupValue,
  getFormInputErrorId,
  getFormRadioErrorId,
} from '@storybook/addon-links';
import { ADD_NEW_AGENT } from '@constants/ga-events.constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { UserRoleType } from '@apis/models/profile.api.model';

/**
 * validates if the closing date provided fits within the indicated closing date range.
 * @param closingDate  the value to check against
 * @param listingCreationDate  for created contracts, the date range is dependent on the contract's date. Otherwise, don't define to treat with current date instead.
 * @return {boolean} true if closing date within date range. otherwise false. If no closing date, returns true.
 */
export const isClosingDateWithinRange = (closingDate, listingCreationDate = null) => {
  return (
    !closingDate ||
    isWithinInterval(new Date(closingDate), {
      start: addDays(CLOSING_DATE_RANGE(listingCreationDate).min, -1),
      end: addDays(CLOSING_DATE_RANGE(listingCreationDate).max, 0),
    })
  );
};

export const validateCardNewOrderClosingInfo = (listingCreationDate) => (values, isTouched) => {
  const errors = {};

  if (!isTouched) return errors;

  return errors;
};

// TODO: props.agentRepresent should be renamed to 'props.closingDateRequired' and combined with the props.showUnknownDate. Check using the component tests.
const CardNewOrderClosingInfo = ({ values: savedFormValues = {}, ...props }) => {
  const {
    values: scratchFormValues,
    setValues: setScratchFormValues,
    errors,
    handleBlur,
    handleSubmit,
    setErrors,
    forceTouchAll,
    onChangeInput,
  } = useForm(
    validateCardNewOrderClosingInfo(props.listingCreationDate),
    savedFormValues || {
      closingFileNumber: '',
      closingTitleCompany: '',
      closingTitleAgent: '',
      titleCompany: {
        yes: false,
        no: false,
      },
      AgentId: '',
      AgentEmail: '',
      closingOffice: '',
      dateUnknown: false,
      projectedClosingDate: '',
      userDetail: {
        firstName: '',
        lastName: '',
      },
    },
  );
  const { profile, setProfile } = useContext(ProfileContext);
  const { width } = useWindowSize();
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || false);
  const [closingDate, setClosingDate] = useState(
    savedFormValues?.projectedClosingDate ? new Date(savedFormValues.projectedClosingDate) : null,
  );
  const [isAddClosingAgentModalActive, setIsAddClosingAgentModalActive] = useState(false);
  const [isAgentVerificationActive, setIsAgentVerificationActive] = useState(false);

  const [suggestedAgents, setSuggestedAgents] = useState([]);
  const [selectedAgent, changeSelectedAgent] = useState(savedFormValues?.closingTitleAgent || '');
  const [agentHelpText, setAgentHelpText] = useState('');
  const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false);

  const isUserClosingCompanyAgent = props.userType === 'ClosingCompanyAgent';

  const optionLabels = {
    yes: 'Yes',
    no: 'No',
  };

  const [optionValues, setOptionValues] = useState(
    savedFormValues?.optionValues || {
      yes: false,
      no: false,
    },
  );

  const [dateCheckboxValue, setDateCheckboxValue] = useState({
    dateUnknown: false,
  });

  const dateCheckboxLabel = { dateUnknown: 'Date currently unknown' };
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  const toggleDateCurrentlyUnknown = (e) => {
    const isUnknownDate = e.target.checked;
    setDateCheckboxValue({ dateUnknown: isUnknownDate });
    setScratchFormValues((prevState) => ({
      ...prevState,
      dateUnknown: isUnknownDate,
      projectedClosingDate: isUnknownDate ? '' : prevState.projectedClosingDate,
    }));
    if (isUnknownDate) setClosingDate(undefined);
    setErrors({ ...errors, projectedClosingDate: '' });
  };

  // Show/hide title company fields. On hide, clear out all values related to it
  const [showTitleCompanyFields, setShowTitleCompanyFields] = useState(
    savedFormValues?.titleCompany?.yes || false,
  );
  const toggleTitleCompanyFields = (e) => {
    setShowTitleCompanyFields(e.target.value === 'yes');
    const updateOpts = {
      yes: false,
      no: false,
      [e.target.name]: e.target.checked,
    };
    setOptionValues(updateOpts);
    if (updateOpts.no) {
      const update = {
        ...scratchFormValues,
        closingTitleCompany: '',
        closingTitleAgent: '',
        closingFileNumber: '',
        office: undefined,
        closingOffice: undefined,
        AgentId: undefined,
        AgentEmail: undefined,
        titleCompany: updateOpts,
      };
      setScratchFormValues(update);
    } else if (updateOpts.yes) {
      setDateCheckboxValue({ dateUnknown: false });
      setScratchFormValues({ ...scratchFormValues, titleCompany: updateOpts });
    }
  };

  // Scroll to alternate address fields
  const titleCompanyFields = useRef(null);
  useEffect(() => {
    showTitleCompanyFields &&
      titleCompanyFields?.current &&
      titleCompanyFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
  }, [showTitleCompanyFields]);

  async function fetchAgents() {
    let res;
    if (savedFormValues.office && savedFormValues.office.id !== '') {
      res = await AgentApi.searchAgents(
        {
          officeId: savedFormValues.office.id,
          officeType: savedFormValues.office.type,
          activeInactive: 'A',
        },
        {
          tags: { source: 'CardNewOrderClosingInfo#fetchAgents' },
        },
      );
    }

    if (res) setScratchFormValues({ ...savedFormValues, agentlist: res.agentsList });
  }

  useEffect(() => {
    if (savedFormValues?.projectedClosingDate) {
      setClosingDate(new Date(savedFormValues.projectedClosingDate));
    }
  }, [savedFormValues?.projectedClosingDate]);

  useEffect(() => {
    if (savedFormValues) {
      // order dashboard doesnt have agent for office, get it.
      if (savedFormValues.office && !savedFormValues.agentlist) {
        fetchAgents();
      } else {
        setScratchFormValues(savedFormValues);
      }
      if (props.isEditing) {
        if (savedFormValues.projectedClosingDate) {
          setClosingDate(new Date(savedFormValues.projectedClosingDate));
        } else {
          setClosingDate(null);
        }
        if (savedFormValues.dateUnknown) {
          setDateCheckboxValue({ dateUnknown: savedFormValues.dateUnknown });
          setErrors({ ...errors, projectedClosingDate: '' });
        }
        if (savedFormValues.titleCompany) {
          setOptionValues({ ...savedFormValues.titleCompany });
          setShowTitleCompanyFields(savedFormValues.titleCompany.yes);
        } else {
          setShowTitleCompanyFields(false);
        }
      }
    }
  }, [props.isEditing]);

  /**
   * If the show unknown date flag was removed, then clear unknown date selection appropriately.
   * Also re-run this useEffect is isEditing gets changed, so this gets re-ran.
   */
  useEffect(() => {
    const isClosingDateRequired = props.agentRepresent;
    if (isClosingDateRequired) {
      toggleDateCurrentlyUnknown({ target: { checked: false } });
    }
  }, [props.agentRepresent, props.isEditing]);

  /** based on user role rules, update profile to include the defined office */
  const updateUserOffice = async (userOffice) => {
    if (
      userOffice &&
      props.userDetails &&
      officeTypeDictionary[props.userDetails.roleIDType] !== officeTypeDictionary['CC']
    ) {
      await updateWorkedWithOfficesOnSave(profile, userOffice);
    }
    if (
      userOffice &&
      props.userDetails &&
      props.userDetails.roleIDType === UserRoleType.ClosingCompanyAdmin
    ) {
      await updateOfficesOnSave(profile, userOffice, setProfile);
    }
  };

  const handleSave = async (event) => {
    const { hasErrors } = forceTouchAll();
    if (hasErrors) {
      event.preventDefault();
      return;
    }

    handleSubmit(event);

    updateUserOffice(scratchFormValues.closingOffice);

    if (
      (scratchFormValues.closingOffice || scratchFormValues.closingFileNumber) &&
      !scratchFormValues.projectedClosingDate
    ) {
      setErrors({ ...errors, projectedClosingDate: c.PROJECTED_CLOSING_DATE });
    } else if (dateCheckboxValue.dateUnknown) {
      setErrors({ ...errors, projectedClosingDate: '' });
    }

    if (
      selectedAgent &&
      !scratchFormValues.AgentId &&
      selectedAgent !== `${NOT_APPLICABLE_OPTION.firstName} ${NOT_APPLICABLE_OPTION.lastName}`
    ) {
      setIsAgentVerificationActive(true);

      // setIsEditing(true);
      // setIsOkToSubmit(false);
      // open add agent box
      return;
    }

    const hasClosingDate = Boolean(scratchFormValues.projectedClosingDate);
    const isDateUnknown = dateCheckboxValue.dateUnknown;
    const isDateRequired = !props.showUnknownDate;

    scratchFormValues.dateUnknown = dateCheckboxValue.dateUnknown;

    // if date not provided, then do not submit
    if (isDateRequired && !hasClosingDate) {
      setErrors({ ...errors, projectedClosingDate: c.PROJECTED_CLOSING_DATE });
      return;
    }

    if ((hasClosingDate || isDateUnknown) && !errors.projectedClosingDate) {
      props.handleSubmit(scratchFormValues);
    }
  };

  // Clear agent and office if office field is empty
  useEffect(() => {
    // closingoffice is the actual office, closingTitleCompany is the displayed name. is office necessary??
    if (
      !scratchFormValues.closingOffice ||
      !scratchFormValues.closingTitleCompany ||
      !scratchFormValues.office
    ) {
      setScratchFormValues({
        ...scratchFormValues,
        closingTitleAgent: '',
        closingTitleCompany: '',
        agentlist: undefined,
        closingOffice: undefined,
        office: undefined,
        AgentId: undefined,
        AgentEmail: undefined,
      });
    }
  }, [
    scratchFormValues.closingOffice,
    scratchFormValues.closingTitleCompany,
    scratchFormValues.office,
  ]);

  useEffect(() => {
    if (selectedAgent) {
      setScratchFormValues({ ...scratchFormValues, closingTitleAgent: selectedAgent });
    }
    // quick fix to update displayed closing/title officer if empty
    else if (
      !(Object.keys(scratchFormValues).length === 0 && scratchFormValues.constructor === Object) &&
      scratchFormValues.projectedClosingDate &&
      scratchFormValues.closingTitleCompany
    ) {
      setScratchFormValues({
        ...scratchFormValues,
        closingTitleAgent: `${NOT_APPLICABLE_OPTION.firstName} ${NOT_APPLICABLE_OPTION.lastName}`,
      });
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (
      props.userDetails &&
      isUserClosingCompanyAgent &&
      props.userDetails.offices &&
      showTitleCompanyFields
    ) {
      const userAgentDetails = props.userDetails;
      const closingCompany = props.userDetails.offices[0];

      setScratchFormValues({
        ...scratchFormValues,
        closingTitleCompany: closingCompany.name,
        office: closingCompany,
        closingOffice: closingCompany,
        AgentId: props.userDetails.roleID,
        AgentEmail: props.userDetails.email,
        // TODO: display uses selectedAgent, while values read are from scratchFormValues. Remove selectedAgent state
        // closingTitleAgent: userAgentDetails.firstName + " " + userAgentDetails.lastName,
      });
      changeSelectedAgent(`${userAgentDetails.firstName} ${userAgentDetails.lastName}`);
    } else if (!showTitleCompanyFields) {
      setScratchFormValues({
        ...scratchFormValues,
        closingTitleCompany: '',
        closingTitleAgent: '',
        closingFileNumber: '',
        office: undefined,
        closingOffice: undefined,
        AgentId: undefined,
        AgentEmail: undefined,
      });

      changeSelectedAgent('');
    }
  }, [props.userDetails, showTitleCompanyFields]);

  async function handleOfficeSelect(offices) {
    setShowOfficeSuggestions(false);
    let agentsList = [];
    if (offices[0] && offices[0].id !== '') {
      // getting list of agent as per selected office
      await AgentApi.searchAgents(
        {
          officeId: offices[0].id,
          officeType: offices[0].type,
          activeInactive: 'A',
        },
        {
          tags: { source: 'CardNewOrderClosingInfo#handleOfficeSelect' },
        },
      ).then((res) => {
        agentsList = res.agentsList;
      });
      setScratchFormValues({
        ...scratchFormValues,
        closingTitleCompany: offices[0].name,
        agentlist: agentsList,
        closingOffice: offices[0],
        office: offices[0],
        closingTitleAgent: '',
      });
    }
    setIsDrawerActive(false);

    // Clear the selected agent when the selected office changes
    changeSelectedAgent('');
  }

  /** When adding agent to office, we want to update the agent list to include the new agent, as well as select the agent */
  function addNewAgentToOffice(agent) {
    // Add the agent to the list of agents
    const updatedValues = {
      ...scratchFormValues,
      agentlist: [...scratchFormValues.agentlist, agent],
    };

    // Make the selection (done this way so that we are sync up with the auto-suggest logic)
    selectSuggestion(
      undefined,
      { suggestion: agent },
      changeSelectedAgent,
      setScratchFormValues,
      updatedValues,
    );
  }

  const data = {
    values: scratchFormValues,
    setValues: setScratchFormValues,
    handleSubmit: handleSave,
    setShowTitleCompanyFields,
    setOptionValues,
    setClosingDate,
    setDateCheckboxValue,
    optionValues,
    dateCheckboxValue,
    overrideDefaultToggleCollapsed: true,
  };

  /**
   * runs when user selects a date from the date picker
   * sets the date values
   */
  const onDateChangeHandler = (date) => {
    setClosingDate(date);
    setDateCheckboxValue({ dateUnknown: false });
    setScratchFormValues({
      ...scratchFormValues,
      dateUnknown: false,
      projectedClosingDate: formatDate(date, DateFormat.DEFAULT),
    });

    // manually blur once a date is selected
    onDateBlur({
      target: {
        name: 'projectedClosingDate',
        value: formatDate(date, DateFormat.DEFAULT),
      },
    });
  };

  const onDateBlur = (e) => {
    handleBlur(e);
  };

  useEffect(() => {
    if (dateCheckboxValue.dateUnknown && errors.projectedClosingDate === c.PROJECTED_CLOSING_DATE) {
      setErrors({ ...errors, projectedClosingDate: '' });
    }
  }, [errors]);

  function blurOfficeSelect(event) {
    if (
      !event.relatedTarget ||
      (event.relatedTarget.id !== 'officeSuggestionDropdown' &&
        event.relatedTarget.id !== 'wb_openSearchDrawer')
    ) {
      handleBlur(event);
      setShowOfficeSuggestions(false);
    }
  }

  function handleSearchOffice() {
    setShowOfficeSuggestions(false);
    setIsDrawerActive(true);
  }

  function handleOnFocus() {
    setShowOfficeSuggestions(true);
  }

  function handleClearInfo() {
    if (isUserClosingCompanyAgent) {
      setDateCheckboxValue({
        dateUnknown: false,
      });
    } else {
      setScratchFormValues({
        titleCompany: {
          yes: false,
          no: false,
        },
      });
      setOptionValues({
        yes: false,
        no: false,
      });
    }
  }

  return (
    <>
      <CardNewOrder
        title="Closing Information"
        alertText={props.alertText}
        submitHelperText={props.submitHelperText}
        isEditing={props.isEditing}
        toggleEditing={() => props.setIsEditing(!props.isEditing)}
        isCollapsed={isCollapsed}
        toggleCollapsed={() => {
          setIsCollapsed(!isCollapsed);
          props.setIsEditing(false);
        }}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        onCancel={props.onCancel}
        data={data}
        cancelModal={props.cancelModal}
        isSaveDisabled={props.isSaveDisabled}
        onClear={handleClearInfo}
      >
        {!props.isEditing ? (
          props.isClosingInfoMissing && !savedFormValues ? (
            <div
              className={classNames([
                'sm:flex sm:flex-col sm:flex-1 sm:-my-2',
                props.isSmall && 'sm:-mx-4',
                isCollapsed && 'xs-max:hidden',
              ])}
            >
              <div className="sm:flex w-full sm:py-2">
                <div className="sm:w-1/3 sm:px-4">
                  <Text>{ORDER_VIEW_LABELS.NO_CLOSING_INFORMATION}</Text>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={classNames([
                'sm:flex sm:flex-col sm:flex-1 sm:-my-2',
                props.isSmall && 'sm:-mx-4',
                isCollapsed && 'xs-max:hidden',
              ])}
            >
              <div className="sm:flex w-full sm:py-2">
                <div className="sm:w-1/3 sm:px-4">
                  <Text variant="label">{ORDER_FORM_FIELDS.PROJECTED_CLOSING_DATE}</Text>
                  <Text id="card-new-order-closing-info__projected-closing-date-text">
                    {savedFormValues.projectedClosingDate}
                  </Text>
                </div>

                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <Text variant="label">{ORDER_FORM_FIELDS.CLOSING_COMPANY}</Text>
                  <Text id="card-new-order-closing-info__closing-title-company-text">
                    {savedFormValues.closingTitleCompany}
                  </Text>
                </div>

                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <Text variant="label">{ORDER_FORM_FIELDS.CLOSING_AGENT}</Text>
                  <Text id="card-new-order-closing-info__closing-or-title-agent-text">
                    {savedFormValues.closingTitleAgent}
                  </Text>
                </div>
              </div>

              <div className="sm:flex w-full sm:py-2">
                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <Text variant="label">{ORDER_FORM_FIELDS.CLOSING_FILE}</Text>
                  <Text id="card-new-order-closing-info__closing-file-number-text">
                    {savedFormValues.closingFileNumber}
                  </Text>
                </div>
              </div>
            </div>
          )
        ) : (
          <>
            {props.shouldDisplayBanner && (
              <Panel
                backgroundColor="interactive-50"
                borderColor="gray-300"
                className="mb-4"
                id="closing-banner"
                shadow={true}
              >
                <Text id="closing-banner-text">
                  Closing dates must be within the last 90 days or next 365 days
                </Text>
              </Panel>
            )}
            <div className="flex flex-wrap -mx-4">
              <div className="w-full sm:w-1/2 sm:max-w-64 px-4 py-2">
                <DateTimeInput
                  id="wb_closingDate"
                  name="projectedClosingDate"
                  label={ORDER_FORM_FIELDS.PROJECTED_CLOSING_DATE}
                  required={!!props.agentRepresents}
                  onlyDate={true}
                  datePicker={true}
                  autoComplete="off"
                  selectedDate={closingDate}
                  onDateSelect={onDateChangeHandler}
                  minDate={CLOSING_DATE_RANGE(props.listingCreationDate).min}
                  maxDate={CLOSING_DATE_RANGE(props.listingCreationDate).max}
                  error={errors.projectedClosingDate}
                />
                {props.showUnknownDate && (
                  <div className="mt-2">
                    <CheckboxGroup
                      id="wb_dateUnknownCheck"
                      label="Date Unknown Options"
                      checkboxes={convertOptionsObjToCheckboxGroupOptions(
                        'wb_dateUnknownCheck',
                        dateCheckboxLabel,
                      )}
                      value={convertValuesToCheckboxGroupValue(dateCheckboxValue)}
                      onChange={(items) =>
                        convertCheckboxGroupOnChangeToOnClick(
                          items,
                          dateCheckboxValue,
                          toggleDateCurrentlyUnknown,
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <div className="w-full px-4 py-2 -mb-1">
                <RadioGroup
                  formField={true}
                  formFieldMessageId={getFormRadioErrorId('wb_titleCompanyCheck')}
                  id="wb_titleCompanyCheck"
                  label={ORDER_FORM_FIELDS.CLOSING_COMPANY__QUESTION}
                  required={false}
                  orientation="horizontal"
                  radios={convertOptionsObjToRadioGroupOptions(
                    'wb_titleCompanyCheck',
                    optionLabels,
                  )}
                  value={
                    isUserClosingCompanyAgent
                      ? convertValuesToRadioGroupValue({
                          yes: true,
                          no: false,
                        })
                      : convertValuesToRadioGroupValue(optionValues)
                  }
                  onChange={(selected) =>
                    isUserClosingCompanyAgent
                      ? console.log('do nothing')
                      : convertRadioGroupOnChangeToOnClick(selected, toggleTitleCompanyFields)
                  }
                />
              </div>

              <div ref={titleCompanyFields} className="w-full">
                {showTitleCompanyFields && (
                  <div className="title-company-fields flex flex-wrap w-full mt-1">
                    <div className="w-full sm:w-1/2 px-4 py-2">
                      <div
                        id="card-new-order-closing-info__title-company-fields-overlay"
                        className="relative"
                      >
                        <FormOfficeSelection
                          id="wb_closingTitleCompany"
                          name="closingTitleCompany"
                          label={ORDER_FORM_FIELDS.CLOSING_COMPANY}
                          officeList={getProfileOfficesByType(
                            props.userDetails,
                            OfficeType.ClosingCompany,
                          )}
                          selectedOffice={scratchFormValues.closingTitleCompany}
                          setIsDrawerActive={handleSearchOffice}
                          handleOfficeSelect={handleOfficeSelect}
                          updateOfficeFilter={() => changeSelectedAgent('')}
                          focusOfficeSelect={handleOnFocus}
                          handleBlur={blurOfficeSelect}
                          errors={errors}
                          isDrawerActive={isDrawerActive}
                          showOfficeSuggestions={showOfficeSuggestions}
                          isDisabled={isUserClosingCompanyAgent}
                        />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-4 py-2">
                      <AgentAutosuggest
                        id="wb_closingAgentValue"
                        hasNotApplicableOption={true}
                        typeAheadAddress={suggestedAgents}
                        setTypeAheadAddress={setSuggestedAgents}
                        selectedAgent={selectedAgent}
                        changeSelectedAgent={changeSelectedAgent}
                        agentHelpText={agentHelpText}
                        setAgentHelpText={setAgentHelpText}
                        agentOffice={scratchFormValues.closingTitleCompany}
                        agentlist={scratchFormValues.agentlist}
                        placeholder={ORDER_FORM_FIELDS.CLOSING_AGENT}
                        setValues={setScratchFormValues}
                        values={scratchFormValues}
                        addAgentEnabled={props.userType !== 'ClosingCompanyAgent'}
                        setIsAddAgentModalActive={setIsAddClosingAgentModalActive}
                        isDisabled={!scratchFormValues.office || isUserClosingCompanyAgent}
                        noResultsFoundHelperText="Try searching for a different agent or add a new agent"
                      />
                    </div>
                    <div className="w-full sm:w-1/2 px-4 py-2">
                      <Input
                        formField={true}
                        formFieldMessageId={getFormInputErrorId('wb_closingFile')}
                        id="wb_closingFile"
                        name="closingFileNumber"
                        label={ORDER_FORM_FIELDS.CLOSING_FILE}
                        required={false}
                        value={scratchFormValues.closingFileNumber}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={onChangeInput}
                        maxLength={c.ESCROW_NUMBER_MAX_LENGTH}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </CardNewOrder>
      <DrawerOfficeSearch
        id="card-new-order-closing-info__drawer-office-search"
        usaStates={props.usaStates}
        typeCode="CC"
        userOfficeIDs={props.setUserOfficeIDs}
        excludeBrandSearch={true}
        isActive={isDrawerActive}
        onClose={() => setIsDrawerActive(false)}
        onSubmit={handleOfficeSelect}
      />

      <ModalAddAgentToOffice
        id="modal-add-closing-agent"
        isActive={isAddClosingAgentModalActive}
        office={scratchFormValues.closingOffice}
        agents={scratchFormValues.agentlist}
        onCancel={() => setIsAddClosingAgentModalActive(false)}
        onSuccess={(agent) => {
          addNewAgentToOffice(agent);
          fireGAEvent(ADD_NEW_AGENT('Closing', agent.realEstateAgentID));
          setIsAddClosingAgentModalActive(false);
        }}
      />

      <ModalAgentVerification
        id="modal-agent-verification"
        isActive={isAgentVerificationActive}
        office={scratchFormValues.closingOffice}
        onClose={() => setIsAgentVerificationActive(false)}
        agentRole="closing"
        selectedAgent={selectedAgent}
        agentList={scratchFormValues.agentlist}
        setTypeAheadAddress={setSuggestedAgents}
        setIsAddAgentModalActive={setIsAddClosingAgentModalActive}
        onSelectAgent={(agent) =>
          selectSuggestion(
            undefined,
            { suggestion: agent },
            changeSelectedAgent,
            setScratchFormValues,
            scratchFormValues,
          )
        }
      />
    </>
  );
};

export default CardNewOrderClosingInfo;
