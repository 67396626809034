import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import PlanPriceSaveQuote, {
  PlanPriceSaveQuoteProps,
} from '@components/drawer/subcomponents/PlanPriceSaveQuote';
import { ProductPricingDetailsRequest } from '@apis/models';

export interface DrawerPlanPriceSaveQuoteProps
  extends Required<Pick<DrawerProps, 'isActive' | 'onClose'>>,
    Omit<PlanPriceSaveQuoteProps, 'emailQuoteCall'> {
  onSuccess?: () => void;
  productPricingDetailsRequest: ProductPricingDetailsRequest;
}

const DrawerPlanPriceSaveQuote: React.FC<DrawerPlanPriceSaveQuoteProps> = (props) => {
  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <PlanPriceSaveQuote
          cityOptions={props.cityOptions}
          quote={props.quote}
          productPricingDetailsRequest={props.productPricingDetailsRequest}
          formData={props.formData}
          onClose={props.onClose}
          onSuccess={props.onSuccess}
        />
      )}
    </Drawer>
  );
};

export default DrawerPlanPriceSaveQuote;
