import { Warrantylink } from '@apis/models/warrantylink.api.model';

export default class WarrantylinkContractModel {
  id: string;
  brand: string;
  contractStatus: string;
  initiatingOfficeAgent: {
    office: {
      id: string;
      name: string;
      type: string;
      represents: string;
    };
    agent: {
      realEstateAgentID: string;
      agentType: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  dates: {
    type: string;
    effective: string;
    expiration: string;
  };
  property: {
    ID: number;
    type: string;
    dwellingType: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    zipFour: string;
    streetNumber: string;
    streetDirection: string;
    streetName: string;
    unitType: string;
    unitNumber: string;
    squareFeet: number;
    yearBuilt: string;
    numberMotherLawUnits: number;
    propertyValue: number;
  };
  warrantylink: Warrantylink;
}
