import { BaseAPI } from './_api';
import {
  CategoryData,
  FAQData,
  IZestyResponse,
  MerchandisingContent,
  PlansAndPricesMetaData,
  Promo,
  SalesMaterial,
  ToggleContent,
  ToggleData,
  ZestyBanner,
  ZestyBannerResponse,
  ZestyCategoryResponse,
  ZestyDashboardWhatsNew,
  ZestyDashboardWhatsNewResponse,
  ZestyFAQResponse,
  ZestyLink,
  ZestyLinkResponse,
  ZestyMarketingArticle,
  ZestyMarketingArticlesResponse,
  ZestyPageResponse,
  ZestyPlansAndPrices,
  ZestyPlansAndPricesCoverage,
  ZestyPlansAndPricesCoveragesResponse,
  ZestyPlansAndPricesPlans,
  ZestyPlansAndPricesResponse,
  ZestyPrivacyPolicy,
  ZestyPrivacyPolicyResponse,
  ZestyPromoData,
  ZestyPromoResponse,
  ZestySalesMaterialFull,
  ZestySalesMaterialResponse,
  ZestySplashScreen,
  ZestySplashScreenResponse,
  ZestyTermsOfUse,
  ZestyTermsOfUseResponse,
  ZestyToggleResponse,
  ZestyWelcomeDashboardNotification,
  ZestyWelcomeDashboardNotificationResponse,
} from '@apis/models/zesty.api.model';
import { getConfig } from '@services/app.config.loader';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import { getBrand } from '@helpers/brand.utils';

const baseConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// TODO move these into a const file
const configServiceName = 'zesty';
const configFAQCategories = 'zestyFAQCategories';
const configPartnerPortalPages = `zestyPartnerPortalPages`;
const configFAQs = 'zestyFAQ';
const configZestyAPI = 'zestyAPI';
const configToggles = 'zestyToggles';
const configSalesMaterial = 'zestySalesMaterial';
const configZestyMerchandisePath = 'zestyMerchandisePath';
const configBanner = 'zestyBanner';
const configZestyPlansAndPricesPath = 'zestyPlansAndPricesPath';
const configZestyPlansAndPricesCoveragePath = 'zestyPlansAndPricesCoveragePath';
const configSplashScreen = 'zestySplashScreen';
const configDashboardWhatsNew = 'zestyDashboardWhatsNew';
const configMarketingArticles = 'zestyMarketingArticles';
const configPrivacyPolicy = 'zestyPrivacyPolicy';
const configTermsOfUse = 'zestyTermsOfUse';
const configWelcomeDashboardNotifications = 'zestyWelcomeDashboardNotifications';
const configPromoContent = 'zestyPromoContent';

const useJWT = false;

class API extends BaseAPI {
  constructor() {
    super(configServiceName, useJWT, baseConfig, false);
  }

  public async GetLinks(): Promise<ZestyLink[]> {
    return this.axios
      .get<any, ZestyLinkResponse>(`${this.getHost()}/ajax/ahs/navigation.json`)
      .then((res) => {
        return res.links;
      })
      .catch((e) => {
        console.log(e);
        return [] as ZestyLink[];
      });
  }

  public async GetFAQCategories(): Promise<FAQCategory[]> {
    return this.axios
      .get<any, ZestyCategoryResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configFAQCategories)}`,
      )
      .then((res) => {
        return translateCategoryData(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [] as FAQCategory[];
      });
  }

  public async GetMerchandiseData(): Promise<MerchandisingContent> {
    const zestyMerchandisePath = getConfig(configZestyMerchandisePath);
    return this.axios
      .get<any, any>(`${this.getHost()}${zestyMerchandisePath}`)
      .then((res) => {
        return translateToMerchandiseModel(res);
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  }

  public async GetPartnerPortalPages(): Promise<string[]> {
    return this.axios
      .get<any, ZestyPageResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configPartnerPortalPages)}`,
      )
      .then((res) => {
        return translatePortalPages(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }

  public async GetPromoBanner(): Promise<Promo[]> {
    return this.axios
      .get<any, ZestyPromoResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configPromoContent)}`,
      )
      .then((res) => {
        return translatePromoData(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }

  public async GetFAQs(): Promise<FAQ[]> {
    return this.axios
      .get<any, ZestyFAQResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configFAQs)}`,
      )
      .then((res) => {
        return translateFAQData(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [] as FAQ[];
      });
  }

  public async GetToggles(): Promise<ToggleContent[]> {
    return this.axios
      .get<any, ZestyToggleResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configToggles)}`,
      )
      .then((res) => {
        return translateToggleData(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [] as ToggleContent[];
      });
  }

  public async GetSalesMaterial(): Promise<SalesMaterial[]> {
    return this.axios
      .get<any, ZestySalesMaterialResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configSalesMaterial)}`,
      )
      .then((res) => {
        return translateToSalesMaterialModel(res.data);
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }

  public async GetBannerText(): Promise<ZestyBanner[]> {
    return this.axios
      .get<any, ZestyBannerResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configBanner)}`,
      )
      .then((res) => {
        return translateToBannerModel(res.data);
      })
      .catch((e) => {
        return [];
      });
  }

  public async GetSplashScreen(): Promise<ZestySplashScreen[]> {
    return this.axios
      .get<any, ZestySplashScreenResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configSplashScreen)}`,
      )
      .then((res) => {
        return translateToSplashScreenModel(res.data);
      })
      .catch((e) => {
        console.error('Error occurred when retrieving the zesty splash screen model', e);
        return [];
      });
  }

  public async GetDashboardWhatsNew(): Promise<ZestyDashboardWhatsNew[]> {
    return this.axios
      .get<any, ZestyDashboardWhatsNewResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configDashboardWhatsNew)}`,
      )
      .then((res) => {
        return translateToDashboardWhatsNewModel(res.data);
      })
      .catch((e) => {
        console.error("Error occurred when retrieving the zesty dashboard what's new? model", e);
        return [];
      });
  }

  public async GetPlansAndPrices(): Promise<ZestyPlansAndPrices[]> {
    return this.axios
      .get<
        any,
        ZestyPlansAndPricesResponse
      >(`${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configZestyPlansAndPricesPath)}`)
      .then(ZestyResponseToContentArray);
  }

  public async GetPlansAndPricesCoverages(brand: string): Promise<ZestyPlansAndPricesCoverage[]> {
    const allCoveragesData = this.axios
      .get<
        any,
        ZestyPlansAndPricesCoveragesResponse
      >(`${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configZestyPlansAndPricesCoveragePath)}`)
      .then(ZestyResponseToContentArray);

    // iterate through to find plan and price coverages for the brand
    const planAndPriceCoveragesForBrand = (await allCoveragesData).reduce<
      ZestyPlansAndPricesCoverage[]
    >(function (acc, coverage) {
      if (coverage?.brand?.toUpperCase() === brand.toUpperCase()) {
        acc.push(coverage);
      }
      return acc;
    }, []);

    if (!planAndPriceCoveragesForBrand) {
      console.warn(`no coverages found for brand:${brand}`);
    }

    if (planAndPriceCoveragesForBrand.length > 0) {
      console.debug(
        `A total of ${planAndPriceCoveragesForBrand.length} coverages found for brand ${brand}`,
      );
    }

    return planAndPriceCoveragesForBrand;
  }

  /** returns the marketing articles */
  public async GetMarketingArticles(): Promise<ZestyMarketingArticle[]> {
    return this.axios
      .get<
        any,
        ZestyMarketingArticlesResponse
      >(`${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configMarketingArticles)}`)
      .then(ZestyResponseToContentArray);
  }

  public async GetPrivacyPolicy(): Promise<ZestyPrivacyPolicy> {
    if (!getConfig(configPrivacyPolicy)) {
      console.warn('no privacy policy available. check configuration.');
      return Promise.resolve().then(() => {
        return null;
      });
    }
    return this.axios
      .get<any, ZestyPrivacyPolicyResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configPrivacyPolicy)}`,
      )
      .then(ZestyResponseToContentArray)
      .then((content) => {
        // when needed, use brand or enabled flag to determine which content to return back
        return content?.[0];
      });
  }

  public async GetTermsOfUse(): Promise<ZestyTermsOfUse> {
    if (!getConfig(configTermsOfUse)) {
      console.warn('no terms of use available. check configuration.');
      return Promise.resolve().then(() => {
        return null;
      });
    }
    return this.axios
      .get<any, ZestyTermsOfUseResponse>(
        `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configTermsOfUse)}`,
      )
      .then(ZestyResponseToContentArray)
      .then((termsOfUse) => {
        return termsOfUse.find((a) => a.brand === getBrand());
      });
  }

  /** returns a combined data of plans and prices along with included plans data */
  public async GetPlansAndPricesData(tenant: string): Promise<PlansAndPricesMetaData> {
    const allPageData = await this.GetPlansAndPrices();

    // iterate through to find the enabled page data
    const enabledPageData = allPageData.find(
      (pageData) =>
        pageData.enabled === '1' && pageData.tenant.toUpperCase() === tenant.toUpperCase(),
    );

    if (!enabledPageData) {
      console.warn('no enabled plans and prices data found.');
    }

    console.debug(
      `found page data. contains ${enabledPageData.plans.totalItems} plan items. loading...`,
    );

    const listedPlans = await Promise.all(
      enabledPageData.plans.data.map((plan) =>
        this.GetDataFromResourceURI<ZestyPlansAndPricesPlans>(
          `${this.getHost()}${getConfig(configZestyAPI)}${plan.zuid}.json`,
        ),
      ),
    );
    // remove nulls and reduce to combined array
    const plans = listedPlans.reduce((acc, plan) => (plan ? [...acc, plan[0]] : acc), []);

    return {
      ...enabledPageData,
      plans,
    };
  }

  /** returns the welcome dashboard notifications */
  public async GetWelcomeDashboardNotifications(): Promise<ZestyWelcomeDashboardNotification[]> {
    const url = `${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configWelcomeDashboardNotifications)}`;
    console.log('zesty url', url);
    return this.axios
      .get<
        any,
        ZestyWelcomeDashboardNotificationResponse
      >(`${this.getHost()}${getConfig(configZestyAPI)}${getConfig(configWelcomeDashboardNotifications)}`)
      .then(ZestyResponseToContentArray);
  }

  /** utility function to obtain a mapped one-to-one or one-to-many via the provided resourceURI. */
  private async GetDataFromResourceURI<T>(resourceURI: string): Promise<T[]> {
    return this.axios
      .get<any, IZestyResponse<any, T>>(resourceURI)
      .then(ZestyResponseToContentArray);
  }
}

/** helper function to transform the zesty response to content array */
function ZestyResponseToContentArray<T>(response: IZestyResponse<any, T>): T[] {
  return response.data.map((data) => data.content);
}

function translatePortalPages(data: any): string[] {
  return data.map((item) => {
    return item.content.zuid.data[0].zuid;
  });
}

function translatePromoData(data: ZestyPromoData[]): Promo[] {
  return data.map((item) => {
    return {
      states: item.content.states,
      status: item.content.status,
      startDate: item.content.start_date,
      endDate: item.content.end_date,
      smallBannerImage: item.content.small_banner_image?.data[0]?.url,
      smallBannerText: item.content.small_banner_text,
      displayPages: item.content.display_banner_pages.data.map((item2) => {
        return item2.zuid;
      }),
    };
  });
}

function translateFAQData(data: FAQData[]): FAQ[] {
  return data.map((item) => {
    return {
      sort: Number(item.content.sort),
      question: item.content.question,
      answer: item.content.answer,
      categoryID: item.content.category.data[0].zuid,
      id: item.meta.zuid,
    };
  });
}

function translateToggleData(data: ToggleData[]): ToggleContent[] {
  return data.map((item) => {
    return {
      feature: item.content.feature,
      status: Number(item.content.status),
      branchonly: Number(item.content.branchonly) === 1,
    };
  });
}

function translateCategoryData(data: CategoryData[]): FAQCategory[] {
  return data.map((item) => {
    return {
      sort: Number(item.content.sort),
      name: item.content.name,
      id: item.meta.zuid,
    };
  });
}

function translateToMerchandiseModel(res: any): MerchandisingContent {
  try {
    const data = res.data[0];
    const copyIcon = data.content.copy_icon;
    const navIcon = data.content.navigation_icon;
    return {
      copyText: data.content.copy_text,
      merchandisingUrl: data.content.merchandising_url,
      isActive: data.content.active === '1',
      imageIconUrl: copyIcon ? copyIcon.data[0].url : '',
      navIconUrl: navIcon ? navIcon.data[0].url : '',
      ctaText: data.content.cta_text,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

function translateToSalesMaterialModel(data: ZestySalesMaterialFull[]): SalesMaterial[] {
  return data.map((item) => {
    return {
      title: item.content.title,
      sort: Number(item.content.sort_order),
      image: item.content.image.data[0]?.url,
      media:
        item.content.media?.data?.map((media) => {
          return { url: media.url, zuid: media.zuid };
        }) || [],
      description: item.content.description,
      externalMedia: item.content.external_media,
      brand: item.content.brand,
    };
  });
}

function translateToBannerModel(data: any): ZestyBanner[] {
  return data.map((item) => {
    return {
      AHS: item.content.ahs === '1',
      HSA: item.content.hsa === '1',
      authenticated: item.content.authenticated === '1',
      unauthenticated: item.content.unauthenticated === '1',
      loginpage: item.content.loginpage === '1',
      masterswitch: item.content.masterswitch === '1',
      message: item.content.message,
    };
  });
}

function translateToSplashScreenModel(data: any): ZestySplashScreen[] {
  return data.map((item) => {
    return {
      zuid: item.meta.zuid,
      nickname: item.content.nickname,
      background_image:
        item.content.background_image?.data?.map((media) => {
          return { url: media.url, zuid: media.zuid };
        }) || [],
      headline: item.content.headline,
      body_text: item.content.body_text,
      time_period_begin: new Date(Date.parse(`${item.content.time_period_begin}`)), // add ' CST', for example, to the end to force time zone
      time_period_end: new Date(Date.parse(`${item.content.time_period_end}`)),
      // ahs_enabled: item.content.ahs_enabled === "1",
      // hsa_enabled: item.content.hsa_enabled === "1",
      ahs_enabled: item.content.brand === 'ahs' || item.content.brand === 'both', // workaround since product wants dropdown in zesty
      hsa_enabled: item.content.brand === 'hsa' || item.content.brand === 'both',
      learn_more_behavior: item.content.learn_more_behavior,
      linked_url: item.content.linked_url,
      linked_media:
        item.content.linked_media?.data?.map((media) => {
          return { url: media.url, zuid: media.zuid };
        }) || [],
      states: item.content.states,
    };
  });
}

function translateToDashboardWhatsNewModel(data: any): ZestyDashboardWhatsNew[] {
  return data.map((item) => {
    return {
      sortOrder: Number(item.content.sort_order),
      header: item.content.header,
      body: item.content.body,
      ctaBehavior: item.content.cta_behavior,
      ctaText: item.content.cta_text,
      ctaLinkedMedia:
        item.content.cta_linked_media?.data?.map((media) => {
          return { url: media.url, zuid: media.zuid };
        }) || [],
      ctaLinkedUrl: item.content.cta_linked_url,
      brand: item.content.brand,
    };
  });
}

const ZestyApi = new API();
export default ZestyApi;
