import React, { useCallback } from 'react';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { Button, Text, TextProps } from '@ftdr/blueprint-components-react';
import { removeTags, toCurrency, toPercentage, formatDate } from '@helpers/utils';
import { ResidenceTypeValues, isAgeNewConstruction, getSqftDisplay } from '@services/helpers';
import { canModifySquareFootage } from '@helpers/order.utils';

interface Props {
  /** order data to display on the summary */
  orderData: NewOrderContextData;
  /** the step the user has completed thus far, in order to display sections in the order summary */
  latestStep: NewOrderStep;
  /** the step currently being viewed on page, in order to enable edit CTA for certain sections */
  currentStep: NewOrderStep;
  /** when user clicks to edit a previous step, with the step they wish to edit */
  onClickEditStep: (step: NewOrderStep) => void;
}

const NewOrderSummary: React.FC<Props> = (props) => {
  const displayPropertyInfoSummary = props.latestStep >= NewOrderStep.AgentPropertyInfo;
  const displayPropertyInfoAttributesSummary = props.orderData?.propertyInfo?.squareFootage > 0;
  const displayPlanCoverageSummary = props.latestStep >= NewOrderStep.PlanCoverage;
  const displayBuyerSellerSummary = props.latestStep >= NewOrderStep.BuyerSellerInfo;
  const displayProjectedClosingDateInfoSummary =
    props.orderData?.productFilters?.projectedClosingDate?.getDate() > 0;
  const displayClosingCompanyInfoSummary =
    props.orderData?.closingInfo?.closingAgent.officeName.length > 0;

  const allowEditPropertyInfo = props.currentStep > NewOrderStep.AgentPropertyInfo;
  const allowEditPlanCoverage = props.currentStep > NewOrderStep.PlanCoverage;
  const allowEditBuyerSeller = props.currentStep > NewOrderStep.BuyerSellerInfo;

  const { planInfo } = props.orderData;
  const { pricingResponse } = planInfo;

  const sellersCoverageValue = useCallback(
    () => (
      <>
        {pricingResponse?.pricing.sellersCoverage > 0
          ? toCurrency(pricingResponse?.pricing.sellersCoverage)
          : props.orderData.productFilters.sellersCoverage
            ? 'Yes'
            : 'No'}
      </>
    ),
    [props.orderData.productFilters, pricingResponse],
  );

  const sellersCoveragePerDiemLine = useCallback(
    () => (
      <>
        Fee ({toCurrency(pricingResponse?.details.dailyRate)}/day for{' '}
        {pricingResponse?.details.listingDays} days)
      </>
    ),
    [pricingResponse],
  );

  const taxHelperText = useCallback(
    () =>
      pricingResponse?.details.taxableAmount > 0 &&
      `(${toPercentage(pricingResponse.details.taxRate)} of ${toCurrency(pricingResponse.details.taxableAmount)})`,
    [pricingResponse],
  );

  return (
    <div className="NewOrderSummary">
      <div className="NewOrderSummary_Content">
        {displayPropertyInfoSummary && (
          <>
            <Section
              id="AgentInfo"
              heading="Agent info"
              onClick={() => props.onClickEditStep(NewOrderStep.AgentPropertyInfo)}
              showEdit={allowEditPropertyInfo}
            >
              <LineItem label="Agent name" id="Agent">
                <div id="InitiatingAgentName">{props.orderData.initiatingAgent.agentName}</div>
              </LineItem>
              <LineItem label="Office name" id="Office">
                <div id="InitiatingAgentOffice">{props.orderData.initiatingAgent.officeName}</div>
              </LineItem>
            </Section>
            <Section
              id="PropertyInfo"
              heading="Property info"
              onClick={() => props.onClickEditStep(NewOrderStep.AgentPropertyInfo)}
              showEdit={allowEditPropertyInfo}
            >
              <LineItem label="Address" id="Address">
                <div>
                  <div>
                    {props.orderData.addressInfo.streetAddress}
                    {props.orderData.addressInfo.streetAddress?.length > 0 ? ',' : ''}
                  </div>
                  <div>
                    {props.orderData.addressInfo.unit}
                    {props.orderData.addressInfo.unit.length > 0 ? ',' : ''}
                  </div>
                  <div>
                    {props.orderData.addressInfo.city}
                    {props.orderData.addressInfo?.city?.length > 0 ? ',' : ''}{' '}
                    {props.orderData.addressInfo.state} {props.orderData.addressInfo.zip}
                  </div>
                </div>
              </LineItem>
              {displayPropertyInfoAttributesSummary && (
                <>
                  <LineItem label="Type of residence" id="ResidenceType">
                    {ResidenceTypeValues[props.orderData.propertyInfo.residenceType]}
                  </LineItem>
                  {canModifySquareFootage() && (
                    <LineItem label="Square footage" id="Sqft">
                      {getSqftDisplay(props.orderData.propertyInfo?.squareFootage)}
                    </LineItem>
                  )}
                  <LineItem label="New construction" id="NewConstruction">
                    {isAgeNewConstruction(props.orderData.propertyInfo.age) ? 'Yes' : 'No'}
                  </LineItem>
                </>
              )}
            </Section>

            {(displayProjectedClosingDateInfoSummary || displayClosingCompanyInfoSummary) &&
              props.currentStep.valueOf() >= NewOrderStep.BuyerSellerInfo && (
                <Section
                  id="ClosingInfo"
                  heading="Closing info"
                  onClick={() => props.onClickEditStep(NewOrderStep.PlanCoverage)}
                  showEdit={props.currentStep.valueOf() === NewOrderStep.ReviewSubmit}
                >
                  <LineItem label="Closing/Title company" id="ClosingCompany">
                    <div id="ClosingCompanyName">
                      {props.orderData?.closingInfo?.closingAgent?.officeName}
                    </div>
                  </LineItem>
                  <LineItem label="Project closing date" id="ClosingDate">
                    <div id="ProjectedClosingDate">
                      {formatDate(props.orderData.productFilters?.projectedClosingDate)}
                    </div>
                  </LineItem>
                </Section>
              )}
          </>
        )}
        {displayPlanCoverageSummary && (
          <Section
            id="PlanInfo"
            heading="Plan info"
            onClick={() => props.onClickEditStep(NewOrderStep.PlanCoverage)}
            showEdit={allowEditPlanCoverage}
          >
            {props.orderData.productFilters.term > 0 && (
              <>
                <LineItem id="WarrantyTermLength" label="Warranty term length">
                  {props.orderData.productFilters.term} Year
                </LineItem>
                <LineItem
                  id="SellersCoverageOption"
                  label="Sellers coverage option"
                  hasSublines={true}
                  value={sellersCoverageValue()}
                >
                  {pricingResponse?.details.dailyRate > 0 && (
                    <SubLineItem
                      id="SellersCoveragePerDiem"
                      label=""
                      labelProps={{
                        color: 'gray',
                        className: 'ml-2',
                      }}
                      labelRenderer={sellersCoveragePerDiemLine()}
                    />
                  )}
                </LineItem>
                <LineItem id="ACOption" label="A/C included in all plans">
                  {props.orderData.productFilters.acCoverage ? 'Yes' : 'No'}
                </LineItem>
              </>
            )}
            {planInfo.selectedPlanName && (
              <>
                <LineItem label="Plan Selected" hasSublines={true} id="PlanSelection">
                  <SubLineItem
                    label=""
                    labelRenderer={
                      <>
                        {planInfo.selectedPlanName}
                        <sup>SM</sup>
                      </>
                    }
                    id="Plan"
                  >
                    {toCurrency(planInfo.selectedPlanPrice)}/yr
                  </SubLineItem>
                </LineItem>
              </>
            )}
            {(planInfo.selectedOptionalCoverages.length > 0 ||
              planInfo.selectedGroupCoverages.length > 0) && (
              <LineItem label="Add-Ons" hasSublines={true} id="AddOns">
                {[...planInfo.selectedOptionalCoverages, ...planInfo.selectedGroupCoverages].map(
                  (cvg) => (
                    <SubLineItem key={cvg.id} label={removeTags(cvg.name)} id={cvg.id}>
                      {toCurrency(cvg.price)}
                    </SubLineItem>
                  ),
                )}
              </LineItem>
            )}

            {pricingResponse && (
              <LineItem label="Tax" id="Tax" labelHelperText={taxHelperText()}>
                <b>{toCurrency(pricingResponse.pricing.tax)}</b>
              </LineItem>
            )}
          </Section>
        )}
      </div>
      {displayPlanCoverageSummary && pricingResponse && (
        <div className="NewOrderSummary_Footer">
          <Text id="TotalDue.Heading" variant="heading-05">
            Total
          </Text>
          <Text id="TotalDue.Value" variant="heading-05">
            {toCurrency(pricingResponse.pricing.totalDueAtClosing)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default NewOrderSummary;

const Section: React.FC<{ id: string; heading: string; showEdit: boolean; onClick: () => void }> = (
  props,
) => {
  const appendID = (appendText: string) => props.id && `NewOrderSummary.${props.id}.${appendText}`;
  return (
    <div id={props.id} className="NewOrderSummary_Section">
      <div className="NewOrderSummary_Section_Heading">
        <Text id={appendID('HeadingText')} variant="heading-05">
          {props.heading}
        </Text>
        {props.showEdit && (
          <Button
            id={appendID('Edit')}
            label="Edit"
            size="small"
            variant="ghost"
            onClick={() => props.onClick()}
          />
        )}
      </div>
      {props.children}
    </div>
  );
};

const LineItem: React.FC<{
  id: string;
  label: string;
  hasSublines?: boolean;
  value?: JSX.Element;
  labelHelperText?: string;
}> = (props) => {
  const appendID = (appendText: string) =>
    props.id && `NewOrderSummary.LineItem.${props.id}.${appendText}`;
  return (
    <div className="NewOrderSummary_LineItem">
      <Text id={appendID('Label')} variant="label" className="NewOrderSummary_LineItem_Label">
        {props.label}:
        {props.labelHelperText && (
          <Text
            id={appendID('Helper')}
            variant="helper-text"
            color="gray"
            className="NewOrderSummary_LineItem_Label_Helper"
          >
            {props.labelHelperText}
          </Text>
        )}
      </Text>
      {((props.hasSublines && props.value) || !props.hasSublines) && (
        <Text id={appendID('Value')} variant="caption">
          {props.value || props.children}
        </Text>
      )}
      {props.hasSublines && props.children}
    </div>
  );
};

/** use labelRenderer if custom label formatting is needed. Otherwise use label. */
const SubLineItem: React.FC<{
  id: string;
  label: string;
  boldLabel?: boolean;
  labelRenderer?: JSX.Element;
  labelProps?: Omit<TextProps<string>, 'id' | 'variant'>;
}> = (props) => {
  const appendID = (appendText: string) =>
    props.id && `NewOrderSummary.SubLineItem.${props.id}.${appendText}`;
  return (
    <div className="NewOrderSummary_SubLineItem">
      <Text
        {...props.labelProps}
        id={appendID('Label')}
        variant={props.boldLabel ? 'label' : 'caption'}
      >
        {props.labelRenderer ? props.labelRenderer : props.label}
      </Text>
      <Text id={appendID('Value')} variant="label">
        {props.children}
      </Text>
    </div>
  );
};
