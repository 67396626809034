import { Brand } from '@constants/brands';
import { getConfig } from '@services/app.config.loader';

/** Methods to determine brand used by getBrand().
 * For the most part, we shouldn't be switching back and forth and instead use a default.
 */
export enum GetBrandStrategy {
  /** Least reliable, but functionally proven to work in legacy site */
  LocationHostURL,
  /** Always return 'AHS', should only be used for development purposes */
  ForceAHS,
  /** Always return 'HSA', should only be used for development purposes */
  ForceHSA,
  /** Choose based on the client-configuration */
  ClientConfig,
}

/**
 * Gets brand of current running session. No parameter needs to be passed in unless absolutely necessary.
 * For development, if you need to test the entire site in a different brand quickly, change the default value
 * of the parameter to the ForceAHS or ForceHSA for testing.
 * TODO: GetBrandStrategy.ClientConfig cannot be used directly yet because there are constants that are ran on runtime before client-configuration comes in. Clean this part eventually.
 */
export function getBrand(method: GetBrandStrategy = GetBrandStrategy.LocationHostURL): string {
  switch (method) {
    case GetBrandStrategy.LocationHostURL:
      return getBrandByLocationHref();
    case GetBrandStrategy.ClientConfig:
      return getBrandByClientConfig();
    case GetBrandStrategy.ForceAHS:
      console.warn('forcing brand to be AHS');
      return Brand.AHS;
    case GetBrandStrategy.ForceHSA:
      console.warn('forcing brand to be HSA');
      return Brand.HSA;
    default:
      console.warn('unknown method type: ', method);
      return undefined;
  }
}

function getBrandByLocationHref(): string {
  const { hostname } = window.location;
  if (hostname.indexOf('own.onlinehsa.com') >= 0 || hostname.indexOf('hsa-b2b.') >= 0) {
    return Brand.HSA;
  }
  if (hostname.indexOf('pro.ahs.com') >= 0 || hostname.indexOf('ahs-b2b.') >= 0) {
    return Brand.AHS;
  }
  // Default (normally on localhost)
  const defaultBrand = Brand.AHS;
  return defaultBrand;
}

function getBrandByClientConfig(): string {
  return getConfig('brand');
}
