import React from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';
import CancelOrder from '@components/modal/subcomponents/CancelOrder';

interface Props extends ModalProps {
  message?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  isButtonDisabled?: boolean;
}

const ModalCancelOrder: React.FC<Props> = (props) => {
  const actions = [
    <Button
      key="submit"
      id="modal-cancel-order__cancel-order-btn"
      label={props.confirmButtonLabel}
      onClick={() => props.onConfirm()}
      disabled={props.isButtonDisabled}
      width="full"
      labelAlign="center"
      size="medium"
    />,
    <Button
      key="cancel"
      id="modal-cancel-order__nevermind-btn"
      variant="ghost"
      label={props.cancelButtonLabel}
      onClick={() => props.onClose()}
      disabled={props.isButtonDisabled}
      width="full"
      labelAlign="center"
      size="medium"
    />,
  ];

  return (
    <Modal
      id={props.id}
      isActive={props.isActive}
      onClose={props.onClose}
      heading={props.heading}
      actions={actions}
    >
      {props.isActive && <CancelOrder {...props} />}
    </Modal>
  );
};

ModalCancelOrder.defaultProps = {
  heading: 'Are you sure you want to cancel this order?',
  message: 'This action cannot be reversed, all data will be lost.',
  cancelButtonLabel: 'Close',
  confirmButtonLabel: 'Cancel Order',
  onConfirm: () => {
    console.warn('Not implemented');
  },
};

export default ModalCancelOrder;
