import React, { useMemo, useState } from 'react';
import Modal from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';
import InvalidClosingDate, {
  InvalidClosingDateProps,
} from '@components/modal/subcomponents/InvalidClosingDate';
import { isClosingDateWithinRange } from '@components/card/CardNewOrderClosingInfo';

export interface ModalInvalidClosingDateProps
  extends Pick<InvalidClosingDateProps, 'id' | 'existingClosingDate' | 'listingCreationDate'> {
  isActive: boolean;
  onSubmitClosingDateUpdate: (closingDate: Date) => void;
}

const ModalInvalidClosingDate: React.FC<ModalInvalidClosingDateProps> = (props) => {
  const [selectedClosingDate, setSelectedClosingDate] = useState<Date>(null);

  const disableSubmit: boolean = useMemo(
    () =>
      !selectedClosingDate ||
      !isClosingDateWithinRange(selectedClosingDate, props.listingCreationDate),
    [selectedClosingDate, props.listingCreationDate],
  );

  const getActions = () => {
    const actions: JSX.Element[] = [
      <Button
        key="submit"
        id="save-closing-date"
        label="Update Closing Date"
        onClick={() => props.onSubmitClosingDateUpdate(selectedClosingDate)}
        width="full"
        labelAlign="center"
        size="medium"
        disabled={disableSubmit}
      />,
    ];
    return actions;
  };

  return (
    <Modal
      isActive={props.isActive}
      heading="Order Closing Date Needs to Be Valid"
      actions={getActions()}
      closeOnOutsideClick={false}
      canClose={false}
      hideClose={true}
      className="w-full"
    >
      {props.isActive && (
        <InvalidClosingDate
          id={props.id}
          existingClosingDate={props.existingClosingDate}
          selectedClosingDate={selectedClosingDate}
          onSelectClosingDate={(date) => setSelectedClosingDate(date)}
        />
      )}
    </Modal>
  );
};

ModalInvalidClosingDate.defaultProps = {
  id: 'modal-invalid-closing-date',
};

export default ModalInvalidClosingDate;
