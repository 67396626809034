import React from 'react';
import ContactUsAuthSuccess from './ContactUsAuthSuccess';
import ContactUsNoAuthSuccess from './ContactUsNoAuthSuccess';

interface IContactUsSuccessProps {
  isAuthed?: boolean;
}

const ContactUsSuccess = (props: IContactUsSuccessProps) => {
  return props.isAuthed ? <ContactUsAuthSuccess /> : <ContactUsNoAuthSuccess />;
};

export default ContactUsSuccess;
