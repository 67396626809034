import { Tab, Tabs } from '@ftdr/blueprint-components-react';
import React, { useEffect, useState } from 'react';

export enum OrdersActionSearchType {
  Submitted,
  NotSubmitted,
}

interface Props {
  id: string;
  searchType?: OrdersActionSearchType;
  defaultSearchType?: OrdersActionSearchType;
  onChangeSearchType?: (searchType: OrdersActionSearchType) => void;
  contentRenderer?: (activeSearchType: OrdersActionSearchType) => JSX.Element;
}

/** component allows switching between the available actions for the myOrders page. */
const OrdersActionSwitcher: React.FC<Props> = (props) => {
  const tabItems = ['Submitted', 'Not Submitted'];
  const [searchType, setSearchType] = useState<OrdersActionSearchType>(
    props.searchType || props.defaultSearchType,
  );

  useEffect(() => {
    setSearchType(props.searchType || props.defaultSearchType);
  }, [props.searchType]);

  const updateSearchType = (newSearchType: OrdersActionSearchType) => {
    setSearchType(newSearchType);
    props.onChangeSearchType?.(newSearchType);
  };

  return (
    <>
      <div className="mt-3">
        <Tabs
          variant="horizontal"
          activeIndex={searchType}
          onActivate={(_, index) => {
            updateSearchType(index);
          }}
        >
          {tabItems.map((label) => (
            <Tab key={label} label={label}>
              {}
            </Tab>
          ))}
        </Tabs>
      </div>
    </>
  );
};

OrdersActionSwitcher.defaultProps = {
  defaultSearchType: OrdersActionSearchType.Submitted,
};

export default OrdersActionSwitcher;
