import React, { useEffect, useState } from 'react';
import { useThemeContext } from '@ThemeContext';
import { classNames } from '@utils';

// Components
import Skeleton from 'react-loading-skeleton';
import AccordionItemWlkContract from './AccordionItemWlkContract';
import { Badge, Checkbox, Panel, Text } from '@ftdr/blueprint-components-react';
import { upperCase } from 'lodash';
import { WarrantylinkStatus } from '@constants/dashboardFilters';

const AccordionItemWarranty = (props) => {
  const theme = useThemeContext();
  const [isChecked, setIsChecked] = useState(isContractSelected);
  const [tagColor, setTagColor] = useState('gray');

  const submittableStatuses = [WarrantylinkStatus.READY, WarrantylinkStatus.SAVED];

  useEffect(() => {
    if (props.status) setTagColor(getStatusColor());
  }, [props.status]);

  useEffect(() => {
    if (!props.isActive && !props.skeleton) {
      setEdit(false);
    }
  }, [props.isActive]);

  /** Update the checkbox if selection was changed */
  useEffect(() => {
    setIsChecked(isContractSelected);
  }, [props.filteredList, props.selectedContracts]);

  function handleCheckboxClick(e) {
    setIsChecked(!isChecked);
    props.onCheckboxClick(props.contract);
  }

  async function handleSave(buyer, seller) {
    const success = await props.onSaveForm({
      id: props.contractId,
      warrantylink: {
        form: { buyer, seller },
      },
    });

    if (success) {
      setEdit(false);
    }
    return success;
  }

  function isContractSelected() {
    return props.selectedContracts && props.selectedContracts.includes(props.contractId.toString());
  }

  function isEditable() {
    return (props.status === 'Ready' || props.status === 'Saved') && props.isActive;
  }

  function setEdit(editState) {
    props.toggleEdit(props.contractId, editState);
  }

  function getStatusColor() {
    switch (props.status) {
      case 'Ready':
        return 'success';
      case 'Saved':
        return 'success';
      case 'Submitted':
        return 'primary';
      case 'Pending':
        return 'primary';
      case 'Complete':
        return 'gray';
      default:
        return 'gray';
    }
  }

  const getSubmissionHeading = () => {
    if (
      [
        WarrantylinkStatus.SUBMITTED,
        WarrantylinkStatus.COMPLETED,
        WarrantylinkStatus.PENDING,
      ].includes(props.status)
    ) {
      return 'SUBMITTED DATE';
    }

    return 'SUBMISSION DEADLINE';
  };

  const getSubmissionValue = () => {
    if (
      [
        WarrantylinkStatus.SUBMITTED,
        WarrantylinkStatus.COMPLETED,
        WarrantylinkStatus.PENDING,
      ].includes(props.status)
    ) {
      return props.submissionDate;
    }

    return props.submissionDeadline;
  };

  return (
    <Panel
      className={classNames([
        'accordion-item rounded bg-white shadow-1 border-2 mb-4',
        isChecked
          ? theme === 'hsa'
            ? 'border-primary-400'
            : 'border-secondary-400'
          : 'border-transparent',
      ])}
      border="1"
      borderColor="gray-300"
      rounded="sm"
      padding="none"
      shadow="true"
    >
      <div
        className="accordion-item__heading flex justify-start items-center relative w-full py-3 pr-16 md:pr-20 pl-4 md:pl-6 text-left text-sm md:text-base bg-transparent cursor-pointer"
        onClick={props.skeleton ? undefined : props.onClick}
        aria-expanded={props.isActive}
        id={props.id}
      >
        <div className="flex flex-row w-full">
          <div className="flex">
            <div className={submittableStatuses.includes(props.status) ? 'flex' : 'flex ml-7'}>
              {props.skeleton ? (
                <Skeleton />
              ) : (
                props.canEditWLKContracts && (
                  <Checkbox
                    className={submittableStatuses.includes(props.status) ? 'flex' : 'hidden'}
                    value={isChecked || false}
                    checked={isChecked || false}
                    onClick={props.skeleton ? () => {} : handleCheckboxClick}
                  />
                )
              )}
            </div>
          </div>

          <div className="flex flex-col w-full pl-6">
            <div className="flex mb-1">
              {props.skeleton ? (
                <Skeleton />
              ) : (
                <Badge color={tagColor} size="small">
                  {props.status}
                </Badge>
              )}
            </div>

            <div className="flex sm:flex-row sm-max:flex-col">
              <div className="flex flex-col w-full m:w-1/3 mr-2">
                <Text variant="label" color="gray">
                  PROPERTY ADDRESS
                </Text>
                {props.skeleton ? (
                  <Skeleton width={240} />
                ) : (
                  <Text variant="label" color="primary">
                    {props.address}
                  </Text>
                )}
              </div>
              <div className="flex flex-col w-full m:w-1/4 mr-2">
                <Text variant="label" color="gray">
                  OFFICE
                </Text>
                {props.skeleton ? (
                  <Skeleton width={240} />
                ) : (
                  <Text variant="label" color="primary">
                    {props.office}
                  </Text>
                )}
              </div>
              <div className="flex flex-col w-full m:w-1/4 mr-2">
                <Text variant="label" color="gray">
                  AGENT
                </Text>
                {props.skeleton ? (
                  <Skeleton width={240} />
                ) : (
                  <Text variant="label" color="primary">
                    {props.agent}
                  </Text>
                )}
              </div>
              <div className="flex flex-col w-full m:w-1/4 mr-2">
                <Text variant="label" color="gray">
                  CONTRACT #
                </Text>
                {props.skeleton ? (
                  <Skeleton width={240} />
                ) : (
                  <Text variant="label" color="primary">
                    #{props.contractNumber}
                  </Text>
                )}
              </div>
              <div className="flex flex-col w-full m:w-1/4">
                <Text variant="label" color="gray">
                  {getSubmissionHeading()}
                </Text>
                {props.skeleton ? (
                  <Skeleton width={240} />
                ) : (
                  <Text variant="label" color="primary">
                    {getSubmissionValue()}
                  </Text>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccordionItemWlkContract
        isActive={props.isActive}
        isDisabled={!isEditable()}
        isCollapsed={props.isCollapsed}
        seller={props.seller}
        buyer={props.buyer}
        contractNumber={props.contractNumber}
        handleSave={handleSave}
        updateErrors={props.updateErrors}
        contract={props.contract}
        onSaveAndCancel={props.onClick}
        isMobile={props.isMobile}
        className="py-2 md:py-4 pr-16 md:pr-20 pl-12 md:pl-16 sm-max:p-6"
        canEditWLKContracts={props.canEditWLKContracts}
      />
    </Panel>
  );
};

export default AccordionItemWarranty;
