import { BaseAPI } from '@apis/_api';
import { getConfig } from '@services/app.config.loader';
import { IdentityProviderTenantID } from '@app/auth/authService';
import { AxiosError } from 'axios';

class API extends BaseAPI {
  constructor() {
    super(
      'IdentityProviderBaseUrl',
      true,
      { headers: { 'Content-Type': 'application/json' } },
      false,
    );
  }

  reSendEmail(email: string) {
    return this.axios
      .put(`${this.getHost()}/api/user/verify-email?email=${email}`, null, {
        headers: {
          'X-FusionAuth-TenantId': getConfig(IdentityProviderTenantID),
        },
      })
      .then(() => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.error('Error occurred when resending registration email = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }
}

const FusionAuthApi = new API();
export default FusionAuthApi;
