import React, { useContext, useEffect, useState } from 'react';
import UpdateBuyerInfo from '@components/drawer/subcomponents/UpdateBuyerInfo';
import ContractApi from '@apis/contract.api';
import contractApiService from '@apis/contract.api';
import { Panel } from '@ftdr/blueprint-components-react';
import { formatDateToISO8601 } from '@helpers/utils';
import { getBuyerInfoForAPICall, getSellerInfoForAPICall } from '@components/drawer/DrawerOrder';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_ACTION_UPDATE_COE,
  DASHBOARD_SEND_DOCUMENTS_IN_MISSING_COE,
} from '@constants/ga-events.constants';
import SendDocuments, {
  GetLatestRecipients,
  Recipient,
  Type as DocumentType,
} from '@components/SendDocuments';
import { getUniqueDocumentTypes, OrderFlowType } from '@helpers/order.utils';
import ProfileContext from '@context/ProfileContext';
import { ContractModelVariant } from '@app/models/contract.model';
import { RecipientType } from '@app/generators/recipientGenerator';
import ValidateApi from '@apis/validate.api';

const AccordionItemClosingInfo = (props) => {
  const [isContractFetched, setIsContractFetched] = useState(false);
  const [contract, setContract] = useState(undefined);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [triggerOnSave, setTriggerOnSave] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [canSave, setCanSave] = useState(false);
  const [closingDate, setClosingDate] = useState<string>('');

  const handleSave = async (contractData): Promise<boolean> => {
    // asynchronously call validate for buyer email/phone
    if (contractData?.buyerInfo?.email || contractData?.buyerInfo?.phone) {
      ValidateApi.validateEmailPhone({
        email: contractData.buyerInfo.email,
        phone: contractData.buyerInfo.phone,
        flow: OrderFlowType.DashboardMissingCOE,
      });
    }

    upsertSellerAndBuyerInfo(contractData.sellerInfo, contractData.buyerInfo)
      .then(() => {
        updateClosingInformation(contractData)
          .then(() => {
            fireGAEvent(DASHBOARD_ACTION_UPDATE_COE);
            makeEmailDocumentsCall(recipients)
              .then(() => {
                // Track in GA send documents when missing COE is updated
                const uniqueDocTypes = getUniqueDocumentTypes(recipients);
                if (uniqueDocTypes?.length > 0) {
                  fireGAEvent(DASHBOARD_SEND_DOCUMENTS_IN_MISSING_COE(uniqueDocTypes));
                }

                props.onCompleteItem(contract.detail.id, true);
              })
              .catch((err) => {
                console.error('failed to send email closing information in joint call', err);
                // still successful but just email failed
                props.onCompleteItem(contract.detail.id, true);
              });
          })
          .catch((err) => {
            console.error('failed to update closing information in joint call', err);
            props.onCompleteItem(contract.detail.id, false);
            return false;
          });
      })
      .catch((err) => {
        console.error('failed to update buyer information in joint call', err);
        props.onCompleteItem(contract.detail.id, false);
        return false;
      });

    return true;
  };

  const updateClosingInformation = (contractData): Promise<any> => {
    setClosingDate(contractData.closingInfo.projectedClosingDate);
    return ContractApi.updateContractClosingAndMLSNumber(contractData.id, {
      mls_number: contractData.propertyDetails?.mlsNumber,
      agent_id: contractData.closingInfo.AgentId,
      date: contractData.closingInfo.projectedClosingDate
        ? formatDateToISO8601(new Date(contractData.closingInfo.projectedClosingDate))
        : undefined,
      office_file_number: contractData.closingInfo.closingFileNumber,
      office_id: contractData.closingInfo.closingOffice?.id,
    });
  };

  const upsertBuyerInfo = (buyerInfo): Promise<any> => {
    return upsertCustomerInfo([...getBuyerInfoForAPICall(buyerInfo)]);
  };

  const upsertSellerAndBuyerInfo = (sellerInfo, buyerInfo): Promise<any> => {
    return upsertCustomerInfo([
      ...getBuyerInfoForAPICall(buyerInfo),
      ...getSellerInfoForAPICall(sellerInfo),
    ]);
  };

  const makeEmailDocumentsCall = (recipients: any): Promise<any> => {
    const docEmails = recipients
      .filter((recipient) => recipient.selectedTypes.length > 0)
      .map(({ email, selectedTypes }) => {
        return { email, docs: selectedTypes };
      });
    const request = {
      docEmails,
    };
    return contractApiService.emailContractDocuments(request, Number(contract?.summary?.id));
  };

  const onEmailChange = (text: string) => {
    const buyerRecipient = recipients.filter((e) => e.type === 'Buyer');
    if (buyerRecipient.length > 0) {
      const emailRecipients = [...recipients];
      const buyerIndex = emailRecipients.findIndex((recipient) => recipient.type === 'Buyer');
      if (text === '') {
        emailRecipients.splice(buyerIndex, 1);
      } else {
        emailRecipients[buyerIndex].email = text;
      }
      setRecipients(emailRecipients);
    } else {
      const emailRecipients: Recipient[] = [
        ...recipients,
        {
          name: buyerRecipient[0]?.name,
          email: text,
          type: RecipientType.Buyer,
          selectedTypes: [],
        },
      ];
      setRecipients(emailRecipients);
    }
  };

  const upsertCustomerInfo = (customerInfo): Promise<any> => {
    return ContractApi.upsertCustomerInfo(contract.detail.id, { customers: customerInfo });
  };

  useEffect(() => {
    if (!isContractFetched) {
      ContractApi.getContractDetails([props.contract.id], true, true)
        .then((fetchedContracts) => {
          if (fetchedContracts[0]) {
            setContract(fetchedContracts[0]);
            setRecipients(
              GetLatestRecipients(
                fetchedContracts[0],
                ContractModelVariant.API,
                recipients,
                profile.email,
                `${profile.firstName} ${profile.lastName}`,
              ),
            );
          }
        })
        .finally(() => setIsContractFetched(true));
    }
  }, []);

  return (
    <Panel
      border="1"
      borderColor="gray-300"
      rounded="sm"
      padding="none"
      shadow={true}
      className="rounded bg-white shadow-1 border-2 mb-4 -mt-4"
    >
      <div className={isContractFetched ? 'pl-6 pr-6 pb-6 pt-3' : 'hidden'}>
        {isContractFetched && (
          <div>
            <UpdateBuyerInfo
              contract={contract}
              onClose={props.onCancel}
              onSave={handleSave}
              showTopSection={false}
              showClosingInfoFirst={true}
              showCancelButton={false}
              saveButtonText="Save & Continue"
              // this updates recipients
              onEmailChange={(email) => onEmailChange(email)}
              // probably a better way of doing this ¯\_(ツ)_/¯ TODO: refactor trigger to be handled by parent (ie. this component)
              triggerOnSave={triggerOnSave}
              setTriggerOnSave={setTriggerOnSave}
              setCanSave={setCanSave}
              setClosingDate={setClosingDate}
            />
            <SendDocuments
              recipients={recipients}
              allowedTypes={[DocumentType.Confirmation, DocumentType.Invoice]}
              onChange={setRecipients}
              onSave={() => setTriggerOnSave(true)}
              onCancel={props.onCancel}
              disabled={!canSave}
              newOrderData={contract}
              newOrderClosingDate={closingDate}
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default AccordionItemClosingInfo;
