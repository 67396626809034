import React from 'react';
import { Address } from '@app/models';
import { addressObjectToString, denormalizeStreetAddress2 } from '@services/helpers';

interface AddressDisplayProps {
  address: Address;
  singleLine?: boolean;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ address, ...props }) => {
  const streetAddress2 = address ? denormalizeStreetAddress2(address.unitType, address.unit) : '';

  if (!address || !address.streetAddress) {
    return null;
  }

  return props.singleLine ? (
    <div>
      {addressObjectToString({
        address1: address.streetAddress,
        address2: streetAddress2,
        city: address.city,
        state: address.state,
        zip: address.zip,
      })}
    </div>
  ) : (
    <>
      <div>
        {address.streetAddress}
        {streetAddress2 ? `, ${streetAddress2}` : ''}
      </div>
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
    </>
  );
};

export default AddressDisplay;
