enum Path {
  HomePage = '/',
  Account = '/account',
  Dashboard = '/dashboard',
  MyOrders = '/myOrders',
  MyOrdersDetails = '/myOrders/:contractId',
  MyOffices = '/offices',
  NewOrder = '/newOrder',
  ConvertNewOrder = '/newOrder/:orderID',
  NewOrderSubmitted = '/orderSubmitted',
  RegistrationAccount = '/registration/account',
  RegistrationExistingUser = '/registration/existingUser',
  Warrantylink = '/warranty-link',
  WarrantylinkAgreements = '/warranty-link/agreements',
  WarrantylinkPayments = '/warranty-link/payments',
  Callback = '/oauth2/callback',
  SSORedirect = '/sso',
  VerifyEmail = '/verifyEmail',
  ProfileError = '/profile/error',
  SiteAdmin = '/admin',
  FAQ = '/faq',
  ContactUs = '/contactUs',
  ContactUsSuccess = '/contactUs/success',
  PlansAndPrices = '/plansAndPrices',
  SalesMaterial = '/salesMaterial',
  NewOrderStep1 = '/newOrder/agent-property',
  NewOrderStep2 = '/newOrder/plans-coverage',
  NewOrderStep3 = '/newOrder/buyer-seller',
  NewOrderStep4 = '/newOrder/review-submit',
  NewOrderComplete = '/newOrder/submitted',
  MyQuote = '/myQuotes',
}

export default Path;
