import SalesMaterialTemplate from '@templates/misc/SalesMaterialTemplate';
import React from 'react';
import Layout from '@components/layout/Layout';
import { ContentBox } from '@components/layout/ContentBox';

export const SalesMaterial = () => {
  return (
    <Layout isLoggedIn={true} slug="sales-material">
      <ContentBox title="Sales Material">
        <SalesMaterialTemplate />
      </ContentBox>
    </Layout>
  );
};
