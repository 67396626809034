import React, { useEffect, useState } from 'react';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderOfficeAgentData } from '@app/models/order/order.model';
import { Office } from '@apis/models';
import { Office as AppOffice, OfficeType } from '@app/models';
import OrderForm from '@components/orders/forms/OrderForm';
import InitiatingOfficeInput from '@components/input/InitiatingOfficeInput';
import InitiatingAgentInput from '@components/input/InitiatingAgentInput';
import { Text } from '@ftdr/blueprint-components-react';

export enum OrderFormOfficeAgentField {
  officeInput = 'officeInput',
  agentInput = 'agentInput',
}

interface OrderFormOfficeAgentProps
  extends OrderFormProps<OrderOfficeAgentData, OrderFormOfficeAgentField> {
  /** ids for the input fields */
  ids: Record<OrderFormOfficeAgentField, string>;
  /** when disableOfficeAgentSelection, use these ids instead for display */
  idNoDropdowns: Record<OrderFormOfficeAgentField, string>;
  /** the input field labels */
  labels: Record<OrderFormOfficeAgentField, string>;
  /** if true, office and agent cannot be selected, displays based on profile (e.g. agent user)
   * TODO: not implemented. we'd want to handle it after learning the UX for it */
  disableOfficeAgentSelection?: boolean;
  /** if true, office suggestions are not provided, would launch the order drawer immediately (e.g. cooperating)
   * TODO: not implemented. we may want to have cooperating office/agent allow selection, it would be easier */
  disableOfficeSuggestions?: boolean;
  /** the offices to suggest to the user. */
  officeSuggestions?: AppOffice[];

  heading?: string;
  hideHeading?: boolean;

  isOfficeRequired?: boolean;
  isAgentRequired?: boolean;
}

/** TODO: this component is incomplete. it isn't handling clear logic properly yet.
 *   In addition, the step template needs logic to save the office into user profile on save of step.
 *   In addition, should handle add agent to office logic.
 *   Should see if we can switch over to the BDS Select Autocomplete implementation instead for Agent. */
const OrderFormOfficeAgent: React.FC<OrderFormOfficeAgentProps> = (props) => {
  const [selectedOffice, setSelectedOffice] = useState<Office>(null);
  const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false);
  const [agentSuggestions, setAgentSuggestions] = useState<any[]>([]);

  const onOfficeSelect = (offices: Office[]) => {
    setShowOfficeSuggestions(false);

    const office = offices[0];
    setSelectedOffice(office);
    setAgentSuggestions([]);

    props.onUpdateFormData({
      ...props.formData,
      officeID: office.id,
      officeName: office.name,
      agentID: '',
      agentName: '',
      agentEmail: '',
      franchiseCode: office.franchiseCode,
    });
    props.clearFieldError(OrderFormOfficeAgentField.officeInput);
  };

  const onAgentSelect = (values: any) => {
    if (values.initAgentId && values.AgentName) {
      props.onUpdateFormData({
        ...props.formData,
        agentID: values.initAgentId,
        agentName: values.AgentName,
        agentEmail: values.AgentEmail,
      });
    }
    props.clearFieldError(OrderFormOfficeAgentField.agentInput);
  };

  const onClearOfficeSelection = () => {
    setSelectedOffice(null);
    props.onUpdateFormData({
      ...props.formData,
      officeID: '',
      officeName: '',
      agentID: '',
      agentName: '',
      agentEmail: '',
      franchiseCode: '',
    });
  };

  useEffect(() => {
    if (props.formData.officeID === '') {
      onClearOfficeSelection();
    }
  }, [props.formData.officeID]);

  return (
    <OrderForm.Container id="office-agent-order-form" heading={!props.hideHeading && props.heading}>
      <OrderForm.Row>
        <OrderForm.RowItem>
          <InitiatingOfficeInput // mostly untyped
            id={props.ids.officeInput}
            required={props.isOfficeRequired}
            name="office"
            label={props.labels.officeInput}
            officeList={props.officeSuggestions}
            selectedOffice={selectedOffice}
            handleOfficeSelect={(office) => onOfficeSelect(office)}
            updateOfficeFilter={() => {}} // do nothing with this
            focusOfficeSelect={() => setShowOfficeSuggestions(true)}
            errors={props.errors.officeInput}
            onOfficeError={() =>
              props.onValidateField(
                OrderFormOfficeAgentField.officeInput,
                props.formData.officeName,
              )
            }
            showOfficeSuggestions={showOfficeSuggestions}
            setShowOfficeSuggestions={setShowOfficeSuggestions}
            // below are used for blur event. too strict. boils down to the below.
            values={{
              initOfficeId: props.formData.officeID,
            }}
            setValues={() => onClearOfficeSelection()}
            officeType={OfficeType.RealEstate}
          />
        </OrderForm.RowItem>
        <OrderForm.RowItem>
          <InitiatingAgentInput
            id={props.ids.agentInput}
            idNoDropdown={props.idNoDropdowns.agentInput}
            required={props.isAgentRequired}
            name="agent"
            label={props.labels.agentInput}
            selectedOffice={props.formData.officeName}
            errors={props.errors.agentInput}
            onAgentError={() =>
              props.onValidateField(OrderFormOfficeAgentField.agentInput, props.formData.agentName)
            }
            values={{
              agentOffice: selectedOffice,
              agentlist: agentSuggestions,
            }}
            setValues={(values) => onAgentSelect(values)}
            onSelectContactChange={() => {}}
          />
        </OrderForm.RowItem>
      </OrderForm.Row>
    </OrderForm.Container>
  );
};

OrderFormOfficeAgent.defaultProps = {
  officeSuggestions: [],
  disableOfficeAgentSelection: false,
  disableOfficeSuggestions: false,
  heading: 'Agent Information',
  isOfficeRequired: true,
  isAgentRequired: true,
};

export default OrderFormOfficeAgent;
