import React, { useContext, useEffect, useState } from 'react';
import Layout from '@components/layout/Layout';
import WelcomeDashboardTemplate from '@templates/dashboard/WelcomeDashboardTemplate';
import { ContentBox } from '@components/layout/ContentBox';
import ProfileContext from '@context/ProfileContext';
import { isMobileView } from '@utils';
import ZestyApi from '@apis/zesty.apis';
import {
  ZestyWelcomeDashboardNotification,
  ZestyWYSIWYGField,
  ZestyDashboardNotificationsLocation,
} from '@apis/models/zesty.api.model';
import NotificationInfo, { NotificationDismissalKeys } from './NotificationInfo';
import { getBrand } from '@helpers/brand.utils';
import { AHS_AND_HSA } from '@constants/dashBoard-constants';
import { hasOfficesInState } from '@services/helpers/profile.offices.helper';

const Dashboard = () => {
  const { profile } = useContext(ProfileContext);
  const isMobile = isMobileView();

  const [bottomNotificationData, setBottomNotificationData] =
    useState<ZestyWelcomeDashboardNotification>();
  const [bottomNotificationContent, setBottomNotificationContent] =
    useState<ZestyWYSIWYGField>(null);
  const [showBottomNotification, setShowBottomNotification] = useState<boolean>(false);
  const [topNotificationData, setTopNotificationData] =
    useState<ZestyWelcomeDashboardNotification>();
  const [topNotificationContent, setTopNotificationContent] = useState<ZestyWYSIWYGField>(null);
  const [showTopNotification, setShowTopNotification] = useState<boolean>(false);

  /** on load, get the contents for the notification. */
  useEffect(() => {
    let notificationStates: string[] = [];

    ZestyApi.GetWelcomeDashboardNotifications().then((notifications) => {
      const activeNotifications = notifications?.filter(
        (a) =>
          a.active === '1' &&
          (a.brand.toUpperCase() === getBrand() || a.brand.toUpperCase() === AHS_AND_HSA),
      );

      activeNotifications.forEach((activeNotification) => {
        switch (activeNotification.location) {
          case ZestyDashboardNotificationsLocation.Top: {
            setShowTopNotification(true);
            setTopNotificationData(activeNotification);
            setTopNotificationContent(activeNotification?.notification);
            break;
          }
          case ZestyDashboardNotificationsLocation.Bottom: {
            if (activeNotification.states.length > 0) {
              notificationStates = activeNotification.states.toUpperCase().split(',');
              // Call the helper method to determine if we have a match between notification states and profile offices states
              const stateMatchFound = hasOfficesInState(profile, notificationStates);
              if (stateMatchFound) {
                setShowBottomNotification(true);
                setBottomNotificationData(activeNotification);
                setBottomNotificationContent(activeNotification?.notification);
              }
            } else {
              // States field having no data entered condition. Applicable for all US states
              setShowBottomNotification(true); // Notification is applicable for all US states.
              setBottomNotificationData(activeNotification);
              setBottomNotificationContent(activeNotification?.notification);
            }
            break;
          }
        }
      });
    });
  }, []);

  return (
    <Layout isLoggedIn={true} slug="dashboard">
      <NotificationInfo
        showNotification={showTopNotification}
        notificationData={topNotificationData}
        notificationContent={topNotificationContent}
        dismissalKey={NotificationDismissalKeys.WELCOME_DASHBOARD_TOP_NOTIFICATION_DISMISSED_KEY}
        className="lg:px-6 md:px-6 px-2 min-w-80 max-w-xxl mx-auto flex mt-8"
        setShowTopNotification={setShowTopNotification}
      />
      <ContentBox title={`Welcome, ${profile.firstName}!`}>
        <NotificationInfo
          showNotification={showBottomNotification}
          notificationData={bottomNotificationData}
          notificationContent={bottomNotificationContent}
          dismissalKey={
            NotificationDismissalKeys.WELCOME_DASHBOARD_BOTTOM_NOTIFICATION_DISMISSED_KEY
          }
        />
        <WelcomeDashboardTemplate isMobile={isMobile} />
      </ContentBox>
    </Layout>
  );
};

export default Dashboard;
