import React from 'react';
import Modal, { ModalProps } from './Modal';
import ConfirmAddress, {
  UnknownAddressModalContentProps,
} from '@components/confirmAddress/confirmAddress';

type Props = ModalProps & UnknownAddressModalContentProps;

const ModalConfirmAddressEntered: React.FC<Props> = (props) => {
  return (
    <Modal {...props}>
      {props.isActive && (
        <ConfirmAddress
          id={props.id}
          addressFromService={props.addressFromService}
          addressFromInput={props.addressFromInput}
          onEditAddress={props.onEditAddress}
          onUseServiceAddress={props.onUseServiceAddress}
          onUseCurrentAddress={props.onUseCurrentAddress}
        />
      )}
    </Modal>
  );
};

export default ModalConfirmAddressEntered;
