import React, { useEffect, useState } from 'react';
import { Button, Input, Panel } from '@ftdr/blueprint-components-react';
import { Contract } from '@apis/models';
import { openOrderDrawer } from '@app/core/GlobalDrawerOrder';
import {
  RefreshContractServiceSubscriber,
  subscribeToRefreshedContracts,
  unsubscribeRefreshedContracts,
} from '@app/core/refreshContract.service';

const DemoGlobalDrawer: React.FC = (props) => {
  const [contract, setContract] = useState<Contract>(null);
  const [contractID, setContractID] = useState<string>('');

  useEffect(() => {
    subscribeToRefreshedContracts(
      RefreshContractServiceSubscriber.DemoGlobalDrawer,
      (contracts) => {
        setContract(contracts.find((c) => c.summary.id === contractID));
      },
    );
    return () => {
      unsubscribeRefreshedContracts(RefreshContractServiceSubscriber.DemoGlobalDrawer);
    };
  }, []);

  const submitId: React.FormEventHandler = (e) => {
    e.preventDefault();
    const $contractInput = document.getElementById('input_contract-id') as HTMLInputElement;
    const contractId = $contractInput.value;
    setContractID(contractId);
    openOrderDrawer({
      contractId,
    });
  };

  return (
    <div className="flex flex-col p-5 w-5/6 mx-auto space-y-4">
      <Panel>
        <form onSubmit={submitId}>
          <Input formField={true} label="Enter a Contract ID" id="input_contract-id" />
          <Button type="submit" label="Open Drawer" size="medium" />
        </form>
      </Panel>
      <Panel>
        <strong>Contract Data:</strong>
        <br />
        <pre className="text-gray text-sm">{JSON.stringify(contract, null, 2)}</pre>
      </Panel>
    </div>
  );
};

export default DemoGlobalDrawer;
