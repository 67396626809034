import React from 'react';
import { AbstractNewOrderStep, wrapNavigation } from '@pages/order/AbstractNewOrderStep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderStep4Template } from '@templates/order/NewOrderStep4Template';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import Layout from '@components/layout/Layout';

class NewOrderStep4 extends AbstractNewOrderStep {
  constructor(props) {
    super(props);
  }

  getCurrentStep(): NewOrderStep {
    return NewOrderStep.ReviewSubmit;
  }

  submitPage(updatedData: NewOrderContextData) {
    // ga event
    // for pages, set the context data, and navigate to a different step
    // if the changes on the page conflicts with later parts of the page (e.g. changing property address requires change in plans)
    //  then update the context based on what's necessary
    // we'd also want to create an order or update the order, depending on if the context contains the order id or not
    // for order submit, call the api to submit the order, and complete the order
    this.context.setNewOrderData(updatedData);

    alert('TODO: submit order');

    this.navigateToOrderSubmitted({
      newOrderData: updatedData,
      contractID: '12345678',
    });
  }

  editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData) {
    // ga event
    // for pages, set the data based off of what is being updated, then navigate to step
    if (updatedData) {
      // we'd also want to update the order if there are partial updates
      this.context.setNewOrderData(updatedData);
    }
    this.navigateToStep(step);
  }

  cancelPage() {
    // ga event
    this.context.resetNewOrderData();
    this.navigateToDashboard();
  }

  render() {
    return (
      <Layout isLoggedIn={true} slug="newOrderStep4" containerClassName="py-5">
        <NewOrderStep4Template
          currentStep={this.getCurrentStep()}
          latestStep={this.context.latestStep}
          savedData={this.context.newOrderData}
          metaData={this.context.metaData}
          userProfile={this.context.userProfile}
          submitPage={(updatedData) => this.submitPage(updatedData)}
          cancelPage={() => this.cancelPage()}
          editAnotherStep={(step, updateData) => this.editAnotherStep(step, updateData)}
        />
      </Layout>
    );
  }
}

export default wrapNavigation(NewOrderStep4);
