/**
 * DEPRECATED 04/11/20. Please use the typescript functionality instead. See _api.ts
 */

import Axios from 'axios';
import merge from 'lodash/merge';
import { getJWT } from '../../auth/authService.ts';

const DEFAULT_AXIOS_CONFIG = {
  // baseURL: 'http://localhost:3000',
  responseType: 'json',
};

function _InstallRequestInterceptors(instance, includeAuth) {
  instance.interceptors.request.use((config) => {
    const copyConfig = { ...config };
    // Access Token
    if (includeAuth) {
      const token = getJWT();
      if (token) {
        copyConfig.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return copyConfig;
  });
}

function _InstallResponseInterceptors(instance) {
  instance.interceptors.response.use((response) => {
    return response.data;
  });
}

function _InstallInterceptors(instance, includeAuth) {
  try {
    if (instance) {
      _InstallRequestInterceptors(instance, includeAuth);
      _InstallResponseInterceptors(instance);
    }
  } catch (e) {
    console.error('Unable to install axios interceptors', e);
  }
}

export default function createAxiosInstance(config = {}, includeAuth = false) {
  const mergeConfig = merge({}, DEFAULT_AXIOS_CONFIG, config);
  const instance = Axios.create(mergeConfig);
  _InstallInterceptors(instance, includeAuth);
  return instance;
}
