import { Address } from './address.api.model';

export enum DwellingTypeCode {
  SingleFamilyResidence = 'SINGLE_FAMILY',
  Condominium = 'CONDO',
  Townhouse = 'TOWNHOME',
  Duplex = 'DUPLEX',
  Triplex = 'TRIPLEX',
  Fourplex = 'FOUR_PLEX',
  MobileHome = 'MOBILE_HOME',
  MultiUnitPlex = 'MULTI_UNIT',
}

export interface PropertyDetailList {
  results: PropertyDetail[];
}

export interface PropertyDetailResponse {
  property: PropertyDetail;
}

export interface PropertyDetail {
  ID: string;
  addressID: string;
  legacyIDs: string[];
  attributes: {
    squareFootageRange: {
      min: number;
      max: number;
    };
    yearBuilt: number;
    zoning: string;
    dwellingType: DwellingTypeCode;
    parcelNumbers: number[];
  };
  meta: {
    createdAt: string;
    lastUpdated: string;
    datasource: string;
  };
}

export interface GetOrCreatePropertyIDRequest {
  address: Address;
  type_of_residence: string;
  square_footage: number;
  guest_unit_count: number;
  age: number;
}

export interface GetOrCreatePropertyIDResponse {
  id: string;
}

export interface CreateOrUpdateProperty {
  residenceType: string;
  squareFootage: number;
  yearBuilt: number;
}
