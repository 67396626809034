import React, { useState } from 'react';

const CardRadio = (props) => {
  const [checkedItem, setCheckedItem] = useState([]);

  const handleClick = (idx) => () => {
    if (props.multiSelect) {
      if (checkedItem && checkedItem.length) {
        if (checkedItem.includes(idx)) {
          const filterItems = checkedItem.filter((item) => {
            return item !== idx;
          });
          setCheckedItem(filterItems);
          props.onClick(idx);
          return;
        }
        setCheckedItem([...checkedItem, idx]);
      } else {
        setCheckedItem([idx]);
      }
    } else {
      setCheckedItem([idx]);
    }
    props.onClick(idx);
  };

  function checkActiveItems(idx) {
    if (checkedItem && checkedItem.length) {
      return checkedItem.includes(idx);
    }
  }

  const renderChildren = () => {
    return React.Children.map(props.children, (child, idx) => {
      return React.cloneElement(child, {
        key: idx,
        onClick: handleClick(idx),
        defaultChecked: checkedItem === idx,
        isActive: checkActiveItems(idx),
        name: 'radio-item',
      });
    });
  };

  return (
    <div id={props.id} className="card-radio-group">
      {renderChildren()}
    </div>
  );
};

export default CardRadio;
