import React from 'react';
import { AbstractNewOrderStep, wrapNavigation } from '@pages/order/AbstractNewOrderStep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import Layout from '@components/layout/Layout';
import OrderForm from '@components/orders/forms/OrderForm';
import {
  AbstractNewOrderStepTemplate,
  NewOrderStepStates,
  NewOrderStepTemplateProps,
} from '@templates/order/AbstractNewOrderStepTemplate';
import cloneDeep from 'lodash/cloneDeep';
import {
  validateInitiatingAgent,
  validateInitiatingOffice,
  validateInitiatingRepresents,
} from '@services/validation/NewOrder.FieldValidationRules';
import { isAllowedRO, isREAgent } from '@helpers/profile.utils';
import Path from '@constants/paths';
import OrderFormOfficeAgent, {
  OrderFormOfficeAgentField,
} from '@components/orders/forms/OrderFormOfficeAgent';
import { OrderFormFieldError } from '@components/orders/forms/OrderForm.types';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import OrderFormAgentInfo, {
  OrderFormAgentInfoField,
} from '@components/orders/forms/OrderFormAgentInfo';

class DemoNewOrderRedesign extends AbstractNewOrderStep {
  constructor(props) {
    super(props);
  }

  cancelPage(): void {
    // ga event
    this.context.resetNewOrderData();
    this.props.navigation.navigate(Path.Dashboard);
  }

  editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData): void {
    // ga event
    // for pages, set the data based off of what is being updated, then navigate to step
    if (updatedData) {
      // we'd also want to update the order if there are partial updates
      this.context.setNewOrderData(updatedData);
    }
    this.navigateToStep(step);
  }

  getCurrentStep(): NewOrderStep {
    return NewOrderStep.AgentPropertyInfo;
  }

  submitPage(updatedData: NewOrderContextData) {
    // ga event
    // for pages, set the context data, and navigate to a different step
    // we'd also want to create an order or update the order, depending on if the context contains the order id or not
    // for order submit, call the api to submit the order, and complete the order
    this.context.setNewOrderData(updatedData);
    this.navigateToStep(NewOrderStep.PlanCoverage);
  }

  render() {
    return (
      <Layout isLoggedIn={true} slug="newOrderStepDemo" containerClassName="py-5">
        <DemoNewOrderRedesignTemplate
          userProfile={this.context.userProfile}
          currentStep={this.getCurrentStep()}
          latestStep={this.context.latestStep}
          savedData={this.context.newOrderData}
          metaData={this.context.metaData}
          submitPage={(updatedData) => this.submitPage(updatedData)}
          cancelPage={() => this.cancelPage()}
          editAnotherStep={(step, updatedData) => this.editAnotherStep(step, updatedData)}
        />
      </Layout>
    );
  }
}

export default wrapNavigation(DemoNewOrderRedesign);

////////////////////////////////////////////////////////////////////////////////////

interface States
  extends NewOrderStepStates,
    Pick<NewOrderContextData, 'initiatingAgent' | 'agentInfo' | 'addressInfo' | 'propertyInfo'> {
  initiatingAgentErrors: OrderFormFieldError<OrderFormOfficeAgentField>;
  agentInfoErrors: OrderFormFieldError<OrderFormAgentInfoField>;
}

class DemoNewOrderRedesignTemplate extends AbstractNewOrderStepTemplate<States> {
  constructor(props) {
    super(props);
  }

  getDefaultState(props: NewOrderStepTemplateProps): States {
    return {
      agentInfo: cloneDeep(props.savedData.agentInfo),
      initiatingAgent: cloneDeep(props.savedData.initiatingAgent),
      addressInfo: cloneDeep(props.savedData.addressInfo),
      propertyInfo: cloneDeep(props.savedData.propertyInfo),
      initiatingAgentErrors: {
        [OrderFormOfficeAgentField.officeInput]: '',
        [OrderFormOfficeAgentField.agentInput]: '',
      },
      agentInfoErrors: {
        [OrderFormAgentInfoField.represents]: '',
        [OrderFormAgentInfoField.roSelect]: '',
      },
    };
  }

  getPartialSubmittedData(): NewOrderContextData {
    // should perform validation on the fields and stripped off any incomplete data
    return this.getPendingData(); // for now, saving all data regardless of incomplete data
  }

  getPendingData(): NewOrderContextData {
    return {
      ...this.props.savedData,
      agentInfo: this.state.agentInfo,
      addressInfo: this.state.addressInfo,
      propertyInfo: this.state.propertyInfo,
      initiatingAgent: this.state.initiatingAgent,
    };
  }

  isFormValidForSubmission(): boolean {
    const agentInfoErrors: OrderFormFieldError<OrderFormAgentInfoField> = {
      [OrderFormAgentInfoField.represents]: this.validateAgentInfoField(
        OrderFormAgentInfoField.represents,
        this.getPendingData().agentInfo.represents,
      ),
      [OrderFormAgentInfoField.roSelect]: '',
    };
    const initiatingAgentErrors: OrderFormFieldError<OrderFormOfficeAgentField> = {
      [OrderFormOfficeAgentField.officeInput]: this.validateOfficeAgentField(
        OrderFormOfficeAgentField.officeInput,
        this.getPendingData().initiatingAgent.officeName,
      ),
      [OrderFormOfficeAgentField.agentInput]: this.validateOfficeAgentField(
        OrderFormOfficeAgentField.agentInput,
        this.getPendingData().initiatingAgent.agentName,
      ),
    };
    // updates all errors in the state to show latest error messages
    this.setState({ agentInfoErrors, initiatingAgentErrors });
    // form is valid if every error is blank
    return Object.values({ ...agentInfoErrors, ...initiatingAgentErrors }).every((err) => !err);
  }

  /** old approach, left for reference */
  isSubmitButtonDisabled(): boolean {
    return [
      this.validateOfficeAgentField(
        OrderFormOfficeAgentField.officeInput,
        this.getPendingData().initiatingAgent.officeName,
      ),
      this.validateOfficeAgentField(
        OrderFormOfficeAgentField.agentInput,
        this.getPendingData().initiatingAgent.agentName,
      ),
      this.validateAgentInfoField(
        OrderFormAgentInfoField.represents,
        this.getPendingData().agentInfo.represents,
      ),
      // add additional validaiton calls below
    ].some((errs) => !!errs); // if at least 1 validation has errors, disable the button
  }

  validateOfficeAgentField = (fieldName: OrderFormOfficeAgentField, value: string) => {
    switch (fieldName) {
      case OrderFormOfficeAgentField.officeInput:
        return validateInitiatingOffice(value);
      case OrderFormOfficeAgentField.agentInput:
        return validateInitiatingAgent(value);
    }
    return '';
  };

  onUpdateErrorOfficeAgentField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormOfficeAgentField,
    value: string = '',
  ) => {
    const errors = this.state.initiatingAgentErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateOfficeAgentField(fieldName, value)
      : value;
    this.setState({ initiatingAgentErrors: errors });
  };

  validateAgentInfoField = (fieldName: OrderFormAgentInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormAgentInfoField.roSelect:
        return '';
      case OrderFormAgentInfoField.represents:
        return validateInitiatingRepresents(value);
    }
    return '';
  };

  onUpdateErrorAgentInfoField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormAgentInfoField,
    value: string = '',
  ) => {
    const errors = this.state.initiatingAgentErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateAgentInfoField(fieldName, value)
      : value;
    this.setState({ initiatingAgentErrors: errors });
  };

  onClear(): void {
    this.setState(this.getDefaultState(this.props));
  }
  onSubmit(): void {
    this.props.submitPage(this.getPendingData());
  }
  onEdit(editStep: NewOrderStep): void {
    this.props.editAnotherStep(editStep, this.getPartialSubmittedData());
  }

  renderForm(): JSX.Element {
    return (
      <OrderForm.Container id="agent-info-form" heading="Agent Information">
        <OrderFormOfficeAgent
          ids={{
            [OrderFormOfficeAgentField.officeInput]: 'wb_initiatingOfficeTypeahead',
            [OrderFormOfficeAgentField.agentInput]: 'wb_initiatingAgentTypeahead',
          }}
          labels={{
            [OrderFormOfficeAgentField.officeInput]: 'Initiating office',
            [OrderFormOfficeAgentField.agentInput]: 'Initiating agent',
          }}
          idNoDropdowns={{
            [OrderFormOfficeAgentField.officeInput]: 'wb_initiatingOfficeTypeahead',
            [OrderFormOfficeAgentField.agentInput]: 'wb_initiatingAgentTypeahead',
          }}
          officeSuggestions={getProfileOfficesByType(this.props.userProfile, OfficeType.RealEstate)}
          disableOfficeSuggestions={false}
          disableOfficeAgentSelection={isREAgent(this.props.userProfile)}
          formData={this.state.initiatingAgent}
          errors={this.state.initiatingAgentErrors}
          onUpdateFormData={(data) => this.setState({ initiatingAgent: data })}
          onValidateField={(fieldName, value) =>
            this.onUpdateErrorOfficeAgentField(true, fieldName, value)
          }
          clearFieldError={(fieldName) => this.onUpdateErrorOfficeAgentField(false, fieldName)}
          onFieldError={(fieldName, err) =>
            this.onUpdateErrorOfficeAgentField(false, fieldName, err)
          }
          onFormError={console.log}
        />

        <OrderFormAgentInfo
          isREAgentUser={isREAgent(this.props.userProfile)}
          showROOption={true /*isAllowedRO(this.props.userProfile)*/}
          formData={this.state.agentInfo}
          errors={this.state.agentInfoErrors}
          onUpdateFormData={(data) => this.setState({ agentInfo: data })}
          onValidateField={(fieldName, value) =>
            this.onUpdateErrorAgentInfoField(true, fieldName, value)
          }
          clearFieldError={(fieldName) => this.onUpdateErrorAgentInfoField(false, fieldName)}
          onFieldError={(fieldName, err) => this.onUpdateErrorAgentInfoField(false, fieldName, err)}
          onFormError={console.log}
          onROToggle={console.log}
        />
      </OrderForm.Container>
    );
  }
}
