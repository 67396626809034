import React from 'react';
import ContactUsSuccessTemplate from '@templates/contact/ContactUsSuccessTemplate';
import HeaderNoAuth from '@components/general/HeaderNoAuth';

export const ContactUsNoAuthSuccess: React.FC = () => {
  return (
    <>
      <HeaderNoAuth />
      <ContactUsSuccessTemplate isButtonVisible={false} />
    </>
  );
};
export default ContactUsNoAuthSuccess;
