import { RealEstateStatus } from '@constants/dashboardFilters';
import { formatDateToISO8601 } from '@helpers/utils';
import { addDays } from 'date-fns';
import { QuoteDetail, QuoteFeatures, QuoteSummary } from './quote.api.model';

export interface REOrderSearchMetaStatus {
  active: number;
  cancelled_order: number;
  closing_soon: number;
  expired: number;
  expiring: number;
  payment_due: number;

  [key: string]: number;
}

export interface REOrderSearchResponse {
  meta: {
    total: number;
    from: number;
    to: number;
    count: number;
    totalAwaitingWlkSubmission: number;
    /** Key is the RE Status */
    totalStatus: REOrderSearchMetaStatus;
    totalMissingCOE: number;
  };
  orders: REOrder[];
}

export interface REOrder {
  id: number; // TODO: align types used across the domain, API currently returns an int32 while the details endpoint requires a string (there may be other areas that lack alignment as well) ARE-8817
  tableId: number; // TODO: this is not part of the api response and shouldn't be in this model.
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  expirationDate: string;
  initiatingAgentName: string;
  initiatingOfficeName: string;
  initiatingAgentID: string;
  initiatingOfficeID: string;
  status: string;
  realEstateStatus: RealEstateStatus;
  awaitingWlkSubmission: boolean;
  warrantylink: {
    status: string;
    deadlineDate: string;
    submissionDate?: string;
  };
  initiatingOfficeAddress?: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
}

export interface REQuote extends REOrder {}

/** note, this is NOT used on the app code. This gets translated into TranslatedREQuoteResponse in the api code. */
export interface REQuoteSearch {
  id: number;
  tableId: number;
  initiatingOfficeName: string;
  initiatingAgentName: string;
  initiatingOfficeID: string;
  initiatingAgentID: string;
  awaitingWlkSubmission: boolean;
  status: string;
  effectiveDate: string;
  expirationDate: string;
  sharedDate?: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    dwellingTypeCode: string;
    squareFeet: string;
  };
  realEstateStatus: RealEstateStatus;
  warrantylink: {
    status: string;
    deadlineDate: string;
    submissionDate?: string;
  };
  property: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    dwellingTypeCode: string;
    squareFeet: string;
  };
  offices: QuoteOffice[];
  quote: {
    quoteId: string;
    quoteStatus: string;
    planId: string;
    productId: string;
    productName: string;
    price: number;
    tax: number;
    // TODO Refactor out sellersCoverage, it's not a thing
    sellersCoverage: number;
    hasSellersCoverage?: boolean;

    optionalCoverages: OptionalCoverages[];
    sellersCoverageDetails?: SellersCoverageDetails;
  };
  summary?: QuoteSummary;
  detail?: QuoteDetail;
  features?: QuoteFeatures;
  displayStatus: string;
  contractId: string;
}

export interface SellersCoverageDetails {
  dailyRate: number;
  listingDays: number;
}

export interface QuoteOffice {
  officeId: string;
  officeType: string;
  initiatingOffice: boolean;
  represents: string;
  agentId: string;
  officeName: string;
  agentName: string;
}

export interface OptionalCoverages {
  productId: string;
  productName: string;
  quantity: number;
  price: number;
}

export interface REQuoteSearchMetaStatus {
  NEW?: number;
  SAVED?: number;
  SHARED?: number;
  ABANDONED?: number;
  EXPIRED?: number;
  CANCELLED?: number;
  COMPLETE?: number;
  'Converted to order'?: number;
  New?: number;
  Saved?: number;
  Shared?: number;
  Abandoned?: number;
  Expired?: number;
  Cancelled?: number;
  Complete?: number;
}

export interface REQuoteSearchResponse {
  meta: {
    total: number;
    from: number;
    to: number;
    count: number;
    totalAwaitingWlkSubmission: number;
    /** Key is the RE Status */
    totalStatus: REQuoteSearchMetaStatus;
    totalMissingCOE: number;
  };
  orders: REQuoteSearch[];
}

export interface REQuoteSearchRequest extends REOrderSearchRequest {}

export interface REOrderSearchRequest {
  meta: {
    page: number;
    limit: number;
    sortBy: string;
    asc: boolean;
  };
  data: {
    query: string;
    order: {
      ids: string[];
      realEstateStatus: string[];
      awaitingWLKSubmissionOnly: boolean;
      statusListingMissingCOEOnly: boolean;
      sourceApplicationFlows: string[]; //default source is RE-PlansPrices
    };
    offices: {
      id: string;
      type: string;
    }[];
    agents: {
      id: string;
      type: string;
    }[];
    date: {
      startDate: string;
      endDate: string;
      dateType: string;
    };
    filters: REOrderDataFilterCode[];
  };
  include: {
    warrantylink: boolean;
    meta: boolean;
    unexpiredOrdersOnly?: boolean;
    initiatingOfficeOnly?: boolean;
    createdBySelfOnly?: boolean;
  };
  includeResponseData?: {
    quote?: boolean; // defaulted true
    offices?: boolean; // defaulted true
    initiatingOffice?: boolean; // defaulted true
  };
}

export enum REOrderDataFilterCode {
  contractRenewal = 0,
}

export class REOrderSearchRequest {
  constructor(pageSize: number, isWarrantyLinkRequest = false) {
    this.data = {
      agents: [],
      date: {
        dateType: '',
        endDate: '',
        startDate: '',
      },
      offices: [],
      order: {
        ids: [],
        realEstateStatus: [],
        awaitingWLKSubmissionOnly: false,
        statusListingMissingCOEOnly: false,
        sourceApplicationFlows: [], //if empty, default value is RE-PlansPrices
      },
      query: '',
      filters: [],
    };

    this.meta = {
      asc: false,
      limit: pageSize,
      page: 0,
      sortBy: '', // TODO: this is currently mapped to entry_date at the ms level. either decouple and add support for both or modify this var name across the stack to better represent that mapping
    };

    this.include = { warrantylink: false, meta: true };

    if (isWarrantyLinkRequest) {
      // default search doesnt return wlk contracts with endDate > today, set endDate to be today + 90 days
      this.data.date.endDate = formatDateToISO8601(addDays(new Date(), 91));
      this.include.warrantylink = true;
      this.meta.sortBy = 'WarrantylinkDeadlineDate';
      this.data.date.dateType = 'WarrantylinkDeadlineDate';
    }
  }
}

export interface MissingBuyerPhoneResponse {
  count: number;
  contracts: {
    id: string;
    propertyAddress: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      propertyId: string;
    };
    buyer: {
      firstName: string;
      lastName: string;
    };
  }[];
}

export const DEFAULT_CUSTOMER_FORM = {
  buyer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  seller: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};
