import React, { useContext } from 'react';
import Layout from '@components/layout/Layout';
import ContactUsTemplate from '@templates/contact/ContactUsTemplate';
import ProfileContext from '../../context/ProfileContext/index';

const ContactUsAuth = (props) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Layout isLoggedIn={true}>
      <ContactUsTemplate
        email={profile.email}
        roleType={profile.roleIDType}
        fullName={`${profile.firstName} ${profile.lastName}`}
        isAuthed={true}
        contractId={props?.contractId}
        topic={props?.topic}
        emailText={props?.emailText}
      />
    </Layout>
  );
};
export default ContactUsAuth;
