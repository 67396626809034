import React, { useState } from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button, IconCalendar } from '@ftdr/blueprint-components-react';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_MISSING_COE } from '@constants/ga-events.constants';
import DrawerOrdersMissingCOE from '@components/drawer/DrawerOrdersMissingCOE';
import { toFormattedNumber } from '@helpers/utils';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

const CardDashboardActionMissingCOE = (props: ICardDashBoardActionProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleAddClosingDates = () => {
    fireGAEvent(DASHBOARD_MISSING_COE);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <CardDashboardAction
        isManualFetch={props.isManualFetch}
        fetchCount={props.fetchCount}
        type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
        heading={props.count === null ? null : toFormattedNumber(props.count)}
        subHeading="missing close of escrow dates"
        action={
          <Button
            label="Add Closing Dates"
            size="medium"
            startIcon={<IconCalendar />}
            disabled={props.count === null || props.count === 0}
            onClick={() => handleAddClosingDates()}
          />
        }
      />
      <DrawerOrdersMissingCOE
        isActive={isDrawerOpen}
        onUpdateOrderCount={props.updateCount}
        onClose={() => setIsDrawerOpen(false)}
        initialTotalContracts={props.count}
      />
    </>
  );
};

export default CardDashboardActionMissingCOE;
