import { GetContractByIDResponse } from '@apis/models';
import { Address } from './address.model';

export enum OrderStatus {
  Active = 'A',
  Listing = 'L',
  Canceled = 'C',
  Expired = 'X',
}

export interface Order {
  summary: OrderSummary;
  detail: OrderDetail;
  features: OrderFeatures;
  /** TODO: THIS IS ONLY TEMPORARY.
   * This will be removed once all data points are identified and moved to details */
  meta?: GetContractByIDResponse;
}

// Used for the table view
export interface OrderSummary {
  id: string;
  expirationDate: Date;
  agentName: string;
  officeName: string;
  status: OrderStatus;
  address: Address;
}

// Used for the single order view. Break it apart for the order-details
export interface OrderDetail {}

// Used to determine flags enable/disable functions
export interface OrderFeatures {
  extendListing: {
    canExtend: boolean;
    daysToExtend: number;
    dateTypes: string[];
  };
  warrantylink: {
    hasUnsubmittedWarrantylink: boolean;
  };
  serviceRequest: {
    canRequestService: boolean;
  };
}

export interface OrderHistory {
  userID: string;
  type: string;
  data: string;
  dateTimeStamp: string;
}
