import React from 'react';
import { classNames } from '@utils';
import { Panel, Radio } from '@ftdr/blueprint-components-react';

export interface CardRadioItemProps {
  isActive?: boolean;
  name?: string;
  onClick?: (event: MouseEvent) => void;
  readOnly?: boolean;
  title?: string;
  leftContent?: JSX.Element;
  centerContent?: JSX.Element;
  rightContent?: JSX.Element;
}

const CardRadioItem = (props: CardRadioItemProps) => {
  const { widthClass, widthClassLeft } = calculateColumnWidths(props);

  const handleSelect = (event) => {
    event.preventDefault();
    props.onClick(event);
  };

  return (
    <Panel
      className="block relative card--radio"
      border="1"
      rounded="md"
      padding="md"
      borderColor="interactive-100"
      shadow={true}
    >
      <div onClick={handleSelect}>
        <div
          data-testid={`wb_compRadioBtn-${props.name}`}
          className="form-radio-wrap absolute top-50 left-4 cursor-pointer border-gray-500 leading-0"
        >
          <Radio
            name={props.name}
            onChange={handleSelect}
            label=""
            hideLabel={true}
            readOnly={props.readOnly}
            checked={props.isActive}
          />
          <div className="form-icon" />
        </div>

        <div
          className={classNames([
            'card__content md:flex md:items-center p-4 pl-12 cursor-pointer border-2 border-transparent rounded',
            props.isActive && 'border-interactive-400',
          ])}
        >
          <div className={classNames(['md:pr-8', widthClassLeft])}>
            {props.title && (
              <div
                className={classNames([
                  'text-base md:text-lg tracking-tight',
                  'text-interactive-400',
                  props.readOnly ? 'text-gray-400' : '',
                ])}
              >
                {props.title}
              </div>
            )}
            {props.leftContent}
          </div>
          <div className={classNames([widthClass])}>{props.centerContent}</div>
          <div className={classNames([widthClass])}>{props.rightContent}</div>
        </div>
      </div>
    </Panel>
  );
};

/**
 * The purpose of this is to determine reasonable column proportions given the number
 * of columns provided.
 *
 * If just one column is provided it will get the full width
 * If just two columns are provided it will split them evenly half-and-half
 * If three columns are provided the first column will be a little bit wider with the next two splitting the remaining space
 *
 * @param props
 * @returns {{widthClassLeft: string, widthClass: string}}
 */
const calculateColumnWidths = (props) => {
  let columnCount = props.title || props.leftContent ? 1 : 0;
  if (props.centerContent) {
    columnCount += 1;
  }
  if (props.rightContent) {
    columnCount += 1;
  }
  let widthClassLeft = '';
  let widthClass = '';
  if (columnCount === 2) {
    widthClassLeft = 'md:w-1/2';
    widthClass = 'md:w-1/2';
  } else if (columnCount === 3) {
    widthClassLeft = 'md:w-1/2';
    widthClass = 'md:w-1/4';
  }
  return { widthClass, widthClassLeft };
};

export default CardRadioItem;
