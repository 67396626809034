import React, { Component } from 'react';
import {
  Button,
  CollapsibleText,
  IconDownload,
  Image,
  Link,
  Notification,
  Panel,
  Popover,
  PopoverContent,
  Select,
  Text,
} from '@ftdr/blueprint-components-react';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  SALES_MATERIAL_DOWNLOAD,
  SALES_MATERIAL_SHARE,
  SALES_MATERIAL_WATCH,
} from '@constants/ga-events.constants';
import { SalesMaterial } from '@apis/models/zesty.api.model';
import ModalShareDocument from '@components/modal/ModalShareDocument';
import SalesMaterialApi from '@apis/salesmaterial.api';
import { SECOND } from '@helpers/utils';
import { ShareDocumentEmailMessage } from '@apis/models/emailsender.api.model';

const FILE_OPTION_PREFIX_SEPARATOR = '_';

interface state {
  textExpanded: boolean;
  selectedMediaZuid: string;
  showShareModal: boolean;
  showShareNotification: boolean;
  isShareSuccess: boolean;
  emailToSend: string;
}

interface props {
  salesMaterial: SalesMaterial;
}
export default class CardSalesMaterial extends Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      textExpanded: false,
      selectedMediaZuid: null,
      showShareModal: false,
      showShareNotification: false,
      isShareSuccess: false,
      emailToSend: null,
    };
  }

  toggleText = () => {
    this.setState({ textExpanded: !this.state.textExpanded });
  };

  selectDropdown = (option) => {
    this.setState({ selectedMediaZuid: option?.value });
  };

  getSelectedDropdownURL = () => {
    let { url } = this.props.salesMaterial.media[0];
    if (this.state.selectedMediaZuid) {
      url = this.props.salesMaterial.media.find(
        (item) => item.zuid === this.state.selectedMediaZuid,
      ).url;
    }
    return url;
  };
  getSelectedDropdownLabel = () => {
    let label = '';
    this.props.salesMaterial.media.map((item) => {
      if (item.zuid === this.state.selectedMediaZuid) {
        const fileName = item.url?.split('/')?.at(-1);
        const prefix = fileName?.split(FILE_OPTION_PREFIX_SEPARATOR)[0];
        if (prefix) {
          label = prefix;
        }
      }
    });
    return label;
  };

  handleDownload = () => {
    // downloadFile(this.props.salesMaterial.media[0], this.props.salesMaterial.media[0].split('/').at(-1));
    let showGAEventUrl = false;
    const urlToOpen = this.getSelectedDropdownURL();

    if (this.state.selectedMediaZuid) {
      showGAEventUrl = true;
    }
    fireGAEvent(
      SALES_MATERIAL_DOWNLOAD(
        `${this.props.salesMaterial.title}${showGAEventUrl ? `: ${urlToOpen}` : ''}`,
      ),
    );
    window.open(urlToOpen, '_blank');
  };

  handleWatch = () => {
    fireGAEvent(SALES_MATERIAL_WATCH(this.props.salesMaterial.title));
    window.open(this.props.salesMaterial.externalMedia, '_blank');
  };

  handleShare = () => {
    fireGAEvent(
      SALES_MATERIAL_SHARE(this.props.salesMaterial.title, this.getSelectedDropdownLabel()),
    );
    this.setState({ showShareModal: true });
  };

  handleSendEmail = (email) => {
    this.setState({ emailToSend: email });
    const shareDocumentEmailMessage: ShareDocumentEmailMessage = {
      email,
      link: this.getSelectedDropdownURL(),
      filename: this.props.salesMaterial.title,
      fileType: this.getFileType(),
    };

    this.setState({ showShareModal: false });

    SalesMaterialApi.shareDocument(shareDocumentEmailMessage).then((result) => {
      this.setState({ showShareNotification: true, isShareSuccess: result });
      if (!this.state.isShareSuccess) {
        console.error('sending share document email failed');
      }
    });
  };

  isButtonDisabled = () => {
    return !this.state.selectedMediaZuid && !!this.props.salesMaterial.media[1];
  };

  getMediaOptions = () => {
    const mediaOptions = this.props.salesMaterial.media.map((item) => {
      const fileName = item.url?.split('/')?.at(-1);
      const prefix = fileName?.split(FILE_OPTION_PREFIX_SEPARATOR)[0].replaceAll('-', ' ');
      return { label: prefix, value: item.zuid };
    });
    return mediaOptions.sort((a, b) => a.label.localeCompare(b.label));
  };

  getFileType = () => {
    const fileTypeDocument = 'document';
    const fileTypeVideo = 'video';
    return this.props.salesMaterial.externalMedia ? fileTypeVideo : fileTypeDocument;
  };

  getNotificationText = () => {
    let notificationText = `Your document was unable to be shared with ${this.state.emailToSend}`;
    if (this.state.isShareSuccess) {
      notificationText = `Your document has been shared with ${this.state.emailToSend}`;
    }
    return notificationText;
  };

  render() {
    const { salesMaterial } = this.props;
    return (
      <div className="md:w-1/2 lg:w-1/3 p-3">
        <Panel
          padding="none"
          rounded="md"
          borderColor="gray-300"
          shadow
          className="h-auto overflow-hidden block"
        >
          <div className="overflow-hidden">
            <Image
              className="h-auto w-full"
              src={salesMaterial.image}
              alt="Zest Image"
              objectFit="contain"
              objectPosition="center"
            />
          </div>
          <div className="mt-3">
            <Text variant="heading-04" className="ml-3 mb-1" lineClamp={1}>
              {salesMaterial.title}
            </Text>
            <CollapsibleText
              className="ml-3 mt-1"
              lineClamp={1}
              state={this.state.textExpanded ? 'expanded' : 'collapsed'}
            >
              <Text variant="body-long">{salesMaterial.description}</Text>
              <Link
                onClick={this.toggleText}
                color="interactive"
                underline="always"
                className="color-primary-300"
              >
                {this.state.textExpanded ? 'less' : 'read more'}
              </Link>
            </CollapsibleText>
            {!!salesMaterial?.media[1] && !salesMaterial.externalMedia ? (
              <Select
                className="m-3"
                label="select"
                placeholder="Select an Option"
                onSelect={this.selectDropdown}
                options={this.getMediaOptions()}
              />
            ) : (
              <div className="h-15" />
            )}
          </div>
          <div className="flex items-center justify-evenly px-1 mt-4 mb-3">
            <Popover
              placement="bottom"
              triggerInteraction="hover"
              content={(popoverContentProps) => (
                <PopoverContent {...popoverContentProps}>
                  <Text className="max-w-xs" color="gray" variant="caption">
                    Select an option above.
                  </Text>
                </PopoverContent>
              )}
              disabled={!this.isButtonDisabled()}
              className="mr-3 w-full"
            >
              <Button
                className="m-2"
                labelAlign="center"
                width="full"
                variant="outlined"
                color="interactive"
                size="small"
                label="Share"
                disabled={this.isButtonDisabled()}
                onClick={this.handleShare}
              />
            </Popover>
            {salesMaterial.externalMedia ? (
              <Button
                className="m-2"
                labelAlign="center"
                width="full"
                color="interactive"
                size="small"
                label="Watch"
                onClick={this.handleWatch}
              />
            ) : (
              <Popover
                placement="bottom"
                triggerInteraction="hover"
                content={(popoverContentProps) => (
                  <PopoverContent {...popoverContentProps}>
                    <Text className="max-w-xs" color="gray" variant="caption">
                      Select an option above.
                    </Text>
                  </PopoverContent>
                )}
                disabled={!this.isButtonDisabled()}
                className="mr-3 w-full"
              >
                <Button
                  className="m-2"
                  color="interactive"
                  width="full"
                  size="small"
                  label="Download"
                  endIcon={<IconDownload />}
                  disabled={this.isButtonDisabled()}
                  onClick={this.handleDownload}
                />
              </Popover>
            )}
          </div>
        </Panel>

        <ModalShareDocument
          id=""
          header=""
          isActive={this.state.showShareModal}
          hideDialog={() => this.setState({ showShareModal: false })}
          handleSendEmail={(email) => this.handleSendEmail(email)}
        />

        {this.state.showShareNotification && (
          <div id="share-doc-notification" className="fixed">
            <Notification
              status={this.state.isShareSuccess ? 'success' : 'error'}
              variant="floating"
              size="medium"
              inline={true}
              showStatusLabel={true}
              onClose={() => this.setState({ showShareNotification: false })}
              autoCloseDelay={30 * SECOND}
            >
              {this.getNotificationText()}
            </Notification>
          </div>
        )}
      </div>
    );
  }
}
