import React from 'react';
import { Badge, IconNavArrowDown, IconNavArrowUp } from '@ftdr/blueprint-components-react';
import { BDSMainColor } from '@constants/bds.constants';
import { classNames } from '@utils';

export enum CardTopStripType {
  None = 'none',
  Strip = 'strip',
  Badge = 'badge',
  Header = 'header',
  CollapsingHeader = 'collapsing-header',
}

export interface CardTopStripProps {
  /** header text, styling depends on the type of top strip */
  title?: string;
  /** applies styling to the top strip of the card */
  type?: CardTopStripType;
  /** only applies for CollapsingHeader type. Changes the nav arrow icon direction and styling */
  isCollapsed?: boolean;
  /** only applies for Badge type. Changes the color of the badge */
  color?: BDSMainColor;
  /** applies if the badge/header gets clicked. only used for CollapsingHeader */
  onClick?: () => void;
}

const CardTopStrip: React.FC<CardTopStripProps> = (props) => {
  if (props.type === CardTopStripType.None) {
    return <></>;
  }
  return (
    <div
      onClick={(e) => props.onClick?.()}
      className={classNames([
        `badge-${props.color}`,
        'card-top-strip',
        props.type === CardTopStripType.Header || props.type === CardTopStripType.CollapsingHeader
          ? 'card-top-strip-header'
          : '',
        props.type === CardTopStripType.CollapsingHeader && 'card-top-strip-header-collapsable',
        props.type === CardTopStripType.CollapsingHeader && props.isCollapsed
          ? 'card-top-strip-header-collapsed'
          : '',
        props.type === CardTopStripType.Strip || props.type === CardTopStripType.Badge
          ? 'card-top-strip-flat'
          : '',
      ])}
    >
      {props.type === CardTopStripType.Badge && (
        <span className="relative -top-2">
          <Badge color={props.color} size="large">
            {props.title}
          </Badge>
        </span>
      )}
      {props.type === CardTopStripType.Header && (
        <div className="card-top-strip-header-text">{props.title}</div>
      )}
      {props.type === CardTopStripType.CollapsingHeader && (
        <div className="card-top-strip-header-text">
          {props.title}
          {props.isCollapsed && (
            <IconNavArrowDown size={24} color="white" className="inline ml-2" />
          )}
          {!props.isCollapsed && <IconNavArrowUp size={24} color="white" className="inline ml-2" />}
        </div>
      )}
    </div>
  );
};

CardTopStrip.defaultProps = {
  type: CardTopStripType.None,
  isCollapsed: true,
  color: BDSMainColor.Primary,
};

export default CardTopStrip;
