import React, { Component } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';
import OfficeSearch from '@components/misc/OfficeSearch';
import usaStates from '@constants/us-states';
import OfficeApi from '@apis/office.api';
import Skeleton from 'react-loading-skeleton';
import ProfileApi from '@services/apis/profile.api';
import { Office } from '@app/models';
import { AuthContext } from '@ftdr/use-auth/build';
import Template from '../../templates/registration/ExistingUserTemplate';
import LayoutCard from '../../components/layout/LayoutCard';
import { officeTypeDictionary, officeTypeFullFormDictionary } from '@constants/dictionaries';
import { loggedInEmail } from '../../auth/authService';
import ProfileModel from '@app/models/profile.model';
import { JUST_REGISTERED } from '@helpers/localStorage.utils';
import Path from '@constants/paths';
import DeepLinkService from '@services/deepLink/deepLink.service';

interface State {
  selectedAgentIndex: number;
  registrationType?: string;
  currentUser: {
    firstName: string;
    lastName: string;
    roleIDType: string;
    phoneNumber: string;
    narID: string;
  };
  agentsList: any[];
  isLoading: boolean;
  isOfficeSearchActive: boolean;
  errorMessage: string;
  shouldLogin: boolean;
}

interface Props {
  navigation: any;
  registrationType: string;
}

const HTTP_STATUS_CONFLICT = 409;
const HTTP_STATUS_OK = 200;
const HTTP_STATUS_CREATED = 201;

// TODO This page has a lot of duplicated functionality from RegistrationAccount due to implementation of CheckForExisting User
// We should consider pulling out the existing user check into a pop-up or overlay for RegistrationAccount
class ExistingUser extends Component<Props, State> {
  static contextType = AuthContext;
  profile: ProfileModel = null;

  constructor(props) {
    super(props);
    this.state = {
      isOfficeSearchActive: false,
      selectedAgentIndex: 0,
      agentsList: [],
      isLoading: true,
      currentUser: undefined,
      errorMessage: '',
      shouldLogin: false,
    };
  }

  async componentDidMount() {
    // TODO Need a validation check on routeData and kick user to registration/account if validation fails
    const routeData = await this.props.navigation.getRoute();
    this.profile = routeData.data.profile;

    if (routeData.data.agentsList && routeData.data.agentsList.length > 0) {
      this.setState(
        { agentsList: routeData.data.agentsList, currentUser: routeData.data.currentUser },
        () => this.loadOfficeData(routeData.data.agentsList),
      );
    } else {
      this.setState({
        agentsList: [],
        currentUser: routeData.data.currentUser,
        isLoading: false,
      });
    }
  }

  loadOfficeData = async (agents) => {
    const uniqueOffices = [];
    agents.forEach((agent) => {
      if (!uniqueOffices.some((office) => office.id === agent.officeID)) {
        uniqueOffices.push({ id: agent.officeID, type: agent.agentType });
      }
    });

    const allOfficeDetails = await OfficeApi.getOfficeList(uniqueOffices);

    const modifiedAgents = this.state.agentsList.map((agent) => {
      agent.officeDetails = allOfficeDetails?.offices?.find(
        (office) => office.id === agent.officeID,
      );
      return agent;
    });

    this.setState({ agentsList: modifiedAgents, isLoading: false });
  };

  handleUserSelection = (e) => {
    this.setState({
      selectedAgentIndex: e,
      errorMessage: '',
    });
  };

  continueAgentSelectHandler = async () => {
    this.clearError();
    const { selectedAgentIndex, currentUser } = this.state;
    const selectedAgent = this.state.agentsList[selectedAgentIndex];
    const { narID } = currentUser;

    let hasExistingAccount = false;

    // only check if the email of the agent is different from the login email
    const isSameEmail = selectedAgent.email.toUpperCase() === loggedInEmail().toUpperCase();
    // don't check empty email--allow user to register with theirs
    if (!isSameEmail && selectedAgent.email) {
      await ProfileApi.checkAccountExists(selectedAgent.email).then((resp) => {
        if (resp.status === HTTP_STATUS_OK) {
          hasExistingAccount = true;
          this.setState({ shouldLogin: true });
        }
      });
    }

    if (!hasExistingAccount) {
      const p = ProfileApi.createProfile({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: this.context.oidcUser.profile.email,
        roleIDType: currentUser.roleIDType,
        roleID: selectedAgent.realEstateAgentID,
        phoneNumber: currentUser.phoneNumber,
        phoneType: 'Office',
        narID,
        offices: [{ type: selectedAgent.agentType, id: selectedAgent.officeID }],
      });
      p.then((result) => {
        if (result.status === HTTP_STATUS_CONFLICT) {
          this.context.logout();
        } else if (result.status !== HTTP_STATUS_CREATED) {
          this.setError('An error has been encountered.  Please try again later');
          this.navigateOutOfRegistration();
        } else {
          localStorage.setItem(JUST_REGISTERED, 'true');
          this.navigateOutOfRegistration();
        }
      }).catch((ex) => {
        this.setError('The account could not be created due to a problem with the site.');
      });
    }
  };

  noneOfTheseBtnHandler = () => {
    this.setState({ isOfficeSearchActive: true });
  };

  closeModalHandler = () => {
    this.setState({ isOfficeSearchActive: false });
  };

  submitOfficeDrawerHandler = async (company: Office[]) => {
    const office = { type: officeTypeFullFormDictionary[company[0].type], id: company[0].id };
    const { currentUser } = this.state;
    const { narID } = currentUser;

    if (!this.profile.isIncomplete) {
      const response = await ProfileApi.createProfile({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: '',
        roleIDType: currentUser.roleIDType,
        roleID: '',
        phoneNumber: currentUser.phoneNumber,
        phoneType: 'Office',
        narID,
        offices: [office],
      });
      if (response.status === HTTP_STATUS_CREATED) {
        localStorage.setItem(JUST_REGISTERED, 'true');
        await this.navigateOutOfRegistration();
      } else if (response.status === HTTP_STATUS_CONFLICT) {
        this.setState({ isOfficeSearchActive: false });
        this.setError('An error has been encountered. Profile already exists.');
        await this.navigateOutOfRegistration();
      } else {
        this.setState({ isOfficeSearchActive: false });
        this.setError('An error has been encountered.  Please try again later');
      }
    } else {
      const updatedProfile = {
        ...this.profile,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        roleIDType: currentUser.roleIDType,
        phoneNumber: currentUser.phoneNumber,
        phoneType: 'Office',
        narID: currentUser.narID,
        email: null, // ARE-6882 Email should only be updated from the Edit My Account page.
      };
      const officeRequest = {
        profileID: this.profile.profileID,
        offices: [office],
      };

      const officeResponse = await ProfileApi.updateUsersOfficeDetails(officeRequest);
      if (officeResponse) {
        const profileResponse = await ProfileApi.updateUser(updatedProfile);
        if (!profileResponse.isAxiosError) {
          localStorage.setItem(JUST_REGISTERED, 'true');
          await this.navigateOutOfRegistration();
        } else {
          this.setState({ isOfficeSearchActive: false });
          this.setError('An error has been encountered.  Please try again later');
        }
      }
    }
  };

  navigateOutOfRegistration = async () => {
    if (DeepLinkService.GetDeepLinkString()) {
      await window.location.assign(DeepLinkService.GetDeepLinkString());
    } else {
      await window.location.assign(Path.Dashboard);
    }
  };

  proceedToLogin = () => {
    this.context.logout();
  };

  setError = (error: string) => {
    this.setState({
      errorMessage: error,
    });
  };

  clearError = () => {
    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const errorStyle = {
      color: 'red',
    };

    return (
      <>
        <LayoutCard hideHeader={true} hideFooter={true}>
          {this.state.isOfficeSearchActive ? (
            <OfficeSearch
              usaStates={usaStates}
              typeCode={officeTypeDictionary[this.state.currentUser.roleIDType]}
              excludeBrandSearch={false}
              isActive={this.state.isOfficeSearchActive}
              onClose={() => this.closeModalHandler}
              onSubmit={this.submitOfficeDrawerHandler}
              ignoreReadOnly={true}
            />
          ) : (
            <>
              <Template
                type={this.props.registrationType}
                handleUserSelectionChange={this.handleUserSelection}
                continueHandler={this.continueAgentSelectHandler}
                noneOfTheseBtnHandler={this.noneOfTheseBtnHandler}
                closeModalHandler={this.closeModalHandler}
                isExistingUser={this.state.shouldLogin}
                proceedToLogin={this.proceedToLogin}
                agentsList={this.state.agentsList}
                isLoading={this.state.isLoading}
              />
              <div style={errorStyle}>{this.state.errorMessage}</div>
            </>
          )}
        </LayoutCard>
      </>
    );

    return <Skeleton />;
  }
}

/*
added this functional component layer over class component to make use of hooks 
like useNavigation
*/
export default function (props) {
  const navigation = useNavigation();
  const route = useCurrentRoute();
  return <ExistingUser {...props} navigation={navigation} agentsList={route.data.agentsList} />;
}
