import { Address } from './address.model';

export enum OfficeType {
  RealEstate = 'RE',
  ClosingCompany = 'CC',
}

export interface Office {
  id?: string;
  name?: string;
  address?: Address;
  contact?: {
    phones?: {
      fax?: string;
      office?: string;
    };
  };
  details?: {
    compositeSalesRank: string;
    franchiseCode: string;
  };
  meta?: {
    type?: OfficeType;
  };
  type?: OfficeType;
}
