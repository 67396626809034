/** matches with what is in the app layer. */
export enum AppErrorCode {
  GetPropertyIDGeneralError = '20200',
}

/** enum containing lookup message text. Case sensitive. */
export enum LookupMessageText {
  EJBRollbackNoEntity = 'javax.ejb.EJBTransactionRolledbackException: No entity found for query',
}

/** Lookup error messages to be used by AppErrorMessageLookup.lookupError */
export const ERROR_LOOKUP = {
  CITY_TRANSACTION_ERROR: {
    code: AppErrorCode.GetPropertyIDGeneralError,
    partialMessageText: LookupMessageText.EJBRollbackNoEntity,
  } as AppErrorMessageLookup,
};

export interface AppErrorMessageLookup {
  code?: AppErrorCode;
  partialMessageText?: string;
}

/** IMPORTANT: to access public functions, use the constructor so public functions are made available */
export class AppErrorResponse {
  errors: ErrorMessage[];

  constructor(err: AppErrorResponse) {
    if (err) {
      this.errors = err.errors.map((msg) => new ErrorMessage(msg)); // instantiate error message to access public functions
    }
  }

  /** find first matching code. note, multiple would only return first. */
  public findByErrorCode(code: AppErrorCode): ErrorMessage {
    return this.errors.find((msg) => msg.hasCode(code));
  }

  /** if response has the error code */
  public hasErrorCode(code: AppErrorCode): boolean {
    return this.findByErrorCode(code) !== undefined;
  }

  /** for a given lookup, find a matching error message. any null/undefined lookup field will be skipped. At least one lookup is required. */
  public lookupError(lookup: AppErrorMessageLookup): ErrorMessage {
    return this.errors.find((msg) => {
      if (lookup.code && !msg.hasCode(lookup.code)) {
        return false;
      }
      if (lookup.partialMessageText && !msg.messageContains(lookup.partialMessageText)) {
        return false;
      }
      return true;
    });
  }
}

export class ErrorMessage {
  code: AppErrorCode;
  message: string;
  field: string;

  constructor(err: ErrorMessage) {
    if (err) {
      this.code = err.code;
      this.message = err.message;
      this.field = err.field;
    }
  }

  public hasCode(code: AppErrorCode): boolean {
    return this.code === code;
  }

  /** case sensitive */
  public messageContains(partialText: string): boolean {
    return this.message.includes(partialText);
  }
}
