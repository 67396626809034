const ExternalLinks = {
  AHS: {
    TermsOfUse: 'https://www.frontdoor.com/contractor-terms-of-use',
    privacyPolicy: 'https://www.frontdoor.com/contractor-privacy-policy',
  },
  HSA: {
    TermsOfUse: 'https://www.frontdoor.com/contractor-terms-of-use',
    privacyPolicy: 'https://www.frontdoor.com/contractor-privacy-policy',
  },
};

export default ExternalLinks;
