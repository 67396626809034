import React from 'react';
import Modal from '@components/modal/Modal';

interface Props {
  onClose: () => void;
  id: string;
  heading: string;
  isActive: boolean;
  message: string;
}

const ModalMessagePopup: React.FC<Props> = (props) => {
  return (
    <Modal id={props.id} isActive={props.isActive} onClose={props.onClose} heading={props.heading}>
      {props.isActive && props.message}
    </Modal>
  );
};

export default ModalMessagePopup;
