interface QueryParameters {
  [key: string]: string;
}

class QueryParamParser {
  private params: QueryParameters = {};
  init() {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.forEach((value, key) => {
        this.params[key] = value;
      });
    }
  }

  getParams() {
    return this.params;
  }

  /** Must be called by the consumer once it is completed using this service. Failure to do so will result in unwanted behavior. */
  reset() {
    this.params = {};
  }
}

const QueryParamParserService = new QueryParamParser();
export default QueryParamParserService;
