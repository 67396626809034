import { useWindowSize } from 'react-use';

/** @deprecated: this is not the correct breakpoints. need to follow BDS, not SF.
 * >> Really, use the responsive tailwind classes, not this thing. This isn't accurate at all.
 * BDS sizes here: node_modules/@ftdr/blueprint-core/src/css/base-tailwind.config.js */
export const breakpoints = {
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1200,
}

export function classNames(arr) {
    return arr.filter(Boolean).join(' ')
}

// TODO Use pattern below as with isSmallView; note that this doesn't check low end
export function isMobileView() {
    const { width } = useWindowSize();
    return width < breakpoints.lg
}

export function isViewInRange(lowEnd, highEnd = -1) {
    const { width } = useWindowSize();
    return width >= lowEnd && (width < highEnd || highEnd === -1);
}

export const isSmallView = () => isViewInRange(0, breakpoints.sm);
export const isMediumView = () => isViewInRange(0, breakpoints.md);
