import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@utils';

/** @deprecated: old SF usage. stop using it */
const Accordion = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (idx) => () => {
    setActiveItem(activeItem == idx ? null : idx);
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child, idx) => {
      return React.cloneElement(child, {
        onClick: handleClick(idx),
        isActive: child.props.isActive || activeItem === idx,
        mobileOnly: props.mobileOnly,
        className: classNames([child.props.className, idx !== 0 && 'mt-4']),
      });
    });
  };

  return <div className={classNames(['accordion', props.className || ''])}>{renderChildren()}</div>;
};

Accordion.propTypes = {
  // Props
  mobileOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    // TODO: There is some issue with hook order when including these prop types for some reason. The above is a small work around for now.
    // PropTypes.shape({
    //     type: AccordionItem,
    // }),
    // PropTypes.arrayOf(
    //     PropTypes.shape({
    //         type: AccordionItem,
    //     })
    // ),
  ]).isRequired,
};

Accordion.defaultProps = {
  mobileOnly: false,
  children: null,
};

export default Accordion;
