import React, { useState } from 'react';
import { useEvent } from 'react-use';
// Components
import TooltipAutocomplete from '@components/tooltip/TooltipAutocomplete';
import { IconFilter, IconSearch, Input } from '@ftdr/blueprint-components-react';

interface Props {
  // This isn't even used...
  id: string;
  onChange: (e) => void;
  results: any; // TODO: add correct typing
  className: string;
  // Also not used?
  inputLabel: string;
  filterText: string;
  placeHolder: string;
  showClearButton: boolean;
  idPrefix?: string;
  icon: IconTypes;
}

export enum IconTypes {
  Search,
  Filter,
}

const CardFilterBar: React.FC<Props> = (props) => {
  const [showAutocomplete, setShowAutocomplete] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // As per discussion with mallory we dont need tooltips for now
    // for more details please check comments in ARE-3775
    // setShowAutocomplete(e.target.value !== '')
    const { value } = e.target;
    props.onChange(value);
  };

  useEvent('keydown', (e) => {
    if (String(e.key) === 'Escape') {
      (document.activeElement as HTMLInputElement).blur(); // Unfocus input
      setShowAutocomplete(false); // Hide autocomplete
      props.onChange('');
    }
  });

  function getIcon() {
    switch (props.icon) {
      case IconTypes.Filter:
        return <IconFilter />;
      case IconTypes.Search:
        return <IconSearch />;
    }
  }

  return (
    <TooltipAutocomplete visible={showAutocomplete} results={props.results ? props.results : {}}>
      <div className="w-full">
        <Input
          label="Search Bar Filter"
          value={props.filterText}
          showClearButton={props.showClearButton}
          onChange={handleInputChange}
          placeholder={props.placeHolder}
          startEnhancer={getIcon()}
          id={`${props.idPrefix}search_input`}
          autoComplete="off"
        />
      </div>
    </TooltipAutocomplete>
  );
};

export default CardFilterBar;
