import React from 'react';
import './confirmAddress.css';
import { Button, Text } from '@ftdr/blueprint-components-react';
import { Address } from '@app/models';

export interface UnknownAddressModalContentProps {
  id: string;
  addressFromService: Address;
  addressFromInput: Address;
  onEditAddress: any;
  onUseCurrentAddress: any;
  onUseServiceAddress: any;
}

function addressToString(address1: string, address2: string): string {
  return address2 && address2.length > 0 ? `${address1}, ${address2}` : `${address1}`;
}

const ConfirmAddress: React.FC<UnknownAddressModalContentProps> = ({
  id = 'confirm-address',
  addressFromService,
  addressFromInput,
  onEditAddress,
  onUseCurrentAddress,
  onUseServiceAddress,
}) => {
  return (
    <div className="typo-address-confirmation-modal-content" id={`${id}--content`}>
      {addressFromService && (
        <>
          <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
            Did you mean:
          </p>

          <div className="flex flex-row items-center justify-between w-full">
            <div>
              <Text variant="caption">
                {addressToString(addressFromService.streetAddress, addressFromService.unit)}{' '}
              </Text>
              <Text variant="caption">
                {`${addressFromService.city}, ${addressFromService.state} ${addressFromService.zip}`}
              </Text>
            </div>
            <Button
              id="confirm-address__use-suggested-address-btn"
              label="Use"
              onClick={onUseServiceAddress}
              size="small"
              className="ml-4"
            />
          </div>
        </>
      )}

      {!addressFromService && (
        <>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
              We couldn’t find the address you entered.
            </p>
            <div className="flex-1">
              <Button
                id="confirm-address__edit-address-btn"
                label="Edit Address"
                onClick={onEditAddress}
                size="small"
                className="ml-4"
              />
            </div>
          </div>
        </>
      )}
      <hr className="horizontal-divider" />
      <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline mt-10">
        The address you entered:
      </p>
      <div className="flex flex-row items-center justify-between w-full">
        <div>
          <Text variant="caption">
            {addressToString(addressFromInput.streetAddress, addressFromInput.unit)}{' '}
          </Text>
          <Text variant="caption">
            {`${addressFromInput.city}, ${addressFromInput.state} ${addressFromInput.zip}`}
          </Text>
        </div>
        <Button
          id="confirm-address__use-given-address-btn"
          label="Use"
          variant="outlined"
          onClick={onUseCurrentAddress}
          size="small"
        />
      </div>
    </div>
  );
};

export default ConfirmAddress;
