import React, { useEffect, useState } from 'react';
import { Address } from '@app/models';
import FormNewOrderCustomerAddress from '@components/form/FormNewOrderCustomerAddress';
import AddressVerification, {
  AddressVerificationItem,
  AddressVerificationVerifiedItem,
} from '@components/misc/AddressVerification';
import { Button, Checkbox, Panel } from '@ftdr/blueprint-components-react';
import { deleteUndefinedValues } from '@helpers/utils';

const DemoAddressVerification: React.FC = (props) => {
  const [isAddressVerificationActive, setIsAddressVerificationActive] = useState(false);
  const [addressVerificationItems, setAddressVerificationItems] = useState<
    AddressVerificationItem[]
  >([]);
  const [disableUnverifiedAddressCreation, setDisableUnverifiedAddressCreation] = useState(false);
  const [showUserAddress2, setShowUserAddress2] = useState(true);

  const [userAddress, setUserAddress] = useState<Address>({
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    zipPlus4: '',
    meta: {
      addressId: '',
      source: '',
      verified: '',
    },
  });

  const [userAddress2, setUserAddress2] = useState<Address>({
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    zipPlus4: '',
    meta: {
      addressId: '',
      source: '',
      verified: '',
    },
  });

  const [error, setError] = useState({
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  });

  const [error2, setError2] = useState({
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setAddressVerificationItems(
      [
        {
          key: 'userAddress',
          address: userAddress,
        },
        showUserAddress2 && {
          key: 'userAddress2',
          address: userAddress2,
        },
      ].filter((item) => !!item),
    );
    setIsAddressVerificationActive(true);
  };

  const onVerifiedAddress = (verifiedItem: AddressVerificationVerifiedItem) => {
    switch (verifiedItem?.key) {
      case 'userAddress':
        setUserAddress(verifiedItem.verifiedAddress);
        break;
      case 'userAddress2':
        setUserAddress2(verifiedItem.verifiedAddress);
        break;
    }
  };

  const onResetAddressVerification = () => {
    setIsAddressVerificationActive(false);
    setAddressVerificationItems([]);
  };

  return (
    <>
      <div className="container mx-auto mt-10 flex flex-row">
        <Panel>
          <form onSubmit={onSubmit}>
            <Checkbox
              checked={disableUnverifiedAddressCreation}
              onChange={() =>
                setDisableUnverifiedAddressCreation(!disableUnverifiedAddressCreation)
              }
              label="Disable Unverified Address Creation"
            />
            <Checkbox
              checked={showUserAddress2}
              onChange={() => setShowUserAddress2(!showUserAddress2)}
              label="Show Second Address Form"
            />
            <hr />

            <div>
              <strong>User Address 1</strong>
            </div>
            <br />
            <FormNewOrderCustomerAddress
              shouldLoadZipDetails={true}
              onLoadZipDetails={(zipDetails) => {
                setUserAddress((prevState) => ({
                  ...prevState,
                  city: zipDetails?.city,
                  state: zipDetails?.state,
                }));
              }}
              ids={{
                address: 'input--address',
                unit: 'input--unit',
                city: 'input--city',
                state: 'input--state',
                zip: 'input--zip',
              }}
              names={{
                address: 'streetAddress',
                unit: 'unit',
                city: 'city',
                state: 'state',
                zip: 'zip',
              }}
              values={{
                address: userAddress.streetAddress,
                unit: userAddress.unit,
                city: userAddress.city,
                state: userAddress.state,
                zip: userAddress.zip,
              }}
              errors={{
                address: error.streetAddress,
                unit: error.unit,
                city: error.city,
                state: error.state,
                zip: error.zip,
              }}
              required={{
                address: false,
                unit: false,
                city: false,
                state: false,
                zip: false,
              }}
              onInputChange={(e) => {
                e && setUserAddress({ ...userAddress, [e.target.name]: e.target.value });
              }}
              onInputBlur={() => {}}
              stateDisabled={false}
              onStateSelect={(s) => setUserAddress({ ...userAddress, state: s?.value })}
              setAddress={(address) =>
                address &&
                setUserAddress({
                  ...userAddress,
                  ...deleteUndefinedValues(address),
                })
              }
              setStreetAddressError={(err) =>
                setError({
                  ...error,
                  streetAddress: err,
                })
              }
            />

            {showUserAddress2 && (
              <>
                <hr />
                <div>
                  <strong>User Address 2</strong>
                </div>
                <br />

                <FormNewOrderCustomerAddress
                  shouldLoadZipDetails={true}
                  onLoadZipDetails={(zipDetails) => {
                    setUserAddress2((prevState) => ({
                      ...prevState,
                      city: zipDetails?.city,
                      state: zipDetails?.state,
                    }));
                  }}
                  ids={{
                    address: 'input--address2',
                    unit: 'input--unit2',
                    city: 'input--city2',
                    state: 'input--state2',
                    zip: 'input--zip2',
                  }}
                  names={{
                    address: 'streetAddress',
                    unit: 'unit',
                    city: 'city',
                    state: 'state',
                    zip: 'zip',
                  }}
                  values={{
                    address: userAddress2.streetAddress,
                    unit: userAddress2.unit,
                    city: userAddress2.city,
                    state: userAddress2.state,
                    zip: userAddress2.zip,
                  }}
                  errors={{
                    address: error2.streetAddress,
                    unit: error2.unit,
                    city: error2.city,
                    state: error2.state,
                    zip: error2.zip,
                  }}
                  required={{
                    address: false,
                    unit: false,
                    city: false,
                    state: false,
                    zip: false,
                  }}
                  onInputChange={(e) => {
                    e && setUserAddress2({ ...userAddress2, [e.target.name]: e.target.value });
                  }}
                  onInputBlur={() => {}}
                  stateDisabled={false}
                  onStateSelect={(s) => setUserAddress2({ ...userAddress2, state: s?.value })}
                  setAddress={(address) =>
                    address &&
                    setUserAddress2({
                      ...userAddress2,
                      ...deleteUndefinedValues(address),
                    })
                  }
                  setStreetAddressError={(err) =>
                    setError2({
                      ...error,
                      streetAddress: err,
                    })
                  }
                />
              </>
            )}

            <Button label="Verify Address" type="submit" />
          </form>
        </Panel>

        <Panel>
          <div>
            <pre>
              {JSON.stringify({ userAddress1: userAddress, userAddress2: userAddress2 }, null, 2)}
            </pre>
          </div>
        </Panel>
      </div>

      <AddressVerification
        id="address-verification"
        isActive={isAddressVerificationActive}
        items={addressVerificationItems}
        onReset={onResetAddressVerification}
        onVerifiedAddress={onVerifiedAddress}
        disableUnverifiedAddressCreation={disableUnverifiedAddressCreation}
      />
    </>
  );
};

export default DemoAddressVerification;
