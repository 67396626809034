import React, { useContext } from 'react';
import ProfileContext from '../../context/ProfileContext/index';

// Components
import Skeleton from 'react-loading-skeleton';
import TooltipMenu from '@components/tooltip/TooltipMenu';
import { UserRoleType } from '@constants/dictionaries';
import { IconButton, IconTrash, Panel, Text } from '@ftdr/blueprint-components-react';

const CardOffice = (props) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Panel
      backgroundColor="white"
      borderT="2"
      borderColor="gray-100"
      shadow={true}
      padding="xl"
      className="flex flex-col h-80"
      id={props.id}
    >
      <div className="flex justify-between items-center">
        <>
          <Text id="office-name" variant="heading-06">
            {props.name}
          </Text>

          {/* WLK invite is the only menuLink, don't hide if another is added. Remove from menuLinks prop instead. */}
          {profile.roleIDType === UserRoleType.SiteAdmin && props.warrantyLinkEligible && (
            <div id="action_dropdown" className="ml-4" onClick={(e) => e.stopPropagation()}>
              <TooltipMenu menuItems={props.menuLinks} />
            </div>
          )}
        </>
      </div>

      <p id="office_address" className="md:mb-1">
        {props.isSkeleton ? <Skeleton width={200} height={16} /> : props.address.address1}

        <br />
        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          `${props.address.city}, ${props.address.state} ${props.address.zip}`
        )}
      </p>

      <p className="mb-1">
        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          <>
            Office: <a href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</a>
          </>
        )}
      </p>

      <p id="distributor_id" className="mb-1">
        {props.isSkeleton ? <Skeleton width={200} height={16} /> : <>Dist ID: {props.id}</>}
      </p>

      {profile.roleIDType === UserRoleType.SiteAdmin && props.warrantyLinkEligible && (
        <p className="md:mb-1 leading-tight">
          <span id="warrantyLink_eligible" className="font-bold">
            {' '}
            WarrantyLink Eligible{' '}
          </span>
          {props.warrantyLinkStatus && (
            <>
              <br />
              Status:{' '}
              <div id="status" className="inline">
                {props.warrantyLinkStatus}
              </div>
            </>
          )}
        </p>
      )}

      <div className="flex -mx-1 mt-auto">
        <IconButton
          label="Trash"
          size="small"
          variant="ghost"
          color="gray"
          icon={<IconTrash />}
          id="delete_office"
          onClick={() => alert('Trigger office delete modal.')}
        />
      </div>
    </Panel>
  );
};

export default CardOffice;
