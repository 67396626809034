import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  IconWarningTriangleFull,
  Input,
  MaskedInput,
  Select,
  SelectOption,
  Text,
} from '@ftdr/blueprint-components-react';
import InputSelector from '@components/input/InputSelector';
import InputWithValidation from '@components/input/InputWithValidation';
import ProfileContext from '@context/ProfileContext';
import PhoneInput, { validatePhoneInput } from '@components/input/PhoneInput';
import EmailInput, {
  EmailInputErrorMessages,
  validateEmailInput,
} from '@components/input/EmailInput';
import { QuoteSummary, QuoteSummarySelection } from '@components/misc/QuoteSummary';
import { PlansAndPricesFormData } from '@templates/misc/PlansAndPricesTemplate';
import { InitiatingOffice, SharePlanAndPriceContact } from '@apis/models/emailsender.api.model';
import AgentInfoInput from '@components/input/AgentInfoInput';
import { getFormInputErrorId, getFormSelectErrorId } from '@storybook/addon-links';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import AddressSuggestionInput from '@components/input/AddressSuggestionInput';
import { usStatesForBDSSelect } from '@constants/us-states';
import AddressApi, { toApiAddress } from '@apis/address.api';
import { Address } from '@app/models';
import ModalConfirmAddressEntered from '@components/modal/ModalConfirmAddressEntered';
import { isAppAddressMatch } from '@helpers/utils';
import { msgsWParams } from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import SentenceText from '@components/SentenceText';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { Quote } from '@apis/models/quote.api.model';
import {
  updateOfficesOnSave,
  updateWorkedWithOfficesOnSave,
} from '@services/helpers/profile.offices.helper';
import { isCCUser } from '@helpers/profile.utils';
import { CityStates } from '@apis/models';

const LABELS = {
  HEADING: 'Share Quote',
  FORM_AGENT_INFORMATION: 'Agent Information',
  FORM_QUESTION_RECIPIENT: 'Who are you sending this Quote to?',
  RECIPIENT_NAME_FIELD: 'Recipient Name',
  RECIPIENT_NAME_PLACEHOLDER: 'Enter first and last name',
  RECIPIENT_EMAIL_FIELD: 'Recipient Email Address',
  RECIPIENT_EMAIL_PLACEHOLDER: 'email@emailaddress.com',
  FORM_QUESTION_CONTACT: 'How can the recipient contact you?',
  CONTACT_EMAIL_FIELD: 'Email Me',
  CONTACT_EMAIL_PLACEHOLDER: 'rachel@yourrealestateagency.com',
  CONTACT_PHONE_FIELD: 'Call or Text Me',
  CONTACT_PHONE_PLACEHOLDER: '(555) 555-5555',
  ATTACH_BROCHURE_FIELD: 'Attach Pricing Brochure',
  SUBMIT_BUTTON: 'Email Quote',
  FORM_LABEL_INFO_OPTIONAL: 'optional',
  FORM_LABEL_INFO_UPDATE_IF_INCORRECT: 'update if incorrect',
};

const ERRORS = {
  RECIPIENT_NAME_REQUIRED: 'Recipient name required',
  RECIPIENT_EMAIL_REQUIRED: 'Recipient email required',
  RECIPIENT_EMAIL_INVALID: 'Recipient email format invalid',
  STREET_ADDRESS_REQUIRED: 'Street address required.',
  CITY_ADDRESS_REQUIRED: 'City required.',
};

enum ContactType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export type EmailQuoteCallFunc = (
  contact: SharePlanAndPriceContact,
  initiatingOffice: InitiatingOffice,
  addressId: string,
) => Promise<void>;

export interface PlanPriceShareQuoteProps {
  quote: QuoteSummarySelection;
  /** used by child to stop the loading indicator, regardless of its return */
  emailQuoteCall: EmailQuoteCallFunc;
  formData: PlansAndPricesFormData;
  selectedQuote?: Quote;
  cityOptions?: CityStates[];
}

const PlanPriceShareQuote: React.FC<PlanPriceShareQuoteProps> = (props) => {
  const { profile, setProfile } = useContext(ProfileContext);
  const [isREAgent, setIsREAgent] = useState(false);

  const [selectedContactType, setSelectedContactType] = useState<ContactType>(ContactType.PHONE);

  const [agentId, setAgentId] = useState<string>('');
  const [officeId, setOfficeId] = useState<string>('');
  const [recipientName, setRecipientName] = useState<string>('');
  const [recipientEmail, setRecipientEmail] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [attachBrochure, setAttachBrochure] = useState<boolean>(false);
  const [addressMatch, setAddressMatch] = useState<Address>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [showValidateAddressModal, setShowValidateAddressModal] = useState<boolean>(false);
  const [addressUUID, setAddressUUID] = useState<string>('');

  const [valueProps, setValueProps] = useState({});
  const [showAddressValidationNeededMessage, setShowAddressValidationNeededMessage] =
    useState<boolean>(false);
  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();

  const [formErrors, setFormErrors] = useState({
    recipientName: '',
    recipientEmail: '',
    contactEmail: '',
    contactPhone: '',
    officeId: '',
    agentId: '',
    streetAddress: '',
    city: '',
    state: '',
  });

  const [address, setAddress] = useState({
    city: props.selectedQuote?.property?.city,
    state: props.selectedQuote?.property?.state,
    street: props.selectedQuote?.property?.address1,
    unit: props.selectedQuote?.property?.address2?.trim(),
    zip: '',
  });

  const cityOptions = useMemo<SelectOption[]>(() => {
    return props.cityOptions?.map((cityState) => ({ value: cityState.city })) || [];
  }, [props.cityOptions]);

  const recipientEmailErrors: EmailInputErrorMessages = {
    errorRequired: ERRORS.RECIPIENT_EMAIL_REQUIRED,
    errorInvalidFormat: ERRORS.RECIPIENT_EMAIL_INVALID,
  };

  /** on load of drawer, auto-fill the user's email/phone */
  useEffect(() => {
    if (profile) {
      setContactEmail(profile.email);
      setContactPhone(profile.phoneNumber);
      updateContactType(profile.email, profile.phoneNumber);
      setIsREAgent(profile.roleIDType === 'RealEstateAgent');
    }
  }, [profile]);

  const updateContactType = (email, phoneNumber) => {
    if (email && !phoneNumber) {
      setSelectedContactType(ContactType.EMAIL);
    } else {
      setSelectedContactType(ContactType.PHONE);
    }
  };

  useEffect(() => {
    if (props?.selectedQuote?.offices && props?.selectedQuote?.offices?.length > 0) {
      const office = props?.selectedQuote?.offices[0];
      setAgentId(office.agentId);
      setOfficeId(office.officeId);
      setValueProps({
        agentId: office.agentId,
        officeId: office.officeId,
        initiatingOffice: office.officeName,
        agentOffice: {
          id: office.officeId,
          active: true,
          name: office.officeName,
        },
        office: {
          id: office.officeId,
          type: office.officeType,
        },
        initOfficeId: office.officeId,
        initAgentId: office.agentId,
        agentlist: [],
        AgentName: office.agentName,
        AgentId: office.agentId,
      });
    }
  }, [props?.selectedQuote?.offices]);

  useEffect(() => {
    setAddress({ ...address, state: '', city: '' });
    AddressApi.getZipDetails(props.formData.zipCode).then((res: Address) => {
      if (res) {
        const updatedValues = {
          ...address,
          state: res.state,
          city: res.city,
          zip: res.zip,
        };
        setAddress(updatedValues);
      }
    });
  }, [props.formData.zipCode]);

  const closeValidateAddressModal = () => setShowValidateAddressModal(false);

  const onChangeContactType = (contactType: ContactType) => {
    setSelectedContactType(contactType);
    // validate against the contact fields to reset errors
    setFormErrors({
      ...formErrors,
      contactEmail: validateContactEmail(contactEmail, contactType),
      contactPhone: validateContactPhone(contactPhone, contactType),
    });
  };

  const validateRecipientName = (value = recipientName): string => {
    if (value.length === 0) {
      return ERRORS.RECIPIENT_NAME_REQUIRED;
    }
  };

  const validateStreetAddress = (): string => {
    if (!address?.street?.length) {
      return ERRORS.STREET_ADDRESS_REQUIRED;
    }
  };

  const validateCityAddress = (city: string = address?.city): string => {
    if (!city.length) {
      return ERRORS.CITY_ADDRESS_REQUIRED;
    }
  };

  const validateContactEmail = (
    value = contactEmail,
    contactType = selectedContactType,
  ): string => {
    // only apply validation if field is selected
    const fieldSelected = contactType === ContactType.EMAIL;
    return fieldSelected ? validateEmailInput(value, true) : '';
  };

  const validateContactPhone = (
    value = contactPhone,
    contactType = selectedContactType,
  ): string => {
    // only apply validation if field is selected
    const fieldSelected = contactType === ContactType.PHONE;
    return fieldSelected ? validatePhoneInput(value, true) : '';
  };

  const hasFormErrors = (errors = formErrors) => {
    return Object.values(errors).some((e) => !!e);
  };

  /** returns true if have form error. If we don't want to set the form error, set the param to false. */
  const validateAndHasErrors = (shouldSetFormErrors = true): boolean => {
    const errors: typeof formErrors = {
      recipientName: validateRecipientName(),
      // TODO: if we need to validate the form again, should it be required for the consumer components to define the full validate function call for custom instead of passing in the props.errorMessages?
      recipientEmail: validateEmailInput(recipientEmail, true, recipientEmailErrors),
      contactPhone: validateContactPhone(),
      contactEmail: validateContactEmail(),
      officeId: '',
      agentId: '',
      streetAddress: validateStreetAddress(),
      city: validateCityAddress(),
      state: '',
    };

    if (shouldSetFormErrors) {
      setFormErrors(errors);
    }

    if (!isREAgent) {
      if (!officeId) {
        errors.officeId = 'Initiating office required';
      }
      if (!agentId) {
        errors.agentId = 'Initiating agent required';
      }
    }
    return hasFormErrors(errors);
  };

  const handleCityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const cityInput = e.currentTarget?.value || '';
    if (cityInput !== address.city) {
      setAddress({ ...address, city: e.target?.value });
      setAddressUUID('');
      setFormErrors({ ...formErrors, city: validateCityAddress(e.target?.value) });
    }
  };

  const handleCitySelect = (opt: SelectOption) => {
    const optValue = opt.value || '';
    if (optValue !== address.city) {
      setAddress({ ...address, city: optValue });
      setAddressUUID('');
      setFormErrors({ ...formErrors, city: validateCityAddress(optValue) });
    }
  };

  const useSuggestedAddress = (addressObj = undefined) => {
    setShowValidateAddressModal(false);
    setAddress({ ...addressObj, street: addressObj.streetAddress, city: addressObj.city });
    setFormErrors({ ...formErrors, city: '', streetAddress: '', state: '' });
    setAddressUUID(addressMatch?.meta?.addressId);
    setShowAddressValidationNeededMessage(false);
  };

  const useUserAddress = () => {
    setShowValidateAddressModal(false);
    useGlobalOverlaySpinner().showSpinner(true, 'saving address...');

    const unverifiedAddress: Address = {
      streetAddress: address.street,
      unit: address.unit,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    AddressApi.createUnverifiedAddress(toApiAddress(unverifiedAddress))
      .then((verifiedAddress) => {
        setShowValidateAddressModal(false);
        setAddressUUID(verifiedAddress?.meta?.addressId);
        setFormErrors({ ...formErrors, city: '', streetAddress: '', state: '' });
        addSuccessToQueue(msgsWParams.QUOTE_ADDRESS_VALIDATED_SUCCESSFULLLY());
        setShowAddressValidationNeededMessage(false);
      })
      .catch((err) => {
        addErrorToQueue(msgsWParams.QUOTE_ADDRESS_UUID_CREATION_FAILED());
      })
      .finally(() => {
        useGlobalOverlaySpinner().showSpinner(false);
      });
  };

  const validateAddress = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!validateAndHasErrors()) {
      if (addressUUID) {
        return onSubmit();
      }
      setShowAddressValidationNeededMessage(true);
    }
  };

  const onSubmit = () => {
    const contactData: SharePlanAndPriceContact = {
      contactMeUsing: selectedContactType,
      officeId,
      agentId,
      contactPhone: (selectedContactType === ContactType.PHONE && contactPhone) || '',
      contactEmail: (selectedContactType === ContactType.EMAIL && contactEmail) || '',
      attachBrochure,
      recipients: [
        {
          name: recipientName,
          email: recipientEmail,
        },
      ],
    };

    const initiatingOffice: InitiatingOffice = {
      officeID: officeId,
      agentID: agentId,
      represents: null,
    };

    setIsSubmitting(true);
    props
      .emailQuoteCall(contactData, initiatingOffice, addressUUID)
      .then(() => {
        if (isCCUser(profile) && officeId) {
          updateWorkedWithOfficesOnSave(profile, {
            active: true,
            address: { address1: '', address2: '', city: '', state: '', zip: '' },
            id: officeId,
            type: 'RealEstate',
            userExtensions: [],
            warrantyLinkEligible: false,
          });
        } else if (!isREAgent && officeId) {
          updateOfficesOnSave(
            profile,
            {
              active: true,
              address: { address1: '', address2: '', city: '', state: '', zip: '' },
              id: officeId,
              type: 'RE',
              userExtensions: [],
              warrantyLinkEligible: false,
            },
            setProfile,
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onChangeAddress = (newAddress) => {
    setAddress({ ...address, street: newAddress.streetAddress });
  };

  const changeValues = (initAgent, initOffice) => {
    setAgentId(initAgent);
    setOfficeId(initOffice);
    setValueProps({
      agentId: initAgent,
      officeId: initOffice,
    });
  };

  const changeContact = (email, phone) => {
    setContactEmail(email);
    setContactPhone(phone);
    updateContactType(email, phone);
  };

  const onAddressValidation = async () => {
    try {
      const propertyAddress = {
        streetAddress: address.street,
        unit: address.unit,
        city: address.city,
        state: address.state,
        zip: address.zip,
      };
      const res = await AddressApi.getAddressDetail(propertyAddress);

      if (res) {
        // If the addresses are same then no need to show modal/popup
        if (isAppAddressMatch(res, propertyAddress)) {
          setAddressUUID(res?.meta?.addressId);
          addSuccessToQueue(msgsWParams.QUOTE_ADDRESS_VALIDATED_SUCCESSFULLLY());
          setShowAddressValidationNeededMessage(false);
          return true;
        }
        setAddressMatch({ ...res, verified: true });
        setShowValidateAddressModal(true);

        return false;
      }

      setAddressMatch(null);
      setShowValidateAddressModal(true);
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return (
    <div className="full-height-card">
      <form>
        <Text id="share-quote--heading" variant="heading-02" color="primary" className="mb-8">
          {LABELS.HEADING}
        </Text>
        <div id="share-quote--form" className="flex flex-col space-y-4">
          {!isREAgent && (
            <div id="share-quote--agent" className="flex flex-col space-y-4 mb-4">
              <Text variant="heading-05" color="primary">
                {LABELS.FORM_AGENT_INFORMATION}
              </Text>
              <AgentInfoInput
                id="share-quote--recipient--name"
                profile={profile}
                values={valueProps}
                onChangeValues={(initAgent, initOffice) => changeValues(initAgent, initOffice)}
                officeError={formErrors.officeId}
                onOfficeError={() =>
                  setFormErrors({ ...formErrors, officeId: 'Initiating office required' })
                }
                onOfficeErrorClear={() => setFormErrors({ ...formErrors, officeId: '' })}
                agentError={formErrors.agentId}
                onAgentError={() =>
                  setFormErrors({ ...formErrors, agentId: 'Initiating agent required' })
                }
                onAgentErrorClear={() => setFormErrors({ ...formErrors, agentId: '' })}
                onSelectContactChange={changeContact}
                required={true}
                isReadOnly={!!props?.selectedQuote?.offices}
              />
            </div>
          )}
          <div id="share-quote--recipient" className="flex flex-col space-y-4 mb-4">
            <SentenceText variant="heading-05" color="primary">
              {LABELS.FORM_QUESTION_RECIPIENT}
            </SentenceText>
            <InputWithValidation
              required={true}
              formField={true}
              id="share-quote--recipient--name"
              label={LABELS.RECIPIENT_NAME_FIELD}
              placeholder={LABELS.RECIPIENT_NAME_PLACEHOLDER}
              autoComplete="off"
              value={recipientName}
              onChangeValue={setRecipientName}
              validateValue={validateRecipientName}
              error={formErrors.recipientName}
              onClearError={() => setFormErrors({ ...formErrors, recipientName: '' })}
              onError={(error) => setFormErrors({ ...formErrors, recipientName: error })}
            />
            <EmailInput
              required={true}
              formField={true}
              id="share-quote--recipient--email"
              label={LABELS.RECIPIENT_EMAIL_FIELD}
              placeholder={LABELS.RECIPIENT_EMAIL_PLACEHOLDER}
              value={recipientEmail}
              onChangeValue={setRecipientEmail}
              error={formErrors.recipientEmail}
              onClearError={() => setFormErrors({ ...formErrors, recipientEmail: '' })}
              onError={(error) => setFormErrors({ ...formErrors, recipientEmail: error })}
              errorMessages={recipientEmailErrors}
            />
          </div>
          <div id="share-quote--recipient" className="flex flex-col space-y-4 mb-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full sm:w-1/4 md:w-1/4 px-4 py-2">
                <MaskedInput
                  formField={true}
                  id="wb_zipCodePropertyAddress"
                  name="zip"
                  inputMode="numeric"
                  label={ORDER_FORM_FIELDS.PROPERTY_ZIP}
                  value={props.formData.zipCode}
                  error={null}
                  autoComplete="off"
                  maxLength={5}
                  disabled={true}
                  mask="00000"
                />
              </div>

              <div className="w-full sm-max:hidden md:w-3/4" />

              <div className="flex flex-wrap w-full sm:w-3/4 md:w-1/2">
                <div className="w-full sm:w-3/4 px-4 py-2 field-position">
                  <AddressSuggestionInput
                    id="wb_streetAddressPropertyAddress"
                    formFieldMessageId={getFormInputErrorId('wb_streetAddressPropertyAddress')}
                    name="streetAddress"
                    label={ORDER_FORM_FIELDS.PROPERTY_ADDRESS}
                    addressLookup={{
                      city: address.city,
                      state: address.state,
                      zip: address.zip,
                    }}
                    allowPOBox={false}
                    value={address.street}
                    error={formErrors.streetAddress}
                    setValue={(value) => onChangeAddress(value)}
                    setStreetAddressValue={(value) => {
                      setAddress({ ...address, street: value });
                      setAddressUUID('');
                    }}
                    setError={(err) => setFormErrors({ ...formErrors, streetAddress: err })}
                  />
                </div>

                <div className="w-full sm:w-1/4 px-4 py-2">
                  <Input
                    formField={true}
                    formFieldMessageId={getFormInputErrorId('wb_propertyAddressUnit')}
                    id="wb_propertyAddressUnit"
                    name="unit"
                    label={ORDER_FORM_FIELDS.PROPERTY_UNITS}
                    value={address.unit || ''}
                    onBlur={null}
                    autoComplete="off"
                    onChange={(event) => {
                      setAddress({ ...address, unit: event.target.value });
                      setAddressUUID('');
                    }}
                  />
                </div>
              </div>

              <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
                <Select
                  required={true}
                  formField={true}
                  autoComplete={(address.city ?? '') === ''}
                  autoCompleteFilterPredicate={() => true}
                  id="wb_propertyAddressCity"
                  name="city"
                  label={ORDER_FORM_FIELDS.PROPERTY_CITY}
                  options={cityOptions}
                  selected={{ value: address?.city ?? '' }}
                  onBlur={handleCityBlur}
                  onSelect={handleCitySelect}
                  error={formErrors.city}
                />
              </div>

              <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
                <Select
                  formField={true}
                  formFieldMessageId={getFormSelectErrorId('wb_propertyAddressState')}
                  id="wb_propertyAddressState"
                  name="state"
                  label={ORDER_FORM_FIELDS.PROPERTY_STATE}
                  required={true}
                  options={usStatesForBDSSelect}
                  selected={
                    address.state
                      ? usStatesForBDSSelect.find((o) => o.value === address.state)
                      : null
                  }
                  onSelect={(event) => {
                    setAddress({ ...address, state: event.target.value });
                  }}
                  disabled={true}
                />
              </div>
            </div>

            <div className="text-left sm-max:mt-4 md:ml-0">
              <Button
                id="save-quote--actions--validate-address"
                className="mt-4 md:mt-0"
                label="Validate Address"
                size="small"
                disabled={!address.street || addressUUID.length > 0}
                onClick={onAddressValidation}
              />
              {showAddressValidationNeededMessage && (
                <div id="share-quote--address-validation--error">
                  <div className="flex mt-1">
                    <IconWarningTriangleFull size={15} color="secondary" />
                    <Text variant="helper-text" color="secondary" className="ml-1">
                      Validate Address
                    </Text>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div id="share-quote--contact" className="flex flex-col space-y-4 mb-4">
            <SentenceText variant="heading-05" color="primary">
              {LABELS.FORM_QUESTION_CONTACT}
            </SentenceText>
            <InputSelector
              selected={selectedContactType === ContactType.EMAIL}
              onSelect={() => onChangeContactType(ContactType.EMAIL)}
            >
              <EmailInput
                formField={true}
                id="share-quote--contact--email"
                label={LABELS.CONTACT_EMAIL_FIELD}
                placeholder={LABELS.CONTACT_EMAIL_PLACEHOLDER}
                labelInfo={LABELS.FORM_LABEL_INFO_UPDATE_IF_INCORRECT}
                value={contactEmail}
                onChangeValue={setContactEmail}
                validateValue={validateContactEmail}
                error={formErrors.contactEmail}
                onClearError={() => setFormErrors({ ...formErrors, contactEmail: '' })}
                onError={(error) => setFormErrors({ ...formErrors, contactEmail: error })}
              />
            </InputSelector>
            <InputSelector
              selected={selectedContactType === ContactType.PHONE}
              onSelect={() => onChangeContactType(ContactType.PHONE)}
            >
              <PhoneInput
                formField={true}
                id="share-quote--contact--phone"
                label={LABELS.CONTACT_PHONE_FIELD}
                placeholder={LABELS.CONTACT_PHONE_PLACEHOLDER}
                labelInfo={LABELS.FORM_LABEL_INFO_UPDATE_IF_INCORRECT}
                value={contactPhone}
                onChangeValue={setContactPhone}
                validateValue={validateContactPhone}
                error={formErrors.contactPhone}
                onClearError={() => setFormErrors({ ...formErrors, contactPhone: '' })}
                onError={(error) => setFormErrors({ ...formErrors, contactPhone: error })}
              />
            </InputSelector>
          </div>
        </div>
        <div className="border border-gray-300 rounded-3 shadow-1 w-full py-5 px-6 mt-8 mb-4">
          <QuoteSummary
            residenceType={props.formData.residenceType}
            sellersCoverage={props.formData.includeSellersCoverage}
            zipCode={props.formData.zipCode}
            quoteSummary={props.quote}
          />
        </div>
        <div id="share-quote--actions" className="flex flex-col md:flex-row justify-between">
          <Checkbox
            id="share-quote--actions--attach-brochure"
            label={LABELS.ATTACH_BROCHURE_FIELD}
            checked={attachBrochure}
            onChange={() => setAttachBrochure(!attachBrochure)}
          />
          <div>
            <Button
              id="share-quote--actions--submit"
              type="submit"
              size="medium"
              width="full"
              className="mt-4 md:mt-0"
              label={LABELS.SUBMIT_BUTTON}
              onClick={validateAddress}
              loading={isSubmitting}
            />
          </div>
        </div>
      </form>

      <ModalConfirmAddressEntered
        id="share-quote__address-confirmation-modal"
        heading="Property Address Verification"
        isActive={showValidateAddressModal}
        onClose={closeValidateAddressModal}
        addressFromService={addressMatch}
        addressFromInput={{
          verified: false,
          streetAddress: address.street,
          unit: address.unit,
          city: address.city,
          state: address.state,
          zip: props.formData.zipCode,
        }}
        onEditAddress={closeValidateAddressModal}
        onUseServiceAddress={() => useSuggestedAddress(addressMatch)}
        onUseCurrentAddress={() => useUserAddress()}
      />
    </div>
  );
};

export default PlanPriceShareQuote;
