import { addDays } from 'date-fns';
import { formattedDate, toCurrency } from '@helpers/utils';

import getBrandConstants from './brands';
import Path from '@constants/paths';

export const DEFAULT_CUSTOMER_PHONE_TYPE = 'CELL';

/* Form field rules */
export const PASSWORD_MIN_LENGTH = 8;
export const MINIMUM_ALLOWED_PARTIAL_PAYMENT = 1.0;

export const PO_BOX_PATTERN =
  /^(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?)/;
export const PO_BOX_ACCEPTED_PATTERNS = ['PO Box', 'P.O. Box'];

/** regex standard by W3C, which should match with HTML5.  If you change this constant, consider if the back end email validation needs to be modified. */
export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
/** Used by ValidationRules::validateEmail.  Used in conjunction with the other email patterns. */
export const BASE_EMAIL_PATTERN = /^[a-zA-Z0-9]\S+@\S+\.\S+$/;
export const EMAIL_DISALLOWED_SPECIAL_CHARACTERS = [
  // ARE-5568 >>>  " ( ) , : ; < > @ [ \ ]
  '"',
  '(',
  ')',
  ',',
  ':',
  ';',
  '<',
  '>',
  '[',
  '\\',
  ']',
  // ARE-5629 >>> / { } + = & ^ % $ # ! ~ ` ' ? * |
  '/',
  '{',
  '}',
  '+',
  '=',
  '&',
  '^',
  '%',
  '$',
  '#',
  '!',
  '~',
  '`',
  "'",
  '?',
  '*',
  '|',
];

export const PHONE_NUMBER_PATTERN = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
export const USA_PHONE_FORMAT = '($1) $2-$3';
export const NAR_PATTERN = /^(\d{8}|\d{9})$/;
export const ONLY_NUMBER_PATTERN = /^[0-9]+$/;
export const ZIP_PATTERN = /^[0-9]{5}/;

export const FIRST_NAME_MAX_LENGTH = 20;
export const LAST_NAME_MAX_LENGTH = 17;
export const ESCROW_NUMBER_MAX_LENGTH = 40;
export const MLS_NUMBER_MAX_LENGTH = 50;

/** Mask assumed as '(XXX) XXX-XXXX' format */
export const PHONE_WITH_MASK_MAX_LENGTH = 14;
/** WARNING, double check that you meant the mask format instead. */
export const PHONE_MAX_LENGTH = 10;

/** returns min and max for closing date range.
 * closing date in the past 90 days rule applies for new order flow only & closing date should not to be before the listing entry date (edit order flow). **/
export const CLOSING_DATE_RANGE = (listingStartDate) => {
  let min, max;
  if (listingStartDate) {
    min = addDays(listingStartDate, 0);
    max = addDays(listingStartDate, 365);
  } else {
    min = addDays(new Date(), -90);
    max = addDays(new Date(), 365);
  }
  return { min, max };
};

/** minimum date allowed for abandoned order date filter selection */
export const MIN_ABANDONED_ORDER_DATE_RANGE = addDays(new Date(), -14);
export const MAX_CVG_QTY_ALLOWED = 25;

/* Form field error messages */
export const DISTRIBUTOR_ID_REQUIRED = 'Distributor ID is required';
export const EMAIL_REQUIRED = 'Email is required.';
export const PAYMENT_MIN_AMOUNT_ERROR = `Minimum payment amount is ${toCurrency(MINIMUM_ALLOWED_PARTIAL_PAYMENT)}`;
export const PASSWORD_REQUIRED = 'Password is required.';
export const INVALID_EMAIL = 'Valid email required.';
export const INVALID_EMAIL_VERBOSE = 'Enter a valid email address in format user@example.com.';
export const ISSUE_CATEGORY_REQUIRED = 'An issue category is required.';
export const PASSWORD_MIN_LENGTH_REQUIRED = 'Password must be at least 8 characters.';
export const PASSWORD_MATCH = 'Passwords must match.';
export const FIRST_NAME_REQUIRED = 'First name required.';
export const FIRST_NAME_LENGTH_EXCEEDED = `Please shorten name to ${FIRST_NAME_MAX_LENGTH} characters`;
export const CUSTOMER_FIRST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED =
  "Must be 1-20 characters. Valid character include a-z, A-Z, 0-9,_,-,.,',+ and space";
export const LAST_NAME_REQUIRED = 'Last name required.';
export const LAST_NAME_LENGTH_EXCEEDED = `Please shorten name to ${LAST_NAME_MAX_LENGTH} characters`;
export const CUSTOMER_LAST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED =
  "Must be 1-17 characters. Valid character include a-z, A-Z, 0-9,_,-,.,',+ and space";
export const PHONE_NUMBER_REQUIRED = 'Phone number required.';
export const INVALID_PHONE_NUMBER = 'Valid phone number required.';
export const OFFICE_NAME_REQUIRED = 'Office name required.';
export const STREET_ADDRESS_REQUIRED = 'Street address required.';
export const STREET_ADDRESS_MISSING_NUMBER = 'Street number required.';
export const STREET_ADDRESS_NUMBER_MISSING = 'Please enter a street number and name.';
export const STREET_ADDRESS_MISSING_NAME = 'Street name required.';
export const STREET_ADDRESS_PO_BOX_NOT_ACCEPTABLE = 'PO Box cannot be used';
export const STREET_ADDRESS_PO_BOX_INCOMPLETE = 'This is not a PO Box';
export const STREET_ADDRESS_PO_BOX_INVALID =
  'PO Box format invalid. Please correct (Ex: PO Box 555).';
export const STREET_ADDRESS_PO_BOX_NUMBER_MISSING = 'Please enter the PO Box number';
export const CITY_REQUIRED = 'City required.';
export const STATE_REQUIRED = 'State required.';
export const ZIP_REQUIRED = 'Zip code required.';
export const ACCOUNT_TYPE_REQUIRED = 'Account type required.';
export const PROJECTED_CLOSING_DATE = 'Closing date is required.';

export const PROJECTED_CLOSING_DATE_OUT_OF_RANGE = (date) => {
  return `Closing date must be between ${formattedDate(CLOSING_DATE_RANGE(date).min)} and ${formattedDate(CLOSING_DATE_RANGE(date).max)}`;
};

export const EMAIL_OR_PHONE_REQUIRED = 'Email or phone is required.';
export const PHONE_OR_EMAIL_REQUIRED = 'Phone or Email required.';

export const BUYER_SELF_EMAIL_MATCHING = `Buyer's email cannot be same as your email`;
export const BUYER_SELF_PHONE_MATCHING = `Buyer's phone cannot be same as your phone`;
export const BUYER_SELF_OR_AGENT_EMAIL_MATCHING = `Buyer's email cannot be same as your email or Agent's email`;
export const BUYER_SELF_OR_AGENT_PHONE_MATCHING = `Buyer's phone cannot be same as your phone or Agent's phone`;

export const SELLER_SELF_EMAIL_MATCHING = `Seller's email cannot be same as your email`;
export const SELLER_SELF_PHONE_MATCHING = `Seller's phone cannot be same as your phone`;
export const SELLER_SELF_OR_AGENT_EMAIL_MATCHING = `Seller's email cannot be same as your email or Agent's email`;
export const SELLER_SELF_OR_AGENT_PHONE_MATCHING = `Seller's phone cannot be same as your phone or Agent's phone`;

export const INVALID_ZIP_FOR_OFFICE = 'There were no Offices found in this zip code.';
export const INVALID_NAR_ID = 'Invalid NAR ID.';
export const INVALID_CITY_STATE_FOR_OFFICE = 'There were no Offices found in this City and State.';
export const INVALID_DISTRIBUTOR_ID_FOR_OFFICE = 'There were no offices with this Distributor ID.';
export const INVALID_PHONE_FOR_OFFICE = 'There were no Offices found with this phone.';
export const INITIATING_OFFICE_REQUIRED = 'Initiating office required.';
export const INITIATING_AGENT_REQUIRED = 'Initiating agent required.';
export const AGENT_REPRESENTS_REQUIRED = 'Selection required.';
export const BUYER_RADIO_REQUIRED = 'Selection required.';
export const SELLER_RADIO_REQUIRED = 'Selection required.';
export const NEW_CONSTRUCTION_REQUIRED = 'Selection required.';
export const SQUARE_FOOTAGE_REQUIRED = 'Square footage is required.';
export const RESIDENT_TYPE_REQUIRED = 'Type of residence is required.';
export const MALFORMED_ZIP_CODE = 'Zip code must be 5 digits.';
export const INVALID_ZIP_CODE = 'Enter a valid zip code.';
export const REASON_REQUIRED = 'Reason is required.';
export const REASON_CANCELLING_REQUIRED = 'Reason for cancelling is required.';
export const REASON_DIFFERENT_PROVIDER_REQUIRED =
  'Reason for choosing different provider is required';
export const COMPANY_NAME_REQUIRED = 'Company name is required.';
export const COOPERATING_AGENT_REQUIRED = 'Cooperating agent is required.';
export const FULL_NAME_REQUIRED = 'Enter a first and last name.';

/* forget password internal email check */
export const INTERNAL_EMAIL_DOMAIN = [
  'ahs.com',
  'onlinehsa.net',
  'onlinehsa.com',
  'frontdoorhome.com',
  'frontdoor.com',
];

// TODO: These constants might be better off used on the components themselves
// In order to allow enhancing the message to have href to email and phone (similar to 'OFFICE_NOT_IN_NETWORK').
// Alternatively, the messages would contain HTML tags themselves (similar to CardOfficeInfo for siteSupportEmail).
// Explore the alternatives later; for now, apply the constants directly to the existing messages.
const AHS_CONSTANTS = getBrandConstants('AHS');
const HSA_CONSTANTS = getBrandConstants('HSA');

export const INTERNAL_EMAIL_ERROR =
  'We are unable to reset your password.  Please contact MyIT for assistance.';
export const INTERNAL_REGISTRATION_ERROR = `There has been a system error. Please try again. If issue persists, please contact ${getBrandConstants().supportEmail}.`;
export const EXISTING_USER_WARNING =
  'The selected user already has an account. Try logging in with the email registered to this user. Contact our support team.';
export const EXISTING_PROFILE_WARNING =
  'Account already exists with this name and phone number. Contact our support team.';
export const PLACE_ORDER_FAIL_MSG = `Unable to place order. Please try again or call ${getBrandConstants().supportPhone}.`;
export const PLAN_SELECTION_REQUIRED =
  'Plan selection is required in order to proceed. Please select a plan.';
export const SITE_SUPPORT_CONTACT = 'For site support, contact our ';
export const ORDER_DETAILS_ACCESS_FAILURE =
  'We are sorry, but you are not listed as the initiating, cooperating, or title/closing agent on this contract. If this is in error, please contact our support team.';
export const PROFILE_ERROR = 'We encountered an error loading your profile. Contact our ';
export const ACCOUNT_ERROR =
  'We apologize for the inconvenience, but an error has occurred with your account, Contact our ';

/* Message Display To change Account Type */
export const ACCOUNT_TYPE_CHANGE = 'To change your account type, contact our ';
export const CANT_FIND_OFFICE_AHS = `Your office may not be in our system. Contact ${AHS_CONSTANTS.supportPhone} to have your office be added to our system.`;
export const CANT_FIND_OFFICE_HSA = `Your office may not be in our system. Contact ${HSA_CONSTANTS.supportPhone} to have your office be added to our system.`;
export const TOO_MANY_OFFICES = 'Please select an office to view orders.';
export const INITIATING_COOPERATING_OFFICE_MATCH =
  'Agent has been selected as Initiating Agent.  Please select Initiating Agent as representing Both.';

/* tooltip */
export const SELLERS_COVERAGE =
  "Protect your budget when a home inspection reveals expensive surprises. Seller's coverage is limited to $1,500 on  covered repairs and replacements during this listing period.";
export const WARRANTY_TERM_LENGTH =
  'Select your desired coverage length. Save money on the second year of your home service plan.';
export const AC_COVERAGE = 'A/C Coverage offers peace of mind and budget protection.';
export const PASSWORD_SUCCESS_MESSAGE = 'Password update successful.';
export const OFFICE_REMOVED = 'Office has been removed.';
export const OFFICE_UPDATED = 'Office successfully added to your account.';
export const OFFICE_UPDATED_ERROR = 'Unable to update office. Please try again.';
export const OFFICE_ALREADY_ON_ACCOUNT = 'Office already on account.';
export const OFFICE_NOT_IN_NETWORK = `This office is not in your profile office's network. Please contact <a className="underline" href=${Path.ContactUs}>support team</a> to add this office to your profile.`;
export const MAKE_PAYMENT_FAILED = `Your payment was unsuccessful. Please resubmit your payment or call ${getBrandConstants().supportPhone}`;
export const FILTER_BY_NUMBER_NAME_ADDRESS = 'Filter by order number, name, or address...';
export const FILTER_BY_NUMBER_ADDRESS = 'Filter by order number or address...';

export const MOBILE_PHONE_NUMBER_AGENT =
  'We need Agent’s mobile number to communicate order related information';
export const FILTER_BY_QUOTE_NUMBER_NAME_ADDRESS = 'Filter by Quote number or Address';
export const FUZZY_MATCH_INITIATING_AGENT_MODAL_HEADER = 'Matching Agents Found';
export const FUZZY_MATCH_INITIATING_AGENT_MODAL_SUBHEADER =
  'We found existing agent(s) that appear to match your desired agent.  If you select one, we will add them to your selected office and to your order.';
