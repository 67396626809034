import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSection,
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
  Notification,
  Pagination,
} from '@ftdr/blueprint-components-react';
import PanelContractSummary from '@components/panel/PanelContractSummary';
import AccordionItemClosingInfo from '@components/accordion/AccordionItemClosingInfo';
import { GlobalSearchRestrictions, REOrderSearchRequest } from '@apis/models';
import _RealEstateOrderApi from '@apis/realestateorder.api';
import { isMobileView } from '@utils';
import { ITEMS_PER_PAGE_ACTION } from '@constants/dashBoard-constants';
import DashboardActionSwitcher, {
  DashboardActionSearchType,
} from '@components/drawer/subcomponents/DashboardActionSwitcher';
import DashboardActionAdvancedSearch from '@components/drawer/subcomponents/DashboardActionAdvancedSearch';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import { ContractSearchBarHeaderContentRendererFunc } from '@components/drawer/subcomponents/ContractSearchBar';
import { SECOND } from '@helpers/utils';
import msgs from '@app/locales/en';

const RealEstateOrderApi = _RealEstateOrderApi.new({
  suppressAllErrorNotifications: true,
});

const OrdersMissingCOE = (props) => {
  const [hasFetchedContractData, setHasFetchedContractData] = useState(false);
  const [pageContractData, setPageContractData] = useState([]);
  const [totalContracts, setTotalContracts] = useState(props.initialTotalContracts);
  const [activePage, setActivePage] = useState(1);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState([]);
  const [lastCompletedContractId, setLastCompletedContractId] = useState(undefined);
  const [lastCompletedIsSuccess, setLastCompletedIsSuccess] = useState(undefined);
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [errorMessageActive, setErrorMessageActive] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<DashboardActionSearchType>(
    DashboardActionSearchType.ShowAll,
  );

  const isMobile = isMobileView();

  const contractHeaderRenderer =
    (contract, advancedSearchText = '') =>
    (renderProps) => {
      const toggleAction = async () => {
        setAccordionActiveIndex(renderProps.active ? [] : [renderProps.index]);
      };

      return (
        <PanelContractSummary
          key={renderProps.key}
          contract={contract}
          idPrefix="order-missing-coe"
          actions={[
            <IconButton
              key="action"
              label=""
              icon={renderProps.active ? <IconNavArrowUp /> : <IconNavArrowDown />}
              variant="ghost"
              size={isMobile ? 'small' : 'medium'}
              onClick={toggleAction}
            />,
          ]}
          onClickContract={toggleAction}
          showExpiryDate={false}
          query={advancedSearchText}
        />
      );
    };

  /** renders the orders into an accordion with action. Shared display. */
  const renderREOrderResults: ContractSearchBarHeaderContentRendererFunc = (
    orders,
    searchState,
  ) => (
    <Accordion
      mode="single"
      id="accordion-orders-missing-coe"
      activeIndex={accordionActiveIndex}
      onActivate={(e, ais) => setAccordionActiveIndex(ais)}
      separatorClassName="hidden"
    >
      {orders.map((contract) => (
        <AccordionSection
          className="w-full"
          headerRenderer={contractHeaderRenderer(contract, searchState?.searchTerm)}
          label=""
          key={contract.id}
        >
          <AccordionItemClosingInfo
            contract={contract}
            onCompleteItem={handleCompleteItem}
            onCancel={() => setAccordionActiveIndex([])}
          />
        </AccordionSection>
      ))}
    </Accordion>
  );

  const handleCompleteItem = (contractId, isSuccess) => {
    setHasFetchedContractData(false);
    setActivePage(Math.min(activePage, Math.floor(totalContracts / ITEMS_PER_PAGE_ACTION) + 1));
    setAccordionActiveIndex([]);
    setLastCompletedContractId(contractId);
    setLastCompletedIsSuccess(isSuccess);
    setIsNotificationActive(true);
  };

  const fetchContractData = async () => {
    const request = new REOrderSearchRequest(ITEMS_PER_PAGE_ACTION);
    request.meta.page = activePage - 1;
    request.data.order.statusListingMissingCOEOnly = true;

    const response = await RealEstateOrderApi.searchProfileContracts(request);
    if (!response) {
      setErrorMessage(msgs.DASHBOARD_ACTION_CONTRACT_LOAD_ERROR_GENERIC.message);
      setErrorMessageActive(true);
      return;
    }

    setPageContractData(response.orders);

    setTotalContracts(response.meta.totalMissingCOE);
    props.onUpdateOrderCount(response.meta.totalMissingCOE);

    setHasFetchedContractData(true);
  };

  useEffect(() => {
    setAccordionActiveIndex([]);
    setHasFetchedContractData(false);
  }, [activePage]);

  useEffect(() => {
    if (!hasFetchedContractData) {
      fetchContractData();
    }
  }, [hasFetchedContractData]);

  const changeSearchType = (newSearchType: DashboardActionSearchType) => {
    // only when view changes, we want to refresh the state
    if (newSearchType !== searchType) {
      setAccordionActiveIndex([]); // reset selections
      setSearchType(newSearchType);
    }
  };

  return (
    <div className="full-height-card">
      <DashboardActionSwitcher
        id="drawer-orders-missing-coe"
        heading="Add Closing Dates"
        totalContractsCount={totalContracts}
        includeAdvancedSearch={true}
        searchType={searchType}
        onChangeSearchType={changeSearchType}
        contentRenderer={(searchType) => (
          <>
            {isNotificationActive && (
              <Notification
                inline={true}
                status={lastCompletedIsSuccess ? 'success' : 'error'}
                className="max-w-full mb-4"
                autoCloseDelay={5 * SECOND}
                onClose={() => setIsNotificationActive(false)}
                // TODO Implement view order link later
                /* actions={<Button className="ml-6" label="View Order" variant="ghost" size="small" />} */
              >
                {lastCompletedIsSuccess ? (
                  <span>
                    Order {lastCompletedContractId} has been updated to include a closing date and
                    buyer information.
                  </span>
                ) : (
                  <span>
                    Uh oh, there was a problem updating the closing information. Please try again.
                  </span>
                )}
              </Notification>
            )}

            {errorMessageActive && (
              <Notification
                inline={true}
                status="error"
                className="max-w-full mb-4"
                autoCloseDelay={5 * SECOND}
                onClose={() => {
                  setErrorMessageActive(false);
                  setErrorMessage(undefined);
                }}
              >
                {errorMessage}
              </Notification>
            )}

            {searchType === DashboardActionSearchType.ShowAll && (
              <>
                {hasFetchedContractData ? (
                  renderREOrderResults(pageContractData)
                ) : (
                  <LoadingIndicator />
                )}
                <Pagination
                  onItemsPerPageChange={null}
                  itemsPerPage={ITEMS_PER_PAGE_ACTION}
                  itemsPerPageOptions={[{ value: '10', label: '10' }]}
                  onPageChange={(page) => setActivePage(page)}
                  totalItems={totalContracts}
                  page={activePage}
                  hideViewAll={true}
                />
              </>
            )}

            {searchType === DashboardActionSearchType.Advanced && (
              <DashboardActionAdvancedSearch
                subtitle="Search for an order number or address"
                globalSearchRestrictions={[GlobalSearchRestrictions.MissingCOEOnly]}
                searchedContractsRenderer={renderREOrderResults}
                onSearchTextChange={(searchText) => setIsNotificationActive(false)}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default OrdersMissingCOE;
