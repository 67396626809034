import React from 'react';
import Drawer from '@components/drawer/Drawer';
import WLKVerification from '@components/drawer/subcomponents/WLKVerification';
import { isMobileView } from '@utils';

interface DrawerOrdersMissingCOEProps {
  isActive: boolean;
  onUpdateOrderCount: any;
  onClose: any;
  initialTotalContracts: number;
  canEditWLKContracts: boolean;
}

const DrawerOrdersWLKVerification = (props: DrawerOrdersMissingCOEProps) => {
  const isMobile = isMobileView();

  return (
    <Drawer
      isActive={props.isActive}
      onClose={props.onClose}
      useDefaultWidth={false}
      className="max-w-256 w-256"
    >
      {props.isActive && (
        <WLKVerification
          onUpdateOrderCount={props.onUpdateOrderCount}
          initialTotalContracts={props.initialTotalContracts}
          isMobile={isMobile}
          canEditWLKContracts={props.canEditWLKContracts}
        />
      )}
    </Drawer>
  );
};

export default DrawerOrdersWLKVerification;
