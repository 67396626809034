import { getConfig } from '@services/app.config.loader';
import { capitalize, keyBy, merge, values } from 'lodash';
import dateFnParse from 'date-fns/parse';
import dateFnFormat from 'date-fns/format';
import { formatISO } from 'date-fns';
import { Address } from '@app/models';
import { isAfter, isBefore } from 'date-fns/fp';
import moment from 'moment';

export enum DateFormat {
  DEFAULT = 'MM/dd/yyyy',
  ISO8601 = 'yyyy-MM-dd',
  FULL = 'MMMM d, yyyy',
  ISO8601_Timestamp = 'timestamp',
}

export const SECOND = 1000;
export const MINUTE = SECOND * 60;

export function isProdEnv(): boolean {
  const env = getConfig('env');
  return env === 'prod' || env === 'staging';
}

export function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones */
  const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}

/** export numeric value (i.e. 1333) into currency format with symbol (i.e. $1,333.00) */
export function toCurrency(num: number): string {
  return num?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

/** export numeric value (i.e. 0.0557) into percentage format with symbol (i.e. 5.57%) */
export function toPercentage(num: number): string {
  return `${(num * 100).toFixed(2)}%`;
}

/** Converts a string to title case. Note that this should not be used for certain fields such as street direction and state.
 * Also note that we can't differentiate initials with names, so initials will not work either, but for now we will accept this loss.
 */
export function toTitleCase(str: string): string {
  return !str ? '' : str.split(' ').map(capitalize).join(' ');
}

/** Returns the domain from an email string */
export function getEmailDomain(str: string = ''): string {
  return str.split('@').pop().toLowerCase();
}

/** Masks a phone number to USA format */
export function maskPhoneNumber(phone: string): string {
  const PHONE_FORMAT_PATTERN = /(\d{0,3})(\d{0,3})(\d{0,4})/;
  const value = phone.replace(/\D/g, '').match(PHONE_FORMAT_PATTERN);
  const formattedValue = !value[2]
    ? value[1]
    : `(${value[1]}) ${value[2]}${value[3] ? `-${value[3]}` : ''}`;
  return formattedValue;
}

/** Formats a phone number to USA format */
export function formatPhoneNumber(phone: string): string {
  try {
    if (phone) {
      const cleaned = phone.replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }
  } catch (e) {
    console.error(e);
  }
  return '';
}

/**
 * @deprecated
 * Sets up a given date to be localizable while remaining in UTC
 * @param   {Date} date the date to adjust
 *
 * @returns {Date} a date that will be in UTC when localized
 */
export const getLocalizableUTCDate = (date: Date): Date => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.valueOf() + timezoneOffset);
};

/** @deprecated use formatDate(date, DateFormat.DEFAULT)
 * Formats a date object to string MM/dd/yyyy format
 */
export function formattedDate(date: Date): string {
  return formatDate(date, DateFormat.DEFAULT) || undefined;
}

/** @deprecated use formatDate(date, DateFormat.ISO8601)
 * Formats a date object to string YYYY-MM-DD format
 */
export function formatDateToISO8601(date: Date, _localizeDate = true): string {
  return formatDate(date, DateFormat.ISO8601);
}

// Returns raw numbers only if phoneNumber string has any formatting characters
// An empty string is returned if phoneNumber string contains no numbers
export function cleanPhone(phoneNumber: string = ''): string {
  const cleanedPhone = phoneNumber.match(/[0-9]+/g);
  if (cleanedPhone != null) {
    return cleanedPhone.join('');
  }
  return '';
}

/** @deprecated use formatDate(str)
 * Converts string date to MM/dd/YYYY format using formattedDate(date).
 * If empty string or null, return empty string.
 */
export function formatDateFromString(str: string): string {
  return formatDate(str, DateFormat.DEFAULT) || '';
}

/** @deprecated use parseDate(str)
 * From a given string, get the date object.
 * If string is empty, then undefined is returned.
 * @param str
 */
export function getDateFromString(str: string): Date {
  return parseDate(str);
}

export function isNullOrUndefined(object: any): boolean {
  return object === null || object === undefined;
}

/**
 * Merges two arrays of objects by a given property
 * @template T
 * @param {T[]} dest - The destination array of objects
 * @param {T[]} source - The source array of objects
 * @param {string} [key='id'] The property key used to match objects. Defaults to 'id'.
 *
 * @return {T[]} Returns the destination array merged with the source array
 */
export const mergeBy = <T>(dest: T[], source: T[], key = 'id'): T[] => {
  const destByKey = keyBy(dest, key);
  const sourceByKey = keyBy(source, key);

  Object.keys(destByKey).forEach((destKey) => {
    const newData = sourceByKey[destKey];

    if (!newData) return;

    merge(destByKey[destKey], newData);
  });

  return values(destByKey);
};

/**
 * Comparison function to alphabetize a set of values, using a supplied function to unwrap the value for comparison.
 * @template T
 * @param {(x: any) => string} valFunc - The function to use to unwrap the value for comparison.
 *
 * @return {(a: T, b: T) => 1 | -1 | 0} Returns a comparison function that alphabetizes a set using the value provided by valFunc for comparison
 */
export const alphabetizeBy =
  <T>(valFunc: (x: any) => string) =>
    (a: T, b: T) => {
      const aVal = valFunc(a);
      const bVal = valFunc(b);

      if (aVal > bVal) return 1;

      if (bVal > aVal) return -1;

      return 0;
    };

export const downloadPDFViaURL = (pdfURL: string, fileName: string): void => {
  const a = document.createElement('a');
  a.href = pdfURL;
  a.setAttribute('download', fileName);
  a.click();
};

/** For a given dataset, slice the set to multiple subsets to be used for data loading purposes
 * TODO: Update Orders.tsx to use this function instead.
 */
export const generateDataSubSets = <T>(dataSet: T[], subSetSize: number): T[][] => {
  const subsetCount = Math.ceil(dataSet.length / subSetSize);
  let subSets: T[][] = [];

  for (let i = 0; i < subsetCount; i++) {
    const startIndex = i * subSetSize;
    const endIndex = (i + 1) * subSetSize;
    const subSet = dataSet.slice(startIndex, endIndex);
    subSets = [...subSets, subSet];
  }

  return subSets;
};

/**
 * Parses date from string. Compatible with ISO-8601 format and also MM/DD/YYYY from our front end.
 * @param date
 */
export function parseDate(date: string): Date {
  if (!date) {
    console.warn('no date provided, returning undefined Date instead');
    return undefined;
  }

  // if matches MM/DD/YYYY, then handle it separately
  if (date.match(/\d{2}\/\d{2}\/\d{4}/)) {
    return dateFnParse(date, 'MM/dd/yyyy', new Date());
  }

  // Otherwise, drop timestamp and proceed
  const dateTokens = date.split('T');
  const dateWithoutTimestamp = dateTokens[0];

  return dateFnParse(dateWithoutTimestamp, 'yyyy-MM-dd', new Date());
}

/**
 * Formats a date with a given standard layout. See DateFormat enum for some provided formats that the site uses.
 * If date is undefined, function returns empty string.
 * @param date
 * @param layout
 */
// TODO: Simplify, and revise once we're able to fully switch over to timestamps: https://ftdr.atlassian.net/browse/ARE-8908
export function formatDate(
  date: Date | string,
  layout: DateFormat | string = DateFormat.DEFAULT,
): string {
  if (!date) {
    return '';
  }

  if (layout === DateFormat.ISO8601_Timestamp) {
    // @ts-expect-error TODO: not type safe
    return formatISO(date);
  }

  let dateObj: Date;

  if (typeof date === 'string') {
    dateObj = parseDate(date);
  } else {
    dateObj = date;
  }

  return dateFnFormat(dateObj, layout);
}

/**
 * Rounds a date object up to the nearest day.
 * @param date
 */
export function roundUpDate(date: Date | string): Date {
  if (!date) {
    return null;
  }

  let roundedDate: Date;

  if (typeof date === 'string') {
    roundedDate = parseDate(date);
  } else {
    roundedDate = date;
  }

  return new Date(roundedDate.setHours(24, 0, 0, 0));
}

/**
 * Returns a date object rounded down to the nearest day.
 * @param date
 */
export function roundDownDate(date: Date | string): Date {
  if (!date) {
    return null;
  }

  let roundedDate: Date;

  if (typeof date === 'string') {
    roundedDate = parseDate(date);
  } else {
    roundedDate = date;
  }

  return new Date(roundedDate.setHours(0, 0, 0, 0));
}

/**
 * Return a random whole number between the min and max (inclusive).
 * @param min
 * @param max
 */
export function randomNumber(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** sanitizes the provided id by lowercasing and underscoring it.
 *  ex. Chlorine Pool/Shared Spa & Equipment ==> chlorine_pool/shared_spa_&_equipment */
export function sanitizeId(id: string): string {
  return id.split(' ').join('_').toLowerCase();
}

/**
 * Converts an object to a map
 *
 * @return {Map} Returns obj as a Map
 * @param obj
 */
export const objToMap = (obj: object): Map<string, unknown> => {
  return new Map(Object.entries(obj));
};

export const toFormattedNumber = (input: any) => {
  const toNum = Number(input);
  return toNum.toLocaleString();
};

export const deleteUndefinedValues = (obj: object, deleteNulls = false) => {
  const cleanObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      return;
    }
    if (deleteNulls && obj[key] === null) {
      return;
    }
    cleanObj[key] = obj[key];
  });
  return cleanObj;
};

export const removeTags = (str) => {
  if (str === null || str === undefined || str === '') return false;

  str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const isAppAddressMatch = (actualAddress: Address, userAddress: Address): boolean => {
  // check city/state/zip foremost for match
  if (actualAddress.zip.toLowerCase() !== userAddress.zip.toLowerCase()) {
    return false;
  }
  if (actualAddress.state.toLowerCase() !== userAddress.state.toLowerCase()) {
    return false;
  }
  if (actualAddress.city.toLowerCase() !== userAddress.city.toLowerCase()) {
    return false;
  }

  const { streetAddress: userStreet = '', unit: userUnit = '' } = userAddress;
  const {
    streetAddress: actualStreet = '',
    unit: actualUnit = '',
    unitType: actualUnitType = '',
  } = actualAddress;

  // helper function to merge, lowercase, and trim whitespaces for fields to compare
  const mergeFields = (...fields: string[]) => {
    return fields
      .map((f) => f.trim().toLowerCase())
      .join(' ')
      .trim();
  };
  const removeSpecialCharacters = (field: string) => {
    return field.replace(',', '').replace('.', '');
  };

  // NOTE: currently, user can put units in street address. they also need to type the unit type
  // The comparison below is considering that unit type is not factored in atm.
  // In other words, if user entered "#10" and actual address has unit value "10", this is a mismatch.

  // user may have put units along with the street address
  const userStreetAddressAndUnit = mergeFields(userStreet, userUnit);

  // users may have had some special characters we want to exclude ('.' for 'Rd.', or ',' to separate street and units copy and paste
  const userStreetAddressAndUnitNoSpecialChars = mergeFields(
    removeSpecialCharacters(userStreet),
    removeSpecialCharacters(userUnit),
  );

  // for an address that doesn't have units, we need to make sure the user didn't enter any units
  if (actualUnit == '') {
    return (
      mergeFields(actualStreet) == userStreetAddressAndUnit ||
      mergeFields(actualStreet) == userStreetAddressAndUnitNoSpecialChars
    );
  }

  // otherwise, we need to make various combinations for comparison
  const comparisons = [
    mergeFields(actualStreet, actualUnitType, actualUnit), // user has entered the unit type and unit value correctly
    mergeFields(actualStreet, `${actualUnitType}${actualUnit}`), // user has not added a space between unit type and unit value
    mergeFields(actualStreet, actualUnit), // user has only provided unit value and not unit type
  ];

  // if at least one of the comparison matches, we consider it a matching address then
  return (
    comparisons.some((comparison) => comparison === userStreetAddressAndUnit) ||
    comparisons.some((comparison) => comparison == userStreetAddressAndUnitNoSpecialChars)
  );
};

/**
 * Creates a Date object from year, month, day, hour, minute, second, and a timezone name
 * @param timeZone Example: "America/Chicago"
 *
 * @return {Date} Returns a Date object
 */
export const dateTimeWithTimeZone = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number,
  timeZone: string,
) => {
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  const offset = utcDate.getTime() - tzDate.getTime();
  date.setTime(date.getTime() + offset);
  return date;
};

export const getLeastDate = (...dates: Date[]): Date => {
  return dates.filter((d) => !!d).reduce((r, d) => (!r || isBefore(r, d) ? d : r), null);
};

export const getGreatestDate = (...dates: Date[]): Date => {
  return dates.filter((d) => !!d).reduce((r, d) => (!r || isAfter(r, d) ? d : r), null);
};

export const convertDateToCST = (date: string) => {
  if (date) {
    const momentDate = moment.utc(date);
    momentDate.tz('America/Chicago');
    const dateInCST = momentDate.format('YYYY-MM-DD');
    return dateInCST;
  }
}
