import React from 'react';

// Components
import Layout from '@components/layout/Layout';

const LayoutCard = (props) => {
  return (
    <Layout
      slug={props.slug}
      isLoggedIn={props.isLoggedIn}
      hideHeader={props.hideHeader}
      hideFooter={props.hideFooter}
    >
      <div className="container xs-max:p-0 pb-8">
        <div className="card px-4 sm:px-12 lg:px-32 py-8 sm:py-10">{props.children}</div>
      </div>
    </Layout>
  );
};

export default LayoutCard;
