import { ProfileErrorResponse } from '@apis/models/profile.api.model';
import ProfileApi from '@apis/profile.api';
import ProfileModel, { ProfileOffice } from '@app/models/profile.model';
import {
  officeTypeDictionary,
  officeTypeFullFormDictionary,
  UserExtension,
} from '@constants/dictionaries';
import { Office, OfficeType } from '@app/models';
import uniqBy from 'lodash/uniqBy';
import { isInternalUser } from '@helpers/profile.utils';
import { alphabetizeBy } from '@helpers/utils';
import isEmpty from 'lodash/isEmpty';

// TODO Can refactor the magic strings in this file to use our dictionaries
/** Returns the worked with office type code based on the profile's role type */
export function getWorkedWithOfficeTypeCode(profile: ProfileModel): string {
  if (isInternalUser(profile)) {
    return 'CC';
  }

  return officeTypeDictionary[profile.roleIDType] === 'RE' ? 'CC' : 'RE';
}

/** Updates worked with offices. Returns null if no changes occured in worked with offices. Otherwise, may or may not return error response.
 * Function checks for duplicates offices prior to ignore the api call if no changes needed.
 */
export async function updateWorkedWithOfficesOnSave(
  profile: ProfileModel,
  ...offices: ProfileOffice[]
): Promise<ProfileErrorResponse> {
  let hasOfficeUpdates = false;

  for (const office of offices) {
    if (!isDuplicateOffice(profile.workedWithOffices, office)) {
      profile.workedWithOffices.push(office);
      hasOfficeUpdates = true;
    }
  }

  if (hasOfficeUpdates) {
    const updatedProfile = { ...profile };
    updatedProfile.email = null; // ARE-6882 Email should only be updated from the Edit My Account page.

    return await ProfileApi.updateUser(updatedProfile);
  }

  return Promise.resolve(null);
}

/** Updates profile offices (main one).
 * Function checks for duplicates offices prior to ignore the api call if no changes needed. */
export async function updateOfficesOnSave(
  profile: ProfileModel,
  office: ProfileOffice,
  setProfile?: any,
) {
  if (!isDuplicateOffice(profile.offices, office)) {
    const officeRequest = profile.offices.map((office) => {
      return { id: office.id, type: officeTypeFullFormDictionary[office.type] };
    });

    officeRequest.push({ id: office.id, type: officeTypeFullFormDictionary[office.type] });
    const request = {
      profileID: profile.profileID,
      offices: officeRequest,
    };

    try {
      await ProfileApi.updateUsersOfficeDetails(request);
      if (setProfile) {
        const updatedProfile = await ProfileApi.getUser();
        setProfile(updatedProfile);
      }
    } catch (e) {
      console.error('failed to update profile offices', e);
    }
  }
}

function isDuplicateOffice(officeArray: ProfileOffice[], officeToFind: ProfileOffice): boolean {
  return officeArray.some((office) => {
    return (
      office.id === officeToFind.id &&
      officeTypeFullFormDictionary[office.type] === officeTypeFullFormDictionary[officeToFind.type]
    );
  });
}

export function getProfileOfficesByType(
  userDetails: ProfileModel,
  officeType: OfficeType,
): Office[] {
  return getOfficesByType(userDetails.offices, userDetails.workedWithOffices, officeType);
}

export function getOfficesByType(
  userOfficeDetail,
  userWorkedWithOfficeDetail,
  officeType,
): Office[] {
  let officesOfType = [];

  if (userOfficeDetail && userOfficeDetail.length > 0) {
    officesOfType = officesOfType.concat(
      userOfficeDetail.filter(
        (office) =>
          officeTypeFullFormDictionary[office.type] === officeTypeFullFormDictionary[officeType],
      ),
    );
  }

  if (userWorkedWithOfficeDetail && userWorkedWithOfficeDetail.length > 0) {
    officesOfType = officesOfType.concat(
      userWorkedWithOfficeDetail.filter(
        (office) =>
          officeTypeFullFormDictionary[office.type] === officeTypeFullFormDictionary[officeType],
      ),
    );
  }

  return deduplicateOffices(officesOfType).sort(alphabetizeBy((office) => office.name));
}

export const getOffices = (profile: ProfileModel): ProfileOffice[] => {
  let { offices } = profile;
  const workWithOffices = profile.workedWithOffices;

  if (isEmpty(offices) && isEmpty(workWithOffices)) return [];

  if (isInternalUser(profile) && !isEmpty(profile.workedWithOffices))
    offices = offices.concat(profile.workedWithOffices);

  return offices;
};

export function hasWarrantyLinkOffice(profile: ProfileModel): boolean {
  return getOffices(profile).some((office) => office.warrantyLinkEligible);
}

export function deduplicateOffices(offices: any[]) {
  return uniqBy(offices, 'id');
}

export function getProfileWLKOffices(userDetails: ProfileModel): ProfileOffice[] {
  return getProfileOffices(userDetails).filter(
    (o) => officeTypeDictionary[o.type] === 'RE' && o.warrantyLinkEligible,
  );
}

export function getWLKBrokerOffices(userDetails: ProfileModel): ProfileOffice[] {
  return getProfileOffices(userDetails).filter((o) =>
    o.userExtensions ? o.userExtensions.includes(UserExtension.WLKBroker) : false,
  );
}

export function isWarrantyLinkBroker(userDetails: ProfileModel): boolean {
  return getProfileOffices(userDetails).some((o) =>
    o.userExtensions.includes(UserExtension.WLKBroker),
  );
}

/** Returns all offices in the profile, combining offices and workedWithOffices */
export function getProfileOffices(userDetails: ProfileModel): ProfileOffice[] {
  let offices = [];

  const userOfficeDetail = userDetails.offices;
  const userWorkedWithOfficeDetail = userDetails.workedWithOffices;

  if (userOfficeDetail?.length > 0) {
    offices = offices.concat(userOfficeDetail);
  }

  if (userWorkedWithOfficeDetail?.length > 0) {
    offices = offices.concat(userWorkedWithOfficeDetail);
  }

  return _deduplicateProfileOffices(offices);
}

export function _deduplicateProfileOffices(offices: ProfileOffice[]): ProfileOffice[] {
  const uniqueOfficeMap: { [key: string]: boolean } = {};
  return offices.reduce<ProfileOffice[]>((uniqueOffices, office) => {
    const key = `${office.id}-${office.type}`;
    if (!uniqueOfficeMap[key]) {
      uniqueOfficeMap[key] = true;
      uniqueOffices.push(office);
    }
    return uniqueOffices;
  }, []);
}

export function hasOfficesInState(profile: ProfileModel, states: string[]): boolean {
  const officeStates = profile?.offices
    ?.filter((office) => office.active)
    .map((o) => o.address.state);
  console.log(`profileOfficesStates for active offices:${officeStates}`);
  const officeStateMatch = officeStates?.some((pos) => states?.includes(pos));
  return officeStateMatch;
}
