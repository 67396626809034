import ModalChoice from '@components/modal/ModalChoice';
import React, { useState } from 'react';
import ConfirmWLRemoval from '@components/modal/subcomponents/ConfirmWLRemoval';

const ModalConfirmWLRemoval = (props) => {
  const [effectiveDateString, setEffectiveDateString] = useState('');

  return (
    <ModalChoice
      id={props.id}
      heading={props.heading}
      isActive={props.isActive}
      onClose={() => props.onClose(false)}
      onConfirm={() => props.onConfirm(effectiveDateString)}
      yesText="Continue"
      noText="Cancel"
    >
      {props.isActive && <ConfirmWLRemoval onDateChange={setEffectiveDateString} />}
    </ModalChoice>
  );
};

export default ModalConfirmWLRemoval;
