import Path from '@constants/paths';

const lastLocation = 'LastLocation';
const defaultLocation = Path.Dashboard.valueOf();

// TODO Refactor out this entire functionality
// UX no longer wants the ability to return to last visited location
// So none of this code is necessary
// Will require changing the callback component to always redirect to /dashboard
// And remove all calls to setCurrentLocation, et al.

export const ignoredPathsForCallback: string[] = [Path.HomePage, Path.Callback];

// TODO Consider whitelisting instead of Blacklisting
export const unauthorizedRedirects: string[] = [
  Path.NewOrderSubmitted,
  Path.VerifyEmail,
  Path.RegistrationExistingUser,
];

export function setCurrentLocation(url: string) {
  localStorage.setItem(lastLocation, url);
}

export function getLastLocation(): string {
  // return localStorage.getItem(lastLocation) || defaultLocation;
  return defaultLocation;
}

export function saveCurrentLocation() {
  const currentPath = window.location.pathname;
  if (!ignoredPathsForCallback.includes(currentPath)) {
    setCurrentLocation(currentPath);
  }
}

export function savedLocationForRedirect(): string {
  let redirectLocation = getLastLocation();
  if (unauthorizedRedirects.includes(redirectLocation)) {
    redirectLocation = defaultLocation;
  }

  return redirectLocation;
}
