import React, { useState } from 'react';
import {
  Badge,
  Button,
  IconCheck,
  Link,
  List,
  ListItem,
  ListItemTextColorOption,
  Panel,
  Text,
} from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import { CONTENT } from '@app/locales/en';
import ModalCoverage from '@components/modal/ModalCoverage';
import Skeleton from 'react-loading-skeleton';
import { randomNumber } from '@helpers/utils';

interface CoverageContent {
  text: string;
  bolded?: boolean;
}

export type GetIncludedCoveragesHandler = (pvid: string) => Promise<string[]>;

export interface PlanCardContent {
  pvid: string;
  heading: string;
  subtext: string;
  price: number;
  coverages: CoverageContent[] | string[];
  allCoverages?: CoverageContent[] | string[];
  bestCoverage: boolean;
  selected: boolean;
  paymentFrequency?: string;
  zestyBased?: boolean;
  getIncludedCoverages?: GetIncludedCoveragesHandler;
  loading?: boolean;
  loadingCvgCnt?: number;
}

export interface PlanCardProps extends PlanCardContent {
  onSelect: () => void;
}

const PlanCard: React.FC<PlanCardProps> = (props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [coverages, setCoverages] = useState<string[]>([]);

  const getBorderColor = () => {
    return props.selected ? 'interactive-600' : 'white';
  };

  const getDescriptionTextColor = (): ListItemTextColorOption => {
    return props.selected ? 'primary' : 'gray';
  };

  const getListTextColor = (): ListItemTextColorOption => {
    return props.selected ? 'primary' : 'gray';
  };

  const getDecoratedCoverages = (zestyBased: boolean): ListItem[] => {
    if (zestyBased) {
      return getZestyDecoratedCoverages();
    }

    const coverages = props.coverages as CoverageContent[];

    return coverages.map<ListItem>((cov) => ({
      text: cov.text,
      className: classNames([cov.bolded ? 'font-bold' : '']),
      textColor: getListTextColor(),
      icon: <IconCheck size="18" color="gray" />,
    }));
  };

  const getZestyDecoratedCoverages = (): ListItem[] => {
    const coverages = props.coverages as string[];

    return coverages.map((cov) => ({
      text: <span className="zesty-html" dangerouslySetInnerHTML={{ __html: cov }} />,
      icon: <IconCheck size={18} />,
      textColor: getListTextColor(),
    }));
  };

  const getDescriptionComponent = (zestyBased: boolean): JSX.Element => {
    if (props.loading) {
      return (
        <>
          <Skeleton />
          <Skeleton />
        </>
      );
    }
    if (zestyBased) {
      return getZestyDescriptionComponent();
    }

    return (
      <Text
        id={`${props.pvid}--header--caption`}
        variant="caption"
        className="my-2"
        color={getDescriptionTextColor()}
      >
        {props.subtext}
      </Text>
    );
  };

  const getZestyDescriptionComponent = (): JSX.Element => {
    return (
      <Text
        id={`${props.pvid}--header--caption`}
        variant="caption"
        className="my-2 zesty-html"
        color={getDescriptionTextColor()}
        dangerouslySetInnerHTML={{ __html: props.subtext }}
      />
    );
  };

  const getTitleComponent = (): JSX.Element => {
    return (
      <Text id={`${props.pvid}--header`} className="m-0 mt-2" variant="heading-04" color="primary">
        {props.loading ? (
          <Skeleton />
        ) : (
          <>
            {props.heading}
            <sup>SM</sup>
          </>
        )}
      </Text>
    );
  };

  const getClassNames = () => {
    return classNames([
      'plan-card',
      'grow',
      'relative',
      'flex-1',
      props.selected ? 'plan-card--selected' : '',
    ]);
  };

  const handleAllCoverageClick = async () => {
    const res = await props.getIncludedCoverages(props.pvid);
    setCoverages(res);
    setIsModalActive(true);
  };

  return (
    <Panel
      id={`plan-card-${props.pvid}`}
      className={getClassNames()}
      border="3"
      borderColor={getBorderColor()}
      shadow={true}
    >
      {props.bestCoverage ? (
        <Badge className="plan-card-badge absolute top-0" color="primary" size="large">
          {CONTENT.BEST_COVERAGE_LABEL}
        </Badge>
      ) : null}
      <div className="flex flex-col p-2 h-full space-y-3">
        {getTitleComponent()}
        {!props.loading && (
          <Link onClick={() => handleAllCoverageClick()} color="interactive">
            {' '}
            See All Coverage{' '}
          </Link>
        )}
        {getDescriptionComponent(props.zestyBased)}
        <span id={`${props.pvid}--plan-price`} className="flex flex-row">
          <Text className="m-0" variant="heading-04" color="primary">
            {props.loading ? <Skeleton width={54} /> : <>${props.price}</>}
          </Text>
        </span>
        <div id={`${props.pvid}--plan-coverages`} className="flex-1">
          {props.loading ? (
            <List
              className="my-2"
              variant="unordered"
              size="small"
              listItems={Array(props.loadingCvgCnt || randomNumber(3, 9))
                .fill(null)
                .map(() => ({ text: '' }))}
              listItemRenderer={() => (
                <div className="list-item flex">
                  <div className="list-icon mr-4">
                    <Skeleton circle={true} width={18} height={18} />
                  </div>
                  <Skeleton width={randomNumber(120, 178)} />
                </div>
              )}
            />
          ) : (
            <List
              className="my-2"
              variant="unordered"
              size="small"
              listItems={getDecoratedCoverages(props.zestyBased)}
            />
          )}
        </div>
        {!props.loading && (
          <Button
            size="medium"
            label={
              props.selected
                ? CONTENT.PLAN_CARD_SELECTED_BUTTON_LABEL
                : CONTENT.PLAN_CARD_NON_SELECTED_BUTTON_LABEL
            }
            variant="filled"
            width="full"
            className="mt-1"
            onClick={props.onSelect}
          />
        )}
      </div>

      <ModalCoverage
        id={`${props.pvid}--modal`}
        isActive={isModalActive}
        onClose={() => setIsModalActive(false)}
        plan={
          <>
            {props.heading}
            <sup>SM</sup>
          </>
        }
        covered={coverages}
      />
    </Panel>
  );
};

PlanCard.defaultProps = {
  paymentFrequency: 'year',
};

export default PlanCard;
