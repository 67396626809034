import React from 'react';

// Components
import Modal from '@components/modal/Modal';
import ContractDetails from '@components/modal/subcomponents/ContractDetails';

// TODO: Strict props don't play well with typescript for optional values. After converting this to typescript, go and see if there are default values provided

const ModalWlkContractDetails = (props) => {
  return (
    <Modal
      id="contract-details-modal"
      heading="Contract Details"
      closeOnOutsideClick={true}
      isModalDialog={false}
      showBackdrop={true}
      {...props}
    >
      {props.isActive && <ContractDetails {...props} />}
    </Modal>
  );
};

export default ModalWlkContractDetails;
