import React from 'react';
import { Button, Text } from '@ftdr/blueprint-components-react';

export interface SubmitSuccessProps {
  title: string;
  message: string;
  buttonText: string;
  isButtonVisible: boolean;
  onClick?: any;
}

const SumbitSuccess: React.FC<SubmitSuccessProps> = (props: SubmitSuccessProps) => {
  return (
    <div className="card py-4">
      <div className="px-8 pt-4">
        <Text id="contact-success-title" variant="heading-03">
          {props.title}
        </Text>
      </div>
      <div className="px-8 pt-4">
        <Text id="contact-success-message">{props.message}</Text>
      </div>
      <div className="p-8">
        {props.isButtonVisible && (
          <Button
            id="contact-success-button"
            label={props.buttonText}
            variant="outlined"
            shape="rounded"
            onClick={props.onClick}
          />
        )}
      </div>
    </div>
  );
};

export default SumbitSuccess;
