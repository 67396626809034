import React, { useEffect, useState } from 'react';
import InitiatingOfficeInput from '@components/input/InitiatingOfficeInput';
import InitiatingAgentInput from '@components/input/InitiatingAgentInput';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import { Input } from '@ftdr/blueprint-components-react';

const AgentInfoInput = (props) => {
  const [initiatingOffice, setInitiatingOffice] = useState({
    name: undefined,
  });
  const [values, setValues] = useState(props.values);
  const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false);

  async function handleOfficeSelect(offices) {
    setShowOfficeSuggestions(false);
    const office = offices[0];
    setValues({
      ...values,
      initiatingOffice: office.name,
      agentOffice: office,
      office: { id: office.id },
      initOfficeId: office.id,
      initAgentId: '',
      agentlist: [],
    });

    props.onOfficeErrorClear();
    setInitiatingOffice(office);
  }

  function updateOfficeFilter() {
    setValues({ ...values, agentlist: [], initAgentId: '' });
  }

  function focusOfficeSelect() {
    setShowOfficeSuggestions(true);
  }

  useEffect(() => {
    props.onChangeValues(values.initAgentId, values.initOfficeId);
  }, [values.initAgentId, values.initOfficeId]);

  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 relative">
      <div className="w-full sm:w-1/2 sm:pr-3">
        {props.isReadOnly && (
          <Input
            formField={true}
            id="wb_initiatingOfficeTypeahead"
            name="initiatingOffice"
            label="Initiating Office"
            value={props.values.initiatingOffice}
            autoComplete="off"
            disabled={true}
          />
        )}
        {!props.isReadOnly && (
          <InitiatingOfficeInput
            id="wb_initiatingOfficeTypeahead"
            idNoDropdown="wb_initOfc"
            name="initiatingOffice"
            label="Initiating Office"
            officeList={getProfileOfficesByType(props.profile, OfficeType.RealEstate)}
            selectedOffice={initiatingOffice}
            handleOfficeSelect={(value) => handleOfficeSelect(value)}
            updateOfficeFilter={() => updateOfficeFilter()}
            focusOfficeSelect={() => focusOfficeSelect()}
            errors={props.officeError}
            onError={props.onOfficeError}
            showOfficeSuggestions={showOfficeSuggestions}
            setShowOfficeSuggestions={setShowOfficeSuggestions}
            values={values}
            setValues={setValues}
            onOfficeError={props.onOfficeError}
            officeType={OfficeType.RealEstate}
            required={props.required}
          />
        )}
      </div>

      <div className="w-full sm:w-1/2">
        {props.isReadOnly && (
          <Input
            formField={true}
            id="wb_initiatingAgentTypeahead"
            name="initiatingAgent"
            label="Initiating Agent"
            value={props.values.AgentName}
            autoComplete="off"
            disabled={true}
          />
        )}
        {!props.isReadOnly && (
          <InitiatingAgentInput
            id="wb_initiatingAgentTypeahead"
            idNoDropdown="wb_agent"
            name="initiatingAgent"
            label="Initiating Agent"
            selectedOffice={initiatingOffice.name}
            values={values}
            setValues={setValues}
            errors={props.agentError}
            onAgentError={props.onAgentError}
            onSelectContactChange={props.onSelectContactChange}
            required={props.required}
          />
        )}
      </div>
    </div>
  );
};
export default AgentInfoInput;
