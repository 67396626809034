import PubSub from 'pubsub-js';
import {Contract, REOrder} from '@apis/models';
import _ContractApi from '@apis/contract.api';
import _RealEstateOrderApi from '@apis/realestateorder.api';
import {Quote} from '@apis/models/quote.api.model';

/** define what components are subscribed to the refresh logic as a means to keep track of it. */
export enum RefreshContractServiceSubscriber {
  DemoGlobalDrawer,
  OrdersPage,
  OrdersTemplate,
  QuotesTemplate,
}

let initializedApis = false;
let ContractApi: typeof _ContractApi;
let RealEstateOrderApi: typeof _RealEstateOrderApi;

/** ******************************************************************
 * Logic to handle common refreshing logic.
 * The intention will be for any location to trigger a data refresh
 * and anything subscribed to it will then use this to refresh data.
 * The topics will have data published on the api directly so that
 * we can pick up any changes easily.
 * ******************************************************************/

export const TOPIC_REFRESH_CONTRACT = 'TopicRefreshContract';
export const TOPIC_REFRESH_QUOTES = 'TopicRefreshQuotes';
export const TOPIC_REFRESH_REORDER = 'TopicRefreshREOrder';

export interface RefreshContractOptions {
  /** required: specify a valid contract id for refreshing */
  contractId: string;
  /** when set to true, ignores call to the contract by id endpoint */
  excludeContractDetails?: boolean;
  /** when set to true, ignores call to the profile contract endpoint */
  excludeREOrder?: boolean;
}

type RefreshSubscriptionMap = Map<RefreshContractServiceSubscriber, string>;

/** stored tokens for unsubscription. Key should be unique; value is the token. */
const refreshedContractsSubscriptions: RefreshSubscriptionMap = new Map();
/** stored tokens for unsubscription. Key should be unique; value is the token. */
const refreshedREOrdersSubscriptions: RefreshSubscriptionMap = new Map();

const addToSubscriptionMap = (
  map: RefreshSubscriptionMap,
  key: RefreshContractServiceSubscriber,
  token: string,
): void => {
  if (map.has(key)) {
    console.warn(
      'the map currently has existing token. key must be unique. previous token is lost',
      key,
      map.get(key),
    );
  }
  map.set(key, token);
};

const handleUnsubscribe = (
  map: RefreshSubscriptionMap,
  key: RefreshContractServiceSubscriber,
): void => {
  if (!map.has(key)) {
    console.warn('the map does not have the key. ignoring.', key);
    return;
  }
  PubSub.unsubscribe(map.get(key));
  map.delete(key);
};

export const unsubscribeRefreshedContracts = (key: RefreshContractServiceSubscriber): void => {
  handleUnsubscribe(refreshedContractsSubscriptions, key);
};

export const unsubscribeRefreshedREOrders = (key: RefreshContractServiceSubscriber): void => {
  handleUnsubscribe(refreshedREOrdersSubscriptions, key);
};

/** listen to the queue to know when a global contract was updated. allows updating parts of the page when necessary.
 * @return string - the subscription token */
export const subscribeToRefreshedContracts = (
  key: RefreshContractServiceSubscriber,
  callback: typeof publishToRefreshedContracts,
): string => {
  const token = PubSub.subscribe(TOPIC_REFRESH_CONTRACT, (_, data) => callback(data));
  addToSubscriptionMap(refreshedContractsSubscriptions, key, token);
  return token;
};

/** listen to the queue to know when a global contract was updated. allows updating parts of the page when necessary.
 * @return string - the subscription token */
export const subscribeToRefreshedREOrders = (
  key: RefreshContractServiceSubscriber,
  callback: typeof publishToRefreshedREOrders,
): string => {
  const token = PubSub.subscribe(TOPIC_REFRESH_REORDER, (_, data) => callback(data));
  addToSubscriptionMap(refreshedREOrdersSubscriptions, key, token);
  return token;
};

/** publish the data to the topic */
export const publishToRefreshedContracts = (contracts: Contract[]): void => {
  if (contracts?.length > 0) {
    PubSub.publish(TOPIC_REFRESH_CONTRACT, contracts);
  }
};

/** publish the data to the topic */
export const publishToRefreshedREOrders = (reOrders: REOrder[]): void => {
  if (reOrders?.length > 0) {
    PubSub.publish(TOPIC_REFRESH_REORDER, reOrders);
  }
};

export const publishToRefreshedREQuotes = (reOrders: Quote[]): void => {
  if (reOrders?.length > 0) {
    PubSub.publish(TOPIC_REFRESH_REORDER, reOrders);
  }
};

/** helper function to initiate the related API calls for data refreshing */
export const refreshContractData = (options: RefreshContractOptions): void => {
  initializeApis();
  if (options.contractId) {
    if (!options.excludeContractDetails) {
      ContractApi.getContractDetails([options.contractId], true, true).then(() =>
        console.debug('refreshed contract data', options.contractId),
      );
    }
    if (!options.excludeREOrder) {
      RealEstateOrderApi.searchProfileContractByContractID(options.contractId).then(() =>
        console.debug('refreshed reorder data', options.contractId),
      );
    }
  }
};

/** done this way as the original API constants hasn't been instantiated yet */
const initializeApis = () => {
  if (!initializedApis) {
    ContractApi = _ContractApi.new({
      suppressAllErrorNotifications: true,
    });
    RealEstateOrderApi = _RealEstateOrderApi.new({
      suppressAllErrorNotifications: true,
    });
    initializedApis = true;
  }
};
