import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Input,
  MaskedInput,
  ProgressIndicator,
  Select,
  SelectOption,
} from '@ftdr/blueprint-components-react';
import { usStatesForBDSSelect } from '@constants/us-states';
import { getFormInputErrorId } from '@storybook/addon-links';
import AddressSuggestionInput from '@components/input/AddressSuggestionInput';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { Address } from '@app/models';
import AddressApi from '@apis/address.api';
import { isZipValid } from '@services/validation/ValidationRules';
import { Canceler } from 'axios';
import { ZipDetails } from '@apis/models';

interface NewOrderCustomerAddress<T> {
  address: T;
  unit: T;
  city: T;
  state: T;
  zip: T;
}

interface FormNewOrderCustomerAddressProps {
  ids: NewOrderCustomerAddress<string>;
  names: NewOrderCustomerAddress<string>;
  values: NewOrderCustomerAddress<string>;
  errors: NewOrderCustomerAddress<string>;
  required: NewOrderCustomerAddress<boolean>;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputBlur: (e) => void;

  stateDisabled: boolean;
  onStateSelect: (item: SelectOption) => void;

  setAddress: (address: Address) => void;
  setStreetAddressError: (err: string) => void;

  shouldLoadZipDetails?: boolean;
  onLoadZipDetails?: (zipDetails: { city: string; state: string }) => void;
}

const AddressApiCustom = AddressApi.new({
  suppressResponseErrorNotification: false,
  returnCancelErrorAsNull: false,
});

/** canceler used when zip detail call has been changed */
let loadingZipDetailsCanceler: Canceler;

const FormNewOrderCustomerAddress: React.FC<FormNewOrderCustomerAddressProps> = ({
  ids,
  names,
  values,
  errors,
  required,
  ...props
}) => {
  const [loadingZipDetails, setLoadingZipDetails] = useState<boolean>(false);
  const [cityOptions, setCityOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    return () => {
      loadingZipDetailsCanceler?.('unmounting');
    };
  }, []);

  /** when chose to load zip details, if zip code entered, make api call to get zip details
   * and return for parent to store city/state */
  useEffect(() => {
    if (props.shouldLoadZipDetails && props.onLoadZipDetails) {
      if (isZipValid(values.zip)) {
        setLoadingZipDetails(true);
        makeZipDetailsCall(values.zip, false)
          .then((zipData: ZipDetails) => {
            const cityStates = zipData.cityStates;
            const options = cityStates?.map((cityState) => ({ value: cityState.city }));
            setCityOptions(options);
            const zipDetails = {
              city: zipData.zipCodes?.[0].defaultCity,
              state: zipData.zipCodes?.[0].stateAbbreviation,
            };
            props.onLoadZipDetails(zipDetails);
          })
          .catch((err) => {
            if (AddressApiCustom.isCancellationError(err)) {
              console.debug('cancellation error', err);
              return;
            }
            props.onLoadZipDetails(null);
          })
          .finally(() => {
            setLoadingZipDetails(false);
          });
      } else {
        loadingZipDetailsCanceler?.(`cancelled zip call`);
      }
    }
  }, [props.shouldLoadZipDetails, values.zip]);

  const makeZipDetailsCall: typeof AddressApi.getZipDetails = (zip, shouldMap) => {
    const cancelSource = AddressApiCustom.createNewCancelTokenSource();
    loadingZipDetailsCanceler = cancelSource.cancel;
    return AddressApi.withRequestConfigAs({ cancelToken: cancelSource.token }).getZipDetails(
      zip,
      shouldMap,
    );
  };

  const onSelectCityOption = (opt: SelectOption) => {
    if (opt) {
      props.setAddress({
        ...values,
        city: opt.value,
      });
    }
  };

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full sm:w-1/4 md:w-1/4 px-4 py-2">
        <MaskedInput
          formField={true}
          formFieldMessageId={getFormInputErrorId(ids.zip)}
          inputMode="numeric"
          id={ids.zip}
          name={names.zip}
          required={required.zip}
          value={values.zip || ''}
          error={errors.zip}
          autoComplete="off"
          onBlur={props.onInputBlur}
          onChange={props.onInputChange}
          label="ZIP code"
          mask="00000"
          secondaryAction={
            loadingZipDetails && (
              <ProgressIndicator size="small" variant="circular" className="absolute right-0" />
            )
          }
        />
      </div>

      <div className="w-full sm-max:hidden md:w-3/4" />

      <div className="flex flex-wrap w-full sm:w-3/4 md:w-1/2">
        <div className="w-full sm:w-3/4 px-4 py-2">
          <AddressSuggestionInput
            id={ids.address}
            formFieldMessageId={getFormInputErrorId(ids.address)}
            name={names.address}
            label={ORDER_FORM_FIELDS.CUSTOMER_STREET_ADDRESS}
            addressLookup={{
              city: values.city,
              state: values.state,
              zip: values.zip,
            }}
            allowPOBox={true}
            value={values.address || ''}
            error={errors.address}
            onChangeInputNewOrderDeprecated={props.onInputChange}
            onBlurInputNewOrderDeprecated={props.onInputBlur}
            setValue={props.setAddress}
            setError={props.setStreetAddressError}
          />
        </div>

        <div className="w-full sm:w-1/4 px-4 py-2">
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(ids.unit)}
            id={ids.unit}
            name={names.unit}
            required={required.unit}
            value={values.unit || ''}
            autoComplete="off"
            onBlur={props.onInputBlur}
            onChange={props.onInputChange}
            label="Unit #"
          />
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
        <Select
          required={required.city}
          formField={true}
          autoComplete={true}
          autoCompleteFilterPredicate={() => true}
          id={ids.city}
          name={names.city}
          label="City"
          options={cityOptions}
          selected={{ value: values.city } || { value: '' }}
          onBlur={props.onInputBlur}
          onSelect={onSelectCityOption}
          error={errors.city}
        />
      </div>

      <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
        <Select
          formField={true}
          formFieldMessageId={getFormInputErrorId(ids.state)}
          id={ids.state}
          name={names.state}
          required={required.state}
          options={usStatesForBDSSelect}
          selected={
            values.state ? usStatesForBDSSelect.find((o) => o.value === values.state) : null
          }
          onSelect={props.onStateSelect}
          error={errors.state}
          disabled={props.stateDisabled}
          label="State"
        />
      </div>
    </div>
  );
};

export default FormNewOrderCustomerAddress;
