import React, { useEffect, useState } from 'react';
import { getBrand } from '@helpers/brand.utils';
import { Button, Checkbox } from '@ftdr/blueprint-components-react';

export interface LegalConfirmationProps {
  onSubmit: () => void;
  onClose: () => void;
  setActions: (actions: any[]) => void;
  firstName: string;
  lastName: string;
}

const LegalConfirmation: React.FC<LegalConfirmationProps> = (props) => {
  const [isCertificationBoxChecked, setIsCertificationBoxChecked] = useState(false);

  const toggleCertificationBox = () => {
    setIsCertificationBoxChecked(!isCertificationBoxChecked);
  };

  useEffect(() => {
    props.setActions([
      <Button
        key="continue"
        size="medium"
        label="Continue"
        disabled={!isCertificationBoxChecked}
        onClick={props.onSubmit}
        id="wlk_contract_confirm_submit"
      />,
      <Button
        key="cancel"
        variant="ghost"
        size="medium"
        label="Cancel"
        onClick={props.onClose}
        id="wlk_contract_confirm_cancel"
      />,
    ]);
  }, [isCertificationBoxChecked]);

  return (
    <>
      <p className="mb-4">
        I hereby attest and represent that the office(s) for which I am submitting has (have)
        provided me with the power, authority and legal right to act on their behalf. The office(s)
        for which I am submitting hereby certify they has (have) verified the above information and
        it is true and correct. The office(s) for which I am submitting further certify that it has
        (have) permission from the seller(s) and buyer(s) to share their contact information listed
        above with {getBrand()} and that the fulfillment services are being performed.
      </p>

      <Checkbox
        id="confirm"
        name="confirm"
        value="confirm"
        label="I have read and agree to this certification."
        required={true}
        checked={isCertificationBoxChecked}
        onChange={toggleCertificationBox}
      />

      <p className="mb-4">{`Name: ${props.firstName} ${props.lastName}`}</p>
    </>
  );
};

export default LegalConfirmation;
