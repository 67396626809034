import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import PlanPriceShareQuote, {
  EmailQuoteCallFunc,
  PlanPriceShareQuoteProps,
} from '@components/drawer/subcomponents/PlanPriceShareQuote';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs, { msgsWParams } from '@app/locales/en';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  PLAN_PRICE__EMAIL_QUOTE,
  PLAN_PRICE__SHARE_QUOTE_SUBMIT,
} from '@constants/ga-events.constants';
import SalesMaterialApi from '@apis/salesmaterial.api';
import { ProductPricingDetailsRequest, RESourcePagePlansPrices } from '@apis/models';
import { dwellingTypeDictionary } from '@constants/dictionaries';

export interface DrawerPlanPriceShareQuoteProps
  extends Required<Pick<DrawerProps, 'isActive' | 'onClose'>>,
    Omit<PlanPriceShareQuoteProps, 'emailQuoteCall'> {
  onSuccess?: () => void;
  productPricingDetailsRequest: ProductPricingDetailsRequest;
  isQuotePage?: boolean;
}

const DrawerPlanPriceShareQuote: React.FC<DrawerPlanPriceShareQuoteProps> = (props) => {
  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();

  /** calls the api to send the quote. Returns whether or not it was successful. */
  const callEmailQuote: EmailQuoteCallFunc = (
    contact,
    initiatingOffice,
    addressId,
  ): Promise<void> => {
    if (props?.isQuotePage) {
      fireGAEvent(PLAN_PRICE__SHARE_QUOTE_SUBMIT(contact.attachBrochure));
    } else {
      fireGAEvent(PLAN_PRICE__EMAIL_QUOTE(contact.attachBrochure));
    }
    const residenceType = props.productPricingDetailsRequest.property.residenceType;
    return SalesMaterialApi.saveAndEmailQuote({
      contact,
      initiatingOffice,
      property: {
        age: props.productPricingDetailsRequest.property.age,
        squareFootage: props.productPricingDetailsRequest.property.squareFootage,
        residenceType: dwellingTypeDictionary[residenceType],
      },
      options: props.productPricingDetailsRequest.options,
      selected: props.productPricingDetailsRequest.selected,
      propertyAddressUUID: addressId,
      sourcePage: RESourcePagePlansPrices,
    })
      .then((res) => {
        if (res?.success) {
          addSuccessToQueue(
            msgsWParams.QUOTE_SHARED_SUCCESS(
              contact.recipients.map((r) => r.email).join(','),
              res.orderID,
            ),
          );
          props.onSuccess?.();
          props.onClose?.();
        } else {
          console.error('failed to send emails', res?.failedEmails);
          addErrorToQueue(msgs.SHARE_PLAN_PRICE_QUOTE_FAILED);
        }
      })
      .catch((err) => {
        console.error('failed api call', err);
        addErrorToQueue(msgs.SHARE_PLAN_PRICE_QUOTE_FAILED);
      });
  };

  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <PlanPriceShareQuote
          cityOptions={props.cityOptions}
          selectedQuote={props.selectedQuote}
          quote={props.quote}
          formData={props.formData}
          emailQuoteCall={callEmailQuote}
        />
      )}
    </Drawer>
  );
};

export default DrawerPlanPriceShareQuote;
