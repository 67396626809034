const bds = require('@ftdr/blueprint-core/src/css/themes/ahs-web-app/tailwind.config');

// TODO: this is only used for the decorator component we have. That component is considered deprecated now so this file can be cleared.

const mediaBreakpoints = {
  xs: 320, // TODO: this is not considered in BDS, should not be used.
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1366, // 1366px is how BDS defines 2XL
  // Override with the BDS values instead
  ...Object.keys(bds.theme.screens).reduce((clonedScreen, bkpt) => {
    const bkptSize = bds.theme.screens[bkpt];
    clonedScreen[bkpt] = Number(bkptSize.replace('px', ''));
    return clonedScreen;
  }, {}),
};

module.exports = {
  mediaBreakpoints,
};
