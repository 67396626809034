import * as app from '@app/models';
import { BaseAPI } from './_api';
import {
  Address,
  AddressDetail,
  AddressList,
  AddressTypeaheadRequest,
  ProtoAddress,
  ZipDetails,
} from './models';

export class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  /** Returns the ZIP Details, including city and state information
   * TODO: param 'shouldMap' is temporary to not break existing code that is using the deprecated service...
   */
  public async getZipDetails(zip: string, shouldMap = true): Promise<app.Address | ZipDetails> {
    return this.axios
      .get<any, ZipDetails>(`${this.getHost()}/address/zipcode/${zip}`)
      .then((res) => {
        if (!shouldMap) {
          return res;
        }
        if (res.zipCodes.length > 0) {
          if (res.zipCodes.length > 1) {
            console.warn('More than 1 zipcode returned for zip code. Returning 1st one for now');
          }
          return {
            zip,
            city: res.zipCodes[0].defaultCity,
            state: res.zipCodes[0].stateAbbreviation,
            meta: {
              source: 'zipDetail',
            },
          };
        }
        return undefined;
      });
  }

  /** Returns possible addresses based on given address */
  public async getPossibleAddresses(address: app.Address): Promise<app.Address[]> {
    const body: AddressTypeaheadRequest = {
      street_line: address.streetAddress,
      city: address.city,
      state: address.state,
      zipCode: address.zip,
      max_suggestions: 10,
    };
    return this.axios
      .post<any, AddressList>(`${this.getHost()}/address/address_type_ahead`, body)
      .then((res) =>
        res.addresses.map((res) => {
          return {
            streetAddress: res.streetLine, // May need to grab units out of this
            city: res.city,
            state: res.state,
            zip: address.zip, // TODO: wait until Address MS is ready to return the zip to us and handle it
            meta: {
              source: 'possible',
            },
          };
        }),
      )
      .catch(() => {
        return [];
      });
  }

  public async getAddressDetail(address: app.Address): Promise<app.Address> {
    const body: Address = {
      address1: address.streetAddress,
      address2: address.unit,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };
    return this.axios
      .post<any, AddressDetail>(`${this.getHost()}/address`, body)
      .then((res) => mapProtoAddressToAppAddress(res?.address, 'getAddressDetail'))
      .catch((e) => {
        const errors: any[] = e.response?.data?.errors || [];
        for (let i = 0; i < errors.length; i++) {
          const { code = '', field, message = '' } = errors[i];
          if (['20410'].includes(code)) {
            throw errors[i];
          }
        }
        return null;
      });
  }

  public async createUnverifiedAddress(address: Address): Promise<app.Address> {
    return this.axios
      .post<any, AddressDetail>(`${this.getHost()}/address/unverified`, address)
      .then((res) => mapProtoAddressToAppAddress(res?.address, 'createUnverifiedAddress'));
  }
}

export function mapProtoAddressToAppAddress(
  address: ProtoAddress,
  metaSource: string,
): app.Address {
  if (!address) {
    return null;
  }
  return {
    streetAddress: address.street1, // May need adjustments with street 2
    unit: address.unitValue,
    unitType: address.unitType,
    city: address.city,
    state: address.state,
    zip: address.zip,
    zipPlus4: address.zipLocal?.split(`${address.zip}-`)[1],
    meta: {
      source: metaSource,
      addressId: address.uuid,
      verified: address.attributes?.status,
    },
  };
}

export function toApiAddress(address: app.Address): Address {
  return {
    address1: address.streetAddress,
    address2: address.unit,
    city: address.city,
    state: address.state,
    zip: address.zip,
  };
}

export function toAppAddress(address: Address): app.Address {
  if (!address) {
    return null;
  }
  return {
    streetAddress: address.address1,
    unit: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
  };
}

const AddressApi = new API();
export default AddressApi;
