import React, { useState } from 'react';
import { Button, Checkbox } from '@ftdr/blueprint-components-react';

const SplashScreenOverlay = (props) => {
  const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);

  // TODO The inline styles are a workaround since BDS doesn't have a different way to remove margins from the content area of dialogs and to have the X button overlay the content.
  // These should be removed if/when BDS supports these options.
  return (
    <>
      <div
        style={{
          maxWidth: '647px',
          aspectRatio: '2/1',
          backgroundColor: 'lightgray',
          backgroundImage: `url(${props.imageUrl})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {props.headline !== '' && (
          <div
            id="splash-screen-headline"
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#202A45',
              backgroundColor: '#FFFFFFD3',
              padding: '5px',
              textAlign: 'center',
              marginTop: 'auto',
            }}
            dangerouslySetInnerHTML={{ __html: props.headline }}
          />
        )}
      </div>
      <hr className="border-gray-300 border-0 border-b-1 m-0" />
      <div
        id="splash-screen-body-text"
        style={{ maxWidth: '647px', padding: '2rem' }}
        dangerouslySetInnerHTML={{ __html: props.bodyText }}
      />
      <hr className="border-gray-300 border-0 border-b-1 m-0" style={{ width: '647px' }} />
      <div className="flex flex-row-reverse justify-between p-4" style={{ marginBottom: '2rem' }}>
        <Button
          size="medium"
          label="Learn More"
          onClick={() => {
            props.onDialogClose('learn-more');
            props.onLearnMore();
          }}
        />
        <Checkbox
          label="Don't show me this again"
          className="align-middle"
          checked={dontShowAgainChecked}
          onChange={() => {
            props.onDontShowAgainChange(!dontShowAgainChecked);
            setDontShowAgainChecked(!dontShowAgainChecked);
          }}
        />
      </div>
    </>
  );
};

export default SplashScreenOverlay;
