import React, { useMemo } from 'react';
import FormFieldset from '@components/form/FormFieldset';
import CardPlan from '@components/card/CardPlan';
import { Product, ProductAndCoveragesResponse, ProductPricingResponse } from '@apis/models';
import { sanitizeId } from '@helpers/utils';

export interface FormNewOrderPlanSelectionProps {
  /** the selected PVID to select the component */
  selectedProductId: string;
  /** used for styling to determine how to present the component */
  isSmall: boolean;
  /** is set to true, plans are hidden and show skeletons instead */
  isLoading: boolean;
  /** if set to a value, the # of skeleton plans to display will be this value.  If not set, default to 3. */
  loadingSkeletonCnt?: number;
  /** the data of the plan and coverages offered */
  availablePlansAndCoverages: ProductAndCoveragesResponse[];
  /** the data of the price information for each plan */
  planPricingData: ProductPricingResponse[];
  /** event fired when a plan was selected */
  onSelectPlan: (plan: Product) => void;
}

interface PlanItem {
  id: string;
  name: string;
  price: number;
  serviceFees: number;
  isActive: boolean;
  includedCoverageNames: string[];
  onClick: () => void;
}

const FormNewOrderPlanSelection: React.FC<FormNewOrderPlanSelectionProps> = ({
  selectedProductId,
  availablePlansAndCoverages,
  planPricingData,
  onSelectPlan,
  ...props
}) => {
  const plans: PlanItem[] = useMemo(() => {
    if (availablePlansAndCoverages.length === 0 || planPricingData.length === 0) {
      return [];
    }
    return availablePlansAndCoverages
      .map<PlanItem>(({ product, coverages }) => ({
        id: product.id,
        name: product.name,
        serviceFees: product.serviceFees,
        isActive: selectedProductId === product.starPVID,
        includedCoverageNames: coverages.included
          .map((cvg) => cvg.name)
          .sort((a, b) => a.localeCompare(b)),
        price: planPricingData.find((data) => data.product.id === product.starPVID)?.product.price,
        onClick: () => onSelectPlan(product),
      }))
      .sort((a, b) => a.price - b.price);
  }, [availablePlansAndCoverages, planPricingData, selectedProductId, onSelectPlan]);

  return (
    <div className="w-full pt-2" id="plan-selection-container">
      <FormFieldset>
        <div className="w-full pt-4">
          <div className="justify-center md:flex md:-mx-2 lg:-mx-4 sm-max:space-y-2 sm-max:mx-2">
            {props.isLoading && (
              <>
                {Array(props.loadingSkeletonCnt || 3)
                  .fill(null)
                  .map((_, idx) => (
                    <div key={idx} className="md:w-1/3 sm-max:-mx-4 md:px-2 lg:px-2 xl:px-4">
                      <CardPlan isLoading={true} />
                    </div>
                  ))}
              </>
            )}
            {!props.isLoading &&
              plans.map((plan) => (
                <div key={plan.id} className="md:w-1/3 sm-max:-mx-4 md:px-2 lg:px-2 xl:px-4">
                  <CardPlan
                    id={`${sanitizeId(plan.name)}--card`}
                    isDrawerView={props.isSmall}
                    heading={
                      <>
                        {plan.name}
                        <sup>SM</sup>
                      </>
                    }
                    price={plan.price}
                    serviceFee={plan.serviceFees}
                    onClick={plan.onClick}
                    isActive={plan.isActive}
                    details={plan.includedCoverageNames}
                  />
                </div>
              ))}
          </div>
          {props.children}
        </div>
      </FormFieldset>
    </div>
  );
};

export default FormNewOrderPlanSelection;
