import { BaseAPI } from '@apis/_api';
import { EmailPhoneValidationRequest } from '@apis/models/validate.api.model';

class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      true,
      suppressErrors,
    );
  }

  /** Healthcheck */
  public async validateEmailPhone(body: EmailPhoneValidationRequest): Promise<any> {
    return this.axios.post<any, any>(`${this.getHost()}/validate/email-phone`, body);
  }
}

const ValidateApi = new API();
export default ValidateApi;
