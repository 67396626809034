import React from 'react';
import {
  IconNavArrowRight,
  Link,
  MessageStatusOption,
  NotificationProps,
} from '@ftdr/blueprint-components-react';

export interface ReNotification {
  id?: string;
  isActive: boolean;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actions?: ReAction[];
  className?: string;
  title?: string;
  children: JSX.Element | string;
}

export interface ReAction {
  id?: string;
  label: string;
  onClick?: any;
  href?: string;
}
export interface ReNotificationProps extends NotificationProps {
  id: string;
}

export const AddErrorNotification = (
  notification: ReNotification,
  notifications: ReNotificationProps[],
): ReNotificationProps[] => {
  if (!notification.isActive) {
    return notifications;
  }

  return [...notifications, makeNotification(notification, 'error')];
};

export const AddWarningNotification = (
  notification: ReNotification,
  notifications: ReNotificationProps[],
): ReNotificationProps[] => {
  if (!notification.isActive) {
    return notifications;
  }

  return [...notifications, makeNotification(notification, 'warning')];
};

export const AddInfoNotification = (
  notification: ReNotification,
  notifications: ReNotificationProps[],
): ReNotificationProps[] => {
  if (!notification.isActive) {
    return notifications;
  }
  return [...notifications, makeNotification(notification, 'info')];
};

export const AddSuccessNotification = (
  notification: ReNotification,
  notifications: ReNotificationProps[],
): ReNotificationProps[] => {
  if (!notification.isActive) {
    return notifications;
  }
  return [...notifications, makeNotification(notification, 'success')];
};

export function addActionsLinkRow(...actions: ReAction[]): JSX.Element {
  return (
    <>
      {actions.map((action) => (
        <p key={action.label}>
          <Link
            bold
            endIcon={<IconNavArrowRight />}
            onClick={action.onClick}
            id={action.id}
            href={action.href}
            target="_blank"
          >
            {action.label}
          </Link>
        </p>
      ))}
    </>
  );
}

function makeNotification(
  notification: ReNotification,
  status: MessageStatusOption,
): ReNotificationProps {
  if (notification.isActive === false) {
    return undefined;
  }

  let { children } = notification;
  let showStatusLabel = true;

  if (notification.title) {
    showStatusLabel = false;
    children = (
      <>
        <div className="message-label message-label-no-match-color">
          {notification.title}
          {': '}
        </div>
        {children}
      </>
    );
  }

  return {
    id: notification.id || 'floating-notification',
    variant: 'floating',
    onClose: notification.onClose,
    actions: addActionsLinkRow(...notification.actions),
    autoCloseDelay: status === 'error' || status === 'warning' ? 0 : undefined,
    status,
    size: 'medium',
    showStatusLabel,
    inline: true,
    className: notification.className || 'max-w-full',
    children,
  };
}
