import React, { useContext, useEffect, useState } from 'react';

import Template, {
  REOrderWithBuyerAndSellerInfo,
} from '@templates/warrantylink/WarrantyLinkTemplate';
import {
  Contract,
  DEFAULT_CUSTOMER_FORM,
  WarrantylinkContract,
  WarrantylinkContractResponse,
} from '@apis/models';
import WarrantyLinkAPI from '@apis/warrantylink.api';
import ContractAPI from '@apis/contract.api';
import ProfileContext from '@context/ProfileContext';
import { mergeBy } from '@helpers/utils';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import { ProfileOffice } from '@app/models/profile.model';
import OfficeApi from '@apis/office.api';
import { getOffices } from '@services/helpers/profile.offices.helper';
import { UserRoleType } from '@constants/dictionaries';
import { WarrantylinkStatus } from '@constants/dashboardFilters';
import Layout from '@components/layout/Layout';

interface props {}

/*
Do not set this value above 50.
The call to RE App doesn't allow size to be passed in and the contract ms has a default page size of 50.
 */
const CONTRACT_DATA_LOAD_COUNT = 20;

// TODO: Should use the permissions endpoint
const PERMISSIONS: { [key: string]: UserRoleType[] } = {
  CAN_SUBMIT_WLK_CONTRACTS: [
    UserRoleType.SiteAdmin,
    UserRoleType.RealEstateAgent,
    UserRoleType.RealEstateAdmin,
    UserRoleType.ClosingCompanyAgent,
    UserRoleType.ClosingCompanyAdmin,
    UserRoleType.Broker,
  ],
};

const WarrantylinkPage: React.FC<props> = (props) => {
  const [isOnLoad, setIsOnLoad] = useState<boolean>(true);
  const [warrantyLinkEligibleOrders, setWarrantyLinkEligibleOrders] = useState<
    REOrderWithBuyerAndSellerInfo[]
  >([]);
  const [pageCount, setPageCount] = useState(0);
  const [pagesFetched, setPagesFetched] = useState(0);

  const { profile } = useContext(ProfileContext);
  const [wlkOfficeList, setWlkOfficeList] = useState<ProfileOffice[]>([]);

  const [canSubmitContracts, setCanSubmitContracts] = useState(false);

  const getValidOffices = async () => {
    const offices = getOffices(profile);

    const officeIDsAndTypes = getOffices(profile).map((office) => {
      return { id: office.id, type: office.type };
    });

    const { offices: officesWithWLKStatus } = await OfficeApi.getOfficeList(officeIDsAndTypes);

    const validOffices = officesWithWLKStatus?.filter(
      (office) => office.warrantyLinkStatus.toUpperCase() === 'ACTIVE',
    );
    const validOfficesById = keyBy(validOffices, 'id');

    return offices.filter((office) => validOfficesById[office.id]);
  };

  const getAndSetValidOffices = async () => {
    const validOffices = await getValidOffices();

    setWlkOfficeList(validOffices);

    return validOffices;
  };

  useEffect(() => {
    getAndSetValidOffices();
  }, []);

  useEffect(() => {
    setPageCount(1);
    setPagesFetched(0);
    setWarrantyLinkEligibleOrders([]);

    if (
      profile?.roleIDType &&
      PERMISSIONS.CAN_SUBMIT_WLK_CONTRACTS.includes(profile.roleIDType as UserRoleType)
    ) {
      setCanSubmitContracts(true);
    }
  }, [wlkOfficeList]);

  useEffect(() => {
    if (pagesFetched < pageCount && wlkOfficeList.length > 0) {
      loadProfileContractsNextPage();
    }
  }, [pageCount, pagesFetched, wlkOfficeList]);

  useEffect(() => {
    if (warrantyLinkEligibleOrders.length > pagesFetched * CONTRACT_DATA_LOAD_COUNT) {
      setPagesFetched((pf) => pf + 1);
    }
  }, [warrantyLinkEligibleOrders]);

  async function loadProfileContractsNextPage() {
    if (pagesFetched === 0) {
      setIsOnLoad(true);
    }

    try {
      const officeIds = wlkOfficeList.map((office) => office.id);

      let params = {
        offset: pagesFetched * CONTRACT_DATA_LOAD_COUNT,
        limit: CONTRACT_DATA_LOAD_COUNT,
        agentId: '',
      };

      if (officeIds.length) params['officeId'] = officeIds.join();

      // add the agent ID to the request only if user is an RE agent or CC agent
      if (
        profile.roleIDType === UserRoleType.RealEstateAgent ||
        profile.roleIDType === UserRoleType.ClosingCompanyAgent
      ) {
        params = {
          agentId: profile.roleID,
          offset: pagesFetched * CONTRACT_DATA_LOAD_COUNT,
          limit: CONTRACT_DATA_LOAD_COUNT,
        };

        if (officeIds.length) params['officeId'] = officeIds.join();
      }

      if (!params['officeId'] && !params['agentId']) {
        setIsOnLoad(false);
        setPageCount(0);
        return;
      }

      const wlkSearchResp: WarrantylinkContractResponse =
        await WarrantyLinkAPI.searchWarrantyLinkContracts(params);

      // If no contracts, stop load
      if (!wlkSearchResp || wlkSearchResp.meta?.total === 0) {
        setIsOnLoad(false);
        setPageCount(0);
        return;
      }

      const combinedContracts = await combinePropertyData(wlkSearchResp.contracts);
      setWarrantyLinkEligibleOrders((current) => current.concat(combinedContracts));
      setPageCount(Math.ceil(wlkSearchResp.meta.total / CONTRACT_DATA_LOAD_COUNT));
      setIsOnLoad(false);
    } catch (e) {
      console.error(e);
      setIsOnLoad(false);
    }
  }

  async function combinePropertyData(wlkContracts: WarrantylinkContract[]) {
    const wlkContractIDs = wlkContracts.map((c) => c.id.toString());
    const reOrderContracts: Contract[] = await ContractAPI.getContractDetails(
      wlkContractIDs,
      false,
      true,
    );

    if (reOrderContracts?.length !== wlkContractIDs.length) {
      console.warn(
        `some contracts were filtered out. this is expected, request=${wlkContractIDs.length}, output=${reOrderContracts.length}`,
      );
    }

    return wlkContracts.map((wlkContract) => {
      const orderWithWLKForm = {} as REOrderWithBuyerAndSellerInfo;

      const reOrderContract = reOrderContracts?.find(
        (reOrder) => reOrder.detail.id === wlkContract.id,
      )?.summary;
      // // set the property data to the model
      orderWithWLKForm.id = parseInt(wlkContract.id);
      orderWithWLKForm.tableId = orderWithWLKForm.id;
      orderWithWLKForm.address = reOrderContract?.address;
      orderWithWLKForm.expirationDate = reOrderContract?.expirationDate;
      orderWithWLKForm.initiatingOfficeID = wlkContract.initiatingOffice?.officeId;
      orderWithWLKForm.initiatingAgentName = reOrderContract?.agentName;
      orderWithWLKForm.initiatingOfficeName = reOrderContract?.officeName;
      orderWithWLKForm.form = wlkContract.warrantylink
        ? wlkContract.warrantylink.form
        : DEFAULT_CUSTOMER_FORM;
      orderWithWLKForm.warrantylink = {
        submissionDate: wlkContract.warrantylink?.submissionDate,
        deadlineDate: wlkContract.warrantylink?.deadlineDate,
        status:
          wlkContract.warrantylink.status === 'Paid/Completed'
            ? WarrantylinkStatus.COMPLETED
            : wlkContract.warrantylink?.status,
      };
      return orderWithWLKForm;
    });
  }

  const refreshWarrantyLinkData = async (contractIDs: string[]): Promise<void> => {
    console.log(`refreshing contracts ${contractIDs.join()}`);
    const sourceData = await WarrantyLinkAPI.searchWarrantyLinkContracts({
      contractId: contractIDs.join(),
    });

    // map the source data to the new model
    let relevantSourceData =
      warrantyLinkContractResponseMapToREOrderWithBuyerAndSellerInfo(sourceData);

    // source data missing the address of contract, of which this code below is merging
    const destData = cloneDeep(warrantyLinkEligibleOrders); // clone to prevent modifying original var
    relevantSourceData = relevantSourceData.map((rsd) => {
      const matchingDestData = destData.find((wlkContract) => wlkContract.id === rsd.id);
      if (!matchingDestData) {
        console.warn(`wlk contract #${rsd.id} not found. cannot refresh its data in table`);
      } else {
        rsd.address = matchingDestData.address;
      }
      return rsd;
    });
    mergeBy<Partial<REOrderWithBuyerAndSellerInfo>>(destData, relevantSourceData);
    setWarrantyLinkEligibleOrders(destData);
  };

  function warrantyLinkContractResponseMapToREOrderWithBuyerAndSellerInfo(
    wlkResp: WarrantylinkContractResponse,
  ): Partial<REOrderWithBuyerAndSellerInfo>[] {
    const relevantSourceData = wlkResp.contracts.map((contract) => {
      const tmpContract: any = contract;
      tmpContract.id = parseInt(contract.id);

      const reOrderWithBuyerAndSellerInfo: Partial<REOrderWithBuyerAndSellerInfo> = tmpContract;
      reOrderWithBuyerAndSellerInfo.form = contract.warrantylink.form;

      return reOrderWithBuyerAndSellerInfo;
    });
    return relevantSourceData;
  }

  return (
    <Layout isLoggedIn={true} slug="warrantyLink">
      <Template
        summaryView={warrantyLinkEligibleOrders}
        wlkOfficeList={wlkOfficeList}
        isLoading={isOnLoad}
        canSubmitContracts={canSubmitContracts}
        refreshWarrantyLinkData={refreshWarrantyLinkData}
      />
    </Layout>
  );
};

export default WarrantylinkPage;
