import axios from 'axios';

let configs = {};

/** Returns the config value from the client-configuration */
export function getConfig(configName) {
  if (configs === undefined) {
    throw Error('Client Configuration has not been loaded');
  } else if (configs[configName] === undefined) {
    throw Error(`Property does not exist: ${configName}`);
  }
  return configs[configName];
}

/** Loads the configuration on App start */
export function loadConfigs() {
  return axios
    .get('/client-configuration')
    .then((res) => {
      configs = { ...res.data['common'], ...res.data['env'] };
      return configs;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}
