import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Input, Select, SelectOption, Text } from '@ftdr/blueprint-components-react';
import ProfileContext from '@context/ProfileContext';
import { QuoteSummary, QuoteSummarySelection } from '@components/misc/QuoteSummary';
import { PlansAndPricesFormData } from '@templates/misc/PlansAndPricesTemplate';
import AgentInfoInput from '@components/input/AgentInfoInput';
import { usStatesForBDSSelect } from '@constants/us-states';
import { validateStreetAddress } from '@services/validation/ValidationRules';
import ProductApi from '@apis/product.api';
import { CityStates, ProductPricingDetailsRequest, RESourcePagePlansPrices } from '@apis/models';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { msgsWParams } from '@app/locales/en';
import AddressSuggestionInput from '@components/input/AddressSuggestionInput';
import { fireGAEvent } from '@app/core/tracking.service';
import { PLAN_PRICE__SAVE_QUOTE } from '@constants/ga-events.constants';
import { InitiatingOffice } from '@apis/models/emailsender.api.model';
import ModalConfirmAddressEntered from '@components/modal/ModalConfirmAddressEntered';
import { isAppAddressMatch } from '@helpers/utils';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import AddressApi, { toApiAddress } from '@apis/address.api';
import { Address } from '@app/models';
import {
  updateOfficesOnSave,
  updateWorkedWithOfficesOnSave,
} from '@services/helpers/profile.offices.helper';
import { isCCUser } from '@helpers/profile.utils';
import ErrorText from '@components/error/ErrorText';

const LABELS = {
  HEADING: 'Save Quote',
  FORM_AGENT_INFORMATION: 'Agent Information',
  FORM_QUESTION_TITLE: 'Property Address',
  ADDRESS: 'Street Address',
  ADDRESS_PLACEHOLDER: '',
  UNIT_NUMBER: 'Unit #',
  UNIT_NUMBER_PLACEHOLDER: '',
  CITY: 'City',
  CITY_PLACEHOLDER: '',
  STATE: 'State',
  STATE_PLACEHOLDER: '',
  ZIP_CODE: 'Zipcode',
  ZIP_CODE_PLACEHOLDER: '',
  SUBMIT_BUTTON: 'Save Quote',
  CANCEL_BUTTON: 'Cancel',
};

const ERRORS = {
  CITY_REQUIRED: 'City required',
  STATE_REQUIRED: 'State required',
};

export interface PlanPriceSaveQuoteProps {
  quote: QuoteSummarySelection;
  formData: PlansAndPricesFormData;
  productPricingDetailsRequest: ProductPricingDetailsRequest;
  onClose: () => void;
  onSuccess?: () => void;
  cityOptions?: CityStates[];
}

const PlanPriceSaveQuote: React.FC<PlanPriceSaveQuoteProps> = (props) => {
  const { profile, setProfile } = useContext(ProfileContext);
  const [isREAgent, setIsREAgent] = useState(false);

  const [agentId, setAgentId] = useState<string>('');
  const [officeId, setOfficeId] = useState<string>('');

  const [address, setAddress] = useState<string>('');
  const [unit, setUnit] = useState<string>(props.productPricingDetailsRequest.property.address2);
  const [state, setState] = useState<string>(props.productPricingDetailsRequest.property.state);
  const [zipCode, setZipCode] = useState<string>(props.formData.zipCode);
  const [addressUUID, setAddressUUID] = useState<string>('');
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [addressMatch, setAddressMatch] = useState(null);
  const [showValidateAddressModal, setShowValidateAddressModal] = useState(false);

  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();
  const closeValidateAddressModal = () => setShowValidateAddressModal(false);
  const cityOptions = useMemo<SelectOption[]>(() => {
    return props.cityOptions?.map((cityState) => ({ value: cityState.city })) || [];
  }, [props.cityOptions]);
  const [citySelected, setCitySelected] = useState<SelectOption>(cityOptions[0] || { value: '' });

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const cityInput = e.currentTarget?.value || '';
    if (cityInput !== citySelected.value) {
      setFormErrors({ ...formErrors, city: CityValidationCheck(e.target.value) });
      setCitySelected({ value: e.target.value });
      setAddressUUID('');
    }
  };

  const handleSelect = (selected: SelectOption) => {
    const onCitySelectValue = selected.value || '';
    if (onCitySelectValue !== citySelected.value) {
      setCitySelected(selected as { value: string });
      setAddressUUID('');
      setFormErrors({ ...formErrors, city: CityValidationCheck(selected.value) });
    }
  };

  const [formErrors, setFormErrors] = useState({
    address: '',
    addressValidated: '',
    city: '',
    state: '',
    officeId: '',
    agentId: '',
  });

  /** on load of drawer, auto-fill the user's email/phone */
  useEffect(() => {
    if (profile) {
      setIsREAgent(profile.roleIDType === 'RealEstateAgent');
    }
  }, [profile]);

  /** whenever any of the form fields gets modified, apply validation to determine if submit button should be disabled. */
  useEffect(() => {
    setFormErrors({ ...formErrors, addressValidated: '' });
  }, [addressUUID]);

  const AddressValidationCheck = (value): string => {
    return validateStreetAddress(value, false);
  };

  const CityValidationCheck = (value: string): string => {
    if (value?.length === 0) {
      return ERRORS.CITY_REQUIRED;
    }
  };

  const StateValidationCheck = (value): string => {
    if (value?.length === 0) {
      return ERRORS.STATE_REQUIRED;
    }
  };

  const hasFormErrors = (errors = formErrors) => {
    return Object.values(errors).some((e) => !!e);
  };

  /** returns true if have form error. If we don't want to set the form error, set the param to false. */
  const validateAndHasErrors = (shouldSetFormErrors = true): boolean => {
    const errors: typeof formErrors = {
      address: AddressValidationCheck(address),
      addressValidated: '',
      city: CityValidationCheck(citySelected.value),
      state: StateValidationCheck(state),
      officeId: '',
      agentId: '',
    };

    if (!errors.address && !addressUUID) {
      errors.addressValidated = 'Validate Address';
    }

    if (shouldSetFormErrors) {
      setFormErrors(errors);
    }

    if (!isREAgent) {
      if (!officeId) {
        errors.officeId = 'Initiating office required';
      }
      if (!agentId) {
        errors.agentId = 'Initiating agent required';
      }
    }
    return hasFormErrors(errors);
  };

  const onCancel = () => {
    props.onClose();
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    if (validateAndHasErrors()) {
      return;
    }

    // GA EVent - Save Quote
    fireGAEvent(PLAN_PRICE__SAVE_QUOTE);

    const propertyAddress = {
      residenceType: props.productPricingDetailsRequest.property.residenceType,
      age: props.productPricingDetailsRequest.property.age,
      squareFootage: props.productPricingDetailsRequest.property.squareFootage,
    };

    const initiatingOffice: InitiatingOffice = {
      officeID: officeId,
      agentID: agentId,
      represents: null,
    };

    if (addressUUID) {
      setIsSubmitting(true);

      ProductApi.saveQuote({
        propertyAddressUUID: addressUUID,
        initiatingOffice,
        property: propertyAddress,
        options: props.productPricingDetailsRequest.options,
        selected: props.productPricingDetailsRequest.selected,
        sourcePage: RESourcePagePlansPrices,
      })
        .then((res) => {
          if (res?.quoteID) {
            addSuccessToQueue(msgsWParams.QUOTE_SAVED_SUCCESS(res?.orderID));
            if (isCCUser(profile) && officeId) {
              updateWorkedWithOfficesOnSave(profile, {
                active: true,
                address: { address1: '', address2: '', city: '', state: '', zip: '' },
                id: officeId,
                type: 'RealEstate',
                userExtensions: [],
                warrantyLinkEligible: false,
              });
            } else if (!isREAgent && officeId) {
              updateOfficesOnSave(
                profile,
                {
                  active: true,
                  address: { address1: '', address2: '', city: '', state: '', zip: '' },
                  id: officeId,
                  type: 'RealEstate',
                  userExtensions: [],
                  warrantyLinkEligible: false,
                },
                setProfile,
              );
            }
            props.onSuccess();
            onCancel();
          } else {
            setDisableSubmit(false);
            addErrorToQueue(msgsWParams.QUOTE_SAVED_FAILED());
          }
        })
        .catch((error) => {
          console.error(error);
          setDisableSubmit(false);
          addErrorToQueue(msgsWParams.QUOTE_SAVED_FAILED());
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      addErrorToQueue(msgsWParams.QUOTE_SAVED_FAILED());
    }
  };

  const changeValues = (initAgent, initOffice) => {
    setAgentId(initAgent);
    setOfficeId(initOffice);
  };

  const useSuggestedAddress = (addressObj = undefined) => {
    setShowValidateAddressModal(false);
    setAddress(addressObj.streetAddress);
    setUnit(addressObj.unit);
    setCitySelected({ value: addressObj.city });
    setAddressUUID(addressMatch?.meta?.addressId);
  };

  const useUserAddress = () => {
    setShowValidateAddressModal(false);
    useGlobalOverlaySpinner().showSpinner(true, 'saving address...');

    const unverifiedAddress: Address = {
      streetAddress: address,
      unit,
      city: citySelected.value,
      state,
      zip: zipCode,
    };

    AddressApi.createUnverifiedAddress(toApiAddress(unverifiedAddress))
      .then((verifiedAddress) => {
        setShowValidateAddressModal(false);
        setAddressUUID(verifiedAddress?.meta?.addressId);
        addSuccessToQueue(msgsWParams.QUOTE_ADDRESS_VALIDATED_SUCCESSFULLLY());
      })
      .catch((err) => {
        addErrorToQueue(msgsWParams.QUOTE_ADDRESS_UUID_CREATION_FAILED());
      })
      .finally(() => {
        useGlobalOverlaySpinner().showSpinner(false);
      });
  };

  const onAddressValidation = async () => {
    try {
      const propertyAddress = {
        streetAddress: address,
        unit,
        city: citySelected.value,
        state,
        zip: zipCode,
      };

      const res = await AddressApi.getAddressDetail(propertyAddress);

      if (res) {
        // If the addresses are same then no need to show modal/popup
        if (isAppAddressMatch(res, propertyAddress)) {
          setAddressUUID(res?.meta?.addressId);
          addSuccessToQueue(msgsWParams.QUOTE_ADDRESS_VALIDATED_SUCCESSFULLLY());
          return true;
        }
        setAddressMatch({ ...res, verified: true });
        setShowValidateAddressModal(true);

        return false;
      }

      setAddressMatch(null);
      setShowValidateAddressModal(true);
      return false;
    } catch (err) {
      console.error('failed to validate address', err);
      return false;
    }
  };

  return (
    <div className="full-height-card">
      <form>
        <Text id="save-quote--heading" variant="heading-02" color="primary" className="mb-8">
          {LABELS.HEADING}
        </Text>
        <div id="save-quote--form" className="flex flex-col space-y-4">
          {!isREAgent && (
            <div id="save-quote--agent" className="flex flex-col space-y-4 mb-4">
              <Text variant="heading-05" color="primary">
                {LABELS.FORM_AGENT_INFORMATION}
              </Text>
              <AgentInfoInput
                id="save-quote--recipient--name"
                profile={profile}
                values={{ agentId, officeId }}
                onChangeValues={(initAgent, initOffice) => changeValues(initAgent, initOffice)}
                officeError={formErrors.officeId}
                onOfficeError={() =>
                  setFormErrors({ ...formErrors, officeId: 'Initiating office required' })
                }
                onOfficeErrorClear={() => setFormErrors({ ...formErrors, officeId: '' })}
                agentError={formErrors.agentId}
                onAgentError={() =>
                  setFormErrors({ ...formErrors, agentId: 'Initiating agent required' })
                }
                onAgentErrorClear={() => setFormErrors({ ...formErrors, agentId: '' })}
                onSelectContactChange={() => {}}
                required={true}
              />
            </div>
          )}

          <div id="save-quote--address" className="flex flex-col space-y-4 mb-4 relative">
            <Text variant="heading-05" color="primary">
              {LABELS.FORM_QUESTION_TITLE}
            </Text>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="w-full sm:w-1/2 sm:pr-3">
                <AddressSuggestionInput
                  id="save-quote--address1"
                  name="save-quote--address1"
                  label={LABELS.ADDRESS}
                  addressLookup={{
                    city: citySelected.value,
                    state,
                    zip: zipCode,
                  }}
                  allowPOBox={true}
                  value={address || ''}
                  error={formErrors.address}
                  setValue={(addressObj) => {
                    setAddress(addressObj.streetAddress);
                    setUnit(addressObj.unit);
                    setCitySelected({ value: addressObj.city });
                  }}
                  setStreetAddressValue={(streetAddress) => {
                    setAddressUUID('');
                    setAddress(streetAddress);
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2">
                <Input
                  required={true}
                  formField={true}
                  id="save-quote--unit"
                  label={LABELS.UNIT_NUMBER}
                  placeholder={LABELS.UNIT_NUMBER_PLACEHOLDER}
                  autoComplete="off"
                  value={unit}
                  onChange={(e) => {
                    setAddressUUID('');
                    setUnit(e.target.value);
                  }}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div
            id="save-quote--location"
            className="flex flex-col md:flex-row space-y-4 md:space-y-0 relative"
          >
            <div className="w-full sm:w-1/2 sm:pr-3 sm:mb-2">
              <Select
                required={true}
                formField={true}
                autoComplete={citySelected.value === ''}
                autoCompleteFilterPredicate={() => true}
                className="w-full"
                id="wb_propertyAddressCity"
                name="city"
                label={LABELS.CITY}
                options={cityOptions}
                selected={citySelected}
                onBlur={handleBlur}
                onSelect={handleSelect}
                error={formErrors.city}
              />
            </div>
            <div className="w-full sm:w-1/2 ">
              <div className="flex flex-col md:flex-row  space-y-4 md:space-y-0">
                <div className="w-full sm:w-1/2 sm:pr-3 sm:mb-2">
                  <Select
                    formField={true}
                    id="wb_propertyAddressState"
                    name="state"
                    label={LABELS.STATE}
                    required={true}
                    options={usStatesForBDSSelect}
                    selected={state ? usStatesForBDSSelect.find((o) => o.value === state) : null}
                    onSelect={setState}
                    error={formErrors.state}
                    className="w-full"
                    disabled={true}
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <Input
                    required={true}
                    formField={true}
                    id="save-quote--zipcode"
                    label={LABELS.ZIP_CODE}
                    placeholder={LABELS.ZIP_CODE_PLACEHOLDER}
                    autoComplete="off"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="w-full"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-left sm-max:mt-4 md:ml-0">
            <Button
              id="save-quote--actions--validate-address"
              label="Validate Address"
              className="mt-4 md:mt-0"
              size="small"
              disabled={!address || addressUUID.length > 0}
              onClick={onAddressValidation}
            />
            <ErrorText text={formErrors.addressValidated} />
          </div>
        </div>
        <div className="border border-gray-300 rounded-3 shadow-1 w-full py-5 px-6 mt-8 mb-4">
          <QuoteSummary
            residenceType={props.formData.residenceType}
            sellersCoverage={props.formData.includeSellersCoverage}
            zipCode={props.formData.zipCode}
            quoteSummary={props.quote}
          />
        </div>
        <div
          id="share-quote--actions"
          className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-end"
        >
          <div className="text-center sm-max:mt-4 md:mr-4">
            <Button
              id="save-quote--actions--cancel"
              className="text-primary-400"
              variant="ghost"
              label="Cancel"
              size="small"
              onClick={onCancel}
            />
          </div>
          <div className="sm-max:w-1/2">
            <Button
              id="save-quote--actions--submit"
              type="submit"
              size="medium"
              width="full"
              className="mt-4 md:mt-0"
              label={LABELS.SUBMIT_BUTTON}
              onClick={onSubmit}
              loading={isSubmitting}
            />
          </div>
        </div>

        <ModalConfirmAddressEntered
          id="save-quote-property_address__address-confirmation-modal"
          heading="Property Address Verification"
          isActive={showValidateAddressModal}
          onClose={closeValidateAddressModal}
          addressFromService={addressMatch}
          addressFromInput={{
            verified: false,
            streetAddress: address,
            unit,
            city: citySelected.value,
            state,
            zip: zipCode,
          }}
          onEditAddress={closeValidateAddressModal}
          onUseServiceAddress={() => useSuggestedAddress(addressMatch)}
          onUseCurrentAddress={() => useUserAddress()}
        />
      </form>
    </div>
  );
};

export default PlanPriceSaveQuote;
