import AddressApi from '@apis/address.api';
import { ZipDetails } from '@apis/models';

/** @deprecated: This should be using AddressApi.getZipDetails() directly. */
export const searchCityStateByZip = (zip): Promise<ZipDetails> => {
  return AddressApi.getZipDetails(zip, false)
    .then((res: ZipDetails) => {
      if (res.cityStates && res.cityStates.length > 0) {
        return res;
      }
      return null;
    })
    .catch((error) => {
      console.log('searchCityStateByZip = ', error);
      return null;
    });
};
