import React, { useEffect, useState } from 'react';
import OfficeAutoComplete from '@components/autocomplete/OfficeAutocomplete';
import { Office } from '@apis/models';
import { Input } from '@ftdr/blueprint-components-react';
import { getFormInputErrorId } from '@storybook/addon-links';

export interface Props {
  /** ID of the input form field (autocomplete) */
  id: string;
  /** @deprecated: ID of the input without dropdown (Should not be used. Leaving alone to not break automated tests for now.) */
  idNoDropdown?: string;
  /** Name of the input form field */
  name: string;
  label: string;
  officeList: Partial<Office>[];
  setIsDrawerActive: any;
  isDrawerActive: boolean;
  handleOfficeSelect: any;
  selectedOffice: string;
  focusOfficeSelect: any;
  updateOfficeFilter: any;
  errors: any;
  handleBlur: any;
  showOfficeSuggestions: boolean;
  isDisabled?: boolean;
  showOnlyActive?: boolean;
}

function FormOfficeSelection(props: Props): JSX.Element {
  const [userOfficeDropdown, setUserOfficeDropdown] = useState(true);
  const [inputValue, setInputValue] = useState(props.selectedOffice ?? '');
  const [suggestions, setSuggestions] = useState<Partial<Office>[]>([]);

  // Keeping here in case we have use cases where this should be false in the future
  // Rather than deleting the code
  useEffect(() => {
    setUserOfficeDropdown(true);
  });

  useEffect(() => {
    setInputValue(props.selectedOffice ?? '');
  }, [props.selectedOffice]);

  const onFilterChange = (e: React.FormEvent<any>) => {
    setInputValue(e.currentTarget.value);
    props.updateOfficeFilter(e);
  };

  /** Update the filter */
  useEffect(() => {
    let filteredOfficeList: Partial<Office>[] = [];
    if (props.showOfficeSuggestions) {
      filteredOfficeList = props.officeList?.filter((o) => {
        return o.name.toLowerCase().includes(inputValue.toLowerCase());
      });
    }

    if (props.showOnlyActive) {
      // active only offices should be displayed in the office auto suggestions
      filteredOfficeList = filteredOfficeList.filter((o) => o.active);
    }
    setSuggestions(filteredOfficeList);
  }, [inputValue, props.officeList, props.showOfficeSuggestions]);

  function handleOfficeSelect(e) {
    setInputValue(e[0].name);
    props.handleOfficeSelect(e);
  }

  return (
    <>
      {userOfficeDropdown && (
        <div data-testid="form-office-selection__with-autocomplete" className="w-full relative">
          <OfficeAutoComplete
            itemList={suggestions}
            listKey="id"
            itemKey="name"
            openOfficeSearch={() => props.setIsDrawerActive(!props.isDrawerActive)}
            itemSelected={(e) => handleOfficeSelect(e)}
            showSuggestions={props.showOfficeSuggestions}
          >
            <Input
              formField={true}
              formFieldMessageId={getFormInputErrorId(props.id)}
              id={props.id}
              name={props.name}
              label={props.label}
              required={true}
              error={props.errors[props.name]}
              value={inputValue}
              onFocus={props.focusOfficeSelect}
              onChange={onFilterChange}
              onBlur={props.handleBlur}
              autoComplete="off"
              disabled={props.isDisabled}
            />
          </OfficeAutoComplete>
        </div>
      )}
      {!userOfficeDropdown && (
        <div
          id={props.idNoDropdown || `${props.id}__nodropdown`}
          data-testid="form-office-selection__without-autocomplete"
          className="cursor-pointer"
          onClick={() => props.setIsDrawerActive(!props.isDrawerActive)}
        >
          <div className="pointer-events-none">
            <Input
              formField={true}
              formFieldMessageId={getFormInputErrorId(`${props.id}__nodropdown__input`)}
              id={`${props.id}__nodropdown__input`}
              name={props.name}
              label={props.label}
              required={true}
              value={inputValue}
              error={props.errors[props.name]}
              onBlur={props.handleBlur}
              autoComplete="off"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FormOfficeSelection;
