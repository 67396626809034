import React, { useEffect } from 'react';
import { Router, View } from 'react-navi';
import * as Sentry from '@sentry/react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { mount, redirect, route } from 'navi';
import Orders from '@pages/account/Orders';
import Dashboard from '@pages/account/Dashboard';
import RegistrationAccount from '@pages/registration/RegistrationAccount';
import EditMyAccount from '@pages/account/EditMyAccount';
import NewOrder, { NewOrderRouteData } from '@pages/order/NewOrder';
import Offices from '@pages/office/Offices';
import ExistingUser from '@pages/registration/ExistingUser';
import WarrantyLink from '@pages/warrantylink/index';
import { HelmetProvider } from 'react-navi-helmet-async';
import OrderSubmitted from '@pages/order/OrderSubmitted';
import { getThemeClass } from '@ThemeContext';
import Callback from '@app/auth/callback';
import Path from '@constants/paths';
import requireAuth from './auth/requireAuth';
import AppCrashBoundary from './core/AppCrashBoundary';
import { GlobalAlertModal } from './core/GlobalAlertModal';
import { loadGlobalScripts } from './core/globalScripts';
import 'ie11-custom-properties';
import { useAuth } from '@ftdr/use-auth/build';
import SSORedirect from '@app/auth/ssoRedirect';
import VerifyEmail from '@pages/verify-email/verifyEmail';
import { GlobalOverlaySpinner } from '@components/spinner/GlobalOverlaySpinner';
import ProfileError from '@pages/errors/profileError';
import WarrantylinkAgreements from '@pages/warrantylink/WarrantylinkAgreements';
import WarrantylinkPayments from '@pages/warrantylink/WarrantylinkPayments';
import NonSupportIECheck from './core/NonSupportIECheck';
import { Permissions } from '@constants/dictionaries';
import Admin from '@pages/admin/Admin';
import FAQPage from '@pages/FAQ/FAQPage';
import ContactUs from '@pages/contact/ContactUs';
import ContactUsSuccess from '@pages/contact/ContactUsSuccess';
import PlansAndPrices from '@pages/misc/PlansAndPrices';
import { SalesMaterial } from '@pages/misc/SalesMaterial';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';
import { App as BlueprintApp, SupportedLanguages } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import { enUsLocale } from '@constants/bds.constants';
import GlobalDrawerOrder from '@app/core/GlobalDrawerOrder';
import DemoPageLayout from '@components/layout/DemoPageLayout';
import { DemoPages } from '@constants/demo.paths';
import { loadWindowScript, useOptimizely } from '@context/OptimizelyContext';
import NewOrderStep1 from '@pages/order/NewOrderStep1';
import NewOrderStep2 from '@pages/order/NewOrderStep2';
import NewOrderStep3 from '@pages/order/NewOrderStep3';
import NewOrderStep4 from '@pages/order/NewOrderStep4';
import NewOrderComplete from '@pages/order/NewOrderComplete';
import Quotes from '@pages/account/Quotes';
import deepLinkService from '@services/deepLink/deepLink.service';
import utmParametersService from '@services/utmParameters/utmParameters.service';
import queryParamParserService from '@services/queryParamParserService/queryParam.service';
import { DefaultPropsContextProvider, DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES } from "@ftdr/blueprint-components-react";

const brand = getBrand();

const appSettings = {
  language: 'en_US' as SupportedLanguages,
  textTemplatesByLanguage: {
    ...enUsLocale,
  },
};

const errorFallback = ({ error }) => {
  return <AppCrashBoundary error={error} />;
};

const App = () => {
  const optimizely = useOptimizely();

  // TODO Consider changing how we pass useAuth() into requireAuth
  const { oidcUser } = useAuth();

  useEffect(() => {
    loadGlobalScripts();
    loadWindowScript(optimizely);
    deepLinkService.init();
    utmParametersService.init();
    queryParamParserService.init();
  }, []);

  useEffect(() => {
    const useAhs2024BdsTheme = brand === Brand.AHS && IsFeatureEnabled(Features.Ahs2024BdsTheme);

    if (useAhs2024BdsTheme) {
      // @ts-expect-error ide doesn't see the file
      import(`../node_modules/@ftdr/blueprint-core/themes/ahs-2024.css`)
        .then(() => {
          document.body.className = "ahs-2024";
        })
        .catch(error => console.error("error loading AHS 2024 theme", error));
    }

  }, [])

  function getRoutes() {
    const demoRoutes = DemoPages.reduce((acc, demoPage) => {
      return {
        ...acc,
        [demoPage.path]: route({
          title: demoPage.path,
          view: requireAuth(
            demoPage.disableDefaultDemoLayout ? (
              <demoPage.component />
            ) : (
              <DemoPageLayout>
                <demoPage.component />
              </DemoPageLayout>
            ),
            demoPage.path,
            useAuth(),
          ),
        }),
      };
    }, {});

    let newOrderRedesignRoutes = {};
    if (IsFeatureEnabled(Features.NewOrderRedesign)) {
      newOrderRedesignRoutes = {
        [Path.NewOrderStep1]: route({
          title: 'Place New Order | Step 1',
          view: requireAuth(<NewOrderStep1 />, Path.NewOrderStep1, useAuth()),
        }),
        [Path.NewOrderStep2]: route({
          title: 'Place New Order | Step 2',
          view: requireAuth(<NewOrderStep2 />, Path.NewOrderStep2, useAuth()),
        }),
        [Path.NewOrderStep3]: route({
          title: 'Place New Order | Step 3',
          view: requireAuth(<NewOrderStep3 />, Path.NewOrderStep3, useAuth()),
        }),
        [Path.NewOrderStep4]: route({
          title: 'Place New Order | Step 4',
          view: requireAuth(<NewOrderStep4 />, Path.NewOrderStep4, useAuth()),
        }),
        [Path.NewOrderComplete]: route({
          title: 'Place New Order | Submitted',
          getData: (data) => {
            return data.body;
          },
          view: requireAuth(<NewOrderComplete />, Path.NewOrderComplete, useAuth()),
        }),
      };
    }

    const routes = {
      ...demoRoutes,
      [Path.HomePage]: route({
        title: 'Orders',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Orders />, Path.Dashboard, useAuth()),
      }),
      [Path.Dashboard]: route({
        title: 'Orders',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Orders />, Path.Dashboard, useAuth()),
      }),
      [Path.RegistrationAccount]: route({
        title: 'Registration',
        view: requireAuth(
          <RegistrationAccount registrationType="self" />,
          Path.RegistrationAccount,
          useAuth(),
          { shouldRequireProfile: false, shouldCheckInactiveOffices: false },
        ),
      }),
      [Path.RegistrationExistingUser]: route({
        title: 'Existing User',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(
          <ExistingUser registrationType="self" />,
          Path.RegistrationExistingUser,
          useAuth(),
          { shouldRequireProfile: false },
        ),
      }),
      [Path.Account]: route({
        title: 'Account Details',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<EditMyAccount />, Path.Account, useAuth()),
      }),
      ...newOrderRedesignRoutes,
      [Path.NewOrder]: route({
        title: 'Place New Order',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<NewOrder />, Path.NewOrder, useAuth()),
      }),
      [Path.ConvertNewOrder]: route({
        title: 'Place New Order',
        getData: (data) => {
          return {
            ...data.body,
            params: data.params,
          } as NewOrderRouteData;
        },
        view: requireAuth(<NewOrder />, Path.NewOrder, useAuth()),
      }),
      [Path.MyOrdersDetails]: route({
        title: 'My Orders',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Orders />, Path.MyOrders, useAuth()),
      }),
      [Path.MyOrders]: route({
        title: 'My Orders',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Orders />, Path.MyOrders, useAuth()),
      }),
      [Path.Warrantylink]: route({
        title: 'Warranty Link',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<WarrantyLink />, Path.Warrantylink, useAuth()),
      }),
      [Path.WarrantylinkAgreements]: route({
        title: 'Warranty Link | Program Agreement',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<WarrantylinkAgreements />, Path.WarrantylinkAgreements, useAuth()),
      }),
      [Path.WarrantylinkPayments]: route({
        title: 'Warranty Link | Payments',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<WarrantylinkPayments />, Path.WarrantylinkPayments, useAuth()),
      }),
      [Path.NewOrderSubmitted]: route({
        title: 'Order Submitted',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<OrderSubmitted />, Path.NewOrderSubmitted, useAuth()),
      }),
      [Path.Callback]: route({
        title: 'Callback',
        view: <Callback authService={useAuth()} />,
      }),
      [Path.SSORedirect]: route({
        title: 'SSO',
        view: <SSORedirect authService={useAuth()} />,
      }),
      [Path.VerifyEmail]: route({
        title: 'Verify Email',
        view: <VerifyEmail authService={useAuth()} />,
      }),
      [Path.ProfileError]: route({
        title: 'Profile Error',
        getData: (data) => {
          return data.body;
        },
        view: <ProfileError />,
      }),
      [Path.MyOffices]: route({
        title: 'Offices',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Offices />, Path.MyOffices, useAuth()),
      }),
      [Path.SiteAdmin]: route({
        title: 'User Maintenance',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Admin />, Path.SiteAdmin, useAuth(), {
          requiredPermissions: [Permissions.UserMaintenance],
        }),
      }),
      [Path.FAQ]: route({
        title: 'FAQs',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<FAQPage />, Path.FAQ, useAuth(), { shouldRequireAuth: false }),
      }),
      [Path.ContactUs]: route({
        title: 'Contact Us',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<ContactUs />, Path.ContactUs, useAuth(), { shouldRequireAuth: false }),
      }),
      [Path.ContactUsSuccess]: route({
        title: 'Contact Us Success',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<ContactUsSuccess />, Path.ContactUsSuccess, useAuth(), {
          shouldRequireAuth: false,
        }),
      }),
      [Path.HomePage]: route({
        title: 'Dashboard',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Dashboard />, Path.Dashboard, useAuth()),
      }),
      [Path.Dashboard]: route({
        title: 'Dashboard',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Dashboard />, Path.Dashboard, useAuth()),
      }),

      '*': redirect('/', { exact: false }),
    };

    addMyQuoteRoute(routes);
    addFeatureToggleRoutes(routes);
    return mount(routes);
  }

  function addMyQuoteRoute(routes): void {
    if (brand === Brand.AHS && IsFeatureEnabled(Features.MyQuotes)) {
      routes[Path.MyQuote] = route({
        title: 'My Quotes',
        getData: (data) => {
          return data.body;
        },
        view: requireAuth(<Quotes />, Path.MyQuote, useAuth()),
      });
    }
  }

  function addFeatureToggleRoutes(routes): void {
    addSalesMaterialFeatureRoutes(routes);

    if (brand === Brand.AHS) {
      addPlansAndPricesFeatureRoutes(routes);
    }
  }

  function addSalesMaterialFeatureRoutes(routes): void {
    routes[Path.SalesMaterial] = route({
      title: 'Sales Material',
      getData: (data) => data.body,
      view: requireAuth(<SalesMaterial />, Path.SalesMaterial, useAuth()),
    });
  }

  function addPlansAndPricesFeatureRoutes(routes): void {
    routes[Path.PlansAndPrices] = route({
      title: 'Plans & Prices',
      getData: (data) => data.body,
      view: requireAuth(<PlansAndPrices />, Path.PlansAndPrices, useAuth()),
    });
  }

  return (
    // ...useAuth() doesn't expose it's isLoading value,
    // but instead starts oidcUser as undefined and upon finishing loading,
    // sets oidcUser to null.  So we have to explicitly check for undefined
    // on the oidcUser to determine if it's finished loading or not
    oidcUser !== undefined && (
      // TODO Can remove other calls to theme; we should only be setting Theme class here and components can key off it
      <BlueprintApp
        appSettings={appSettings}
        className={classNames([
          // deprecated simplefocus class
          getThemeClass(),

          // these two classes are put into the body directly. see the README.md for more details on it under the BDS Migrations section
          // 'bds-ahs-web-app', 'fix-minified-bds',

          // these classes has been commented out and moved out to the body itself, as there were
          // too many problems with rendered components outside of the root element with all the SF and minified BDS stuff.
          // the classes below have been commented out, but the description is left here so we have it as reference,
          // leaving it in index.html would be exposing it to the public

          // the BDS minified parent class. required to use the minified file
          // 'bds-ahs-web-app',

          // all of our classes (whether it be tailwind or custom css classes) were being overruled
          // by the minified parent class due to CSS precedence hierarchy rules.
          // for example, '.my-4' would not be applied properly because '.bds-ahs-web-app .component' with a margin would be higher precedence.
          // by adding this class and using it in the postcss.config.js to prefix the css, we can get around the problem temporarily.
          // once we are entirely not reliant on maintaining both SimpleFocus and BDS together,
          // we can remove this and use the BDS tailwind directly instead of the static minified version.
          // 'fix-minified-bds',
        ])}
      >
        <DefaultPropsContextProvider value={(brand === Brand.AHS && IsFeatureEnabled(Features.Ahs2024BdsTheme)) ? DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES : null}>
          <SentryErrorBoundary fallback={errorFallback}>
            <HelmetProvider>
              <Router routes={getRoutes()}>
                <View />
                <GlobalAlertModal />
                <GlobalOverlaySpinner />
                <GlobalDrawerOrder />
              </Router>
            </HelmetProvider>
          </SentryErrorBoundary>
          <NonSupportIECheck />
        </DefaultPropsContextProvider>
      </BlueprintApp>
    )
  );
};

export default Sentry.withProfiler(App);
