/** *************************************************
 * Anything related to brands should be based on
 * constants to be consistent.
 ************************************************** */

import { getBrand } from '@helpers/brand.utils';

export interface BrandConstant {
  code: Brand;
  /** Brand name display string */
  brandName: string;
  /** Terms of Use redirect URL */
  termsOfUseLink: string;
  /** Customer support email */
  supportEmail: string;
  /** Customer support phone */
  supportPhone: string;
  /** Email address verification emails are sent from */
  verificationEmail: string;
  /** Root URL for the parent site */
  rootURL: string;
}

interface BrandConstants {
  AHS: BrandConstant;
  HSA: BrandConstant;
}

export enum Brand {
  AHS = 'AHS',
  HSA = 'HSA',
}

const BrandConstantsValues: BrandConstants = {
  AHS: {
    code: Brand.AHS,
    brandName: 'American Home Shield',
    supportEmail: 'prosupport@ahs.com',
    supportPhone: '800-735-4663',
    termsOfUseLink: 'https://www.frontdoor.com/contractor-terms-of-use',
    verificationEmail: 'no-reply.pro.ahs.com@ahs.com',
    rootURL: 'https://www.ahs.com',
  },
  HSA: {
    code: Brand.HSA,
    brandName: 'HSA',
    supportEmail: 'ownsupport@onlinehsa.com',
    supportPhone: '800-367-1448',
    termsOfUseLink: 'https://www.frontdoor.com/contractor-terms-of-use',
    verificationEmail: 'no-reply.own.onlinehsa@onlinehsa.com',
    rootURL: 'https://www.onlinehsa.com',
  },
};

/** Get constants for a brand. Defaults to brand.utils:getBrand() if unprovided. */
export default function getBrandConstants(brand: string = getBrand()): BrandConstant {
  if (BrandConstantsValues[brand]) {
    return BrandConstantsValues[brand];
  }
  console.warn('Unknown brand, defaulting to AHS');
  return BrandConstantsValues.AHS;
}
