// this file is temprarory as service is not ready yet for list of offices

const list = [
  {
    id: 1,
    name: "Matt's Homes Forever Downtown1",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown2",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown3",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown4",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown5",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown7",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown8",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown9",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown10",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown11",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown14",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown15",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown16",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown17",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown19",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown21",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown24",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown25",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown27",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
  {
    id: 2,
    name: "Matt's Homes Forever Downtown31",
    address: {
      street: '12345 Street St.',
      city: 'Memphis',
      state: 'TN',
      zip: '38104',
    },
    phoneNumber: '901-555-5555',
    employees: 50,
    orders: 100,
  },
];

export default list;
