import React from 'react';
import { classNames } from '@utils';
import { CONTENT_BOX_CONTAINER_WIDTH_CLASSNAMES } from '@components/layout/ContentBox';

export interface ContentBoxContainerProps {
  className?: string;
}

/** Applies just the width of the content box. Used by the child if they are using in conjunction of 'applyContainerWidthToHeaderOnly' */
export const ContentBoxContainer: React.FC<ContentBoxContainerProps> = (props) => {
  return (
    <div className={classNames([CONTENT_BOX_CONTAINER_WIDTH_CLASSNAMES, props.className])}>
      {props.children}
    </div>
  );
};
