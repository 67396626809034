import { getBrand } from '@helpers/brand.utils';
import { toTitleCase } from '@helpers/utils';
import { BaseAPI, Options } from './_api';
import {
  AgentType,
  OfficeTypeCode,
  REQuoteSearch,
  REQuoteSearchRequest,
  REQuoteSearchResponse,
} from './models';
import { publishToRefreshedREQuotes } from '@app/core/refreshContract.service';
import {
  CancelQuoteModel,
  CancelReasonCategory,
  Quote,
  QuoteDetail,
  QuoteFeatures,
  QuoteSummary,
} from './models/quote.api.model';
import { AxiosError } from 'axios';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { cloneDeep } from 'lodash';
import { UnsubmittedOrderCancellationWorkflow } from '@constants/app.constants';

export class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Brand-Name': getBrand(),
      },
      timeout: 0, // disable timeout for RE Order so that it can be controlled on app layer
    });
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public async searchProfileQuotes(
    req?: REQuoteSearchRequest,
    opts?: Options,
  ): Promise<TranslatedREQuoteResponse> {
    const request = cloneDeep(req) as any;

    // Workaround because we reuse an object in a lot of code but our API uses a slightly different name
    // Could add a new object, but would require a surgical refactor
    // API service layer is an excellent spot to just adjust this with little effort
    request.data.order.statuses =
      request?.data?.order?.realEstateStatus?.map(
        (status) => translatedStatuses[status.toUpperCase()],
      ) || [];
    delete request.data.order.realEstateStatus;

    return this.axios
      .post<any, REQuoteSearchResponse>(`${this.getHost()}/orders/search`, request, opts)
      .then((res) => {
        if (res) {
          return translateREQuoteSearchResponse(res);
        }
        return null;
      })
      .then((res) => {
        publishToRefreshedREQuotes(res.orders);
        return res;
      })
      .catch((err) => {
        return null;
      });
  }

  public async getCancelReason(): Promise<CancelReasonCategory> {
    return this.axios
      .get<any, CancelReasonCategory>(`${this.getHost()}/plans-and-prices/cancel-reasons`)
      .then((response) => {
        return response; // Success has blank response
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred in cancelling reason ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  public async cancelQuote(quoteId: string, data: CancelQuoteModel): Promise<boolean> {
    return this.axios
      .post<any, any>(`${this.getHost()}/plans-and-prices/order/${quoteId}/cancel`, data)
      .then((response) => {
        return response?.error?.length > 0 ? false : true;
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred in cancelling quote ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  /** used for Not Submitted (abandoned) orders.  Cancels with a default reason */
  public async cancelUnsubmittedOrder(
    orderID: string,
    workflow: UnsubmittedOrderCancellationWorkflow,
  ): Promise<boolean> {
    return this.getCancelReason()
      .then((res) => {
        if (!res) {
          throw new Error('unable to load cancellation reason category');
        }
        const otherCategory = res.categories.find((c) => c.name.toLocaleLowerCase() === 'other');
        if (!otherCategory) {
          throw new Error('unable to find cancellation reason Other category');
        }
        return this.cancelQuote(orderID, {
          category: otherCategory.id,
          description: `Unsubmitted order cancelled from ${workflow}`,
          subCategory: null,
        });
      })
      .catch((err) => {
        console.error('failed to cancel unsubmitted order', err);
        throw new Error('failed to cancel unsubmitted order');
      });
  }

  /** Perform searchProfileContract for a single contract id */
  public async searchProfileQuoteByQuoteID(quoteID: string): Promise<REQuoteSearchResponse> {
    return this.searchProfileQuotes({
      data: {
        order: {
          ids: [quoteID],
          realEstateStatus: [],
          awaitingWLKSubmissionOnly: false,
          statusListingMissingCOEOnly: false,
          sourceApplicationFlows: ['RE-PlansPrices'], //If empty or not passed, default source is RE-PlansPrices
        },
        agents: [],
        offices: [],
        date: {
          dateType: '',
          endDate: '',
          startDate: '',
        },
        query: '',
        filters: [],
      },
      meta: {
        asc: false,
        limit: 1,
        page: 0,
        sortBy: '',
      },
      include: {
        warrantylink: false,
        meta: true,
      },
    });
  }
}

const RealEstateQuoteApi = new API();
export default RealEstateQuoteApi;

const translatedStatuses = {
  'CONVERTED TO ORDER': 'COMPLETE',
  SAVED: 'NEW',
  SHARED: 'SHARED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  NEW: 'NEW',
  ABANDONED: 'ABANDONED',
};

/** fix casting issues being hidden away */
type TranslatedREQuoteResponse = Omit<REQuoteSearchResponse, 'orders'> & { orders: Quote[] };

/** Performs any translations to the response object, as required */
function translateREQuoteSearchResponse(
  response: REQuoteSearchResponse,
): TranslatedREQuoteResponse {
  response.meta.totalStatus.Saved = response.meta.totalStatus?.NEW;
  response.meta.totalStatus.Shared = response.meta.totalStatus?.SHARED;
  response.meta.totalStatus.Cancelled = response.meta.totalStatus?.CANCELLED;
  response.meta.totalStatus.Expired = response.meta.totalStatus?.EXPIRED;
  response.meta.totalStatus['Converted to order'] = response.meta.totalStatus?.COMPLETE;

  return {
    meta: response.meta,
    orders: response.orders.map(translateREQuoteSearchQuote),
  };
}

/** Perform any translation to the order, such as titlecase */
function translateREQuoteSearchQuote(quote: REQuoteSearch): Quote {
  const { id, status, effectiveDate, expirationDate, property, offices, address } = quote;

  const summary: QuoteSummary = {
    id: id.toString(),
    status,
    expirationDate: expirationDate === '0001-01-01T00:00:00Z' ? '' : expirationDate,
    address: {
      address1: toTitleCase(quote.property.address1),
      address2: toTitleCase(quote.property.address2),
      city: toTitleCase(quote.property.city),
      state: quote.property.state,
      zip: quote.property.zip,
    },
    awaitingWlkSubmission: false,
    realEstateStatus: status as RealEstateStatus,
    agentName: null,
    officeName: null,
  };

  if (offices && offices.length > 0) {
    const initiatingOffice = offices.find((office) => office.initiatingOffice);
    if (initiatingOffice) {
      summary.agentName = initiatingOffice.agentName;
      summary.officeName = initiatingOffice.officeName;
    }
  }

  const detail: QuoteDetail = {
    id,
    QuoteStatus: status,
    brand: '',
    mlsNumber: '', // TODO: Add to app layer
    closingFileNumber: '', // TODO: Add to app layer
    details: [], // TODO: deprecate this
    importantDates: {
      creationDate: quote.effectiveDate,
      listingExpirationDate: '',
      sellersCoverageEffectiveDate: '',
      sellersCoverageExpirationDate: '',
      projectedClosingDate: '',
      effectiveDate,
      expirationDate: expirationDate === '0001-01-01T00:00:00Z' ? '' : expirationDate,
      closingDate: '',
      listDate: '', // Listdate is the unformatted version of creationDate, it's used for product search
    },
    property: {
      ID: '',
      type: '',
      dwellingType: property.dwellingTypeCode,
      streetAddress: property.address1,
      streetAddress2: property.address2,
      city: property.city,
      state: property.state,
      zip: property.zip,
      zipFour: '',
      streetNumber: '',
      streetDirection: '',
      streetName: '',
      unitType: '',
      unitNumber: '',
      squareFeet: Number(property.squareFeet),
      yearBuilt: '',
      numberMotherLawUnits: 0,
      propertyValue: 0,
      addressUUID: '',
    },
    initiatingOfficeAgent: {
      office: {
        id: '',
        name: '',
        type: OfficeTypeCode.RealEstate,
        brand: '',
        active: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
        phones: {
          fax: '',
          office: '',
        },
        compositeSalesRank: '',
        franchiseCode: '',
        territoryCode: '',
        warrantyLinkEligible: false,
        warrantyLinkStatus: '',
        email: '',
      },
      agent: {
        realEstateAgentID: '',
        agentType: AgentType.RealEstate,
        active: false,
        narID: '',
        firstName: '',
        lastName: '',
        phoneNumbers: [],
        email: '',
        officeID: '',
        archivedOfficeID: [],
      },
      represents: '',
    },
    cooperatingOfficeAgent: {
      office: {
        id: '',
        name: '',
        type: OfficeTypeCode.RealEstate,
        brand: '',
        active: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
        phones: {
          fax: '',
          office: '',
        },
        compositeSalesRank: '',
        franchiseCode: '',
        territoryCode: '',
        warrantyLinkEligible: false,
        warrantyLinkStatus: '',
        email: '',
      },
      agent: {
        realEstateAgentID: '',
        agentType: AgentType.RealEstate,
        active: false,
        narID: '',
        firstName: '',
        lastName: '',
        phoneNumbers: [],
        email: '',
        officeID: '',
        archivedOfficeID: [],
      },
      represents: '',
    },
    closingOfficeAgent: {
      office: {
        id: '',
        name: '',
        type: OfficeTypeCode.RealEstate,
        brand: '',
        active: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
        phones: {
          fax: '',
          office: '',
        },
        compositeSalesRank: '',
        franchiseCode: '',
        territoryCode: '',
        warrantyLinkEligible: false,
        warrantyLinkStatus: '',
        email: '',
      },
      agent: {
        realEstateAgentID: '',
        agentType: AgentType.RealEstate,
        active: false,
        narID: '',
        firstName: '',
        lastName: '',
        phoneNumbers: [],
        email: '',
        officeID: '',
        archivedOfficeID: [],
      },
      represents: '',
    },
    customers: [],
  };

  const features: QuoteFeatures = {
    extendListing: {
      canExtend: false,
      daysToExtend: 0,
      dateTypes: [],
    },
    warrantylink: {
      hasUnsubmittedWarrantylink: false,
    },
    serviceRequest: {
      canRequestService: false,
    },
    makePayment: {
      isPayable: false,
    },
    cancelListing: {
      isCancellable: false,
    },
  };

  quote.tableId = quote.id;

  quote.address = quote.property && {
    ...quote.property,
    address1: toTitleCase(quote.property.address1),
    address2: toTitleCase(quote.property.address2),
    city: toTitleCase(quote.property.city),
  };

  quote.summary = summary;
  quote.detail = detail;
  quote.features = features;

  return quote as Quote;
}
