import React from 'react';
import {
  INVALID_PHONE_NUMBER,
  PHONE_NUMBER_REQUIRED,
  PHONE_WITH_MASK_MAX_LENGTH,
} from '@constants/formField-constants';
import {
  MaskedInputWithValidation,
  MaskedInputWithValidationProps,
} from '@components/input/InputWithValidation';
import { cleanPhone } from '@helpers/utils';
import { isPhoneNumberValid } from '@services/validation/ValidationRules';
import merge from 'lodash/merge';

type OwnPhoneInputProps = {
  /** set if need to have a custom error message for anything. */
  errorMessages?: PhoneInputErrorMessages;
};

export type PhoneInputProps = OwnPhoneInputProps &
  Omit<
    MaskedInputWithValidationProps,
    'type' | 'inputMode' | 'mask' | 'maxLength' | 'formatChangeValue' | 'label' | 'validateValue'
  > &
  Partial<Pick<MaskedInputWithValidationProps, 'label' | 'validateValue'>>;

export type PhoneInputErrorMessages = {
  errorRequired?: string;
  errorInvalidFormat?: string;
};

const DEFAULT_ERRORS: PhoneInputErrorMessages = {
  errorRequired: PHONE_NUMBER_REQUIRED,
  errorInvalidFormat: INVALID_PHONE_NUMBER,
};

/** used to validate the PhoneInput. If there are any special handling necessary, can re-use this function for any purpose.
 * @param value - the input value
 * @param emptyNotAllowed - true if the field is required and should perform empty check.
 * @param errorMessages - set if want to override any existing error message. Leave blank to use default error message. */
export const validatePhoneInput = (
  value: string,
  emptyNotAllowed: boolean,
  errorMessages: PhoneInputErrorMessages = {},
) => {
  const errors = merge({}, DEFAULT_ERRORS, errorMessages);
  if (emptyNotAllowed && value.length === 0) {
    return errors.errorRequired;
  }
  if (value.length > 0 && !isPhoneNumberValid(value)) {
    return errors.errorInvalidFormat;
  }
};

/** function will unmask and return a raw phone number value. */
export const formatPhoneInput = (value: string): string => {
  return cleanPhone(value);
};

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  /** use the default validation function if there is no custom one. Otherwise, use the custom one. */
  const validateValue = (value: string) => {
    if (!props.validateValue) {
      return validatePhoneInput(value, props.required, props.errorMessages);
    }
    return props.validateValue(value);
  };

  return (
    <MaskedInputWithValidation
      type="tel"
      inputMode="tel"
      mask="(000) 000-0000"
      maxLength={PHONE_WITH_MASK_MAX_LENGTH}
      label={props.label}
      formatChangeValue={formatPhoneInput}
      validateValue={validateValue}
      autoComplete="off"
      {...props}
    />
  );
};

PhoneInput.defaultProps = {
  label: 'Phone Number',
};

export default PhoneInput;
