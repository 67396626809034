import React, { Component } from 'react';
import { SearchButton } from '@components/button/SearchButton';
import CardUserDetails from '@components/card/CardUserDetails';
import { UserDetails } from '@app/models/profile.model';
import ProfileApi from '@apis/profile.api';
import Drawer from '@components/drawer/Drawer';
import ViewUserDetails from '@components/view/ViewUserDetails';
import { IconSearch, Input, Panel } from '@ftdr/blueprint-components-react';

interface state {
  searchCriteria: string;
  searchResults: UserDetails[];
  isActive: boolean;
}
export default class AdminSearch extends Component<unknown, state> {
  selectedUser = null;

  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: '',
      searchResults: [],
      isActive: false,
    };
  }

  onChangeSearch = (event) => {
    this.setState({ searchCriteria: event.target.value });
  };

  handleSearch = () => {
    ProfileApi.searchUsers(this.state.searchCriteria).then((response) => {
      this.setState({ searchResults: response });
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  onClickUserDetails = (userDetails) => {
    this.selectedUser = userDetails;
    this.setState({ isActive: true });
  };

  updateUser = (updatedUser) => {
    const tempArray = this.state.searchResults;
    const userIndex = this.state.searchResults.findIndex(
      (user) => user.userID === updatedUser.userID,
    );

    if (userIndex > -1) {
      tempArray[userIndex] = updatedUser;
      this.setState({ searchResults: tempArray });
    }
  };

  removeSearchResult = (userID) => {
    this.setState({
      searchResults: this.state.searchResults.filter((user) => user.userID !== userID),
      isActive: false,
    });
  };

  render() {
    return (
      <Panel className="mt-6" shadow={true} border="0" rounded="sm" padding="xl">
        <div className="flex items-center">
          <div
            className="w-full card card--search w-full shadow-none mr-8"
            style={{ float: 'left' }}
          >
            <Input
              type="text"
              name="search"
              label="Search by first name, last name, user type, or office affiliation"
              placeholder="Search by first name, last name, user type, or office affiliation"
              className="p-0"
              startEnhancer={<IconSearch />}
              value={this.state.searchCriteria}
              onChange={this.onChangeSearch}
              autoComplete="off"
              onKeyDown={this.handleKeyDown}
              autoFocus={true}
            />
          </div>
          <SearchButton
            id="adminSearchButton"
            onClick={this.handleSearch}
            className="flex items-center"
          />
        </div>
        <div className="flex flex-wrap -mx-2">
          {this.state.searchResults.length > 0 ? (
            <>
              {this.state.searchResults.map((user) => {
                return (
                  <div key={user.userID} className="w-full sm:w-1/2 lg:w-1/3 p-2">
                    <CardUserDetails user={user} onClick={() => this.onClickUserDetails(user)} />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="m-2 pl-4">No results found</div>
          )}
        </div>
        <Drawer isActive={this.state.isActive} onClose={() => this.setState({ isActive: false })}>
          {this.selectedUser && this.state.isActive && (
            <ViewUserDetails
              user={this.selectedUser}
              updateUser={this.updateUser}
              removeUser={this.removeSearchResult}
            />
          )}
        </Drawer>
      </Panel>
    );
  }
}
