import React, { useEffect, useMemo, useState } from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import MakePaymentStripe, {
  MakePaymentStripeProps,
} from '@components/drawer/subcomponents/MakePaymentStripe';
import ModalServiceUnavailable from '@components/modal/ModalServiceUnavailable';
import ModalMustHaveBuyer from '@components/modal/ModalMustHaveBuyer';
import { CustomerData } from '@apis/models';

interface DrawerMakePaymentStripeProps
  extends Required<Pick<DrawerProps, 'isActive' | 'onClose'>>,
    MakePaymentStripeProps {}

const DrawerMakePaymentStripe: React.FC<DrawerMakePaymentStripeProps> = (props) => {
  const closeDrawer = () => {
    props.onClose();
  };

  // Double check for buyer as it's needed to make a payment
  if (!props?.contract?.detail?.customers.some((customer) => customer.type === 'BUY')) {
    return (
      <>
        <ModalMustHaveBuyer
          id="card-new-order-property_address__service-unavailable-modal"
          isActive={props.isActive}
          onClose={closeDrawer}
          heading="Missing buyer"
        />
      </>
    );
  }
  return (
    <>
      <Drawer {...props} onClose={closeDrawer} useDefaultWidth={false}>
        {props.isActive && <MakePaymentStripe {...props} closeDrawer={closeDrawer} />}
      </Drawer>
    </>
  );
};

export default DrawerMakePaymentStripe;
