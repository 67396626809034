import React, { useState } from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button, IconPhone } from '@ftdr/blueprint-components-react';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_ACTION_ADD_MISSING_BUYER_PHONE } from '@constants/ga-events.constants';
import { toFormattedNumber } from '@helpers/utils';
import DrawerOrdersMissingBuyerPhone from '@components/drawer/DrawerOrdersMissingBuyerPhone';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

const CardDashBoardActionMissingBuyerPhone = (props: ICardDashBoardActionProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const openDrawer = () => {
    fireGAEvent(DASHBOARD_ACTION_ADD_MISSING_BUYER_PHONE);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <CardDashboardAction
        isManualFetch={props.isManualFetch}
        fetchCount={props.fetchCount}
        type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
        heading={props.count === null ? null : toFormattedNumber(props.count)}
        subHeading="Missing Buyer Phone Number"
        action={
          <Button
            label="Add phone number"
            size="medium"
            startIcon={<IconPhone />}
            disabled={props.count === null || props.count === 0}
            onClick={() => openDrawer()}
          />
        }
      />
      <DrawerOrdersMissingBuyerPhone
        isActive={isDrawerOpen}
        onUpdateOrderCount={props.updateCount}
        onClose={() => setIsDrawerOpen(false)}
        initialTotalContracts={props.count}
      />
    </>
  );
};

export default CardDashBoardActionMissingBuyerPhone;
