import React, { useContext, useState } from 'react';

const OptimizelyContext = React.createContext({});

export const OptimizelyProvider = ({ children }) => {
  const [experiments, setExperiments] = useState({});
  const [variations, setVariations] = useState({});

  const addExperiment = (name, value) => {
    const newExperiment = {};
    newExperiment[name] = value;
    const exp = Object.assign(experiments, newExperiment);
    setExperiments({ ...exp });
  };

  const addVariation = (name, value) => {
    const newVariation = {};
    newVariation[name] = value;
    const vars = Object.assign(variations, newVariation);
    setVariations({ ...vars });
  };

  const waitForExperiment = (name, timeout) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (experiments[name]) {
          resolve(experiments[name]);
        } else {
          resolve('');
        }
      }, timeout);
    });
  };

  const waitForExperimentsOrVariations = (names, timeout) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const exps = {};

        names.forEach((name) => {
          if (experiments[name]) {
            exps[name] = experiments[name];
          }
          if (variations[name]) {
            exps[name] = variations[name];
          }
        });

        resolve(exps);
      }, timeout);
    });
  };

  return (
    <OptimizelyContext.Provider
      value={{
        experiments: { ...experiments },
        variations: { ...variations },
        addExperiment,
        addVariation,
        waitForExperiment,
        waitForExperimentsOrVariations,
      }}
    >
      {children}
    </OptimizelyContext.Provider>
  );
};

const useOptimizely: any = () => {
  const optimizely = useContext(OptimizelyContext);
  return optimizely;
};

/**
 * This method combines split.io treatments with the optimizely experiment
 * to determine if functionality should be exposed. The understood treatment values are:
 * on: Ignore optimizely and enable the feature
 * off: Ignore optimizely and disable the feature
 * ab_test: Defer to optimizely to determine whether or not to enable the feature.
 *
 * Note: Not all split flags utilize the 3 values above. This method should not be used for those!
 *
 * @param splitValue  The treatment value returned by split
 * @param variation  The optimizely experiment
 */
const isSplitAndVariationEnabled = (splitValue, variation) => {
  return splitValue === 'on' || (splitValue === 'ab_test' && variation !== undefined);
};

export const loadWindowScript = (optimizely: any) => {
  if (typeof document !== 'undefined') {
    (window as any).addExperiment = (name, value) => {
      if (name) {
        console.log('addExperiment', name, value);
        optimizely.addExperiment(name, value);
      }
    };
    (window as any).addVariation = (name) => {
      if (name) {
        console.log('addVariation', name);
        optimizely.addVariation(name, true);
        if (name === 'SimplifiedStep2') localStorage.setItem('SimplifiedStep2', 'true');
      }
    };
  }
};

export default OptimizelyContext;
export { OptimizelyContext, useOptimizely, isSplitAndVariationEnabled };
