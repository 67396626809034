import { ProfileOffice } from '@app/models/profile.model';

export enum UserRoleType {
  SiteAdmin = 'SiteAdmin',
  FieldSales = 'FieldSales',
  RealEstateAdmin = 'RealEstateAdmin',
  ClosingCompanyAdmin = 'ClosingCompanyAdmin',
  RealEstateAgent = 'RealEstateAgent',
  ClosingCompanyAgent = 'ClosingCompanyAgent',
  Broker = 'Broker',
  InternalSalesAgent = 'InternalSalesAgent',
}

export interface CreateProfileRequest {
  firstName: string;
  lastName: string;
  roleIDType: string;
  roleID: string;
  email: string;
  phoneType: string;
  phoneNumber: string;
  narID?: string;
  offices: OfficeRequest[];
}

export interface CreateProfileResponse {
  status: number;
  statusText: string;
}

export interface UserProfileResponse {
  profileID: string;
  authID: string;
  brand: string;
  authIDType: string;
  firstName: string;
  lastName: string;
  roleID: string;
  roleIDType: string;
  email: string;
  phoneNumber: string;
  phoneType: string;
  narID: string;
  AhsUserID: string;
  offices: ProfileOfficeData[];
  workedWithOffices: ProfileOfficeData[];
  hasConfirmedOffice: boolean;
  active: boolean;
  isIncomplete: boolean;
}

export interface ProfileErrorResponse {
  data: { errors: ProfileError[] };
  isAxiosError: boolean;
}

export interface ProfileError {
  code: string;
  message: string;
  field: string;
}

export interface ProfilePermissionsResponse {
  Office: string[];
  Agent: string[];
  Account: string[];
  Admin: string[];
  Contract: string[];
}

export interface UpdateOfficeRequest {
  profileID: string;
  offices: OfficeRequest[];
}

export interface OfficeRequest {
  type: string;
  id: string;
}

export interface UserDetailsResponse {
  roleID: string;
  roleIDType: string;
  email: string;
  faEmail: string;
  firstName: string;
  lastName: string;
  offices: ProfileOffice[];
  registrationDate: string;
  brand: string;
  lastActiveDate: string;
  lastPasswordChangeDate: string;
  lastForgotPassword: string;
  phoneNumber: string;
  emailVerified: boolean;
  AhsUserID: string;
  profileID: string;
  narID: string;
  authIDType: string;
  authID?: string;
}

export interface CheckAccountExistsResponse {
  status: number;
  statusText: string;
}

export interface ProfileOfficeData {
  type: string;
  id: string;
  active: boolean;
  name: string;
  phone: number;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  warrantyLinkEligible: boolean;
  userExtensions: string[];
  franchiseCode: string;
}
