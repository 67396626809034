import React, { useEffect, useState } from 'react';
import Search from '@components/search/Search';
import PropertyInfoOption from '@components/search/PropertyInfoOption';

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export type PropertyInfoType = {
  id: string;
  streetAddress: string;
  initiatingAgent: string;
  initiatingOffice: string;
  officeAddress: string;
  status: string;
};

type Props = {
  inputForm: JSX.Element;
  query: string;
  hits: PropertyInfoType[];
  onHitClick: (id) => void;
};

const goToElement = (id) => {
  const currentElement = document.getElementById(`${id}-id`);
  const optionList = document.getElementById('property-search-list');

  if (currentElement) {
    optionList.scrollTop = currentElement.offsetTop;
  }
};

const SearchInputFormAdapter: React.FC<Props> = ({
  inputForm,
  query,
  hits,
  onHitClick,
}): JSX.Element => {
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState<{ index?: number; hit?: any }>({});
  const [hovered, setHovered] = useState({});

  useEffect(() => {
    if (hits.length && downPress) {
      setCursor((prevState) => {
        const prevIndex = prevState.index;
        let index = prevIndex < hits.length - 1 ? prevIndex + 1 : prevIndex;

        index = index || 0; // handles case where no element selected by starting them off on the first element on down press

        const hit = hits[index];

        goToElement(hit?.id);

        return { index, hit };
      });
    }
  }, [downPress]);

  useEffect(() => {
    if (hits.length && upPress) {
      setCursor((prevState) => {
        const prevIndex = prevState.index;
        let index = prevIndex > 0 ? prevIndex - 1 : prevIndex;

        if (index === undefined) index = hits.length - 1; // handles case where no element selected by starting them off on the last element on up press

        const hit = hits[index];

        goToElement(hit?.id);

        return { index, hit };
      });
    }
  }, [upPress]);

  useEffect(() => {
    if (hits.length && enterPress && cursor.hit) {
      onHitClick(cursor.hit?.id);
    }
  }, [cursor, enterPress]);

  useEffect(() => {
    if (hits.length && hovered) {
      setCursor(hovered);
    }
  }, [hovered]);

  return (
    <>
      <Search
        options={hits}
        optionComponent={
          <>
            {hits.map((hit: any, i) => (
              <PropertyInfoOption
                key={hit.id}
                option={hit}
                query={query}
                setHovered={(hit) => {
                  setHovered({ index: i, hit });
                }}
                onClick={onHitClick}
                active={i === cursor?.index}
              />
            ))}
          </>
        }
        query={query}
        inputForm={inputForm}
      />
    </>
  );
};

export default SearchInputFormAdapter;
