export enum ResidenceType {
  SingleFamilyResidence = 'singleFamilyResidence',
  Condominium = 'condominium',
  Townhouse = 'townhouse',
  Duplex = 'duplex',
  Triplex = 'triplex',
  Fourplex = 'fourplex',
  MobileHome = 'mobileHome',
}

export const residenceTypeByValue = {
  singleFamilyResidence: ResidenceType.SingleFamilyResidence,
  condominium: ResidenceType.Condominium,
  townhouse: ResidenceType.Townhouse,
  duplex: ResidenceType.Duplex,
  triplex: ResidenceType.Triplex,
  fourplex: ResidenceType.Fourplex,
  mobileHome: ResidenceType.MobileHome,
};

export interface Property {
  typeOfResidence?: ResidenceType;
  squareFootage?: number;
  age?: number;
  meta?: {
    legacyId?: string;
    propertyId?: string;
    addressId?: string;
    yearBuilt?: number;
  };
}

export interface PropertyDetails {
  age: string;
  newConstruction: boolean;
  notNewConstruction: boolean;
  squareFootage: number;
  residenceType: string;
  mlsNumber?: string;
}
