/***************************************************************************
 * Models related to the New Order and Edit Order forms
 * Be sure to only include minimum data.
 * We don't want to include UI-state values like dropdown names
 * or an entire object. Only return necessary information for the forms.
 ************************************************************************* */
import { NewOrderContextData } from '@context/NewOrderContext/model';
import { ProductPricingDetailsRequest, ProductPricingDetailsResponse } from '@apis/models';
import { Recipient } from '@components/SendDocuments';

//
// Below include sample fields for demonstration. Add/remove fields as you need them.
// Fields provided would be used for both form and display purposes (on order summary).
// DO NOT use optional parameter declarations. We want it to be as defined and flat as possible.
//

export enum Represents {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Both = 'BOTH',
}

/** used per initiating/cooperating/closing office agent data */
export interface OrderOfficeAgentData {
  officeID: string;
  agentID: string;
  officeName: string;
  agentName: string;

  /** needed for send documents */
  agentEmail: string;

  /** needed for productFilters */
  franchiseCode: string;
}

export enum CustomerType {
  Buyer = 'buyer',
  Seller = 'seller',
  CoBuyer = 'coBuyer',
  CoSeller = 'coSeller',
}

/** used per buyer/cobuyer/seller/coseller customer data */
export interface OrderCustomerFormData extends OrderAddressInfoFormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  customerType: CustomerType;
}

export interface OrderAgentInfoFormData {
  represents: Represents;
  selectROOption: boolean;
}

export interface OrderAddressInfoFormData {
  zip: string;
  streetAddress: string;
  city: string;
  state: string;
  unit: string;
  hasVerified: boolean;
  addressUUID: string;
  /*
  example fields:
  streetAddress, unit, city, state, zip, zipPlus4, addressUUID
  */
}

export interface OrderPropertyInfoFormData {
  /*
  example fields:
  age, residenceType, sqft, mls
  */

  residenceType: string;
  squareFootage: number;
  mlsNumber: string;
  age: number;
}

export interface OrderProductFiltersFormData {
  term: number;
  sellersCoverage: boolean;
  acCoverage: boolean;
  projectedClosingDate: Date;
}

export interface OrderPlanInfoFormData {
  selectedPlanID: string;
  selectedPlanName: string;
  selectedPlanPrice: number;
  selectedPlanListingTermInDays: number;
  selectedOptionalCoverages: OrderPlanInfoCoverageData[];
  selectedGroupCoverages: OrderPlanInfoCoverageData[];
  pricingRequest: ProductPricingDetailsRequest;
  pricingResponse: ProductPricingDetailsResponse;
}

export interface OrderPlanInfoCoverageData {
  id: string;
  name: string;
  price: number;
}

export interface OrderClosingInfoFormData {
  closingFileNumber: string;
  closingAgent: NewOrderContextData['closingAgent'];
  /*
  example fields:
  closing file, closing date
  the closing office/agent should use the closingAgent field
  */
}

export interface OrderSendDocumentsFormData {
  recipients: Recipient[];
  /*
  example fields:
  invoice array [ name, email ]
  confirmation array [ name, email ]
  */
}
