import React from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';

interface Props extends ModalProps {
  id: string;
  onConfirm: () => void;
  onClose: () => void;
  yesText?: string;
  noText?: string;
  heading: string;
}

const ModalChoice: React.FC<Props> = ({ id, heading, yesText, noText, ...props }) => {
  const actions = [
    <Button key="submit" size="medium" label={yesText} onClick={() => props.onConfirm()} />,
    <Button
      key="cancel"
      variant="ghost"
      size="medium"
      label={noText}
      onClick={() => props.onClose()}
    />,
  ];

  return (
    <Modal id={id} heading={heading} canClose={true} actions={actions} {...props}>
      {props.children}
    </Modal>
  );
};

ModalChoice.defaultProps = {
  yesText: 'Yes',
  noText: 'No',
};

export default ModalChoice;
