import React from 'react';
import { ProgressIndicator, Text } from '@ftdr/blueprint-components-react';
import SentenceText from '@components/SentenceText';

interface AtypicallySlowOverlayProps {
  isActive: boolean;
}

const AtypicallySlowOverlay: React.FC<AtypicallySlowOverlayProps> = (props) => {
  return (
    props.isActive && (
      <div className="flex flex-col items-center align-center mx-auto py-8 px-4 bg-white shadow-2 rounded">
        <SentenceText variant="heading-04" className="text-primary line-clamp-none">
          This is taking longer than we expected, please hold!
        </SentenceText>
        <ProgressIndicator variant="circular" size="large" />
        <Text color="gray" className="pb-8 mt-3" variant="caption">
          Loading...
        </Text>
      </div>
    )
  );
};

export default AtypicallySlowOverlay;
