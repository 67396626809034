import { addDays, differenceInCalendarDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import {
  Contract,
  ContractPlanCoverages,
  ContractProduct,
  ContractProductCoverageItem,
  ContractSummary,
  Customer,
  REOrder,
  RESourcePageNewOrder,
} from '@apis/models';
import {
  CustomerTypes,
  dwellingTypeDictionary,
  orderStatusDictionary,
  orderStatusIDDictionary,
  ProductSalesGroup,
} from '@constants/dictionaries';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { getConfig } from '@services/app.config.loader';
import ProfileModel from '@app/models/profile.model';
import { getBrand } from '@helpers/brand.utils';
import { PropertyDetails } from '@app/models';
import {
  DEFAULT_AGE,
  getSquareFootageRangeValueNum,
  NEW_CONSTRUCTION_AGE,
} from '@services/helpers';
import { TableExtendListingRow } from '@components/table/TableExtendListing';
import {
  cleanPhone,
  DateFormat,
  formatDate,
  formattedDate,
  getDateFromString,
} from '@helpers/utils';
import { dateTypeLabel, dateTypeSortOrder } from '@constants/dates';
import { Recipient } from '@components/SendDocuments';
import { EmailDocument, OfficeAgent, OrderRequest } from '@apis/models/orders.api.model';
import { DEFAULT_CUSTOMER_PHONE_TYPE } from '@constants/formField-constants';
import { Represents } from '@app/models/order/order.model';
import msgs from '@app/locales/en';
import { Brand } from '@constants/brands';
import { ContractRecipientData } from '@app/generators/recipientGenerator';
import { PlansCoverageValues } from '@components/card/CardNewOrderPlansCoverage';
import { isREAgent } from '@helpers/profile.utils';

interface AgentRepresentsInput {
  seller: boolean;
  buyer: boolean;
  both: boolean;
}

interface ContractCustomerBreakdown {
  buyer?: Customer;
  seller?: Customer;
  cobuyer?: Customer;
  coseller?: Customer;
}

/** Checks if closing info should clear buyer info.
 * Should clear buyer info if:
 * - buyer info was optional
 * - buyer info is filled
 * - closing info has empty
 * @param representing who the initiating agent is representing (to determine if buyer info is optional)
 * @param buyerInfo the buyer information to see if buyer info is filled
 * @param closingInfo the closing information to see if closing information is empty
 * @return boolean true if should clear buyer info. Otherwise buyer info should be left alone
 */
export function shouldClosingInfoClearBuyerInfo(
  representing: string,
  buyerInfo: {
    buyerInfo: {
      yes: boolean;
      no: boolean;
    };
    cooperatingOffice: string;
    cooperatingAgent: string;
  }, // TODO: types
  closingInfo: {
    closingTitleCompany: string;
    closingTitleAgent: string;
    closingFileNumber: string;
    projectedClosingDate: any;
    dateUnknown: boolean;
    titleCompany: {
      yes: boolean;
      no: boolean;
    };
  }, // TODO: types
): boolean {
  try {
    const hasCooperatingBuyerInfo: boolean = Boolean(
      buyerInfo && (buyerInfo.cooperatingOffice || buyerInfo.cooperatingAgent),
    );
    const hasNoClosingDate: boolean = closingInfo && closingInfo.dateUnknown;

    // Only clear buyer information if buyer information is optional
    const allowedToClearBuyerInfo = ['seller'].includes(representing.toLowerCase());

    // Only clear buyer information if there is buyer information to clear
    const hasBuyerInfoToClear = hasBuyerInfo(buyerInfo) || hasCooperatingBuyerInfo;

    // Only clear buyer information if closing information is considered empty
    const isClosingInfoCleared = hasNoClosingDate;

    return allowedToClearBuyerInfo && hasBuyerInfoToClear && isClosingInfoCleared;
  } catch (e) {
    console.error('Failed to determine if buyer info should be cleared: ', e);
    return false;
  }
}

export function shouldReturnToBuyerInfo(
  buyerInfo: {
    firstName: string;
  },
  closingInfo: {
    projectedClosingDate: string;
  },
): boolean {
  try {
    const hasBuyerInfo: boolean = Boolean(buyerInfo?.firstName);
    const hasClosingDate: boolean = Boolean(closingInfo?.projectedClosingDate);
    return hasClosingDate && !hasBuyerInfo;
  } catch (e) {
    console.error('Failed to determine if return to buyer card is necessary: ', e);
    return false;
  }
}

export function shouldReturnToClosingInfo(
  buyerInfo: {
    firstName: string;
  },
  closingInfo: {
    projectedClosingDate: string;
  },
): boolean {
  try {
    const hasBuyerInfo: boolean = Boolean(buyerInfo?.firstName);
    const hasClosingDate: boolean = Boolean(closingInfo?.projectedClosingDate);
    return hasBuyerInfo && !hasClosingDate;
  } catch (e) {
    console.error('Failed to determine if return to closing card is necessary: ', e);
    return false;
  }
}

/** Returns true if closing information is required.
 * Required if agent is not representing seller or order has buyer info.
 */
export function isClosingInfoRequired(agentInfo: any, buyerInfo: any): boolean {
  const isRepresentingSeller = agentInfo?.agentRepresents?.seller;
  const hasBuyerInfo = Boolean(buyerInfo?.firstName);

  return !isRepresentingSeller || hasBuyerInfo;
}

/** Returns true if buyer information is required.
 * Required if agent is not representing seller or order has closing info.
 */
export function isBuyerInfoRequired(agentInfo: any, closingInfo: any): boolean {
  const isRepresentingSeller = agentInfo?.agentRepresents?.seller;
  const hasClosingDate = Boolean(closingInfo?.projectedClosingDate);

  return !isRepresentingSeller || hasClosingDate;
}

export function isRequiredSellerInfoPresent(
  sellerInfo: any,
  isRepresentingSeller: boolean,
): boolean {
  const firstNameCollected = !isEmpty(sellerInfo?.firstName);
  const lastNameCollected = !isEmpty(sellerInfo?.lastName);
  const phoneOrEmailCollected = !isEmpty(sellerInfo.email) || !isEmpty(sellerInfo.phone);

  if (isRepresentingSeller) return firstNameCollected && lastNameCollected && phoneOrEmailCollected;

  return firstNameCollected && lastNameCollected;
}

export const calculateDaysToClosing = (
  currentDate: Date,
  projectedDate: string,
  listTermDays: number,
) => {
  const projectedClosingDate = new Date(projectedDate);
  let projectedDays = 0;
  if (!isNaN(projectedClosingDate.getTime())) {
    const closingDate = new Date(projectedDate);

    projectedDays = differenceInCalendarDays(closingDate, currentDate);
    // console.log(currentDate, projectedDate, closingDate, projectedDays);
  } else {
    projectedDays = listTermDays;
  }
  return projectedDays;
};

export function getOrderRowShortStatusLabel(item: REOrder): string {
  const shortStatusLabel = {
    ...orderStatusDictionary,
    // shorter label for warranty link status
    [RealEstateStatus.AWAITING_WL_SUBMISSION]: 'WarrantyLink Ready',
  };
  const status = getOrderStatus(item);
  return shortStatusLabel[status];
}

export function getStatusID(item: REOrder): string {
  const status = getOrderStatus(item);
  return orderStatusIDDictionary[status];
}

export function getOrderIDFromContract(contract: Contract): string {
  return contract?.detail?.details?.find((d) => d.type === 'ORDERID')?.value;
}

export function getOrderStatus(item: REOrder): RealEstateStatus {
  return item.awaitingWlkSubmission
    ? RealEstateStatus.AWAITING_WL_SUBMISSION
    : item.realEstateStatus;
}

export function GetRequestServiceUrl(contract: REOrder) {
  return `${getConfig('request-service-url')}?contractID=${contract.id}&zipCode=${contract.address.zip}&b2bRS=true`;
}

/** Get all emails from contract to send notifications for. Includes profile so we add/relabel ourselves */
export function getContractRecipientsForNotifications(
  contract: ContractRecipientData,
  profile: ProfileModel,
): any {
  const selectedContract = contract;

  if (!selectedContract) return {};
  const { buyer, seller, cobuyer, coseller } = breakdownContractCustomers(contract);

  const { initiatingOfficeAgent, cooperatingOfficeAgent, closingOfficeAgent } =
    selectedContract.detail;
  const temp = {
    Buyer: { name: '', email: '' },
    CoBuyer: { name: '', email: '' },
    Myself: { name: '', email: '' },
    Seller: { name: '', email: '' },
    CoSeller: { name: '', email: '' },
    InitiatingAgent: { name: '', email: '' },
    CooperatingAgent: { name: '', email: '' },
    CooperatingAgentInvited: { name: '', email: '' },
    ClosingAgent: { name: '', email: '' },
  };
  temp.Myself.email = profile?.email || '';

  const newOrderData = {
    buyerInfo: {
      name: buyer && buyer.firstName && buyer.lastName && `${buyer.firstName} ${buyer.lastName}`,
      email: buyer && buyer.email && buyer.email.address,
      coCustomerName:
        cobuyer &&
        cobuyer.firstName &&
        cobuyer.lastName &&
        `${cobuyer.firstName} ${cobuyer.lastName}`,
      coCustomerEmail: cobuyer && cobuyer.email && cobuyer.email.address,
    },
    sellerInfo: {
      name:
        seller && seller.firstName && seller.lastName && `${seller.firstName} ${seller.lastName}`,
      email: seller && seller.email && seller.email.address,
      coCustomerName:
        coseller &&
        coseller.firstName &&
        coseller.lastName &&
        `${coseller.firstName} ${coseller.lastName}`,
      coCustomerEmail: coseller && coseller.email && coseller.email.address,
    },
    agentInfo: {
      AgentName:
        initiatingOfficeAgent?.agent?.firstName &&
        initiatingOfficeAgent?.agent?.lastName &&
        `${initiatingOfficeAgent?.agent?.firstName} ${initiatingOfficeAgent?.agent?.lastName}`,
      AgentEmail: initiatingOfficeAgent?.agent?.email,
    },
    cooperatingAgentInfo: {
      AgentName:
        cooperatingOfficeAgent?.agent?.firstName &&
        cooperatingOfficeAgent?.agent?.lastName &&
        `${cooperatingOfficeAgent?.agent?.firstName} ${cooperatingOfficeAgent?.agent?.lastName}`,
      AgentEmail: cooperatingOfficeAgent?.agent?.email,
    },
    cooperatingAgentInvitedInfo: {
      AgentName:
        contract.cooperatingAgentInvited?.firstName &&
        contract?.cooperatingAgentInvited?.lastName &&
        `${contract?.cooperatingAgentInvited?.firstName} ${contract?.cooperatingAgentInvited?.lastName}`,
      AgentEmail: contract.cooperatingAgentInvited?.email,
    },
    closingOfficeAgent: {
      AgentName:
        closingOfficeAgent?.agent?.firstName &&
        closingOfficeAgent?.agent?.lastName &&
        `${closingOfficeAgent?.agent?.firstName} ${closingOfficeAgent?.agent?.lastName}`,
      AgentEmail: closingOfficeAgent?.agent?.email,
    },
  };
  if (newOrderData.buyerInfo) {
    if (newOrderData.buyerInfo.email) {
      temp.Buyer.name = newOrderData.buyerInfo.name;
      temp.Buyer.email = newOrderData.buyerInfo.email;
    }
    if (newOrderData.buyerInfo.coCustomerEmail) {
      temp.CoBuyer.name = newOrderData.buyerInfo.coCustomerName;
      temp.CoBuyer.email = newOrderData.buyerInfo.coCustomerEmail;
    }
  }
  if (newOrderData.sellerInfo) {
    if (newOrderData.sellerInfo.email) {
      temp.Seller.name = newOrderData.sellerInfo.name;
      temp.Seller.email = newOrderData.sellerInfo.email;
    }
    if (newOrderData.sellerInfo.coCustomerEmail) {
      temp.CoSeller.name = newOrderData.sellerInfo.coCustomerName;
      temp.CoSeller.email = newOrderData.sellerInfo.coCustomerEmail;
    }
  }
  if (newOrderData.agentInfo && newOrderData.agentInfo.AgentEmail) {
    temp.InitiatingAgent.name = newOrderData.agentInfo.AgentName;
    temp.InitiatingAgent.email = newOrderData.agentInfo.AgentEmail;
  }
  if (newOrderData.cooperatingAgentInfo && newOrderData.cooperatingAgentInfo.AgentEmail) {
    temp.CooperatingAgent.name = newOrderData.cooperatingAgentInfo.AgentName;
    temp.CooperatingAgent.email = newOrderData.cooperatingAgentInfo.AgentEmail;
  }
  if (
    newOrderData.cooperatingAgentInvitedInfo &&
    newOrderData.cooperatingAgentInvitedInfo.AgentEmail
  ) {
    temp.CooperatingAgentInvited.name = newOrderData.cooperatingAgentInvitedInfo.AgentName;
    temp.CooperatingAgentInvited.email = newOrderData.cooperatingAgentInvitedInfo.AgentEmail;
  }
  if (newOrderData.closingOfficeAgent && newOrderData.closingOfficeAgent.AgentEmail) {
    temp.ClosingAgent.name = newOrderData.closingOfficeAgent.AgentName;
    temp.ClosingAgent.email = newOrderData.closingOfficeAgent.AgentEmail;
  }
  for (const role in temp) {
    if (
      Object.prototype.hasOwnProperty.call(temp, role) &&
      role != 'Myself' &&
      temp.Myself &&
      temp.Myself.email.localeCompare(temp[role]) == 0
    ) {
      delete temp[role];
    }
  }
  return temp;
}

export const isSellerNameRequired = (
  hasSellersCoverage: boolean,
  agentRepresentsInput: AgentRepresentsInput,
) => {
  return hasSellersCoverage || agentRepresentsInput.both || agentRepresentsInput.seller;
};

export const isSellerContactInfoRequired = (agentRepresentsInput: AgentRepresentsInput) => {
  return agentRepresentsInput.both || agentRepresentsInput.seller;
};

export const isBuyerContactInfoRequired = (agentRepresentsInput: AgentRepresentsInput) => {
  return agentRepresentsInput.both || agentRepresentsInput.buyer;
};

export const canEditAgentInfo = (contract: Contract, hasAdminPermission: boolean) => {
  return contract.summary.status == 'L' && hasAdminPermission;
};

export const canModifySquareFootage = (brand = getBrand()): boolean => {
  return brand === Brand.AHS;
};

/** Returns the square footage value given the option name (less, between, above). Dependent on brand.
 * The square footage option should really only reside with the form field itself and not passed around like this. */
export const getSquareFootageFromOptionName = (option: number): number => {
  return getSquareFootage(option);
};

/** returns the normalized static square footage value given the sqft where it places in the options */
export const normalizeSquareFootageValue = (sqft: number): number => {
  return getSquareFootageFromOptionName(getSquareFootageRangeValueNum(sqft));
};

/** @deprecated: use getSquareFootageFromOptionName instead.
 * Even then, we should work on refactoring so that the option name isn't passed around, and the value is used directly instead. */
export const getSquareFootage = (val) => {
  let squareFootageArr = [{ less: 4999 }, { between: 9999 }, { above: 10000 }];
  squareFootageArr = squareFootageArr.filter((item) => item[val]);
  return squareFootageArr.length > 0 && getBrand() === 'AHS' ? squareFootageArr[0][val] : 4999;
};

/** Obtain the age based on the new construction flag.  If flag is null or undefined, use DEFAULT_AGE. */
export const getAgeByNewConstruction = (isNewConstruction: boolean): number => {
  return isNewConstruction ? NEW_CONSTRUCTION_AGE : DEFAULT_AGE;
};

/** checks if property details changed, optionally checking mls number if specified as an argument (defaults to false) */
export const didPropertyDetailsChange = (
  oldProperty: PropertyDetails,
  newProperty: PropertyDetails,
  includeMLS: boolean = false,
): boolean => {
  let mlsChanged: boolean = false;
  if (includeMLS) {
    mlsChanged = oldProperty.mlsNumber !== newProperty.mlsNumber;
  }

  return (
    oldProperty.age !== newProperty.age ||
    oldProperty.newConstruction !== newProperty.newConstruction ||
    oldProperty.notNewConstruction !== newProperty.notNewConstruction ||
    oldProperty.squareFootage !== newProperty.squareFootage ||
    oldProperty.residenceType !== newProperty.residenceType ||
    mlsChanged
  );
};

/** return the residence type string. If upperFirstChar is true, the first character will be uppercased. Otherwise lower cased. */
export const getResidenceType = (
  dwellingTypeCodeOrResidenceType: string,
  upperFirstChar = false,
): string => {
  let residenceType = dwellingTypeDictionary[dwellingTypeCodeOrResidenceType];
  if (upperFirstChar) {
    residenceType = residenceType.charAt(0).toUpperCase() + residenceType.slice(1);
  }
  return residenceType;
};

// ** returns a uniqe list of selected document types recipients */
export const getUniqueDocumentTypes = (recipients: Recipient[]): any[] => {
  // Get all the document types to be sent to all recipients
  const docTypes = recipients.reduce((acc, curr) => {
    if (!isEmpty(curr)) {
      acc.push(...curr.selectedTypes);
    }
    return acc;
  }, []);

  // Get the distinct document types to be sent. Capitalize the first letter of the document type
  const uniqueDocTypes = docTypes.reduce((acc, curr) => {
    if (!acc.includes(curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase())) {
      acc.push(curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase());
    }
    return acc;
  }, []);

  return uniqueDocTypes.sort();
};

/** checks product to see if it is an RO product */
export const isROProduct = (product: ContractProduct): boolean => {
  return product?.salesGroup?.includes(ProductSalesGroup.RealtorOwned);
};

export const breakdownContractCustomers = (contract: Contract): ContractCustomerBreakdown => {
  let buyer: Customer;
  let seller: Customer;
  let cobuyer: Customer;
  let coseller: Customer;
  if (contract?.detail) {
    for (const c of contract.detail.customers) {
      switch (c.type) {
        case CustomerTypes.Buyer:
          buyer = c;
          break;
        case CustomerTypes.CoBuyer:
          cobuyer = c;
          break;
        case CustomerTypes.Seller:
          seller = c;
          break;
        case CustomerTypes.CoSeller:
          coseller = c;
          break;
      }
    }
  }
  return { buyer, seller, cobuyer, coseller };
};

/** From a number of office classes, get the franchise code correctly */
export const getFranchiseCodeFromOffice = (office: any): string => {
  return (
    office?.franchiseCode || office?.details?.franchiseCode // Api Office
  ); // App Office
};

export const orderToSummary = (order: REOrder): ContractSummary => {
  return {
    id: order.id.toString(),
    expirationDate: order.expirationDate,
    agentName: order.initiatingAgentName,
    officeName: order.initiatingOfficeName,
    status: order.status,
    address: {
      address1: order.address.address1,
      address2: order.address.address2,
      city: order.address.city,
      state: order.address.state,
      zip: order.address.zip,
    },
    awaitingWlkSubmission: order.awaitingWlkSubmission,
    realEstateStatus: order.realEstateStatus,
  };
};

export const getTableExtendListingArray = (contract: Contract): TableExtendListingRow[] => {
  try {
    const { dateTypes, daysToExtend } = contract.features.extendListing;
    // Get relevant dates to extend listing
    const dates = contract.detail.dates.filter((d) => dateTypes.includes(d.type));

    // Map the results to the object needed
    return dates
      .sort((a, b) => dateTypeSortOrder.indexOf(a.type) - dateTypeSortOrder.indexOf(b.type))
      .map((d) => {
        // Get the extended date
        const currentDate = getDateFromString(d.expiration);
        const extendedDate = addDays(currentDate, daysToExtend);

        return {
          title: dateTypeLabel[d.type],
          currentDate: formattedDate(currentDate),
          newDate: formattedDate(extendedDate),
        };
      });
  } catch (e) {
    console.error('Failed to load extend listing array:', e);
    return [];
  }
};

export const getDefaultListingEffectiveDate = () => {
  try {
    const todaysDate = new Date();
    return new Date(
      todaysDate.toLocaleDateString('en-US', {
        timeZone: 'CST',
      }),
    );
  } catch (err) {
    console.log(err);
    return new Date();
  }
};

export const calculateListingTermInDays = (
  listingExpirationDate: Date,
  listingEffectiveDate: Date = getDefaultListingEffectiveDate(),
) => {
  return differenceInCalendarDays(listingExpirationDate, listingEffectiveDate);
};

export const isClosingDateRequired = (
  represents: Represents,
  hasBuyerFirstName: boolean,
): boolean => {
  return [Represents.Buyer, Represents.Both].includes(represents) || hasBuyerFirstName;
};

/** reason code as to why the plan card should be reset. Used to determine what floating notification to display. */
export enum PlanCardResetReason {
  PropertyChanged,
  AddressChanged,
  FranchiseChanged,
  RealtorOwnedPropertySelected,
  RealtorOwnedPropertyDeselected,
  PastClosingDateSelected,
}

export const getPlanReselectionReason = (reason: PlanCardResetReason): string => {
  switch (reason) {
    case PlanCardResetReason.PropertyChanged:
      return msgs.PROPERTY_CHANGE_NEW_PLAN_REQUIRED.message;
    case PlanCardResetReason.AddressChanged:
      return msgs.ADDRESS_CHANGE_NEW_PLAN_REQUIRED.message;
    case PlanCardResetReason.FranchiseChanged:
      return msgs.INITIATING_OFFICE_FRANCHISE_CHANGE_NEW_PLAN_REQUIRED.message;
    case PlanCardResetReason.RealtorOwnedPropertySelected:
      return msgs.REALTOR_OWNED_PROPERTY_SELECTION_NEW_PLAN_REQUIRED.message;
    case PlanCardResetReason.RealtorOwnedPropertyDeselected:
      return msgs.REALTOR_OWNED_PROPERTY_DESELECTION_NEW_PLAN_REQUIRED.message;
    case PlanCardResetReason.PastClosingDateSelected:
      return msgs.PAST_CLOSING_DATE_SELECTED.message;
    default:
      console.warn('unknown plan reselection reason used, no message');
  }
};

export enum OrderFlowType {
  NewOrder = 'New Order',
  EditOrder = 'Edit Order',
  DashboardMissingBuyerPhone = 'Dashboard > Missing Buyer Phone',
  DashboardMissingCOE = 'Dashboard > Missing COE',
  QuickLinksUpdateBuyerInfo = 'Quick Links > Update Buyer Information',
}

// //////////////// bellow are used for migration only /////////////////////

export type OrderRequestStateAttributes = {
  propertyAddressUUID: string;
  buyerAddressUUID: string;
  coBuyerAddressUUID: string;
  sellerAddressUUID: string;
  coSellerAddressUUID: string;
  quoteID: string;
};

export const getOrderRequestFromNewOrderValues = (
  values: any,
  attrs: OrderRequestStateAttributes,
  emailDocuments: EmailDocument,
  profile: ProfileModel,
): OrderRequest => {
  const getCooperatingOffice = () => {
    if (values.agentInfo?.representing) {
      switch (values.agentInfo.representing) {
        case 'Buyer':
          return {
            officeID: values.sellerInfo?.office?.id,
            agentID: values.sellerInfo?.AgentId,
          };
        case 'Seller':
          return {
            officeID: values.buyerInfo?.office?.id,
            agentID: values.buyerInfo?.AgentId,
          };
      }
    }
  };

  const getBuyer = () => {
    if (values.buyerInfo?.buyerInfo && !values.buyerInfo?.buyerInfo.no) {
      return {
        firstName: values.buyerInfo.firstName,
        lastName: values.buyerInfo.lastName,
        email: values.buyerInfo.email,
        addressUUID: attrs.buyerAddressUUID,
        phones: [
          {
            phone: cleanPhone(values.buyerInfo.phone),
            phoneType: values.buyerInfo.phone ? DEFAULT_CUSTOMER_PHONE_TYPE : null,
          },
        ],
      };
    }
    return null;
  };
  const getCoBuyer = () => {
    if (values.buyerInfo?.buyerInfo && !values.buyerInfo?.buyerInfo.no) {
      if (values.buyerInfo.coBuyerFields) {
        return {
          firstName: values.buyerInfo.coCustomerFirstName,
          lastName: values.buyerInfo.coCustomerLastName,
          email: values.buyerInfo.coCustomerEmail,
          addressUUID: attrs.coBuyerAddressUUID,
          phones: [
            {
              phone: cleanPhone(values.buyerInfo.coCustomerPhone),
              phoneType: values.buyerInfo.coCustomerPhone ? DEFAULT_CUSTOMER_PHONE_TYPE : null,
            },
          ],
        };
      }
    }
    return null;
  };
  const getSeller = () => {
    if (values.sellerInfo?.sellerInfo && !values.sellerInfo?.sellerInfo.no) {
      return {
        firstName: values.sellerInfo.firstName,
        lastName: values.sellerInfo.lastName,
        email: values.sellerInfo.email,
        addressUUID: attrs.sellerAddressUUID,
        phones: [
          {
            phone: cleanPhone(values.sellerInfo.phone),
            phoneType: values.sellerInfo.phone ? DEFAULT_CUSTOMER_PHONE_TYPE : null,
          },
        ],
      };
    }
    return null;
  };
  const getCoSeller = () => {
    if (values.sellerInfo?.sellerInfo && !values.sellerInfo?.sellerInfo.no) {
      if (values.sellerInfo.coSellerFields) {
        return {
          firstName: values.sellerInfo.coCustomerFirstName,
          lastName: values.sellerInfo.coCustomerLastName,
          email: values.sellerInfo.coCustomerEmail,
          addressUUID: attrs.coSellerAddressUUID,
          phones: [
            {
              phone: cleanPhone(values.sellerInfo.coCustomerPhone),
              phoneType: values.sellerInfo.coCustomerPhone ? DEFAULT_CUSTOMER_PHONE_TYPE : null,
            },
          ],
        };
      }
    }
    return null;
  };

  let reAgentInitiatingOffice: OfficeAgent = null;
  const profileAgentID = profile.roleID;
  const profileOfficeID = profile.offices?.[0]?.id;

  if (isREAgent(profile) && profileAgentID && profileOfficeID) {
    reAgentInitiatingOffice = {
      agentID: profileAgentID,
      officeID: profileOfficeID,
    };
  }

  return {
    propertyAddressUUID: attrs.propertyAddressUUID,
    quoteID: attrs.quoteID,
    escrowNumber: values.closingInfo?.closingFileNumber,
    mlsValue: values.propertyDetails?.mlsNumber,
    estimatedClosingDate:
      values.closingInfo?.projectedClosingDate &&
      formatDate(new Date(values.closingInfo.projectedClosingDate), DateFormat.ISO8601),
    offices: {
      initiatingOfficeRepresents: values.agentInfo?.representing,
      initiatingOffice:
        values.agentInfo?.office?.id && values.agentInfo?.AgentId
          ? {
            officeID: values.agentInfo?.office?.id,
            agentID: values.agentInfo?.AgentId,
          }
          : reAgentInitiatingOffice,
      cooperatingOffice: getCooperatingOffice(),
      closingOffice: {
        officeID: values.closingInfo?.closingOffice?.id,
        agentID: values.closingInfo?.AgentId,
      },
    },
    customers: {
      buyer: getBuyer(),
      cobuyer: getCoBuyer(),
      seller: getSeller(),
      coseller: getCoSeller(),
    },
    emailDocuments,
    sourcePage: RESourcePageNewOrder,
  };
};

/** returns true if the array contains guest unit, regardless if there is any quantity */
export const hasGuestUnitCoverage = (optionalCoverages: ContractProductCoverageItem[]): boolean => {
  return optionalCoverages?.some((cvg) => cvg.name.match(/Guest Unit/i));
};

export const toPlanCoverageValues = (
  contractPlanCoveragesData: ContractPlanCoverages,
  isMilitaryDiscountApplied: boolean,
  legacySelectedGuestUnit?: boolean,
): PlansCoverageValues => {
  return {
    selectedFilters: contractPlanCoveragesData?.product
      ? {
        includeSellersCoverage: contractPlanCoveragesData.product.sellersCoverage,
        includeACCoverage: contractPlanCoveragesData.product.acCoverage,
        termYear: contractPlanCoveragesData.product.contractTermMonths / 12,
      }
      : null,
    selectedGuestUnit:
      legacySelectedGuestUnit || hasGuestUnitCoverage(contractPlanCoveragesData?.optionalCoverages),
    selectedPlanCoverages: contractPlanCoveragesData,
    selectedMilitaryDiscount: isMilitaryDiscountApplied,
  };
};
export const isAgentOfficeFSBO = (officeID: string, agentID: string): boolean => {
  const fsboOfficeID = `${getConfig('fsboOfficeID')}`;
  const fsboAgentID = `${getConfig('fsboAgentID')}`;

  return officeID == fsboOfficeID && agentID === fsboAgentID;
};

export const hasBuyerInfo = (buyerInfo: any): boolean => buyerInfo && buyerInfo.buyerInfo && buyerInfo.buyerInfo.yes;