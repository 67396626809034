import React from 'react';
import Drawer from '@components/drawer/Drawer';
import OrdersMissingCOE from '@components/drawer/subcomponents/OrdersMissingCOE';

interface DrawerOrdersMissingCOEProps {
  isActive: boolean;
  onUpdateOrderCount: any;
  onClose: any;
  initialTotalContracts: number;
}

const DrawerOrdersMissingCOE = (props: DrawerOrdersMissingCOEProps) => {
  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <OrdersMissingCOE
          onUpdateOrderCount={props.onUpdateOrderCount}
          initialTotalContracts={props.initialTotalContracts}
        />
      )}
    </Drawer>
  );
};

export default DrawerOrdersMissingCOE;
