import Modal, { ModalProps } from '@components/modal/Modal';
import React from 'react';
import MustHaveBuyer from '@components/modal/subcomponents/MustHaveBuyer';

export interface ModalMustHaveBuyerProps extends ModalProps {}

const ModalMustHaveBuyer: React.FC<ModalMustHaveBuyerProps> = (props) => {
  return (
    <Modal id={props.id} isActive={props.isActive} onClose={props.onClose} heading={props.heading}>
      {props.isActive && <MustHaveBuyer />}
    </Modal>
  );
};

export default ModalMustHaveBuyer;
