import React from 'react';
import { useNavigation } from 'react-navi';
import Template from '@templates/office/OfficesTemplate';
import Layout from '@components/layout/Layout';
import { ContentBox } from '@components/layout/ContentBox';

const OfficesTemplate = () => {
  const navigation = useNavigation();
  return (
    <Layout slug="offices" isLoggedIn={true}>
      <ContentBox title="My Offices">
        <Template
          redirectTo={(pathName) => {
            navigation.navigate(`/${pathName}`, {
              body: {
                redirectUrl: '/offices',
              },
            });
          }}
        />
      </ContentBox>
    </Layout>
  );
};

export default OfficesTemplate;
