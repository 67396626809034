export default async function withRetry(apiCall: () => Promise<any>, totalAttempts: number = 5) {
  return apiCall()
    .then((resp) => {
      return resp;
    })
    .catch((e) => {
      if (totalAttempts === 1) {
        throw e;
      } else {
        return withRetry(apiCall, totalAttempts - 1);
      }
    });
}
