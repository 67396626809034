import React, { useEffect, useState } from 'react';
import OrderForm from '@components/orders/forms/OrderForm';
import { Input, RadioGroup, Select } from '@ftdr/blueprint-components-react';
import { NEW_ORDER_LABELS, ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderPropertyInfoFormData } from '@app/models/order/order.model';

import { getBrand } from '@helpers/brand.utils';
import {
  DEFAULT_AGE,
  getSquareFootageRangeValueNum,
  NEW_CONSTRUCTION_AGE,
  NEW_CONSTRUCTION_AGE_THRESHOLD,
  SQUARE_FOOTAGE_VALUES,
} from '@services/helpers';
import ModalIneligibleDwellingSize from '@components/modal/ModalIneligibleDwellingSize';
import { canModifySquareFootage } from '@helpers/order.utils';
import { MLS_NUMBER_MAX_LENGTH } from '@constants/formField-constants';

export enum OrderFormPropertyInfoField {
  squareFootage = 'squareFootage',
  mls = 'mls',
  age = 'age',
  residenceType = 'residenceType',
}
interface OrderFormAddressInfoProps
  extends OrderFormProps<OrderPropertyInfoFormData, OrderFormPropertyInfoField> {
  isRealtorOwned: boolean;
}

const OrderFormPropertyInfo: React.FC<OrderFormAddressInfoProps> = (props) => {
  const [isEditing, setisEditing] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);

  const [newConstructionOptionValues, setNewConstructionOptionValues] = useState(
    props.formData.age > NEW_CONSTRUCTION_AGE_THRESHOLD
      ? {
          newConstruction: false,
          notNewConstruction: true,
        }
      : {
          newConstruction: true,
          notNewConstruction: false,
        },
  );

  const brandLocation = getBrand();

  const _squareFootageValues =
    brandLocation === 'AHS'
      ? {
          4999: 'Less than 5,000 sq. ft.',
          9999: '5,000 to 9,999 sq. ft.',
          10000: '10,000 sq. ft. and above',
        }
      : {};
  const squareFootageValues = Object.keys(_squareFootageValues).reduce((options, key) => {
    options.push({
      label: _squareFootageValues[key],
      value: key,
    });
    return options;
  }, []);

  const _residenceTypeValues = {
    singleFamilyResidence: 'Single Family Residence',
    condominium: 'Condominium',
    townhouse: 'Townhouse',
    duplex: 'Duplex',
    triplex: 'Triplex',
    fourplex: 'Fourplex',
    mobileHome: 'Mobile Home',
  };
  const residenceTypeValues = Object.keys(_residenceTypeValues).reduce((options, key) => {
    options.push({
      label: _residenceTypeValues[key],
      value: key,
    });
    return options;
  }, []);
  const newConstructionValues = [
    {
      label: 'Yes',
      value: 'newConstruction',
    },
    {
      label: 'No',
      value: 'notNewConstruction',
    },
  ];

  const handleResidenceTypeSelect = (item) => {
    props.onUpdateFormData({
      ...props.formData,
      residenceType: item.value,
    });
  };

  const handleResidenceTypeBlur = () => {
    props.onValidateField(OrderFormPropertyInfoField.residenceType, props.formData.residenceType);
  };

  const handleSquareFootageBlur = () => {
    props.onValidateField(
      OrderFormPropertyInfoField.squareFootage,
      props.formData.squareFootage?.toString(),
    );
  };

  const handleSquareFootageSelect = (item) => {
    props.onUpdateFormData({
      ...props.formData,
      squareFootage: +item.value,
    });
    if (item.value === '10000') {
      setIsModalActive(true);
    }
  };

  const handleMlsNumberChange = (e) => {
    props.onUpdateFormData({
      ...props.formData,
      mlsNumber: e.currentTarget.value,
    });
  };

  const handleNewConstructionSelect = (item) => {
    if (item === 'newConstruction') {
      props.onUpdateFormData({
        ...props.formData,
        age: NEW_CONSTRUCTION_AGE,
      });
      setNewConstructionOptionValues({
        newConstruction: true,
        notNewConstruction: false,
      });
    }

    if (item === 'notNewConstruction') {
      props.onUpdateFormData({
        ...props.formData,
        age: DEFAULT_AGE,
      });
      setNewConstructionOptionValues({
        newConstruction: false,
        notNewConstruction: true,
      });
    }
  };

  useEffect(() => {
    setNewConstructionOptionValues(
      props.formData.age > NEW_CONSTRUCTION_AGE_THRESHOLD
        ? {
            newConstruction: false,
            notNewConstruction: true,
          }
        : {
            newConstruction: true,
            notNewConstruction: false,
          },
    );
  }, [props.formData.age]);

  const handlesquarefootageClose = (squareFootageValue) => {
    props.onUpdateFormData({
      ...props.formData,
      squareFootage:
        brandLocation === 'AHS'
          ? getSquareFootageRangeValueNum(squareFootageValue)
          : squareFootageValue,
    });
    // setValues({...values, });
    setIsModalActive(false);
  };

  const handleStartOver = () => {
    setIsModalActive(false);
    props.onUpdateFormData({
      ...props.formData,
      squareFootage: null,
    });
  };

  return (
    <>
      <div className="pt-8">
        <OrderForm.Container id="property-info-form">
          <OrderForm.Row>
            <OrderForm.RowItem>
              <Select
                formField={true}
                id="wb_pdResidenceType"
                name="residenceType"
                label={ORDER_FORM_FIELDS.RESIDENCE_TYPE}
                placeholder={ORDER_FORM_FIELDS.RESIDENCE_TYPE__PLACEHOLDER}
                required={true}
                options={residenceTypeValues}
                selected={
                  props.formData.residenceType
                    ? residenceTypeValues.find((o) => o.value === props.formData.residenceType)
                    : null
                }
                onSelect={handleResidenceTypeSelect}
                error={props.errors.residenceType}
                disabled={isEditing}
                onBlur={handleResidenceTypeBlur}
                onFocus={() => props.clearFieldError(OrderFormPropertyInfoField.residenceType)}
              />
            </OrderForm.RowItem>
            {canModifySquareFootage() && (
              <OrderForm.RowItem>
                <Select
                  formField={true}
                  id="wb_pdSquareFootage"
                  name="squareFootage"
                  label={ORDER_FORM_FIELDS.SQUARE_FOOTAGE}
                  placeholder={ORDER_FORM_FIELDS.SQUARE_FOOTAGE__PLACEHOLDER}
                  required={true}
                  options={squareFootageValues}
                  selected={
                    props.formData.squareFootage
                      ? squareFootageValues.find(
                          (o) => o.value === props.formData.squareFootage?.toString(),
                        )
                      : null
                  }
                  onSelect={handleSquareFootageSelect}
                  error={props.errors.squareFootage}
                  disabled={isEditing}
                  onBlur={handleSquareFootageBlur}
                  onFocus={() => props.clearFieldError(OrderFormPropertyInfoField.squareFootage)}
                />
              </OrderForm.RowItem>
            )}
          </OrderForm.Row>

          <OrderForm.Row>
            <RadioGroup
              formField={true}
              id="wb_pdNewConstruction"
              name="newConstruction"
              label={ORDER_FORM_FIELDS.NEW_CONSTRUCTION__QUESTION}
              labelInfo={NEW_ORDER_LABELS.HELP_TEXT__OPTIONAL}
              radios={newConstructionValues}
              value={Object.keys(newConstructionOptionValues).find(
                (k) => newConstructionOptionValues[k] === true,
              )}
              onChange={handleNewConstructionSelect}
              error={!props.formData.age ? props.errors.age : ''}
              disabled={isEditing || props.isRealtorOwned}
            />
          </OrderForm.Row>

          <OrderForm.Row>
            <OrderForm.RowItem>
              <Input
                formField={true}
                id="wb_pdMLSNumber"
                name="mlsNumber"
                label={ORDER_FORM_FIELDS.MLS}
                labelInfo={NEW_ORDER_LABELS.HELP_TEXT__OPTIONAL}
                placeholder={ORDER_FORM_FIELDS.MLS__PLACEHOLDER}
                autoComplete="off"
                value={props.formData.mlsNumber || ''}
                maxLength={MLS_NUMBER_MAX_LENGTH}
                onChange={handleMlsNumberChange}
                onBlur={console.log}
              />
            </OrderForm.RowItem>
          </OrderForm.Row>
        </OrderForm.Container>
      </div>

      <ModalIneligibleDwellingSize
        isActive={isModalActive}
        sqFtMax={
          brandLocation === 'AHS' ? SQUARE_FOOTAGE_VALUES.AHS.max : SQUARE_FOOTAGE_VALUES.HSA.max
        }
        onClose={(coverageValue) => handlesquarefootageClose(coverageValue)}
        startOver={handleStartOver}
      />
    </>
  );
};

export default OrderFormPropertyInfo;
