import React, { useEffect, useState } from 'react';
import { addressToString } from '@services/helpers';
import {
  COMPANY_NAME_REQUIRED,
  REASON_CANCELLING_REQUIRED,
  REASON_DIFFERENT_PROVIDER_REQUIRED,
  REASON_REQUIRED,
} from '@constants/formField-constants';
import { Formik } from 'formik';
import { Button, Input, Select, Text } from '@ftdr/blueprint-components-react';
import ModalCancelOrder from '@components/modal/ModalCancelOrder';
import { getFormInputErrorId, getFormSelectErrorId } from '@storybook/addon-links';
import { Quote } from '@apis/models/quote.api.model';
import RealEstateQuoteApi from '@apis/realestatequote.api';
import msg, { msgsWParams } from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { fireGAEvent } from '@app/core/tracking.service';
import { PLAN_PRICE__CANCEL_ORDER } from '@constants/ga-events.constants';
import { setCancelQuoteLocal } from '@helpers/localStorage.utils';

type FormErrors = { [P in keyof FormValues]?: string };

interface FormValues {
  reason: number;
  other?: string;
  differentProvider?: number;
  providerName?: string;
}

export interface PlanPriceCancelQuoteProps {
  selectedOrder: Quote;
  onClose: () => void;
  onRefreshQuotes?: () => void;
}

const PlanPriceCancelQuote: React.FC<PlanPriceCancelQuoteProps> = (props) => {
  const defaultCancelReasonValues = {
    reason: 0,
    other: '',
    differentProvider: null,
    providerName: '',
  };

  const [cancelReason, setCancelReason] = useState([]);
  const [cancelDifferentProviderOptionReason, setCancelDifferentProviderOptionReason] = useState(
    [],
  );

  const [cancelReasonValues, setCancelReasonValues] =
    useState<FormValues>(defaultCancelReasonValues);

  const [isModalActive, setIsModalActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [showDifferentProvider, setShowDifferentProvider] = useState(false);

  const [isValid, setIsValid] = useState(true);
  const [address, setAddress] = useState('');

  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();

  /** When contract is loaded, refresh the view with information needed */
  useEffect(() => {
    // Reset view
    setIsValid(true);
    setAddress('');

    if (props.selectedOrder) {
      // Load view if valid
      const { address1, address2, city, state, zip } = props.selectedOrder.property;
      setAddress(addressToString(address1, address2, city, state, zip));

      RealEstateQuoteApi.getCancelReason()
        .then((response) => {
          const categories = response.categories
            .sort((a, b) => a.id - b.id)
            .map((data) => {
              if (data.subCategories && data.subCategories.length > 0) {
                const subCategories = data.subCategories
                  .sort((a, b) => a.id - b.id)
                  .map((subData) => {
                    return {
                      id: subData.id,
                      label: subData.name,
                      value: subData.id,
                    };
                  });
                setCancelDifferentProviderOptionReason(subCategories);
              }
              return {
                id: data.id,
                label: data.name,
                value: data.id,
              };
            });
          setCancelReason(categories);
          setIsValid(true);
        })
        .catch(() => {
          setIsValid(false);
          addErrorToQueue(msgsWParams.QUOTE_CANCEL_REASON_FETCH_FAILED());
        });
    } else {
      setIsValid(false);
    }
  }, [props.selectedOrder]);

  const submitForm = (values: FormValues) => {
    setCancelReasonValues(values);
    setIsModalActive(true);
  };

  const handleOrderCancelConfirmed = () => {
    setIsLoading(true);
    const quoteId = props?.selectedOrder?.summary?.id;
    const reason = cancelReason.filter((data) => data.id === cancelReasonValues.reason)[0].label;

    RealEstateQuoteApi.cancelQuote(quoteId, {
      category: cancelReasonValues.reason,
      subCategory: cancelReasonValues.differentProvider,
      description: [
        reason,
        cancelReasonValues.other,
        cancelReasonValues.differentProvider,
        cancelReasonValues.providerName,
      ]
        .filter(Boolean)
        .join('; '),
    })
      .then(() => {
        fireGAEvent(PLAN_PRICE__CANCEL_ORDER(reason));
        props.onClose();
        addSuccessToQueue(msgsWParams.QUOTE_CANCEL_SUCCESS(quoteId));
        setCancelQuoteLocal(quoteId);
        if (props.onRefreshQuotes) {
          props.onRefreshQuotes();
        }
      })
      .catch(() => {
        addErrorToQueue(msgsWParams.QUOTE_CANCEL_FAILED());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormChange = (values: FormValues) => {
    const isOther = values.reason === 7;
    const isDifferentProvider = values.reason === 2;
    setShowOtherField(isOther);
    setShowDifferentProvider(isDifferentProvider);

    if (!isOther) {
      // clean 'other' field value
      setCancelReasonValues({
        ...values,
        other: '',
      });
    }

    if (!isDifferentProvider) {
      // clean 'different provider' field values
      setCancelReasonValues({
        ...values,
        differentProvider: null,
        providerName: '',
      });
    }
  };

  const validateForm = (values: FormValues): FormErrors => {
    handleFormChange(values);
    const errors: FormErrors = {};

    if (!values.reason) {
      errors.reason = REASON_REQUIRED;
    }

    if (showOtherField && !values.other) {
      errors.other = REASON_CANCELLING_REQUIRED;
    }

    if (showDifferentProvider && !values.differentProvider) {
      errors.differentProvider = REASON_DIFFERENT_PROVIDER_REQUIRED;
    }

    if (showDifferentProvider && !values.providerName) {
      errors.providerName = COMPANY_NAME_REQUIRED;
    }

    return errors;
  };

  const onReasonSelect = (item, formikHandler) => {
    formikHandler({
      target: {
        name: 'reason',
        value: item.id,
        label: item.label,
      },
    });
  };

  const onDifferentProviderReasonSelect = (item, formikHandler) => {
    formikHandler({
      target: {
        name: 'differentProvider',
        value: item.value,
        lable: item.label,
      },
    });
  };

  return (
    <>
      <Text variant="heading-03" color="primary">
        Cancel Quote
      </Text>

      <Text variant="body-long" className="mt-4">
        {address}
      </Text>

      {!isValid ? (
        <p>{msg.QUOTE_CANCELLATION_NOT_ALLOWED.message}</p>
      ) : (
        <Formik
          initialValues={cancelReasonValues}
          enableReinitialize={true}
          validate={validateForm}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <p className="mt-8">Please let us know why you are cancelling this quote.</p>

              <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                <Select
                  formField={true}
                  formFieldMessageId={getFormSelectErrorId('wb_cancelOrderReason')}
                  id="wb_cancelOrderReason"
                  name="reason"
                  label="Select a reason"
                  required={true}
                  multiSelect={false}
                  options={cancelReason}
                  onSelect={(item) => onReasonSelect(item, handleChange)}
                  selected={
                    values.reason ? cancelReason.find((o) => o.value === values.reason) : null
                  }
                  error={errors.reason}
                />
              </div>

              {showOtherField && (
                <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                  <Input
                    formField={true}
                    formFieldMessageId={getFormInputErrorId('wb_coOther')}
                    id="wb_coOther"
                    name="other"
                    required={true}
                    label="Reason for canceling"
                    labelInfo=""
                    placeholder="Enter"
                    autoComplete="off"
                    value={values.other}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.other}
                  />
                </div>
              )}

              {showDifferentProvider && (
                <>
                  <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                    <Select
                      formField={true}
                      formFieldMessageId={getFormSelectErrorId('wb_cancelOrderDifferentProvider')}
                      id="wb_cancelOrderDifferentProvider"
                      name="differentProvider"
                      label="Reason for choosing different provider"
                      required={true}
                      multiSelect={false}
                      options={cancelDifferentProviderOptionReason}
                      onSelect={(item) => onDifferentProviderReasonSelect(item, handleChange)}
                      selected={
                        values.differentProvider
                          ? cancelDifferentProviderOptionReason.find(
                              (o) => o.value === values.differentProvider,
                            )
                          : null
                      }
                      error={errors.differentProvider}
                    />
                  </div>
                  <div className="w-full sm:max-w-1/2 mt-8 mb-4">
                    <Input
                      formField={true}
                      formFieldMessageId={getFormInputErrorId('wb_coProviderName')}
                      id="wb_coProviderName"
                      name="providerName"
                      required={true}
                      label="Which company was selected?"
                      placeholder="Which company was selected?*"
                      value={values.providerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.providerName}
                    />
                  </div>
                </>
              )}

              <div className="mt-12 -mx-4 md:-mx-8">
                <div className="md:flex md:flex-wrap md:justify-end md:items-center w-full px-4 md:px-8">
                  <Button
                    className="sm-max:w-full sm-max:mt-4 md:ml-4"
                    color="error"
                    label="Cancel Quote"
                    size="large"
                    shape="rounded"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      <ModalCancelOrder
        id="drawer-cancel-quote__modal-cancel-quote"
        heading="Are you sure you want to cancel this quote?"
        confirmButtonLabel="Cancel Quote"
        isActive={isModalActive}
        isButtonDisabled={isLoading}
        onClose={() => setIsModalActive(false)}
        onConfirm={() => handleOrderCancelConfirmed()}
      />
    </>
  );
};

export default PlanPriceCancelQuote;
