import React, { useEffect, useState } from 'react';
import DrawerOrderCombined from '@components/drawer/DrawerOrderCombined';
import { Contract } from '@apis/models';
import PubSub from 'pubsub-js';
import { publishToRefreshedContracts } from '@app/core/refreshContract.service';

const TOPIC_DRAWER_ORDER = 'TopicDrawerOrder';

export interface GlobalDrawerOrderOptions {
  /** required: specify a valid contract id */
  contractId: string;
  /** optional: when retrieving contract data, do not display a screen load spinner */
  hideScreenLoadSpinner?: boolean;
}

/** pass in contract id in order to open the drawer automatically through global usage.
 * note, we can enhance this to allow passing in the drawer type to allow opening any drawers and not just orders */
export const openOrderDrawer = (options: GlobalDrawerOrderOptions): void => {
  PubSub.publish(TOPIC_DRAWER_ORDER, options);
};

/**
 * Global Order Drawer that can be opened up anywhere in the App by using the openOrderDrawer function.
 *
 * Any pages or components that need to react to these subscription changes would need to use subscribeToRefreshContracts
 * function to listen to events of when the drawer was changed and act upon it.
 *
 * For demo, see page /demoGlobalDrawer where any changes on the drawer would be reflected onto the page.
 */
const GlobalDrawerOrder: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<GlobalDrawerOrderOptions>({
    contractId: '',
  });

  useEffect(() => {
    const subscription = PubSub.subscribe(
      TOPIC_DRAWER_ORDER,
      (_, queuedOptions: GlobalDrawerOrderOptions) => {
        if (queuedOptions) {
          // for now, only allow the drawer to open once if it hasn't been opened yet.
          // toss the queued contract id if already open
          if (isDrawerOpen) {
            return;
          }
          setOptions(queuedOptions);
          setIsDrawerOpen(true);
        }
      },
    );
    return function cleanup() {
      PubSub.unsubscribe(subscription);
    };
  }, []);

  /** publish event that contract should reload */
  const onRefreshedContractLoad = (contract: Contract) => {
    publishToRefreshedContracts([contract]);
  };

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setOptions({
      contractId: '',
    });
  };

  return (
    <DrawerOrderCombined
      isOrderDrawerActive={isDrawerOpen}
      onClose={onCloseDrawer}
      onRefreshedContractLoad={onRefreshedContractLoad}
      contractID={options.contractId}
      includeScreenLoadSpinner={!options.hideScreenLoadSpinner}
    />
  );
};

export default GlobalDrawerOrder;
