import React from 'react';
import { Address } from '@app/models';
import ModalConfirmAddressEntered from '@components/modal/ModalConfirmAddressEntered';
import AddressApi, { toApiAddress } from '@apis/address.api';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { getErrorMessageFromAddressDetailsCall } from '@components/card/CardNewOrderPropertyAddress';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { noop } from 'lodash';
import { isAppAddressMatch } from '@helpers/utils';

export interface AddressVerificationItem {
  /** in the circumstances where we need a different id per item, override it on the item level. */
  overrideModalId?: string;
  /** in the circumstances where we need a different modal title, override it on the item level. */
  overrideModalHeading?: string;
  /** an identifier. Used in the event multiple addresses are passed in. */
  key?: any;
  /** required: the address to be verified */
  address: Address;
}

export interface AddressVerificationVerifiedItem {
  /** an identifier. Matches with the key from the request item for identifying. */
  key: any;
  /** the verified address returned from the request. */
  verifiedAddress: Address;
}

interface Props {
  /** id of the modal component */
  id: string;
  /** set to true to start going through the items one by one */
  isActive: boolean;
  /** addresses to be verified. able to pass more than one if needed */
  items: AddressVerificationItem[];
  /** called when the component needs to be reset.  set isActive to false and clear all items in this step. */
  onReset: () => void;
  /** disable creating an unverified address, in the event we want to suppress it. */
  disableUnverifiedAddressCreation?: boolean;

  /** if there was an error when making the api call, this returns the error string. */
  onVerifyAddressError?: (err: string) => void;
  /** if there was an error when creating unverified address. this returns the error string. */
  onCreateUnverifiedAddressError?: (err: string) => void;

  /** optional: when modal has been open, this event is called. */
  onOpenModal?: (item: AddressVerificationItem, suggestedAddress: Address) => void;
  /** optional: when the modal has been closed, this event is called. */
  onCloseModal?: () => void;
  /** optional: event handler that signals an address match was found. used if need to look at a single address item or create ga event. */
  onAddressMatchFound?: (verifiedItem: AddressVerificationVerifiedItem) => void;
  /** optional: event handler that signals the user selected the suggested address during confirmation. used if need to look at a single address item or create ga event. */
  onSelectSuggestedAddress?: (verifiedItem: AddressVerificationVerifiedItem) => void;
  /** optional: event handler that signals the user selected to use their own entered address. used if need to look at a single address item or create ga event. */
  onClickUserAddress?: (item: AddressVerificationItem) => void;
  /** optional: event handler that signals the user's entered address has been formalized (created unverified address uuid). used if need to look at a single address item or create ga event. */
  onSelectUserAddress?: (verifiedItem: AddressVerificationVerifiedItem) => void;
  /** optional: event handler that signals the return of a verified address item.  used if need to look at a single address item or create ga event. */
  onVerifiedAddress?: (verifiedItem: AddressVerificationVerifiedItem) => void;
  /** when all items in the request is verified, this event is called and can be iterated. */
  onComplete?: (verifiedItems: AddressVerificationVerifiedItem[]) => void;
}

interface State {
  isModalOpen: boolean;
  loadingAddress: boolean;
  creatingUnverifiedAddress: boolean;
  /** items that are from the prop and needs verification. */
  unverifiedAddressItems: AddressVerificationItem[];
  /** item that is running through validation at the moment. */
  inputAddressItem: AddressVerificationItem;
  /** address returned from API service */
  suggestedAddress: Address;
  verifiedAddressItems: AddressVerificationVerifiedItem[];
}

/** Component is used to initiate an address verification.  Determines if there is a valid match for the address.
 *  If valid match found, that address is returned.  Otherwise, the confirm address modal would appear.
 *
 *  For this confirmation modal, if there is a suggested address, that would be displayed to the user as an option.
 *  The modal would also display the user's entered address in the event they want to continue.
 *
 *  Selecting to use the suggested address, that address is returned.
 *  Selecting to use the user entered address, an unverified address will be created and then it is returned.
 */
class AddressVerification extends React.Component<Props, State> {
  static defaultProps = {
    onCreateUnverifiedAddressError: () => {
      useGlobalAlert().addErrorToQueue(msgs.NEW_ORDER__CREATE_UNVERIFIED_ADDRESS_FAILED);
    },
    onOpenModal: noop,
    onComplete: noop,
    onAddressMatchFound: noop,
    onClickUserAddress: noop,
    onCloseModal: noop,
    onSelectSuggestedAddress: noop,
    onVerifiedAddress: noop,
    onVerifyAddressError: noop,
    onSelectUserAddress: noop,
    onReset: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      loadingAddress: false,
      creatingUnverifiedAddress: false,
      unverifiedAddressItems: [],
      inputAddressItem: null,
      suggestedAddress: null,
      verifiedAddressItems: [],
    };
  }

  componentDidMount() {
    this._startup();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.isActive !== prevProps.isActive) {
      this._startup();
    }
  }

  /** when component is set to active, we want to start up the component.
   *  when component is enabled or disabled, load up the state queue appropriately. */
  _startup = () => {
    if (this.props.isActive) {
      this.setState({
        unverifiedAddressItems: this.props.items,
      });
      if (this.props.items.length > 0) {
        this.queueNextItem(this.props.items, []);
      }
    } else {
      this.clearStates();
      this.closeModal();
    }
  };

  /** grab the first item in the queue list and execute the address verification */
  queueNextItem = (
    pendingUnverifiedItems: AddressVerificationItem[],
    verifiedItems: AddressVerificationVerifiedItem[],
  ) => {
    if (pendingUnverifiedItems.length > 0) {
      const item = pendingUnverifiedItems[0];
      this.verifyAddressExists(item);
    } else if (verifiedItems.length === this.props.items.length) {
      this.finalizeQueue(verifiedItems);
    }
  };

  /** when there are no longer and unverified items, and the verified items match with the items provided,
   * considered that the verification is entirely completed. */
  finalizeQueue = (verifiedItems: AddressVerificationVerifiedItem[]) => {
    this.props.onComplete(verifiedItems);
    this.props.onReset();
  };

  verifyAddressExists = (enteredAddress: AddressVerificationItem) => {
    this.setState({ loadingAddress: true });
    useGlobalOverlaySpinner().showSpinner(true, 'finding address...');
    AddressApi.getAddressDetail(enteredAddress.address)
      .then((address) => {
        // if an address is not found, then display modal
        if (!address) {
          this.openModal(enteredAddress, null);
          return;
        }
        // if an address does not have a match, then display modal
        if (!isAppAddressMatch(address, enteredAddress.address)) {
          this.openModal(enteredAddress, address);
          return;
        }
        // address match is found
        this.useMatchingAddress(address, enteredAddress);
        return;
      })
      .catch((err) => {
        this.props.onVerifyAddressError(getErrorMessageFromAddressDetailsCall(err));
      })
      .finally(() => {
        this.setState({ loadingAddress: false });
        useGlobalOverlaySpinner().showSpinner(false);
      });
  };

  openModal = (enteredAddress: AddressVerificationItem, suggestedAddress: Address) => {
    if (!this.state.isModalOpen) {
      this.setState({
        suggestedAddress: suggestedAddress,
        inputAddressItem: enteredAddress,
        isModalOpen: true,
      });
      this.props.onOpenModal(enteredAddress, suggestedAddress);
    }
  };

  closeModal = () => {
    if (this.state.isModalOpen) {
      this.setState({ isModalOpen: false });
      this.props.onCloseModal();
    }
  };

  cancelModal = () => {
    this.closeModal();
    this.props.onReset();
  };

  clearStates = () => {
    this.setState({
      unverifiedAddressItems: [],
      verifiedAddressItems: [],
      suggestedAddress: null,
      inputAddressItem: null,
    });
  };

  useMatchingAddress = (verifiedAddress: Address, inputItem: AddressVerificationItem) => {
    const verifiedAddressItem = this.createAddressVerificationVerifiedItem(
      verifiedAddress,
      inputItem.key,
    );
    this.props.onAddressMatchFound(verifiedAddressItem);
    this.completeVerification(verifiedAddressItem, inputItem);
  };

  useSuggestedAddress = () => {
    this.closeModal();
    const verifiedAddressItem = this.createAddressVerificationVerifiedItem(
      this.state.suggestedAddress,
    );
    this.props.onSelectSuggestedAddress(verifiedAddressItem);
    this.completeVerification(verifiedAddressItem);
  };

  useUserAddress = () => {
    this.props.onClickUserAddress(this.state.inputAddressItem);
    this.setState({ creatingUnverifiedAddress: true });
    useGlobalOverlaySpinner().showSpinner(true, 'saving address...');

    // determine if it is necessary to create an unverified address
    let callback: typeof AddressApi.createUnverifiedAddress;
    if (this.props.disableUnverifiedAddressCreation) {
      callback = () => Promise.resolve(this.state.inputAddressItem.address);
    } else {
      callback = (...args) => AddressApi.createUnverifiedAddress(...args);
    }

    callback(toApiAddress(this.state.inputAddressItem.address))
      .then((verifiedAddress) => {
        this.closeModal();
        const verifiedAddressItem = this.createAddressVerificationVerifiedItem(verifiedAddress);
        this.props.onSelectUserAddress(verifiedAddressItem);
        this.completeVerification(verifiedAddressItem);
      })
      .catch((err) => {
        this.props.onCreateUnverifiedAddressError(err);
      })
      .finally(() => {
        this.setState({ creatingUnverifiedAddress: false });
        useGlobalOverlaySpinner().showSpinner(false);
      });
  };

  /** complete the verification of an address by taking the input and verified input, and queuing up the next item after resolution. */
  completeVerification = (
    verifiedItem: AddressVerificationVerifiedItem,
    inputItem = this.state.inputAddressItem,
  ) => {
    const verifiedItems = this.addVerifiedAddress(verifiedItem);
    const unverifiedItems = this.removeInputFromQueue(inputItem);
    this.queueNextItem(unverifiedItems, verifiedItems);
  };

  /** removes the current input address from our unverified address queue.
   * For address match, input address won't be set, so the input item is passed in for this case.
   * @return AddressVerificationItem[] - the updated unverified items list */
  removeInputFromQueue = (inputItem: AddressVerificationItem): AddressVerificationItem[] => {
    const updatedUnverifiedItems = this.state.unverifiedAddressItems.filter(
      (item) => item !== inputItem,
    );
    this.setState({ unverifiedAddressItems: updatedUnverifiedItems });
    return updatedUnverifiedItems;
  };

  /** create an verified address item. key is obtained from the input address.
   * For address match, input address won't be set, so the key needs to be passed in for that case. */
  createAddressVerificationVerifiedItem = (
    verifiedAddress: Address,
    key = this.state.inputAddressItem?.key,
  ): AddressVerificationVerifiedItem => ({
    key,
    verifiedAddress,
  });

  /** add verified address to the list of completed items and send onVerifiedAddress event
   * @return AddressVerificationVerifiedItem[] - the updated verified items list */
  addVerifiedAddress = (
    verifiedAddressItem: AddressVerificationVerifiedItem,
  ): AddressVerificationVerifiedItem[] => {
    const updatedVerifiedItems = [...this.state.verifiedAddressItems, verifiedAddressItem];
    this.setState({ verifiedAddressItems: updatedVerifiedItems });
    this.props.onVerifiedAddress(verifiedAddressItem);
    return updatedVerifiedItems;
  };

  render() {
    return (
      <ModalConfirmAddressEntered
        id={this.state.inputAddressItem?.overrideModalId || this.props.id}
        heading={this.state.inputAddressItem?.overrideModalHeading || 'Address Verification'}
        isActive={this.state.isModalOpen}
        onClose={this.cancelModal}
        addressFromService={this.state.suggestedAddress}
        addressFromInput={this.state.inputAddressItem?.address}
        onEditAddress={this.cancelModal}
        onUseServiceAddress={this.useSuggestedAddress}
        onUseCurrentAddress={this.useUserAddress}
      />
    );
  }
}

export default AddressVerification;
