import React from 'react';
import IdleTimer from 'react-idle-timer';
import ConfirmOffice from '@pages/office/ConfirmOffice';
import { getConfig } from '@services/app.config.loader';
import ProfileApi from '@apis/profile.api';
import ProfileModel, { ProfileOffice } from '@app/models/profile.model';
import { OfficeRequest, UpdateOfficeRequest } from '@apis/models/profile.api.model';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import usaStates from '@constants/us-states';
import { officeTypeDictionary, officeTypeFullFormDictionary } from '@constants/dictionaries';
import { Office } from '@apis/models';
import { getLastLocation, saveCurrentLocation } from '@services/callback.service';
import { populateJWT } from '@apis/_api';
import { emailVerified, hardLogout, redirectToVerifyEmail } from '@helpers/auth.utils';
import {
  clearCancelQuoteLocalList,
  JUST_LOGGED_IN_KEY,
  JUST_REGISTERED,
} from '@helpers/localStorage.utils';
import Path from '@constants/paths';
import { setJWT } from './authService';
import ProfileContext from '../context/ProfileContext/index';
import { hasAdminPermissions } from '@helpers/permissions.utils';
import { fireGAEvent, firePageView } from '@app/core/tracking.service';
import {
  ACCOUNT_MANAGEMENT__REGISTRATION_COMPLETE,
  LOGIN_SUCCESS,
  VIEW_PAGE,
} from '@constants/ga-events.constants';
import { hotjar } from 'react-hotjar';
import ModalInactiveOffice from '@components/modal/ModalInactiveOffice';
import ModalSiteIdling from '@components/modal/ModalSiteIdling';
import SplashScreenLauncher from '@components/splashScreen/SplashScreenLauncher';
import { isAgent } from '@helpers/profile.utils';
import { addProfileSentryTags } from '@app/core/sentry.service';

const seconds = 1000;
const minutes = seconds * 60;
const loginRetries = 5;
const retrySecondsToWait = 5;

interface AuthState {
  isAuthed: boolean;
  isIdle: boolean;
  redirectToConfirmOffice: boolean;
  isProfileConfirmed: boolean;
  showInactiveOfficeModal: boolean;
  showSearchOfficeDrawer: boolean;
  willReRender: boolean;
  permissionsValidated: boolean;
  isRedirecting: boolean;
  isInactiveOfficeSearch: boolean;
}

/**
@field shouldRequireAuth Default: true. When False, will overwrite the need to authenticate the user while maintaining all other functionality
*/
interface Options {
  shouldRequireProfile?: boolean;
  shouldCheckInactiveOffices?: boolean;
  requiredPermissions?: string[];
  shouldRequireAuth?: boolean;
}

const defaultOptions: Options = {
  shouldRequireProfile: true,
  shouldCheckInactiveOffices: true,
  requiredPermissions: [],
  shouldRequireAuth: true,
};

// TODO This is becoming quite large.  Functionality should be broken into other components and called from here.  Should also be renamed.
const requireAuth = (
  ComposedComponent: any,
  redirectPath: string,
  authService: any,
  options: Options = {},
) => {
  // Update the redirect path to note include starting slash on redirect path to not break existing functionality
  // TODO: clean the logic up to not need this
  if (redirectPath && redirectPath.startsWith('/')) {
    redirectPath = redirectPath.substring(1);
  }

  // Update the Options to include anything that wasn't passed in to use the default value
  options = { ...defaultOptions, ...options };

  class Authenticate extends React.Component<any, AuthState> {
    static contextType = ProfileContext;

    timeout = (parseInt(getConfig('timeout-length'), 10) || 58) * minutes;
    timeoutWarning = (parseInt(getConfig('timeout-warning-length'), 10) || 2) * minutes;
    idleTimer = null;
    forceLogout = null;
    inactiveOffice: ProfileOffice = null;
    isRedirectingStateless = false;

    constructor(props) {
      super(props);

      this.state = {
        isAuthed: false,
        isIdle: false,
        redirectToConfirmOffice: false,
        isProfileConfirmed: false,
        showInactiveOfficeModal: false,
        showSearchOfficeDrawer: false,
        willReRender: false,
        permissionsValidated: options.requiredPermissions.length === 0,
        // Code continues to execute while a window.location.assign is occurring
        // This flag is used to wrap any code we need to prevent from executing
        isRedirecting: false,
        isInactiveOfficeSearch: false,
      };
    }

    async componentDidMount() {
      this.checkAndRedirect(true);
      await this.fetchUpdatedUser();
      this.checkVerifiedEmail();
      this.confirmOffice();
      saveCurrentLocation();

      let { isRedirecting } = this.state;

      // Check if redirect is needed based on profile
      if (!isRedirecting) {
        isRedirecting = this.checkProfileRequirements();
      }

      // check if not redirecting, then should check inactive office for modal pop up
      if (!isRedirecting && options.shouldCheckInactiveOffices) {
        await this.checkForInactiveOffice();
      }

      // check if not redirecting, check permissions to see if redirect is necessary
      // TODO this really should be moved to checkProfileRequirements instead, but keeping it here because of the TODO below
      // TODO Eventually route permissions should be handled by removing routes from the Route array, but App.tsx doesn't have access to API calls yet
      if (!isRedirecting && !this.state.permissionsValidated) {
        await this.checkPermissions();
      }

      const { profile } = this.context;
      addProfileSentryTags(profile);
      firePageView(VIEW_PAGE(redirectPath, profile));
      const userId = profile?.profileID;
      hotjar.identify(userId, {
        UserType: profile.roleIDType,
      });

      const justRegistered = localStorage.getItem(JUST_REGISTERED);
      const justLoggedIn = localStorage.getItem(JUST_LOGGED_IN_KEY);

      if (justRegistered) {
        fireGAEvent(ACCOUNT_MANAGEMENT__REGISTRATION_COMPLETE(profile));
        localStorage.removeItem(JUST_REGISTERED);
      }

      // If they logged in (Opposed to having an active session already)
      // We want to fire an event to capture a successful login
      // This value should only exist if they went through our callback flow
      if (justLoggedIn && this.shouldRenderComposed()) {
        fireGAEvent(LOGIN_SUCCESS());
        localStorage.removeItem(JUST_LOGGED_IN_KEY);
        clearCancelQuoteLocalList();
      }
    }

    componentDidUpdate() {
      this.checkAndRedirect(false);
    }

    /**
     * This should ONLY be used for events that MUST fire every component lifeCycle
     * Since Auth tokens and timeout can occur at ANY point while using a page, they need to be here
     * Move anything that only needs to be checked on page load, into ComponentDidMount (Make it a function first to reduce clutter)
     * @param isFirstCheck This is used ONLY when this is called from componentDidMount but not on subsequent checks to prevent infinite loops
     */
    checkAndRedirect = (isFirstCheck) => {
      if (authService.oidcUser && !authService.oidcUser.expired && isFirstCheck) {
        this.setState({ isAuthed: true });
      } else if (
        (!authService.oidcUser || authService.oidcUser.expired) &&
        options.shouldRequireAuth &&
        !this.isRedirectingStateless
      ) {
        this.loginWithRetry(loginRetries);

        this.isRedirectingStateless = true;
        this.setState({ isRedirecting: true });
      }
    };

    loginWithRetry = async (retryRemaining: number): Promise<number> => {
      if (retryRemaining > 0) {
        return authService.login('FusionAuth').finally((res) => {
          if (!res) {
            setTimeout(() => this.loginWithRetry(retryRemaining - 1), retrySecondsToWait * seconds);
          } else {
            return Promise.resolve();
          }
        });
      }
      return Promise.resolve(retryRemaining - 1);
    };

    fetchUpdatedUser = async () => {
      const { profile } = this.context;
      populateJWT(authService.oidcUser?.id_token);
      setJWT(authService.oidcUser?.id_token);

      await this.context.fetchProfileData(isAgent(profile) && redirectPath === Path.NewOrder);
    };

    confirmOffice = () => {
      const { profile } = this.context;

      // Looking for 'regist' in redirectPath to capture both 'register' and
      // 'registration'
      if (!this.isOnRegistrationPage() && isAgent(profile)) {
        this.setState({
          redirectToConfirmOffice: !profile.hasConfirmedOffice,
        });
      } else {
        this.setState({
          redirectToConfirmOffice: false,
        });
      }
    };

    handleConfirmOffice = () => {
      const { profile, setProfile } = this.context;

      profile.hasConfirmedOffice = true;

      const updatedProfile = { ...profile };
      ProfileApi.updateUser({
        ...updatedProfile,
        email: null, // ARE-6882 Email should only be updated from the Edit My Account page.
      })
        .then((res) => {
          if (!res.data?.errors) {
            setProfile(updatedProfile);
            this.setState({
              redirectToConfirmOffice: false,
            });
          } else {
            console.error(`failed to update profile flag hasConfirmedOffice: ${res.data?.errors}`);
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    };

    handleNotMyOffice = () => {
      this.handleConfirmOffice();
      this.setState({ isInactiveOfficeSearch: false });
      this.toggleSearchOfficeDrawer();
    };

    /**
     * If we've been authorized but don't have a profile, we need to register.
     * This check only needs to be performed once, on page load
     * @returns true if profile requirements found that redirect is needed
     */
    checkProfileRequirements = (): boolean => {
      let isRedirecting = false;
      const { profile } = this.context;
      if (
        !isRedirecting &&
        options.shouldRequireProfile &&
        (!profile || !profile.profileID) &&
        options.shouldRequireAuth
      ) {
        isRedirecting = true;
        window.location.assign('/profile/error');
      } else if (
        !isRedirecting &&
        options.shouldRequireProfile &&
        !this.isProfileComplete(profile) &&
        options.shouldRequireAuth
      ) {
        // Couldn't get the redirect to work with react-navi inside a class component...
        isRedirecting = true;
        window.location.assign(Path.RegistrationAccount);
      } else if (this.isOnRegistrationPage() && this.isProfileComplete(profile)) {
        // redirect to dashboard if for some reason the user navigates to registration
        window.location.assign(getLastLocation());
      } else {
        this.setState({ isProfileConfirmed: true });
      }
      return isRedirecting;
    };

    isProfileComplete = (profile) => {
      return (
        profile && profile.profileID && profile.profileID !== 'NotFound' && !profile.isIncomplete
      );
    };

    isOnRegistrationPage = () => {
      return !(redirectPath.indexOf('regist') === -1);
    };

    checkForInactiveOffice = async () => {
      const { offices } = this.context.profile;
      let inactiveOffice: ProfileOffice = null;
      for (let i = 0; i < offices.length; i += 1) {
        if (!offices[i].active) {
          inactiveOffice = offices[i];
          break;
        }
      }

      if (inactiveOffice) {
        console.log('profile has inactive office', inactiveOffice);
        this.inactiveOffice = inactiveOffice;
        this.setState({ showInactiveOfficeModal: true });
      }
    };

    onClickOffice = async () => {
      const { profile, setProfile } = this.context;
      const activeOffices: OfficeRequest[] = profile.offices?.filter((office) => office.active);

      if (activeOffices.length > 0) {
        const request: UpdateOfficeRequest = {
          profileID: profile.profileID,
          offices: activeOffices,
        };

        const res = await ProfileApi.updateUsersOfficeDetails(request);
        if (res) {
          const newProfile: ProfileModel = await ProfileApi.getUser();
          if (newProfile) {
            setProfile(newProfile);
          }
        }
      } else {
        this.setState({ isInactiveOfficeSearch: true });
        this.toggleSearchOfficeDrawer();
      }
      this.closeOfficeModal();
    };

    onIdle = () => {
      this.setState({ isIdle: true });
      this.forceLogout = setTimeout(function () {
        hardLogout();
      }, this.timeoutWarning);
      console.log('User is idle!');
    };

    closeIdle = () => {
      this.setState({ isIdle: false });
    };

    closeOfficeModal = () => {
      this.setState({ showInactiveOfficeModal: false });
    };

    toggleSearchOfficeDrawer = () => {
      const currentShowSearchOfficeDrawer = this.state.showSearchOfficeDrawer;
      this.setState({ showSearchOfficeDrawer: !currentShowSearchOfficeDrawer });
    };

    closeSearchOfficeDrawer = () => {
      this.setState({ showSearchOfficeDrawer: false });
    };

    extendSession = () => {
      clearTimeout(this.forceLogout);
      this.closeIdle();
    };

    submitOfficeDrawerHandler = async (offices: Office[]) => {
      const { profile } = this.context;

      if (offices.length > 0) {
        const officeRequest = offices.map((office) => {
          return { id: office.id, type: officeTypeFullFormDictionary[office.type] };
        });

        const request = {
          profileID: profile.profileID,
          offices: officeRequest,
        };

        try {
          await ProfileApi.updateUsersOfficeDetails(request);
          await this.context.fetchProfileData(true);
          // TODO This may no longer be needed with the refactored Profile Fetch
          this.forceChildrenReRender();
        } catch (e) {
          console.log(e);
        }
      } else {
        console.warn('trying to submit an empty office.');
      }

      this.toggleSearchOfficeDrawer();
    };

    checkVerifiedEmail = () => {
      if (
        authService.oidcUser &&
        !emailVerified(authService.oidcUser) &&
        redirectPath !== Path.VerifyEmail.substr(1)
      ) {
        // To get the updated email_verified value we have to clear out the auth
        // user.
        // clearAuthUser();
        this.setState({ isRedirecting: true });
        redirectToVerifyEmail();
      }
    };

    // This is one of the hackiest things I've had to do in awhile.
    // In short, we need a way to force any children to re-render
    // This isn't easily accomplished without passing the children new props, which we don't do here at all
    // So instead, we destroy and re-create the child elements
    // This forces a hard update of the child
    forceChildrenReRender = () => {
      this.setState({ willReRender: true }, () => {
        this.setState({ willReRender: false });
      });
    };

    shouldRenderComposed = () => {
      const { isAuthed, redirectToConfirmOffice, isProfileConfirmed, willReRender } = this.state;
      return (
        (isAuthed || !options.shouldRequireAuth) &&
        !redirectToConfirmOffice &&
        isProfileConfirmed &&
        !willReRender
      );
    };

    /**
     * Validate permissions on profile. If validation fails, function will redirect to last location.
     * @returns true if permission check failed and need redirect. Otherwise false.
     */
    checkPermissions = async (): Promise<boolean> => {
      console.log('checking permissions...');
      const result = await hasAdminPermissions(options.requiredPermissions);
      this.setState({ permissionsValidated: result });
      if (!result) {
        console.debug('Redirecting due to failed permissions');
        window.location.assign(getLastLocation());
        return true;
      }
      return false;
    };

    // This adds the isAuthed prop to any component that needs it
    // Can be extended to add any other props we need across all components
    // Might be a good idea to use this to pass profile, brand, etc. for better dependency injection pattern
    addPropsToComponent = () => {
      const { profile } = this.context;
      return React.cloneElement(ComposedComponent, {
        isAuthed: this.state.isAuthed && this.isProfileComplete(profile),
      });
    };

    render() {
      const {
        isAuthed,
        isIdle,
        redirectToConfirmOffice,
        isProfileConfirmed,
        showInactiveOfficeModal,
        showSearchOfficeDrawer,
        permissionsValidated,
        isRedirecting,
      } = this.state;
      const { profile } = this.context;

      return isRedirecting ? (
        <>{/* redirecting... */}</>
      ) : !permissionsValidated ? (
        <>{/* verifying permissions... */}</>
      ) : (
        <div>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            debounce={1000}
            timeout={this.timeout}
          />
          {isAuthed && redirectToConfirmOffice && isProfileConfirmed && (
            <ConfirmOffice
              handleConfirm={this.handleConfirmOffice}
              handleReject={this.handleNotMyOffice}
            />
          )}
          {/* {isAuthed && !redirectToConfirmOffice && isProfileConfirmed && !willReRender && emailVerified(authService.oidcUser) && ComposedComponent} */}
          {this.shouldRenderComposed() && this.addPropsToComponent()}
          {isAuthed && (
            <ModalInactiveOffice
              isActive={showInactiveOfficeModal}
              onClose={null}
              inactiveOffice={this.inactiveOffice}
              onClick={this.onClickOffice}
              offices={profile?.offices}
              onClickKeep={this.closeOfficeModal}
            />
          )}
          <ModalSiteIdling
            isActive={isIdle}
            onClose={this.closeIdle}
            minutes={this.timeoutWarning / minutes}
            onClick={this.extendSession}
          />
          {profile && (
            <DrawerOfficeSearch
              usaStates={usaStates}
              typeCode={officeTypeDictionary[profile.roleIDType]}
              excludeBrandSearch={false}
              isActive={showSearchOfficeDrawer}
              onClose={this.state.isInactiveOfficeSearch ? null : this.closeSearchOfficeDrawer}
              onSubmit={this.submitOfficeDrawerHandler}
            />
          )}
          {profile && profile.profileID !== 'NotFound' && <SplashScreenLauncher />}
        </div>
      );
    }
  }

  return Authenticate;
};

export default requireAuth;
