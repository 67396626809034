import React from 'react';
import { AbstractNewOrderStep, wrapNavigation } from '@pages/order/AbstractNewOrderStep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderStep1Template } from '@templates/order/NewOrderStep1Template';
import { NewOrderContextData, NewOrderMetaData } from '@context/NewOrderContext/model';
import Layout from '@components/layout/Layout';
import { getPlanReselectionReason, PlanCardResetReason } from '@helpers/order.utils';
import { isNullOrUndefined } from '@helpers/utils';
import OrdersApi from '@apis/orders.api';
import cloneDeep from 'lodash/cloneDeep';
import PropertyApi from '@apis/property.api';
import { getYearBuiltFromAge } from '@services/helpers';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';

class NewOrderStep1 extends AbstractNewOrderStep {
  constructor(props) {
    super(props);
  }

  getCurrentStep(): NewOrderStep {
    return NewOrderStep.AgentPropertyInfo;
  }

  /** handles order update calls.
   * Returns true if order update was considered successful and data should be saved and navigation should occur.
   * Otherwise false. */
  async handleOrderUpdate(
    updatedData: NewOrderContextData,
    updatedMeta = this.context.metaData,
  ): Promise<{ orderID: string; success: boolean }> {
    await this.updatePropertyDetails(updatedData);

    let orderUpdateComplete = false;
    let orderID = this.context.metaData.orderID;
    try {
      useGlobalOverlaySpinner().showSpinner(true, 'updating order...');
      const orderRequest = this.context.toOrderRequest(updatedData, updatedMeta);
      if (!orderID) {
        console.info('order will be created...');
        const orderResponse = await OrdersApi.create(orderRequest);
        console.info('orderid created', orderResponse.orderID);
        orderID = orderResponse.orderID;
        orderUpdateComplete = true;
      } else if (this.context.hasOrderRequestChanged(orderRequest)) {
        console.info('order will be updated...', orderID);
        await OrdersApi.update(orderID, orderRequest);
        console.info('orderid updated', orderID);
        orderUpdateComplete = true;
      } else {
        console.info('no changes on the order found. proceed without updates', orderID);
        orderUpdateComplete = true;
      }
    } catch (e) {
      console.error('error occurred processing order', e);
    } finally {
      useGlobalOverlaySpinner().showSpinner(false);
    }

    return {
      orderID,
      success: orderUpdateComplete,
    };
  }

  /** handles updating property details for the given address and property. return an empty promise when update completes.
   * if no update needed, this would return as well. */
  async updatePropertyDetails(updatedData: NewOrderContextData): Promise<void> {
    if (updatedData.addressInfo?.addressUUID) {
      useGlobalOverlaySpinner().showSpinner(true, 'saving property information...');
      await PropertyApi.updateProperty(updatedData.addressInfo.addressUUID, {
        residenceType: updatedData.propertyInfo.residenceType,
        squareFootage: updatedData.propertyInfo.squareFootage,
        yearBuilt: getYearBuiltFromAge(updatedData.propertyInfo.age),
      })
        .catch((err) => console.error('failed to update or create property', err))
        .finally(() => {
          useGlobalOverlaySpinner().showSpinner(false);
        });
    }
  }

  async submitPage(updatedData: NewOrderContextData) {
    const profile = this.context.userProfile;
    // ga event
    // for pages, set the context data, and navigate to a different step
    // if the changes on the page conflicts with later parts of the page (e.g. changing property address requires change in plans)
    //  then update the context based on what's necessary
    // we'd also want to create an order or update the order, depending on if the context contains the order id or not
    // for order submit, call the api to submit the order, and complete the order

    // if RO option is selected, we want to prefill some RO related data for the user
    // only when they haven't been defined yet
    if (updatedData.agentInfo.selectROOption) {
      if (!updatedData.productFilters.projectedClosingDate) {
        updatedData.productFilters.projectedClosingDate = new Date();
      }
      if (!updatedData.buyerInfo.firstName) {
        updatedData.buyerInfo.firstName = profile.firstName;
        updatedData.buyerInfo.lastName = profile.lastName;
        updatedData.buyerInfo.emailAddress = profile.email;
        updatedData.buyerInfo.phoneNumber = profile.phoneNumber;
      }
    }

    const requirePlanSelectionReason = this.requirePlanReselection(updatedData);
    const updatedMeta: NewOrderMetaData = cloneDeep(this.context.metaData);
    if (requirePlanSelectionReason) {
      console.debug('plan reselection required. clearing plan selections and plan filters');
      updatedMeta.planReselectionReason = requirePlanSelectionReason;
      updatedMeta.quoteID = '';
      updatedData.planInfo = {
        selectedPlanID: '',
        selectedOptionalCoverages: [],
        selectedGroupCoverages: [],
        selectedPlanPrice: null,
        selectedPlanName: '',
        selectedPlanListingTermInDays: null,
        pricingRequest: null,
        pricingResponse: null,
      };
      updatedData.productFilters = {
        ...updatedData.productFilters,
        acCoverage: null,
        sellersCoverage: null,
        term: null,
      };
    }

    const { success, orderID } = await this.handleOrderUpdate(updatedData, updatedMeta);
    if (success) {
      updatedMeta.orderID = orderID;
      this.context.setNewOrderData(updatedData);
      this.context.setMetaData(updatedMeta);
      this.navigateToStep(NewOrderStep.PlanCoverage);
    }
  }

  editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData) {
    // step1 cannot edit another step, this does nothing
    console.warn('attempted to edit another step, but theres no action here. ignored.');
  }

  cancelPage() {
    // ga event
    this.context.resetNewOrderData();
    this.navigateToDashboard();
  }

  /** if plan reselection required from step1, then returns the string reason for it */
  requirePlanReselection(
    updatedData: NewOrderContextData,
    existingData = this.context.newOrderData,
  ): string {
    // if user is still before the plan step, then no plan reselection required
    if (this.context.latestStep < NewOrderStep.PlanCoverage) {
      return '';
    }
    let reason: PlanCardResetReason;
    if (existingData.agentInfo.selectROOption !== updatedData.agentInfo.selectROOption) {
      reason = updatedData.agentInfo.selectROOption
        ? PlanCardResetReason.RealtorOwnedPropertySelected
        : PlanCardResetReason.RealtorOwnedPropertyDeselected;
    }
    if (existingData.initiatingAgent.franchiseCode !== updatedData.initiatingAgent.franchiseCode) {
      reason = PlanCardResetReason.FranchiseChanged;
    }
    if (existingData.addressInfo.addressUUID !== updatedData.addressInfo.addressUUID) {
      reason = PlanCardResetReason.AddressChanged;
    }
    if (existingData.propertyInfo.residenceType !== updatedData.propertyInfo.residenceType) {
      reason = PlanCardResetReason.PropertyChanged;
    }
    if (existingData.propertyInfo.age !== updatedData.propertyInfo.age) {
      reason = PlanCardResetReason.PropertyChanged;
    }
    if (existingData.propertyInfo.squareFootage !== updatedData.propertyInfo.squareFootage) {
      reason = PlanCardResetReason.PropertyChanged;
    }
    return isNullOrUndefined(reason) ? '' : getPlanReselectionReason(reason);
  }

  render() {
    return (
      <Layout isLoggedIn={true} slug="newOrderStep1" containerClassName="py-5">
        <NewOrderStep1Template
          currentStep={this.getCurrentStep()}
          latestStep={this.context.latestStep}
          savedData={this.context.newOrderData}
          metaData={this.context.metaData}
          userProfile={this.context.userProfile}
          submitPage={(updatedData) => this.submitPage(updatedData)}
          cancelPage={() => this.cancelPage()}
          editAnotherStep={(step, updateData) => this.editAnotherStep(step, updateData)}
        />
      </Layout>
    );
  }
}

export default wrapNavigation(NewOrderStep1);
