import * as app from '@app/models';
import { getAgeByYearBuilt, NEW_CONSTRUCTION_AGE, DEFAULT_AGE } from '@services/helpers';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import {
  Address,
  PropertyDetail,
  DwellingTypeCode,
  PropertyDetailList,
  GetOrCreatePropertyIDRequest,
  GetOrCreatePropertyIDResponse,
  CreateOrUpdateProperty,
  PropertyDetailResponse,
} from './models';
import { BaseAPI } from './_api';
import { getSquareFootageFromOptionName } from '@helpers/order.utils';

export class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      true,
      suppressErrors,
    );
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public async getPropertyDetailsByAddress(address: app.Address): Promise<app.Property> {
    const body: Address = {
      address1: address.streetAddress,
      address2: address.unit,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };
    return await this.axios
      .post<any, PropertyDetailList>(`${this.getHost()}/address/properties`, body)
      .then((res) => {
        if (res && res.results.length > 0) {
          if (res.results.length > 1) {
            console.warn('More than one result returned. Using just first one');
          }
          const property = res.results[0];
          return toAppProperty(property);
        }
        return undefined;
      });
  }

  public async updateProperty(
    propertyAddressUUID: string,
    body: CreateOrUpdateProperty,
  ): Promise<app.Property> {
    return this.axios
      .post<
        any,
        PropertyDetailResponse
      >(`${this.getHost()}/address/${propertyAddressUUID}/properties`, body)
      .then((res) => res && toAppProperty(res.property));
  }
}

const PropertyApi = new API();
export const PropertyApiSuppressErrors = new API(true);
export default PropertyApi;

function getTypeOfResidence(propertyDetail: PropertyDetail): app.ResidenceType {
  if (propertyDetail.attributes.dwellingType) {
    switch (propertyDetail.attributes.dwellingType) {
      case DwellingTypeCode.SingleFamilyResidence:
        return app.ResidenceType.SingleFamilyResidence;
      case DwellingTypeCode.Condominium:
        return app.ResidenceType.Condominium;
      case DwellingTypeCode.Townhouse:
        return app.ResidenceType.Townhouse;
      case DwellingTypeCode.Duplex:
        return app.ResidenceType.Duplex;
      case DwellingTypeCode.Triplex:
        return app.ResidenceType.Triplex;
      case DwellingTypeCode.Fourplex:
        return app.ResidenceType.Fourplex;
      case DwellingTypeCode.MobileHome:
        return app.ResidenceType.MobileHome;
      default:
        console.warn('Unhandled case', propertyDetail.attributes.dwellingType);
        break;
    }
  }
  return undefined;
}

// TODO: Move this to helper
function getSquareFootage(propertyDetail: PropertyDetail): number {
  // TODO: This is predicted value, not actual value. Need to check if calculation makes sense with response
  const range = propertyDetail.attributes.squareFootageRange;
  const average = (range.max + range.min) / 2;
  return average;
}

function toAppProperty(property: PropertyDetail): app.Property {
  return {
    typeOfResidence: getTypeOfResidence(property),
    squareFootage: getSquareFootage(property),
    age: getAgeByYearBuilt(property.attributes.yearBuilt),
    meta: {
      legacyId: property.legacyIDs?.[0],
      propertyId: property.ID,
      addressId: property.addressID,
      yearBuilt: property.attributes.yearBuilt,
    },
  };
}
