import { getBrand } from '@helpers/brand.utils';

export function loadQualtric(delayToStart: number = 10000) {
  setTimeout(() => {
    if (getBrand() === 'AHS') {
      const ahsscript = document.createElement('script');
      ahsscript.src = '/statics/ahs/ahsqualtricsurvey.js';
      ahsscript.async = true;
      document.body.appendChild(ahsscript);
    } else if (getBrand() === 'HSA') {
      const hsascript = document.createElement('script');
      hsascript.src = '/statics/hsa/hsaqualtricsurvey.js';
      hsascript.async = true;
      document.body.appendChild(hsascript);
    }
  }, delayToStart);

  if (getBrand() === 'AHS') {
    const ahsdiv = document.createElement('div');
    ahsdiv.id = 'ZN_6KCRZLGLBkqiH7T';
    document.body.appendChild(ahsdiv);
  } else if (getBrand() === 'HSA') {
    const hsadiv = document.createElement('div');
    hsadiv.id = 'ZN_8Jqg8Kflepcukol';
    document.body.appendChild(hsadiv);
  }
}
