import {
  PaymentMethodMicroFrontend,
  PaymentsMethodMfeExposedApi,
} from '@ftdr/payment-method-micro-frontend';
import * as React from 'react';
import { createRef, RefObject, useEffect, useState } from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import { getJWT } from '@app/auth/authService';
import { getConfig } from '@services/app.config.loader';
// eslint-disable-next-line camelcase
import { payment3_paymentmethodpb } from '@ftdr/payment3_paymentmethod_coordinator-js-client';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__MAKE_PAYMENT } from '@constants/ga-events.constants';
import { PAYMENTS } from '@constants/newOrder-constants';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';

interface Props {
  handleMFEResponse: any;
  contractId: any;
  isSubmitting: boolean;
}

const StripeMFE: React.FC<Props> = (props: Props) => {
  const submitFunctionRef: RefObject<PaymentsMethodMfeExposedApi> = createRef();

  const [activePaymentMethod, setActivePaymentMethod] = useState('');
  const [jwtToken, setJwtToken] = useState(getJWT());
  const [agregatePaymentResponse, setAgregatePaymentResponse] = useState({});

  const submitToMFE = async () => {
    console.log('Waiting for result');
    if (activePaymentMethod === 'CREDIT_CARD') {
      const creditCardResult = await submitFunctionRef.current!.processPayment({
        stripe: {
          owner: {
            ownerIdentity: {
              type: payment3_paymentmethodpb.OwnerIdentityType.ContractIdentityType,
              contractIdentity: {
                contractID: props.contractId,
              },
            },
          },
        },
      });
      setAgregatePaymentResponse(creditCardResult);
      console.log('CC Result', creditCardResult);
      fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.CC, props.contractId));
      props.handleMFEResponse(creditCardResult.stripe);
    } else if (activePaymentMethod === 'BANK_ACCOUNT') {
      const paymentusResult = await submitFunctionRef.current!.processPayment({
        paymentus: {
          owner: {
            name: 'SVCRealEstatePro',
            ownerIdentity: {
              type: payment3_paymentmethodpb.OwnerIdentityType.ContractIdentityType,
              contractIdentity: {
                contractID: props.contractId,
              },
            },
          },
          source: {
            name: 'SVCRealEstatePro',
          },
        },
      });
      setAgregatePaymentResponse(paymentusResult);
      console.log('paymentus result', paymentusResult);
      fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.ACH, props.contractId));
      props.handleMFEResponse(paymentusResult.paymentus);
    } else {
      // should never hit this
      const achResult = await submitFunctionRef.current!.processPayment({});
      setAgregatePaymentResponse(achResult);
      console.log('ACH Result', achResult);
    }
  };

  useEffect(() => {
    if (props.isSubmitting) {
      submitToMFE();
    }
  }, [props.isSubmitting]);

  return (
    <>
      <PaymentMethodMicroFrontend
        applicationName="SVCRealEstatePro"
        owner={{
          ownerIdentity: {
            type: payment3_paymentmethodpb.OwnerIdentityType.ContractIdentityType,
            contractIdentity: { contractID: props.contractId },
          },
        }}
        // sets CC as default in MFE radio option
        defaultOpenPaymentMethod={'CREDIT_CARD' as never}
        token={jwtToken}
        baseURL={`${getConfig('baseApiUrl')}/`}
        ref={submitFunctionRef}
        paymentusIframeBaseUrl={getConfig('paymentusUrl')}
        achPaymentProcessor={'achpaymentus'}
        cardPaymentProcessor="stripe"
        onStateChange={(state) => {
          if (state?.activePaymentMethod !== activePaymentMethod && state?.activePaymentMethod) {
            setActivePaymentMethod(state?.activePaymentMethod);
          }
        }}
        onSuccess={() => {}}
        onFailure={() => {}}
        onResponse={(e) => {
          if (e?.setupCardSuccessResponse) {
            console.log('setupCardSuccessResponse');
          }
          if (e?.stripeSuccessResponse) {
            console.log('stripeSuccessResponse');
          }
          if (e?.registrationSuccessResponse) {
            console.log('registrationSuccessResponse');
          }
        }}
      />
    </>
  );
};

export default StripeMFE;
