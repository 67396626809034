import { Text } from '@ftdr/blueprint-components-react';
import { DateFormat, formatDate } from '@helpers/utils';
import React from 'react';

interface editHistoryProps {
  history: any[];
}

const EditHistory = (props: editHistoryProps) => {
  return (
    <>
      {props.history.map((item) => (
        <div key={item.data} className="-mx-4 md:-mx-8">
          <div className="px-4 md:px-8">
            <Text className="mb-1 uppercase text-xs text-gray-600" variant="label" as="strong">
              {formatDate(item.dateTimeStamp, DateFormat.FULL)}
            </Text>

            <Text className="mb-0 text-sm text-gray-600 leading-tight" variant="helper-text">
              {item.data}
            </Text>
          </div>
        </div>
      ))}
    </>
  );
};

export default EditHistory;
