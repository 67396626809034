import React, { useState } from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button, IconRefreshDouble } from '@ftdr/blueprint-components-react';
import DrawerActionExpired from '@components/drawer/DrawerActionExpired';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_EXTEND } from '@constants/ga-events.constants';
import { toFormattedNumber } from '@helpers/utils';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

const CardDashboardActionExpiring = (props: ICardDashBoardActionProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleClickCard = () => {
    fireGAEvent(DASHBOARD_EXTEND);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <CardDashboardAction
        isManualFetch={props.isManualFetch}
        fetchCount={props.fetchCount}
        type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
        heading={props.count === null ? null : toFormattedNumber(props.count)}
        subHeading="Expired or expiring within 30 days"
        action={
          <Button
            label="Extend Orders"
            size="medium"
            startIcon={<IconRefreshDouble />}
            disabled={!props.count}
            onClick={handleClickCard}
          />
        }
      />
      <DrawerActionExpired
        isActive={isDrawerOpen}
        initialContractTotal={props.count}
        onClose={() => setIsDrawerOpen(false)}
        updateTotalContracts={props.updateCount}
      />
    </>
  );
};

export default CardDashboardActionExpiring;
