import { getNewOrderStepLabelOrdered, INewOrderStepDeterminer } from '@helpers/new-order-step';
import { ORDER_CARD_TITLE } from '@constants/newOrder-constants';
import { Order } from '@apis/models/orders.api.model';
import { isAfter } from 'date-fns/fp';

export class NewOrderStepDeterminer implements INewOrderStepDeterminer<Order> {
  isREAgentUser: boolean;

  constructor(isREAgentUser: boolean) {
    this.isREAgentUser = isREAgentUser;
  }

  isStepConsideredComplete(order: Order, step: string): boolean {
    switch (step) {
      case ORDER_CARD_TITLE.BUYER_INFO:
        return this.isBuyerStepComplete(order);
      case ORDER_CARD_TITLE.SELLER_INFO:
        return this.isSellerStepComplete(order);
      case ORDER_CARD_TITLE.CLOSING_INFO:
        return this.isClosingStepComplete(order);
      case ORDER_CARD_TITLE.PLANS_AND_COVERAGES:
        return this.isPlanStepComplete(order);
      default:
        throw new Error(`unhandled step: ${step}`);
    }
  }

  getCurrentNewOrderWorkflow(order: Order): string[] {
    return getNewOrderStepLabelOrdered(this.isREAgentUser, order.getRepresents());
  }

  getCurrentNewOrderStep(order: Order): string {
    const defaultStep = ORDER_CARD_TITLE.PROPERTY_DETAILS;
    const ignoreSteps = [
      ORDER_CARD_TITLE.AGENT_INFO,
      ORDER_CARD_TITLE.PROPERTY_ADDRESS,
      ORDER_CARD_TITLE.PROPERTY_DETAILS,
    ];

    const represents = order.getRepresents();
    const orderedSteps = getNewOrderStepLabelOrdered(this.isREAgentUser, represents);

    const steps = orderedSteps
      .filter((step) => !ignoreSteps.includes(step))
      .map((step) => ({
        name: step,
        complete: this.isStepConsideredComplete(order, step),
      }));

    // if all steps completed, we treat it as being on the final step
    if (steps.every((v) => v.complete === true)) {
      return orderedSteps[orderedSteps.length - 1];
    }
    // if all steps are incomplete, we treat it to be the default step
    if (steps.every((v) => v.complete === false)) {
      return defaultStep;
    }
    // otherwise, we grab the first step that is incomplete
    const incompleteStep = steps.find((v) => v.complete === false);
    return orderedSteps.find((step) => step === incompleteStep.name);
    //
  }

  getLatestOrderWithLatestStep(orders: Order[]): Order {
    let latestOrder: Order = null;
    let latestOrderStepIndex = 99;
    for (const order of orders) {
      if (order.isOrderExpired()) {
        continue;
      }
      const represents = order.getRepresents();
      const reversedOrderedSteps = getNewOrderStepLabelOrdered(
        this.isREAgentUser,
        represents,
      ).reverse();

      const orderStepIndex = reversedOrderedSteps.indexOf(this.getCurrentNewOrderStep(order));
      if (orderStepIndex < latestOrderStepIndex) {
        latestOrder = order;
        latestOrderStepIndex = orderStepIndex;
        continue;
      }
      if (
        orderStepIndex === latestOrderStepIndex &&
        isAfter(latestOrder.getUpdateDate(), order.getUpdateDate())
      ) {
        latestOrder = order;
        latestOrderStepIndex = orderStepIndex;
      }
    }
    return latestOrder;
  }

  isBuyerStepComplete(order: Order): boolean {
    const represents = order.getRepresents();
    const hasBuyerInfo = order.hasBuyerInfo();
    const hasClosingDate = !!order.getClosingDate();
    const hasClosingInfo = order.hasClosingInfo();
    const hasQuoteID = !!order.getQuoteID();

    switch (represents.toLocaleLowerCase()) {
      case 'buyer':
        if (!hasBuyerInfo) {
          return false;
        }
        break;
      case 'both':
        if (!hasBuyerInfo) {
          return false;
        }
        break;
      case 'seller':
        if (!hasBuyerInfo && !hasClosingInfo && !hasQuoteID) {
          return false;
        }
        // user added date at a later point
        if (!hasBuyerInfo && hasClosingDate) {
          return false;
        }
        if (!hasBuyerInfo && !hasQuoteID) {
          return false;
        }
        break;
      default:
        console.warn('represents indeterminate, cannot be used to determine state: ', represents);
        return false;
    }

    return true;
  }

  isSellerStepComplete(order: Order): boolean {
    const represents = order.getRepresents();
    const hasSellerInfo = order.hasSellerInfo();
    const hasClosingInfo = order.hasClosingInfo();
    const hasQuoteID = !!order.getQuoteID();

    switch (represents.toLocaleLowerCase()) {
      case 'seller':
        if (!hasSellerInfo) {
          return false;
        }
        break;
      case 'both':
        if (!hasSellerInfo) {
          return false;
        }
        break;
      case 'buyer':
        if (!hasSellerInfo && !hasClosingInfo && !hasQuoteID) {
          return false;
        }
        break;
      default:
        console.warn('represents indeterminate, cannot be used to determine state: ', represents);
        return false;
    }

    return true;
  }

  isClosingStepComplete(order: Order): boolean {
    const hasSellerInfo = order.hasSellerInfo();
    const hasBuyerInfo = order.hasBuyerInfo();
    const hasClosingDate = !!order.getClosingDate();

    if (!hasSellerInfo && !hasBuyerInfo) {
      return false;
    }
    return !(hasBuyerInfo && !hasClosingDate);
  }

  isPlanStepComplete(order: Order): boolean {
    return !!order.getQuoteID();
  }
}
