import React, { useEffect, useState } from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import classNames from 'classnames';
import CustomerInputFields from '@components/input/CustomerInputFields';
import { CustomerData } from '@apis/models';

// TODO: ARE-9309. cleanup efforts needed for this component and such up its chain:
//  - there are certain props that were left in and not used, causing confusion
//  - there are deprecated tags mentioned that shouldn't be used anymore
//  - contractStatus prop should be controlled upwards in the chain, as it isn't the responsibility of this component to know about contract
//    - i.e. payment modal would never allow submission.  wlk verification would always allow submission
//  - prop spreads made it more difficult to trace down what props are used until we typed the lower components correctly
//  - determine if the accordion animation is actually necessary to do still or not
//  - various interfaces are redundant or repeated that should be cleared up

export type SaveCustomerInfoHandler = (
  buyer: CustomerData,
  seller: CustomerData,
) => Promise<boolean>;

export interface CustomerInfoInputProps {
  initialBuyer: CustomerData;
  initialSeller: CustomerData;
  isDisabled: boolean;
  isMobile: boolean;
  onSaveAndCancel: any;
  onSave: SaveCustomerInfoHandler;
  /** @deprecated should have its logic be changed to be 'hideSubmit' instead to keep this component contract context unaware */
  contractStatus: string;
  canEditWLKContracts?: boolean;
}

export enum CustomerFields {
  buyer = 'buyer',
  seller = 'seller',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
}

const CustomerInfoInput: React.FC<CustomerInfoInputProps> = (props) => {
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [buyerTemp, setBuyerTemp] = useState(props.initialBuyer);
  const [sellerTemp, setSellerTemp] = useState(props.initialSeller);
  const [doBuyerErrorsExist, setDoBuyerErrorsExist] = useState<boolean>(false);
  const [doSellerErrorsExist, setDoSellerErrorsExist] = useState<boolean>(false);

  useEffect(() => {
    setBuyerTemp(props.initialBuyer);
    setSellerTemp(props.initialSeller);
  }, []);

  async function handleSubmit(e) {
    e.stopPropagation();
    e.cancelBubble = true;
    return handleSave(buyerTemp, sellerTemp);
  }

  function handleCancel(e) {
    e.stopPropagation();
    e.cancelBubble = true;
    props.onSaveAndCancel();
  }

  async function handleSave(newBuyer, newSeller) {
    setIsSaving(true);
    const success = await props.onSave(newBuyer, newSeller);
    if (success) {
      props.onSaveAndCancel();
    }
    setIsSaving(false);
  }

  function handleInputFieldChangeBuyer(newBuyer: CustomerData) {
    setCanSave(true);
    setBuyerTemp(newBuyer);
  }

  function handleInputFieldChangeSeller(newSeller: CustomerData) {
    setCanSave(true);
    setSellerTemp(newSeller);
  }

  return (
    <div className="flex flex-col w-full">
      <div className="">
        <CustomerInputFields
          customerType={CustomerFields.buyer}
          customer={buyerTemp}
          onInputFieldChanged={handleInputFieldChangeBuyer}
          onBlurAllInputs={setDoBuyerErrorsExist}
          isDisabled={props.isDisabled || !props.canEditWLKContracts}
          inputIdPrefix="wlk_contract__buyer"
        />
      </div>
      <div className="mt-4">
        <CustomerInputFields
          customerType={CustomerFields.seller}
          customer={sellerTemp}
          onInputFieldChanged={handleInputFieldChangeSeller}
          onBlurAllInputs={setDoSellerErrorsExist}
          isDisabled={props.isDisabled || !props.canEditWLKContracts}
          inputIdPrefix="wlk_contract__seller"
        />
      </div>

      {!props.isDisabled && (
        <div
          className={classNames([
            'mt-4',
            ['Submitted', 'Closed', 'Pending', 'Completed'].includes(props.contractStatus)
              ? 'hidden'
              : 'flex sm:justify-start sm:items-center sm:flex-row-reverse xs-max:flex-col',
          ])}
        >
          {props.canEditWLKContracts && (
            <Button
              id="wlk_contract__save"
              className="xs-max:w-full sm:ml-4"
              label="Save"
              variant="filled"
              color="interactive"
              size="medium"
              width={props.isMobile ? 'full' : 'auto'}
              labelAlign="center"
              disabled={!canSave || isSaving || doBuyerErrorsExist || doSellerErrorsExist}
              onClick={handleSubmit}
            />
          )}
          <Button
            id="wlk_contract__cancel"
            className="xs-max:mt-2"
            label="Cancel"
            variant="ghost"
            color="interactive"
            size="medium"
            width={props.isMobile ? 'full' : 'auto'}
            labelAlign="center"
            onClick={handleCancel}
          />
        </div>
      )}
    </div>
  );
};

CustomerInfoInput.defaultProps = {};

export default CustomerInfoInput;
