import React, { useEffect, useState } from 'react';
import ModalChoice from '@components/modal/ModalChoice';
import { Radio, Text } from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import { useNavigation } from 'react-navi';
import ContractApi from '@apis/contract.api';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs from '@app/locales/en';
import { UpsertOrderAgent } from '@apis/models';
import {
  ACTION_VIEW_EXISTING_ORDER_DETAILS,
  ACTION_ADD_AS_COOPERATING_AGENT,
  ACTION_CONTACT_SUPPORT,
  ROLE_TYPE_REAL_ESTATE_ADMIN,
  ROLE_TYPE_REAL_ESTATE_AGENT,
  ROLE_TYPE_REAL_ESTATE_BROKER,
} from '@constants/newOrder-constants';
import { OrderPreFilter } from '@pages/account/Orders';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__ADD_AS_COOPERATING_AGENT_ORDER } from '@constants/ga-events.constants';

interface ModalContractExistsOnPropertyProps {
  id: string;
  contractId: string;
  existingContractSalesChannel: string;
  existingContractAddress: string;
  existingContractInitiatingAgentName: string;
  existingContractInitiatingAgentOffice: string;
  existingOrderInitiatingAgentMatch: boolean;
  initiatingAgentRepresentBoth: boolean;
  pSelectedREAgentId: string;
  pSelectedREAgentName: string;
  pSelectedREAgentOfficeId: string;
  exisitingOrderInitiatingOfficeOnUserProfile: boolean;
  existingOrderClosingAgentExists: boolean;
  existingOrderCooperatingAgentExists: boolean;
  existingOrderInitiatingAgentExists: boolean;
  user: any;
  heading: string;
  isActive: boolean;
  onClose: any;
  contractStatus?: string;
}

const ModalContractExistsOnProperty: React.FC<ModalContractExistsOnPropertyProps> = (props) => {
  const [loggedInUserREAdmin, setLoggedInUserREAdmin] = useState(false);

  const [checkedViewExistingOrderDetails, setCheckedViewExistingOrderDetails] = useState(false);
  const [checkedCooperatingAgent, setCheckedCooperatingAgent] = useState(true);

  const [checkedContactSupport, setCheckedContactSupport] = useState(false);
  const [currentSelection, setCurrentSelection] = useState('');
  const [selectedREAgentId, setSelectedREAgentId] = useState('');
  const [selectedREAgentName, setSelectedREAgentName] = useState('');
  const [selectedREAgentOfficeId, setSelectedREAgentOfficeId] = useState('');
  const [existingContractAddr, setExistingContractAddr] = useState('');
  const [addCooperatingAgentLabel, setAddCooperatingAgentLabel] = useState(
    'Add me as the Cooperating Agent on this order',
  );
  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();

  const onViewExistingOrderDetailsChecked = (checked: boolean) => {
    setCheckedViewExistingOrderDetails(checked);
    setCheckedCooperatingAgent(false);
    setCheckedContactSupport(false);
  };

  const onCooperatingAgentChecked = (checked: boolean) => {
    setCheckedCooperatingAgent(checked);
    setCheckedViewExistingOrderDetails(false);
    setCheckedContactSupport(false);
  };

  const onContactSupportChecked = (checked: boolean) => {
    setCheckedContactSupport(checked);
    setCheckedViewExistingOrderDetails(false);
    setCheckedCooperatingAgent(false);
  };

  useEffect(() => {
    // Determine user role type is RE ADMIN.
    if (props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_ADMIN) {
      setLoggedInUserREAdmin(true);
    }

    setSelectedREAgentId(props.pSelectedREAgentId);
    setSelectedREAgentName(props.pSelectedREAgentName);
    setSelectedREAgentOfficeId(props.pSelectedREAgentOfficeId);
    setExistingContractAddr(props.existingContractAddress);

    if (
      (props.existingContractSalesChannel === 'RE' &&
        props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_ADMIN &&
        props.exisitingOrderInitiatingOfficeOnUserProfile) ||
      props.user?.roleIDType !== ROLE_TYPE_REAL_ESTATE_AGENT ||
      props.user?.roleIDType !== ROLE_TYPE_REAL_ESTATE_BROKER
    ) {
      onViewExistingOrderDetailsChecked(true);
      setCurrentSelection(ACTION_VIEW_EXISTING_ORDER_DETAILS);
    }

    if (
      props.existingContractSalesChannel === 'RE' &&
      ((!props.existingOrderCooperatingAgentExists &&
        props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_ADMIN &&
        !props.exisitingOrderInitiatingOfficeOnUserProfile) ||
        props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_AGENT ||
        props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_BROKER)
    ) {
      onCooperatingAgentChecked(true);
      setCurrentSelection(ACTION_ADD_AS_COOPERATING_AGENT);
    }

    if (
      (props.existingOrderCooperatingAgentExists || props.initiatingAgentRepresentBoth) &&
      (props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_AGENT || !checkedViewExistingOrderDetails)
    ) {
      onContactSupportChecked(true);
      setCurrentSelection(ACTION_CONTACT_SUPPORT);
    }

    if (props.existingContractSalesChannel !== 'RE') {
      onContactSupportChecked(true);
      setCurrentSelection(ACTION_CONTACT_SUPPORT);
    }

    if (props.user.roleIDType !== ROLE_TYPE_REAL_ESTATE_AGENT) {
      setAddCooperatingAgentLabel(
        `Add ${props.pSelectedREAgentName} as the Cooperating Agent on this order`,
      );
    }
  }, [
    props.existingOrderClosingAgentExists,
    props.existingOrderCooperatingAgentExists,
    props.exisitingOrderInitiatingOfficeOnUserProfile,
    props.user,
    props.pSelectedREAgentOfficeId,
    props.pSelectedREAgentId,
    props.pSelectedREAgentName,
    props.existingContractAddress,
    props.existingContractSalesChannel,
    props.initiatingAgentRepresentBoth,
  ]);

  const selectionChangedHandler = (e) => {
    if (e.target.value.length > 0) {
      setCurrentSelection(e.target.value);
    }
    switch (e.target.value) {
      case ACTION_VIEW_EXISTING_ORDER_DETAILS:
        onViewExistingOrderDetailsChecked(e.target.checked);
        break;
      case ACTION_ADD_AS_COOPERATING_AGENT:
        onCooperatingAgentChecked(e.target.checked);
        break;
      case ACTION_CONTACT_SUPPORT:
        onContactSupportChecked(e.target.checked);
        break;
      default:
        console.log('selectionChangedHandler default case!');
        break;
    }
  };

  const handleCooperatingAgentUpdate = async (
    contractID: string,
    cooperatingAgentInfo: UpsertOrderAgent,
  ): Promise<boolean> => {
    const success = await ContractApi.updateCooperatingAgentInfo(contractID, {
      agentId: cooperatingAgentInfo.agentId,
      officeId: cooperatingAgentInfo.officeId,
    }).catch(() => {
      return false;
    });

    if (success) {
      addSuccessToQueue(msgs.UPDATE_AGENT_SUCCESS);
      // Track in GA the successful association of cooperating agent to an existing order.
      fireGAEvent(ORDER__ADD_AS_COOPERATING_AGENT_ORDER(contractID));
    } else {
      addErrorToQueue(msgs.UPDATE_AGENT_FAILURE);
    }
    return success;
  };

  const navigation = useNavigation();
  const continueOrViewExistingContractHandler = () => {
    if (!props.existingOrderInitiatingAgentMatch && currentSelection.length > 0) {
      if (currentSelection !== ACTION_CONTACT_SUPPORT) {
        if (currentSelection === ACTION_ADD_AS_COOPERATING_AGENT) {
          // add agent as a cooperating agent on contract according to selection first.
          const cooperatingAgentAdded = handleCooperatingAgentUpdate(props.contractId, {
            agentId: selectedREAgentId,
            officeId: selectedREAgentOfficeId,
          });
          console.log(
            `for contract: ${props.contractId} add coopertating agent outcome:${cooperatingAgentAdded}. selected agentId:${selectedREAgentId} selected agent officeId:${selectedREAgentOfficeId}`,
          );
        }

        navigation.navigate(Path.MyOrders, {
          body: {
            openOrderDrawerContractId: props.contractId,
          } as OrderPreFilter,
          replace: true,
        });
      } else {
        // Navigate to 'ContactUs' page and pre populate issue category and text of the email body
        navigation.navigate(Path.ContactUs, {
          body: {
            contractId: props.contractId,
            topic: 'Order Management',
            emailText: `I want to place an order for ${props.existingContractAddress} but an ${props.contractStatus === 'open' ? `open order` : `active contract`} #${props.contractId} already exists for this address. I need assistance with getting more details on this contract.`,
          },
          replace: true,
        });
      }
    } else {
      navigation.navigate(Path.MyOrders, {
        body: {
          openOrderDrawerContractId: props.contractId,
        } as OrderPreFilter,
        replace: true,
      });
    }
  };

  return (
    props.isActive && (
      <ModalChoice
        id={props.id}
        heading={props.heading}
        isActive={props.isActive}
        onClose={() => props.onClose(false)}
        onConfirm={continueOrViewExistingContractHandler}
        yesText={props.existingOrderInitiatingAgentMatch ? 'View Existing Listing' : 'Continue'}
        noText="Cancel"
      >
        <Text id="modal-contract-exisits-on-property__existingContractAddress" variant="heading-06">
          Address: {existingContractAddr}
        </Text>
        {props.existingContractInitiatingAgentName?.length > 0 &&
          props.existingContractInitiatingAgentOffice?.length > 0 && (
            <div>
              <Text
                id="modal-contract-exisits-on-property__existingContractInitiatingAgentName"
                variant="heading-06"
              >
                Agent: {props.existingContractInitiatingAgentName}
              </Text>
              <Text
                id="modal-contract-exisits-on-property__existingContractInitiatingOffice"
                variant="heading-06"
              >
                Office: {props.existingContractInitiatingAgentOffice}
              </Text>
            </div>
          )}

        {props.existingContractSalesChannel === 'RE' &&
          !props.existingOrderInitiatingAgentMatch && (
            <div className="w-full px-4 py-2 -mb-1">
              {((loggedInUserREAdmin && props.exisitingOrderInitiatingOfficeOnUserProfile) ||
                (props.user?.roleIDType !== ROLE_TYPE_REAL_ESTATE_AGENT &&
                  props.user?.roleIDType !== ROLE_TYPE_REAL_ESTATE_ADMIN &&
                  props.user?.roleIDType !== ROLE_TYPE_REAL_ESTATE_BROKER)) && (
                <div>
                  <Radio
                    label="View existing order details"
                    name="viewExistingOrder"
                    id="modal-contract-exisits-on-property__wb_pdViewExistingOrder"
                    value={ACTION_VIEW_EXISTING_ORDER_DETAILS}
                    checked={checkedViewExistingOrderDetails}
                    onChange={selectionChangedHandler}
                    required={false}
                    disabled={false}
                    hideLabel={false}
                    labelPlacement="right"
                    width="auto"
                    color="interactive"
                  />
                </div>
              )}

              {props.existingContractSalesChannel === 'RE' &&
                !props.initiatingAgentRepresentBoth &&
                !props.existingOrderCooperatingAgentExists &&
                ((props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_ADMIN &&
                  !props.exisitingOrderInitiatingOfficeOnUserProfile) ||
                  props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_AGENT ||
                  props.user?.roleIDType === ROLE_TYPE_REAL_ESTATE_BROKER) && (
                  <div>
                    <Radio
                      label={addCooperatingAgentLabel}
                      name="cooperatingAgent"
                      id="modal-contract-exisits-on-property__wb_pdCooperatingAgent"
                      value={ACTION_ADD_AS_COOPERATING_AGENT}
                      checked={checkedCooperatingAgent}
                      onChange={selectionChangedHandler}
                      required={false}
                      disabled={false}
                      hideLabel={false}
                      labelPlacement="right"
                      width="auto"
                      color="interactive"
                    />
                  </div>
                )}
              <div>
                <Radio
                  label="Contact Support"
                  name="contact-support"
                  id="modal-contract-exisits-on-property__wb_pdContactSuport"
                  value={ACTION_CONTACT_SUPPORT}
                  checked={checkedContactSupport}
                  onChange={selectionChangedHandler}
                  required={false}
                  disabled={false}
                  hideLabel={false}
                  labelPlacement="right"
                  width="auto"
                  color="interactive"
                />
              </div>
              <></>
            </div>
          )}
        {props.existingContractSalesChannel !== 'RE' && (
          <div className="w-full px-4 py-2 -mb-1">
            <div>
              <Radio
                label="Contact Support"
                name="contact-support"
                id="modal-contract-exisits-on-property__wb_pdContactSuport"
                value={ACTION_CONTACT_SUPPORT}
                checked={checkedContactSupport}
                onChange={selectionChangedHandler}
                required={false}
                disabled={false}
                hideLabel={false}
                labelPlacement="right"
                width="auto"
                color="interactive"
              />
            </div>
          </div>
        )}
      </ModalChoice>
    )
  );
};

export default ModalContractExistsOnProperty;
