import { Panel, Text } from '@ftdr/blueprint-components-react';
import { addressObjectToString } from '@services/helpers';
import React, { useRef } from 'react';
import { breakpoints, isViewInRange } from '@utils';
import { BuyerMissingPhoneResponse } from '@components/drawer/subcomponents/OrdersMissingBuyerPhone';

interface internalProps {
  idPrefix: string;
  onClickContract?: any;
  actions?: any[];
  leftActions?: any[];
  missingBuyerPhoneContract?: BuyerMissingPhoneResponse;
}

const PanelContractSummary = (props: internalProps) => {
  const isMobile = isViewInRange(0, breakpoints.md);
  const container = useRef<HTMLDivElement | null>(null);

  return (
    <Panel
      border="1"
      borderColor="gray-300"
      rounded="sm"
      padding="none"
      shadow={true}
      className="rounded bg-white shadow-1 border-2 mb-4 w-full"
    >
      <div
        ref={container}
        className={`flex justify-start items-center w-full py-3 pl-4 pr-4 md:pl-6 text-left text-sm md:text-base bg-transparent ${props.onClickContract && 'cursor-pointer'}`}
        id={props.idPrefix}
      >
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full pl-3">
            <div className="flex md:flex-row md-max:flex-col ">
              {isMobile &&
                props.leftActions?.length &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              {!isMobile &&
                props.leftActions?.length &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              <div
                className="flex md:flex-row md-max:flex-col "
                onClick={() => props.onClickContract(props.missingBuyerPhoneContract)}
              >
                <div className="min-w-max">
                  <div className="float-left flex flex-row">
                    <div className="flex flex-col w-full ">
                      <Text variant="label" color="gray" className="mr-6 nowrap DO-NOT-HIGHLIGHT">
                        ORDER NUMBER
                      </Text>
                      <Text variant="caption" color="black">
                        {props.missingBuyerPhoneContract.id}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row float-right">
                    {isMobile &&
                      props.actions?.length &&
                      props.actions.map((action) => {
                        return (
                          <div key={action} className="ml-4">
                            {action}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="flex flex-col w-full pr-2">
                  <Text variant="label" color="gray" className="pr-48 nowrap DO-NOT-HIGHLIGHT">
                    PROPERTY ADDRESS
                  </Text>
                  <Text variant="caption" color="black" className="overflow-visible">
                    {addressObjectToString(props.missingBuyerPhoneContract.propertyAddress)}
                  </Text>
                </div>

                <div className="flex flex-col w-full pr-4 nowrap">
                  <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                    BUYER FIRST NAME
                  </Text>
                  <Text variant="caption" color="black">
                    {props.missingBuyerPhoneContract.buyer.firstName}
                  </Text>
                </div>

                <div className="flex flex-col w-full nowrap">
                  <Text variant="label" color="gray" className="DO-NOT-HIGHLIGHT">
                    BUYER LAST NAME
                  </Text>
                  <Text variant="caption" color="black">
                    {props.missingBuyerPhoneContract.buyer.lastName}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {!isMobile &&
            props.actions?.length &&
            props.actions.map((action) => {
              return (
                <div key={action} className="flex flex-row ml-4 h-fit md-max:mr-4">
                  {action}
                </div>
              );
            })}
        </div>
      </div>
    </Panel>
  );
};

export default PanelContractSummary;
