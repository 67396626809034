import React, { useEffect, useState } from 'react';
import { classNames } from '@utils';
import CardNewOrder from '@components/card/CardNewOrder';
import validate, { isZipValid } from '@services/validation/ValidationRules';
import useForm from '@helpers/UseForm';
import {
  INVALID_ZIP_CODE,
  STREET_ADDRESS_REQUIRED,
  ZIP_REQUIRED,
} from '@constants/formField-constants';
import AddressApi from '@apis/address.api';
import ContractApi from '@apis/contract.api';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { Input, MaskedInput, Select, Text } from '@ftdr/blueprint-components-react';
import { ORDER_CARD_TITLE, ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { usStatesForBDSSelect } from '@constants/us-states';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import ModalServiceUnavailable from '@components/modal/ModalServiceUnavailable';
import ModalConfirmAddressEntered from '@components/modal/ModalConfirmAddressEntered';
import { ProductApiSuppressErrors } from '@apis/product.api';
import msgs from '@app/locales/en';
import { getFormInputErrorId, getFormSelectErrorId } from '@storybook/addon-links';
import AddressSuggestionInput from '@components/input/AddressSuggestionInput';
import { deleteUndefinedValues, isAppAddressMatch } from '@helpers/utils';
import ModalContractExistsOnProperty from '@components/modal/ModalContractExistsOnProperty';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';
import { isREAgent } from '@helpers/profile.utils';
import { NewOrderStepDeterminer } from '@helpers/new-order-step.order';
import ModalIncompleteOrderFound from '@components/modal/ModalIncompleteOrderFound';
import { OrdersApiSuppressErrors } from '@apis/orders.api';
import addDays from 'date-fns/addDays';
import { OrderSourceApplication, OrderWorkflow } from '@constants/app.constants';
import { AbandonedOrderStatuses } from '@apis/models/orders.api.model';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  CONTINUE_CREATING_NEW_ORDER,
  CONTINUE_PREVIOUS_ORDER,
} from '@constants/ga-events.constants';

export const getErrorMessageFromAddressDetailsCall = (error) => {
  if (error && error.code) {
    const { code = '', message = '' } = error;
    if (['20410'].includes(code)) {
      return message;
    }
  }
  return '';
};

const CardNewOrderPropertyAddress = ({ isEditing, setIsEditing, ...props }) => {
  const { showSpinner } = useGlobalOverlaySpinner();
  const { addWarningToQueue, addErrorToQueue } = useGlobalAlert();
  let defaultFormValues = {
    propertyAddressChanged: false,
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
  };
  if (props.values) {
    defaultFormValues = { ...defaultFormValues, ...props.values };
  }
  const { values, errors, setErrors, handleSubmit, forceTouchAll, handleBlur, setValues } = useForm(
    validate,
    defaultFormValues,
  );

  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || false);
  const [showServiceUnavailableModal, setShowServiceUnavailableModal] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(true);

  const [showValidateAddressModal, setShowValidateAddressModal] = useState(false);
  const [addressMatch, setAddressMatch] = useState(null);
  const [doesZipExist, setDoesZipExist] = useState(true);
  const [cityOptions, setCityOptions] = useState([]);
  const [citySelected, setCitySelected] = useState(cityOptions[0] || { value: '' });
  const [stateSelected, setStateSelected] = useState(
    values.state ? usStatesForBDSSelect.find((o) => o.value === values.state) : null,
  );
  const [isStateDisabled, setIsStateDisabled] = useState(false);

  const [showContractExistsOnPropertyModal, setShowContractExistsOnPropertyModal] = useState(false);
  const [existingContract, setExistingContract] = useState(null);
  const [existingContractAddress, setExistingContractAddress] = useState('');
  const [existingOrderInitiatingAgentMatch, setExistingOrderInitiatingAgentMatch] = useState(false);
  const [
    exisitingOrderInitiatingOfficeOnUserProfile,
    setExisitingOrderInitiatingOfficeOnUserProfile,
  ] = useState(false);
  const [existingOrderCooperatingAgentExists, setExistingOrderCooperatingAgentExists] =
    useState(false);
  const [existingOrderClosingAgentExists, setExistingOrderClosingAgentExists] = useState(false);
  const [existingContractModalHeader, setExistingContractModalHeader] = useState('');
  const [existingContractStatus, setExistingContractStatus] = useState('');

  const [existingContractSalesChannel, setExistingContractSalesChannel] = useState('');
  const [existingOrderInitiatingAgentExists, setExistingOrderInitiatingAgentExists] =
    useState(false);
  const [existingContractInitiatingAgentName, setExistingContractInitiatingAgentName] =
    useState('');
  const [existingContractInitiatingAgentOffice, setExistingContractInitiatingAgentOffice] =
    useState('');
  const [initiatingAgentRepresentBoth, setInitiatingAgentRepresentBoth] = useState(false);

  const [showAbandonedOrderModal, setShowAbandonedOrderModal] = useState(false);
  const [existingAbandonedOrder, setExistingAbandonedOrder] = useState(null);
  const [abandonedOrderStep, setAbandonedOrderStep] = useState('');
  const [abandonedOrderWorkflow, setAbandonedOrderWorkflow] = useState([]);
  const [abandonedOrderInitAgent, setAbandonedOrderInitAgent] = useState('');
  const [abandonedOrderInitOffice, setAbandonedOrderInitOffice] = useState('');

  useEffect(() => {
    if (props.values) {
      setValues(props.values);
    }
  }, [isEditing, props.values]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setIsCollapsed(false);
      setIsEditing(true);
    }
  }, [errors]);

  useEffect(() => {
    if (values?.state !== stateSelected?.value || values?.city !== citySelected?.value) {
      setValues({ ...values, state: stateSelected?.value, city: citySelected?.value });
    }
  }, [stateSelected, citySelected]);

  /** Check validity of the address before saving. */

  const processErrorFromAddressDetailsCall = (errors) => {
    const message = getErrorMessageFromAddressDetailsCall(errors);
    if (message.length > 0) {
      setErrors({
        ...errors,
        streetAddress: message,
      });
    }
  };

  /** If submitting via service address, perform a duplicate contract check.
   * Otherwise, continues with submission.
   *  @param serviceAddress - the address that is suggested to the user, different from current address. If null, uses the address sent by user
   *  @param shouldCheckAddressForExistingContract - if true, then would check if there is a contract for the suggested address.
   *  */
  const setAddressAndCheckExistingContractAndSubmit = async (
    serviceAddress = undefined,
    shouldCheckAddressForExistingContract = true,
  ) => {
    setShowValidateAddressModal(false);
    // use the provided service address and replcae the values
    if (serviceAddress) {
      const newAddress = {
        ...values,
        ...serviceAddress,
      };
      setValues(newAddress);
      props.setValues({ propertyAddr: newAddress });

      // Pass in address as state hasn't updated properly
      if (shouldCheckAddressForExistingContract) {
        const hasContract = await checkDuplicateContractSameProperty(newAddress);
        if (hasContract) {
          return;
        }
      }
      if (props.checkAbandonedOrders && IsFeatureEnabled(Features.CheckAbandonedOrders)) {
        const hasOrder = await checkExistingAbandonedOrder(newAddress);
        if (hasOrder) {
          return;
        }
      }

      submitAndCheckChangedAddress(newAddress);
    } else {
      const unverifiedUserAddress = await finalizeUserEnteredAddress();
      if (unverifiedUserAddress) {
        // already checked contract for this address. would run this function again but for a different address, which should be valid this time.
        await setAddressAndCheckExistingContractAndSubmit(unverifiedUserAddress, false);
      }
      return;
    }
    setIsCollapsed(!isCollapsed);
    setIsEditing(false);
  };

  /** checks for existing abandoned orders given some criteria.
   *  returns a promise of what to do.  True if has existing order. Otherwise returns false. */
  const checkExistingAbandonedOrder = async (address) => {
    let agentID;
    let officeID;
    let agentName;
    let officeName;
    if (isREAgent(props.user)) {
      agentID = props.user.roleID;
      officeID = props.user.offices?.[0].id;
      agentName = `${props.user.firstName} ${props.user.lastName}`;
      officeName = props.user.offices?.[0].name;
    } else {
      agentID = props.agentInfo?.agentInfo?.AgentId;
      officeID = props.agentInfo?.agentInfo?.office?.id;
      agentName = props.agentInfo?.agentInfo?.initiatingAgent;
      officeName = props.agentInfo?.agentInfo?.initiatingOffice;
    }
    const propertyAddressID = address.meta?.addressId;

    // if 1 of the fields is blank, then we exit out early
    if ([agentID, officeID, propertyAddressID].some((v) => !v)) {
      console.warn(
        'some data was not provided, order check was skipped',
        agentID,
        officeID,
        propertyAddressID,
      );
      return Promise.resolve(false);
    }

    showSpinner(true);
    return OrdersApiSuppressErrors.searchAbandonedOrders({
      criteria: {
        propertyAddressID,
        initiatingOfficeAgent: { officeID, agentID },
        sourceApplication: OrderSourceApplication.REPortal,
        sourceApplicationFlow: OrderWorkflow.NewOrder,
        createdDate: {
          start: addDays(new Date(), -30),
          end: new Date(),
        },
        statuses: AbandonedOrderStatuses,
      },
      meta: {
        page: 1,
        limit: 10,
        sortBy: 'createdDate',
        asc: false,
      },
    })
      .then((res) => {
        if (res?.results.length > 0) {
          const stepDeterminer = new NewOrderStepDeterminer(isREAgent(props.user));
          const latestOrder = stepDeterminer.getLatestOrderWithLatestStep(res.results);
          if (!latestOrder) {
            return false;
          }
          setShowAbandonedOrderModal(true);
          setExistingAbandonedOrder(latestOrder);
          setAbandonedOrderStep(stepDeterminer.getCurrentNewOrderStep(latestOrder));
          setAbandonedOrderWorkflow(stepDeterminer.getCurrentNewOrderWorkflow(latestOrder));
          setAbandonedOrderInitAgent(agentName);
          setAbandonedOrderInitOffice(officeName);
          return true;
        }
      })
      .catch((err) => {
        console.error(
          'failed to determine abandoned orders. proceeding as if no orders were found',
          err,
        );
        return false;
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  /** if user chooses to use their unverified address, generate a new unverified address uuid and return the address data */
  const finalizeUserEnteredAddress = async () => {
    return AddressApi.createUnverifiedAddress({
      address1: values.streetAddress,
      address2: values.unit,
      city: values.city,
      state: values.state,
      zip: values.zip,
    }).catch(() => {
      addErrorToQueue(msgs.NEW_ORDER__CREATE_UNVERIFIED_ADDRESS_FAILED);
      return null;
    });
  };

  const handleSave = async (event) => {
    handleSubmit(event); // errors are invalidated and reapplied with forceTouchAll

    const { errors: formErrors } = forceTouchAll();
    setIsCollapsed(false);
    setIsEditing(true);

    // specific validation logic for this card (additional zip code check)
    if (!doesZipExist || !values.zip) {
      formErrors.zip = ZIP_REQUIRED;
    }
    if (!values.streetAddress) {
      formErrors.streetAddress = STREET_ADDRESS_REQUIRED;
    } else if (values?.streetAddress?.length > 70) {
      formErrors.streetAddress =
        'Property address exceeding maximum allowed length. Please update the property street number and name,';
    }

    setErrors(formErrors);

    if (!Object.keys(formErrors).length) {
      showSpinner(true);
      // Check if there is an existing contract. If not, then check valid address.
      const hasContracts = await checkDuplicateContractSameProperty();
      if (hasContracts) {
        showSpinner(false);
        return;
      }

      await confirmAddressOrSubmit();
      showSpinner(false); // stop spinner after confirmation/submission
    }
  };

  /** verify if the address was changed and handle the 'propertyAddressChanged' flag logic */
  const submitAndCheckChangedAddress = (newValues = values) => {
    if (props.values) {
      const savedProperty = props.values;
      if (
        savedProperty.zip !== newValues.zip ||
        savedProperty.streetAddress !== newValues.streetAddress ||
        savedProperty.unit !== newValues.unit ||
        savedProperty.city !== newValues.city ||
        savedProperty.state !== newValues.state
      ) {
        console.log('property Address changed!');
        setValues({ ...values, ...newValues, propertyAddressChanged: true });
        props.handleSubmit({ ...values, ...newValues, propertyAddressChanged: true });
      } else {
        console.log('property Address should changed!');
        setValues({ ...values, ...newValues, propertyAddressChanged: false });
        props.handleSubmit({ ...values, ...newValues, propertyAddressChanged: false });
      }
    } else {
      setValues({ ...values, ...newValues, propertyAddressChanged: false });
      props.handleSubmit({ ...values, ...newValues, propertyAddressChanged: false });
    }
  };

  /** when a valid zip is provided, pre-populate the city and state for the user */
  useEffect(() => {
    setValues({ ...values, state: '', city: '' });
    setIsStateDisabled(false);

    setDoesZipExist(true);
    if (isZipValid(values.zip)) {
      props.searchCityStateByZip(values.zip).then((res) => {
        if (res) {
          let selectedCityState = res.cityStates.find(
            (c) => c.city?.toUpperCase() === values.city?.toUpperCase(),
          );
          const options = res.cityStates?.map((cityState) => ({ value: cityState.city }));
          setCityOptions(options);
          if (!selectedCityState) {
            // defaulting to old code (earlier code was always defaulting to 1st element)
            [selectedCityState] = res.cityStates;
          }

          setStateSelected(
            usStatesForBDSSelect.find((o) => o.value === selectedCityState.stateAbbreviation) ||
              null,
          );
          setCitySelected({ value: selectedCityState.city });
          setIsStateDisabled(true);

          const updatedErrors = { ...errors };
          delete errors.state;
          delete errors.city;
          setErrors(updatedErrors);

          if (!props.skipAvailability || values.zip !== props.values.zip) {
            checkServiceAvailability(values.zip, selectedCityState.stateAbbreviation);
          }
        } else {
          setDoesZipExist(false);
        }
      });
    }
  }, [values.zip]);

  /** For a given zip and state, check to see if there are available products for the given property location.
   *  IF there is not, this will display a modal to the user that there are no plans available. */
  const checkServiceAvailability = (zip, state) => {
    if (zip && state) {
      ProductApiSuppressErrors.getProductAvailabilityByLocation({ zip, state })
        .then((res) => {
          if (res) {
            setShowServiceUnavailableModal(
              showServiceUnavailableModal || !res.plansAvailableInLocation,
            );
            setIsServiceAvailable(!!res.plansAvailableInLocation);
          }
        })
        .catch((err) => {
          console.warn(
            'unable to determine product availability in location. will be assumed to be fine and checked at plan step.',
            err,
          );
          addWarningToQueue(msgs.CHECK_ZIP_HAS_PLANS_FAILED);
        });
    }
  };

  const closeServiceUnavailableModal = () => setShowServiceUnavailableModal(false);
  const closeValidateAddressModal = () => setShowValidateAddressModal(false);
  const closeContractExistsOnPropertyModal = () => {
    setShowContractExistsOnPropertyModal(false);
    setExistingContract(null);
    setExistingContractAddress('');
    setExistingContractSalesChannel('');
    setExistingOrderInitiatingAgentExists(false);
    setExistingContractInitiatingAgentName('');
    setExistingContractInitiatingAgentOffice('');
    setInitiatingAgentRepresentBoth(false);
    setExistingOrderInitiatingAgentMatch(false);
    setExisitingOrderInitiatingOfficeOnUserProfile(false);
    setExistingOrderCooperatingAgentExists(false);
    setExistingOrderClosingAgentExists(false);
    setExistingContractModalHeader('');
  };

  const data = {
    values,
    setValues,
    handleSubmit: handleSave,
    setErrors,
  };

  /** For the address entered in the form, check if the address exists.
   * @return boolean - true if address is valid. Otherwise, false that there was an error or address confirmation needed */
  const confirmAddressOrSubmit = async () => {
    try {
      const address = {
        streetAddress: values.streetAddress,
        unit: values.unit,
        city: values.city,
        state: values.state,
        zip: values.zip,
      };
      const res = await AddressApi.getAddressDetail(address);
      if (res) {
        // If the addresses are same then no need to show modal/popup
        if (isAppAddressMatch(res, values)) {
          await setAddressAndCheckExistingContractAndSubmit(res, false);
          return true;
        }
        setAddressMatch({ ...res, verified: true });
        setShowValidateAddressModal(true);
        return false;
      }

      setAddressMatch(null);
      setShowValidateAddressModal(true);
      return false;
    } catch (err) {
      console.error(err);
      processErrorFromAddressDetailsCall(err);
      return false;
    }
  };

  /** Checks if there are existing contracts for the property address. Returns true if there are existing contracts */
  const checkDuplicateContractSameProperty = async (propertyInfo) => {
    let address = {};
    if (propertyInfo) {
      address = {
        streetAddress: propertyInfo.streetAddress,
        unit: propertyInfo.unit,
        city: propertyInfo.city,
        state: propertyInfo.state,
        zip: propertyInfo.zip,
      };
    } else {
      address = {
        streetAddress: values.streetAddress,
        unit: values.unit,
        city: values.city,
        state: values.state,
        zip: values.zip,
      };
    }
    // Check if there are existing contracts
    const hasExistingContracts = (contractID) => {
      return contractID && contractID?.length > 0;
    };

    // Check for active or listing contract
    const res = await ContractApi.searchContractByAddress(address);
    if (hasExistingContracts(res.contractID)) {
      // The follwing address was entered for which we found an existing contract
      let enteredPropertyAddr = '';
      if (address?.unit?.length > 0) {
        enteredPropertyAddr = `${address?.streetAddress?.trim()}, Unit# ${address?.unit}, ${address?.city}, ${address?.state} ${address?.zip}`;
      } else {
        enteredPropertyAddr = `${address?.streetAddress?.trim()}, ${address?.city}, ${address?.state} ${address?.zip}`;
      }

      if (res.salesChannel === 'RE') {
        setShowContractExistsOnPropertyModal(true);
        setExistingContract(res.contractID);
        setExistingContractSalesChannel(res.salesChannel);
        // Call the API to get contract details first to determine the initiating agent, co-operating agent, and title agent on this contract
        const contRes = await ContractApi.getContractDetails([String(res.contractID)], false, true);

        const existingPropertyAddr = `${contRes[0]?.detail?.property?.streetAddress}, ${contRes[0]?.detail?.property?.streetAddress2}${contRes[0]?.detail?.property?.streetAddress2?.length > 0 ? ', ' : ''}${contRes[0]?.detail?.property?.city}, ${contRes[0]?.detail?.property?.state} ${contRes[0]?.detail?.property?.zip}`;

        setExistingContractAddress(existingPropertyAddr);

        // set if initiating agent exists on existing contract
        if (contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID?.length > 0) {
          setExistingOrderInitiatingAgentExists(true);
        } else {
          setExistingOrderInitiatingAgentExists(false);
        }

        // set initiating agent name and office
        if (contRes[0]?.summary?.agentName?.length > 0) {
          setExistingContractInitiatingAgentName(contRes[0]?.summary?.agentName);
          setExistingContractInitiatingAgentOffice(contRes[0]?.summary?.officeName);
        }

        // NOTE: This (Initiating Agent represents BOTH buyer and seller) is for a future ticket
        // set if initiating agent represents both buyer and seller
        if (contRes[0]?.detail.initiatingOfficeAgent?.represents === 'BOTH') {
          // console.log(`CardNewOrderPropertyAddress initiating agent represents:${contRes[0]?.detail.initiatingOfficeAgent?.represents}`);
          setInitiatingAgentRepresentBoth(true);
        } else {
          setInitiatingAgentRepresentBoth(false);
        }

        // set if closing/title agent exists on existing contract
        if (contRes[0]?.detail.closingOfficeAgent?.agent?.realEstateAgentID?.length > 0) {
          setExistingOrderClosingAgentExists(true);
        } else {
          setExistingOrderClosingAgentExists(false);
        }

        // set if cooperating agent exists on existing contract
        if (contRes[0]?.detail.cooperatingOfficeAgent?.agent?.realEstateAgentID?.length > 0) {
          setExistingOrderCooperatingAgentExists(true);
        } else {
          setExistingOrderCooperatingAgentExists(false);
        }

        // set if existing contract's initiating office is on logged in user's profile.
        if (
          props.user?.offices?.find(
            (office) => office.id === contRes[0]?.detail?.initiatingOfficeAgent?.office?.id,
          )
        ) {
          setExisitingOrderInitiatingOfficeOnUserProfile(true);
        } else {
          setExisitingOrderInitiatingOfficeOnUserProfile(false);
        }

        // determine if the initiating agent on exisiting contract match with the logged in on selected agent
        if (
          contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID ===
            props.user.roleID ||
          contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID ===
            props.agentInfo?.agentInfo?.AgentId
        ) {
          setExistingOrderInitiatingAgentMatch(true);
        } else {
          setExistingOrderInitiatingAgentMatch(false);
        }

        // Now we have comman header no matter if the initiating office on existing contract is same or not.
        const modalHeader1 = `An ${contRes[0]?.detail?.contractStatus === 'L' ? 'open' : 'active'} order #${res.contractID} exists for this address`;
        setExistingContractModalHeader(modalHeader1);
        setExistingContractStatus(contRes[0]?.detail?.contractStatus === 'L' ? 'open' : 'active');
      } else {
        const modalHeader1 = `A contract #${res.contractID} exists for this address`;
        setExistingContractModalHeader(modalHeader1);

        setShowContractExistsOnPropertyModal(true);
        setExistingContract(res.contractID);
        setExistingContractSalesChannel(res.salesChannel);
        setExistingContractAddress(enteredPropertyAddr);
      }

      startOver();
      return true;
    }

    return false;
  };

  const startOver = () => {
    setValues({
      ...values,
      state: '',
      city: '',
      streetAddress: '',
      zip: '',
      unit: '',
      zipPlus4: '',
    });
    setCitySelected(null);
    setStateSelected(null);
    setIsStateDisabled(false);
  };

  const toggleHandler = () => {
    setIsCollapsed(!isCollapsed);
    setIsEditing(false);
  };

  const addressString = `
  ${values && values.streetAddress !== undefined ? `${values.streetAddress},` : ''}
  ${values && values.unit !== undefined && values.unit !== '' ? `${values.unit},` : ''} 
  ${values && values.city !== undefined ? values.city : ''},
  ${values && values.state !== undefined ? values.state : ''}
  ${values && values.zip !== undefined ? values.zip : ''}`;

  const onChangeZip = (e) => {
    onChangeInput(e);
    setIsServiceAvailable(true);
  };

  const onStateSelect = (item) => {
    handleBlur({
      target: {
        name: 'state',
        value: item.value,
      },
    });
  };

  const onChangeInput = (e) => {
    if (e) {
      const updatedValues = {
        ...values,
        [e.target.name]: e.target.value,
      };
      if (updatedValues.zipPlus4 && shouldClearZipPlus4FromInputChange(e.target.name)) {
        updatedValues.zipPlus4 = '';
      }
      setValues(updatedValues);
      setErrors({
        ...errors,
        [e.target.name]: '',
      });
    }
  };

  /** returns true if the zipPlus4 field needs to be cleared (i.e. the address changed) */
  const shouldClearZipPlus4FromInputChange = (inputFieldName) => {
    return ['streetAddress', 'state', 'zip', 'city', 'unit'].includes(inputFieldName);
  };

  const onSelectStreetAddressOptionTemp = (opt) => {
    if (opt) {
      setValues({
        ...values,
        ...deleteUndefinedValues(opt), // clear undefined values to avoid replacement
      });
    }
  };

  const onBlurStreetAddressNewTemp = (e) => {
    handleBlur(e);
  };

  const handleCitySelect = (opt) => {
    if (opt) {
      handleBlur({
        target: {
          name: 'city',
          value: opt.value,
        },
      });
    }
  };

  const handleCityBlur = (e) => {
    handleBlur(e);
  };

  const onClose = () => {
    setErrors({});
    setIsEditing(false);
  };

  const onContinueNewOrder = () => {
    fireGAEvent(CONTINUE_CREATING_NEW_ORDER);
    setShowAbandonedOrderModal(false);
    submitAndCheckChangedAddress();
    setIsCollapsed(!isCollapsed);
    setIsEditing(false);
  };

  const onContinuePreviousOrder = (orderID) => {
    fireGAEvent(CONTINUE_PREVIOUS_ORDER);
    setShowAbandonedOrderModal(false);
    props.continuePreviousOrderID(orderID);
  };

  return (
    <>
      <CardNewOrder
        title={ORDER_CARD_TITLE.PROPERTY_ADDRESS}
        isEditing={isEditing}
        toggleEditing={() => setIsEditing(!isEditing)}
        isCollapsed={isCollapsed}
        toggleCollapsed={toggleHandler}
        isSmall={props.isSmall}
        data={data}
        cancelModal={props.cancelModal}
        onClose={onClose}
        isSaveDisabled={!isServiceAvailable}
      >
        {!isEditing ? (
          <div
            className={classNames([
              'flex-1',
              props.isSmall && '-mx-4',
              isCollapsed && 'xs-max:hidden',
            ])}
          >
            <div className="px-4">
              <Text id="card-new-order-property_address__address-summary-text">
                {addressString}
              </Text>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap -mx-4">
            <div className="w-full sm:w-1/4 md:w-1/4 px-4 py-2">
              <MaskedInput
                formField={true}
                formFieldMessageId={getFormInputErrorId('wb_zipCodePropertyAddress')}
                id="wb_zipCodePropertyAddress"
                name="zip"
                inputMode="numeric"
                label={ORDER_FORM_FIELDS.PROPERTY_ZIP}
                required={true}
                value={values.zip || ''}
                error={doesZipExist === false && errors.zip == null ? INVALID_ZIP_CODE : errors.zip}
                onBlur={handleBlur}
                autoComplete="off"
                onChange={onChangeZip}
                maxLength={5}
                mask="00000"
              />
            </div>

            <div className="w-full sm-max:hidden md:w-3/4" />

            <div className="flex flex-wrap w-full sm:w-3/4 md:w-1/2">
              <div className="w-full sm:w-3/4 px-4 py-2 field-position">
                <AddressSuggestionInput
                  id="wb_streetAddressPropertyAddress"
                  formFieldMessageId={getFormInputErrorId('wb_streetAddressPropertyAddress')}
                  name="streetAddress"
                  label={ORDER_FORM_FIELDS.PROPERTY_ADDRESS}
                  addressLookup={{
                    zip: values.zip,
                  }}
                  allowPOBox={false}
                  value={values.streetAddress}
                  error={errors.streetAddress}
                  onChangeInputNewOrderDeprecated={onChangeInput}
                  onBlurInputNewOrderDeprecated={onBlurStreetAddressNewTemp}
                  setValue={onSelectStreetAddressOptionTemp}
                  setError={(err) => setErrors({ ...errors, streetAddress: err })}
                />
              </div>

              <div className="w-full sm:w-1/4 px-4 py-2">
                <Input
                  formField={true}
                  formFieldMessageId={getFormInputErrorId('wb_propertyAddressUnit')}
                  id="wb_propertyAddressUnit"
                  name="unit"
                  label={ORDER_FORM_FIELDS.PROPERTY_UNITS}
                  value={values.unit || ''}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onChange={onChangeInput}
                />
              </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
              <Select
                formField={true}
                required={true}
                autoComplete={(values.city ?? '') === ''}
                autoCompleteFilterPredicate={() => true} // always show the autocomplete results, no matter what
                id="wb_propertyAddressCity"
                name="city"
                label={ORDER_FORM_FIELDS.PROPERTY_CITY}
                options={cityOptions}
                selected={values.city ? { value: values.city } : null}
                onSelect={handleCitySelect}
                onBlur={handleCityBlur}
                error={errors.city}
              />
            </div>

            <div className="w-full sm:w-1/2 md:w-1/4 px-4 py-2">
              <Select
                formField={true}
                formFieldMessageId={getFormSelectErrorId('wb_propertyAddressState')}
                id="wb_propertyAddressState"
                name="state"
                label={ORDER_FORM_FIELDS.PROPERTY_STATE}
                required={true}
                autoComplete={false}
                options={usStatesForBDSSelect}
                selected={
                  values.state ? usStatesForBDSSelect.find((o) => o.value === values.state) : null
                }
                onSelect={onStateSelect}
                error={errors.state}
                disabled={isStateDisabled}
              />
            </div>
          </div>
        )}
      </CardNewOrder>

      <ModalServiceUnavailable
        id="card-new-order-property_address__service-unavailable-modal"
        isActive={showServiceUnavailableModal}
        onClose={closeServiceUnavailableModal}
        heading={"Sorry, we don't service that area"}
      />

      <ModalConfirmAddressEntered
        id="card-new-order-property_address__address-confirmation-modal"
        heading="Property Address Verification"
        isActive={showValidateAddressModal}
        onClose={closeValidateAddressModal}
        addressFromService={addressMatch}
        addressFromInput={{
          verified: false,
          streetAddress: values.streetAddress,
          unit: values.unit,
          city: values.city,
          state: values.state,
          zip: values.zip,
        }}
        onEditAddress={closeValidateAddressModal}
        onUseServiceAddress={() => setAddressAndCheckExistingContractAndSubmit(addressMatch)}
        onUseCurrentAddress={() => setAddressAndCheckExistingContractAndSubmit()}
      />

      <ModalIncompleteOrderFound
        isActive={showAbandonedOrderModal}
        order={existingAbandonedOrder}
        orderCurrentStep={abandonedOrderStep}
        workflowSteps={abandonedOrderWorkflow}
        initiatingAgentName={abandonedOrderInitAgent}
        initiatingOfficeName={abandonedOrderInitOffice}
        onContinueNewOrder={onContinueNewOrder}
        onContinuePreviousOrder={onContinuePreviousOrder}
      />

      {existingContract?.length > 0 && (
        <ModalContractExistsOnProperty
          id="contract-exists-on-property-modal"
          contractId={existingContract}
          contractStatus={existingContractStatus}
          existingContractSalesChannel={existingContractSalesChannel}
          existingContractAddress={existingContractAddress}
          existingOrderInitiatingAgentExists={existingOrderInitiatingAgentExists}
          existingContractInitiatingAgentName={existingContractInitiatingAgentName}
          existingContractInitiatingAgentOffice={existingContractInitiatingAgentOffice}
          existingOrderInitiatingAgentMatch={existingOrderInitiatingAgentMatch}
          initiatingAgentRepresentBoth={initiatingAgentRepresentBoth}
          pSelectedREAgentId={
            props.agentInfo?.agentInfo?.AgentId?.length > 0
              ? props.agentInfo?.agentInfo?.AgentId
              : props.user?.roleID
          }
          pSelectedREAgentName={
            props.agentInfo?.agentInfo?.initiatingAgent?.length > 0
              ? props.agentInfo?.agentInfo?.initiatingAgent
              : `${props.user?.firstName} ${props.user?.lastName}`
          }
          pSelectedREAgentOfficeId={
            props.agentInfo?.agentInfo?.agentOffice?.id?.length > 0
              ? props.agentInfo?.agentInfo?.agentOffice?.id
              : props.user?.offices[0]?.id
          }
          exisitingOrderInitiatingOfficeOnUserProfile={exisitingOrderInitiatingOfficeOnUserProfile}
          existingOrderClosingAgentExists={existingOrderClosingAgentExists}
          existingOrderCooperatingAgentExists={existingOrderCooperatingAgentExists}
          user={props.user}
          heading={existingContractModalHeader}
          isActive={showContractExistsOnPropertyModal}
          onClose={closeContractExistsOnPropertyModal}
        />
      )}
    </>
  );
};

export default CardNewOrderPropertyAddress;
