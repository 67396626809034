import React from 'react';
import ContractSearchBar, {
  ContractSearchBarProps,
} from '@components/drawer/subcomponents/ContractSearchBar';
import { Text } from '@ftdr/blueprint-components-react';
import ProfileContext from '@context/ProfileContext';
import ProfileModel from '@app/models/profile.model';
import { OfficeIDAndType } from '@apis/models';
import { officeTypeDictionary } from '@constants/dictionaries';
import { isAgent } from '@helpers/profile.utils';

type OwnProps = {
  subtitle?: string;
  onSearchResultChange?: (results: any[]) => void;
};

type DashboardActionAdvancedSearchProps = OwnProps &
  Pick<
    ContractSearchBarProps,
    | 'searchedContractsRenderer'
    | 'unsuccessfulSearchErrorMessage'
    | 'placeHolder'
    | 'includedStatuses'
    | 'globalSearchRestrictions'
    | 'onSearchTextChange'
    | 'footerContentRenderer'
  >;

/** component used for dashboard action drawer's advanced search, which allows them to search via global search and additional restrictions applied. */
export default class DashboardActionAdvancedSearch extends React.Component<DashboardActionAdvancedSearchProps> {
  static contextType = ProfileContext;

  constructor(props) {
    super(props);
  }

  getProfileOfficeIDsAndTypes = (): OfficeIDAndType[] => {
    const profile: ProfileModel = this.context.profile;

    if (isAgent(profile)) {
      return [];
    }

    return (this.context.profile as ProfileModel)?.offices.map<OfficeIDAndType>((office) => ({
      id: office.id,
      type: officeTypeDictionary[office.type],
    }));
  };

  getProfileAgentIDAndTypes = (): OfficeIDAndType[] => {
    const profile: ProfileModel = this.context.profile;

    if (!isAgent(profile)) {
      return [];
    }

    return [
      {
        id: profile.roleID,
        type: officeTypeDictionary[profile.roleIDType],
      },
    ];
  };

  render() {
    return (
      <ContractSearchBar
        headerContentRenderer={() => (
          <>
            {this.props.subtitle && (
              <p className="mb-2">
                <Text variant="body-short">{this.props.subtitle}</Text>
              </p>
            )}
          </>
        )}
        searchedContractsRenderer={this.props.searchedContractsRenderer}
        footerContentRenderer={this.props.footerContentRenderer}
        globalSearchRestrictions={this.props.globalSearchRestrictions}
        unsuccessfulSearchErrorMessage={this.props.unsuccessfulSearchErrorMessage}
        placeHolder={this.props.placeHolder}
        includedStatuses={this.props.includedStatuses}
        filterByOffices={this.getProfileOfficeIDsAndTypes()}
        filterByAgents={this.getProfileAgentIDAndTypes()}
        onSearchTextChange={this.props.onSearchTextChange}
        onSearchResultChange={this.props.onSearchResultChange}
      />
    );
  }
}
