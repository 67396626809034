import React, { useState } from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import IneligibleDwellingSize from '@components/modal/subcomponents/IneligibleDwellingSize';

interface Props extends ModalProps {
  onClose: (squareFootageValue: number) => void;
  startOver: () => void;
  sqFtMax: number;
}

const ModalIneligibleDwellingSize: React.FC<Props> = ({
  id = 'ineligible-dwelling-size-modal',
  heading = 'Ineligible Dwelling Size',
  ...props
}) => {
  const [actions, setActions] = useState([]);

  return (
    <Modal id={id} heading={heading} canClose={true} actions={actions} {...props}>
      {props.isActive && <IneligibleDwellingSize setActions={setActions} {...props} />}
    </Modal>
  );
};

export default ModalIneligibleDwellingSize;
