import React from 'react';
import Tippy from '@tippy.js/react';

/** @deprecated: old SF usage. stop using it */
const Tooltip = (props) => {
  return (
    // Full list of options
    // https://atomiks.github.io/tippyjs/all-options/
    <Tippy
      theme="basic"
      arrow={true}
      animation="fade"
      duration={0}
      interactive={true}
      arrowType="round"
      trigger="mouseenter focus click"
      {...props}
    />
  );
};

export default Tooltip;
