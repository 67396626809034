import DemoRefactoredPlanCoverages from '@pages/demo/DemoRefactoredPlanCoverages';
import DemoGlobalDrawer from '@pages/demo/DemoGlobalDrawer';
import DemoAddressVerification from '@pages/demo/DemoAddressVerification';
import DemoPlanCard from '@pages/demo/DemoPlanCard';
import DemoNewOrderRedesign from '@pages/demo/DemoNewOrderRedesign';

export enum DemoPagePath {
  Demo = '/demo',
  PlanCardDemo = '/planCardDemo',
  DemoGlobalDrawer = '/demoGlobalDrawer',
  DemoAddressVerify = '/demoAddressVerify',
  DemoNewOrderRedesignLayout = '/demoNewOrderRedesign',
}

export const DemoPages: {
  component: any;
  path: DemoPagePath;
  disableDefaultDemoLayout?: boolean;
}[] = [
  {
    path: DemoPagePath.Demo,
    component: DemoRefactoredPlanCoverages,
  },
  {
    path: DemoPagePath.DemoGlobalDrawer,
    component: DemoGlobalDrawer,
  },
  {
    path: DemoPagePath.DemoAddressVerify,
    component: DemoAddressVerification,
  },
  {
    path: DemoPagePath.PlanCardDemo,
    component: DemoPlanCard,
  },
  {
    path: DemoPagePath.DemoNewOrderRedesignLayout,
    component: DemoNewOrderRedesign,
    disableDefaultDemoLayout: true,
  },
];
