import React, { useState } from 'react';
import PlanCards, { mockPlans } from '@components/card/PlanCards';
import SendDocuments, { Type as DocumentType, Recipient } from '@components/SendDocuments';
import { RecipientType } from '@app/generators/recipientGenerator';

const recipientsMockData = [
  {
    name: 'First Lastbuyer',
    email: 'test1@test.com',
    type: RecipientType.Buyer,
    selectedTypes: [DocumentType.Confirmation, DocumentType.Invoice],
  },
  {
    name: 'First Lastseller',
    email: 'test@test.com',
    type: RecipientType.Seller,
    selectedTypes: [DocumentType.Confirmation],
  },
  {
    name: '',
    email: '',
    type: RecipientType.Other,
    selectedTypes: [DocumentType.Confirmation],
  },
];

const DemoPlanCard = () => {
  const [selectedPlanID, setSelectedPlanID] = useState<string>('');
  const [recipients, setRecipients] = useState<Recipient[]>(recipientsMockData);

  return (
    <>
      <PlanCards
        onSelectCallback={(pvid: string) => setSelectedPlanID(pvid)}
        plans={mockPlans}
        selectedPlanID={selectedPlanID || '2'}
        zestyBased={false}
      />
      <SendDocuments
        recipients={recipients}
        allowedTypes={[DocumentType.Confirmation, DocumentType.Invoice]}
        onChange={(data: Recipient[]) => setRecipients(data)}
      />
    </>
  );
};

export default DemoPlanCard;
