const ROOF_RX_COVERAGE_IDS = [115192];
const ELECTRONICS_COVERAGE_IDS = [115222, 115282, 25358];
const NHD_COVERAGE_IDS = [117372, 21587];

const CoverageConfirmationRequired: ReadonlyArray<number> = [
  ...ROOF_RX_COVERAGE_IDS,
  ...ELECTRONICS_COVERAGE_IDS,
  ...NHD_COVERAGE_IDS,
];

/**
 * Checks if coverage id requires confirmation
 */
export const requiresConfirmationById = (cvgId: string | number): boolean => {
  return CoverageConfirmationRequired.includes(+cvgId);
};

/**
 * Checks if coverage id requires confirmation
 */
export const isRoofRXCoverageById = (cvgId: string | number): boolean => {
  return ROOF_RX_COVERAGE_IDS.includes(+cvgId);
};

/**
 * Checks if it is electronics coverage option
 */
export const isElectronicsCoverageById = (cvgId: string | number): boolean => {
  return ELECTRONICS_COVERAGE_IDS.includes(+cvgId);
};

/**
 * Checks if it is NHD coverage option
 */
export const isNHDCoverageById = (cvgId: string | number): boolean => {
  return NHD_COVERAGE_IDS.includes(+cvgId);
};
