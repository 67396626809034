export const SQUARE_FOOTAGE_VALUES = {
  AHS: {
    min: 5000,
    max: 10000,
  },
  HSA: {
    min: 5000,
    max: 15000,
  },
};

/** Age a new construction is considered as */
export const NEW_CONSTRUCTION_AGE = 1;
/** Age a non-new construction is considered as */
export const DEFAULT_AGE = 10;
/** The threshold of a property that is considered a 'new construction' */
export const NEW_CONSTRUCTION_AGE_THRESHOLD = 2;

/** From the given sqft value, returns the string value of it, 'above, between, less'.
 *  Based on CardNewOrderPlansCoverage
 */
export function getSquareFootageRangeValue(sqft: number): string {
  // TODO: Should be checking brand, but the current logic in new order is not doing it
  // Defaulted to AHS
  const range = SQUARE_FOOTAGE_VALUES.AHS;

  if (sqft < range.min) {
    return 'less';
  }
  if (sqft < range.max) {
    return 'between';
  }
  return 'above';
}

/** From the given sqft value, returns the string value of it, 'above, between, less'.
 *  Based on CardNewOrderPlansCoverage
 */
export function getSquareFootageRangeValueNum(sqft: number): number {
  // TODO: Should be checking brand, but the current logic in new order is not doing it
  // Defaulted to AHS
  const range = SQUARE_FOOTAGE_VALUES.AHS;

  if (sqft < range.min) {
    return 4999;
  }
  if (sqft < range.max) {
    return 9999;
  }
  return 10000;
}

/** Determine if new construction or not based on the age. */
export function isAgeNewConstruction(age: number): boolean {
  return age <= NEW_CONSTRUCTION_AGE_THRESHOLD;
}

/** Determine if new construction or not based on the year built. For existing contracts, we go by the list date year */
export function isNewConstructionByYearBuilt(
  yearBuilt: string | number,
  listDateYear: number = new Date().getFullYear(),
): boolean {
  const age = getAgeByYearBuilt(+yearBuilt, listDateYear);
  return isAgeNewConstruction(age);
}

/** Given a property's year built, determine the age of the property. For existing contracts, we go by the list date year. If age is 0, we default to the new construction age */
export function getAgeByYearBuilt(
  yearBuilt: number,
  listDateYear: number = new Date().getFullYear(),
): number {
  let age = 0;

  // Sales entry do not need to specify year built, resulting as 0 age. We don't have a way to determine if a product is for NC or non-NC yet.
  // Until there is a way, we are going to make the assumption that it is non-NC, as that is the most common scenario we have.
  if (yearBuilt === 0) {
    return DEFAULT_AGE;
  }

  if (yearBuilt !== 0) {
    age = listDateYear - yearBuilt;
  }

  return age || NEW_CONSTRUCTION_AGE;
}

export function getYearBuiltFromAge(age: number): number {
  const currentDate = new Date().getFullYear();
  return currentDate - age;
}

export function addressToString(
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
): string {
  return address2 && address2?.length > 0
    ? `${address1}, ${address2}, ${city}, ${state} ${zip}`
    : `${address1}, ${city}, ${state} ${zip}`;
}

export function addressObjectToString(address: {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}): string {
  return addressToString(
    address?.address1,
    address?.address2,
    address?.city,
    address?.state,
    address?.zip,
  );
}

export function propertyObjectToString(address: {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
}): string {
  return addressToString(
    address?.streetAddress,
    address?.streetAddress2,
    address?.city,
    address?.state,
    address?.zip,
  );
}

export function denormalizeStreetAddress1(
  streetNumber: string,
  streetDirection: string,
  streetName: string,
): string {
  return `${streetNumber} ${streetDirection} ${streetName}`.replace('  ', ' ');
}

export function denormalizeStreetAddress2(unitType: string, unitNumber: string): string {
  const hiddenUnitTypes = ['ZZ'];
  if (unitType?.length === 0 && unitNumber?.length === 0) {
    return '';
  }
  if (!unitType || hiddenUnitTypes.includes(unitType)) {
    return unitNumber;
  }
  return `${unitType} ${unitNumber}`;
}

export function getSqftDisplay(input: number) {
  const enumText = getSquareFootageRangeValue(input);
  return SquareFootageValues[enumText];
}

export enum ResidenceTypeValues {
  singleFamilyResidence = 'Single Family Residence',
  condominium = 'Condominium',
  townhouse = 'Townhouse',
  duplex = 'Duplex',
  triplex = 'Triplex',
  fourplex = 'Fourplex',
  mobileHome = 'Mobile Home',
}

export enum SquareFootageValues {
  less = 'Less than 5,000 sq. ft.',
  between = '5,000 to 9,999 sq. ft.',
  above = '10,000 sq. ft. and above',
}
