import React from 'react';
import { Link, Text } from '@ftdr/blueprint-components-react';
import { useNavigation } from 'react-navi';
import paths from '@constants/paths';

const MustHaveBuyer = () => {
  return (
    <>
      <Text>Must have buyer phone to make payment</Text>
    </>
  );
};

export default MustHaveBuyer;
