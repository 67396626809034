import React, { useEffect, useState } from 'react';
import { MaskedInput, ProgressIndicator } from '@ftdr/blueprint-components-react';
import { OrderFormFieldError } from '@components/orders/forms/OrderForm.types';
import { OrderFormAddressInfoField } from '@components/orders/forms/OrderFormAddressInfo';
import { Address } from '@app/models';
import AddressApi from '@apis/address.api';
import { Canceler } from 'axios';

interface ZipInputProps {
  id: string;
  value: string;
  error: string;
  onZipError: any;
  onZipBlur: any;
  onZipChange: any;
  onLoadZipDetails: any;
}

let loadingZipDetailsCanceler: Canceler;

const AddressApiCustom = AddressApi.new({
  suppressResponseErrorNotification: false,
  returnCancelErrorAsNull: false,
});

const ZipInput: React.FC<ZipInputProps> = (props) => {
  useEffect(() => {
    return () => {
      loadingZipDetailsCanceler?.('unmounting');
    };
  }, []);

  const [loadingZipDetails, setLoadingZipDetails] = useState<boolean>(false);

  const makeZipDetailsCall: typeof AddressApi.getZipDetails = (zip, shouldMap) => {
    const cancelSource = AddressApiCustom.createNewCancelTokenSource();
    loadingZipDetailsCanceler = cancelSource.cancel;
    return AddressApi.withRequestConfigAs({ cancelToken: cancelSource.token }).getZipDetails(
      zip,
      shouldMap,
    );
  };

  function handleBlur(e) {
    if (e) {
      props.onZipBlur(e.currentTarget.value);
    }
  }

  function handleChange(e) {
    if (e) {
      props.onZipChange(e.currentTarget.value);

      if (props.error === '' && e.currentTarget.value.length === 5) {
        setLoadingZipDetails(true);
        makeZipDetailsCall(e.currentTarget.value)
          .then((zipDetails: Address) => {
            props.onLoadZipDetails(zipDetails);
          })
          .catch((err) => {
            if (AddressApiCustom.isCancellationError(err)) {
              console.debug('cancellation error', err);
              return;
            }
            props.onLoadZipDetails(null);
          })
          .finally(() => {
            setLoadingZipDetails(false);
          });
      }
    }
  }

  return (
    <MaskedInput
      formField={true}
      inputMode="numeric"
      id={props.id}
      name="zip"
      required={true}
      value={props.value}
      error={props.error}
      autoComplete="off"
      onBlur={(e) => handleBlur(e)}
      onChange={(e) => handleChange(e)}
      label="Zip Code"
      mask="00000"
      secondaryAction={
        loadingZipDetails && (
          <ProgressIndicator size="small" variant="circular" className="absolute right-0" />
        )
      }
    />
  );
};

ZipInput.defaultProps = {};

export default ZipInput;
