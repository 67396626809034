import React from 'react';
import Drawer from '@components/drawer/Drawer';
import QuickLinkUpdateBuyerInfo from '@components/drawer/subcomponents/QuickLinkUpdateBuyerInfo';

const DrawerQLUpdateBuyerInfo = (props) => {
  return (
    <Drawer {...props} className="w-224" useDefaultWidth={false}>
      {props.isActive && (
        <QuickLinkUpdateBuyerInfo isActive={props.isActive} onClose={props.onClose} />
      )}
    </Drawer>
  );
};

export default DrawerQLUpdateBuyerInfo;
