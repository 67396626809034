import { RealEstateStatus } from '@constants/dashboardFilters';
import { Agent } from './agent.api.model';
import { Office } from './office.api.model';
import { Address } from './address.api.model';

export enum ContractDocumentType {
  Invoice = 'invoice',
  Confirmation = 'confirmation',
  Contract = 'contract',
}

export enum ContractDateType {
  ListingDate = 'LISTDATE',
  ListingCoverageEffectiveDate = 'LISTCOVEFF',
  EntryDate = 'ENTRYDATE',
  EstimatedClosingOfEscrow = 'ESTCOE',
  EffectiveDate = 'EFFDATE',
}

export enum ContractStatus {
  ActiveContract = 'A',
  ActiveListing = 'L',
  CancelledContract = 'C',
  CancelledListing = 'X',
}

export interface SearchContractRequest {
  address?: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  status?: ContractStatus[];
}

export interface SearchContractByPropertyRequest {
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
}

export interface ContractSearchResponse {
  contractIDs: string[];
}

export interface ContractSearchByPropertyResponse {
  companyCode: string;
  contractID: string;
  contractStatus: string;
  contractStatusDescription: string;
  salesChannel: string;
}

export interface GetContractIDsResponse {
  contracts: string[];
}

export interface ContractBalance {
  currentBalance: string;
  details: {
    total: number;
    paid: number;
    remittance: number;
    credit: number;
    discounts: number;
  };
  appliedSpecialDiscounts: string[];
}

export interface MakePaymentResponse {
  status: string;
}

// From Contract MS
export interface GetContractByIDResponse {
  ID: string;
  previousContractID: string;
  nextContractID: string;
  productVersionID: string;
  productVersionDescription: string;
  companyCode: string;
  contractStatus: string;
  salesChannel: string;
  originalSalesChannel: string;
  eFulfillmentEnabled: boolean;
  details: {
    type: string;
    value: string;
  }[];
  dates: ContractDate[];
  customers: {
    ID: string;
    type: string;
  }[];
  offices: {
    ID: string;
    typeCode: string;
    represents: string;
    initiating: string;
    agentID: string;
  }[];
  propertyID: string;
}

export interface GetContractsResponse {
  contracts: Contract[];
}

export interface Contract {
  summary: ContractSummary;
  detail: ContractDetail;
  features: ContractFeatures;
  /** TODO: THIS IS ONLY TEMPORARY.
   * This will be removed once all data points are identified and moved to details */
  meta?: GetContractByIDResponse;
}

// Used for the table view
export interface ContractSummary {
  id: string;
  expirationDate: string;
  agentName?: string;
  officeName?: string;
  status: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  awaitingWlkSubmission: boolean;
  realEstateStatus: RealEstateStatus;
  warrantylink?: {
    deadlineDate: string;
  };
}

// Used for the single order view. Break it apart for the order-details
export interface ContractDetail {
  id: string;
  brand: string;
  contractStatus: string;
  mlsNumber: string; // TODO: Add to app layer
  closingFileNumber: string; // TODO: Add to app layer
  details: {
    // TODO: deprecate this
    type: string;
    value: string;
  }[];
  importantDates: {
    // TODO: Add to app layer
    creationDate: string;
    listingExpirationDate: string;
    sellersCoverageEffectiveDate: string;
    sellersCoverageExpirationDate: string;
    projectedClosingDate: string;
    effectiveDate: string;
    expirationDate: string;
    closingDate: string;
    // Listdate is the unformatted version of creationDate, it's used for product search
    listDate: string;
  };
  dates: ContractDate[]; // TODO: deprecate this
  property: {
    ID: string;
    type: string;
    dwellingType: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    zipFour: string;
    streetNumber: string;
    streetDirection: string;
    streetName: string;
    unitType: string;
    unitNumber: string;
    squareFeet: number;
    yearBuilt: string;
    numberMotherLawUnits: number;
    propertyValue: number;
    addressUUID: string;
  };
  initiatingOfficeAgent: OfficeAgent;
  cooperatingOfficeAgent: OfficeAgent;
  closingOfficeAgent: OfficeAgent;
  customers: Customer[];
}

export interface ContractDate {
  type: string;
  effective: string;
  expiration: string;
}

export interface OfficeAgent {
  office: Office;
  agent: Agent;
  represents: string; // TODO: Add to app layer
}

export interface Customer {
  customerId: string;
  type: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  email: {
    id: string;
    address: string;
  };
  phones: {
    number: string;
    type: string;
  };
  address: {
    address1: string; // TODO: Add to app layer
    address2: string; // TODO: Add to app layer
    cityName: string;
    state: string;
    streetDirection: string;
    streetName: string;
    streetNumber: string;
    unitNumber: string;
    unitType: string;
    zipCode: string;
    propertyID: string;
  };
}

// Used to determine flags enable/disable functions
export interface ContractFeatures {
  extendListing: {
    canExtend: boolean;
    daysToExtend: number;
    dateTypes: string[];
    workOrderCount: number;
  };
  warrantylink: {
    hasUnsubmittedWarrantylink: boolean;
  };
  serviceRequest: {
    canRequestService: boolean;
  };
  makePayment: {
    isPayable: boolean;
  };
  cancelListing: {
    isCancellable: boolean;
  };
  upForRenewal: {
    isUpForRenewal: boolean;
    canSendPromoEmail: boolean;
  };
}

// NEW ORDER ///////////////////////////////////////////////

export class CreateOrderRequest {
  constructor(data: Partial<CreateOrderRequest> = {}) {
    this.office = data.office;
    this.closing_information = data.closing_information;
    this.property = data.property;
    this.seller = data.seller;
    this.buyer = data.buyer;
    this.selected_product = data.selected_product;
    this.mls_number = data.mls_number;
    this.createSource = data.createSource;
  }

  public isMissingBuyerInfo(): boolean {
    try {
      return (
        (!this.hasBuyerInfo() && this.requiresBuyerInfo()) ||
        (!this.hasBuyerName() && this.requiresBuyerName())
      );
    } catch (e) {
      console.error('failed to determine if missing buyer info. defaulting to false');
      return false;
    }
  }

  public isMissingSellerInfo(): boolean {
    try {
      return (
        (!this.hasSellerInfo() && this.requiresSellerInfo()) ||
        (!this.hasSellerName() && this.requiresSellerName())
      );
    } catch (e) {
      console.error('failed to determine if missing seller info. defaulting to false');
      return false;
    }
  }

  public isMissingClosingDate(): boolean {
    try {
      return !this.hasClosingDate() && this.requiresClosingDate();
    } catch (e) {
      console.error('failed to determine if missing closing date. defaulting to false');
      return false;
    }
  }

  private getRepresents(): string {
    return (this.office?.represents || '').toLowerCase();
  }

  private hasClosingDate(): boolean {
    return Boolean(this.closing_information?.date);
  }

  private hasBuyerInfo(): boolean {
    return this.hasBuyerName() && this.hasBuyerContact();
  }

  private hasBuyerName(): boolean {
    return Boolean(this.buyer?.first_name && this.buyer?.last_name);
  }

  private hasBuyerContact(): boolean {
    return Boolean(this.buyer?.email || this.buyer?.phone);
  }

  private hasSellerInfo(): boolean {
    return this.hasSellerName() && this.hasSellerContact();
  }

  private hasSellerName(): boolean {
    return Boolean(this.seller?.first_name && this.seller?.last_name);
  }

  private hasSellerContact(): boolean {
    return Boolean(this.seller?.email || this.seller?.phone);
  }

  private hasSellersProduct(): boolean {
    return Boolean(this.selected_product?.sellers_coverage);
  }

  private requiresBuyerInfo(): boolean {
    return this.getRepresents() === 'buyer' || this.getRepresents() === 'both';
  }

  private requiresBuyerName(): boolean {
    return this.requiresBuyerInfo() || this.hasClosingDate();
  }

  private requiresSellerInfo(): boolean {
    return this.getRepresents() === 'seller' || this.getRepresents() === 'both';
  }

  private requiresSellerName(): boolean {
    return this.requiresSellerInfo() || this.hasSellersProduct();
  }

  private requiresClosingDate(): boolean {
    return (
      this.getRepresents() === 'buyer' || this.getRepresents() === 'both' || this.hasBuyerName()
    );
  }
}

export interface CreateOrderRequest {
  office: CreateOrderOffice;
  closing_information?: CreateOrderClosingInformation;
  property: CreateOrderProperty;
  seller?: CreateOrderSeller;
  buyer?: CreateOrderBuyer;
  selected_product: CreateOrderSelectedProduct;
  mls_number: string;
  // TODO: field should be declared in real-estate-app instead of UI
  createSource?: string;
}

interface ICreateOrderOffice {
  id?: string;
  /** Agent ID */
  agent?: string;
  /** BUYER, SELLER, BOTH */
  represents?: string;
}

export interface CreateOrderOffice extends ICreateOrderOffice {
  cooperating_office?: ICreateOrderOffice;
}

/** Required to normalize the address */
export interface CreateOrderProperty {
  id: string;
  street_address: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  type_of_residence: string;
  square_footage: number;
  guest_unit: boolean;
  new_construction: boolean;
}

/** Required to normalize the address */
export interface CreateOrderAddress {
  street_number?: string;
  unit?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface ICreateOrderCustomer {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  /** TODO: Phone type pending */
  phone_type: string;
}

export interface CreateOrderCoCustomer extends ICreateOrderCustomer {
  address?: CreateOrderAddress;
}

export interface CreateOrderSeller extends ICreateOrderCustomer {
  alt_address?: CreateOrderAddress;
  co_seller?: CreateOrderCoCustomer[];
}

export interface CreateOrderBuyer extends ICreateOrderCustomer {
  alt_address?: CreateOrderAddress;
  co_buyer?: CreateOrderCoCustomer[];
}

export interface CreateOrderClosingInformation extends ICreateOrderOffice {
  date: string;
  /** Should be used and not id */
  office_id: string;
  office_file_number?: string;
}

export interface CreateOrderSelectedProduct {
  brand: string;
  id: string;
  sellers_coverage?: boolean;
  selected_optional_coverages: {
    id: string;
  }[];
  selected_optional_coverage_groups: {
    id: string;
    groupId?: string; // This field is never being used from what I can tell
  }[];
}

export interface ContractEmailDocsRequest {
  docEmails: docEmail[];
}

export interface docEmail {
  email: string;
  docs: string[];
}

export interface GetContractDocumentResponse {
  documents: {
    invoice: string;
    confirmation: string;
    contract: string;
  };
}

export interface CancelListingRequest {
  reasonType: string;
  reasonDescription: string;
}

export interface EditOrderClosingInfo {
  /** Format YYYY-MM-DD */
  date: string;
  office_id: string;
  agent_id: string;
  office_file_number: string;
  mls_number: string;
}

export interface SelectedOptionCoverage {
  id: string;
  count: number;
}

export interface SelectedOptionCoverageGroup {
  id: string;
  count: number;
}

export interface EditOrderPropertyDetails {
  selectedProduct: {
    id: string;
    militaryDiscount: boolean;
    selectedOptionalCoverages: SelectedOptionCoverage[];
    selectedOptionalCoverageGroups: SelectedOptionCoverageGroup[];
    guestUnitCount: number;
  };
  property: {
    typeOfResidence: string;
    squareFeet: number;
    age: number;
    mlsNumber: string;
  };
}

export interface UpsertOrderCustomerRequest {
  customers: UpsertOrderCustomer[];
}

export interface UpsertOrderCustomer {
  customerId: string;
  type: string;
  firstName: string;
  lastName: string;
  middleInitial?: string;
  email?: {
    id?: string;
    address?: string;
  };
  phones?: {
    number: string;
    type: string;
  };
  address?: Address;
  titleTypeCode?: string;
}

export interface UpsertOrderAgent {
  officeId?: string;
  agentId?: string;
  agentFirstName?: string;
  agentLastName?: string;
  agentEmail?: string;
}

export interface ContractPricing {
  pricing: OrderPricingData;
  details: OrderPricingDetails;
  earnixRuleID: number;
  appliedSpecialDiscounts: string[];
}

export interface OrderPricingData {
  productAndGuestUnitTotal: number;
  guestUnitTotal: number;
  productTotal: number;
  optionalCoverageTotal: number;
  groupCoverageTotal: number;
  tax: number;
  sellersCoverage: number;
  appliedPromotion: number;
  appliedProrationCredit: number;
  totalDueAtClosing: number;
}

export interface OrderPricingItems {
  id: string;
  name: string;
  quantity: number;
  cost: number;
}

export interface OrderPricingDetails {
  taxableAmount: number;
  taxRate: number;
  listingDays: number;
  dailyRate: number;
  optionalCoverages: OrderPricingItems[];
  groupCoverages: OrderPricingItems[];
}

export interface ContractPlanCoverages {
  product: ContractProduct;
  optionalCoverages: ContractProductCoverageItem[];
  groupCoverages: ContractProductCoverageItem[];
}

export interface ContractProduct {
  id: string;
  starPVID: string;
  name: string;
  contractTermMonths: number;
  sellersCoverage: boolean;
  acCoverage: boolean;
  salesGroup: string[];
}

export interface ContractProductCoverageItem {
  id: string;
  name: string;
  quantity: number;
}

export interface OfficeIDAndType {
  id: string;
  type: string;
}

export enum GlobalSearchRestrictions {
  MissingCOEOnly = 'missingCOEOnly',
  AwaitingWLKSubmissionOnly = 'awaitingWLKSubmissionOnly',
}

export interface GlobalSearchContractRequest {
  limit?: number;
  query?: string;
  /** when set to true, the initiating office address is returned */
  initiatingOfficeAddress?: boolean;
  offices?: OfficeIDAndType[];
  agents?: OfficeIDAndType[];
  restrictions?: GlobalSearchRestrictions[];
}
