import React from 'react';
import { NewOrderContext } from '@context/NewOrderContext';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import { NewOrderStep } from '@app/models/order/neworder.model';
import Path from '@constants/paths';
import { useNavigation } from 'react-navi';
import { NewOrderCompleteRouteData } from '@pages/order/NewOrderComplete';
import { Navigation } from 'navi';
import { isProdEnv } from '@helpers/utils';

interface Props {
  navigation: Navigation;
}

interface States {}

export abstract class AbstractNewOrderStep extends React.Component<Props, States> {
  static contextType = NewOrderContext;
  context: React.ContextType<typeof NewOrderContext>;

  protected constructor(props) {
    super(props);

    this.submitPage.bind(this);
    this.editAnotherStep.bind(this);
    this.cancelPage.bind(this);
    this.navigateToStep.bind(this);
  }

  componentDidMount() {
    // do not allow the user to continue, navigate them to the latest step
    if (this.getCurrentStep() > this.context.latestStep) {
      this.navigateToStep(this.context.latestStep);
    }
    if (!isProdEnv()) {
      this.context.addDebugTool();
    }
  }

  abstract getCurrentStep(): NewOrderStep;

  abstract submitPage(updatedData: NewOrderContextData);

  abstract editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData);

  abstract cancelPage(): void;

  protected navigateToStep(step: NewOrderStep): void {
    // if the latest step has been exceeded, the new step is now the latest step.
    if (this.context.latestStep < step) {
      this.context.setLatestStep(step);
    }
    switch (step) {
      case NewOrderStep.AgentPropertyInfo:
        this.props.navigation.navigate(Path.NewOrderStep1);
        break;
      case NewOrderStep.PlanCoverage:
        this.props.navigation.navigate(Path.NewOrderStep2);
        break;
      case NewOrderStep.BuyerSellerInfo:
        this.props.navigation.navigate(Path.NewOrderStep3);
        break;
      case NewOrderStep.ReviewSubmit:
        this.props.navigation.navigate(Path.NewOrderStep4);
        break;
    }
    return;
  }

  protected navigateToDashboard(): void {
    this.props.navigation.navigate(Path.Dashboard);
  }

  protected navigateToOrderSubmitted(routeData: NewOrderCompleteRouteData): void {
    this.props.navigation.navigate(Path.NewOrderComplete, {
      body: routeData,
    });
    this.context.resetNewOrderData();
  }
}

export function wrapNavigation(Component: typeof AbstractNewOrderStep) {
  return function (props) {
    const navigation = useNavigation();
    return <Component {...props} navigation={navigation} />;
  };
}
