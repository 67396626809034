export default class ProfileModel {
  firstName: string;
  lastName: string;
  roleIDType: string;
  brand: string;
  email: string;
  phoneNumber: string;
  phoneType: string;
  profileID: string;
  authID: string;
  authIDType: string;
  roleID: string;
  narID: string;
  AhsUserID: string;
  offices: ProfileOffice[];
  workedWithOffices: ProfileOffice[];
  hasConfirmedOffice: boolean;
  active: boolean;
  isIncomplete: boolean;
}

export class ProfileOffice {
  type: string;
  id: string;
  active: boolean;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  warrantyLinkEligible: boolean;
  userExtensions: string[];
}

export interface ProfilePermissions {
  Office: string[];
  Agent: string[];
  Account: string[];
  Admin: string[];
  Contract: string[];
}

export interface UserDetails {
  email: string;
  faEmail: string;
  firstName: string;
  lastName: string;
  userType: string;
  offices: ProfileOffice[];
  registrationDate: string;
  brand: string;
  lastActiveDate: string;
  lastPasswordUpdate: string;
  lastForgotPassword: string;
  lastActiveDates: string[];
  phone: string;
  emailVerified: boolean;
  userID: string;
  isWarrantyLinkBroker: boolean;
  profileID: string;
  narID: string;
  agentID: string;
  authIDType: AuthType;
  authID?: string;
}

export enum AuthType {
  FusionAuth = 'FusionAuth',
}
