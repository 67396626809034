import { Input } from '@ftdr/blueprint-components-react';
import React from 'react';

const ShareDocument = (props) => {
  return (
    <div>
      <span>Provide an email address to share this document.</span>
      <Input
        id="share-doc-input-email"
        className="mt-4"
        label=""
        placeholder="Enter email address..."
        inputMode="email"
        onChange={(e) => {
          props.setEmail(e.target.value);
        }}
      />
    </div>
  );
};

export default ShareDocument;
