/** *********************************************************
 *  Store all GA events here to make it easier to change
 *  all at once, such as category and action names
 * ******************************************************** */
import { GAEventArgsMaster, GAEventView, ProfileDataGA } from '@app/models/gaevents.model';
import { PlansAndPricesFormData } from '@templates/misc/PlansAndPricesTemplate';
import { Office, WarrantylinkAgreement, WLKOfficeDetail } from '@apis/models';
import ProfileModel from '@app/models/profile.model';

const CATEGORY = {
  ACCOUNT_MANAGEMENT: 'Account Management',
  CONTACT_US: 'Contact Us',
  DASHBOARD: 'Dashboard',
  EMAIL_CAMPAIGNS: 'Email Campaigns',
  MY_ORDERS: 'My Orders',
  ABANDONED_ORDERS: 'Orders',
  MY_ORDER_URL_AUDIT: 'My Order url audit',
  NEW_ORDER_FLOW: 'New Order Flow',
  ORDERS: 'Orders',
  ORDER_SEARCH: 'Order Search',
  PLANS_AND_PRICES: 'Plans & Prices',
  QUOTES: 'Quotes',
  RENEWALS: 'Renewals',
  SALES_MATERIAL: 'Sales Material',
  SITE_NAVIGATION: 'Site Navigation',
  SPLASH_SCREEN: 'Splash Screen',
  WARRANTY_LINK: 'WarrantyLink',
};

const PLAN_PRICE_START_ORDER_EVENT = {
  event_category: CATEGORY.PLANS_AND_PRICES,
  event_action: 'Start Order',
};
const DASHBOARD_QUICKLINKS = { event_category: CATEGORY.DASHBOARD, event_action: 'Quick Links' };
const DASHBOARD_MYORDERS = { event_category: CATEGORY.DASHBOARD, event_action: 'MyOrders' };
const DASHBOARD_WHATS_NEW_LINK = {
  event_category: CATEGORY.DASHBOARD,
  event_action: "Click What's New",
};
const DASHBOARD_MARKETING_MATERIAL_LINK = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Click Marketing Material',
};
const DASHBOARD_AWAITING_PAYMENT = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Awaiting Payment',
};
const DASHBOARD_VIEW_WLK = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'View warrantyLink',
};
const DASHBOARD_VIEW_WLK_PAYMENTS = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'View WarrantyLink Payments',
};
const DASHBOARD_CLOSING_SOON = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Closing in 7 days',
};
const ORDERS_SELECTED_INITIATING_AGENT_FROM_FUZZY = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Selected Initiating Agent from Fuzzy Search',
};
const ORDERS_CREATED_NEW_INITIATING_AGENT_FROM_FUZZY = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Created New Initiating agent from Fuzzy Search',
};

export const ORDERS_SUBMITTED_FROM_PLANS_PRICES = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Other Submitted Orders',
  event_label: 'Initiated from Plans and Prices page',
};
export const ORDERS_ADDMCA_NONE_ASSIGNED = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Invited Cooperating Agent clicked on contacted support',
  event_label: 'No Cooperating agent assigned on order',
};
export const ORDERS_ADDMCA_ALREADY_ASSIGNED = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Invited Cooperating Agent clicked on contacted support',
  event_label: 'Cooperating agent already assigned on order',
};
export const ORDERS_ADDMCA_ACTIVE_CONTRACT = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Invited Cooperating Agent clicked on contacted support',
  event_label: 'Cooperating agent landed on Active Contract',
};
export const ORDERS_ADDMCA_CANCELLED_CONTRACT = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Invited Cooperating Agent clicked on contacted support',
  event_label: 'Cooperating agent landed on Cancelled Contract',
};
export const ORDERS_ADDMCA_CANCELLED_ORDER = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Invited Cooperating Agent clicked on contacted support',
  event_label: 'Cooperating agent landed on Cancelled Order',
};

export const DASHBOARD_CONTRACT_RENEWALS_VIEW_CONTRACTS = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Contract Renewals',
  event_label: 'View Contracts',
};
export const RENEWALS_SELECTED_SEND_RENEWAL_EMAIL = {
  event_category: CATEGORY.RENEWALS,
  event_action: 'Selected Send Renewal Email option',
};
export const RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_MY_ORDERS = {
  ...RENEWALS_SELECTED_SEND_RENEWAL_EMAIL,
  event_label: 'MyOrders Page',
};
export const RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_DETAILS_DRAWER = {
  ...RENEWALS_SELECTED_SEND_RENEWAL_EMAIL,
  event_label: 'Contract Details drawer',
};

export const RENEWALS_CLICKED_SEND_EMAIL = (contract_id) => {
  return {
    event_category: CATEGORY.RENEWALS,
    event_action: 'clicked on Send Renewal Email CTA',
    event_label: contract_id,
  };
};

export const RENEWALS_UPDATE_EMAIL = (customer_description, contract_id) => {
  return {
    event_category: CATEGORY.RENEWALS,
    event_action: `Updated ${customer_description} email`,
    event_label: contract_id,
  };
};

export const LOGIN_SUCCESS = (): GAEventArgsMaster => {
  const label = new Date().getTime();
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Login Success',
    event_label: `${label}`,
  };
};

export const ACCOUNT_MANAGEMENT__REGISTRATION_COMPLETE = (
  profile: ProfileModel,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Registration Complete',
    event_label: profile?.profileID,
    user_id: getUserId(profile),
    user_type: profile?.roleIDType,
    user_city: profile?.offices[0]?.address.city,
    user_state: profile?.offices[0]?.address.state,
  };
};

export const ACCOUNT_MANAGEMENT__RESEND_EMAIL: GAEventArgsMaster = {
  event_category: CATEGORY.ACCOUNT_MANAGEMENT,
  event_action: 'Resend Verification Email',
  event_label: 'Verification Email Resent',
};

export const ACCOUNT_MANAGEMENT__PROFILE_CREATED = (userType: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Profile Created',
    event_label: userType,
  };
};

export const ACCOUNT_MANAGEMENT__FIELD_UPDATE = (field: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Account Information Changed',
    event_label: `${field}`,
  };
};

export const ACCOUNT_MANAGEMENT__ADDED_OFFICE = (type: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Account Information Changed',
    event_label: `${type} Office Added`,
  };
};

export const ACCOUNT_MANAGEMENT__REMOVED_OFFICE = (type: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Account Information Changed',
    event_label: `${type} Office Removed`,
  };
};

export const ACCOUNT_MANAGEMENT__ACCOUNT_TYPE_CHANGED = (type: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ACCOUNT_MANAGEMENT,
    event_action: 'Account Information Changed',
    event_label: `Account Type Changed To ${type}`,
  };
};

const CONTACT_US_CATEGORY_AND_LABEL = (userType: string, issueType: string) => {
  return {
    event_category: CATEGORY.CONTACT_US,
    event_label: `${issueType} | ${userType}`,
  };
};

export const CONTACT_US__SUBMIT = (userType: string, issueType: string): GAEventArgsMaster => {
  if (userType == null || userType === '') {
    userType = 'Unknown';
  }
  return {
    ...CONTACT_US_CATEGORY_AND_LABEL(userType, issueType),
    event_action: 'Submit Request',
  };
};

export const FAQ__CONTACT_US = (userType: string): GAEventArgsMaster => {
  if (userType == null || userType === '') {
    userType = 'Unknown';
  }
  return {
    event_category: CATEGORY.CONTACT_US,
    event_action: 'Request Support',
    event_label: `${userType}`,
  };
};

const getUserId = (profile: ProfileModel): string => {
  const userId = profile?.profileID;
  return userId === 'NotFound' ? '' : userId;
};

export const NAVIGATION__ORDER_FILTER_SORT = (filter: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDER_SEARCH,
    event_action: 'Filter Orders',
    event_label: `${filter}`,
  };
};

export const NAVIGATION__ORDER_SEARCH = (search: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDER_SEARCH,
    event_action: 'Search Orders',
    event_label: `${search}`,
  };
};

export const NAVIGATION__TOP_LINK_CLICK = (
  linkSection: string,
  linkName: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.SITE_NAVIGATION,
    event_label: `${linkSection}`,
    event_action: `${linkName}`,
  };
};

export const VIEW_PAGE = (path: string, profile: ProfileModel): GAEventView => {
  return {
    user_id: getUserId(profile),
    user_type: profile?.roleIDType,
    user_city: profile?.offices[0]?.address.city,
    user_state: profile?.offices[0]?.address.state,
    virtual_page_path: path,
  };
};

export const WLK_REMOVED: GAEventArgsMaster = {
  event_category: CATEGORY.WARRANTY_LINK,
  event_action: 'WLK Removed',
  event_label: 'WarrantyLink',
};

export const ORDER__START_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.ORDERS,
  event_action: 'Start Order',
  event_label: 'New Order',
};

export const ORDER__OFFICE_AGENT_SETUP = (
  action: string,
  originator: string,
  isEdit: boolean,
  orderId: string,
): GAEventArgsMaster => {
  const actionText = isEdit ? 'Edit Office/Agent' : 'Save Office/Agent';
  return {
    event_category: CATEGORY.ORDERS,
    event_action: actionText,
    event_label: `${originator}`,
    order_id: orderId,
  };
};

export const ORDER__PROPERTY_ADDRESS_SAVE = (
  isEdit: boolean,
  orderId: string,
): GAEventArgsMaster => {
  const actionText = isEdit ? 'Edit Property Address' : 'Save Property Address';
  return {
    event_category: CATEGORY.ORDERS,
    event_action: actionText,
    event_label: 'Property Address',
    order_id: orderId,
  };
};

type PropertyDetailsSaveEventHandler = (data: {
  residenceType: string;
  squareFootage: string;
  isNewConstruction: boolean;
  isEdit?: boolean;
  orderId?: string;
}) => GAEventArgsMaster;

export const ORDER__PROPERTY_DETAILS_START = (orderId?: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Get Property Details',
    event_label: 'Start Property Details',
    order_id: orderId,
  };
};

export const ORDER__PROPERTY_DETAILS_SAVE: PropertyDetailsSaveEventHandler = ({
  residenceType,
  squareFootage,
  isNewConstruction,
  isEdit = false,
  orderId,
}) => {
  const actionText = isEdit ? 'Edit Property Details' : 'Save Property Details';
  return {
    event_category: CATEGORY.ORDERS,
    event_action: actionText,
    event_label: `${residenceType} ${squareFootage} ${isNewConstruction ? 'New' : 'Old'}`,
    order_id: orderId,
  };
};

export const ORDER__PROPERTY_DETAILS_UPDATE: PropertyDetailsSaveEventHandler = (data) => ({
  ...ORDER__PROPERTY_DETAILS_SAVE(data),
  event_action: 'Update Property Details',
});

export const ORDER__BUYER_INFO_ADDED = (field: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: `Added Buyer ${field}`,
    event_label: 'Marketing Email Deeplinks',
  };
};

export const ORDER__BUYER_SELLER_CLOSING_INFO_STEP = (
  saveUpdate: string,
  buyerSellerClosing: string,
  originator: string,
  orderId?: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: `${saveUpdate} ${buyerSellerClosing} Info`,
    event_label: `${originator}`,
    order_id: orderId,
  };
};

export const ORDER__UPDATE_PLAN_STEP = (
  selectUpdate: string,
  originator: string,
  orderId?: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: `${selectUpdate} Plan`,
    event_label: `${originator}`,
    order_id: orderId,
  };
};

export const ORDER__SUBMIT = (
  originator: string,
  contractId: string,
  orderId: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Submit Order',
    event_label: originator,
    order_contract_id: contractId,
    order_id: orderId,
  };
};

export const ORDER__SEND_DOCUMENTS = (
  documentType: string,
  contractID: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Send Documents',
    event_label: `${documentType}`,
    order_contract_id: `${contractID}`,
  };
};

export const ORDER__EXTEND_ORDER_CONTRACT = (contractID: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Extend Order',
    event_label: `Contract ID: ${contractID}`,
  };
};

export const ORDER__CANCEL = (originator: string, orderId?: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Cancel Order',
    event_label: `${originator}`,
    order_id: orderId,
  };
};

export const ORDER__MAKE_PAYMENT = (paymentType: string, contractID: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Make Payment',
    event_label: `${paymentType}`,
    order_contract_id: `${contractID}`,
  };
};

export const ORDER__PAYMENT_CLICK = (button: string, source: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: `Clicked on ${button}`,
    event_label: `From ${source}`,
  };
};

export const ORDER__REQUEST_SERVICE = (contractID: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Request Service',
    event_label: `Contract ID: ${contractID}`,
  };
};

export const PLAN_PRICE__UPDATE_PRICE = (form: PlansAndPricesFormData): GAEventArgsMaster => ({
  event_category: CATEGORY.PLANS_AND_PRICES,
  event_action: 'Update Price',
  event_label: `${form.residenceType} ${form.includeSellersCoverage ? 'with' : 'without'} seller's coverage`,
});

export const PLAN_PRICE__NAVIGATION: GAEventArgsMaster = {
  event_category: CATEGORY.SITE_NAVIGATION,
  event_label: 'Plans and Prices',
  event_action: 'Clicked on See Prices on Top Nav',
};

const getProfileGA = (profile: ProfileModel): ProfileDataGA => {
  return {
    user_id: getUserId(profile),
    user_type: profile?.roleIDType,
    user_city: profile?.offices[0]?.address.city,
    user_state: profile?.offices[0]?.address.state,
  };
};

export const NEW_ORDER_FLOW__AGENT_AND_PROPERTY_COMPLETE = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Completed Agent & Property',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__PLANS_AND_COVERAGE_COMPLETE = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Completed Plans & Coverage',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__OPTIONAL_COVERAGE_SELECTION = (
  profile: ProfileModel,
  represents: string,
  optionalCoverageSelected: boolean,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: optionalCoverageSelected
      ? 'Optional Coverage Selected'
      : 'Optional Coverage Not Selected',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__SELLERS_COVERAGE_SELECTED = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Sellers Coverage Selected',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__BUYER_AND_SELLER_COMPLETE = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Completed Buyer & Seller',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__ORDER_SUBMITTED = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Submitted Order',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__DOCUMENTS_SENT = (
  profile: ProfileModel,
  docTypes: string[],
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Sent Documents',
    event_label: `${docTypes.join(' ')}`,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__BUYER_INFO_ADDED = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Added Buyer Information',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__SELLER_INFO_ADDED = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Added Seller Information',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const NEW_ORDER_FLOW__CLOSING_DATE_ADDED = (
  profile: ProfileModel,
  represents: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.NEW_ORDER_FLOW,
    event_action: 'Added Closing Date',
    event_label: represents,
    ...getProfileGA(profile),
  };
};

export const PLAN_PRICE__SHARE_QUOTE: GAEventArgsMaster = {
  event_category: CATEGORY.PLANS_AND_PRICES,
  event_action: 'Share Quote',
  event_label: 'Share Quote',
};

export const PLAN_PRICE__SAVE_QUOTE: GAEventArgsMaster = {
  event_category: CATEGORY.PLANS_AND_PRICES,
  event_action: 'Save Quote',
  event_label: 'Save Quote',
};

export const PLAN_PRICE__EMAIL_QUOTE = (attachBrochure: boolean): GAEventArgsMaster => ({
  event_category: CATEGORY.PLANS_AND_PRICES,
  event_action: 'Email Quote',
  event_label: attachBrochure ? 'Email & Brochure' : 'Email',
});

export const PLAN_PRICE__START_ORDER = (residenceType, zipCode, hasSellersCoverage) => ({
  ...PLAN_PRICE_START_ORDER_EVENT,
  event_label: `${residenceType} in Zip ${zipCode} ${hasSellersCoverage ? 'with' : 'without'} seller's coverage`,
});

export const WLK_SUBMIT_SINGLE = (contractId: string) => {
  return {
    event_category: CATEGORY.WARRANTY_LINK,
    event_action: 'Submit Order',
    event_label: contractId,
    order_contract_id: contractId,
  };
};

export const WLK_SUBMIT_BULK = {
  event_category: CATEGORY.WARRANTY_LINK,
  event_action: 'Submit Bulk Order',
  event_label: 'multiple orders',
};

export const WLK_AGREEMENT_SUCCESS = (office: Office, agreement: WarrantylinkAgreement) => {
  return {
    event_category: CATEGORY.WARRANTY_LINK,
    event_action: `${agreement.status} ${agreement.type}`,
    event_label: `${office.type} ${office.id}`,
  };
};

export const WLK_AGREEMENT_DOWNLOAD = (office: Office, agreement: WarrantylinkAgreement) => {
  return {
    event_category: CATEGORY.WARRANTY_LINK,
    event_action: `Download ${agreement.type}`,
    event_label: `${office.type} ${office.id}`,
  };
};

export const WLK_VIEW_PAYMENT = (office: WLKOfficeDetail) => {
  return {
    event_category: CATEGORY.WARRANTY_LINK,
    event_action: 'View Payment',
    event_label: `${office.officeType} ${office.officeId}`,
  };
};

export const WLK_FILTER = (filter: string) => {
  return {
    event_category: CATEGORY.WARRANTY_LINK,
    event_action: 'Filter',
    event_label: filter,
  };
};

export const SALES_MATERIAL_DOWNLOAD = (itemName: string) => {
  return {
    event_category: CATEGORY.SALES_MATERIAL,
    event_action: 'Download material',
    event_label: itemName,
  };
};

export const SALES_MATERIAL_WATCH = (itemName: string) => {
  return {
    event_category: CATEGORY.SALES_MATERIAL,
    event_action: 'Watch Video',
    event_label: itemName,
  };
};

export const SALES_MATERIAL_SHARE = (title: string, dropdown: string) => {
  return {
    event_category: CATEGORY.SALES_MATERIAL,
    event_action: 'Share Material',
    event_label: `${title}, ${dropdown}`,
  };
};

export const SPLASH_SCREEN_EVENT = (nickname: string, action: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.SPLASH_SCREEN,
    event_action: `${action}`,
    event_label: `${nickname}`,
  };
};

export const DASHBOARD_WHATS_NEW_LINK_EVENT = (headerText: string): GAEventArgsMaster => {
  return {
    ...DASHBOARD_WHATS_NEW_LINK,
    event_label: `${headerText}`,
  };
};

export const DASHBOARD_MARKETING_MATERIAL_LINK_EVENT = (
  articleTitle: string,
): GAEventArgsMaster => {
  return {
    ...DASHBOARD_MARKETING_MATERIAL_LINK,
    event_label: `${articleTitle}`,
  };
};

export const DASHBOARD_UPDATE_BUYER_INFO = {
  ...DASHBOARD_QUICKLINKS,
  event_label: 'Update Buyer Information',
};

export const DASHBOARD_SEND_INVOICE = {
  ...DASHBOARD_QUICKLINKS,
  event_label: 'Send an Invoice',
};

export const DASHBOARD_CANCEL_ORDER: GAEventArgsMaster = {
  ...DASHBOARD_QUICKLINKS,
  event_label: 'Cancel Order',
};

export const DASHBOARD_REQUEST_SERVICE: GAEventArgsMaster = {
  ...DASHBOARD_QUICKLINKS,
  event_label: 'Request Service',
};

export const DASHBOARD_MISSING_COE = {
  ...DASHBOARD_MYORDERS,
  event_label: 'Missing COE',
};

export const DASHBOARD_VERIFY_WLK = {
  ...DASHBOARD_VIEW_WLK,
  event_label: 'View WL',
};

export const DASHBOARD_WLK_PAYMENTS = {
  ...DASHBOARD_VIEW_WLK_PAYMENTS,
  event_label: 'View WarrantyLink',
};

export const DASHBOARD_EXTEND = {
  ...DASHBOARD_MYORDERS,
  event_label: 'Extend Order',
};

export const DASHBOARD_AWAITING_PAYMENT_VIEW_ORDERS = {
  ...DASHBOARD_AWAITING_PAYMENT,
  event_label: 'View Orders',
};

export const DASHBOARD_CLOSING_SOON_VIEW_ORDERS = {
  ...DASHBOARD_CLOSING_SOON,
  event_label: 'View Orders',
};

export const DASHBOARD_ACTION_UPDATE_COE: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Add COE',
  event_label: 'Add COE',
};

export const DASHBOARD_ACTION_EXTEND_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Extend Orders',
  event_label: 'Extend Order',
};

export const DASHBOARD_ACTION_CANCEL_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Extend Orders',
  event_label: 'Cancel Order',
};

export const DASHBOARD_ACTION_ADD_MISSING_BUYER_PHONE: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Clicked Missing Buyer phone number',
  event_label: 'Missing Buyer phone',
};

export const DASHBOARD_ACTION_MISSING_BUYER_PHONE = (represents: string): GAEventArgsMaster => ({
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Added buyer phone number',
  event_label: `${represents}`,
});

export const DASHBOARD_QUICK_LINK_ACTION_UPDATE_BUYER: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Update Buyer Information',
  event_label: 'Buyer Information',
};

export const DASHBOARD_SUBMIT_WARRANTY_LINK: GAEventArgsMaster = {
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Submit WL Order',
  event_label: 'Submit Order',
};

export const DASHBOARD_BULK_SUBMIT_WARRANTY_LINK = (count: number): GAEventArgsMaster => ({
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Submit Bulk WL Order',
  event_label: `Submit Bulk Order (${count})`,
});

export const DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS = (
  docTypes: string[],
): GAEventArgsMaster => ({
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Send Documents',
  event_label: `${docTypes.join(' ')}`,
});

export const ORDER__ACTION_SEND_DOCUMENTS = (
  docTypes: string[],
  orderId: string,
): GAEventArgsMaster => ({
  event_category: CATEGORY.ORDERS,
  event_action: 'Send Documents in New Order',
  event_label: `${docTypes.join(' ')}`,
  order_id: orderId,
});

export const ORDER__ADD_AS_COOPERATING_AGENT_ORDER = (contractId: string): GAEventArgsMaster => ({
  event_category: CATEGORY.ORDERS,
  event_action: 'Add as cooperating agent',
  event_label: 'Add to existing order',
  order_contract_id: contractId,
});

export const ADD_NEW_AGENT = (agentRole: string, agentId: string): GAEventArgsMaster => ({
  event_category: CATEGORY.ORDERS,
  event_action: `Created new ${agentRole} Agent`,
  event_label: `${window.location.pathname}, ${agentId}`,
});

export const ADD_EXISTING_AGENT = (agentRole: string, agentId: string): GAEventArgsMaster => ({
  event_category: CATEGORY.ORDERS,
  event_action: `Exact matching Agent added as ${agentRole} Agent`,
  event_label: `${agentId}`,
});

export const MISSING_BUYER_PHONE_POPUP = (represents: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Buyer phone message popup is displayed',
    event_label: `${represents}`,
  };
};

export const ADD_BUYER_PHONE_NUMBER = (represents: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Clicked on Add phone number',
    event_label: `${represents}`,
  };
};

export const ADD_BUYER_PHONE_NUMBER_LATER = (represents: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Clicked on I’ll add it later',
    event_label: `${represents}`,
  };
};

export const DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS_IN_UPDATE_BUYER_INFORMATION = (
  docTypes: string[],
): GAEventArgsMaster => ({
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Send Documents in Update Buyer Information',
  event_label: `${docTypes.join(' ')}`,
});

export const DASHBOARD_SEND_DOCUMENTS_IN_MISSING_COE = (docTypes: string[]): GAEventArgsMaster => ({
  event_category: CATEGORY.DASHBOARD,
  event_action: 'Send Documents in Missing CoE',
  event_label: `${docTypes.join(' ')}`,
});

export const MY_ORDERS_NHD_BANNER_CLICK: GAEventArgsMaster = {
  event_category: CATEGORY.MY_ORDERS,
  event_action: 'Click NHD Banner',
  event_label: 'NHD',
};

export const PLAN_PRICE__CONVERT_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.QUOTES,
  event_action: 'Convert to Order',
  event_label: 'Start Quote to Order',
};

export const PLAN_PRICE__CONVERT_ORDER_SUBMIT: GAEventArgsMaster = {
  event_category: CATEGORY.QUOTES,
  event_action: 'Convert to Order',
  event_label: 'Submit Quote to Order',
};

export const PLAN_PRICE__CANCEL_ORDER = (reason: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.QUOTES,
    event_action: 'Cancel Quote',
    event_label: reason,
  };
};

export const PLAN_PRICE__SHARE_QUOTE_SUBMIT = (attachBrochure: boolean): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.QUOTES,
    event_action: 'Email Quote',
    event_label: attachBrochure ? 'Email & Brochure' : 'Email',
  };
};

export const CAPTURE_SELLERS_COVERAGE = (sellerType: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Added Seller Coverage',
    event_label: `${sellerType}`,
  };
};

export const CREATED_CUSTOM_AGENT = (flow: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Added new Cooperating Agent contacts',
    event_label: `${flow}`,
  };
};

export const SELECTED_INITIATING_AGENT_FROM_FUZZY = (flow: string): GAEventArgsMaster => {
  if (flow == null || flow === '') {
    flow = 'Unknown';
  }
  return {
    ...ORDERS_SELECTED_INITIATING_AGENT_FROM_FUZZY,
    event_label: `${flow}`,
  };
};

export const CREATED_NEW_INITIATING_AGENT_FROM_FUZZY = (flow: string): GAEventArgsMaster => {
  if (flow == null || flow === '') {
    flow = 'Unknown';
  }
  return {
    ...ORDERS_CREATED_NEW_INITIATING_AGENT_FROM_FUZZY,
    event_label: `${flow}`,
  };
};

export const EMAIL_CAMPAIGNS_CATEGORY_REQUEST_STATUS = (
  isGoodRequest: boolean,
  url: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.EMAIL_CAMPAIGNS,
    event_action: isGoodRequest ? 'Good Request' : 'Bad Request',
    event_label: url,
  };
};

export const MY_ORDER_URL_AUDIT_REQUEST_STATUS = (
  isGoodRequest: boolean,
  url: string,
): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.MY_ORDER_URL_AUDIT,
    event_action: isGoodRequest ? 'Good Request' : 'Bad Request',
    event_label: url,
  };
};

export const ASSOCIATE_COOPERATING_AGENT: GAEventArgsMaster = {
  event_category: 'Orders',
  event_action: 'Add as Cooperating Agent',
  event_label: 'Claimed order from email',
};

export const ADDED_EXISTING_COOP_AGENT = (flow: string): GAEventArgsMaster => {
  if (flow == null || flow === '') {
    flow = 'Unknown';
  }
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Selected existing Cooperating Agent',
    event_label: `${flow}`,
  };
};

export const CONTINUE_CREATING_NEW_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.ABANDONED_ORDERS,
  event_action: 'Clicked on Continue Creating New Order',
  event_label: 'Abandoned Order Modal on New Order page',
};

export const CONTINUE_PREVIOUS_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.ABANDONED_ORDERS,
  event_action: 'Clicked on Continue Previous Order',
  event_label: 'Abandoned Order Modal on New Order page',
};

export const SUBMITTED_ABANDONED_ORDER = (source: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ABANDONED_ORDERS,
    event_action: 'Submitted Abandoned Order',
    event_label: `Initiated from ${source}`,
  };
};

export const CONTINUE_ABANDONED_ORDER: GAEventArgsMaster = {
  event_category: CATEGORY.ABANDONED_ORDERS,
  event_action: 'Clicked on Continue Abandoned Order',
  event_label: 'My Orders page',
};

export const NOT_SUBMITTED_TAB: GAEventArgsMaster = {
  event_category: CATEGORY.ABANDONED_ORDERS,
  event_action: 'Clicked on Not Submitted Tab',
  event_label: 'My Orders page',
};

export const MILITARY_DISCOUNT_REMOVED = (flow: string): GAEventArgsMaster => {
  return {
    event_category: CATEGORY.ORDERS,
    event_action: 'Military Discount Removed',
    event_label: `${flow}`,
  }
};