import React from 'react';
import ModalConfirmCoverage, {
  ModalConfirmCoverageProps,
} from '@components/modal/ModalConfirmCoverage';
import ElectronicsCoverage from '@components/modal/subcomponents/ElectronicsCoverage';

const ModalElectronicsCoverage: React.FC<ModalConfirmCoverageProps> = (props) => (
  <ModalConfirmCoverage
    id="electronics-coverage-modal"
    heading="Electronics Protection Plan"
    {...props}
  >
    {props.isActive && <ElectronicsCoverage />}
  </ModalConfirmCoverage>
);

export default ModalElectronicsCoverage;
