import {
  Button,
  Checkbox,
  IconButton,
  IconNavArrowLeft,
  IconPlus,
  IconTrash,
  Input,
  Table,
  Text,
} from '@ftdr/blueprint-components-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { camelCase, isEmpty } from 'lodash';
import { validateEmailInput } from './input/EmailInput';
import { classNames, isMobileView } from '@utils';
import { Contract } from '@apis/models';
import { ContractModelVariant } from '@app/models/contract.model';
import RecipientsGenerator, {
  ContractRecipientData,
  RecipientType,
} from '@app/generators/recipientGenerator';
import { addressToString } from '@services/helpers';
import { dateTypes } from '@constants/dates';

export enum Type {
  Invoice = 'invoice',
  Confirmation = 'confirmation',
  Renewal = 'renewal',
}

export interface Recipient {
  name: string;
  type: RecipientType;
  email: string;
  selectedTypes: Type[];
}

export interface RecipientRow extends Recipient {
  saved: boolean;
  onChange: (recipients: Recipient[]) => void; // needed to pass down SendDocuments's props.onChange callback
  onValidate: (valid: boolean) => void; // used to allow the parent form to know overall error count
}

export interface Props {
  recipients: Recipient[];
  allowedTypes: Type[];
  onChange: (recipients: Recipient[]) => void;
  onSave?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  newOrderData?: any;
  onBackToSearch?: any;
  hideHeading?: boolean;
  displaySelectRecipientMessage?: boolean;
  onUpdateFormValidState?: (isValid: boolean) => void;
  newOrderClosingDate?: string;
}

interface EmailInputProps {
  email: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string;
  isShort: boolean;
}

interface EmailCellProps {
  value: string;
  row: any; // TODO: type
  data: RecipientRow[];
}

interface NameInputProps {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface NameCellProps {
  value: string;
  row: any; // TODO: type
  data: RecipientRow[];
}

export enum RecipientContext {
  SendDocuments = 1,
  SendRenewal,
}

const getRecipients = (recipientRows: RecipientRow[]): Recipient[] => {
  return recipientRows.map((recipient) => {
    const { name, email, type, selectedTypes } = recipient;

    return {
      name,
      email,
      type,
      selectedTypes,
    };
  });
};

const EmailInput: React.FC<EmailInputProps> = (props) => {
  return (
    <Input
      formField
      label=""
      size="medium"
      type="email"
      inputClassName={`sm-max:pt-0 text-base pr-2 send-documents-input ${props.isShort ? 'send-documents-input-short' : ''}`}
      placeholder="Email Address"
      value={props.email}
      error={props.errors}
      onChange={props.onChange}
    />
  );
};

const EmailCell: React.FC<EmailCellProps> = (props) => {
  const {
    row: { id, original },
    data,
    value: email,
  } = props;
  const { saved, onChange, onValidate } = original;
  const dataCopy = [...data]; // copy to avoid mutating props
  const [errors, setErrors] = useState('');

  const isMobile = isMobileView();

  if (!isEmpty(email) && saved) return <div>{email}</div>;

  const updateEmail = (newEmail: string): RecipientRow[] => {
    dataCopy[id].email = newEmail;

    return dataCopy;
  };

  const getOtherCount = () => {
    return data.filter(({ type }) => type === RecipientType.Other).length;
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const errs = validateEmailInput(e.target.value, false);

    if (errors !== errs) onValidate(isEmpty(errs)); // Only notify parent on validation state change to allow overall error count to be maintained correctly

    setErrors(errs);
    onChange(getRecipients(updateEmail(e.target.value)));
  };

  return (
    <>
      <div className="flex">
        <EmailInput
          email={email}
          onChange={onInputChange}
          errors={errors}
          isShort={isMobile && getOtherCount() > 1}
        />
        {getOtherCount() > 1 && (
          <IconButton
            label=""
            icon={<IconTrash />}
            variant="ghost"
            size="medium"
            color="primary"
            onClick={() => {
              dataCopy.splice(id, 1);

              onChange(getRecipients(dataCopy));
            }}
          />
        )}
      </div>
    </>
  );
};

const NameInput: React.FC<NameInputProps> = (props) => {
  return (
    <Input
      formField
      label=""
      size="medium"
      type="text"
      inputClassName="sm-max:pt-0 text-base pr-2 send-documents-input"
      placeholder="Recipient Name"
      value={props.name}
      onChange={props.onChange}
    />
  );
};

const NameCell: React.FC<NameCellProps> = (props) => {
  const {
    row: { id, original },
    data,
    value: name,
  } = props;
  const { saved, onChange } = original;
  const dataCopy = [...data]; // copy to avoid mutating props

  if (!isEmpty(name) && saved) return <div>{name}</div>;

  const updateName = (newName: string): RecipientRow[] => {
    dataCopy[id].name = newName;

    return dataCopy;
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(getRecipients(updateName(e.target.value)));
  };

  return (
    <>
      <div className="flex">
        <NameInput name={name} onChange={onInputChange} />
      </div>
    </>
  );
};

export const GetLatestRecipients = (
  contract: Contract | ContractRecipientData | any,
  type: ContractModelVariant,
  recipients: Recipient[],
  email: string,
  fullName: string,
  context: RecipientContext = RecipientContext.SendDocuments,
): Recipient[] => {
  // TODO: Normalize domain
  const generator = new RecipientsGenerator(recipients, email, fullName);
  let recipientList: Recipient[] = [];
  switch (type) {
    case ContractModelVariant.API:
      recipientList = generator.fromAPIContract(contract);
      break;
    case ContractModelVariant.App:
      recipientList = generator.fromAppContract(contract);
      break;
    case ContractModelVariant.NewOrderContextData:
      recipientList = generator.fromNewOrderContext(contract);
      break;
    default:
      return [];
  }

  if (context === RecipientContext.SendRenewal) {
    recipientList = recipientList.filter((r) => {
      return [
        RecipientType.Buyer,
        RecipientType.CoBuyer,
        RecipientType.InitiatingAgent,
        RecipientType.Myself,
        RecipientType.Other,
      ].includes(r.type);
    });
    recipientList.forEach((r) => {
      r.selectedTypes = [RecipientType.Buyer, RecipientType.CoBuyer].includes(r.type)
        ? [Type.Renewal]
        : [];
    });
  }

  return recipientList;
};

const SendDocuments: React.FC<Props> = (props) => {
  const [errorCount, setErrorCount] = useState(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onValidate = (valid: boolean) => {
    let count = errorCount;

    if (valid && count > 0) setErrorCount((count -= 1));

    if (!valid) setErrorCount((count += 1));
  };

  const getData = (): RecipientRow[] => {
    const recipients = props.recipients.map((recipient) => {
      const data = {
        saved: recipient.type !== RecipientType.Other, // used to know whether or not to keep input field available - just needed for others atm
        onChange: props.onChange,
        onValidate,
        ...recipient,
      };

      Object.keys(Type).forEach((type) => {
        data[`${camelCase(type)}Selected`] = { selected: data.selectedTypes.includes(Type[type]) };
      });

      return data;
    });

    //Place RecipientType.Other items at the bottom of the list
    const sortedRecipients = [
      ...recipients.filter((recipient) => recipient.type !== RecipientType.Other),
      ...recipients.filter((recipient) => recipient.type === RecipientType.Other),
    ];

    return sortedRecipients;
  };

  const updateSelection = (id: string, selected: boolean, type: Type): RecipientRow[] => {
    const data = getData();
    if (selected) data[id].selectedTypes = [...data[id].selectedTypes, type];

    if (!selected)
      data[id].selectedTypes = data[id].selectedTypes.filter(
        (selectedType) => type !== selectedType,
      );
    return data;
  };

  const getCheckbox = (row, type: Type) => {
    return (
      <div className="lg:text-center">
        <Checkbox
          label=""
          name=""
          hideLabel={true}
          onChange={({ target }) =>
            props.onChange(getRecipients(updateSelection(row.id, target.checked, type)))
          }
          checked={isInvoiceAllowed(type) ? row.original.selectedTypes.includes(type) : false}
          disabled={!isInvoiceAllowed(type)}
        />
      </div>
    );
  };

  // shouldDisableCheckBox returns true if the type is invoice and closing date does not exist
  const isInvoiceAllowed = (type: Type): boolean => {
    const doesHaveClosingDate: boolean = !!props.newOrderData?.detail?.dates?.find(
      (date) => date?.type == dateTypes.ESTCOE,
    );
    return !(type === Type.Invoice && !(doesHaveClosingDate || props.newOrderClosingDate));
  };

  const filterAllowedTypes = (columns) => {
    const test = columns.filter((x) => {
      if (!Object.values(Type).includes(x.accessor)) return true;

      if (Object.values(Type).includes(x.accessor) && !props.allowedTypes.includes(x.accessor))
        return false;

      return true;
    });

    return test;
  };

  const getColumns = () => {
    return [
      {
        Header: (
          <Text variant="heading-06" className="lg:text-center">
            Order Confirmation
          </Text>
        ),
        accessor: Type.Confirmation, // unused but string needed
        Cell: ({ row }) => getCheckbox(row, Type.Confirmation),
      },
      {
        Header: (
          <Text variant="heading-06" className="lg:text-center">
            Invoice
          </Text>
        ),
        accessor: Type.Invoice, // unused but string needed
        Cell: ({ row }) => getCheckbox(row, Type.Invoice),
      },
      {
        Header: <Text variant="heading-06">Recipients</Text>,
        accessor: 'type',
      },
      {
        Header: <Text variant="heading-06">Recipient Name</Text>,
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: <Text variant="heading-06">Email Address</Text>,
        accessor: 'email',
        Cell: EmailCell,
      },
    ];
  };

  const isValid = (): boolean => {
    return errorCount === 0;
  };

  const getAddRecipientButton = (): React.ReactElement => {
    // leverage css
    return (
      <>
        <Button
          label="Add Recipient"
          startIcon={<IconPlus />}
          onClick={() =>
            props.onChange([
              ...props.recipients,
              { name: '', type: RecipientType.Other, email: '', selectedTypes: [] },
            ])
          }
          size="small"
          variant="outlined"
          className="md:hidden"
        />
        <Button
          label="Add Recipient"
          startIcon={<IconPlus />}
          onClick={() =>
            props.onChange([
              ...props.recipients,
              { name: '', type: RecipientType.Other, email: '', selectedTypes: [] },
            ])
          }
          size="medium"
          variant="outlined"
          className="hidden md:inline-flex"
        />
      </>
    );
  };

  const isSaveDisabled = (): boolean => {
    const selectionsMade = !props.recipients.some((x) => {
      return x.selectedTypes.length > 0 && !isEmpty(x.email);
    });

    return props.disabled || !isValid() || selectionsMade || isSaving;
  };

  useEffect(() => {
    if (!props.onUpdateFormValidState) return;
    props.onUpdateFormValidState(errorCount === 0);
  }, [errorCount]);

  const onSave = () => {
    setIsSaving(true);
    props.onSave();
  };

  return (
    <>
      <div className="w-full px-4">
        {!props.hideHeading && (
          <Text variant="heading-05" className="pt-2">
            Send Documents
          </Text>
        )}

        {props.newOrderData && (
          <Text variant="heading-06">
            {addressToString(
              props.newOrderData.summary.address.address1,
              props.newOrderData.summary.address.address2,
              props.newOrderData.summary.address.city,
              props.newOrderData.summary.address.state,
              props.newOrderData.summary.address.zip,
            )}
          </Text>
        )}
        {props.onBackToSearch && (
          <Button
            className="mt-4"
            label="Back to search results"
            startIcon={<IconNavArrowLeft />}
            onClick={props.onBackToSearch}
            size="small"
            variant="ghost"
          />
        )}
        {props.displaySelectRecipientMessage && (
          <Text variant="body-short" color="error" className="pb-4 pt-2 pl-1">
            Please select recipients to send the order documents. At least one recipient selection
            is required.
          </Text>
        )}
        <Table
          data={getData()}
          columns={filterAllowedTypes(getColumns())}
          striped="gray"
          variant="light"
          paginate={false}
          sortable={false}
        />
        <div className="md:flex">
          <div className="py-4">{getAddRecipientButton()}</div>
          <div
            className={classNames([
              'text-center md:flex-wrap md:flex-row-reverse md:justify-start md:items-center w-full md:pl-8 py-4',
              props.onSave ? 'md:flex ' : 'hidden',
            ])}
          >
            <div>
              <Button
                id="btn-send-notification"
                className="justify-center"
                label="Save & Continue"
                size="medium"
                onClick={onSave}
                disabled={isSaveDisabled()}
                width="full"
                labelAlign="center"
              />
            </div>
            <Button
              className="sm-max:mt-4 md:mr-4"
              label="Cancel"
              size="small"
              variant="ghost"
              onClick={props.onCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

SendDocuments.defaultProps = {
  disabled: false,
};

export default SendDocuments;
