import React from 'react';
import { NewOrderStep } from '@app/models/order/neworder.model';
import {
  WorkflowIndicator,
  WorkflowStep,
  WorkflowStepStatus,
} from '@ftdr/blueprint-components-react';

interface Props {
  currentStep: NewOrderStep;
  latestStep: NewOrderStep;
}

const StepName = {
  [NewOrderStep.AgentPropertyInfo]: 'Agent & Property Info',
  [NewOrderStep.PlanCoverage]: 'Plans & Coverage',
  [NewOrderStep.BuyerSellerInfo]: 'Buyer & Seller Info',
  [NewOrderStep.ReviewSubmit]: 'Send Documents',
};

const NewOrderStepIndicator: React.FC<Props> = ({ currentStep, latestStep }) => {
  const stepLabels = Object.values(StepName);

  const getStepStatus = (stepIndex: number): WorkflowStepStatus => {
    if (currentStep === stepIndex) {
      return 'in-progress';
    }
    if (latestStep > stepIndex) {
      return 'completed';
    }
    return 'not-started';
  };

  return (
    <div className="md:hidden NewOrderStepIndicator">
      <WorkflowIndicator>
        {stepLabels.map((step, idx) => (
          <WorkflowStep key={step} status={getStepStatus(idx)}>
            {step}
          </WorkflowStep>
        ))}
      </WorkflowIndicator>
    </div>
  );
};

export default NewOrderStepIndicator;
