import React, { useState } from 'react';
import { getPreviousNewOrderStep, NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import { isMediumView } from '@utils';
import NewOrderStepWayfinder from '@components/orders/neworder/layout/NewOrderStepWayfinder';
import { Button, IconArrowLeft, Panel, Text } from '@ftdr/blueprint-components-react';
import NewOrderCardSection from '@components/orders/neworder/layout/NewOrderCardSection';
import NewOrderSummary from '@components/orders/neworder/layout/NewOrderSummary';
import NewOrderPageDrawer from '@components/orders/neworder/layout/NewOrderPageDrawer';
import ModalCancelOrder from '@components/modal/ModalCancelOrder';
import NewOrderStepIndicator from './NewOrderStepIndicator';

interface Props {
  /** user clicks on continue CTA. should update the context data based off of the form on page */
  onContinue: () => void;
  /** user clicks on cancel CTA and acknowledged to cancel. should reset the context data and send user to some location */
  onCancel: () => void;
  /** user clicks to edit a previous step. should store to context any values that are allowed to be saved partially and navigate user */
  onEdit: (step: NewOrderStep) => void;
  /** user clicks on CTA to clear current page form data.  should reset the form data on the page. */
  onClear: () => void;
  /** the current form data, including active changes done in the form */
  orderData: NewOrderContextData;
  /** the current step the user is currently viewing */
  currentStep: NewOrderStep;
  /** the most latest step the user has gotten to */
  latestStep: NewOrderStep;
}

const NewOrderPageLayout: React.FC<Props> = (props) => {
  const isMedium = isMediumView();
  const [isCancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);

  const CTAs = () => (
    <div className="NewOrderPageLayout_Actions space-y-2">
      <Button
        id="NewOrderPageLayout.ContinueCTA"
        label={props.currentStep === NewOrderStep.ReviewSubmit ? 'Submit Order' : 'Continue'}
        onClick={() => props.onContinue()}
        size="medium"
        width="full"
      />
      <Button
        label="Cancel"
        id="NewOrderPageLayout.CancelCTA"
        onClick={() => setCancelOrderModalOpen(true)}
        size="medium"
        variant="ghost"
        width="full"
      />
    </div>
  );

  const Summary = () => (
    <NewOrderSummary
      orderData={props.orderData}
      currentStep={props.currentStep}
      latestStep={props.latestStep}
      onClickEditStep={props.onEdit}
    />
  );

  return (
    <>
      <div className="NewOrderPageLayout">
        <NewOrderStepIndicator currentStep={props.currentStep} latestStep={props.latestStep} />
        <NewOrderStepWayfinder currentStep={props.currentStep} />
        <Text
          id="NewOrderPageLayout.HeadingText"
          className="NewOrderPageLayout_Heading"
          variant="heading-03"
        >
          Place New Order
        </Text>
        <div className="NewOrderPageLayout_Content">
          <NewOrderCardSection currentStep={props.currentStep} onClear={props.onClear}>
            {props.children}

            {props.currentStep > 0 && (
              <div>
                <Button
                  className="relative -z-50"
                  startIcon={<IconArrowLeft />}
                  size="medium"
                  label="Back"
                  variant="outlined"
                  onClick={() => {
                    props.onEdit(getPreviousNewOrderStep(props.currentStep));
                  }}
                />
              </div>
            )}
          </NewOrderCardSection>
          <div className="NewOrderPageLayout_FloatingContent">
            <Panel shadow={true}>
              <div className="NewOrderPageLayout_FloatingContent_Heading">
                <Text variant="heading-04">Order Summary</Text>
              </div>
              {Summary()}
              {CTAs()}
            </Panel>
          </div>
        </div>
        <div className="NewOrderPageLayout_MobileFooter">{CTAs()}</div>
      </div>
      {isMedium && <NewOrderPageDrawer>{Summary()}</NewOrderPageDrawer>}
      <ModalCancelOrder
        id="new-order-template__modal-cancel-order"
        isActive={isCancelOrderModalOpen}
        onClose={() => setCancelOrderModalOpen(false)}
        onConfirm={() => props.onCancel()}
      />
    </>
  );
};

export default NewOrderPageLayout;
