import React from 'react';
import ModalConfirmCoverage, {
  ModalConfirmCoverageProps,
} from '@components/modal/ModalConfirmCoverage';
import NHDCoverage from '@components/modal/subcomponents/NHDCoverage';

const ModalNHDCoverage: React.FC<ModalConfirmCoverageProps> = (props) => (
  <ModalConfirmCoverage
    id="nhd-coverage-modal"
    heading="Natural Hazard Disclosure Report"
    {...props}
  >
    {props.isActive && <NHDCoverage />}
  </ModalConfirmCoverage>
);

export default ModalNHDCoverage;
