import { Tab, Tabs } from '@ftdr/blueprint-components-react';
import React, { useEffect, useState } from 'react';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';
import DashboardActionHeader, {
  DashboardActionHeaderProps,
} from '@components/drawer/subcomponents/DashboardActionHeader';

interface Props extends DashboardActionHeaderProps {
  includeAdvancedSearch?: boolean;
  searchType?: DashboardActionSearchType;
  defaultSearchType?: DashboardActionSearchType;
  onChangeSearchType?: (searchType: DashboardActionSearchType) => void;
  contentRenderer: (activeSearchType: DashboardActionSearchType) => JSX.Element;
}

export enum DashboardActionSearchType {
  ShowAll,
  Advanced,
}

/** component allows switching between the available actions for the dashboard action card drawer.  */
const DashboardActionSwitcher: React.FC<Props> = (props) => {
  const tabItems = ['Show All', 'Advanced Search'];

  const [searchType, setSearchType] = useState<DashboardActionSearchType>(
    props.searchType || props.defaultSearchType,
  );

  useEffect(() => {
    setSearchType(props.searchType || props.defaultSearchType);
  }, [props.searchType]);

  const updateSearchType = (newSearchType: DashboardActionSearchType) => {
    setSearchType(newSearchType);
    props.onChangeSearchType?.(newSearchType);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="w-full">
          <DashboardActionHeader
            id={props.id}
            heading={props.heading}
            totalContractsCount={props.totalContractsCount}
          />
        </div>
        {props.includeAdvancedSearch && (
          <Tabs
            variant="horizontal"
            activeIndex={searchType}
            onActivate={(_, index) => {
              updateSearchType(index);
            }}
          >
            {tabItems.map((label) => (
              <Tab key={label} label={label}>
                {}
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
      <div className="w-full mt-6">{props.contentRenderer(searchType)}</div>
    </>
  );
};

DashboardActionSwitcher.defaultProps = {
  defaultSearchType: DashboardActionSearchType.ShowAll,
  includeAdvancedSearch: false,
};

export default DashboardActionSwitcher;
