import { Office, OfficeList, WarrantylinkAccount, WarrantylinkAgreement } from '@apis/models';
import OfficeApi from '@apis/office.api';
import WarrantylinkApi from '@apis/warrantylink.api';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { officeTypeDictionary, UserRoleType } from '@constants/dictionaries';
import ProfileContext from '@context/ProfileContext';
import {
  getProfileWLKOffices,
  getWLKBrokerOffices,
} from '@services/helpers/profile.offices.helper';
import {
  WarrantylinkAccountWithOffice,
  WarrantyLinkAgreementsTemplate,
} from '@templates/warrantylink/WarrantyLinkAgreementsTemplate';
import React, { useContext, useEffect, useState } from 'react';
import { fireGAEvent } from '@app/core/tracking.service';
import { WLK_AGREEMENT_SUCCESS } from '@constants/ga-events.constants';
import msgs from '@app/locales/en';
import Layout from '@components/layout/Layout';
import { ContentBox } from '@components/layout/ContentBox';

// Currently the wlk eligibility flag is set to true even for branch offices
// and there is no way to differentiate between main and branch offices as of right now.
// Suppressing errors on the account so that it isn't outputted onto the page.
const CONSUME_ACCOUNT_ERRORS = true;

const WarrantylinkAgreements: React.FC = () => {
  const { profile } = useContext(ProfileContext);
  const { showSpinner } = useGlobalOverlaySpinner();
  const { addErrorToQueue, addWarningToQueue } = useGlobalAlert();

  const [loading, setLoading] = useState({
    userOffices: false,
    allAccounts: false,
    signAgreement: false,
  });
  const [loadingError, setLoadingError] = useState({
    userOffices: false,
    allAccounts: false,
    signAgreement: false,
  });

  const [wlkAccounts, setWlkAccounts] = useState<WarrantylinkAccountWithOffice[]>([]);

  // Handle global spinner where necessary
  useEffect(() => {
    // We are only using global spinner for user offices right now
    if (loading.userOffices) {
      showSpinner(true, 'Loading WarrantyLink Offices');
    } else if (loading.allAccounts) {
      showSpinner(true, 'Loading Wararntylink Account Information');
    } else if (loading.signAgreement) {
      showSpinner(true, 'Signing Warrantylink Agreement');
    } else {
      showSpinner(false);
    }
  }, [loading]);

  // Load user office list.  Select the first office.
  useEffect(() => {
    if (profile?.offices) {
      let offices = [];
      if (profile.roleIDType != UserRoleType.SiteAdmin) {
        offices = getWLKBrokerOffices(profile).map((o) => ({
          id: o.id,
          type: officeTypeDictionary[o.type],
        }));
      } else {
        offices = getProfileWLKOffices(profile).map((o) => ({
          id: o.id,
          type: officeTypeDictionary[o.type],
        }));
      }

      loadOffices(offices);
    } else {
      setLoadingError({ ...loadingError, userOffices: true });
    }
  }, []);

  async function loadOffices(offices: any) {
    let officeDetails: OfficeList;
    try {
      setLoading({ ...loading, userOffices: true });
      setLoadingError({ ...loadingError, userOffices: false });
      officeDetails = await OfficeApi.getOfficeList(offices);
    } catch (e) {
      console.error(e);
      setLoadingError({ ...loadingError, userOffices: true });
    } finally {
      setLoading({ ...loading, userOffices: false });
    }

    // Load all wlk office accounts
    if (officeDetails?.offices?.length > 0) {
      loadAllAccounts(officeDetails.offices);
    }
  }

  async function loadAllAccounts(offices: Office[]) {
    setLoading({ ...loading, allAccounts: true });
    setLoadingError({ ...loadingError, allAccounts: false });

    let accounts: WarrantylinkAccountWithOffice[] = [];

    // For each office, get the account and add it to list
    for (const office of offices) {
      const account = await loadAccount(office);
      if (account) {
        accounts = [...accounts, { ...account, office }];
        setWlkAccounts(accounts);
      } else if (!CONSUME_ACCOUNT_ERRORS) {
        addWarningToQueue({
          messageHTML: (
            <>
              We are unable to load the account information for{' '}
              <span className="font-bold">{office.name}.</span>
            </>
          ),
        });
      }
    }

    setLoading({ ...loading, allAccounts: false });
  }

  /** Replace the account on the accounts list with an updated version. */
  async function reloadAccount(office: Office): Promise<void> {
    const account = await loadAccount(office);
    if (account) {
      const updatedAccounts = [...wlkAccounts];
      const index = updatedAccounts.findIndex((a) => a.office.id === office.id);
      if (index > -1) {
        updatedAccounts[index] = { ...account, office };
        setWlkAccounts(updatedAccounts);
      }
    }
  }

  async function loadAccount(office: Office): Promise<WarrantylinkAccount> {
    let account: WarrantylinkAccount;

    try {
      account = await WarrantylinkApi.getWarrantylinkAccount(office.id);
    } catch (e) {
      console.error(e);
    }

    return account;
  }

  /** Sign the agreement. On success, we reload the agreement table with the updated information. */
  async function handleSignAgreement(
    office: Office,
    agreement: WarrantylinkAgreement,
  ): Promise<boolean> {
    setLoading({ ...loading, signAgreement: true });
    return WarrantylinkApi.signWarrantylinkAgreement(office.id, agreement)
      .catch((err) => {
        console.error('Failed signing agreements:', err);
        setLoadingError({ ...loadingError, signAgreement: true });
        return false;
      })
      .then(async (success) => {
        if (success) {
          // Reload the account before returning to the drawer
          await reloadAccount(office);
          fireGAEvent(WLK_AGREEMENT_SUCCESS(office, agreement));
        } else {
          addErrorToQueue(msgs.SIGN_WLK_AGREEMENTS_FAILURE);
        }
        return success;
      })
      .finally(() => {
        setLoading({ ...loading, signAgreement: false });
      });
  }

  return (
    <Layout isLoggedIn={true} slug="warrantyLink">
      <ContentBox title="Program Agreements">
        <WarrantyLinkAgreementsTemplate
          accounts={wlkAccounts}
          loadingAgreements={loading.allAccounts}
          onSignAgreement={handleSignAgreement}
        />
      </ContentBox>
    </Layout>
  );
};

export default WarrantylinkAgreements;
