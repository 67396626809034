import React, { useState, useEffect } from 'react';
import { getBrand } from '@helpers/brand.utils';
import { browserVersion, browserName, osName, osVersion } from 'react-device-detect';
import { classNames } from '@utils';
import { useNavigation } from 'react-navi';
import Path from '@constants/paths';
import { isEmailValid, stringHasValue } from '@services/validation/ValidationRules';
import ContactUsApi from '@apis/contactus.api';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import {
  INVALID_EMAIL_VERBOSE,
  ISSUE_CATEGORY_REQUIRED,
  REASON_REQUIRED,
  FULL_NAME_REQUIRED,
} from '@constants/formField-constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { CONTACT_US__SUBMIT } from '@constants/ga-events.constants';
import { Button, Select, Input, TextArea } from '@ftdr/blueprint-components-react';
import {
  MediaUploadMicroFrontend,
  FileUpload,
  CustomError,
} from '@ftdr/media-upload-micro-frontend';
import msgs from '@app/locales/en';

const REASON = {
  LOGIN: 'Login',
  ORDER: 'Order Management',
  PAYMENTS: 'Payments',
  UPDATE: 'Update Profile or Office',
  WARRANTYLINK: 'WarrantyLink',
  PLACEHOLDER: 'Select One',
};

const reasonList = [
  { value: REASON.LOGIN },
  { value: REASON.ORDER },
  { value: REASON.PAYMENTS },
  { value: REASON.UPDATE },
  { value: REASON.WARRANTYLINK },
];

const MAXFILES = 3;
const ACCEPTED_FILE_TYPES =
  'image/*, application/msword, application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
// const ACCEPTED_FILE_TYPES = "*.jpg, *.jpeg, *.gif, *.tiff, *.bmp, *.png, *.pdf, *.doc, *.docx, *.txt"
const EXPLANATION_MAX_LENGTH = 4000;
const FULL_NAME_MAX_LENGTH = 50;

interface IContactUsProps {
  email: string;
  roleType: string;
  fullName: string;
  isAuthed: boolean;
  guestToken?: string;
  contractId?: string;
  topic?: string;
  emailText?: string;
}

const ContactUsTemplate: React.FC<IContactUsProps> = (props: IContactUsProps) => {
  const brand = getBrand();
  const navigation = useNavigation();

  const [email, setEmail] = useState(props.email);
  const [additionalEmail, setAdditionalEmail] = useState('');
  const [fullName, setFullName] = useState(props.fullName);
  const [reason, setReason] = useState(null);
  const [details, setDetails] = useState('');
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [showIssueCategoryError, setShowIssueCategoryError] = useState(false);
  const [showExplainIssueError, setShowExplainIssueError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showAdditionalEmailError, setShowAdditionalEmailError] = useState(false);
  const [showFullNameError, setShowFullNameError] = useState(false);
  const { addErrorToQueue } = useGlobalAlert();
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setEmail(props.email);
    setSelected(reasonList.filter((listElement) => listElement.value === props.topic)[0]);
    setReason(reasonList.filter((listElement) => (listElement.value === props.topic)[0]));
    setDetails(props.emailText);
  }, [props.email, props.emailText, props.topic]);

  useEffect(() => {
    setFullName(props.fullName);
  }, [props.fullName]);

  const isEmailInputDisabled = (): boolean => {
    return props.email && props.email.length > 0;
  };

  const handleFaqClick = () => {
    navigation.navigate(Path.FAQ);
  };

  const canSubmit = (): boolean => {
    return (
      email.length > 0 &&
      isEmailValid(email) &&
      reason !== null &&
      details.length > 0 &&
      stringHasValue(fullName) &&
      (additionalEmail.length === 0 || isEmailValid(additionalEmail))
    );
  };

  const setErrorMessages = () => {
    if (!isEmailValid(email)) {
      setShowEmailError(true);
    }
    if (!reason) {
      setShowIssueCategoryError(true);
    }
    if (details.length <= 0) {
      setShowExplainIssueError(true);
    }
    if (!stringHasValue(fullName)) {
      setShowFullNameError(true);
    }
    if (!isEmailValid(additionalEmail) && additionalEmail.length > 0) {
      setShowAdditionalEmailError(true);
    }
  };

  const handleSelect = (selectedReason: any) => {
    setReason(selectedReason);
    setSelected(selectedReason);
    setShowIssueCategoryError(false);
  };

  const handleSubmit = (event) => {
    if (!canSubmit()) {
      setErrorMessages();
      event.preventDefault();
      return;
    }

    console.log('submitting contact form');

    const browser = `${browserName} v.${browserVersion}`;
    const os = `${osName} v.${osVersion}`;

    const formData = new FormData();
    formData.append('IssueCategory', reason.value);
    formData.append('Email', email);
    formData.append('ExplainYourIssue', details);
    formData.append('Browser', browser);
    formData.append('Device', os);
    formData.append('UserRole', props.roleType);
    formData.append('AdditionalEmail', additionalEmail);
    formData.append('FullName', fullName);

    let i = 1;
    uploadFiles.forEach((uploadFile) => {
      const key = `File${i.toString()}`;
      formData.append(key, uploadFile.file, uploadFile.file.name);
      i += 1;
    });

    fireGAEvent(CONTACT_US__SUBMIT(props.roleType, reason.value));
    ContactUsApi.submit(formData, props.guestToken).then((result) => {
      if (result === true) {
        navigation.navigate(Path.ContactUsSuccess);
      } else {
        addErrorToQueue(msgs.EMAIL_SENDING_ERROR);
        console.error('sending contact us email failed');
      }
    });

    event.preventDefault();
    /*
     * TODO: do we need to add a spinner (or other) so the submit button
     *       cannot be clicked twice before the backend returns?
     */
  };

  const handleMediaUploadFailed = (failedFiles: FileUpload[]) => {
    if (failedFiles && failedFiles.length > 0) {
      console.error('upload of files failed', failedFiles);
    }
  };

  const validateUploadFiles = (files: any): CustomError | null | undefined => {
    const nNewFiles = files.length;
    const nExistingFiles = uploadFiles.length;
    if (nNewFiles + nExistingFiles > MAXFILES) {
      return {
        acceptedFiles: null,
        errorMessages: [`Too many files. Maximum number: ${MAXFILES}`],
      };
    }
    return null;
  };

  const handleMediaUpdateSuccess = (files: FileUpload[]) => {
    console.log('uploaded successfully ', files);

    if (files) {
      const previousUploadFiles = uploadFiles;
      let update = false;

      if (previousUploadFiles.length !== files.length) {
        update = true;
      } else {
        previousUploadFiles.forEach((id) => {
          if (!files.some((el) => el.fileId === id.fileId)) {
            update = true;
          }
        });
      }

      if (update === true) {
        setUploadFiles(files);
        if (files.length >= MAXFILES) {
          setIsUploadDisabled(true);
        } else {
          setIsUploadDisabled(false);
        }
      }
    }
  };

  return (
    <div id="contact-us" className="container py-10">
      <h1 className="h3">Contact Us</h1>
      <form id="contact-us-form" className="card my-8 px-8 py-8" onSubmit={handleSubmit}>
        <div className="h3">Submit a support request</div>
        <div id="contact-us-faq">
          Did you check the{' '}
          <u>
            <a id="contact-us-faq-url" onClick={handleFaqClick}>
              FAQ
            </a>
          </u>
          ? Your question may be answered there.
        </div>
        <div className="text-sm">
          <div id="contact-us-issue-category" className="font-bold pt-4">
            <span
              className={classNames([brand === 'hsa' ? 'text-primary-300' : 'text-secondary-400'])}
            />
          </div>
          <Select
            className="max-w-1/3"
            formField={true}
            id="contact-reason-select"
            label="Issue Category"
            placeholder={REASON.PLACEHOLDER}
            options={reasonList}
            selected={selected}
            onSelect={handleSelect}
            error={showIssueCategoryError ? ISSUE_CATEGORY_REQUIRED : null}
          />
          <div id="contact-us-fullname-title" className="font-bold pt-4">
            <span
              className={classNames([brand === 'hsa' ? 'text-primary-300' : 'text-secondary-400'])}
            />
          </div>
          <Input
            className="max-w-1/3"
            formField={true}
            id="contact-us-fullname-input"
            disabled={props.isAuthed}
            label="Name"
            placeholder="Enter Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            onBlur={(e) => setShowFullNameError(!stringHasValue(e.target.value))}
            showClearButton
            maxLength={FULL_NAME_MAX_LENGTH}
            error={showFullNameError ? FULL_NAME_REQUIRED : null}
          />
          <div id="contact-us-email-title" className="font-bold pt-4">
            <span
              className={classNames([brand === 'hsa' ? 'text-primary-300' : 'text-secondary-400'])}
            />
          </div>
          <Input
            className="max-w-1/3"
            formField={true}
            id="contact-us-email-input"
            disabled={isEmailInputDisabled()}
            label={props.isAuthed ? 'Account Email address' : 'Email address'}
            placeholder="user@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => setShowEmailError(!isEmailValid(e.target.value))}
            showClearButton
            error={showEmailError ? INVALID_EMAIL_VERBOSE : null}
          />
          {props.isAuthed && (
            <>
              <div id="contact-us-additional-email-title" className="font-bold pt-4">
                <span
                  className={classNames([
                    brand === 'hsa' ? 'text-primary-300' : 'text-secondary-400',
                  ])}
                />
              </div>
              <Input
                className="max-w-1/3"
                formField={true}
                id="contact-us-additional-email-input"
                label="Additional Email Address"
                placeholder="user@example.com"
                value={additionalEmail}
                onChange={(e) => setAdditionalEmail(e.target.value)}
                onBlur={(e) =>
                  setShowAdditionalEmailError(
                    !isEmailValid(e.target.value) && e.target.value.length > 0,
                  )
                }
                showClearButton
                error={showAdditionalEmailError ? INVALID_EMAIL_VERBOSE : null}
              />
            </>
          )}
          <div id="contact-us-explanation-title" className="font-bold pt-4">
            <span
              className={classNames([brand === 'hsa' ? 'text-primary-300' : 'text-secondary-400'])}
            />
          </div>
          <TextArea
            formField={true}
            id="contact-us-explanation-input"
            label="Please explain your issue"
            value={details}
            placeholder="Share details to make it easier for us to help you."
            rows={7}
            textLength={{
              current: details?.length,
              max: EXPLANATION_MAX_LENGTH,
            }}
            onChange={(e) => setDetails(e.target.value)}
            onBlur={(e) => setShowExplainIssueError(e.target.value.length <= 0)}
            error={showExplainIssueError ? REASON_REQUIRED : null}
          />
          <div id="contact-us-upload-title" className="pt-4 pb-2">
            <b>Upload a screenshot of the issue</b> (optional)
          </div>
          <div className="media-upload-format">
            {/* Wiring up the Media Upload is covered in a separate story, Anthony M. is working on it */}
            <MediaUploadMicroFrontend
              id="contact-us-upload-microfrontend"
              className="height: 100%"
              formFieldClassName="height: 100%"
              baseURL={null}
              token={null}
              isLocalOnly={true}
              label=""
              maxFiles={MAXFILES}
              multiple={true}
              dragAndDrop={true}
              formField={true}
              accept={ACCEPTED_FILE_TYPES}
              disabled={isUploadDisabled}
              customValidator={validateUploadFiles}
              onMediaFailedErrors={handleMediaUploadFailed}
              onMediaUpdate={handleMediaUpdateSuccess}
            />
          </div>
          <div className="media-upload-format py-6">
            <Button
              width="full"
              id="submit-contact-us"
              type="submit"
              disabled={false}
              size="medium"
              label="Submit Request"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsTemplate;
