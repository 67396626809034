import React, { useState } from 'react';
import { classNames } from '@utils';

// Components
import ModalCoverage from '@components/modal/ModalCoverage';
import {
  Button,
  IconCheckCircledCheckedFilled,
  IconCheckCircledUnchecked,
  Panel,
  Text,
} from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';

const CardPlan = ({ isDrawerView = false, isLoading = false, ...props }) => {
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <label className={classNames(['card--plan block relative h-full', props.className || ''])}>
        <input
          id={`${props.id}--radio`}
          type="radio"
          name="plan"
          value={props.heading}
          className="hidden"
          onClick={isLoading ? undefined : props.onClick}
          checked={props.checked}
        />

        <Panel
          backgroundColor="white"
          rounded="md"
          padding="none"
          border={props.isActive ? '2' : '0'}
          shadow={true}
          className={classNames(['flex flex-col overflow-hidden relative h-full cursor-pointer'])}
        >
          <div className="py-6 px-4">
            <div className="flex items-center">
              {isLoading ? (
                <Skeleton circle={true} height={16} width={16} />
              ) : props.isActive ? (
                <IconCheckCircledCheckedFilled size={24} color="interactive" />
              ) : (
                <IconCheckCircledUnchecked size={24} color="gray" />
              )}

              <Text
                id={`${props.id}--label`}
                variant={isDrawerView ? 'heading-05' : 'heading-04'}
                color="accent"
                className={classNames(['relative top-px pl-2 my-0'])}
              >
                {isLoading ? <Skeleton width={200} /> : <>{props.heading}</>}
              </Text>
            </div>

            <div className="pt-2 px-4">
              {isLoading ? (
                <Skeleton width={160} />
              ) : (
                <Button
                  id={`${props.id}--coverage-link`}
                  variant="ghost"
                  size="small"
                  label="See All Coverage"
                  onClick={(e) => setIsModalActive(true) && e.stopPropagation()}
                />
              )}
            </div>
          </div>

          <div className="border-t border-gray-300 py-4 px-4">
            <div
              className={classNames(['flex flex-col space-y-3', isDrawerView ? 'px-2' : 'px-6'])}
            >
              <div className="card__price flex items-baseline space-x-1">
                <Text variant="heading-05" className="my-0" id={`${props.id}--price`}>
                  {isLoading ? <Skeleton width={64} /> : <>${props.price}</>}
                </Text>
              </div>
              <div>
                <Text variant="helper-text">
                  {isLoading ? <Skeleton width={128} /> : <>Trade Service Call Fee (TSCF):</>}
                </Text>
                <Text variant="helper-text" id={`${props.id}--service_fee`}>
                  {isLoading ? (
                    <Skeleton width={32} />
                  ) : (
                    <>${props.serviceFee} per service request.</>
                  )}
                </Text>
              </div>
            </div>
          </div>
        </Panel>
      </label>

      <ModalCoverage
        id={`${props.id}--modal`}
        isActive={isModalActive}
        onClose={() => setIsModalActive(false)}
        plan={props.heading}
        covered={props.details}
      />
    </>
  );
};

export default CardPlan;
