import React, { useState } from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import { isEmailValid } from '@services/validation/ValidationRules';
import ShareDocument from './subcomponents/ShareDocument';
import Modal from './Modal';

const ModalShareDocument = (props) => {
  const [email, setEmail] = useState('');

  const actions = [
    <Button
      key="submit"
      id="share-doc-send-email-button"
      size="small"
      label="Send Email"
      onClick={() => props.handleSendEmail(email)}
      disabled={!isEmailValid(email)}
    />,
    <Button
      key="cancel"
      id="share-doc-cancel-button"
      autoFocus={true}
      variant="outlined"
      size="small"
      label="Cancel"
      onClick={props.hideDialog}
      disabled={false}
    />,
  ];

  return (
    <Modal
      id={props.id || 'share-document-modal'}
      heading={props.header || 'Share'}
      isActive={props.isActive}
      onClose={props.hideDialog}
      actions={actions}
    >
      {props.isActive && <ShareDocument setEmail={setEmail} {...props} />}
    </Modal>
  );
};

export default ModalShareDocument;
