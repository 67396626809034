import * as app from '@app/models';
import { toTitleCase, alphabetizeBy } from '@helpers/utils';
import { officeTypeFullFormDictionary, officeTypeDictionary } from '@constants/dictionaries';
import {
  AgentList,
  AgentSearchRequest,
  OfficeTypeCode,
  Agent,
  AgentType,
  AgentSearchResponse,
  AgentSearchRecord,
  AgentMatchSearchRequest,
  ExactMatchSearchResponse,
  PossibleMatchSearchResponse,
  AgentOfficeMoveRequest,
  AgentAuditRequest,
  AgentAuditResponse,
} from './models';
import { BaseAPI } from './_api';
import { captureException as sentryCaptureException } from '@sentry/react';

class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public agentsList = [];

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }
  /** Return active agents for a given office */
  public async getActiveAgentsByOffice(office: app.Office): Promise<app.Agent[]> {
    const params: AgentSearchRequest = {
      officeId: office.id,
      officeType: getOfficeTypeCode(office.meta.type),
      activeInactive: 'A',
    };
    return this.axios
      .get<any, AgentList>(`${this.getHost()}/agent/search`, { params })
      .then((res) => res.agentsList.map<app.Agent>((agent) => mapToAppAgent(agent, office)));
  }

  // TODO: The two models Agent and AgentSearchRecord are the same. Should convert these to be a single model after making sure nothing else breaks
  public searchAgents(reqObj: AgentSearchRequest, context?): Promise<AgentSearchResponse> {
    const params: AgentSearchRequest = {};
    Object.assign(params, reqObj); // create copy of reqObj so that the original value doesn't get overwritten and can be used for debugging

    params.officeType = officeTypeDictionary[params.officeType];
    if (!params.officeType)
      sentryCaptureException(
        new Error(
          `invalid agent search office type: ${params.officeType}. value before mapping: ${reqObj.officeType}`,
        ),
        context,
      );

    return this.axios
      .get<any, AgentSearchResponse>(`${this.getHost()}/agent/search`, {
        params,
      })
      .then((res) => {
        if (res && res.agentsList) {
          res.agentsList = res.agentsList
            .map(translateAgentSearchResponse)
            .sort(alphabetizeBy((agent) => `${agent.firstName}${agent.lastName}`));
        }
        return res;
      })
      .catch((err) => {
        // TODO: proper error handling. For now return empty list, as this is only being used in searches
        console.error(err);
        return { agentsList: [], errors: [] };
      });
  }

  /** Create a new agent record. Returns the agent record with an agent id */
  public async createAgent(agent: AgentSearchRecord): Promise<AgentSearchRecord> {
    // Perform pre-translations
    agent = {
      ...agent,
      agentType: officeTypeFullFormDictionary[agent.agentType], // translate agent type just in case
      // @ts-expect-error TODO: added new field arbitrarily
      originalAgentType: agent.agentType,
      archivedOfficeID: agent.archivedOfficeID || [], // We shouldn't be creating archived agents
      active: true, // We should only be creating active agents
      narID: agent.narID || '', // Nar ID is optional
      phoneNumbers: agent.phoneNumbers.map((p) => ({
        ...p,
        phoneType: p.phoneType || 'Mobile', // We should only have 1 phone type for now
        extension: p.phoneType === 'Mobile' ? '' : '1', // We should only have US agents, extension is always 1 for non Mobile numbers
      })),
    };

    // TODO: This will be kept here until the logic is added to closing and cooperating
    // return new Promise<AgentSearchRecord>((resolve) => resolve({...agent, realEstateAgentID: '111'}))

    return this.axios
      .post<any, AgentSearchRecord>(`${this.getHost()}/agent/create`, agent)
      .then((res) => {
        if (res) {
          res = translateAgentSearchResponse(res);
        }
        return res;
      });
  }

  public async getExactMatch(agent: AgentMatchSearchRequest): Promise<ExactMatchSearchResponse> {
    return this.axios
      .post<any, ExactMatchSearchResponse>(`${this.getHost()}/agent/find-exact-match`, agent)
      .then((res) => {
        return res;
      });
  }

  public async createAudit(agent: AgentAuditRequest): Promise<AgentAuditResponse> {
    return this.axios.post<any, AgentAuditResponse>(
      `${this.getHost()}/agent/agent-create-audit`,
      agent,
    );
  }

  public async getPossibleMatch(
    agent: AgentMatchSearchRequest,
  ): Promise<PossibleMatchSearchResponse> {
    return this.axios
      .post<any, PossibleMatchSearchResponse>(`${this.getHost()}/agent/find-possible-match`, agent)
      .then((res) => {
        return res;
      });
  }

  public async moveOffice(req: AgentOfficeMoveRequest): Promise<boolean> {
    return this.axios.post<any, boolean>(`${this.getHost()}/agent/move-office`, req).then(() => {
      return true;
    });
  }
}

const AgentApi = new API();
export default AgentApi;

export function getOfficeTypeCode(officeType: app.OfficeType): OfficeTypeCode {
  switch (officeType) {
    case undefined:
      return undefined;
    case app.OfficeType.RealEstate:
      return OfficeTypeCode.RealEstate;
    case app.OfficeType.ClosingCompany:
      return OfficeTypeCode.ClosingCompany;
    default:
      throw TypeError(`Unknown office type: ${officeType}`);
  }
}

/** Perform any translations on the data, such as titlecase */
function translateAgentSearchResponse(agent: AgentSearchRecord): AgentSearchRecord {
  if (agent) {
    agent.firstName = toTitleCase(agent.firstName);
    agent.lastName = toTitleCase(agent.lastName);
  }
  return agent;
}

/** Perform any translations on the data, such as titlecase */
export function translateAgent(agent: Agent): Agent {
  if (agent) {
    agent.firstName = toTitleCase(agent.firstName);
    agent.lastName = toTitleCase(agent.lastName);
  }
  return agent;
}

function mapToAppAgent(agent: Agent, office?: app.Office): app.Agent {
  agent = translateAgent(agent);
  return {
    office,
    id: agent.realEstateAgentID,
    name: `${agent.firstName.trim()} ${agent.lastName.trim()}`.trim(),
    contact: {
      email: agent.email,
      phone: agent.phoneNumbers.map((phone) => {
        return {
          type: phone.phoneType,
          number: phone.phoneNumber,
        };
      }),
    },
    details: {
      narId: agent.narID,
    },
    meta: {
      type: getAppOfficeType(agent.agentType),
    },
  };
}

export function getAppOfficeType(agentType: AgentType): app.OfficeType {
  switch (agentType) {
    case undefined:
      return undefined;
    case AgentType.RealEstate:
      return app.OfficeType.RealEstate;
    case AgentType.ClosingCompany:
      return app.OfficeType.ClosingCompany;
    default:
      throw TypeError(`Unknown agent type: ${agentType}`);
  }
}
