import React from 'react';
import Drawer from '@components/drawer/Drawer';
import QuickLinkRequestService from '@components/drawer/subcomponents/QuickLinkRequestService';

const DrawerQLRequestService = (props) => {
  return (
    <Drawer {...props} className="w-224" useDefaultWidth={false}>
      {props.isActive && (
        <QuickLinkRequestService isActive={props.isActive} onClose={props.onClose} />
      )}
    </Drawer>
  );
};

export default DrawerQLRequestService;
