import React from 'react';
import CardFilterBar, { IconTypes } from '@components/card/CardFilterBar';

interface SearchBarProps {
  searchText: string;
  onChange: (value: string) => void;
  placeHolder: string;
  showClearButton: boolean;
  idPrefix?: string;
  icon: IconTypes;
  outerCSS?: string;
}

const SearchBar: React.FC<SearchBarProps> = (props) => (
  <div className={`flex items-center flex-1 ${props.outerCSS}`}>
    <CardFilterBar
      id="orders-search--filter-container"
      idPrefix={props.idPrefix}
      // TODO: The results prop isn't used for this component?
      results={[]}
      className="w-full shadow-none border-2"
      inputLabel={null}
      showClearButton={props.showClearButton}
      placeHolder={props.placeHolder}
      onChange={props.onChange}
      filterText={props.searchText}
      icon={props.icon}
    />
  </div>
);

export default SearchBar;
