import React, { useContext, useEffect, useMemo, useState } from 'react';
import { classNames } from '@utils';
import CardNewOrder from '@components/card/CardNewOrder';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import { officeTypeDictionary, UserRoleType } from '@constants/dictionaries';
import validate from '@services/validation/ValidationRules';
import useForm from '@helpers/UseForm';
import AgentApi from '@apis/agent.api';

import FormOfficeSelection from '@app/components/form/FormOfficeSelection';
import {
  getProfileOfficesByType,
  updateOfficesOnSave,
  updateWorkedWithOfficesOnSave,
} from '@services/helpers/profile.offices.helper';
import ModalAddAgentToOffice from '@components/modal/ModalAddAgentToOffice';
import { selectSuggestion } from '@services/autosuggest/autosuggest';
import { ModalAgentVerification } from '@components/modal/ModalAgentVerification';
import { OfficeType } from '@app/models';
import ProfileContext from '../../context/ProfileContext';
import AgentAutosuggest from '../form/AgentAutosuggest';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  ADD_EXISTING_AGENT,
  ADD_NEW_AGENT,
  ORDER__OFFICE_AGENT_SETUP,
} from '@constants/ga-events.constants';
import {
  ACTION_EDIT,
  ACTION_SAVE,
  ACTION_UPDATE,
  AGENT_LABELS,
  ORDER_FORM_FIELDS,
  REALTOR_OWNED_FOR_INIT_AGENT_ROLES,
} from '@constants/newOrder-constants';
import {
  Checkbox,
  IconCheckCircledCheckedFilled,
  RadioGroup,
  Text,
} from '@ftdr/blueprint-components-react';
import { getFormRadioErrorId } from '@storybook/addon-links';
import ModalChoice from '@components/modal/ModalChoice';
import { getBrand } from '@helpers/brand.utils';
import { getConfig } from '@services/app.config.loader';

const representingValues = [
  {
    label: 'Buyer',
    value: 'buyer',
  },
  {
    label: 'Seller',
    value: 'seller',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

const FSBORadioValues = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const brand = getBrand();

const CardNewOrderAgentInfo = ({ isEditing, setIsEditing, ...props }) => {
  const {
    values,
    errors,
    handleBlur,
    handleSubmit: validateSubmission,
    setValues,
    setErrors,
  } = useForm(validate);
  const { profile, setProfile } = useContext(ProfileContext);
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || false);
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [isAddAgentModalActive, setIsAddAgentModalActive] = useState(false);
  const [isAgentVerificationActive, setIsAgentVerificationActive] = useState(false);
  const [valuesPristine, setValuesPristine] = useState();
  const [isSellersCoverageROModalActive, setIsSellersCoverageROModalActive] = useState(false);

  const [typeAheadAddress, setTypeAheadAddress] = useState([]);
  const [selectedAgent, changeSelectedAgent] = useState(props.values.initiatingAgent ?? '');
  const [agentHelpText, setAgentHelpText] = useState('');
  const [state, setState] = useState({});
  const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false);
  const [editAgentList, setEditAgentList] = useState([]);
  const [agentSelection, setAgentSelection] = useState(null);
  const [touched, setTouched] = useState(false);

  const [agentRepresentsOptionValues, setAgentRepresentsOptionValues] = useState(
    props.values.agentRepresents ?? {
      buyer: false,
      seller: false,
      both: false,
    },
  );
  const [FSBOOptionValue, setFSBOOptionValue] = useState(props.values.isFSBO ?? 'no');

  useEffect(() => {
    setState({ ...values });
    //set it once when values is available
    if (
      !valuesPristine &&
      Object.keys(values).length > 0 &&
      props.userType === UserRoleType.RealEstateAgent
    ) {
      setValuesPristine(values);
    }
  }, [values]);

  useEffect(() => {
    if (selectedAgent) {
      setValues({ ...values, initiatingAgent: selectedAgent });
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (values.isRealtorOwned && !!values.agentOffice?.franchiseCode) {
      setValues({ ...values, isRealtorOwned: false });
    }
  }, [values.initiatingOffice]);

  const onRepresentsOptionChange = (value) => {
    const event = {
      target: {
        name: value,
        value,
        checked: true,
      },
    };
    const update = {
      buyer: false,
      seller: false,
      both: false,
    };

    update[event.target.name] = event.target.checked;

    if (errors.agentRepresents) {
      delete errors.agentRepresents;
    }
    setAgentRepresentsOptionValues(update);

    const changes = {
      agentRepresents: update,
      representing: AGENT_LABELS[event.target.name],
    };

    //on agent login, the values is being cleared on 'clear info' click and the condition for saving agent info is not satisfied. Using the copy of values to reassign values
    if (props.userType === UserRoleType.RealEstateAgent) {
      setValues({ ...valuesPristine, ...changes });

      if (selectedAgent === '') {
        changeSelectedAgent(`${props.userDetails.firstName} ${props.userDetails.lastName}`);
      }
    } else {
      setValues({ ...values, ...changes });
    }

    return changes;
  };

  const onFSBOOptionChange = (value) => {
    setFSBOOptionValue(value);
    setValues({ ...values, isFSBO: value === 'yes' });
  };

  useEffect(() => {
    if (props.values) {
      setValues(props.values);
      if (props.values.agentRepresents) {
        setAgentRepresentsOptionValues({
          ...props.values.agentRepresents,
        });
      }
      if (props.values.initiatingAgent && selectedAgent === '') {
        changeSelectedAgent(props.values.initiatingAgent);
      }
    }

    // some additional checks for RE Agent user role, default certain office/agent related data
    if (props.userType === UserRoleType.RealEstateAgent && props.userDetails) {
      const officeDetail = props.userDetails.offices[0];
      // set values with defaults for the user role
      setValues({
        ...props.values, // initialize using what was in the props
        initiatingOffice: officeDetail.name,
        office: { id: officeDetail.id },
        agentOffice: officeDetail,
        agentlist: [],
        initiatingAgent: `${props.userDetails.firstName} ${props.userDetails.lastName}`,
        AgentId: props.userDetails.roleID,
      });
      changeSelectedAgent(`${props.userDetails.firstName} ${props.userDetails.lastName}`);
    }
  }, [isEditing, props.values]);

  useEffect(() => {
    if (state.initiatingOffice) {
      delete errors.initiatingOffice;
      setErrors({ ...errors });
    }
  }, [state.initiatingOffice]);

  useEffect(() => {
    if ((!values.agentList || values.agentList.length === 0) && editAgentList.length > 0) {
      setValues({
        ...values,
        agentlist: editAgentList,
      });
    }
  }, [editAgentList]);

  /** based on user role rules, update profile to include the defined office */
  const updateUserOffice = async (userOffice) => {
    if (
      officeTypeDictionary[props.userDetails.roleIDType] !== officeTypeDictionary['RE'] &&
      userOffice
    ) {
      await updateWorkedWithOfficesOnSave(profile, userOffice);
    }
    if (
      userOffice?.active &&
      (props.userDetails.roleIDType === UserRoleType.RealEstateAdmin ||
        props.userDetails.roleIDType === UserRoleType.Broker)
    ) {
      await updateOfficesOnSave(profile, userOffice, setProfile);
    }
  };

  const getActionVarForGA = () => {
    let action = ACTION_SAVE;

    if (props.isExistingOrder) {
      action = ACTION_UPDATE;
    }

    if (props.hasBeenSaved) {
      action = ACTION_EDIT;
    }
    return action;
  };

  async function handleSave(event) {
    const pendingErrors = validateSubmission(event);

    !selectedAgent.trim() ? setAgentHelpText('Initiating agent required.') : setAgentHelpText('');

    if (selectedAgent && !values.AgentId) {
      // open add agent box
      setIsAgentVerificationActive(true);
      return;
    }

    if (FSBOOptionValue === 'yes') {
      let submitValues;
      const officeID = `${getConfig('fsboOfficeID')}`;
      const agentID = `${getConfig('fsboAgentID')}`;

      submitValues = {
        ...values,
        office: {
          id: officeID,
          type: 'RE',
        },
        AgentId: agentID,
        agentRepresents: {
          both: true,
          buyer: false,
          seller: false,
        },
        representing: 'Both',
      };

      props.handleSubmit(submitValues);
      fireGAEvent(
        ORDER__OFFICE_AGENT_SETUP(
          getActionVarForGA(),
          AGENT_LABELS.both,
          props.hasBeenSaved,
          props.orderId,
        ),
      );

      toggleEditing();
      toggleCollapsed();
    }
    // checking is object empty for errors and values objects.
    else if (
      !Object.keys(pendingErrors).length &&
      Object.keys(values).length >= 5 &&
      selectedAgent.trim() &&
      agentRepresentsValue
    ) {
      let originator;

      if (values.agentRepresents.both) {
        originator = AGENT_LABELS.both;
      } else if (values.agentRepresents.buyer) {
        originator = AGENT_LABELS.buyer;
      } else if (values.agentRepresents.seller) {
        originator = AGENT_LABELS.seller;
      }

      // determine if office franchise change. only applicable if values had agentOffice to begin with.
      // would be used by NewOrder and EditOrder to determine if plan selection needs to be reset.
      const didOfficeFranchiseChange =
        props.values?.agentOffice &&
        values.agentOffice &&
        props.values.agentOffice.franchiseCode !== values.agentOffice.franchiseCode;

      let submitValues = { ...values, didOfficeFranchiseChange };

      props.handleSubmit(submitValues);
      fireGAEvent(
        ORDER__OFFICE_AGENT_SETUP(
          getActionVarForGA(),
          originator,
          props.hasBeenSaved,
          props.orderId,
        ),
      );
      toggleEditing();
      toggleCollapsed();

      if (props.updateAgentCardStatusForGATracking) {
        props.updateAgentCardStatusForGATracking();
      }
    } else {
      setIsCollapsed(false);
      setIsEditing(true);
    }
  }

  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  function toggleEditing() {
    if (!isEditing) {
      loadEditAgentList();
    } else {
      changeSelectedAgent('');
    }
    setFSBOOptionValue(props.values.isFSBO === true ? 'yes' : 'no');
    setIsEditing(!isEditing);
  }

  const data = {
    values,
    setAgentRepresentsOptionValues,
    setFSBOOptionValue,
    setValues,
    handleSubmit: handleSave,
    changeSelectedAgent,
    setErrors,
  };

  function focusOfficeSelect() {
    setShowOfficeSuggestions(true);
  }

  function updateOfficeFilter() {
    // Clear the selected agent when the selected office changes
    changeSelectedAgent('');
    setEditAgentList([]);
    setValues({ ...values, agentlist: null });
  }

  function blurOfficeSelect(event) {
    if (
      !event.relatedTarget ||
      (event.relatedTarget.id !== 'officeSuggestionDropdown' &&
        event.relatedTarget.id !== 'wb_openSearchDrawer')
    ) {
      handleBlur(event);
      setShowOfficeSuggestions(false);
    }
  }

  function handleSearchOffice(event) {
    setShowOfficeSuggestions(false);
    setIsDrawerActive(event);
  }

  async function handleOfficeSelect(offices) {
    setShowOfficeSuggestions(false);
    const office = offices[0];

    // save the office to the user immediately
    updateUserOffice(office);

    let agentsList = [];
    // This closes the selection dropdown if it's opened
    try {
      agentsList = await getAgentsForOffice(office);
    } catch (err) {
      // ignore, use empty agents list instead
      console.error(err);
      console.log('could not get agents for office, using empty list instead.');
    }
    setValues({
      ...values,
      initiatingOffice: office.name,
      agentlist: agentsList,
      agentOffice: office,
      office: { id: office.id },
    });

    setIsDrawerActive(false);

    // Clear the selected agent when the selected office changes
    changeSelectedAgent('');
  }

  async function loadEditAgentList() {
    if (
      !props.disableEdit &&
      props.values?.office &&
      (!values.agentList || values.agentList.length === 0)
    ) {
      try {
        const agentsList = await getAgentsForOffice({
          id: props.values.office.id,
          type: officeTypeDictionary['RealEstate'],
        });
        setEditAgentList(agentsList);
      } catch (err) {
        // ignore, use empty agents list instead
        console.err(err);
        console.log('failed to get agents for office. Using empty agent list instead.');
      }
    } else {
      console.log('agent list not updated');
      console.log({
        disableEdit: props.disableEdit,
        office: props.values?.office,
        values: values.agentList,
      });
    }
  }

  async function getAgentsForOffice(office) {
    let agentsList = [];
    if (office && office.id !== '') {
      try {
        // getting list of agent as per selected office
        const response = await AgentApi.searchAgents(
          {
            officeId: office.id,
            officeType: officeTypeDictionary[office.type],
            activeInactive: 'A',
          },
          {
            tags: { source: 'CardNewOrderAgentInfo#getAgentsForOffice' },
          },
        );
        agentsList = response?.agentsList;
      } catch (err) {
        // ignore, use empty agents list instead
        console.err(err);
        console.log('failed to get agents for office. Using empty agent list instead.');
      }
    }
    return agentsList;
  }

  /** When adding agent to office, we want to update the agent list to include the new agent, as well as select the agent */
  function addNewAgentToOffice(agent) {
    // Add the agent to the list of agents
    const updatedValues = {
      ...values,
      agentlist: [...values.agentlist, agent],
    };
    const ddOption = `${agent.firstName} ${agent.lastName}`;
    setAgentSelection(ddOption);
    setTouched(false);
    // Make the selection (done this way so that we are sync up with the auto-suggest logic)
    selectSuggestion(
      undefined,
      { suggestion: agent },
      changeSelectedAgent,
      setValues,
      updatedValues,
    );
  }

  const onRealtorOwnedSelectionChange = (e) => {
    // ARE-11108: Display a modal if there is seller's coverage from the existing quote
    setIsSellersCoverageROModalActive(props.quoteHasSellersCoverage && e.target.checked);

    let changes = {};

    if (e.target.checked) {
      changes = onRepresentsOptionChange('buyer');
    }

    if (props.userType === UserRoleType.RealEstateAgent) {
      //if checked in enabled/disabled state, the buyer radio button is selected
      changes = onRepresentsOptionChange('buyer');
      setValues({ ...valuesPristine, ...changes, isRealtorOwned: e.target.checked });
      if (selectedAgent === '') {
        changeSelectedAgent(`${props.userDetails.firstName} ${props.userDetails.lastName}`);
      }
    } else {
      setValues({ ...values, ...changes, isRealtorOwned: e.target.checked });
    }
  };

  const getROTextLabel = () => {
    if (REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(props.userDetails.roleIDType)) {
      return `This home warranty is for ${props.realtorOwnedOwnerName || 'the initiating agent'}`;
    } else {
      return `This home warranty is for ${props.realtorOwnedOwnerName || 'myself'}`;
    }
  };

  const onConfirmSellersCoverageRO = () => {
    setIsSellersCoverageROModalActive(false);
    props.onConfirmSellersCoverageRO();
  };

  /** returns the represents value string from the radio button options */
  const agentRepresentsValue = useMemo(() => {
    const result = Object.keys(agentRepresentsOptionValues).find(
      (k) => agentRepresentsOptionValues[k] === true,
    );
    return result?.length && result.length > 0 ? result : undefined;
  }, [agentRepresentsOptionValues]);

  return (
    <>
      <CardNewOrder
        title="Agent Information"
        isEditing={isEditing}
        toggleEditing={toggleEditing}
        isCollapsed={isCollapsed}
        toggleCollapsed={toggleCollapsed}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        onCancel={props.onCancel}
        data={data}
        cancelModal={props.cancelModal}
      >
        {!isEditing ? (
          <>
            {values.isFSBO ? (
              <div>This is For Sale By Owner (FSBO)</div>
            ) : (
              <>
                <div
                  className={classNames([
                    'sm:flex sm:flex-1 xs-max:pr-10',
                    props.isSmall && 'sm:-mx-4',
                    isCollapsed && 'xs-max:hidden',
                  ])}
                >
                  <div className="sm:w-1/3 sm:px-4">
                    <Text variant="label">{ORDER_FORM_FIELDS.INITIATING_OFFICE}</Text>
                    <Text id="card-new-order-agent-info__initiating-office-text">
                      {state.initiatingOffice}
                    </Text>
                  </div>

                  <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                    <Text variant="label">{ORDER_FORM_FIELDS.INITIATING_AGENT}</Text>
                    <Text id="card-new-order-agent-info__initiating-agent-text">
                      {state.initiatingAgent}
                    </Text>
                  </div>

                  <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                    <Text variant="label">{ORDER_FORM_FIELDS.REPRESENTING}</Text>
                    <Text id="card-new-order-agent-info__representing-text">
                      {props.values?.representing}
                    </Text>
                  </div>
                </div>
                <div className={classNames(['mt-2', !props.isSmall && 'mx-4'])}>
                  <Text id="card-new-order-agent-info__realtor-owned-text">
                    {state.isRealtorOwned && (
                      <div className="flex items-center space-x-2">
                        <IconCheckCircledCheckedFilled color="interactive" />
                        <Text>{getROTextLabel()}</Text>
                      </div>
                    )}
                  </Text>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex flex-wrap">
            {props.fsboOptionEnabled && (
              <div className="w-full">
                <div className="flex-1 py-2 -mb-1 sm:mt-1">
                  <RadioGroup
                    formField={true}
                    id="wb_wb_FSBO-Represent"
                    name="FSBORepresents"
                    label={'Is This For Sale By Owner (FSBO)?'}
                    required={true}
                    orientation="horizontal"
                    onChange={onFSBOOptionChange}
                    disabled={props.isExistingOrder}
                    radios={FSBORadioValues}
                    value={FSBOOptionValue}
                  />
                </div>
              </div>
            )}
            {props.userType !== UserRoleType.RealEstateAgent && FSBOOptionValue !== 'yes' && (
              <>
                <div className="w-full sm:w-1/2 sm:pr-3">
                  <FormOfficeSelection
                    id="wb_initiatingOfficeTypeahead"
                    idNoDropdown="wb_initOfc"
                    name="initiatingOffice"
                    label="Initiating Office"
                    officeList={getProfileOfficesByType(props.userDetails, OfficeType.RealEstate)}
                    selectedOffice={state.initiatingOffice}
                    setIsDrawerActive={(e) => handleSearchOffice(e)}
                    handleOfficeSelect={(value) => handleOfficeSelect(value)}
                    updateOfficeFilter={(e) => updateOfficeFilter(e)}
                    focusOfficeSelect={(e) => focusOfficeSelect(e)}
                    handleBlur={(e) => blurOfficeSelect(e)}
                    errors={errors}
                    isDrawerActive={isDrawerActive}
                    showOfficeSuggestions={showOfficeSuggestions}
                  />
                </div>

                <div className="w-full sm:w-1/2">
                  <div id="wb_initAgent" className="w-full">
                    <AgentAutosuggest
                      id="wb_initAgentValue"
                      isRequired={true}
                      typeAheadAddress={typeAheadAddress}
                      setTypeAheadAddress={setTypeAheadAddress}
                      selectedAgent={selectedAgent}
                      changeSelectedAgent={changeSelectedAgent}
                      agentHelpText={agentHelpText}
                      setAgentHelpText={setAgentHelpText}
                      agentOffice={state.initiatingOffice}
                      agentlist={state.agentlist}
                      placeholder="Initiating Agent"
                      setValues={setValues}
                      values={values}
                      addAgentEnabled={true}
                      setIsAddAgentModalActive={setIsAddAgentModalActive}
                      isDisabled={!values.office?.id}
                      selection={agentSelection}
                      touched={touched}
                      setTouched={setTouched}
                      noResultsFoundHelperText="Try searching for a different agent or add a new agent"
                    />
                  </div>
                </div>
              </>
            )}
            {FSBOOptionValue !== 'yes' && (
              <div className="flex flex-auto sm-max:flex-col">
                <div className="flex-1 py-2 -mb-1 sm:mt-1">
                  <RadioGroup
                    formField={true}
                    formFieldMessageId={getFormRadioErrorId('wb_wb_AgentInfo-Represent')}
                    id="wb_wb_AgentInfo-Represent"
                    name="agentRepresents"
                    label={
                      props.userType !== UserRoleType.RealEstateAgent
                        ? ORDER_FORM_FIELDS.REPRESENTING__QUESTION
                        : ORDER_FORM_FIELDS.REPRESENTING__QUESTION_SELF
                    }
                    required={true}
                    error={errors.agentRepresents}
                    orientation="horizontal"
                    onChange={onRepresentsOptionChange}
                    disabled={props.isExistingOrder || values.isRealtorOwned}
                    radios={representingValues}
                    value={agentRepresentsValue}
                  />
                </div>
                {props.realtorOwnedSelectionEnabled && (
                  <div className="flex-1 py-2 -mb-1 md:mt-6">
                    <Checkbox
                      id="realtor-owned-checkbox"
                      checked={values.isRealtorOwned}
                      label={getROTextLabel()}
                      onChange={onRealtorOwnedSelectionChange}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </CardNewOrder>

      <DrawerOfficeSearch
        id="card-new-order-agent-info__drawer-office-search"
        usaStates={props.usaStates}
        typeCode="RE"
        isActive={isDrawerActive}
        onClose={() => setIsDrawerActive(false)}
        userOfficeIDs={props.setUserOfficeIDs}
        onSubmit={handleOfficeSelect}
      />

      <ModalAddAgentToOffice
        id="modal-add-initiating-agent"
        fuzzyCheckEnabled={true}
        sourceFlow={props.flow}
        isActive={isAddAgentModalActive}
        office={values.agentOffice}
        agents={state.agentlist}
        onCancel={() => setIsAddAgentModalActive(false)}
        onSuccess={(agent) => {
          addNewAgentToOffice(agent);
          if (agent.createAgentMeta?.existingAgent === 'Y') {
            fireGAEvent(ADD_EXISTING_AGENT('Initiating', agent.realEstateAgentID));
          } else if (agent.createAgentMeta?.existingAgent === 'N') {
            fireGAEvent(ADD_NEW_AGENT('Initiating', agent.realEstateAgentID));
          }
          setIsAddAgentModalActive(false);
        }}
      />

      <ModalAgentVerification
        id="modal-agent-verification"
        isActive={isAgentVerificationActive}
        onClose={() => setIsAgentVerificationActive(false)}
        selectedAgent={selectedAgent}
        agentRole="initiating"
        agentList={values.agentlist}
        office={values.agentOffice}
        setTypeAheadAddress={setTypeAheadAddress}
        setIsAddAgentModalActive={setIsAddAgentModalActive}
        onSelectAgent={(agent) =>
          selectSuggestion(undefined, { suggestion: agent }, changeSelectedAgent, setValues, values)
        }
      />

      <ModalChoice
        id="modal-sellers-coverage-realtor-owned"
        isActive={isSellersCoverageROModalActive}
        onClose={() => setIsSellersCoverageROModalActive(false)}
        onConfirm={onConfirmSellersCoverageRO}
        yesText="Continue"
        noText="Cancel"
        heading="Realtor Owned Contract Option"
      >
        <>
          Seller's coverage is not available for Realtor Owned plan. Click continue to remove
          seller's coverage and continue with Realtor Owned plan.
        </>
      </ModalChoice>
    </>
  );
};

export default CardNewOrderAgentInfo;
