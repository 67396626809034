import { Link, Text } from '@ftdr/blueprint-components-react';
import React from 'react';

const ElectronicsCoverage = () => {
  return (
    <div className="flex flex-col space-y-4">
      <Text>
        The Electronics Protection Plan, including coverage and servicing, is offered to our
        customers by Allstate Protection Plans.
      </Text>
      <Text variant="caption">
        The Electronics Protection Plan provides extended warranty protection for:
      </Text>
      <ul className="list-disc ml-5 text-sm">
        <li>TVs</li>
        <li>Computers</li>
        <li>Laptops</li>
        <li>Tablets</li>
        <li>Printers</li>
        <li>Home Theater Systems</li>
        <li>Smart Home Products</li>
        <li>Headphones</li>
        <li>Video game consoles (including controllers)</li>
        <li>And more!</li>
      </ul>

      <Text variant="caption">It includes:</Text>
      <ul className="list-disc ml-5 text-sm">
        <li>
          Coverage for an unlimited number of electronics under one plan with no registrations or
          receipts required
        </li>
        <li>Coverage for mechanical and electrical failures due to normal wear and tear</li>
        <li>Power surge protection</li>
        <li>Defects and workmanship and materials</li>
        <li>Premium tech support</li>
        <li>Easy online claims</li>
      </ul>

      <Text>Plan has a $2,000 per claim limit and a $5,000 aggregate plan limit.</Text>

      <Text>
        Members place claims directly with Allstate Protection Plans and pay their service fee
        directly to them at the time a claim is placed.
      </Text>

      <Text>
        Coverage only available with one year home warranty contracts. Do not add plan to contracts
        longer than one year
      </Text>

      <div>
        <Link
          href="http://allstateprotectionplans.com/terms"
          className="text-primary-400"
          target="_blank"
        >
          Terms and Conditions
        </Link>
      </div>
    </div>
  );
};

export default ElectronicsCoverage;
