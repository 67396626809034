import React from 'react';
import { ContextMenu, IconButton, IconMoreHoriz } from '@ftdr/blueprint-components-react';

const TooltipMenu = ({ menuItems, id = null }) => {
  return (
    <ContextMenu placement="bottom-start" menuItems={menuItems}>
      <IconButton
        label="Actions"
        size="small"
        variant="ghost"
        icon={<IconMoreHoriz />}
        id={id || 'actions_dropdown'}
      />
    </ContextMenu>
  );
};

export default TooltipMenu;
