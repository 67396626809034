import { Button } from '@ftdr/blueprint-components-react';
import React from 'react';
import Modal from './Modal';
import SiteIdling from '@components/modal/subcomponents/SiteIdling';

const ModalSiteIdling = (props) => {
  const actions = [
    <Button key="submit" size="medium" label="Extend Session" onClick={props.onClick} />,
  ];

  return (
    <Modal
      isActive={props.isActive}
      onClose={props.onClose}
      heading="Session Idle"
      actions={actions}
    >
      {props.isActive && <SiteIdling minutes={props.minutes} />}
    </Modal>
  );
};

export default ModalSiteIdling;
