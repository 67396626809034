import { getBrand } from '@helpers/brand.utils';
import { toTitleCase } from '@helpers/utils';
import { BaseAPI, Options } from './_api';
import {
  ProductAndCoveragesResponse,
  MissingBuyerPhoneResponse,
  REOrder,
  REOrderSearchRequest,
  REOrderSearchResponse,
} from './models';
import { publishToRefreshedREOrders } from '@app/core/refreshContract.service';

export class API extends BaseAPI {
  constructor() {
    super('app-ms', true, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Brand-Name': getBrand(),
      },
      timeout: 0, // disable timeout for RE Order so that it can be controlled on app layer
    });
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public async getProfileContractsMissingBuyerPhone(
    offset: number,
    limit: number,
  ): Promise<MissingBuyerPhoneResponse> {
    const params = {
      offset,
      limit,
    };

    return this.axios.get<any, MissingBuyerPhoneResponse>(
      `${this.getHost()}/profile/contracts/missing-buyer-phone`,
      { params },
    );
  }

  public async searchProfileContracts(
    req?: REOrderSearchRequest,
    opts?: Options,
  ): Promise<REOrderSearchResponse> {
    return this.axios
      .post<any, REOrderSearchResponse>(`${this.getHost()}/profile/contracts`, req, opts)
      .then((res) => {
        res?.orders.map(translateREOrderSearch);
        return res;
      })
      .then((res) => {
        publishToRefreshedREOrders(res.orders);
        return res;
      })
      .catch((err) => {
        return null;
      });
  }

  /** Perform searchProfileContract for a single contract id */
  public async searchProfileContractByContractID(
    contractID: string,
  ): Promise<REOrderSearchResponse> {
    return this.searchProfileContracts({
      data: {
        order: {
          ids: [contractID],
          realEstateStatus: [],
          awaitingWLKSubmissionOnly: false,
          statusListingMissingCOEOnly: false,
          sourceApplicationFlows: [],
        },
        agents: [],
        offices: [],
        date: {
          dateType: '',
          endDate: '',
          startDate: '',
        },
        query: '',
        filters: [],
      },
      meta: {
        asc: false,
        limit: 1,
        page: 0,
        sortBy: '',
      },
      include: {
        warrantylink: false,
        meta: true,
      },
    });
  }
}

const RealEstateOrderApi = new API();
export default RealEstateOrderApi;

/** Perform any translation to the order, such as titlecase */
function translateREOrderSearch(order: REOrder): REOrder {
  order.address = order.address && {
    ...order.address,
    address1: toTitleCase(order.address.address1),
    address2: toTitleCase(order.address.address2),
    city: toTitleCase(order.address.city),
  };

  order.initiatingOfficeName = toTitleCase(order.initiatingOfficeName);
  order.initiatingAgentName = toTitleCase(order.initiatingAgentName);
  order.tableId = order.id;

  return order;
}
