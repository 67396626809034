import React, { Component } from 'react';
import ZestyApi from '@apis/zesty.apis';
import { IconUser, Text } from '@ftdr/blueprint-components-react';
import CardSalesMaterial from '@components/card/CardSalesMaterial';
import { getBrand } from '@helpers/brand.utils';
import { SalesMaterial } from '@apis/models/zesty.api.model';

interface state {
  salesMaterials: SalesMaterial[];
}

export default class SalesMaterialTemplate extends Component<unknown, state> {
  constructor(props) {
    super(props);

    this.state = {
      salesMaterials: [],
    };

    ZestyApi.GetSalesMaterial().then((res) => {
      const resFiltered = res
        .filter((item) => item.brand.toUpperCase() === getBrand())
        .sort((a, b) => a.sort - b.sort);
      this.setState({ salesMaterials: resFiltered || [] });
    });
  }

  render() {
    return (
      <div className="card mx-auto my-4 py-4">
        <IconUser className="float-left mr-1 ml-3" color="primary" />
        <Text variant="heading-06"> For Real Estate Professionals</Text>

        <div className="md:flex flex-wrap">
          {this.state.salesMaterials.map((item) => {
            return <CardSalesMaterial salesMaterial={item} key={item.title} />;
          })}
        </div>
      </div>
    );
  }
}
