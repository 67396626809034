import React, { useState } from 'react';
import {
  AbstractNewOrderStepTemplate,
  NewOrderStepTemplateProps,
  NewOrderStepStates,
} from '@templates/order/AbstractNewOrderStepTemplate';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import cloneDeep from 'lodash/cloneDeep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import SendDocuments, {
  GetLatestRecipients,
  Recipient,
  Type as DocumentType,
} from '@components/SendDocuments';
import { isEmpty } from 'lodash';
import { ContractModelVariant } from '@app/models/contract.model';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NEW_ORDER_FLOW__ORDER_SUBMITTED,
  NEW_ORDER_FLOW__DOCUMENTS_SENT,
  NEW_ORDER_FLOW__BUYER_INFO_ADDED,
  NEW_ORDER_FLOW__SELLER_INFO_ADDED,
  NEW_ORDER_FLOW__CLOSING_DATE_ADDED,
} from '@constants/ga-events.constants';
import { getUniqueDocumentTypes } from '@helpers/order.utils';

interface States extends NewOrderStepStates, Pick<NewOrderContextData, 'sendDocuments'> {}

export class NewOrderStep4Template extends AbstractNewOrderStepTemplate<States> {
  constructor(props) {
    super(props);
  }

  getDefaultState(props: NewOrderStepTemplateProps): States {
    return {
      sendDocuments: cloneDeep(props.savedData.sendDocuments),
    };
  }

  getPendingData(): NewOrderContextData {
    return {
      ...this.props.savedData,
      sendDocuments: this.state.sendDocuments,
    };
  }

  getPartialSubmittedData(): NewOrderContextData {
    // should perform validation on the fields and stripped off any incomplete data
    return this.getPendingData(); // for now, saving all data regardless of incomplete data
  }

  onClear(): void {
    this.setState(this.getDefaultState(this.props));
  }
  onSubmit(): void {
    // dont submit if form isn't valid yet for submission
    if (!this.isFormValidForSubmission()) {
      return;
    }

    const dataToSubmit = this.getPendingData();
    this.props.submitPage(dataToSubmit);

    // Should track in GA that order was submitted!
    fireGAEvent(
      NEW_ORDER_FLOW__ORDER_SUBMITTED(this.props.userProfile, dataToSubmit.agentInfo.represents),
    );

    // Should track in GA the type of documents sent.
    const uniqueDocTypes = getUniqueDocumentTypes(dataToSubmit?.sendDocuments?.recipients);

    fireGAEvent(NEW_ORDER_FLOW__DOCUMENTS_SENT(this.props.userProfile, uniqueDocTypes));

    if (dataToSubmit.buyerInfo?.firstName?.length > 0) {
      // Should track in GA if buyer info was added.
      fireGAEvent(
        NEW_ORDER_FLOW__BUYER_INFO_ADDED(this.props.userProfile, dataToSubmit.agentInfo.represents),
      );
    }

    if (dataToSubmit.sellerInfo?.firstName?.length > 0) {
      // Should track in GA if seller info was added.
      fireGAEvent(
        NEW_ORDER_FLOW__SELLER_INFO_ADDED(
          this.props.userProfile,
          dataToSubmit.agentInfo.represents,
        ),
      );
    }

    if (dataToSubmit.productFilters?.projectedClosingDate?.getDate() > 0) {
      // Should track in GA if projected closing date was added.
      fireGAEvent(
        NEW_ORDER_FLOW__CLOSING_DATE_ADDED(
          this.props.userProfile,
          dataToSubmit.agentInfo.represents,
        ),
      );
    }
  }
  onEdit(editStep: NewOrderStep): void {
    this.props.editAnotherStep(editStep, this.getPartialSubmittedData());
  }

  isFormValidForSubmission(): boolean {
    let isFormValid = false;
    if (!this.recipientsSelected()) {
      return isFormValid;
    }

    //Make sure the selected recipient has valid email.
    this.state.sendDocuments.recipients.forEach((recipient) => {
      if (recipient.selectedTypes.length > 0 && recipient.email.length > 0) {
        isFormValid = true;
      }
    });
    return isFormValid;
  }

  componentDidMount() {
    const recipient = GetLatestRecipients(
      this.props.savedData,
      ContractModelVariant.NewOrderContextData,
      this.state.sendDocuments.recipients,
      this.props.userProfile.email,
      `${this.props.userProfile.firstName} ${this.props.userProfile.lastName}`,
    );
    this.setState({ sendDocuments: { recipients: recipient } });
  }

  getAllowedTypes(): DocumentType[] {
    return [
      DocumentType.Confirmation,
      this.props.savedData.productFilters?.projectedClosingDate ? DocumentType.Invoice : null,
    ];
  }

  recipientsSelected(): boolean {
    let selected = false;
    if (this.state.sendDocuments.recipients.length === 0) {
      return selected;
    }

    // Check if at least one document type is selected for a recipient
    this.state.sendDocuments.recipients.forEach((recipient) => {
      if (recipient.selectedTypes.length > 0) {
        selected = true;
      }
    });

    return selected;
  }

  renderForm(): JSX.Element {
    // should render the form
    return (
      <>
        <div className="-ml-4">
          <SendDocuments
            recipients={this.state.sendDocuments.recipients}
            allowedTypes={this.getAllowedTypes()}
            onChange={(data) => this.setState({ sendDocuments: { recipients: data } })}
            hideHeading={true}
            displaySelectRecipientMessage={!this.recipientsSelected()}
          />
        </div>
      </>
    );
  }
}
