import React from 'react';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderAgentInfoFormData, Represents } from '@app/models/order/order.model';
import OrderForm from '@components/orders/forms/OrderForm';
import { Checkbox, Panel, RadioGroup } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';

export enum OrderFormAgentInfoField {
  represents = 'initRepresents',
  roSelect = 'initROSelect',
}

interface OrderFormAgentInfoFieldProps
  extends OrderFormProps<OrderAgentInfoFormData, OrderFormAgentInfoField> {
  isREAgentUser: boolean;
  showROOption: boolean;
  onROToggle: (roFlag: boolean) => void;
}

const OrderFormAgentInfo: React.FC<OrderFormAgentInfoFieldProps> = (props) => {
  const representOptions = [
    {
      label: 'Buyer',
      value: Represents.Buyer,
    },
    {
      label: 'Seller',
      value: Represents.Seller,
    },
    {
      label: 'Both',
      value: Represents.Both,
    },
  ];

  const onChangeROOption = (checked: boolean) => {
    props.clearFieldError(OrderFormAgentInfoField.represents);
    props.onUpdateFormData({
      ...props.formData,
      selectROOption: checked,
      represents: checked ? Represents.Buyer : props.formData.represents,
    });
    props.onROToggle(checked);
  };

  const onChangeRepresentsOption = (value: string) => {
    props.clearFieldError(OrderFormAgentInfoField.represents);
    props.onUpdateFormData({
      ...props.formData,
      represents: value as Represents,
    });
  };

  return (
    <OrderForm.Row>
      <OrderForm.RowItem>
        <RadioGroup
          formField={true}
          id="OrderForm.AgentInfo.Represents"
          name="agentRepresents"
          label={
            !props.isREAgentUser
              ? ORDER_FORM_FIELDS.REPRESENTING__QUESTION
              : ORDER_FORM_FIELDS.REPRESENTING__QUESTION_SELF
          }
          required={true}
          error={props.errors.initRepresents}
          orientation="horizontal"
          disabled={props.formData.selectROOption}
          radios={representOptions}
          value={props.formData.represents}
          onChange={(value) => onChangeRepresentsOption(value)}
        />
      </OrderForm.RowItem>
      {props.showROOption && (
        <OrderForm.RowItem className="OrderFormAgentInfo_RO-Container">
          <Checkbox
            id="OrderForm.AgentInfo.ROCheckbox"
            checked={props.formData.selectROOption}
            label="This home warranty is for myself."
            onChange={(e) => onChangeROOption(e?.target?.checked)}
          />
        </OrderForm.RowItem>
      )}
    </OrderForm.Row>
  );
};

export default OrderFormAgentInfo;
