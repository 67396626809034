import React from 'react';
import { Link, Text } from '@ftdr/blueprint-components-react';
import { useNavigation } from 'react-navi';
import paths from '@constants/paths';

const ServiceUnavailable = () => {
  const navigation = useNavigation();

  function clickLink() {
    navigation.navigate(paths.ContactUs);
  }

  return (
    <>
      <Text>
        {'We do not offer home warranties in this zip code. Enter a new zip code or contact our '}
        <Link
          onClick={clickLink}
          color="interactive"
          underline="always"
          className="color-primary-300"
        >
          {'support team'}
        </Link>
        {' for assistance.'}
      </Text>
    </>
  );
};

export default ServiceUnavailable;
