import { cleanPhone, convertDateToCST } from '@helpers/utils';
import { getBrand } from '@helpers/brand.utils';
import { AxiosError } from 'axios';
import { BaseAPI } from './_api';
import WarrantyLink from '../../types/api/WarrantyLink';
import {
  WarrantylinkContractResponse,
  WarrantylinkContract,
  WarrantylinkAccount,
  WarrantylinkAgreement,
  WarrantyLinkPaymentsResponse,
  WarrantyLinkInvitation,
  WarrantyLinkRemove,
  WarrantylinkContractData,
  WarrantylinkContracts,
  WarrantyLinkPaymentsByDateResponse,
} from './models';

class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        validateStatus: (status) => {
          return status >= 200 && status < 300 && status !== 207; // our WarrantyLink endpoint currently returns a 207 if an error occurred, this is to ensure those responses go into our error flow
        },
      },
      true,
      suppressErrors,
    );
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public submitWarrantylinkContracts(
    contractIds: string[],
    contracts: WarrantylinkContractData[],
  ): Promise<any> {
    try {
      const body: WarrantylinkContracts = {
        contractIds: [],
        contracts: contracts,
      };

      return this.axios.post(`${this.getHost()}/warrantylink/contracts/submit`, body);
    } catch (error) {
      const e = new Error(error);
      throw e;
    }
  }

  public updateWarrantylinkContractForm(contract: Partial<WarrantylinkContract>): Promise<any> {
    // Re-format phone number
    if (contract?.warrantylink?.form?.buyer) {
      contract.warrantylink.form.buyer.phone = cleanPhone(contract.warrantylink.form.buyer.phone);
    }
    if (contract?.warrantylink?.form?.seller) {
      contract.warrantylink.form.seller.phone = cleanPhone(contract.warrantylink.form.seller.phone);
    }

    return this.axios
      .post(
        `${this.getHost()}/warrantylink/contract/${contract.id}/form`,
        contract.warrantylink.form,
      )
      .then((res) => {
        return true;
        // TODO Do we need to do anything with this response?
      })
      .catch((e) => {
        console.log('error -');
        return false;
      });
  }

  public searchWarrantyLinkContracts(
    params: WarrantyLink.WarrantyLinkContractSearchParams,
  ): Promise<WarrantylinkContractResponse> {
    const totalStatusDefault = {
      READY: 0,
      SAVED: 0,
      CLOSED: 0,
      SUBMITTED: 0,
      PENDING: 0,
      COMPLETED: 0,
      CANCELLED: 0,
    };
    return this.axios
      .get<any, WarrantylinkContractResponse>(`${this.getHost()}/warrantylink/contracts/search`, {
        params,
      })
      .then((res) => {
        return translateToWarrantylinkModel(res);
      })
      .catch((e) => {
        // TODO: Errors need to be handled according to status codes
        console.log('error = ', e);
        return translateToWarrantylinkModel({
          contracts: [],
          meta: { from: 0, to: 0, total: 0, count: 0, totalStatus: totalStatusDefault },
        });
      });
  }

  // TODO: It looks like WLK includes renewal contracts for some reason. That's unnecessary. The GetContractsByIDs resolved that for us
  public getWarrantylinkContractsForUser(): Promise<WarrantylinkContract[]> {
    return this.axios
      .get<any, WarrantylinkContractResponse>(`${this.getHost()}/profile/warrantylink/contracts`)
      .then((res) => {
        return translateToWarrantylinkModel(res).contracts;
      })
      .catch((e) => {
        // TODO: Errors need to be handled according to status codes
        console.log('error = ', e);
        return translateToWarrantylinkModel({
          contracts: [],
          meta: { from: 0, to: 0, total: 0, count: 0, totalStatus: undefined },
        }).contracts;
      });
  }

  public getWarrantylinkAccount(officeID: string): Promise<WarrantylinkAccount> {
    return this.axios
      .get<any, WarrantylinkAccount>(`${this.getHost()}/warrantylink/account/${officeID}`)
      .then((res) => {
        return res;
      });
  }

  public signWarrantylinkAgreement(
    officeID: string,
    agreement: WarrantylinkAgreement,
  ): Promise<boolean> {
    return this.axios
      .patch<
        any,
        any
      >(`${this.getHost()}/warrantylink/offices/${getBrand()}/${officeID}/agreement`, agreement)
      .then(() => true); // Always return true if succeeded
  }

  public inviteToWarrantylink(officeID: string, email: string): Promise<boolean> {
    const body: WarrantyLinkInvitation = {
      officeID,
      email,
    };
    return this.axios
      .post<any, any>(`${this.getHost()}/warrantylink/invite`, body)
      .then(() => true);
  }

  public removeWarrantylink(officeID: string, effectiveDate: string): Promise<boolean> {
    const body: WarrantyLinkRemove = {
      officeID,
      effectiveDate,
    };
    return this.axios
      .post<any, any>(`${this.getHost()}/warrantylink/remove`, body)
      .then(() => true);
  }

  public getWarrantyLinkPayments(): Promise<WarrantyLinkPaymentsResponse> {
    return this.axios
      .get<any, WarrantyLinkPaymentsResponse>(`${this.getHost()}/profile/warrantylink/payments`)
      .then((res) => {
        return res;
      })
      .catch((err: AxiosError<any>) => {
        // If no checks returned, then display nothing.
        if (err.response.status === 404) {
          return { checks: [] };
        }
        throw err;
      });
  }

  public getWarrantyLinkPaymentsByDate(
    startDate: string,
    endDate: string,
  ): Promise<WarrantyLinkPaymentsByDateResponse> {
    const params = {
      startDate: startDate,
      endDate: endDate,
    };

    return this.axios
      .get<any, WarrantyLinkPaymentsByDateResponse>(
        `${this.getHost()}/profile/warrantylink/payments-by-date`,
        { params },
      )
      .then((res) => {
        return res;
      })
      .catch((err: AxiosError<any>) => {
        if (err.response.status === 404) {
          return { totalPaymentAmount: '0' };
        }
        throw err;
      });
  }
}

function translateToWarrantylinkModel(
  response: WarrantylinkContractResponse,
): WarrantylinkContractResponse {
  const statusDictionary = {
    0: 'Ready',
    1: 'Saved',
    10: 'Submitted',
    20: 'Pending',
    21: 'Completed',
    29: 'Completed',
    30: 'Closed',
    31: 'Cancelled',
  };

  const mappedContracts = response.contracts.map((contract) => {
    // translate status using the status dictionary until the api does this for us
    contract.warrantylink.status = statusDictionary[contract.warrantylink.status];
    contract.warrantylink.deadlineDate = convertDateToCST(contract.warrantylink.deadlineDate);
    return contract;
  });

  return { contracts: mappedContracts, meta: response.meta };
}

const WarrantylinkApi = new API();
export const WarrantylinkApiSuppressErrors = new API(true);
export default WarrantylinkApi;
