import React from 'react';
import {
  AbstractNewOrderStepTemplate,
  NewOrderStepStates,
  NewOrderStepTemplateProps,
} from '@templates/order/AbstractNewOrderStepTemplate';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import cloneDeep from 'lodash/cloneDeep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import OrderFormAgentInfo, {
  OrderFormAgentInfoField,
} from '@components/orders/forms/OrderFormAgentInfo';
import { isAllowedRO, isREAgent } from '@helpers/profile.utils';
import { OrderFormFieldError } from '@components/orders/forms/OrderForm.types';
import OrderFormOfficeAgent, {
  OrderFormOfficeAgentField,
} from '@components/orders/forms/OrderFormOfficeAgent';
import {
  validateAge,
  validateCity,
  validateInitiatingAgent,
  validateInitiatingOffice,
  validateInitiatingRepresents,
  validateResidenceType,
  validateSquareFootage,
  validateState,
  validateStreetAddress,
  validateZip,
} from '@services/validation/NewOrder.FieldValidationRules';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { Office as AppOffice, OfficeType } from '@app/models';
import OrderFormAddressInfo, {
  OrderFormAddressInfoField,
} from '@components/orders/forms/OrderFormAddressInfo';
import OrderFormPrefilledOfficeAgent from '@components/orders/forms/OrderFormPrefilledOfficeAgent';
import { Button } from '@ftdr/blueprint-components-react';
import { OrderAddressInfoFormData, OrderPropertyInfoFormData } from '@app/models/order/order.model';
import ContractApi from '@apis/contract.api';
import ModalContractExistsOnProperty from '@components/modal/ModalContractExistsOnProperty';
import OrderFormPropertyInfo, {
  OrderFormPropertyInfoField,
} from '@components/orders/forms/OrderFormPropertyInfo';
import AddressVerification, {
  AddressVerificationItem,
  AddressVerificationVerifiedItem,
} from '@components/misc/AddressVerification';
import {
  DEFAULT_AGE,
  NEW_CONSTRUCTION_AGE,
  getSquareFootageRangeValueNum,
} from '@services/helpers';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import PropertyApi from '@apis/property.api';
import { Brand } from '@constants/brands';
import { canModifySquareFootage } from '@helpers/order.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import { NEW_ORDER_FLOW__AGENT_AND_PROPERTY_COMPLETE } from '@constants/ga-events.constants';

interface AddressVerificationDetails {
  enableAddressVerification: boolean;
  addressVerificationItems: AddressVerificationItem[];
}
interface ExistingContractDetails {
  showContractExistsOnPropertyModal: boolean;
  existingContract: any;
  existingContractAddress: string;
  existingOrderInitiatingAgentMatch: boolean;
  existingOrderInitiatingOfficeOnUserProfile: boolean;
  existingOrderCooperatingAgentExists: boolean;
  existingOrderClosingAgentExists: boolean;
  existingContractModalHeader: string;

  existingContractSalesChannel: string;
  existingOrderInitiatingAgentExists: boolean;
  existingContractInitiatingAgentName: string;
  existingContractInitiatingAgentOffice: string;
  initiatingAgentRepresentBoth: boolean;
}
interface States
  extends NewOrderStepStates,
    Pick<NewOrderContextData, 'initiatingAgent' | 'agentInfo' | 'addressInfo' | 'propertyInfo'> {
  initiatingAgentErrors: OrderFormFieldError<OrderFormOfficeAgentField>;
  agentInfoErrors: OrderFormFieldError<OrderFormAgentInfoField>;
  addressInfoErrors: OrderFormFieldError<OrderFormAddressInfoField>;
  propertyInfoErrors: OrderFormFieldError<OrderFormPropertyInfoField>;

  existingContractModalDetails: ExistingContractDetails;
  addressVerification: AddressVerificationDetails;

  showPropertyInfoStep: boolean;
}

export class NewOrderStep1Template extends AbstractNewOrderStepTemplate<States> {
  constructor(props) {
    super(props);
  }

  getDefaultState(props: NewOrderStepTemplateProps): States {
    let initiatingAgent = cloneDeep(props.savedData.initiatingAgent);

    if (isREAgent(props.userProfile)) {
      initiatingAgent = {
        agentEmail: props.userProfile.email,
        agentID: props.userProfile.roleID,
        agentName: `${props.userProfile.firstName} ${props.userProfile.lastName}`,
        officeName: (props.userProfile.offices[0] as AppOffice).name,
        officeID: props.userProfile.offices[0].id,
        franchiseCode: (props.userProfile.offices[0] as AppOffice).details?.franchiseCode,
      };
    }

    return {
      agentInfo: cloneDeep(props.savedData.agentInfo),
      initiatingAgent,
      addressInfo: cloneDeep(props.savedData.addressInfo),
      propertyInfo: cloneDeep(props.savedData.propertyInfo),
      initiatingAgentErrors: {
        [OrderFormOfficeAgentField.officeInput]: '',
        [OrderFormOfficeAgentField.agentInput]: '',
      },
      agentInfoErrors: {
        [OrderFormAgentInfoField.represents]: '',
        [OrderFormAgentInfoField.roSelect]: '',
      },
      addressInfoErrors: {
        [OrderFormAddressInfoField.zip]: '',
        [OrderFormAddressInfoField.streetAddress]: '',
        [OrderFormAddressInfoField.unitNumber]: '',
        [OrderFormAddressInfoField.city]: '',
        [OrderFormAddressInfoField.state]: '',
      },
      propertyInfoErrors: {
        [OrderFormPropertyInfoField.age]: '',
        [OrderFormPropertyInfoField.residenceType]: '',
        [OrderFormPropertyInfoField.squareFootage]: '',
        [OrderFormPropertyInfoField.mls]: '',
      },
      existingContractModalDetails: {
        existingContract: '',
        existingContractAddress: '',
        existingOrderInitiatingOfficeOnUserProfile: false,
        existingContractInitiatingAgentName: '',
        existingContractInitiatingAgentOffice: '',
        existingContractModalHeader: '',
        existingContractSalesChannel: '',
        existingOrderClosingAgentExists: false,
        existingOrderCooperatingAgentExists: false,
        existingOrderInitiatingAgentExists: false,
        existingOrderInitiatingAgentMatch: false,
        initiatingAgentRepresentBoth: false,
        showContractExistsOnPropertyModal: false,
      },
      addressVerification: {
        enableAddressVerification: false,
        addressVerificationItems: [],
      },
      showPropertyInfoStep: false,
    };
  }

  getPendingData(): NewOrderContextData {
    return {
      ...this.props.savedData,
      agentInfo: this.state.agentInfo,
      addressInfo: this.state.addressInfo,
      propertyInfo: this.state.propertyInfo,
      initiatingAgent: this.state.initiatingAgent,
    };
  }

  getPartialSubmittedData(): NewOrderContextData {
    // should perform validation on the fields and stripped off any incomplete data
    return this.getPendingData(); // for now, saving all data regardless of incomplete data
  }

  onROToggle(checked: boolean): void {
    const pendingPropertyInfo = this.getPendingData().propertyInfo;
    const propertyInfo: OrderPropertyInfoFormData = {
      squareFootage: pendingPropertyInfo.squareFootage,
      age: checked ? DEFAULT_AGE : NEW_CONSTRUCTION_AGE,
      residenceType: pendingPropertyInfo.residenceType,
      mlsNumber: pendingPropertyInfo.mlsNumber,
    };
    this.setState({ propertyInfo });
  }

  onClear(): void {
    // should clear the user's form data entirely, i.e. resetting the states to its default
    this.setState(this.getDefaultState(this.props));
  }
  onSubmit(): void {
    // if there needs to be additional operations prior to submitting, can be done at this point.
    // otherwise, continue on.  most of the time we just want to continue.

    if (!this.isFormValidForSubmission()) {
      return;
    }

    const dataToSubmit = this.getPendingData();
    this.props.submitPage(dataToSubmit);

    // Track in GA the completion of Agent and Property Info.
    fireGAEvent(
      NEW_ORDER_FLOW__AGENT_AND_PROPERTY_COMPLETE(
        this.props.userProfile,
        dataToSubmit.agentInfo.represents,
      ),
    );
  }
  onEdit(editStep: NewOrderStep): void {
    this.props.editAnotherStep(editStep, this.getPartialSubmittedData());
  }

  onUpdateErrorAgentInfoField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormAgentInfoField,
    value: string = '',
  ) => {
    const errors = this.state.agentInfoErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateAgentInfoField(fieldName, value)
      : value;
    this.setState({ agentInfoErrors: errors });
  };

  onUpdateErrorOfficeAgentField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormOfficeAgentField,
    value: string = '',
  ) => {
    const errors = this.state.initiatingAgentErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateOfficeAgentField(fieldName, value)
      : value;
    this.setState({ initiatingAgentErrors: errors });
  };

  onUpdateErrorAddressInfoField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormAddressInfoField,
    value: string = '',
  ) => {
    const errors = this.state.addressInfoErrors;
    errors[fieldName] = shouldExecValidateFc ? this.validateAddressField(fieldName, value) : value;
    this.setState({ addressInfoErrors: errors });
  };

  onUpdateErrorPropertyInfoField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormPropertyInfoField,
    value: string = '',
  ) => {
    const errors = this.state.propertyInfoErrors;
    errors[fieldName] = shouldExecValidateFc ? this.validatePropertyField(fieldName, value) : value;
    this.setState({ propertyInfoErrors: errors });
  };

  validateOfficeAgentField = (fieldName: OrderFormOfficeAgentField, value: string) => {
    switch (fieldName) {
      case OrderFormOfficeAgentField.officeInput:
        return validateInitiatingOffice(value);
      case OrderFormOfficeAgentField.agentInput:
        return validateInitiatingAgent(value);
    }
    return '';
  };

  validateAgentInfoField = (fieldName: OrderFormAgentInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormAgentInfoField.roSelect:
        return '';
      case OrderFormAgentInfoField.represents:
        return validateInitiatingRepresents(value);
    }
    return '';
  };

  validateAddressField = (fieldName: OrderFormAddressInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormAddressInfoField.zip:
        return validateZip(value);
      case OrderFormAddressInfoField.city:
        return validateCity(value);
      case OrderFormAddressInfoField.state:
        return validateState(value);
      case OrderFormAddressInfoField.streetAddress:
        return validateStreetAddress(value, false);
      default:
        break;
    }
    return '';
  };

  validatePropertyField = (fieldName: OrderFormPropertyInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormPropertyInfoField.residenceType:
        return validateResidenceType(value);
      case OrderFormPropertyInfoField.age:
        return validateAge(value);
      case OrderFormPropertyInfoField.squareFootage:
        return canModifySquareFootage() ? validateSquareFootage(value) : '';
      default:
        break;
    }
    return '';
  };

  validateAndUpdateAddressInfoError = (): OrderFormFieldError<OrderFormAddressInfoField> => {
    const err: OrderFormFieldError<OrderFormAddressInfoField> = {
      [OrderFormAddressInfoField.streetAddress]: this.validateAddressField(
        OrderFormAddressInfoField.streetAddress,
        this.getPendingData().addressInfo.streetAddress,
      ),
      [OrderFormAddressInfoField.zip]: this.validateAddressField(
        OrderFormAddressInfoField.zip,
        this.getPendingData().addressInfo.zip,
      ),
      [OrderFormAddressInfoField.state]: this.validateAddressField(
        OrderFormAddressInfoField.state,
        this.getPendingData().addressInfo.state,
      ),
      [OrderFormAddressInfoField.city]: this.validateAddressField(
        OrderFormAddressInfoField.city,
        this.getPendingData().addressInfo.city,
      ),
      [OrderFormAddressInfoField.unitNumber]: '',
    };
    this.setState({ addressInfoErrors: err });
    return err;
  };

  /** runs validation for all fields and updates errors. returns true if no validation errors and is allowed for submission */
  isFormValidForSubmission(): boolean {
    const initiatingAgentErrors: OrderFormFieldError<OrderFormOfficeAgentField> = {
      [OrderFormOfficeAgentField.officeInput]: this.validateOfficeAgentField(
        OrderFormOfficeAgentField.officeInput,
        this.getPendingData().initiatingAgent.officeName,
      ),
      [OrderFormOfficeAgentField.agentInput]: this.validateOfficeAgentField(
        OrderFormOfficeAgentField.agentInput,
        this.getPendingData().initiatingAgent.agentName,
      ),
    };
    const agentInfoErrors: OrderFormFieldError<OrderFormAgentInfoField> = {
      [OrderFormAgentInfoField.represents]: this.validateAgentInfoField(
        OrderFormAgentInfoField.represents,
        this.getPendingData().agentInfo.represents,
      ),
      [OrderFormAgentInfoField.roSelect]: '',
    };
    const addressInfoErrors: OrderFormFieldError<OrderFormAddressInfoField> = {
      [OrderFormAddressInfoField.streetAddress]: this.validateAddressField(
        OrderFormAddressInfoField.streetAddress,
        this.getPendingData().addressInfo.streetAddress,
      ),
      [OrderFormAddressInfoField.zip]: this.validateAddressField(
        OrderFormAddressInfoField.zip,
        this.getPendingData().addressInfo.zip,
      ),
      [OrderFormAddressInfoField.state]: this.validateAddressField(
        OrderFormAddressInfoField.state,
        this.getPendingData().addressInfo.state,
      ),
      [OrderFormAddressInfoField.city]: this.validateAddressField(
        OrderFormAddressInfoField.city,
        this.getPendingData().addressInfo.city,
      ),
      [OrderFormAddressInfoField.unitNumber]: '',
    };
    const propertyInfoErrors: OrderFormFieldError<OrderFormPropertyInfoField> = {
      [OrderFormPropertyInfoField.squareFootage]: this.validatePropertyField(
        OrderFormPropertyInfoField.squareFootage,
        this.getPendingData().propertyInfo.squareFootage?.toString(),
      ),
      [OrderFormPropertyInfoField.age]: this.validatePropertyField(
        OrderFormPropertyInfoField.age,
        `${this.getPendingData().propertyInfo.age}`,
      ),
      [OrderFormPropertyInfoField.residenceType]: this.validatePropertyField(
        OrderFormPropertyInfoField.residenceType,
        this.getPendingData().propertyInfo.residenceType,
      ),
      [OrderFormPropertyInfoField.mls]: this.validatePropertyField(
        OrderFormPropertyInfoField.mls,
        this.getPendingData().propertyInfo.mlsNumber,
      ),
    };
    // update all error states
    this.setState({
      initiatingAgentErrors,
      agentInfoErrors,
      addressInfoErrors,
      propertyInfoErrors,
    });

    // returns true if every error is blank
    return Object.values({
      ...initiatingAgentErrors,
      ...agentInfoErrors,
      ...addressInfoErrors,
      ...propertyInfoErrors,
    }).every((err) => !err);
  }

  validateAddressAndContinue = async () => {
    const addressErrors = this.validateAndUpdateAddressInfoError();

    if (Object.values(addressErrors).every((v) => v === '')) {
      this.setState((state) => ({
        addressVerification: {
          enableAddressVerification: true,
          addressVerificationItems: [
            {
              address: {
                city: state.addressInfo.city,
                state: state.addressInfo.state,
                zip: state.addressInfo.zip,
                streetAddress: state.addressInfo.streetAddress,
                unit: state.addressInfo.unit,
              },
            },
          ],
        },
      }));
    }
  };

  checkDuplicateContractSameProperty = async (info: OrderAddressInfoFormData) => {
    const address = {
      streetAddress: info.streetAddress,
      unit: info.unit,
      city: info.city,
      state: info.state,
      zip: info.zip,
    };
    const existingContractDetails: ExistingContractDetails = {
      existingContract: '',
      existingContractAddress: '',
      existingOrderInitiatingOfficeOnUserProfile: false,
      existingContractInitiatingAgentName: '',
      existingContractInitiatingAgentOffice: '',
      existingContractModalHeader: '',
      existingContractSalesChannel: '',
      existingOrderClosingAgentExists: false,
      existingOrderCooperatingAgentExists: false,
      existingOrderInitiatingAgentExists: false,
      existingOrderInitiatingAgentMatch: false,
      initiatingAgentRepresentBoth: false,
      showContractExistsOnPropertyModal: false,
    };

    const hasExistingContracts = (contractID) => {
      return contractID && contractID.length > 0;
    };
    const res = await ContractApi.searchContractByAddress(address);
    if (hasExistingContracts(res.contractID)) {
      // The follwing address was entered for which we found an existing contract
      let enteredPropertyAddr = '';
      if (address?.unit?.length > 0) {
        enteredPropertyAddr = `${address?.streetAddress?.trim()}, Unit# ${address?.unit}, ${address?.city}, ${address?.state} ${address?.zip}`;
      } else {
        enteredPropertyAddr = `${address?.streetAddress?.trim()}, ${address?.city}, ${address?.state} ${address?.zip}`;
      }

      if (res.salesChannel === 'RE') {
        existingContractDetails.showContractExistsOnPropertyModal = true;
        existingContractDetails.existingContract = res.contractID;
        existingContractDetails.existingContractSalesChannel = res.salesChannel;

        // Call the API to get contract details first to determine the initiating agent, co-operating agent, and title agent on this contract
        const contRes = await ContractApi.getContractDetails([String(res.contractID)], false, true);

        const existingPropertyAddr = `${contRes[0]?.detail?.property?.streetAddress}, ${contRes[0]?.detail?.property?.streetAddress2}${contRes[0]?.detail?.property?.streetAddress2.length > 0 ? ', ' : ''}${contRes[0]?.detail?.property?.city}, ${contRes[0]?.detail?.property?.state} ${contRes[0]?.detail?.property?.zip}`;

        existingContractDetails.existingContractAddress = existingPropertyAddr;

        // set if initiating agent exists on existing contract
        if (contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID.length > 0) {
          existingContractDetails.existingOrderInitiatingAgentExists = true;
        } else {
          existingContractDetails.existingOrderInitiatingAgentExists = false;
        }

        // set initiating agent name and office
        if (contRes[0]?.summary?.agentName?.length > 0) {
          existingContractDetails.existingContractInitiatingAgentName =
            contRes[0]?.summary?.agentName;
          existingContractDetails.existingContractInitiatingAgentOffice =
            contRes[0]?.summary?.officeName;
        }

        // NOTE: This (Initiating Agent represents BOTH buyer and seller) is for a future ticket
        // set if initiating agent represents both buyer and seller
        if (contRes[0]?.detail.initiatingOfficeAgent?.represents === 'BOTH') {
          console.log(
            `CardNewOrderPropertyAddress initiating agent represents:${contRes[0]?.detail.initiatingOfficeAgent?.represents}`,
          );
          existingContractDetails.initiatingAgentRepresentBoth = true;
        } else {
          existingContractDetails.initiatingAgentRepresentBoth = false;
        }

        // set if closing/title agent exists on existing contract
        if (contRes[0]?.detail.closingOfficeAgent?.agent?.realEstateAgentID.length > 0) {
          existingContractDetails.existingOrderClosingAgentExists = true;
        } else {
          existingContractDetails.existingOrderClosingAgentExists = false;
        }

        // set if cooperating agent exists on existing contract
        if (contRes[0]?.detail.cooperatingOfficeAgent?.agent?.realEstateAgentID.length > 0) {
          existingContractDetails.existingOrderCooperatingAgentExists = true;
        } else {
          existingContractDetails.existingOrderCooperatingAgentExists = false;
        }

        // set if existing contract's initiating office is on logged in user's profile.
        if (
          this.props.userProfile.offices?.find(
            (office) => office.id === contRes[0]?.detail?.initiatingOfficeAgent?.office?.id,
          )
        ) {
          existingContractDetails.existingOrderInitiatingOfficeOnUserProfile = true;
        } else {
          existingContractDetails.existingOrderInitiatingOfficeOnUserProfile = false;
        }

        // determine if the initiating agent on exisiting contract match with the logged in on selected agent
        if (
          contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID ===
            this.props.userProfile.roleID ||
          contRes[0]?.detail.initiatingOfficeAgent?.agent?.realEstateAgentID ===
            this.state.initiatingAgent?.agentID
        ) {
          existingContractDetails.existingOrderInitiatingAgentMatch = true;
        } else {
          existingContractDetails.existingOrderInitiatingAgentMatch = false;
        }

        // Now we have comman header no matter if the initiating office on existing contract is same or not.
        const modalHeader1 = `An ${contRes[0]?.detail?.contractStatus === 'L' ? 'open' : 'active'} order #${res.contractID} exists for this address`;
        existingContractDetails.existingContractModalHeader = modalHeader1;
      } else {
        const modalHeader1 = `A contract #${res.contractID} exists for this address`;
        existingContractDetails.existingContractModalHeader = modalHeader1;
        existingContractDetails.showContractExistsOnPropertyModal = true;
        existingContractDetails.existingContract = res.contractID;
        existingContractDetails.existingContractSalesChannel = res.salesChannel;
        existingContractDetails.existingContractAddress = enteredPropertyAddr;
      }

      this.setState({ existingContractModalDetails: existingContractDetails });
      return true;
    }
    return false;
  };

  closeContractExistsOnPropertyModal = () => {
    const existingContractDetails: ExistingContractDetails = {
      existingContract: '',
      existingContractAddress: '',
      existingOrderInitiatingOfficeOnUserProfile: false,
      existingContractInitiatingAgentName: '',
      existingContractInitiatingAgentOffice: '',
      existingContractModalHeader: '',
      existingContractSalesChannel: '',
      existingOrderClosingAgentExists: false,
      existingOrderCooperatingAgentExists: false,
      existingOrderInitiatingAgentExists: false,
      existingOrderInitiatingAgentMatch: false,
      initiatingAgentRepresentBoth: false,
      showContractExistsOnPropertyModal: false,
    };
    this.setState({ existingContractModalDetails: existingContractDetails });
  };

  updateAddressFromVerification = ({ verifiedAddress }: AddressVerificationVerifiedItem) => {
    this.setState({
      addressInfo: {
        streetAddress: verifiedAddress.streetAddress,
        unit: verifiedAddress.unit,
        city: verifiedAddress.city,
        state: verifiedAddress.state,
        zip: verifiedAddress.zip,
        hasVerified: true,
        addressUUID: verifiedAddress.meta?.addressId,
      },
    });
  };

  getPropertyDetails = async (address) => {
    const brandLocation = this.getBrand();
    try {
      const propertyDetails = await PropertyApi.getPropertyDetailsByAddress(address);

      if (propertyDetails) {
        console.log('property data returned, pre-filling property');
        const squarefootageValue = getSquareFootageRangeValueNum(propertyDetails.squareFootage);
        // will be taken care of later
        // if square footage is above, then display modal
        // if (brandLocation === 'AHS'&& squarefootageValue === 'above') {
        //      setIsModalActive(true);
        // } else if(brandLocation === 'HSA'&& propertyDetails.squareFootage > 15000) {
        //      setIsModalActive(true);
        // }
        const propertyInfo: OrderPropertyInfoFormData = {
          squareFootage: squarefootageValue,
          age: propertyDetails.age,
          residenceType: dwellingTypeDictionary[propertyDetails.typeOfResidence],
          mlsNumber: this.getPendingData().propertyInfo.mlsNumber,
        };
        this.setState({ propertyInfo });
        this.onUpdateErrorPropertyInfoField(
          true,
          OrderFormPropertyInfoField.residenceType,
          propertyInfo.residenceType,
        );
        this.onUpdateErrorPropertyInfoField(
          true,
          OrderFormPropertyInfoField.squareFootage,
          propertyInfo.squareFootage?.toString(),
        );
      }
    } catch (error) {
      console.error('Unable to fetch property details', error);
      // if square footage isn't allowed to be modified, then default the square footage instead
      if (!canModifySquareFootage()) {
        this.setState({ propertyInfo: { ...this.state.propertyInfo, squareFootage: 4999 } });
      }
    }
  };

  onAddressVerificationSubmit = () => {
    this.checkDuplicateContractSameProperty(this.state.addressInfo).then((exists) => {
      if (!exists) {
        this.setState({ showPropertyInfoStep: true });
        this.getPropertyDetails(this.state.addressInfo);
      }
    });
  };

  onAddressVerificationReset = () => {
    this.setState({
      addressVerification: {
        enableAddressVerification: false,
        addressVerificationItems: [],
      },
    });
  };
  renderForm(): JSX.Element {
    // should render the form
    return (
      <>
        {isREAgent(this.props.userProfile) ? (
          <OrderFormPrefilledOfficeAgent
            ids={{
              [OrderFormOfficeAgentField.officeInput]: 'wb_initiatingOfficeTypeahead',
              [OrderFormOfficeAgentField.agentInput]: 'wb_initiatingAgentTypeahead',
            }}
            labels={{
              [OrderFormOfficeAgentField.officeInput]: 'Initiating office',
              [OrderFormOfficeAgentField.agentInput]: 'Initiating agent',
            }}
            officeName={this.state.initiatingAgent.officeName}
            agentName={this.state.initiatingAgent.agentName}
          />
        ) : (
          <OrderFormOfficeAgent
            ids={{
              [OrderFormOfficeAgentField.officeInput]: 'wb_initiatingOfficeTypeahead',
              [OrderFormOfficeAgentField.agentInput]: 'wb_initiatingAgentTypeahead',
            }}
            labels={{
              [OrderFormOfficeAgentField.officeInput]: 'Initiating office',
              [OrderFormOfficeAgentField.agentInput]: 'Initiating agent',
            }}
            idNoDropdowns={{
              [OrderFormOfficeAgentField.officeInput]: 'wb_initiatingOfficeTypeahead',
              [OrderFormOfficeAgentField.agentInput]: 'wb_initiatingAgentTypeahead',
            }}
            officeSuggestions={getProfileOfficesByType(
              this.props.userProfile,
              OfficeType.RealEstate,
            )}
            disableOfficeSuggestions={false}
            disableOfficeAgentSelection={isREAgent(this.props.userProfile)}
            formData={this.state.initiatingAgent}
            errors={this.state.initiatingAgentErrors}
            onUpdateFormData={(data) => this.setState({ initiatingAgent: data })}
            onValidateField={(fieldName, value) =>
              this.onUpdateErrorOfficeAgentField(true, fieldName, value)
            }
            clearFieldError={(fieldName) => this.onUpdateErrorOfficeAgentField(false, fieldName)}
            onFieldError={(fieldName, err) =>
              this.onUpdateErrorOfficeAgentField(false, fieldName, err)
            }
            onFormError={console.log}
          />
        )}

        <OrderFormAgentInfo
          isREAgentUser={isREAgent(this.props.userProfile)}
          showROOption={isAllowedRO(this.props.userProfile)}
          formData={this.state.agentInfo}
          errors={this.state.agentInfoErrors}
          onUpdateFormData={(data) => this.setState({ agentInfo: data })}
          onValidateField={(fieldName, value) =>
            this.onUpdateErrorAgentInfoField(true, fieldName, value)
          }
          clearFieldError={(fieldName) => this.onUpdateErrorAgentInfoField(false, fieldName)}
          onFieldError={(fieldName, err) => this.onUpdateErrorAgentInfoField(false, fieldName, err)}
          onFormError={console.log}
          onROToggle={(roToggle) => this.onROToggle(roToggle)}
        />

        <OrderFormAddressInfo
          ids={{
            [OrderFormAddressInfoField.zip]: 'wb_zipCodePropertyAddress',
            [OrderFormAddressInfoField.streetAddress]: 'wb_streetAddressPropertyAddress',
            [OrderFormAddressInfoField.unitNumber]: 'wb_propertyAddressUnit',
            [OrderFormAddressInfoField.city]: 'wb_streetAddressCity',
            [OrderFormAddressInfoField.state]: 'wb_streetAddressState',
          }}
          allowPOBox={false}
          label="Property Information"
          formData={this.state.addressInfo}
          clearFieldError={(fieldName) => this.onUpdateErrorAddressInfoField(false, fieldName)}
          errors={this.state.addressInfoErrors}
          onFieldError={(fieldName, err) =>
            this.onUpdateErrorAddressInfoField(false, fieldName, err)
          }
          onUpdateFormData={(data) => this.setState({ addressInfo: data })}
          onValidateField={(fieldName, value) =>
            this.onUpdateErrorAddressInfoField(true, fieldName, value)
          }
          onFormError={console.log}
        />

        <Button
          label="Validate address to continue"
          id="wb_validateAddressContinue"
          variant="outlined"
          size="medium"
          onClick={this.validateAddressAndContinue}
        />

        {this.state.showPropertyInfoStep && (
          <OrderFormPropertyInfo
            errors={this.state.propertyInfoErrors}
            clearFieldError={(fieldName) => this.onUpdateErrorPropertyInfoField(false, fieldName)}
            formData={this.state.propertyInfo}
            onFormError={console.log}
            onFieldError={(fieldName, err) =>
              this.onUpdateErrorPropertyInfoField(false, fieldName, err)
            }
            onUpdateFormData={(data) => this.setState({ propertyInfo: data })}
            onValidateField={(fieldName, value) =>
              this.onUpdateErrorPropertyInfoField(true, fieldName, value)
            }
            isRealtorOwned={this.state.agentInfo.selectROOption}
          />
        )}

        <ModalContractExistsOnProperty
          id="contract-exists-on-property-modal"
          contractId={this.state.existingContractModalDetails.existingContract}
          existingContractSalesChannel={
            this.state.existingContractModalDetails.existingContractSalesChannel
          }
          existingContractAddress={this.state.existingContractModalDetails.existingContractAddress}
          existingOrderInitiatingAgentExists={
            this.state.existingContractModalDetails.existingOrderInitiatingAgentExists
          }
          existingContractInitiatingAgentName={
            this.state.existingContractModalDetails.existingContractInitiatingAgentName
          }
          existingContractInitiatingAgentOffice={
            this.state.existingContractModalDetails.existingContractInitiatingAgentOffice
          }
          existingOrderInitiatingAgentMatch={
            this.state.existingContractModalDetails.existingOrderInitiatingAgentMatch
          }
          initiatingAgentRepresentBoth={
            this.state.existingContractModalDetails.initiatingAgentRepresentBoth
          }
          pSelectedREAgentId={
            this.state.initiatingAgent.agentID.length > 0
              ? this.state.initiatingAgent.agentID
              : this.props.userProfile?.roleID
          }
          pSelectedREAgentName={
            this.state.initiatingAgent.agentName.length > 0
              ? this.state.initiatingAgent.agentName
              : `${this.props.userProfile?.firstName} ${this.props.userProfile?.lastName}`
          }
          pSelectedREAgentOfficeId={
            this.state.initiatingAgent.officeID.length > 0
              ? this.state.initiatingAgent.officeID
              : this.props.userProfile?.offices[0]?.id
          }
          exisitingOrderInitiatingOfficeOnUserProfile={
            this.state.existingContractModalDetails.existingOrderInitiatingOfficeOnUserProfile
          }
          existingOrderClosingAgentExists={
            this.state.existingContractModalDetails.existingOrderClosingAgentExists
          }
          existingOrderCooperatingAgentExists={
            this.state.existingContractModalDetails.existingOrderCooperatingAgentExists
          }
          user={this.props.userProfile}
          heading={this.state.existingContractModalDetails.existingContractModalHeader}
          isActive={this.state.existingContractModalDetails.showContractExistsOnPropertyModal}
          onClose={this.closeContractExistsOnPropertyModal}
        />

        <AddressVerification
          id="validate-address--order"
          items={this.state.addressVerification.addressVerificationItems}
          isActive={this.state.addressVerification.enableAddressVerification}
          onVerifiedAddress={this.updateAddressFromVerification}
          onComplete={() => this.onAddressVerificationSubmit()}
          onReset={() => this.onAddressVerificationReset()}
          disableUnverifiedAddressCreation={false}
          onVerifyAddressError={(err) => {
            this.onUpdateErrorAddressInfoField(false, OrderFormAddressInfoField.streetAddress, err);
            this.onAddressVerificationReset();
          }}
        />
      </>
    );
  }
}
