import React from 'react';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { GlobalSearchRestrictions, OfficeIDAndType, REOrder } from '@apis/models';
import { debounce } from 'lodash';
import ContractApi from '@apis/contract.api';
import { Canceler } from 'axios';
import { IconSearch, Text } from '@ftdr/blueprint-components-react';
import SearchBar from '@components/input/SearchBar';
import { IconTypes } from '@components/card/CardFilterBar';
import LoadingIndicator from '@components/spinner/LoadingIndicator';

export type ContractSearchBarHeaderContentRendererFunc = (
  orders: REOrder[],
  searchState?: State,
) => JSX.Element;

export interface ContractSearchBarProps {
  placeHolder?: string;
  includedStatuses?: RealEstateStatus[];
  filterByOffices?: OfficeIDAndType[];
  filterByAgents?: OfficeIDAndType[];
  globalSearchRestrictions?: GlobalSearchRestrictions[];
  unsuccessfulSearchErrorMessage?: string;
  searchedContractsRenderer: ContractSearchBarHeaderContentRendererFunc;
  headerContentRenderer?: (searchState: State) => JSX.Element;
  footerContentRenderer?: (searchState: State) => JSX.Element;
  onSearchTextChange?: (searchText: string) => void;
  onSearchResultChange?: (searchResults: REOrder[]) => void;
}

interface State {
  searchedContracts: REOrder[];
  activePage: number;
  searchTerm: string;
  hasSearched: boolean;
}

const MINIMUM_QUERY_CHARS = 3;
const SEARCH_DEBOUNCE_OFFSET = 200;
let searchCanceler: Canceler;

export default class ContractSearchBar extends React.Component<ContractSearchBarProps, State> {
  lastContractSearch = Date.now();

  static defaultProps = {
    unsuccessfulSearchErrorMessage:
      'Order Not Found. Please try again, considering spelling and order status.',
    placeHolder: 'Search',
    headerContentRenderer: () => <></>,
    footerContentRenderer: () => <></>,
    filterByOffices: [],
    filterByAgents: [],
    globalSearchRestrictions: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      searchedContracts: [],
      activePage: 1,
      searchTerm: '',
      hasSearched: false,
    };
  }

  debouncedGlobalSearch = debounce((searchText: string) => {
    const callMade = Date.now();
    searchCanceler?.();
    const [search, currentSearchCanceler] = ContractApi.searchGlobalContracts({
      query: searchText,
      offices: this.props.filterByOffices,
      agents: this.props.filterByAgents,
      restrictions: this.props.globalSearchRestrictions,
    });
    searchCanceler = currentSearchCanceler;

    search()
      .then((res) => {
        if (this.lastContractSearch < callMade) {
          // This prevents an older call from overwriting a newer one, when the user types quickly
          this.lastContractSearch = callMade;

          // If response returned nothing, display no contract found
          if (!res || res.meta?.total === 0) {
            this.setState({ searchedContracts: [] });
            this.props.onSearchResultChange?.([]);
            return;
          }

          if (this.props.includedStatuses) {
            const filteredOrders: REOrder[] = [];
            res.orders.map((order) => {
              if (this.props.includedStatuses.includes(order.realEstateStatus)) {
                filteredOrders.push(order);
              }
            });
            this.setState({ searchedContracts: filteredOrders });
            this.props.onSearchResultChange?.(filteredOrders);
          } else {
            this.setState({ searchedContracts: res.orders });
            this.props.onSearchResultChange?.(res.orders);
          }
        }
      })
      .finally(() => {
        this.setState({ hasSearched: true });
      });
  }, SEARCH_DEBOUNCE_OFFSET);

  // TODO This code was pulled almost verbatim from Header search
  // Small differences prevented easy componentization
  // With a small amount of extra work, it could probably be done (Errors et al. were different, but could be toggleable)
  handleSearch = (searchText: string) => {
    this.setState({ searchTerm: searchText });
    this.props.onSearchTextChange?.(searchText);

    if (searchText && searchText.length >= MINIMUM_QUERY_CHARS) {
      this.debouncedGlobalSearch(searchText);
    } else {
      this.setState({ searchedContracts: [], hasSearched: false });
    }
  };

  render() {
    return (
      <div className="full-height-card flex flex-col w-full">
        {this.props.headerContentRenderer?.(this.state)}

        <div className="md:mt-px lg:py-px">
          <SearchBar
            searchText={this.state.searchTerm}
            onChange={this.handleSearch}
            icon={IconTypes.Search}
            placeHolder={this.props.placeHolder}
            showClearButton={true}
          />
        </div>

        <div className="w-full mt-6">
          {this.state.searchedContracts.length > 0 &&
            this.props.searchedContractsRenderer(this.state.searchedContracts, this.state)}

          {!this.state.hasSearched && this.state.searchTerm.length >= MINIMUM_QUERY_CHARS && (
            <LoadingIndicator />
          )}
          {this.state.searchedContracts.length === 0 && this.state.hasSearched && (
            // TODO This pattern is used elsewhere but a bit different, some work and it can be componentized
            // Part of the problem is that this isn't standardized yet (Color and text differences)
            <div className="text-center">
              <IconSearch size={120} className="mb-2 inline" color="gray" />
              <br />
              <Text variant="body-short">
                <b>Order Not Found</b>
              </Text>
              <Text variant="caption">{this.props.unsuccessfulSearchErrorMessage}</Text>
            </div>
          )}

          {this.props.footerContentRenderer?.(this.state)}
        </div>
      </div>
    );
  }
}
