import React, { useContext, useEffect, useState } from 'react';
import Layout from '@components/layout/Layout';
import Skeleton from 'react-loading-skeleton';
import NewOrderTemplate from '@templates/order/NewOrderTemplate';
import validate from '@services/validation/ValidationRules';
import useForm from '@helpers/UseForm';
import ProfileContext from '../../context/ProfileContext/index';
import { UserRoleType } from '@apis/models/profile.api.model';
import { isAgent } from '@helpers/profile.utils';
import { useOptimizely } from '@context/OptimizelyContext';
import { useCurrentRoute, useNavigation } from 'react-navi';
import Path from '@constants/paths';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { Order } from '@apis/models/orders.api.model';
import { OrdersApiSuppressErrors } from '@apis/orders.api';
import { NewOrderData } from '@helpers/new-order-loader';
import { isBefore } from 'date-fns/fp';
import ProfileModel from '@app/models/profile.model';
import { PlansCoverageValues } from '@components/card/CardNewOrderPlansCoverage';

const defaultValues = { agentInfo: { isRealtorOwned: false } };

interface Props {}

export interface NewOrderParamData {
  from?: string;
  orderID?: string;
}

export interface NewOrderRouteData extends NewOrderParamData {
  source?: string;
  values?: NewOrderData;
  planCoverageValues?: PlansCoverageValues;
}

export enum NewOrderFromSource {
  MyOrders = 'MyOrders',
  MyQuotes = 'MyQuotes',
  ViewQuote = 'ViewQuote',
}

export enum IneligibleOrderFailReason {
  OrderNotFound = 'OrderNotFound',
  OrderExpired = 'OrderExpired',
  OrderAlreadySubmitted = 'OrderAlreadySubmitted',
}

/** determines if the order should be eligible for continuing new order */
export const canOrderContinueForSubmission = (
  order: Order,
  today = new Date(),
): IneligibleOrderFailReason => {
  if (!order) {
    return IneligibleOrderFailReason.OrderNotFound;
  }
  const ineligibleOrderStatuses = ['PENDING', 'COMPLETE'];
  if (ineligibleOrderStatuses.includes(order.status)) {
    return IneligibleOrderFailReason.OrderAlreadySubmitted;
  }
  if (order.orderExpiration && isBefore(today, new Date(order.orderExpiration))) {
    return IneligibleOrderFailReason.OrderExpired;
  }
  return null;
};

const NewOrder: React.FC<Props> = () => {
  const optimizely = useOptimizely();
  const navigation = useNavigation();
  const route = useCurrentRoute();
  const { showSpinner } = useGlobalOverlaySpinner();
  const preFillData = route.data as NewOrderRouteData;

  const { profile, fetchProfileData } = useContext(ProfileContext);
  const { values, setValues, handleSubmit } = useForm<any>(validate, defaultValues);
  const [planCoveragesValues, setPlanCoveragesValues] = useState<PlansCoverageValues>(null);
  const [userDetails, setUser] = React.useState<ProfileModel>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [existingOrderID, setExistingOrderID] = React.useState('');
  const [isNewOrder, setIsNewOrder] = React.useState(true);
  const [isQuoteConvertToOrder, setIsQuoteConvertToOrder] = useState<boolean>(false);
  const [shouldUserJumpToExistingOrderStep, setShouldUserJumpToExistingOrderStep] =
    useState<boolean>(false);
  const [shouldUserBeOfferedOldProducts, setShouldUserBeOfferedOldProducts] =
    useState<boolean>(false);
  const [existingOrder, setExistingOrder] = React.useState<Order>(null);

  function setClosingInfo(userProfile) {
    if (userProfile && userProfile.roleIDType === UserRoleType.ClosingCompanyAgent) {
      setValues({ ...values, closingInfo: getClosingInfo(userProfile) });
    }
  }

  function getClosingInfo(userProfile) {
    const closingCompany = userProfile.offices[0];

    return {
      dateUnknown: true,
      titleCompany: { yes: true },
      closingTitleCompany: closingCompany.name,
      office: closingCompany,
      closingOffice: closingCompany,
      AgentId: userProfile.roleID,
      AgentName: `${userProfile.firstName} ${userProfile.lastName}`,
      AgentEmail: userProfile.email,
      closingTitleAgent: `${userProfile.firstName} ${userProfile.lastName}`,
    };
  }

  /** used during page load for Start Order flow */
  const applyPrefillData = () => {
    if (preFillData) {
      if (preFillData.values) {
        setValues({ ...values, ...preFillData.values });
      }
      if (preFillData.planCoverageValues) {
        setPlanCoveragesValues(preFillData.planCoverageValues);
      }
    }
  };

  useEffect(() => {
    applyPrefillData();
    // redirect user to the new order redesign flow
    if (optimizely.variations.useorderredesign) {
      navigation.navigate(Path.NewOrderStep1);
      return;
    }
    refreshProfile();
  }, []);

  useEffect(() => {
    setUser(profile);
    setClosingInfo(profile);
  }, [profile]);

  useEffect(() => {
    if (!route.data?.params) {
      return;
    }
    const from = route.data?.params.from;

    const { orderID } = route.data.params as NewOrderParamData;
    if (!orderID) {
      return;
    }

    switch (from) {
      case NewOrderFromSource.MyOrders:
        setIsQuoteConvertToOrder(false);
        loadExistingOrder(orderID, true);
        break;
      case NewOrderFromSource.MyQuotes:
      case NewOrderFromSource.ViewQuote:
      default:
        setIsQuoteConvertToOrder(true);
        loadExistingOrder(orderID, false);
        break;
    }
  }, [route]);

  /** Runs the Orders endpoint call to get detailed order information to populate the page.
   *  If order isn't eligible for continuation, function halts.
   *  Otherwise, it will load the states for the page to start pre-populating.
   *  @param orderID - the order id to load information for
   *  @param shouldJumpToStep - would set the state to value. Set true if wish to jump user to latest step immediately. */
  const loadExistingOrder = (orderID: string, shouldJumpToStep: boolean) => {
    showSpinner(true);
    OrdersApiSuppressErrors.get(orderID)
      .then((order) => {
        // if ineligible order, navigate the user back to the page without the order id referenced anymore to make it obvious
        // otherwise, we display an alert of some sort, or ga event.
        const ineligibleOrderFailReason = canOrderContinueForSubmission(order);
        if (ineligibleOrderFailReason) {
          console.warn(
            'existing is not eligible for continuation. will ignore the existing order and allow new order created',
            orderID,
            order?.status,
            order?.orderExpiration,
          );
          navigation.navigate(`${Path.NewOrder}`, {
            replace: true, // prevents user from hitting back button and end up with the order again
          });
          return;
        }

        setExistingOrderID(orderID);
        setExistingOrder(order);
        setIsNewOrder(false); // loading an existing order means it is not a new order
        setShouldUserBeOfferedOldProducts(true); // always offer old products when loading existing order
        setShouldUserJumpToExistingOrderStep(shouldJumpToStep); // jump to next step only when needed
      })
      .catch((err) => {
        console.error('failed to load the order', err);
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  const refreshProfile = async () => {
    await fetchProfileData(!isAgent(profile) || !profile.authID);
    setIsLoading(false);
  };

  if (!isLoading) {
    return (
      <Layout slug="newOrder" isLoggedIn={true}>
        <NewOrderTemplate
          values={values}
          setValues={setValues}
          planCoveragesValues={planCoveragesValues}
          setPlanCoveragesValues={setPlanCoveragesValues}
          handleSubmit={handleSubmit}
          userDetails={userDetails}
          existingOrderID={existingOrderID}
          isNewOrder={isNewOrder}
          source={preFillData?.source}
          existingOrder={existingOrder}
          isQuoteConvertToOrder={isQuoteConvertToOrder}
          shouldUserJumpToExistingOrderStep={shouldUserJumpToExistingOrderStep}
          shouldUserBeOfferedOldProducts={shouldUserBeOfferedOldProducts}
        />
      </Layout>
    );
  }
  return <Skeleton />;
};

export default NewOrder;
