import React, { useState } from 'react';
import AdminSearch from '@templates/admin/AdminSearch';
import AdminUpload from '@templates/admin/AdminUpload';
import { Tab, Tabs } from '@ftdr/blueprint-components-react';

const AdminTemplate = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Tabs
      variant="horizontal"
      activeIndex={selectedTab}
      onActivate={(event, index, previousIndex) => {
        setSelectedTab(index);
      }}
    >
      <Tab label="Search">
        <AdminSearch />
      </Tab>
      <Tab label="File Upload">
        <AdminUpload />
      </Tab>
    </Tabs>
  );
};

export default AdminTemplate;
