/** *****************************************
 *  Tracking util functions for utag events
 ****************************************** */

import utag from './utag.service';
import { GAEventArgsMaster, GAEventView } from '@app/models/gaevents.model';

/** Fires a Google Analytics event. There are two types of events: interactive and non-interactive.
 */
export function fireGAEvent(e: GAEventArgsMaster): void {
  utag.link(e);
}

/** Fires a page view */
export function firePageView(e?: GAEventView): void {
  (window as any).utag_cfg_ovrd = {
    noview: true,
  };
  (window as any).utag_data = {
    user_city: e.user_city,
    user_id: e.user_id,
    user_state: e.user_state,
    user_type: e.user_type,
  };
  utag.view({ virtual_page_path: e.virtual_page_path });
}
