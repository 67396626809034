import { Button } from '@ftdr/blueprint-components-react';
import React from 'react';
import Modal from './Modal';
import InactiveOffice from './subcomponents/InactiveOffice';

const ModalInactiveOffice = (props) => {
  const getActions = () => {
    const actions = [
      <Button
        key="submit"
        label={props.offices?.length > 1 ? 'Remove Office' : 'Add Office'}
        size="medium"
        onClick={props.onClick}
        id="remove-office-inactive-modal"
      />,
    ];

    if (props.offices?.length > 1) {
      actions.push(
        <Button
          label="Keep Office"
          size="medium"
          variant="ghost"
          onClick={props.onClickKeep}
          id="keep-office-inactive-modal"
        />,
      );
    }
    return actions;
  };

  return (
    <Modal
      isActive={props.isActive}
      onClose={props.onClose}
      heading="Office Inactivated"
      actions={getActions()}
      canClose={false}
    >
      {props.isActive && <InactiveOffice {...props} />}
    </Modal>
  );
};

export default ModalInactiveOffice;
