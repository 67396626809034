/** generic interface for zesty response */
export interface IZestyResponse<M, T> {
  meta: M;
  data: {
    content: T;
  }[];
}

export type ZestyWYSIWYGField = string;
export type ZestyToggleField = '0' | '1';

export interface ZestyLink {
  text: string;
  link: string;
}

export interface ZestyLinkResponse {
  links: ZestyLink[];
}

export interface ZestyModel {
  type: string;
  zuid: string;
  name: string;
  label: string;
  resourceURI: string;
}

export interface ZestyMeta {
  type: string;
  zuid: string;
  createdAt: string;
  model: ZestyModel;
  totalItems: number;
}

export interface FAQModel {
  type: string;
  zuid: string;
  name: string;
  label: string;
  resourceURI: string;
}

export interface Parent {
  type: string;
  zuid: number;
  resourceURI?: any;
  note: string;
}

export interface ZestyItemMeta {
  type: string;
  zuid: string;
  name: string;
  title: string;
  description: string;
  keywords?: any;
  language: string;
  model: FAQModel;
  parent: Parent;
}

export interface FAQCategoryData {
  type: string;
  zuid: string;
  resourceURI: string;
}

export interface FAQResponseCategory {
  type: string;
  totalItems: number;
  data: FAQCategoryData[];
}

export interface FAQContent {
  sort: string;
  question: string;
  answer: string;
  category: FAQResponseCategory;
}

export interface ZestyPromoContent {
  states: string;
  status: string;
  start_date: string;
  end_date: string;
  small_banner_image: ZestyMediaFull;
  small_banner_text: ZestyWYSIWYGField;
  display_banner_pages: any;
}

export interface ZestyPromoData {
  meta: ZestyItemMeta;
  content: ZestyPromoContent;
}

export interface FAQData {
  meta: ZestyItemMeta;
  content: FAQContent;
}

export interface ZestyPageResponse {
  data: ZestyPageData;
}

export interface ZestyPageData {
  meta: ZestyMeta;
  content: PageContent;
}

export interface PageContent {
  zuid: any;
}

export interface ZestyPromoResponse {
  meta: ZestyMeta;
  data: ZestyPromoData[];
}

export interface ZestyFAQResponse {
  meta: ZestyMeta;
  data: FAQData[];
}

export interface Promo {
  states: string;
  status: string;
  startDate: string;
  endDate: string;
  smallBannerImage: string;
  smallBannerText: ZestyWYSIWYGField;
  displayPages: string[];
}

export interface ToggleContent {
  feature: string;
  status: number;
  branchonly?: boolean;
}

export interface ToggleData {
  meta: ZestyItemMeta;
  content: ToggleContent;
}

export interface ZestyToggleResponse {
  meta: ZestyMeta;
  data: ToggleData[];
}

export interface ZestyCategoryResponse {
  meta: ZestyMeta;
  data: CategoryData[];
}

export interface CategoryData {
  meta: ZestyItemMeta;
  content: CategoryContent;
}

export interface CategoryContent {
  sort: string;
  name: string;
}

export interface MerchandisingContent {
  copyText: string;
  merchandisingUrl: string;
  isActive: boolean;
  imageIconUrl: string;
  navIconUrl: string;
  ctaText: string;
}

export interface SalesMaterial {
  title: string;
  sort: number;
  image: string;
  media: Media[];
  description: string;
  externalMedia: string;
  brand: string;
}

export interface Media {
  url: string;
  zuid: string;
}

export interface ZestySalesMaterialResponse {
  meta: ZestyMeta;
  data: ZestySalesMaterialFull[];
}

export interface ZestySalesMaterialFull {
  content: ZestySalesMaterial;
  meta: ZestyItemMeta;
}

export interface ZestySalesMaterial {
  brand: string;
  description: string;
  external_media: string;
  image: ZestyMediaFull;
  media: ZestyMediaFull;
  sort_order: string;
  title: string;
}

export interface ZestyMediaFull {
  data: ZestyMedia[];
  totalItems: number;
  type: string;
}

export interface ZestyMedia {
  type: string;
  url: string;
  zuid: string;
}

export interface ZestyBannerResponse {
  meta: ZestyMeta;
  data: ZestyBannerFull[];
}

export interface ZestyBannerFull {
  content: ZestyBanner;
  meta: ZestyItemMeta;
}

export interface ZestyBanner {
  AHS: boolean;
  HSA: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  loginpage: boolean;
  masterswitch: boolean;
  message: string;
}

export type ZestyPlansAndPricesResponse = IZestyResponse<ZestyMeta, ZestyPlansAndPrices>;
export type ZestyPlansAndPricesPlansResponse = IZestyResponse<ZestyMeta, ZestyPlansAndPricesPlans>;
export type ZestyPlansAndPricesCoveragesResponse = IZestyResponse<
  ZestyMeta,
  ZestyPlansAndPricesCoverage
>;

export interface ZestyPlansAndPrices {
  enabled: ZestyToggleField;
  tenant: string;
  page_header: string;
  page_header_subtext: ZestyWYSIWYGField;
  tscf_text: ZestyWYSIWYGField;
  tscf_tooltip: ZestyWYSIWYGField;
  footnote: ZestyWYSIWYGField;
  plans: {
    totalItems: number;
    data: {
      zuid: string;
      resourceURI: string;
    }[];
  };
}

export interface ZestyPlansAndPricesPlans {
  lookup_text: string;
  plan_subtext: ZestyWYSIWYGField;
  coverage_items: ZestyWYSIWYGField;
  brand: string;
}

export interface ZestyPlansAndPricesCoverage {
  coverage_id: string;
  display_sequence: number;
  optional_coverage_icon: OptionalCoverageIconValue;
  updated_name: ZestyWYSIWYGField;
  description: ZestyWYSIWYGField;
  brand: string;
  max_quantity: number;
}

/** dropdown values */
export enum OptionalCoverageIconValue {
  Electronics = 'iconelectronics',
  GuestHouse = 'iconguesthouse',
  HomeWarranty = 'iconhomewarranty',
  PoolSpa = 'iconpoolspa',
  Refrigerator = 'iconrefrigerator',
  RoofCoverage = 'iconroofcoverage',
  SepticPump = 'iconsepticpump',
  WellPump = 'iconwellpump',
  Cooling = 'iconcooling',
  Dishwasher = 'icondishwasher',
  Heating = 'iconheating',
  Microwave = 'iconmicrowave',
  RangeOven = 'iconrangeoven',
  ShieldAssurances = 'iconshieldassurances',
  TubMotor = 'icontubmotor',
  Washer = 'iconwasher',
  WaterHeater = 'iconwaterheater',
}

export interface PlansAndPricesMetaData
  extends Omit<ZestyPlansAndPrices, 'enabled' | 'tenant' | 'plans'> {
  plans: ZestyPlansAndPricesPlans[];
}

// TODO Replace strings with enums where appropriate.
// TODO Move related zesty types (e.g. all Splash Screen types) to their own file.

export enum ZestySplashScreenLearnMoreBehavior {
  MediaInNewTab = 'media_new',
  UrlInNewTab = 'url_new',
  UrlInSameTab = 'url_same',
}

export interface ZestySplashScreenResponse {
  meta: ZestyMeta;
  data: ZestySplashScreenFull[];
}

export interface ZestySplashScreenFull {
  content: ZestySplashScreen;
  meta: ZestyItemMeta;
}

export interface ZestySplashScreen {
  zuid: string; // used for dismissal tracking
  nickname: string;
  background_image: ZestyMediaFull;
  headline: string;
  body_text: string;
  time_period_begin: Date;
  time_period_end: Date;
  ahs_enabled: boolean;
  hsa_enabled: boolean;
  learn_more_behavior: ZestySplashScreenLearnMoreBehavior | string;
  linked_url: string;
  linked_media: ZestyMediaFull;
  states: string;
}

export enum ZestyDashboardWhatsNewCtaBehavior {
  NoCta = 'no_cta',
  MediaInNewTab = 'media_new',
  UrlInNewTab = 'url_new',
  UrlInSameTab = 'url_same',
}

export interface ZestyDashboardWhatsNewResponse {
  meta: ZestyMeta;
  data: ZestyDashboardWhatsNewFull[];
}

export interface ZestyDashboardWhatsNewFull {
  content: ZestyDashboardWhatsNew;
  meta: ZestyItemMeta;
}

export interface ZestyDashboardWhatsNew {
  sortOrder: number;
  header: string;
  body: string;
  ctaBehavior: ZestyDashboardWhatsNewCtaBehavior | string;
  ctaText: string;
  ctaLinkedMedia: ZestyMediaFull;
  ctaLinkedUrl: string;
  brand: string;
}

export type ZestyMarketingArticlesResponse = IZestyResponse<ZestyMeta, ZestyMarketingArticle>;

export interface ZestyMarketingArticle {
  enable: ZestyToggleField;
  category_header: string;
  title: ZestyWYSIWYGField;
  text: ZestyWYSIWYGField;
  link: string;
  link_text: string;
  image: ZestyMediaFull;
  brand: string;
}

export type ZestyPrivacyPolicyResponse = IZestyResponse<ZestyMeta, ZestyPrivacyPolicy>;

export interface ZestyPrivacyPolicy {
  content: ZestyWYSIWYGField;
}

export type ZestyTermsOfUseResponse = IZestyResponse<ZestyMeta, ZestyTermsOfUse>;

export interface ZestyTermsOfUse {
  content: ZestyWYSIWYGField;
  brand: string;
}

export type ZestyWelcomeDashboardNotificationResponse = IZestyResponse<
  ZestyMeta,
  ZestyWelcomeDashboardNotification
>;

export interface ZestyWelcomeDashboardNotification {
  notification: ZestyWYSIWYGField;
  notification_url: string;
  link_text: string;
  active: ZestyToggleField;
  brand: string;
  states: string;
  location: string;
}

export enum ZestyDashboardNotificationsLocation {
  Top = 'top',
  Bottom = 'bottom',
}
