import React from 'react';
import Layout from '@components/layout/Layout';
import OrderSubmittedTemplate from '@templates/order/OrderSubmittedTemplate';
import { useCurrentRoute } from 'react-navi';

const OrderSubmitted = () => {
  const route = useCurrentRoute();
  const { contractId, newOrderData, userDetails, roFlow, pricing, orderID } = route.data;
  return (
    <Layout isLoggedIn={true}>
      <div className="container xs-max:p-0 pb-8">
        <OrderSubmittedTemplate
          newOrderData={newOrderData}
          userDetails={userDetails}
          contractId={contractId}
          roFlow={roFlow}
          pricing={pricing}
          orderID={orderID}
        />
      </div>
    </Layout>
  );
};
export default OrderSubmitted;
