import { getConfig } from '@services/app.config.loader';
import { authProvider } from '@constants/auth-providers';
import { isNullOrUndefined } from '@helpers/utils';
import { OidcUser } from '@ftdr/pkce-js';
import { clearProfileSentryTags } from '@app/core/sentry.service';

const IdentityProviderClientID = 'IdentityProviderClientID';
const IdentityProviderBaseUrl = 'IdentityProviderBaseUrl';
const IdentityProviderTenantID = 'IdentityProviderTenantID';
const IdentityIssuer = 'IdentityIssuer';

export type JWT = {
  partner: string;
  iss: string;
  exp: number;
  email?: string;
};

export function parseJwt(token: string): JWT {
  const base64Url = token.split('.')[1];

  if (base64Url === undefined) return {} as JWT;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  try {
    const parsed = JSON.parse(jsonPayload);
    return parsed;
  } catch (e) {
    return {} as JWT;
  }
}

export function providerType(jwt: string): 'corporate' | 'customer' | 'none' {
  if (jwt) {
    const jwtIss = getConfig(IdentityIssuer);
    const jwtObj = parseJwt(jwt || '') as JWT;
    if (jwtObj && jwtObj.iss === jwtIss) {
      return authProvider.CUSTOMER;
    }
    if (jwtObj.iss.includes('sts.windows.net')) {
      return authProvider.CORPORATE;
    }
  }
  return authProvider.NONE;
}

function getVerifyEmailURL(): string {
  return '/verifyEmail';
}

export const redirectToVerifyEmail = () => {
  window.location.assign(getVerifyEmailURL());
};

export const clearAuthUser = () => {
  for (const key in localStorage) {
    if (key.includes('oidc.user:')) {
      // to get an updated value for email_verified we have to delete the
      // current oidc user to force a re-fetch
      localStorage.removeItem(key);
    }
  }
};

export const emailVerified = (oidcUser: OidcUser): boolean => {
  return isNullOrUndefined(oidcUser.profile.email_verified) || oidcUser.profile.email_verified;
};

export const getLogoutURL = () => {
  const paramsMap = {
    client_id: getConfig(IdentityProviderClientID) || '',
    tenantId: getConfig(IdentityProviderTenantID) || '',
    post_logout_redirect_uri: encodeURI(getRedirectBase()),
  };
  const queryParams = mapToRequestParams(paramsMap);
  const loginUrl = `${getConfig(IdentityProviderBaseUrl)}/oauth2/logout`;
  const logoutURL = `${loginUrl}?${queryParams}`;
  return logoutURL;
};

export const hardLogout = () => {
  try {
    clearProfileSentryTags();
    clearAuthUser();
    window.location.assign(getLogoutURL());
  } catch (error) {
    console.error('could not log out');
  }
};

export function forceRefreshToken() {
  clearAuthUser();
  window.location.reload();
}

function getRedirectBase() {
  const base = window.location.origin;
  if (base.substring(-1) === '/') {
    return base.substr(0, base.length - 1);
  }
  return base;
}

const encodeURIKeyValue = (uriKeyValue: [string, string]) =>
  uriKeyValue.map(encodeURIComponent).join('=');
const mapToRequestParams = (paramsMap: { [key: string]: string }) =>
  Object.entries(paramsMap).map(encodeURIKeyValue).join('&');
