import React, { Component } from 'react';
import {
  FilterOperation,
  FilterType,
  QuoteStatus,
  RealEstateStatus,
  WarrantylinkStatus,
} from '@constants/dashboardFilters';
import { orderStatusDictionary, UserRoleType } from '@constants/dictionaries';
import { Badge, Checkbox } from '@ftdr/blueprint-components-react';
import { FilterTagItem } from '@components/misc/misc.models';

interface state {}

interface props {
  addFilter: any;
  activeFilters: FilterTagItem[];
  filterAlerts?: any;
  roleIDType: any;
  statusMenuType?: StatusMenuType;
}

export enum StatusMenuType {
  Orders = 'orders',
  WarrantyLink = 'warrantyLink',
  WarrantyLinkPayments = 'warrantyLinkPayments',
  Quotes = 'quotes',
  abandonedOrders = 'abandonedOrders',
}

class OrderStatusFilter extends Component<props, state> {
  // statuses to not display to the user for filter selection
  HIDDEN_ORDER_STATUSES: RealEstateStatus[] = [].filter((s) => !!s);

  HIDDEN_WARRANTYLINK_STATUSES: WarrantylinkStatus[] = [
    WarrantylinkStatus.READY,
    WarrantylinkStatus.SAVED,
  ];

  static defaultProps = {
    statusMenuType: StatusMenuType.Orders,
  };

  statusMenu = {
    orders: Object.values(RealEstateStatus).filter(
      (status) => !this.HIDDEN_ORDER_STATUSES.includes(status),
    ) as [],
    warrantyLink: Object.values(WarrantylinkStatus).filter(
      (status) => !this.HIDDEN_WARRANTYLINK_STATUSES.includes(status),
    ) as [],
    quotes: Object.values(QuoteStatus) as [],
  };

  criticalStatus = [
    RealEstateStatus.PAYMENT_DUE,
    RealEstateStatus.PAYMENT_PAST_DUE,
    RealEstateStatus.EXPIRED,
    RealEstateStatus.EXPIRING,
  ];

  onChange = (status, event) => {
    this.props.addFilter(undefined, {
      operation: event.target.checked ? FilterOperation.ADD : FilterOperation.REMOVE,
      type: FilterType.STATUS,
      payload: { status },
      label: `Status: ${orderStatusDictionary[status] || status}`,
    });
  };

  render() {
    return (
      <div className="mb-6">
        {this.statusMenu[this.props.statusMenuType].map((status) => {
          if (
            status === RealEstateStatus.AWAITING_WL_SUBMISSION &&
            [UserRoleType.ClosingCompanyAdmin, UserRoleType.ClosingCompanyAgent].includes(
              this.props.roleIDType,
            )
          ) {
            return;
          }
          // ARE-9853. Remove WLK from checklist
          if (status != RealEstateStatus.AWAITING_WL_SUBMISSION) {
            return (
              <div className="w-full h-6" key={status}>
                <Checkbox
                  checked={this.props.activeFilters.some(
                    (filter) => filter.payload.status === status,
                  )}
                  label={orderStatusDictionary[status]}
                  onChange={(event) => this.onChange(status, event)}
                />
                {this.props.filterAlerts && this.props.filterAlerts[status] > 0 && (
                  <Badge
                    color={this.criticalStatus.includes(status) ? 'secondary' : 'gray'}
                    size="small"
                    maxCount={99}
                    className="absolute ml-2 mt-2"
                  >
                    {this.props.filterAlerts[status].toString()}
                  </Badge>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default OrderStatusFilter;
