import React, { Component } from 'react';
import Layout from '@components/layout/Layout';
import Template from '@templates/account/MyAccountEditTemplate';
import ProfileApi from '@apis/profile.api';
import ProfileModel, { ProfileOffice } from '@app/models/profile.model';
import { OfficeRequest, UpdateOfficeRequest } from '@apis/models/profile.api.model';
import * as app from '@app/models';
import merge from 'lodash/merge';
import { forceRefreshToken } from '@helpers/auth.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import { ACCOUNT_MANAGEMENT__FIELD_UPDATE } from '@constants/ga-events.constants';
import { loggedInEmail } from '@app/auth/authService';
import OfficeApi from '../../services/apis/office.api';
import ProfileContext from '../../context/ProfileContext/index';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs from '@app/locales/en';

interface State {
  userDetail: ProfileModel;
  errors: any;
  handlePhoneFormat: any;
  isFetching: boolean;
  userOfficeDetail: app.Office[];
  userWorkedWithOfficeDetail: app.Office[];
}

enum AccountFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
}

interface Props {}

const EMAIL_TAKEN_ERROR_CODE = '20014';

export default class EditMyAccountTemplate extends Component<Props, State> {
  static contextType = ProfileContext;
  constructor(props) {
    super(props);

    this.state = {
      userDetail: new ProfileModel(),
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        emailPassword: '',
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        phoneNumber: '',
        narIdentificationNumber: '',
      },
      handlePhoneFormat: {},
      isFetching: true,
      userOfficeDetail: null,
      userWorkedWithOfficeDetail: null,
    };
  }

  async componentDidMount() {
    const { profile } = this.context;
    if (profile) {
      this.setState({ userDetail: this.setLoggedInEmail(profile), isFetching: false });
      this.fetchOfficeDetails(profile);
    }
  }

  fetchOfficeDetails = async (profile: ProfileModel) => {
    const profileOffices = profile.offices;
    const profileWorkedWithOffices = profile.workedWithOffices;
    const offices = [...profileOffices, ...profileWorkedWithOffices];
    const userOffices = await OfficeApi.getAppOfficeList(
      offices.map((office) => {
        return { id: office.id, type: office.type };
      }),
    );
    // get only the profile offices from endpoint result
    const OfficeList: app.Office[] = userOffices.filter((office) =>
      profileOffices.map((o) => o.id).includes(office.id),
    );
    // get only worked with offices from endpoint list
    const WorkedWithOfficeList: app.Office[] = userOffices.filter((office) =>
      profileWorkedWithOffices.map((o) => o.id).includes(office.id),
    );
    this.setState({ userOfficeDetail: OfficeList });
    this.setState({ userWorkedWithOfficeDetail: WorkedWithOfficeList });
  };

  updateUser = (userDetail: ProfileModel): void => {
    this.setState({ userDetail: this.setLoggedInEmail(userDetail) });
    try {
      ProfileApi.updateUser(userDetail).then((response) => {
        if (response.isAxiosError) {
          const { errors } = response.data;
          if (errors[0]?.code === EMAIL_TAKEN_ERROR_CODE) {
            useGlobalAlert().addErrorToQueue(msgs.EMAIL_TAKEN(userDetail.email));
          } else if (errors && errors.length >= 1) {
            errors.forEach((error) => {
              const errorsObj = this.state.errors;
              errorsObj[error.field] = error.message;
              this.setState({ errors: errorsObj });
            });
          } else {
            useGlobalAlert().addErrorToQueue(msgs.SAVE_PROFILE_ERROR);
          }
          const { profile } = this.context;
          this.setState({ userDetail: this.setLoggedInEmail(profile) });
        } else {
          useGlobalAlert().addSuccessToQueue(msgs.PROFILE_UPDATE_SUCCESS);
          this.logProfileChangeGAEvents(userDetail);
          this.context.setProfile(userDetail);
          // we need to update the jwt token when the email has been updated.
          if (userDetail.email !== loggedInEmail()) {
            forceRefreshToken();
          }
        }
      });
    } catch (error) {
      console.warn(error);
    }
  };

  logProfileChangeGAEvents = (updatedUser: ProfileModel) => {
    const { profile } = this.context;

    if (profile.lastName !== updatedUser.lastName) {
      fireGAEvent(ACCOUNT_MANAGEMENT__FIELD_UPDATE(AccountFields.LAST_NAME));
    }
    if (profile.firstName !== updatedUser.firstName) {
      fireGAEvent(ACCOUNT_MANAGEMENT__FIELD_UPDATE(AccountFields.FIRST_NAME));
    }
    if (profile.email !== updatedUser.email) {
      fireGAEvent(ACCOUNT_MANAGEMENT__FIELD_UPDATE(AccountFields.EMAIL));
    }
    if (profile.phoneNumber !== updatedUser.phoneNumber) {
      fireGAEvent(ACCOUNT_MANAGEMENT__FIELD_UPDATE(AccountFields.PHONE_NUMBER));
    }
  };

  refreshUser = async () => {
    const profile: ProfileModel = await ProfileApi.getUser();
    if (profile) {
      this.context.setProfile(profile);
      this.fetchOfficeDetails(profile);
      this.setState({ userDetail: this.setLoggedInEmail(profile) });
    }
  };

  removeUserOffice = async (updatedOfficeList: OfficeRequest[], isWorkedWithOffice = false) => {
    let updateUsersOfficeDetailsResponse: any = null; // success response is "success"
    let updateUserResponse: any = null; // success response is ""
    try {
      if (isWorkedWithOffice) {
        const { profile } = this.context;
        const req: ProfileModel = profile;
        req.workedWithOffices = updatedOfficeList.map((o) => {
          const p: ProfileOffice = {
            active: true,
            type: o.type,
            id: o.id,
            userExtensions: null,
            address: null,
            warrantyLinkEligible: false,
          };
          return p;
        });
        updateUserResponse = await ProfileApi.updateUser(req);
      } else {
        const req: UpdateOfficeRequest = {
          profileID: this.state.userDetail.profileID,
          offices: updatedOfficeList,
        };
        updateUsersOfficeDetailsResponse = await ProfileApi.updateUsersOfficeDetails(req);
      }

      if (updateUsersOfficeDetailsResponse === 'success' || updateUserResponse === '') {
        const profile = await ProfileApi.getUser();
        if (profile) {
          this.context.setProfile(profile);
        }
        this.fetchOfficeDetails(profile);
        useGlobalAlert().addSuccessToQueue(msgs.REMOVE_OFFICE_SUCCESS);
        this.setState({ userDetail: this.setLoggedInEmail(profile) });
      } else {
        useGlobalAlert().addErrorToQueue(msgs.REMOVE_OFFICE_ERROR);
      }
    } catch (e) {
      useGlobalAlert().addErrorToQueue(msgs.REMOVE_OFFICE_ERROR);
    }
  };

  setLoggedInEmail = (profile: ProfileModel): ProfileModel => {
    const updatedProfile = new ProfileModel();
    merge(updatedProfile, profile);
    updatedProfile.email = loggedInEmail(); // use the email from the token
    return updatedProfile;
  };

  render() {
    const {
      errors,
      handlePhoneFormat,
      isFetching,
      userOfficeDetail,
      userWorkedWithOfficeDetail,
      userDetail,
    } = this.state;
    return (
      <Layout isLoggedIn={true}>
        {!isFetching && (
          <Template
            errors={errors}
            handlePhoneFormat={handlePhoneFormat}
            updateUser={this.updateUser}
            removeUserOffice={this.removeUserOffice}
            userOfficeDetail={userOfficeDetail}
            userWorkedWithOfficeDetail={userWorkedWithOfficeDetail}
            refreshUser={this.refreshUser}
            userDetails={userDetail}
          />
        )}
      </Layout>
    );
  }
}
