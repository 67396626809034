import React from 'react';

// Components
import Modal from '@components/modal/Modal';
import EditHistory from '@components/modal/subcomponents/EditHistory';

// TODO: Strict props don't play well with typescript for optional values. After converting this to typescript, go and see if there are default values provided

const ModalEditHistory = (props) => {
  return (
    <Modal id="edit-history-modal" heading="Edit History" {...props}>
      {props.isActive && <EditHistory {...props} />}
    </Modal>
  );
};

export default ModalEditHistory;
