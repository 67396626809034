import React, { useState } from 'react';
import Modal from '@components/modal/Modal';
import { Button, IconSearch, Text } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';

const idLookupID = 'idLookup'; // Used to filter and ignore from results
const dataTestID = 'data-testid';
const idLookupActive = 'data-idlookup'; // Used to highlight results

interface Props {
  color: string;
}

/**
 * A tool to allow seeing a list of available id and data-testid on the page.
 * Note that some of these elements may be rendered after a certain event trigger, meaning
 * some IDs may disappear or appear, hence why a reload button is needed.
 */
export const IDLookup: React.FC<Props> = (props) => {
  const [showLookupTool, setShowLookupTool] = useState(false);
  const [idList, setIDList] = useState([]);
  const [testIDList, setTestIDList] = useState([]);

  const description = `This tool allow seeing a list of available id and data-testid on the
    current page. Clicking on an id would highlight them for convenience. Any duplicates will be red.
    
    Keep in mind that elements may be rendered following certain events or conditions.
    In such a case, reload the list of IDs to see them.`;

  const onReload = () => {
    const toDataList = (selector: string, attrName?: string) => {
      return Array.from(document.querySelectorAll(selector))
        .map<string>((e) => (attrName ? e.attributes[attrName].value : e.id))
        .filter((id) => id.indexOf(idLookupID) === -1);
    };
    setIDList(toDataList('*[id]'));
    setTestIDList(toDataList(`*[${dataTestID}]`, dataTestID));
  };

  return (
    <>
      <a
        className={`text-${props.color} link-underline-hover hover:text-${props.color}`}
        onClick={() => setShowLookupTool(!showLookupTool)}
      >
        ID Lookup Tool
      </a>
      <Modal
        id={idLookupID}
        heading="ID Lookup Tool"
        isActive={showLookupTool}
        onClose={() => setShowLookupTool(false)}
      >
        <div className="flex flex-col">
          <Button
            id={`${idLookupID}--btn`}
            size="small"
            startIcon={<IconSearch />}
            label="Reload"
            labelAlign="center"
            width="auto"
            onClick={() => onReload()}
            className="mb-3"
          />
          <Text>{description}</Text>
        </div>
        <hr />

        <div className="flex">
          <IDLookupTable data={idList} header="id" />
          <IDLookupTable data={testIDList} attrName={dataTestID} header={dataTestID} />
        </div>
      </Modal>
    </>
  );
};

interface IDLookupProps {
  data: any[];
  attrName?: string;
  header: string;
}

export const IDLookupTable = (props: IDLookupProps) => {
  const [selected, setSelected] = useState(undefined);

  const onClick = (id: string) => {
    const tag = props.attrName || 'id';

    // Remove existing attributes from lookup
    const activeLookup = Array.from(document.querySelectorAll(`*[${idLookupActive}]`));
    activeLookup.forEach((e) => {
      e.removeAttribute(idLookupActive);
    });

    // Set attribute for lookup
    const eles = Array.from(document.querySelectorAll(`*[${tag}="${id}"]`));
    eles.forEach((e) => {
      e.setAttribute(idLookupActive, 'true');
    });

    // Set selection
    setSelected(id);
  };

  return (
    <div>
      <h4>{props.header}</h4>
      <ul className="list-disc">
        {props.data.map((id, idx) => (
          <li
            className={classNames([
              'cursor-pointer',
              id === selected && 'font-bold',
              props.data.indexOf(id) !== props.data.lastIndexOf(id) && 'text-red-400',
            ])}
            key={idx}
            onClick={() => onClick(id)}
          >
            {id}
          </li>
        ))}
      </ul>
    </div>
  );
};
