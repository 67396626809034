export const mockData = {
  propertyAddr: {
    zip: '38111',
    state: 'TN',
    city: 'Memphis',
    isDisabled: true,
    streetAddress: '341 alexander st',
  },
  propertyDetails: {
    residenceType: 'singleFamilyResidence',
    squareFootage: 'less',
    mlsNumber: '03235601',
    newConstruction: {
      yes: false,
      no: true,
    },
    age: 10,
    typeOfResidence: 'singleFamilyResidence',
  },
  agentInfo: {
    agentRepresents: {
      buyer: true,
      seller: false,
      both: false,
    },
    representing: 'Buyer',
    initiatingOffice: 'A-Z AFFORDABLE PROPERTIES, REALTORS',
    agentlist: [
      {
        realEstateAgentID: '4847701',
        agentType: 'RealEstate',
        active: true,
        narID: '',
        firstName: 'AARON  MANDEL',
        lastName: 'BANIS',
        phoneNumbers: [],
        email: '',
        officeID: '386951',
        archivedOfficeID: [],
      },
    ],
    office: {
      id: '386951',
    },
    initiatingAgent: 'AARON  MANDEL BANIS',
  },
  state: 'TN',
  city: 'Memphis',
  isDisabled: true,
  firstName: 'john',
  lastName: 'dear',
  email: 'john@gmail.com',
  coBuyerFields: false,
  coCustomerFirstName: 'Aron',
  coCustomerEmail: 'aron@gmail.com',
  coCustomerLastName: 'fintch',
  coCustomerPhone: '(123) 456-7890',
  buyerInfo: {
    state: '',
    city: '',
    isDisabled: false,
    coBuyerFields: true,
    alternateAddressFields: true,
    coBuyerAlternateAddressFields: true,
    coCustomerZipCode: '38103',
    coCustomerStreetAddress: '4193 Red Hawk Road',
    coCustomerUnit: '',
    coCustomerCity: 'Mpheshs',
    coCustomerState: 'TN',
    coCustomerFirstName: 'Joey',
    coCustomerEmail: 'mirfin_joey@gmail.com',
    coCustomerLastName: 'Mirfin',
    coCustomerPhone: '(789) 456-7890',
    zipCode: '38103',
    streetAddress: '397 West College Street',
    unit: '',
    firstName: 'Karylin',
    lastName: 'Spencers',
    email: 'karylin_s@gmail.com',
    buyerInfo: {
      yes: true,
      no: false,
    },
    cooperatingOffice: 'ARMSTRONG ALLEN',
    agentlist: [
      {
        realEstateAgentID: '18065612',
        agentType: 'RealEstate',
        active: true,
        narID: '',
        firstName: 'HELLO',
        lastName: 'WORLD',
        phoneNumbers: [],
        email: 'hello.world@gmail.com',
        officeID: '64461',
        archivedOfficeID: [],
      },
      {
        realEstateAgentID: '18065622',
        agentType: 'RealEstate',
        active: true,
        narID: '',
        firstName: 'HELLO',
        lastName: 'WIRLD',
        phoneNumbers: [],
        email: 'hello@gmail.com',
        officeID: '64461',
        archivedOfficeID: [],
      },
      {
        realEstateAgentID: '18065632',
        agentType: 'RealEstate',
        active: true,
        narID: '',
        firstName: 'JAMES',
        lastName: 'WILLIAM',
        phoneNumbers: [],
        email: 'sfsdsfsfds@gmail.com',
        officeID: '64461',
        archivedOfficeID: [],
      },
    ],
    cooperatingAgent: 'JAMES WILLIAM',
    phone: '(123) 456-7890',
  },
  sellerInfo: {
    state: 'TN',
    city: 'Memphis',
    isDisabled: true,
    sellerInfo: {
      yes: true,
      no: false,
    },
    cooperatingOffice: 'A-Z AFFORDABLE PROPERTIES, REALTORS',
    agentlist: [
      {
        realEstateAgentID: '4847701',
        agentType: 'RealEstate',
        active: true,
        narID: '',
        firstName: 'AARON  MANDEL',
        lastName: 'BANIS',
        phoneNumbers: [],
        email: '',
        officeID: '386951',
        archivedOfficeID: [],
      },
    ],
    cooperatingAgent: 'AARON  MANDEL BANIS',
    firstName: 'Amy',
    lastName: 'Muscarnero',
    email: 'amy_m@gmail.com',
    phone: '(938) 383-8383',
    alternateAddressFields: true,
    zipCode: '38117',
    streetAddress: '20W456 Westminister Drive',
    coSellerFields: true,
    coCustomerFirstName: 'Megan Kelly',
    coCustomerLastName: 'William',
    coCustomerEmail: 'megan_kwilliam@gmail.com',
    coCustomerPhone: '(777) 456-7890',
    coSellerAlternateAddressFields: true,
    coCustomerZipCode: '38103',
    coCustomerStreetAddress: '1939 W Grace Street',
    coCustomerCity: 'Mpheshs',
    coCustomerState: 'TN',
  },
  cooperatingOffice: 'A-Z AFFORDABLE PROPERTIES, REALTORS',
  agentlist: [
    {
      realEstateAgentID: '4847701',
      agentType: 'RealEstate',
      active: true,
      narID: '',
      firstName: 'AARON  MANDEL',
      lastName: 'BANIS',
      phoneNumbers: [],
      email: '',
      officeID: '386951',
      archivedOfficeID: [],
    },
  ],
  cooperatingAgent: 'AARON  MANDEL BANIS',
  phone: '(938) 383-8383',
  alternateAddressFields: true,
  zipCode: '38117',
  streetAddress: '123',
  coSellerFields: true,
  coSellerAlternateAddressFields: true,
  coCustomerZipCode: '38103',
  coCustomerStreetAddress: 'Main street',
  coCustomerCity: 'Mpheshs',
  coCustomerState: 'TN',
  closingInfo: {
    closingFileNumber: '711',
    closingTitleAgent: 'JOHN SNOW',
    closingTitleCompany: 'ALLEGENCE TITLE JOHN MEAZELL',
    dateUnknown: false,
    projectedClosingDate: '07/23/2020',
    titleCompany: { yes: true, no: false },
    offices: [
      {
        officeDetail: {
          name: 'ALLEGENCE TITLE JOHN MEAZELL',
        },
      },
    ],
    userDetails: {
      firstName: 'JOHN',
      lastName: 'SNOW',
    },
  },
  plansCoverage: {
    sellersCoverage: { yes: true, no: false },
    warrantyTerm: {
      oneYear: false,
      twoYear: true,
      threeYear: false,
      fourYear: false,
    },
    activePlan: 'ShieldEssential',
  },
  meta: {
    total: 10,
  },
  orders: [
    {
      id: 0,
      address: {
        address1: '100 Madeup St.',
        address2: '#5',
        city: 'Memphis',
        state: 'TN',
        zip: '37067',
      },
      expirationDate: '2006-01-02',
      initiatingAgentName: 'Gary',
      initiatingOfficeName: 'Homes, Homes, Homes',
      status: 'A',
      realEstateStatus: 'active',
    },
  ],
};

export default {
  mockData,
};
