import React from 'react';
import InputWithValidation, {
  InputWithValidationProps,
} from '@components/input/InputWithValidation';
import { EMAIL_REQUIRED, INVALID_EMAIL } from '@constants/formField-constants';
import { isEmailValid } from '@services/validation/ValidationRules';
import merge from 'lodash/merge';

type OwnEmailInputProps = {
  /** set if need to have a custom error message for anything. */
  errorMessages?: EmailInputErrorMessages;
};

export type EmailInputProps = OwnEmailInputProps &
  Omit<
    InputWithValidationProps,
    'type' | 'inputMode' | 'formatChangeValue' | 'label' | 'validateValue'
  > &
  Partial<Pick<InputWithValidationProps, 'label' | 'validateValue'>>;

export type EmailInputErrorMessages = {
  errorRequired?: string;
  errorInvalidFormat?: string;
};

const DEFAULT_ERRORS: EmailInputErrorMessages = {
  errorRequired: EMAIL_REQUIRED,
  errorInvalidFormat: INVALID_EMAIL,
};

/** used to validate the EmailInput. If there are any special handling necessary, can re-use this function for any purpose.
 * @param value - the input value
 * @param emptyNotAllowed - true if the field is required and should perform empty check.
 * @param errorMessages - set if want to override any existing error message. Leave blank to use default error message. */
export const validateEmailInput = (
  value: string,
  emptyNotAllowed: boolean,
  errorMessages: EmailInputErrorMessages = {},
) => {
  const errors = merge({}, DEFAULT_ERRORS, errorMessages);
  if (emptyNotAllowed && value.length === 0) {
    return errors.errorRequired;
  }
  if (value.length > 0 && !isEmailValid(value)) {
    return errors.errorInvalidFormat;
  }
};

const EmailInput: React.FC<EmailInputProps> = (props) => {
  /** use the default validation function if there is no custom one. Otherwise, use the custom one. */
  const validateValue = (value: string) => {
    if (!props.validateValue) {
      return validateEmailInput(value, props.required, props.errorMessages);
    }
    return props.validateValue(value);
  };

  return (
    <InputWithValidation
      type="email"
      inputMode="email"
      label={props.label}
      validateValue={validateValue}
      formatChangeValue={(str) => str}
      autoComplete="off"
      {...props}
    />
  );
};

EmailInput.defaultProps = {
  label: 'Email Address',
};

export default EmailInput;
