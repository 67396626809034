import React from 'react';
import ModalMessagePopup from '@components/modal/ModalMessagePopup';
import msgs from '@app/locales/en';

interface Props {
  id?: string;
  isActive: boolean;
  onClose: () => void;
}

const ModalNoPlansFound: React.FC<Props> = (props) => {
  return (
    <ModalMessagePopup
      id={props.id}
      isActive={props.isActive}
      onClose={props.onClose}
      heading={msgs.NO_PLANS_FOUND.title}
      message={msgs.NO_PLANS_FOUND.message}
    />
  );
};

ModalNoPlansFound.defaultProps = {
  id: 'plans-unavailable-modal',
};

export default ModalNoPlansFound;
