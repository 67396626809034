export enum OrderWorkflow {
  NewOrder = 'RE-NewOrder',
  PlansPrices = 'RE-PlansPrices',
}

export enum OrderSourceApplication {
  REPortal = 'RE Portal',
}

export enum UnsubmittedOrderCancellationWorkflow {
  NewOrder = 'New Order',
  MyOrders = 'My Orders',
}
