import QuickLinkSearch from '@components/drawer/subcomponents/QuickLinkSearch';
import React, { useContext, useState } from 'react';
import ProfileContext from '@context/ProfileContext';
import { Contract, ContractSummary } from '@apis/models';
import ContractApi from '@apis/contract.api';
import contractApiService from '@apis/contract.api';
import { getUniqueDocumentTypes } from '@helpers/order.utils';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS } from '@constants/ga-events.constants';
import { msgsWParams } from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import SendDocuments, {
  Type as DocumentType,
  Recipient,
  GetLatestRecipients,
} from '@components/SendDocuments';
import { ContractModelVariant } from '@app/models/contract.model';
import RecipientsGenerator from '@app/generators/recipientGenerator';

export interface QuickLinksSendDocumentsProps {
  isActive: boolean;
  onClose: any;
}

const QuickLinkSendDocuments = (props: QuickLinksSendDocumentsProps) => {
  const [recipients, setRecipients] = useState<Recipient[]>([]);

  const { addSuccessToQueue } = useGlobalAlert();
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [contract, setContract] = useState<Contract>(null);
  const { profile } = useContext(ProfileContext);

  const makeEmailDocumentsCall = (recipients: any): Promise<any> => {
    const docEmails = recipients
      .filter((recipient) => recipient.selectedTypes.length > 0)
      .map(({ email, selectedTypes }) => {
        return { email, docs: selectedTypes };
      });
    const request = {
      docEmails,
    };
    return contractApiService
      .emailContractDocuments(request, Number(contract?.summary?.id))
      .then((res) => {
        if (res) {
          const uniqueDocTypes = getUniqueDocumentTypes(recipients);
          fireGAEvent(DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS(uniqueDocTypes));
          addSuccessToQueue(msgsWParams.DOCUMENTS_SENT_SUCCESS(request.docEmails));
        }
      })
      .finally(() => handleCloseNotifications());
  };

  const handleClickContract = (selectedContract: ContractSummary) => {
    if (selectedContract) {
      // Contract needs to be fetched every time even if we already have one.
      // We need to retain state for the last contract searched when "back to search results" is clicked.
      ContractApi.getContractDetails([selectedContract.id], true, true)
        .then((fetchedContracts) => {
          if (fetchedContracts[0]) {
            setContract(fetchedContracts[0]);
            setRecipients(
              GetLatestRecipients(
                fetchedContracts[0],
                ContractModelVariant.API,
                recipients,
                profile.email,
                `${profile.firstName} ${profile.lastName}`,
              ),
            );
            setIsNotificationActive(true);
          }
        })
        .catch((e) => {
          console.error('Error fetching contract details...', e);
        });
    }
  };

  const handleCloseNotifications = () => {
    setIsNotificationActive(false);
    props.onClose();
  };

  const handleBackToSearchResults = () => {
    setIsNotificationActive(false);
  };

  return (
    <>
      <div className={props.isActive && isNotificationActive ? 'hidden' : null}>
        <QuickLinkSearch
          title="Send Documents"
          subTitle="Search for an order number or address"
          unsuccessfulSearchErrorMessage="Order Not Found. Please try again, considering spelling and order status. Cancelled orders are not searchable."
          includedStatuses={[
            RealEstateStatus.ACTIVE,
            RealEstateStatus.OPEN_ORDER,
            RealEstateStatus.CLOSING_SOON,
            RealEstateStatus.PAYMENT_DUE,
            RealEstateStatus.CLOSED,
            RealEstateStatus.EXPIRED,
            RealEstateStatus.EXPIRING,
            RealEstateStatus.AWAITING_WL_SUBMISSION,
            RealEstateStatus.CLOSING_LATER,
            RealEstateStatus.PAYMENT_PAST_DUE,
          ]}
          idPrefix="qlInvoice"
          onClickContract={handleClickContract}
        />
      </div>
      <div className={isNotificationActive ? null : 'hidden'}>
        <SendDocuments
          recipients={recipients}
          allowedTypes={[DocumentType.Confirmation, DocumentType.Invoice]}
          onChange={setRecipients}
          onSave={() => makeEmailDocumentsCall(recipients)}
          onCancel={() => handleCloseNotifications()}
          onBackToSearch={handleBackToSearchResults}
          newOrderData={contract}
        />
      </div>
    </>
  );
};

export default QuickLinkSendDocuments;
