import React from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button } from '@ftdr/blueprint-components-react';
import { DASHBOARD_CLOSING_SOON_VIEW_ORDERS } from '@constants/ga-events.constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { useNavigation } from 'react-navi';
import Path from '@constants/paths';
import { toFormattedNumber } from '@helpers/utils';
import { RealEstateStatus } from '@constants/dashboardFilters';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

const CardDashboardActionClosingSoon = (props: ICardDashBoardActionProps) => {
  const navigation = useNavigation();

  const navigateToMyOrdersFiltered = () => {
    fireGAEvent(DASHBOARD_CLOSING_SOON_VIEW_ORDERS);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);

    const preFilter = {
      orderStatuses: [RealEstateStatus.CLOSING_SOON],
      closingDateRange: [new Date(), endDate],
      meta: { sortBy: 'expirationDate', asc: true }, // we want to sort by closing date asc, expiration date is equivalent
      officeLimitBypass: true,
    };

    navigation.navigate(Path.MyOrders, { body: preFilter });
  };

  return (
    <CardDashboardAction
      isManualFetch={props.isManualFetch}
      fetchCount={props.fetchCount}
      type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
      heading={props.count === null ? null : toFormattedNumber(props.count)}
      subHeading="closing within 7 days"
      action={
        <Button
          label="View Orders"
          size="medium"
          variant="ghost"
          disabled={props.count === null || props.count === 0}
          onClick={() => navigateToMyOrdersFiltered()}
        />
      }
    />
  );
};

export default CardDashboardActionClosingSoon;
