// TODO This file should be cleaned up.  We're only using the JWT services

import { default as queryStringParser } from 'query-string';
import { Hub, OIDCService } from '@ftdr/pkce-js';
import createAxiosInstance from '../services/apis/api.service.factory';
import { getConfig } from '../services/app.config.loader';
import { authProvider } from '../constants/auth-providers';

const FORM_URL_ENCODED_CONTENT_TYPE = 'application/x-www-form-urlencoded';
const axios = createAxiosInstance();
const encodeURIKeyValue = (uriKeyValue: [string, string]) =>
  uriKeyValue.map(encodeURIComponent).join('=');
const IdentityProviderClientID = 'IdentityProviderClientID';
const IdentityProviderBaseUrl = 'IdentityProviderBaseUrl';
export const IdentityProviderTenantID = 'IdentityProviderTenantID';
const IdentityIssuer = 'IdentityIssuer';
const CorporateIssuer = 'CorporateIssuer';

const mapToRequestParams = (paramsMap: { [key: string]: string }) =>
  Object.entries(paramsMap).map(encodeURIKeyValue).join('&');

function getRedirectBase() {
  const base = window.location.origin;
  if (base.substring(-1) === '/') {
    return base.substr(0, base.length - 1);
  }
  return base;
}

export interface AuthState {
  userId: string | undefined;
  email: string | undefined;
  accessToken: string | undefined;
  tokenType: string | undefined;
  expiresIn: number | undefined;
  refreshToken: string | undefined;
  authRedirectUri: string | undefined;
  appRedirectUri: string | undefined;
}

export const getTokensUsingRefreshToken = async (
  refreshToken: string,
  redirectUri: string,
): Promise<Partial<AuthState> | undefined> => {
  const requestParams = {
    client_id: getConfig(IdentityProviderClientID),
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    scope: 'offline_access',
    redirect_uri: encodeURI(getRedirectBase()),
  };
  return fetchTokens(requestParams, redirectUri, refreshToken);
};

export const getTokensUsingAuthCode = async (
  authCode: string,
  redirectUri: string,
): Promise<Partial<AuthState> | undefined> => {
  let cleanUri = redirectUri;

  if (redirectUri.substr(redirectUri.length - 1, redirectUri.length) === '/') {
    cleanUri = redirectUri.substr(0, redirectUri.length - 1);
  }
  const requestParams = {
    client_id: getConfig(IdentityProviderClientID),
    code: authCode,
    grant_type: 'authorization_code',
    scope: 'offline_access',
    redirect_uri: encodeURI(cleanUri),
  };
  return await fetchTokens(requestParams, cleanUri);
};

const fetchTokens = async (
  requestParams: { [key: string]: string },
  redirectUri: string,
  refreshToken?: string,
): Promise<Partial<AuthState> | undefined> => {
  try {
    const config = {
      headers: {
        'Content-Type': FORM_URL_ENCODED_CONTENT_TYPE,
      },
    };
    const response = await axios.post(
      `${getConfig(IdentityProviderBaseUrl)}/token`,
      mapToRequestParams(requestParams),
      config,
    );

    const auth = getAuthStateFromResponse(response, redirectUri, refreshToken);
    return Promise.resolve(auth);
  } catch (e) {
    return Promise.resolve(undefined);
  }
};

const getAuthStateFromResponse = (
  response: any,
  redirectUri: string,
  refreshToken?: string,
): Partial<AuthState> => ({
  refreshToken: refreshToken || response.refresh_token,
  accessToken: response.access_token,
  expiresIn: response.expires_in,
  tokenType: response.token_type,
  userId: response.userId,
  authRedirectUri: redirectUri,
  email: getEmailAddressFromAccessToken(response.access_token),
});

export const getQueryStringParam = (queryString: string, param: string): string => {
  const code = queryStringParser.parse(queryString)[param];
  if (code !== null && code !== undefined) {
    if (Array.isArray(code)) {
      return code.length > 0 ? code[0] : '';
    }
    return code;
  }
  return '';
};

const extractAuthCodeFromURL = () => getQueryStringParam(window.location.search, 'code');

const getEmailAddressFromAccessToken = (jwt: string) => {
  const jwtParts = jwt.split('.');
  if (jwtParts.length >= 2) {
    const jwtPayload = jwtParts[1];
    if (jwtPayload) {
      const decodedJwtPayload = atob(jwtPayload);
      if (decodedJwtPayload) {
        try {
          const jwtPayloadObject = JSON.parse(decodedJwtPayload);
          return jwtPayloadObject['email'] || '';
        } catch (error) {
          return '';
        }
      }
    }
  }
  return '';
};

const auth: Partial<AuthState> = {};

export function loggedInEmail(): string {
  const jwt = getJWT();
  return jwt ? getEmailAddressFromAccessToken(jwt) : '';
}

export function getLoginURL(): string {
  return `${getConfig(IdentityProviderBaseUrl)}/authorize?client_id=${getConfig(IdentityProviderClientID)}&tenant_id=${getConfig(IdentityProviderTenantID)}&response_type=code&scope=offline_access&redirect_uri=${encodeURI(getRedirectBase())}`;
}

export function getRegisterURL(): string {
  return `${getConfig(IdentityProviderBaseUrl)}/register?client_id=${getConfig(IdentityProviderClientID)}&tenant_id=${getConfig(IdentityProviderTenantID)}&response_type=code&redirect_uri=${encodeURI(getRedirectBase())}`;
}

export function getResetPasswordURL(): string {
  return `${getConfig(IdentityProviderBaseUrl)}/password/forgot?client_id=${getConfig(IdentityProviderClientID)}&tenant_id=${getConfig(IdentityProviderTenantID)}&response_type=code&redirect_uri=${encodeURI(getRedirectBase())}`;
}

export const getLogoutURL = () => {
  const paramsMap = {
    client_id: getConfig(IdentityProviderClientID) || '',
    tenant_id: getConfig(IdentityProviderTenantID) || '',
    post_logout_redirect_uri: encodeURI(getRedirectBase()),
  };
  const queryParams = mapToRequestParams(paramsMap);
  const loginUrl = `${getConfig(IdentityProviderBaseUrl)}/logout`;
  return `${loginUrl}?${queryParams}`;
};

/**
 * @deprecated Use hardLogout from auth.utils
 */
export const logout = () => {
  try {
    clearTokens();
    window.location.assign(getLogoutURL());
  } catch (error) {
    console.error('could not log out');
  }
};

export const getAppRedirectUri = () => {
  const { origin, href, hash } = window.location;
  return href.replace(origin, '').replace(hash, '').replace(/\/$/, '');
};

const urlHasAuthCode = () => !!extractAuthCodeFromURL();
let oidcHubInstance: Hub;
let OIDCJWT: string;

function setoidcHub() {
  const oidcHub = new Hub([
    new OIDCService({
      nickname: 'customer',
      authority: getConfig(IdentityProviderBaseUrl),
      client_id: getConfig(IdentityProviderClientID),
      debug: true,
      metadata_uri:
        'https://frontdoorhome-dev.fusionauth.io/.well-known/openid-configuration/94510e59-3c18-4e7a-bab0-a1828913b9c3',
      post_logout_redirect_uri: window.location.origin,
      redirect_uri: `${window.location.origin}/Oauth2`,
      scope: 'openid email profile',
    }),
  ]);
  oidcHubInstance = oidcHub;
}

export function getJWT() {
  return OIDCJWT;
}

export function setJWT(jwt: string) {
  OIDCJWT = jwt;
}

// export function isLoggedIn(): any {
//   if(!oidcHubInstance) {
//     setoidcHub();
//   }
//   debugger;
//   const client = oidcHubInstance.serviceMap.get("customer")
//   client.getUser().then((res) => {
//     debugger;
//     if(res !== null) {
//       if(res.expired) {
//         // Do something with expired
//       }
//       OIDCJWT = res.id_token;
//     } else {
//       client.login();
//       debugger;
//     }
//   });
// }

export async function login() {
  if (!oidcHubInstance) {
    setoidcHub();
  }

  if (urlHasAuthCode()) {
    oidcHubInstance.callback();
  }
}

export function hasRefreshToken(): boolean {
  const rwt = getRWT();
  return rwt !== null && rwt !== '';
}

export function tokenExpired() {
  const jwt = getJWT();
  if (jwt) {
    const jwtObj = parseJwt(jwt || '');
    const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(jwtObj.exp);
    const now = new Date();
    if (now >= d) {
      return true;
    }
  }
  return false;
}

export async function refreshToken() {
  const rwt = getRWT();
  if (rwt) {
    const auth = await getTokensUsingRefreshToken(rwt || '', encodeURI(getRedirectBase()));
    if (auth) {
      setTokens(auth.accessToken || '', auth.refreshToken || '');
    }
  } else {
    return Promise.reject();
  }
}

export type JWT = {
  partner: string;
  iss: string;
  exp: number;
  email?: string;
};

function parseJwt(token: string): JWT {
  const base64Url = token.split('.')[1];

  if (base64Url === undefined) return {} as JWT;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  try {
    const parsed = JSON.parse(jsonPayload);
    return parsed;
  } catch (e) {
    return {} as JWT;
  }
}

export function authenticate() {
  const jwt = getJWT();
  const jwtIss = getConfig(IdentityIssuer);
  if (jwt) {
    const jwtObj = parseJwt(jwt || '') as JWT;
    const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(jwtObj.exp);
    const now = new Date();
    if (jwtObj && jwtObj.iss === jwtIss && now < d) {
      auth.email = jwtObj.email;
      return true;
    }
    clearJWT();
  }
  console.log('Not logged in');
  return false;
}

export function providerType() {
  const jwt = getJWT();
  if (jwt) {
    const jwtIss = getConfig(IdentityIssuer);
    const corporateIss = getConfig(CorporateIssuer);
    const jwtObj = parseJwt(jwt || '') as JWT;
    if (jwtObj && jwtObj.iss === jwtIss) {
      return authProvider.CUSTOMER;
    }
    if (jwtObj && jwtObj.iss == corporateIss) {
      return authProvider.CORPORATE;
    }
  }
  return authProvider.NONE;
}

function setTokens(jwt: string, rwt: string) {
  if (jwt.trim()) {
    setCookie('jwt', jwt, 60);
  }

  if (rwt.trim()) {
    setCookie('rwt', rwt, 480);
  }
}

function clearTokens() {
  clearJWT();
  clearRWT();
}

function clearJWT() {
  setCookie('jwt', '', 0);
}

function clearRWT() {
  setCookie('rwt', '', 0);
}

function getRWT() {
  return getCookie('rwt');
}

function setCookie(name: string, value: string, expirationMinutes: number) {
  const d = new Date();
  d.setTime(d.getTime() + expirationMinutes * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

function getCookie(name: string) {
  const re = new RegExp(`${name}=([^;]+)`);
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}

export default authenticate;
