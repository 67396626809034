export enum NewOrderStep {
  AgentPropertyInfo,
  PlanCoverage,
  BuyerSellerInfo,
  ReviewSubmit,
}

export function getPreviousNewOrderStep(step: NewOrderStep): NewOrderStep {
  if (step === 0) {
    return step;
  }
  return step - 1;
}
