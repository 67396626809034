import React, { useEffect, useMemo, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import CustomerInfoInput, {
  CustomerInfoInputProps,
  SaveCustomerInfoHandler,
} from '@components/input/CustomerInfoInput';
import { classNames } from '@utils';
import { CustomerData, REOrder, WarrantylinkContract, WarrantylinkFormData } from '@apis/models';
import WarrantyLinkAPI from '@apis/warrantylink.api';

const variants = {
  animateHeight: {
    initial: {
      height: 0,
      transition: {
        duration: 0.3,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
    active: {
      height: 'auto',
      transition: {
        duration: 0.3,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
  },
};

/** TODO: ARE-9309. the typing is very screwy and should be addressed. There's a different warrantylink shape. */
export interface AccordionWlkContractItem extends Omit<REOrder, 'warrantylink'> {
  warrantylink: WarrantylinkContract['warrantylink'];
}

/** not fully typed as there are a mix of js and tsx and prop spreading around its usage which is not consistent.
 * this component itself is a passthrough but just adds some accordion-related handling. should be typed better. */
interface AccordionItemWlkContractProps
  extends Pick<CustomerInfoInputProps, 'isDisabled' | 'isMobile' | 'onSaveAndCancel'> {
  buyer: CustomerData;
  seller: CustomerData;
  /** @deprecated: should switch to onSaveForm for type-safety and contract id data. typed different name */
  handleSave?: CustomerInfoInputProps['onSave'];
  onSaveForm?: (contractID: string | number, formData: WarrantylinkFormData) => Promise<boolean>;
  isActive: boolean;
  animateHeight?: any;
  className: any;
  /** this is a union of data but not treated as such, not type-safe
   * TODO: ARE-9309 shouldn't even be used here. Pass the wlk status from the top level downwards */
  contract: AccordionWlkContractItem;
  shouldFetchWlkContract?: boolean;
  canEditWLKContracts: boolean;
}

const AccordionItemWlkContract: React.FC<AccordionItemWlkContractProps> = (props) => {
  const [isContractFetched, setIsContractFetched] = useState(!props.shouldFetchWlkContract); // if the contract has warrantylink information already, it's been fetched.
  const [wlkContract, setWlkContract] = useState<WarrantylinkContract>(undefined);

  const onSave: SaveCustomerInfoHandler = (buyer, seller) => {
    if (props.onSaveForm) {
      return props.onSaveForm(props.contract.id, { buyer, seller });
    } else {
      return props.handleSave(buyer, seller);
    }
  };

  useEffect(() => {
    if (!isContractFetched) {
      WarrantyLinkAPI.searchWarrantyLinkContracts({ contractId: props.contract.id }).then(
        (fetchedContracts) => {
          if (fetchedContracts.contracts.length > 0) {
            setWlkContract(fetchedContracts.contracts[0]);
            setIsContractFetched(true);
          }
        },
      );
    }
  });

  const DEFAULT_CUSTOMER_DATA: CustomerData = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  };

  const buyer: CustomerData = useMemo(() => {
    return props.buyer || wlkContract?.warrantylink?.form?.buyer || DEFAULT_CUSTOMER_DATA;
  }, [props.buyer, wlkContract]);

  const seller: CustomerData = useMemo(() => {
    return props.seller || wlkContract?.warrantylink?.form?.seller || DEFAULT_CUSTOMER_DATA;
  }, [props.seller, wlkContract]);

  const wlkStatus: string = useMemo(() => {
    return props.contract?.warrantylink?.status || wlkContract?.warrantylink?.status;
  }, [props.contract, wlkContract]);
  return (
    <AnimatePresence>
      {props.isActive && (
        <motion.div
          key="animateHeight"
          initial="initial"
          animate="active"
          exit="initial"
          variants={variants.animateHeight}
          className="overflow-hidden"
        >
          <div
            className={classNames([
              'accordion-item__content relative',
              props.className,
              !isContractFetched && 'hidden',
            ])}
          >
            {isContractFetched && (
              <div className="flex w-full">
                {props.children}
                <CustomerInfoInput
                  initialBuyer={buyer}
                  initialSeller={seller}
                  isDisabled={props.isDisabled}
                  isMobile={props.isMobile}
                  contractStatus={wlkStatus}
                  onSave={onSave}
                  onSaveAndCancel={props.onSaveAndCancel}
                  canEditWLKContracts={props.canEditWLKContracts}
                />
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AccordionItemWlkContract;
