import classNames from 'classnames';
import React from 'react';
import { ProgressIndicatorProps, ProgressIndicatorSize } from '@components/spinner/OverlaySpinner';

const indicatorSizeOptions: { [sizeKey in ProgressIndicatorSize]: number } = {
  large: 64,
  medium: 40,
  small: 24,
};

const strokeWidthOptions: { [sizeKey in ProgressIndicatorSize]: number } = {
  large: 4,
  medium: 4,
  small: 2,
};

export const ProgressIndicatorCircular: React.FC<ProgressIndicatorProps> = ({ size }) => {
  const circleParameters = React.useMemo(() => {
    const r = indicatorSizeOptions[size] / 2;
    const perimeter = Math.round(Math.PI * (r * 2) * 100) / 100;
    return {
      strokeWidth: strokeWidthOptions[size],
      perimeter,
      r,
      size: (r + strokeWidthOptions[size]) * 2,
    };
  }, [size]);

  return (
    <div
      role="progressbar"
      className="progress-indicator-circular progress-indicator-circular-indeterminate"
    >
      <svg
        id="svg"
        width={circleParameters.size}
        height={circleParameters.size}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="progress-indicator-circular-track progress-indicator-primary-track"
          r={circleParameters.r}
          strokeWidth={circleParameters.strokeWidth}
          cx={circleParameters.r + circleParameters.strokeWidth}
          cy={circleParameters.r + circleParameters.strokeWidth}
          fill="transparent"
          strokeDasharray={circleParameters.perimeter}
          strokeDashoffset="0"
        />
        <circle
          className={classNames([
            'progress-indicator-circular-bar progress-indicator-primary-bar',
            `progress-indicator-circular-indeterminate-${size}-bar`,
          ])}
          r={circleParameters.r}
          strokeWidth={circleParameters.strokeWidth}
          cx={-(circleParameters.r + circleParameters.strokeWidth)}
          cy={circleParameters.r + circleParameters.strokeWidth}
          fill="transparent"
          strokeDasharray={circleParameters.perimeter}
          strokeDashoffset="0"
        />
      </svg>
    </div>
  );
};
