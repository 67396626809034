import { BaseAPI } from './_api';
import { sha3_512 } from 'js-sha3';

const baseConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const configServiceName = 'proofOfWorkUrl';

class API extends BaseAPI {
  constructor() {
    super(configServiceName, false, baseConfig, false);
  }

  public async GetGuestTokenChallenge(): Promise<any> {
    return this.axios
      .get<any, any>(`${this.getHost()}/GUEST`)
      .then((response) => {
        const res = this.RunProofOfWork(response.challenge);
        const body = {
          result: res,
        };
        return { body: body, challenge: response.challenge.challenge };
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  public async GetGuestToken(challengeResult): Promise<any> {
    return this.axios
      .post(`${this.getHost()}/GUEST/${challengeResult.challenge}`, challengeResult.body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        return res.data.token;
      })
      .catch((e) => {
        console.error(e);
        return '';
      });
  }

  private hashWith(challenge, nonce) {
    return sha3_512(this.toHex(challenge + nonce));
  }

  private toHex(s) {
    // utf8 to latin1
    s = unescape(encodeURIComponent(s));
    let h = '';
    for (let i = 0; i < s.length; i += 1) {
      h += s.charCodeAt(i).toString(16);
    }
    return h;
  }

  private fromHex(h) {
    let s = '';
    for (let i = 0; i < h.length; i += 2) {
      s += String.fromCharCode(parseInt(h.substr(i, 2), 16));
    }
    return decodeURIComponent(escape(s));
  }

  private POWIsValid(result) {
    if (!result) {
      return false;
    }
    if (result.hash === '' || result.nonce === '' || result.challenge.difficulty < 1) {
      return false;
    }
    const computed = this.hashWith(result.challenge.challenge, result.nonce);
    if (computed !== result.hash) {
      return false;
    }
    const prefix = '0';
    return result.hash.startsWith(prefix.repeat(result.challenge.difficulty));
  }

  private RunProofOfWork(challenge) {
    for (let i = 0; true; i += 1) {
      const res = {
        challenge: { ...challenge },
        hash: this.hashWith(challenge.challenge, i.toString()),
        nonce: i.toString(),
      };
      if (this.POWIsValid(res)) {
        return res;
      }
    }
  }
}

const ProofOfWorkApi = new API();
export default ProofOfWorkApi;
