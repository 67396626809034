import React from 'react';
import Drawer from '@components/drawer/Drawer';
import OfficeSearch from '@components/misc/OfficeSearch';
import usaStates from '@constants/us-states';

const DrawerOfficeSearch = (props) => {
  return (
    <>
      <Drawer {...props}>
        {props.isActive && (
          <OfficeSearch
            id="drawer-office-search__office-search"
            usaStates={usaStates}
            typeCode={props.typeCode}
            excludeBrandSearch={props.excludeBrandSearch}
            multiSelect={props.multiSelect}
            userOfficeIDs={props.userOfficeIDs}
            onCancel={props.onClose}
            onSubmit={props.onSubmit}
            ignoreReadOnly={props.ignoreReadOnly}
          />
        )}
      </Drawer>
    </>
  );
};

export default DrawerOfficeSearch;
