/**
 * Contains field validations for each individual fields
 */
import * as c from '@constants/formField-constants';
import { CustomerEmailPhoneRequirement } from '@components/orders/forms/OrderFormCustomerInfo';
import isWithinInterval from 'date-fns/isWithinInterval';
import addDays from 'date-fns/addDays';

export type FieldValidationFc = (value: string) => string;

export const validateInitiatingOffice: FieldValidationFc = (value) => {
  if (!value) {
    return c.OFFICE_NAME_REQUIRED;
  }
  return '';
};

export const validateInitiatingAgent: FieldValidationFc = (value) => {
  if (!value) {
    return c.INITIATING_AGENT_REQUIRED;
  }
  return '';
};

export const validateInitiatingRepresents: FieldValidationFc = (value) => {
  if (!value) {
    return c.AGENT_REPRESENTS_REQUIRED;
  }
  return '';
};

export const validateZip: FieldValidationFc = (value) => {
  if (!value) {
    return c.ZIP_REQUIRED;
  }
  if (value.length !== 5) {
    return c.MALFORMED_ZIP_CODE;
  }

  // if (value === '99999') {
  //   return c.INVALID_ZIP_CODE;
  // }
  return '';
};

export const validateCity: FieldValidationFc = (value) => {
  if (!value) {
    return c.CITY_REQUIRED;
  }

  return '';
};

export const validateState: FieldValidationFc = (value) => {
  if (!value) {
    return c.STATE_REQUIRED;
  }

  return '';
};

export const validateAge: FieldValidationFc = (value) => {
  if (!value) {
    return c.NEW_CONSTRUCTION_REQUIRED;
  }
};

export const validateResidenceType: FieldValidationFc = (value) => {
  if (!value) {
    return c.RESIDENT_TYPE_REQUIRED;
  }

  return '';
};

export const validateSquareFootage: FieldValidationFc = (value) => {
  if (!value) {
    return c.SQUARE_FOOTAGE_REQUIRED;
  }

  return '';
};

/** validate the street address */
export const validateStreetAddress = (streetAddress1: string, allowPOBox: boolean): string => {
  // street address cannot be empty
  if (!streetAddress1?.trim()) {
    return c.STREET_ADDRESS_REQUIRED;
  }
  // validate PO Box Address
  if (isPOBoxAddress(streetAddress1)) {
    // don't allow PO Box address if not allowed.
    if (!allowPOBox) {
      return c.STREET_ADDRESS_PO_BOX_NOT_ACCEPTABLE;
    }
    return validatePOBoxAddress(streetAddress1);
  }
  // validate regular address

  // require the first word being digits.
  const firstWord = streetAddress1.split(' ')[0];
  const firstWordAsNoDigits = firstWord && !/\d/.test(firstWord);
  if (firstWordAsNoDigits) {
    return c.STREET_ADDRESS_NUMBER_MISSING;
  }
  return '';
};

/** Validates if the PO Box is complete. It should be a recognizable pattern and has at least 1 digit */
export const validatePOBoxAddress = (streetAddress1: string): string => {
  if (!isPOBoxAddress(streetAddress1)) {
    return c.STREET_ADDRESS_PO_BOX_INCOMPLETE;
  }
  if (!isPOBoxFormatAcceptable(streetAddress1)) {
    return c.STREET_ADDRESS_PO_BOX_INVALID;
  }
  const hasDigit = /\d/.test(streetAddress1);
  if (!hasDigit) {
    return c.STREET_ADDRESS_PO_BOX_NUMBER_MISSING;
  }
  return '';
};

/** returns true if PO Box Address (starts with PO Box). Otherwise false. */
export const isPOBoxAddress = (streetAddress1: string): boolean => {
  return c.PO_BOX_PATTERN.test(streetAddress1.toLocaleLowerCase());
};

export const isPOBoxFormatAcceptable = (streetAddress1: string): boolean => {
  return !!(c.PO_BOX_ACCEPTED_PATTERNS as string[]).find((pattern) =>
    streetAddress1.toLocaleLowerCase().includes(pattern.toLocaleLowerCase()),
  );
};

export const validateProjectedClosingDate = (closingDate: Date, isClosingDateRequired: boolean) => {
  if (!closingDate) {
    return isClosingDateRequired ? c.PROJECTED_CLOSING_DATE : '';
  }
  if (
    !isWithinInterval(new Date(closingDate), {
      start: addDays(c.CLOSING_DATE_RANGE().min, -1),
      end: addDays(c.CLOSING_DATE_RANGE().max, 0),
    })
  ) {
    return c.PROJECTED_CLOSING_DATE_OUT_OF_RANGE();
  }
  return '';
};

export const validateFirstNameInput: FieldValidationFc = (firstName) => {
  if (!firstName) {
    return c.FIRST_NAME_REQUIRED;
  }
  return '';
};

export const validateLastNameInput: FieldValidationFc = (lastName) => {
  if (!lastName) {
    return c.LAST_NAME_REQUIRED;
  }
  return '';
};

export const validateEmailInput: FieldValidationFc = (email) => {
  if (!email) {
    return c.EMAIL_REQUIRED;
  }
  if (email.length < 1) {
    return c.INVALID_EMAIL;
  }
  if (!c.EMAIL_PATTERN.test(email)) {
    return c.INVALID_EMAIL;
  }
  if (!c.BASE_EMAIL_PATTERN.test(email)) {
    return c.INVALID_EMAIL_VERBOSE;
  }

  for (const char of c.EMAIL_DISALLOWED_SPECIAL_CHARACTERS) {
    if (email.indexOf(char) > -1) {
      return c.INVALID_EMAIL_VERBOSE;
    }
  }
  return '';
};

export const validatePhoneInput: FieldValidationFc = (phoneNumber) => {
  //check for the length, all numeric, and digit count
  if (!phoneNumber) {
    return c.PHONE_NUMBER_REQUIRED;
  }
  //check for all numeric, and digit count
  if (!c.PHONE_NUMBER_PATTERN.test(phoneNumber)) {
    return c.INVALID_PHONE_NUMBER;
  }
  // Removes parantheses, dashes, and whitespace
  const phone = phoneNumber.replace(/[()-\s]/g, '');
  const firstDigit = phone.charAt(0);
  const fourthDigit = phone.charAt(3);
  const fifthDigit = phone.charAt(4);
  const sixthDigit = phone.charAt(5);

  // 1st digit cannot be 0 or 1
  if (['0', '1'].includes(firstDigit)) {
    return c.INVALID_PHONE_NUMBER;
  }
  // 4th digit cannot be 0 or 1
  if (['0', '1'].includes(fourthDigit)) {
    return c.INVALID_PHONE_NUMBER;
  }
  // 5th and 6th digit cannot all be 1
  if ([fifthDigit, sixthDigit].every((d) => d === '1')) {
    return c.INVALID_PHONE_NUMBER;
  }
  // 4th, 5th, and 6th digit cannot all be 5
  if ([fourthDigit, fifthDigit, sixthDigit].every((d) => d === '5')) {
    return c.INVALID_PHONE_NUMBER;
  }

  // Skip this mobile number - ARE-10797
  if (phone === '9999999999') {
    return c.INVALID_PHONE_NUMBER;
  }

  return '';
};

/** validate email and phone. validates if certain fields can be blank. */
export const validateCustomerEmailPhoneInput = (
  email: string,
  phone: string,
  validationType: CustomerEmailPhoneRequirement,
): { email: string; phone: string } => {
  const errs = { email: '', phone: '' };

  switch (validationType) {
    case CustomerEmailPhoneRequirement.EmailAndPhoneRequired:
      return {
        email: validateEmailInput(email),
        phone: validatePhoneInput(phone),
      };
    case CustomerEmailPhoneRequirement.EmailAndPhoneOptional:
      return {
        email: email ? validateEmailInput(email) : '',
        phone: phone ? validatePhoneInput(phone) : '',
      };
    case CustomerEmailPhoneRequirement.EmailOrPhoneRequired:
      if (!email && !phone) {
        return {
          email: c.EMAIL_OR_PHONE_REQUIRED,
          phone: '',
        };
      }
      return {
        email: email ? validateEmailInput(email) : '',
        phone: phone ? validatePhoneInput(phone) : '',
      };
  }
  return errs;
};

export const validateCooperatingOffice: FieldValidationFc = (value) => {
  return '';
};

export const validateCooperatingAgent: FieldValidationFc = (value) => {
  return ''; // TODO: don't use FieldValidationFc. should validate cooperating agent: 1) required if cooperating office exists. 2) cannot be the same as initiating agent (compare by id? UX?).
};

export const validatePlanSelection: FieldValidationFc = (plan) => {
  if (!plan) {
    return 'Plan selection required';
  }
  return '';
};

export const validateClosingOffice: FieldValidationFc = (value) => {
  return '';
};

export const validateClosingAgent: FieldValidationFc = (value) => {
  return '';
};
