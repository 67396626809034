import React from 'react';
import { classNames } from '@utils';
import { IconUiV1CheveronLeft, IconUiV1CheveronRight } from '@ftdr/blueprint-components-react';

const Prev = (props) => (
  <li className="pr-3">
    <button id={props.id} disabled={!props.onClick} {...props}>
      <IconUiV1CheveronLeft color="gray" size={24} />
    </button>
  </li>
);

const Link = ({ isActive, children, ...props }) => {
  return (
    <li className="px-1">
      <button
        id={props.id}
        className={classNames([
          'py-1 w-8 text-center',
          isActive && 'text-white rounded',
          isActive && 'bg-primary-400',
        ])}
        {...props}
      >
        {children}
      </button>
    </li>
  );
};

const Next = (props) => (
  <li className="pl-3">
    <button id={props.id} disabled={!props.onClick} {...props}>
      <IconUiV1CheveronRight color="gray" size={24} />
    </button>
  </li>
);

/** @deprecated: old SF usage. stop using it */
const Pagination = (props) => {
  return <ul className="flex flex-wrap justify-center items-center">{props.children}</ul>;
};

Pagination.Prev = Prev;
Pagination.Link = Link;
Pagination.Next = Next;
export default Pagination;
