import React, { FunctionComponent } from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import { Office } from '@app/models';
import { isSmallView } from '@utils';

interface IProps {
  incorrectOfficeInfoBtnHandler: (arg: string) => void;
  correctOfficeInfoBtnHandler: (arg: string) => void;
  office: Office;
}

const ConfirmOfficeTemplate: FunctionComponent<IProps> = ({
  incorrectOfficeInfoBtnHandler,
  correctOfficeInfoBtnHandler,
  office,
}) => {
  const isSmall = isSmallView();

  return (
    <>
      <h1 className="h3">Welcome!</h1>

      <p className="mt-2">Please confirm that your office information is correct.</p>

      <div className="sm:flex sm:flex-wrap mt-8">
        <div className="sm-max:w-full md:pr-24">
          <p>
            <strong>{office && office.name}</strong>
            <br />
            {office && office.address.streetAddress} {office && office.address.unit}
            <br />
            {office && office.address.city}, {office && office.address.state}{' '}
            {office && office.address.zip}
          </p>
          <p>
            Main:{' '}
            <a href={`tel:${office && office.contact.phones.office}`}>
              {office && office.contact.phones.office}
            </a>
            <br />
            Fax:{' '}
            <a href={`tel:${office && office.contact.phones.fax}`}>
              {office && office.contact.phones.fax}
            </a>
          </p>
        </div>
      </div>

      <div className="md:flex md:flex-row-reverse mt-12">
        <Button
          className="sm-max:block md:ml-4"
          label="Yes, this is correct"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
          color="success"
          onClick={() => correctOfficeInfoBtnHandler('dashboard')}
        />
        <Button
          className="sm-max:block sm-max:mt-4"
          label="No, this is incorrect"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
          color="error"
          onClick={() => incorrectOfficeInfoBtnHandler('searchOffice')}
        />
      </div>
    </>
  );
};

export default ConfirmOfficeTemplate;
