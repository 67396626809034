import React, { useState } from 'react';

// Components
import Modal from '@components/modal/Modal';
import { FilterTagItem } from '@components/misc/misc.models';
import { Office } from '@apis/models';
import OfficesFilter from '@components/modal/subcomponents/OfficesFilter';

interface ModalOfficesFilterProps {
  id?: string;
  heading: string;
  onClose: any;
  isActive: boolean;
  addFilter: any;
  onApply: any;
  activeFilters: FilterTagItem[];
  offices: Office[];
}

const ModalOfficesFilter: React.FC<ModalOfficesFilterProps> = (props) => {
  const [actions, setActions] = useState([]);

  return (
    <Modal
      id={props.id || 'offices-filter-modal'}
      heading={props.heading}
      isActive={props.isActive}
      onClose={props.onClose}
      isModalDialog={false}
      closeOnOutsideClick={true}
      showBackdrop={true}
      disableScrolling={true}
      overwriteBottomMargin={true}
      actions={actions}
    >
      {props.isActive && <OfficesFilter setActions={setActions} {...props} />}
    </Modal>
  );
};

export default ModalOfficesFilter;
