import { addMonths, lastDayOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';
import { RadioGroupItem } from '@ftdr/blueprint-components-react';
import { ContractDateType } from '@apis/models';
import { endOfDay, startOfDay } from 'date-fns/fp';

const previousFilterDatePresets = [
  {
    label: 'Last 12 months',
    start: subMonths(new Date(), 12),
    end: new Date(),
    id: 'last_12_months',
  },
  {
    label: 'Last 6 months',
    start: subMonths(new Date(), 6),
    end: new Date(),
    id: 'last_6_months',
  },
  {
    label: 'Last 3 months',
    start: subMonths(new Date(), 3),
    end: new Date(),
    id: 'last_3_months',
  },
];

const yesterdayFilterDatePresets = [
  {
    label: 'Yesterday',
    start: startOfDay(subDays(new Date(), 1)),
    end: endOfDay(subDays(new Date(), 1)),
    id: 'yesterday',
  },
];

const todayFilterDatePresets = [
  {
    label: 'Today',
    start: new Date(),
    end: new Date(),
    id: 'today',
  },
];

const currentMonthFilterDatePreset = [
  {
    label: 'This month',
    start: startOfMonth(new Date()),
    end: lastDayOfMonth(new Date()),
    id: 'this_month',
  },
];

const nextFilterDatePresets = [
  {
    label: 'Next 3 months',
    start: new Date(),
    end: addMonths(new Date(), 3),
    id: 'next_3_months',
  },
  {
    label: 'Next 6 months',
    start: new Date(),
    end: addMonths(new Date(), 6),
    id: 'next_6_months',
  },
];

const wlkNextFilterDatePresets = [
  {
    label: 'Next 3 months',
    start: new Date(),
    end: addMonths(new Date(), 3),
    id: 'next_3_months',
  },
];

const customFilterDatePreset = [
  {
    label: 'Custom',
    start: null,
    end: null,
    id: 'custom',
  },
];

export const defaultFilterDatePresets = [
  ...previousFilterDatePresets,
  ...todayFilterDatePresets,
  ...currentMonthFilterDatePreset,
  ...nextFilterDatePresets,
  ...customFilterDatePreset,
  ...yesterdayFilterDatePresets,
];

export const entryTypeFilterPresets = [
  ...previousFilterDatePresets,
  ...todayFilterDatePresets,
  ...customFilterDatePreset,
];

export const warrantylinkFilterDatePresets = [
  ...previousFilterDatePresets,
  ...todayFilterDatePresets,
  ...currentMonthFilterDatePreset,
  ...wlkNextFilterDatePresets,
  ...customFilterDatePreset,
];

export const quoteFilterDatePresets = [
  ...previousFilterDatePresets,
  ...todayFilterDatePresets,
  ...currentMonthFilterDatePreset,
  ...customFilterDatePreset,
];

export const abandonedOrdersDatePresets = [
  ...todayFilterDatePresets,
  ...yesterdayFilterDatePresets,
  ...customFilterDatePreset,
];

export enum dateTypes {
  ESTCOE = 'ESTCOE',
  ENTRYDATE = 'ENTRYDATE',
  EFFDATE = 'EFFDATE',
}

export const dateTypeRadios: RadioGroupItem[] = [
  { value: dateTypes.ESTCOE, label: 'Closing Date' },
  { value: dateTypes.ENTRYDATE, label: 'Order Entry Date' },
];

export const dateTypeLabel = {
  [ContractDateType.ListingDate]: 'Order expiration date',
  [ContractDateType.ListingCoverageEffectiveDate]: "Seller's coverage expiration date",
};
export const dateTypeSortOrder: string[] = [
  ContractDateType.ListingDate,
  ContractDateType.ListingCoverageEffectiveDate,
];
