import React, { useEffect, useState } from 'react';
import { NOT_APPLICABLE_OPTION, onFetchSuggestions } from '@services/autosuggest/autosuggest';
import { Button, Text } from '@ftdr/blueprint-components-react';

const AgentVerification = (props) => {
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agentListDivs, setAgentListDivs] = useState(Element[0]);

  useEffect(() => {
    setFilteredAgents(
      onFetchSuggestions(props.selectedAgent, props.agentList, props.setTypeAheadAddress),
    );
  }, [props.isActive]);

  useEffect(() => {
    setAgentListDivs(
      filteredAgents.map((agent, idx) => (
        <ul key={agent.realEstateAgentID}>
          <div className="p-4 border-b border-gray-300">
            <div className="flex justify-between">
              <div>
                <div className="pb-2">
                  <Text variant="heading-06">{`${agent.firstName} ${agent.lastName}`}</Text>
                </div>
                <div>
                  {agent !== NOT_APPLICABLE_OPTION && (
                    <div>
                      <Text variant="caption">{props.office?.name || ' '}</Text>
                    </div>
                  )}
                  <Text variant="caption">{agent.email || ' '}</Text>
                </div>
              </div>
              <div>
                <Button
                  id={`wb_agent_verification_selection_${idx}`}
                  label="Select"
                  size="medium"
                  onClick={() => {
                    props.onSelectAgent(agent);
                    props.onClose();
                  }}
                />
              </div>
            </div>
          </div>
        </ul>
      )),
    );
  }, [filteredAgents]);

  return (
    <>
      <div className="max-w-1/2 mb-2">
        <Text>
          For the {props.agentRole} agent, you entered: {props.selectedAgent}
        </Text>
      </div>
      {filteredAgents && !filteredAgents.some((element) => element.empty === true) ? (
        <div>
          <div className="max-w-full mb-2 border-b border-gray-300 pb-4">
            <Text>Did you mean:</Text>
          </div>
          <div>
            <ul>{agentListDivs}</ul>
          </div>
          <div className="py-4 flex flex-row items-center">
            <Text>If none of these are correct, </Text>
            <Button
              id="wb_agent_verification_add_agent"
              variant="ghost"
              size="small"
              label="add a new agent."
              onClick={() => {
                props.setIsAddAgentModalActive(true);
                props.onClose();
              }}
            />
          </div>
        </div>
      ) : (
        <div className="py-4 flex flex-row items-center">
          <Text>No matches were found. </Text>
          <Button
            id="wb_agent_verification_add_agent"
            variant="ghost"
            size="small"
            label={`Click here to ${props.agentRole == 'cooperating' ? 'invite' : 'add'} a new agent.`}
            onClick={() => {
              props.setIsAddAgentModalActive(true);
              props.onClose();
            }}
          />
        </div>
      )}
    </>
  );
};

export default AgentVerification;
