import React from 'react';
import { motion } from 'framer-motion';
import './OverlaySpinner.css';
import { ProgressIndicatorCircular } from '@components/spinner/ProgressIndicatorCircular';

const variants = {
  overlay: {
    initial: { opacity: 0 },
    active: { opacity: 0.7 },
  },
};

export type ProgressIndicatorSize = 'small' | 'medium' | 'large';

export interface ProgressIndicatorProps {
  /**
   * Controls size of the component.
   * @default medium
   */
  size?: ProgressIndicatorSize;
}

interface Props extends ProgressIndicatorProps {
  id: string;
  /**
   * Whether or not to show the loader
   */
  isActive: boolean;
}

const OverlaySpinner: React.FC<Props> = (props) => {
  return props.isActive ? (
    <>
      <motion.div
        id={`${props.id}-spinner`}
        key="overlay"
        initial="initial"
        animate="active"
        exit="initial"
        variants={variants.overlay}
        className="!fixed inset-0 bg-white z-top flex items-center justify-center"
        aria-hidden={!props.isActive}
      />
      <div className="!fixed inset-0 z-top flex items-center justify-center spinner-container">
        <ProgressIndicatorCircular size={props.size || 'large'} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default OverlaySpinner;
