import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { toFormattedNumber } from '@helpers/utils';
import { Button, IconClockOutline } from '@ftdr/blueprint-components-react';
import React, { useState } from 'react';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_CONTRACT_RENEWALS_VIEW_CONTRACTS } from '@constants/ga-events.constants';
import DrawerOrdersSendRenewal from '@components/drawer/DrawerOrdersSendRenewal';

function CardDashboardActionUpForRenewal(props: ICardDashBoardActionProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const onClickActionButton = () => {
    fireGAEvent(DASHBOARD_CONTRACT_RENEWALS_VIEW_CONTRACTS);
    setIsDrawerOpen(true);
  };

  return (
    <>
      {!props.hide && (
        <>
          <CardDashboardAction
            isManualFetch={props.isManualFetch}
            fetchCount={props.fetchCount}
            type={
              props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention
            }
            heading={props.count === null ? null : toFormattedNumber(props.count)}
            subHeading="Contracts eligible for Renewal"
            action={
              <Button
                label="Review and Send Email"
                size="medium"
                startIcon={<IconClockOutline />}
                disabled={props.count === null || props.count === 0}
                onClick={() => onClickActionButton()}
              />
            }
          />

          <DrawerOrdersSendRenewal
            isActive={isDrawerOpen}
            onUpdateOrderCount={props.updateCount}
            onClose={() => setIsDrawerOpen(false)}
            initialTotalContracts={props.count}
          />
        </>
      )}
    </>
  );
}

export default CardDashboardActionUpForRenewal;
