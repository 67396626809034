import Mark from 'mark.js';
import React, { useCallback, useEffect, useRef } from 'react';
import { classNames } from '@utils';

export type PropertyInfoType = {
  id: string;
  streetAddress: string;
  initiatingAgent: string;
  initiatingOffice: string;
  officeAddress: string;
  status: string;
  value: string;
};

type PropertyInfoOptionProps = {
  option: PropertyInfoType;
  query: string;
  setHovered: (hit: PropertyInfoType) => void;
  onClick: (id: string) => void;
  active: boolean;
};

const PropertyInfoOption = ({
  option,
  query,
  setHovered,
  onClick,
  active,
}: PropertyInfoOptionProps): JSX.Element => {
  if (active) console.log('active', active, option.id);
  const container = useRef<HTMLDivElement | null>(null);

  const performMark = useCallback(() => {
    if (container?.current) {
      const instance = new Mark(container.current);
      instance.unmark({
        done: function () {
          instance.mark(query.split(' '), {
            separateWordSearch: false,
            diacritics: true,
            debug: false,
            exclude: ['label', '.DO-NOT-HIGHLIGHT'],
            className: 'font-bold',
          });
        },
      });
    }
  }, [query]);

  useEffect(() => {
    performMark();
  }, [query]);

  return (
    <div
      ref={container}
      id={`${option.id}-id`}
      className={classNames([
        'p-6 items-center cursor-pointer border-b-2 border-gray-300',
        active ? 'bg-gray-200' : '',
      ])}
      onClick={() => onClick(option.id)}
      onMouseEnter={() => setHovered(option)}
    >
      {option?.id && (
        <section className="flex justify-between text-gray-700">
          <div className="flex flex-col">
            <span id="search-option-contract-id" className="mb-4 font-bold text-black">
              <label>Contract #</label>
              {option.id}
            </span>
            <span id="search-option-streetAddress">{option.streetAddress}</span>
            <span id="search-option-initiating-agent" className="DO-NOT-HIGHLIGHT">
              <label>Initiating Agent: </label>
              {option.initiatingAgent}
            </span>
            <span id="search-option-initiating-office" className="DO-NOT-HIGHLIGHT">
              <label>Initiating Office: </label>
              {option.initiatingOffice}
            </span>
            <span id="search-option-office-address" className="DO-NOT-HIGHLIGHT">
              <label>Office Address: </label>
              {option.officeAddress}
            </span>
            <span id="search-option-status" className="DO-NOT-HIGHLIGHT">
              <label>Status: </label>
              {option.status?.toUpperCase()}
            </span>
          </div>
        </section>
      )}
    </div>
  );
};

export default PropertyInfoOption;
