import React from 'react';
import AccordionItemPayment from '@components/accordion/AccordionItemPayment';
import { classNames } from '@utils';
import { WarrantyLinkCheck } from '@apis/models';
import {
  Accordion,
  AccordionSection,
  Badge,
  Button,
  Dialog,
  Tag,
  Text,
} from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';
import { dateTypes } from '@constants/dates';
import { ContentBox } from '@components/layout/ContentBox';
import DateRangeSelectionModal from '@components/dateRange/DateRangeSelectionModal';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import { FilterOperation, FilterType } from '@constants/dashboardFilters';
import { fireGAEvent } from '@app/core/tracking.service';
import { NAVIGATION__ORDER_FILTER_SORT } from '@constants/ga-events.constants';
import { formatDate, toCurrency } from '@helpers/utils';
import TagGroup from '@components/tag/TagGroup';
import { mediaBreakpoints } from '@constants/tailwindConstants';
import { DateFilterButton } from '@components/button/DateFilterButton';
import { WarrantyLinkPaymentFilter } from '@components/filter/WarrantyLinkPaymentFilter';

interface props {
  /** When true, fetching API data. Used to determine if loading bar is displayed. */
  isLoading?: boolean;

  /** A list of WarrantyLinkCheck objects to filter through. */
  warrantyLinkChecks: WarrantyLinkCheck[];

  /** URL request data for applying a prefilter. */
  preFilter?: any;
}

interface state {
  /** If true, display date range selection modal on web view */
  showDatePicker: boolean;

  /** Start date for date filter. Inclusive bound, */
  startDate: Date;

  /** End date for date filter. Inclusive bound. */
  endDate: Date;

  /** Type of filter operation. Can either be ADD or REMOVE, for adding and removing a filter, respectively. */
  type: string;

  /** The width of the screen as a number value. Used to determine whether a user is on mobile view. */
  width: number;

  /** Boolean value to represent whethe a user is on mobile view. Adjusts based on the width state. */
  isMobile: boolean;

  /** If a user is on mobile view, this state determines whether the date range selection for mobile view is visible. */
  showFilterModal: boolean;

  /** List of date filters applied to the payment checks. */
  filterList: WarrantyLinkPaymentFilter[];

  /** List of checks after filters are applied. */
  warrantyLinkChecksFiltered: WarrantyLinkCheck[];
}

class WarrantyLinkPaymentsTemplate extends React.Component<props, state> {
  didApplyPreFilter: boolean = !this.props.preFilter;
  requestBody = this.props.preFilter?.payload;

  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      startDate: null,
      endDate: null,
      type: dateTypes.ESTCOE,
      width: window.innerWidth,
      isMobile: window.innerWidth < mediaBreakpoints.lg,
      showFilterModal: false,
      filterList: [],
      warrantyLinkChecksFiltered: props.warrantyLinkChecks,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth, isMobile: window.innerWidth < mediaBreakpoints.lg });
  };

  handleDateRangeFilter = (dateType: string, presetTitle: string, start, end) => {
    if (start && end && dateType) {
      const startDateStr = formatDate(start);
      const endDateStr = formatDate(end);

      this.onFilterChange(undefined, {
        operation: FilterOperation.ADD,
        type: FilterType.DATE,
        payload: {
          date: {
            start: new Date(start),
            end: new Date(end),
          },
        },
        label: `Payment Date: ${startDateStr} - ${endDateStr}`,
      });
    }
  };

  headerRenderer(check) {
    return (
      <div className="flex relative w-full pr-16 md:pr-20 pl-4 md:pl-6 text-sm md:text-base">
        <div className="w-32 md:w-40 font-bold text-left">{check?.date || <Skeleton />}</div>
        <div className="flex-auto pl-4 text-left">{check?.checkNumber || <Skeleton />}</div>
        <div className="w-24 md:w-32 pl-4 text-right">
          {check ? `${toCurrency(parseFloat(check?.amount))}` : <Skeleton />}
        </div>
      </div>
    );
  }

  /**
   * On [add/remove] filter changes, we want to check if it is a valid change. If so, we would then make a call to refresh the dashboard contracts.
   * On refresh, we should return back to first page in pagination.
   */
  onFilterChange(label: string, event: WarrantyLinkPaymentFilter): boolean {
    let result: boolean;

    try {
      // Only handle date filter change
      result = this.updateDateFilter(event);

      // On valid update, we want to proceed onwards to update the tags and make a new request
      if (result && event.label) {
        fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${event.operation}: ${event.label}`));
      }
    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result;
  }

  updateDateFilter(event: WarrantyLinkPaymentFilter): boolean {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = this.state.filterList.find(({ type }) => type === 'date');

    /** Determine if we are adding or removing a date filter */
    switch (event.operation) {
      case FilterOperation.ADD: {
        // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
        const startDate = new Date(date.start);
        const endDate = new Date(date.end);
        const validDates = Boolean(startDate && endDate);
        if (validDates) {
          this.setState({ filterList: [event] });
          const newWarrantyLinkChecksList = [];
          this.props.warrantyLinkChecks.forEach((check) => {
            const checkDate = new Date(check.date);
            if (checkDate >= startDate && checkDate <= endDate) {
              newWarrantyLinkChecksList.push(check);
            }
          });
          this.setState({ warrantyLinkChecksFiltered: newWarrantyLinkChecksList });
          validUpdate = true;
        }
        break;
      }
      case FilterOperation.REMOVE: {
        if (inFilter) {
          this.setState({ filterList: [] });
          this.setState({ warrantyLinkChecksFiltered: this.props.warrantyLinkChecks });
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it');
        }
        break;
      }
    }

    return validUpdate;
  }

  /** Renderer for Date Range button */
  dateRenderer = () => {
    return (
      <DateFilterButton
        id="wl_payments_date_filter_button"
        onClick={() => this.setState({ showDatePicker: true })}
      />
    );
  };

  /** Renderer for Date Range Selection Modal */
  dateSelectionRenderer = () => {
    return (
      <DateRangeSelectionModal
        open={this.state.showDatePicker}
        initialValues={null}
        statusMenuType={StatusMenuType.WarrantyLinkPayments}
        onChange={this.handleDateRangeFilter}
        onClose={() => {
          this.setState({ showDatePicker: false });
        }}
      />
    );
  };

  /** Display number of filters applied for mobile view */
  getFilterBadge = () => {
    return this.state.filterList?.length ? (
      <Badge color="interactive" size="small" maxCount={99} className="ml-1-important">
        {this.state.filterList.length.toString()}
      </Badge>
    ) : null;
  };

  render = () => {
    if (!this.props.isLoading && !this.didApplyPreFilter && this.requestBody) {
      this.handleDateRangeFilter(
        dateTypes.ESTCOE,
        null,
        this.requestBody?.date?.start,
        this.requestBody?.date?.end,
      );
      this.didApplyPreFilter = true;
    }
    return (
      <>
        {/** The ContentBox is rendered at the template level to allow for cleaner access to dateRenderer and its dependencies. */}
        <ContentBox
          title="Payments"
          leftContent={
            !this.state.isMobile && (
              <div className="w-48 min-w-48 mr-4">
                <Text variant="heading-06" className="my-2">
                  Filter Payments
                </Text>
                <AccordionSection label="Date Range" headerRenderer={this.dateRenderer} />
              </div>
            )
          }
        >
          <div
            id="wlk-sticky-container"
            className="extended-border-invisible border-bottom-faded sticky-container-top-index-3"
          >
            {this.state.isMobile ? (
              <div>
                <Button
                  id="filter-wlk-payments-button"
                  label="Filter Payments"
                  shape="rounded"
                  variant="outlined"
                  endIcon={this.getFilterBadge()}
                  onClick={() => this.setState({ showFilterModal: true })}
                  width="full"
                  labelAlign="center"
                />
                <div className="inline-block mt-2 md:mb-4">
                  <TagGroup>
                    <>{this.state.filterList.length ? 'Filtered by:' : ''}</>
                    {this.state.filterList.map((filter) => (
                      <Tag
                        id={`wlk_payments_date_filter__${filter.label}`}
                        key={filter.label}
                        className={this.state.isMobile ? '' : 'ml-4'}
                        removable
                        color="interactive"
                        onClick={(e) => {
                          filter.operation = FilterOperation.REMOVE;
                          this.onFilterChange(filter.label, filter);
                        }}
                      >
                        {filter.label}
                      </Tag>
                    ))}
                  </TagGroup>
                </div>
              </div>
            ) : (
              <div className="md:mb-4">
                <TagGroup>
                  <>{this.state.filterList.length ? 'Filtered by:' : ''}</>
                  {this.state.filterList.map((filter, idx) => (
                    <Tag
                      id={`wlk_payments_date_filter__${filter.label}`}
                      key={filter.label}
                      className="ml-4"
                      removable
                      color="interactive"
                      onClick={(e) => {
                        filter.operation = FilterOperation.REMOVE;
                        this.onFilterChange(filter.label, filter);
                      }}
                    >
                      {filter.label}
                    </Tag>
                  ))}
                </TagGroup>
              </div>
            )}
          </div>

          {/** Display no results found if no payments match date range. */}
          {!this.props.isLoading &&
            this.state.filterList.length !== 0 &&
            this.state.warrantyLinkChecksFiltered.length == 0 && (
              <div className="mt-3">
                <Text>No Results Found</Text>
              </div>
            )}

          {/* Contract list header*/}
          <div
            className={classNames([
              'flex w-full mt-4 mb-2 pr-16 md:pr-20 pl-4 md:pl-6 text-xs font-bold text-primary-400 uppercase tracking-wide',
            ])}
          >
            <div className="pl-8 w-32 md:w-40">
              <span className="sm-max:hidden">Payment Date</span>
              <span className="md:hidden">Paid On</span>
            </div>
            <div className="flex-auto pl-4 ml-8">
              <span className="sm-max:hidden">Check Number</span>
              <span className="md:hidden">Check#</span>
            </div>
            <div className="w-24 md:w-32 mr-6 text-right">
              <span className="sm-max:hidden">Payment Total</span>
              <span className="md:hidden">Total</span>
            </div>
          </div>
          {/* /!* Display skeleton load if there are still pending contracts *!/ */}
          {this.props.isLoading ? (
            // @ts-expect-error TODO: label is not typed correctly
            <AccordionSection className="w-full" label={this.headerRenderer(null)} />
          ) : (
            /** Load contract list if we have finished retrieving all data. */
            <Accordion mode="single" id="payments-accordion-parent" className="bg-white">
              {!this.props.isLoading &&
                (this.state.filterList.length
                  ? this.state.warrantyLinkChecksFiltered
                  : this.props.warrantyLinkChecks
                ).map((check) => (
                  <AccordionSection
                    className="w-full"
                    // @ts-expect-error TODO: label is not typed correctly
                    label={this.headerRenderer(check)}
                    key={check.checkNumber}
                  >
                    <AccordionItemPayment check={check} rows={check.contracts} />
                  </AccordionSection>
                ))}
            </Accordion>
          )}
        </ContentBox>
        {!this.state.isMobile && this.dateSelectionRenderer()}
        {/** Dialog popup for mobile view */}
        <Dialog
          id="filter-wlk-modal"
          header="Filter Payments"
          modal={true}
          onClose={() => this.setState({ showFilterModal: false })}
          open={this.state.showFilterModal && this.state.isMobile}
          actionsAlign="right"
          actions={[
            <Button
              key="action"
              size="small"
              label="Done"
              onClick={() => this.setState({ showFilterModal: false })}
            />,
          ]}
        >
          <div className="min-w-80">
            {this.dateRenderer()}
            {this.dateSelectionRenderer()}
          </div>
        </Dialog>
      </>
    );
  };
}

export default WarrantyLinkPaymentsTemplate;
