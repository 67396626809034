import React from 'react';
import { AbstractNewOrderStep, wrapNavigation } from '@pages/order/AbstractNewOrderStep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { NewOrderStep2Template } from '@templates/order/NewOrderStep2Template';
import { NewOrderContextData, NewOrderMetaData } from '@context/NewOrderContext/model';
import Layout from '@components/layout/Layout';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { ProductApiSuppressErrors } from '@apis/product.api';
import cloneDeep from 'lodash/cloneDeep';
import OrdersApi from '@apis/orders.api';

class NewOrderStep2 extends AbstractNewOrderStep {
  constructor(props) {
    super(props);
  }

  getCurrentStep(): NewOrderStep {
    return NewOrderStep.PlanCoverage;
  }

  async handleOrderUpdate(
    updatedData: NewOrderContextData,
    updatedMeta = this.context.metaData,
  ): Promise<{ orderID: string; quoteID: string; success: boolean }> {
    // attempt to create a quote id first. if it fails, then do not continue.
    let orderID = this.context.metaData.orderID;
    let quoteID = this.context.metaData.quoteID;
    try {
      quoteID = await this.createQuote(updatedData);
      updatedMeta.quoteID = quoteID;
    } catch (e) {
      console.error('failed to create new quote', e);
      return { orderID, quoteID, success: false };
    }

    let orderUpdateComplete = false;
    try {
      useGlobalOverlaySpinner().showSpinner(true, 'updating order...');
      const orderRequest = this.context.toOrderRequest(updatedData, updatedMeta);
      if (!orderID) {
        console.info('order will be created...');
        const orderResponse = await OrdersApi.create(orderRequest);
        console.info('orderid created', orderResponse.orderID);
        orderID = orderResponse.orderID;
        orderUpdateComplete = true;
      } else if (this.context.hasOrderRequestChanged(orderRequest)) {
        console.info('order will be updated...', orderID);
        await OrdersApi.update(orderID, orderRequest);
        console.info('orderid updated', orderID);
        orderUpdateComplete = true;
      } else {
        console.info('no changes on the order found. proceed without updates', orderID);
        orderUpdateComplete = true;
      }
    } catch (e) {
      console.error('error occurred processing order', e);
    } finally {
      useGlobalOverlaySpinner().showSpinner(false);
    }

    return { orderID, quoteID, success: orderUpdateComplete };
  }

  /** returns quote id. if quote does not need to be changed, then existing quote is returned */
  async createQuote(updatedData: NewOrderContextData): Promise<string> {
    // quote needs to be regenerated if product, coverage, or closing date was changed
    const existingQuoteID = this.context.metaData.quoteID;
    const newQuoteRequired =
      !existingQuoteID || // no existing quote yet
      JSON.stringify(this.context.newOrderData.productFilters) !==
        JSON.stringify(updatedData.productFilters) ||
      JSON.stringify(this.context.newOrderData.planInfo) !== JSON.stringify(updatedData.planInfo);

    if (!newQuoteRequired) {
      console.info('no changes found for the quote. proceed with existing quote', existingQuoteID);
      return existingQuoteID;
    }

    console.info(
      'new changes found for the quote. creating a new quote.... existingQuote=',
      existingQuoteID,
    );

    useGlobalOverlaySpinner().showSpinner(true, 'saving new quote...');
    const quoteResponse = await ProductApiSuppressErrors.createQuote(
      updatedData.planInfo.pricingRequest,
    ).finally(() => {
      useGlobalOverlaySpinner().showSpinner(false);
    });

    if (!quoteResponse?.quoteID) {
      throw new Error('response is empty');
    }

    console.info('successfully created new quote id', quoteResponse.quoteID);
    return quoteResponse.quoteID;
  }

  async saveData(
    updatedData: NewOrderContextData,
    updatedMeta: NewOrderMetaData,
  ): Promise<{ data: NewOrderContextData; meta: NewOrderMetaData }> {
    const postUpdateData: NewOrderContextData = cloneDeep(updatedData);
    const postUpdateMeta: NewOrderMetaData = cloneDeep(updatedMeta);

    const { success, orderID, quoteID } = await this.handleOrderUpdate(
      postUpdateData,
      postUpdateMeta,
    );
    if (!success) {
      throw new Error('failed to save order data');
    }

    postUpdateMeta.orderID = orderID;
    postUpdateMeta.quoteID = quoteID;
    postUpdateMeta.planReselectionReason = ''; // clear plan selection reason before submitting

    this.context.setNewOrderData(postUpdateData);
    this.context.setMetaData(postUpdateMeta);
    return { data: postUpdateData, meta: postUpdateMeta };
  }

  async submitPage(updatedData: NewOrderContextData) {
    // ga event
    // for pages, set the context data, and navigate to a different step
    // if the changes on the page conflicts with later parts of the page (e.g. changing property address requires change in plans)
    //  then update the context based on what's necessary
    // we'd also want to create an order or update the order, depending on if the context contains the order id or not
    // for order submit, call the api to submit the order, and complete the order
    try {
      await this.saveData(updatedData, this.context.metaData);
      this.navigateToStep(NewOrderStep.BuyerSellerInfo);
    } catch (e) {
      console.error('failed to save order data', e);
      return;
    }
  }

  async editAnotherStep(step: NewOrderStep, updatedData?: NewOrderContextData) {
    // ga event
    // for pages, set the data based off of what is being updated, then navigate to step
    if (updatedData) {
      try {
        await this.saveData(updatedData, this.context.metaData);
      } catch (e) {
        console.error('failed to save order data', e);
        return;
      }
    }
    this.navigateToStep(step);
  }

  cancelPage() {
    // ga event
    this.context.resetNewOrderData();
    this.navigateToDashboard();
  }

  render() {
    return (
      <Layout isLoggedIn={true} slug="newOrderStep2" containerClassName="py-5">
        <NewOrderStep2Template
          currentStep={this.getCurrentStep()}
          latestStep={this.context.latestStep}
          savedData={this.context.newOrderData}
          metaData={this.context.metaData}
          userProfile={this.context.userProfile}
          submitPage={(updatedData) => this.submitPage(updatedData)}
          cancelPage={() => this.cancelPage()}
          editAnotherStep={(step, updateData) => this.editAnotherStep(step, updateData)}
        />
      </Layout>
    );
  }
}

export default wrapNavigation(NewOrderStep2);
