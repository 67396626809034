import React from 'react';
import Modal, { ModalProps } from './Modal';
import { AgentSearchRecord } from '@apis/models';
import AgentVerification from '@components/modal/subcomponents/AgentVerification';

interface Props extends ModalProps {
  bodyMessage: string;
  footerMessage: string;
  footerMessage2: string;
  selectedAgent: string;
  agentRole: 'initiating' | 'cooperating' | 'closing';
  agentList: AgentSearchRecord[];
  // needed for parent update
  setTypeAheadAddress: (val: any) => void;
  setIsAddAgentModalActive: (status: boolean) => void;
  office: any;
  onSelectAgent: (agent: AgentSearchRecord) => void;
}

export const ModalAgentVerification: React.FC<Props> = (props) => {
  return (
    <Modal
      id={props.id}
      isActive={props.isActive}
      onClose={props.onClose}
      heading={props.heading}
      canClose={true}
    >
      {props.isActive && <AgentVerification {...props} />}
    </Modal>
  );
};

ModalAgentVerification.defaultProps = {
  id: 'modal-agent-verification',
  heading: 'There was a problem with the agent name',
};
