import React, { useState, useEffect } from 'react';

// Components
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import { Button, Text } from '@ftdr/blueprint-components-react';
import { Contract, ContractDocumentType } from '@apis/models';
import { addressToString } from '@services/helpers';
import { formatDateFromString } from '@helpers/utils';
import ContractApi from '@apis/contract.api';

export interface DrawerDocumentProps extends DrawerProps {
  /** the contract that is being extended */
  contract: Contract;
  /** document type */
  docType: ContractDocumentType;
}

const DrawerDocument: React.FC<DrawerDocumentProps> = ({
  contract,
  docType,
  onClose,
  ...props
}) => {
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [address, setAddress] = useState('');
  const [agentName, setAgentName] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [pdfURL, setPdfURL] = useState('');

  const docTypeHeaders = {
    invoice: 'Invoice',
    confirmation: 'Order Confirmation',
    contract: 'Contract',
  };

  /** Restores the state back to its default values at once */
  const resetState = (): void => {
    setIsValid(false);
    setAddress('');
    setAgentName('');
    setOfficeName('');
    setExpirationDate('');
    setOrderNumber('');
    setPdfURL('');
    setHasError(false);
    setLoading(false);
  };

  /** Initial load */
  useEffect(() => {
    resetState();
  }, []);

  /** When drawer opens or closes, refresh view */
  useEffect(() => {
    resetState();

    if (props.isActive) {
      const isValid = Boolean(contract);
      if (isValid) {
        setAddress(
          addressToString(
            contract.summary.address.address1,
            contract.summary.address.address2,
            contract.summary.address.city,
            contract.summary.address.state,
            contract.summary.address.zip,
          ),
        );
        setAgentName(contract.summary.agentName);
        setOfficeName(contract.summary.officeName);
        setExpirationDate(formatDateFromString(contract.summary.expirationDate));
        setOrderNumber(contract.summary.id);

        loadPDF(contract.summary.id);

        setIsValid(true);
      }
    }
  }, [props.isActive]);

  /** Loads a PDF for the contract for the current doc type */
  const loadPDF = (contractId: string): void => {
    setLoading(true);
    getContractPDFURL(contractId, docType)
      .then((url) => {
        setPdfURL(url);
        setHasError(!url); // is considered an error if url is empty
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /** Initiate a download for the document */
  const downloadPDF = (): void => {
    const fileName = `${docType}-${orderNumber}.pdf`;
    downloadFile(pdfURL, fileName);
  };

  return (
    <Drawer
      onClose={() => {
        resetState();
        onClose();
      }}
      useDefaultWidth={false}
      className="w-5/6"
      {...props}
    >
      <div className="sm:flex sm:flex-wrap sm:justify-between sm:items-center sm:-mx-4 -mt-4">
        <div className="py-4 sm:px-4">
          <Text variant="heading-03" className="h3">
            {docTypeHeaders[docType] || docType}
          </Text>

          <Text className="mt-2 sm:text-lg text-primary-400">{address}</Text>
        </div>

        <div className="sm:px-4">
          <Button
            id="downloadPdf"
            label="Download"
            size="medium"
            onClick={downloadPDF}
            disabled={!pdfURL}
          />
        </div>
      </div>

      <div className="sm:flex sm:flex-wrap sm:justify-between w-full mt-4">
        <Text className="sm:mr-4">
          <Text as="strong" variant="heading-06">
            {agentName}
          </Text>
          <br />
          {officeName}
        </Text>

        <Text className="xs-max:mt-2">
          Order #: {orderNumber}
          <br />
          Expires: {expirationDate}
        </Text>
      </div>

      <Text
        id="pdfViewer"
        className="flex justify-center items-center w-full h-256 mt-4 bg-gray-300 rounded"
      >
        {!isValid ? (
          <Text>Unable to determine contract to display PDF</Text>
        ) : loading ? (
          <Text>loading PDF...</Text>
        ) : hasError ? (
          <Text>An error occurred when trying to load the PDF</Text>
        ) : !pdfURL ? (
          <Text>Unable to load PDF</Text>
        ) : (
          <object type="application/pdf" data={pdfURL} className="w-full h-full">
            <embed type="application/pdf" src={pdfURL} />
          </object>
        )}
      </Text>
    </Drawer>
  );
};

/** Decode base64 to a data blob and write a temporary URL for the PDF */
const base64ToPdfURL = (pdfBlob): string => {
  const binPdf = window.atob(pdfBlob);
  const byteNumbers = new Array(binPdf.length);
  for (let i = 0; i < binPdf.length; i++) {
    byteNumbers[i] = binPdf.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], {
    type: 'application/pdf',
  });
  return window.URL.createObjectURL(blob);
};

/** Loads a PDF for the contract for the current doc type */
export const getContractPDFURL = (
  contractId: string,
  docType: ContractDocumentType,
): Promise<string> => {
  return ContractApi.getContractDocument(contractId, [docType])
    .then((res) => {
      try {
        if (res && res.documents[docType]) {
          const blob = res.documents[docType];
          return base64ToPdfURL(blob);
        }
      } catch (e) {
        console.error('failed to load pdf', e);
        return null;
      }
    })
    .catch((e) => {
      console.error('failed to make call', e);
      return null;
    });
};

/** Initiate a download for the document */
export const downloadFile = (blobURL: string, fileName: string): void => {
  const a = document.createElement('a');
  a.href = blobURL;
  a.setAttribute('download', fileName);
  a.click();
};

export default DrawerDocument;
