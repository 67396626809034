import { ProgressIndicator, Text } from '@ftdr/blueprint-components-react';
import React from 'react';

const LoadingIndicator = () => {
  return (
    <div className="align-center mb-4">
      <ProgressIndicator className="my-5 pt-1" size="large" />
      <Text>Loading...</Text>
    </div>
  );
};

export default LoadingIndicator;
