import React from 'react';
import { NewOrderContextData, NewOrderMetaData } from '@context/NewOrderContext/model';
import { NewOrderStep } from '@app/models/order/neworder.model';
import NewOrderPageLayout from '@components/orders/neworder/layout/NewOrderPageLayout';
import ProfileModel from '@app/models/profile.model';
import { getBrand } from '@helpers/brand.utils';

export interface NewOrderStepTemplateProps {
  userProfile: ProfileModel;
  /** the step the template the user is currently in */
  currentStep: NewOrderStep;
  /** the most latest step the user has gotten to */
  latestStep: NewOrderStep;
  /** valid data currently stored for the new order */
  savedData: NewOrderContextData;
  /** meta data currently stored for the new order */
  metaData: NewOrderMetaData;
  /** data to store to perform on when user has submitted the page (continue or submit) */
  submitPage: (updatedData: NewOrderContextData) => void;
  /** when user has cancelled */
  cancelPage: () => void;
  /** in the event that the user has decided to go edit another section, navigate the user.
   * If the function has updatedData, we want to update it. */
  editAnotherStep: (step: NewOrderStep, updatedData?: NewOrderContextData) => void;
}

export interface NewOrderStepStates {}

export abstract class AbstractNewOrderStepTemplate<
  S extends NewOrderStepStates = NonNullable<unknown>,
> extends React.Component<NewOrderStepTemplateProps, S> {
  protected constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  /** should be called when user is submitting. runs validation for all fields and updates errors.
   * returns true if no validation errors and is allowed for submission. */
  abstract isFormValidForSubmission(): boolean;

  /** returns the new order data the user is currently working with */
  abstract getPendingData(): NewOrderContextData;

  /** returns the new order data the user has been working it, stripped off of any incomplete data in the form. */
  abstract getPartialSubmittedData(): NewOrderContextData;

  /** helper function get the default State for resetting. */
  abstract getDefaultState(props: NewOrderStepTemplateProps): S;

  /** render the form */
  abstract renderForm(): JSX.Element;

  /** when user chooses to clear the form, we want to reset the state to its default */
  abstract onClear(): void;
  // this.setState(this.getDefaultState(this.props));

  /** when user submits, we want to submit the page. */
  abstract onSubmit(): void;
  // this.props.submitPage(this.getPendingData());

  /** when user edits to a different step, we want to partial submit any submittable data. */
  abstract onEdit(editStep: NewOrderStep): void;
  // this.props.editAnotherStep(editStep, this.getPartialSubmittedData());

  getBrand() {
    return getBrand();
  }

  render() {
    return (
      <NewOrderPageLayout
        currentStep={this.props.currentStep}
        latestStep={this.props.latestStep}
        orderData={this.getPendingData()}
        onCancel={() => this.props.cancelPage()}
        onClear={() => this.onClear()}
        onContinue={() => this.onSubmit()}
        onEdit={(step) => this.onEdit(step)}
      >
        {this.renderForm()}
      </NewOrderPageLayout>
    );
  }
}
