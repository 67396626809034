import React from 'react';
import { classNames } from '@utils';
import { Button, IconWarningCircledFull } from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { orderStatusDictionary } from '@constants/dictionaries';
import ActionsDropdown from '@components/button/ActionsDropdown';

interface CardOrderGridProps {
  skeleton?: boolean;
  className?: string;
  menuLinks?: {
    label: string;
    onClick: () => void;
  }[];

  street?: string;
  unit?: string;
  city?: string;
  state?: string;
  zip?: string;
  agent?: string;
  office?: string;

  /** Currently only accepts specific string 'payment-due', 'closing-soon', 'active' TODO: Dont... */
  status?: RealEstateStatus;
  enableActions?: boolean;
  awaitingWlkSubmission?: boolean;

  viewOrder?: () => void;

  showMakePaymentButton?: boolean;
  openPayment?: () => void;
}

const CardOrderGrid = (props: CardOrderGridProps) => {
  const getOrderStatus = () => {
    const status = props.awaitingWlkSubmission
      ? RealEstateStatus.AWAITING_WL_SUBMISSION
      : props.status;

    const isErrorStatus = [
      RealEstateStatus.EXPIRED,
      RealEstateStatus.EXPIRING,
      RealEstateStatus.PAYMENT_DUE,
      RealEstateStatus.PAYMENT_PAST_DUE,
      RealEstateStatus.AWAITING_WL_SUBMISSION,
    ].includes(status);
    const isWarningStatus = [RealEstateStatus.CLOSING_SOON].includes(status);
    let textClass = '';
    let sfColor = 'text-gray-500'; // @deprecated: colors doesn't work anymore
    let showStatusText = false;
    let showIcon = false;

    if (isErrorStatus) {
      sfColor = 'text-red-400';
      textClass = 'ml-1';
      showStatusText = true;
      showIcon = true;
    } else if (isWarningStatus) {
      sfColor = 'text-yellow-400';
      textClass = 'ml-1';
      showStatusText = true;
      showIcon = true;
    } else {
      sfColor = 'text-gray-500';
    }

    return !showIcon && !showStatusText ? null : (
      <>
        {!showIcon ? null : (
          <IconWarningCircledFull size={14} className={classNames(['inline -mt-1', sfColor])} />
        )}
        <span className={classNames(['card--order-grid--status-text', textClass, sfColor])}>
          {orderStatusDictionary[status]}
        </span>
      </>
    );
  };

  return (
    <>
      <div
        className={classNames([
          'card card--order-grid group flex flex-col h-full px-6 py-4 hover:shadow-5 cursor-pointer transition',
          props.className,
        ])}
        onClick={props.skeleton || !props.enableActions ? undefined : props.viewOrder}
      >
        <div className="border-b">
          <div className="flex justify-between items-center">
            {props.skeleton ? (
              <>
                <div className="text-xs font-bold leading-tight">
                  <Skeleton circle={true} width={12} height={12} />
                  <span className="ml-1 text-red-400">
                    <Skeleton width={80} />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="text-xs font-bold leading-tight">{getOrderStatus()}</div>

                <div className="card--order-grid--menu ml-4" onClick={(e) => e.stopPropagation()}>
                  {!props.skeleton && props.enableActions ? (
                    <ActionsDropdown menuItems={props.menuLinks} isLabelVisible={false} />
                  ) : (
                    <div className="h-9" />
                  )}
                </div>
              </>
            )}
          </div>

          <div className="card--order-grid--header mb-2 text-primary-400 font-bold md:text-lg text-left group-hover:text-primary-300">
            {props.skeleton ? (
              <>
                <Skeleton />
                <br />
                <Skeleton />
              </>
            ) : (
              <>
                {props.unit && props.unit != '' ? `${props.street}, ${props.unit}` : props.street}
                <br />
                {`${props.city}, ${props.state} ${props.zip}`}
              </>
            )}
          </div>
        </div>

        <p className="card--order-grid--body mt-2">
          {props.skeleton ? (
            <>
              <Skeleton />
              <br />
              <Skeleton />
            </>
          ) : (
            <>
              <strong className="card--order-grid--agent block">{props.agent}</strong>
              <span className="card--order-grid--office">{props.office}</span>
            </>
          )}
        </p>

        {props.showMakePaymentButton && (
          <div
            className="card--order-grid--btn-make-payment mt-2"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              label="Make Payment"
              size="small"
              width="full"
              labelAlign="center"
              onClick={props.openPayment}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CardOrderGrid;
