import at from 'array-string-at';
import replaceAll from 'core-js-pure/features/string/replace-all';

export function loadPolyfills() {
  loadArrayStringAt();
  loadStringReplaceAll();
}

function loadArrayStringAt() {
  if (typeof String.prototype.at === 'undefined') {
    // @ts-expect-error unknown and string type confliction to resolve from polyfill. can ignore.
    String.prototype.at = function (index) {
      return at(this, index);
    };
  }

  if (typeof Array.prototype.at === 'undefined') {
    Array.prototype.at = function (index) {
      return at(this, index);
    };
  }
}

function loadStringReplaceAll() {
  if (typeof String.prototype.replaceAll === 'undefined') {
    String.prototype.replaceAll = function (searchValue, replaceValue) {
      return replaceAll(this, searchValue, replaceValue);
    };
  }
}
