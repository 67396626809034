import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSection,
  Button,
  Checkbox,
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
  Notification,
  Pagination,
  Panel,
} from '@ftdr/blueprint-components-react';
import PanelContractSummary from '@components/panel/PanelContractSummary';
import AccordionItemWlkContract from '@components/accordion/AccordionItemWlkContract';
import {
  GlobalSearchRestrictions,
  REOrder,
  WarrantylinkContract,
  WarrantylinkContractData,
} from '@apis/models';
import { ITEMS_PER_PAGE_ACTION } from '@constants/dashBoard-constants';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import { SECOND } from '@helpers/utils';
import WarrantyLinkAPI from '@apis/warrantylink.api';
import WarrantylinkApi from '@apis/warrantylink.api';
import msgs, { msgsWParams } from '@app/locales/en';
import ModalLegalConfirmation from '@components/modal/ModalLegalConfirmation';
import ProfileContext from '@context/ProfileContext';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_BULK_SUBMIT_WARRANTY_LINK,
  DASHBOARD_SUBMIT_WARRANTY_LINK,
} from '@constants/ga-events.constants';
import { isEmpty } from 'lodash';
import { Message } from '@app/core/GlobalAlertModal';
import { ContractSearchBarHeaderContentRendererFunc } from '@components/drawer/subcomponents/ContractSearchBar';
import DashboardActionSwitcher, {
  DashboardActionSearchType,
} from '@components/drawer/subcomponents/DashboardActionSwitcher';
import DashboardActionAdvancedSearch from '@components/drawer/subcomponents/DashboardActionAdvancedSearch';
import { classNames } from '@utils';
import { ContractApiSuppressErrors } from '@apis/contract.api';
import { WarrantylinkStatus } from '@constants/dashboardFilters';
import { getProfileWLKOffices } from '@services/helpers/profile.offices.helper';

const ADVANCED_SEARCH_NO_RESULTS_MSG =
  'Order Not Found. Please try again, considering spelling and order status. Orders eligible for WarrantyLink are searchable.';
const PAGE_SIZE = 10;
const submittableStatuses = [WarrantylinkStatus.READY, WarrantylinkStatus.SAVED];

const WLKVerification = (props) => {
  const { profile } = useContext(ProfileContext);

  const [hasFetchedContractData, setHasFetchedContractData] = useState(false);
  const [totalContracts, setTotalContracts] = useState(props.initialTotalContracts);
  const [pageContractData, setPageContractData] = useState([]);
  const [advancedSearchContractData, setAdvancedSearchContractData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState([]);
  const [checkedContracts, setCheckedContracts] = useState([]);
  const [activeContractId, setActiveContractId] = useState('');

  const [isLegalModalActive, setIsLegalModalActive] = useState(false);
  const [legalModalOffice, setLegalModalOffice] = useState<Partial<REOrder>>({
    id: 0,
    initiatingOfficeName: '',
    initiatingAgentName: '',
  });

  const [isSuccessNotificationActive, setIsSuccessNotificationActive] = useState(false);
  const [isErrorNotificationActive, setIsErrorNotificationActive] = useState(false);
  const [successNotificationMessage, setSuccessNotificationMessage] = useState<
    string | JSX.Element
  >('');
  const [errorNotificationMessage, setErrorNotificationMessage] = useState<string | JSX.Element>(
    '',
  );

  const [searchType, setSearchType] = useState<DashboardActionSearchType>(
    DashboardActionSearchType.ShowAll,
  );

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setCheckedContracts(pageContractData.map((contract) => contract.id));
      setLegalModalOffice(pageContractData[0]);
    } else {
      setCheckedContracts([]);
    }
  };

  useEffect(() => {
    setAccordionActiveIndex([]);
    setHasFetchedContractData(false);
  }, [activePage]);

  useEffect(() => {
    if (!hasFetchedContractData) {
      fetchContractData();
    }
  }, [hasFetchedContractData]);

  useEffect(() => {
    if (checkedContracts.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [checkedContracts]);

  const changeSearchType = (newSearchType: DashboardActionSearchType) => {
    // only when view changes, we want to refresh the state
    if (newSearchType !== searchType) {
      setAccordionActiveIndex([]); // reset selections
      setSearchType(newSearchType);
      setCheckedContracts([]);
    }
  };

  // TODO: has a lot of functions ripped from WarrantyLinkTemplate.tsx, consolidate logic
  const isCustomerFormCompleted = (data: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }): boolean => {
    return Boolean(data.firstName && data.lastName && data.email && data.phone);
  };

  async function onSaveContractForm(contract: Partial<WarrantylinkContract>): Promise<boolean> {
    setSuccessNotificationMessage('');
    setErrorNotificationMessage('');
    setIsSuccessNotificationActive(false);
    setIsErrorNotificationActive(false);

    try {
      const success = await WarrantyLinkAPI.updateWarrantylinkContractForm(contract);

      // On success, refresh the contract data
      setHasFetchedContractData(false);
      return success;
    } catch (error) {
      console.error('failed to update wlk contract form', error);
      return false;
    }
  }

  async function handleSave(contractID, formData) {
    const success = await onSaveContractForm({
      id: contractID,
      warrantylink: {
        form: formData,
      },
    });

    if (success) {
      setAccordionActiveIndex([]);
    }
    return success;
  }
  const validateContractReadyForSubmission = (
    contractSource: any[],
    contractID: string,
  ): boolean => {
    const contract = contractSource.find((c) => c.id === contractID);

    // Contract not found
    if (!contract) {
      return false;
    }

    // Check for complete form data
    if (!contract.warrantylink?.form) return false;
    const { buyer, seller } = contract.warrantylink?.form || {};
    return isCustomerFormCompleted(buyer) || isCustomerFormCompleted(seller);
  };

  const INCOMPLETE_FORM_NOTIFICATION = (invalidContractIDs: string[]): Message => {
    return {
      id: 'modal--wlk-incomplete-form',
      messageHTML: (
        <div>
          Contracts <b>{invalidContractIDs.join(', ')}</b> have incomplete data. You must have
          either buyer or seller information fully completed to submit the contract. Please update
          the information and resubmit.
        </div>
      ),
    };
  };

  async function submitContracts(contractSource: any[]): Promise<boolean> {
    setSuccessNotificationMessage('');
    setErrorNotificationMessage('');
    setIsSuccessNotificationActive(false);
    setIsErrorNotificationActive(false);

    const contractIds: string[] = checkedContracts;

    if (isEmpty(contractIds)) return false;

    // Determine if there are any contracts that are not valid for submission
    // If so, display a modal to alert the user
    const invalidContractIDs: string[] = [];
    const validWarrantylinkContracts: WarrantylinkContractData[] = [];

    for (const contractId of contractIds) {
      if (!validateContractReadyForSubmission(contractSource, contractId)) {
        invalidContractIDs.push(contractId);
      } else {
        const con = contractSource.find((c) => c.id === contractId);
        const WarrantylinkContractData = { id: con.id.toString(), form: con.warrantylink.form };
        validWarrantylinkContracts.push(WarrantylinkContractData);
      }
    }
    if (invalidContractIDs.length > 0) {
      setErrorNotificationMessage(INCOMPLETE_FORM_NOTIFICATION(invalidContractIDs).messageHTML);
      setIsErrorNotificationActive(true);
      return false;
    }

    try {
      await WarrantyLinkAPI.submitWarrantylinkContracts(contractIds, validWarrantylinkContracts);
      setSuccessNotificationMessage(msgsWParams.WLK_SUBMIT_IDS_SUCCESS(contractIds).messageHTML);
      setIsSuccessNotificationActive(true);
      setCheckedContracts([]);
      return true;
    } catch (error) {
      console.error('failed to submit the order for wlk', error);
      setErrorNotificationMessage(msgs.WLK_SUBMIT_ERROR_DASHBOARD.message);
      setIsErrorNotificationActive(true);
      return false;
    }
  }

  const handleSubmit = async (contractSource: any[]) => {
    setIsLegalModalActive(false);
    const contractIds: string[] = checkedContracts;
    const success = await submitContracts(contractSource);

    if (success) {
      contractIds.length > 1
        ? fireGAEvent(DASHBOARD_BULK_SUBMIT_WARRANTY_LINK(contractIds.length))
        : fireGAEvent(DASHBOARD_SUBMIT_WARRANTY_LINK);
      setHasFetchedContractData(false);
    }
  };

  const handleCloseSuccessNotification = () => {
    setSuccessNotificationMessage('');
    setIsSuccessNotificationActive(false);
  };

  const handleCloseErrorNotification = () => {
    setErrorNotificationMessage('');
    setIsErrorNotificationActive(false);
  };

  const fetchContractData = async () => {
    const warrantyLinkEligibleOffices = getProfileWLKOffices(profile);
    const offices = warrantyLinkEligibleOffices.map((o) => o.id);
    const officeIds = offices.join(',');

    const request = {
      limit: PAGE_SIZE,
      offset: (activePage - 1) * PAGE_SIZE,
      agentId: profile.roleID,
    };

    if (officeIds !== '') request['officeId'] = officeIds;

    //get WLK contracts
    const wlkResponse = await WarrantylinkApi.searchWarrantyLinkContracts(request);
    wlkResponse.contracts = wlkResponse?.contracts?.filter((ctr) =>
      submittableStatuses.includes(ctr.warrantylink.status as WarrantylinkStatus),
    );

    //total WLKContractCount is the summation of all the contracts in SAVED or READY status
    const savedCount = wlkResponse?.meta?.totalStatus['SAVED'];
    const readyCount = wlkResponse?.meta?.totalStatus['READY'];
    let totalCount: number = 0;

    if (savedCount) {
      totalCount = totalCount + savedCount;
    }

    if (readyCount) {
      totalCount = totalCount + readyCount;
    }

    setTotalContracts(totalCount);
    props.onUpdateOrderCount(totalCount);

    //Get agent, dates and address details
    const wlkContractIds = wlkResponse?.contracts.map((c) => c.id);
    const contracts =
      wlkContractIds.length === 0
        ? []
        : await ContractApiSuppressErrors.getContractDetails(wlkContractIds, true, true);

    const profileDetailsMap = new Map();
    if (contracts && contracts?.length > 0) {
      contracts.forEach((contract, ind) =>
        profileDetailsMap.set(contract.detail.id, {
          address: {
            address1: contract?.summary.address?.address1,
            address2: contract?.summary.address?.address2,
            city: contract?.summary?.address?.city,
            state: contract?.summary?.address?.state,
            zip: contract?.summary?.address?.zip,
          },
          awaitingWLKSubmission: contract?.summary?.awaitingWlkSubmission,
          closingDate: contract?.detail?.importantDates?.closingDate,
          expirationDate: contract?.detail?.importantDates?.expirationDate,
          id: contract?.detail?.id,
          initiatingAgentID: contract?.detail?.initiatingOfficeAgent?.agent?.realEstateAgentID,
          initiatingAgentName:
            contract?.detail?.initiatingOfficeAgent?.agent?.firstName +
            ' ' +
            contract?.detail?.initiatingOfficeAgent?.agent?.lastName,
          initiatingOfficeID: contract?.detail?.initiatingOfficeAgent?.agent?.officeID,
          initiatingOfficeName: contract?.detail?.initiatingOfficeAgent?.office?.name,
          listingExpirationDate: contract?.detail?.dates?.find((cntr) => cntr.type == 'LISTCOVEFF')
            ?.effective,
          realEstateStatus: contract?.summary?.realEstateStatus,
          tableId: contract?.detail?.id,
        }),
      );
    }

    //Merge the 2 datasets on contractId
    const contractDataMap = new Map();
    wlkResponse.contracts?.forEach((contract) => {
      const profileDetails = contract?.id ? profileDetailsMap.get(contract.id) : null;
      if (profileDetails) {
        contractDataMap.set(contract.id, {
          ...profileDetails,
          warrantylink: contract.warrantylink,
        });
      }
    });
    const contractDataArr = Array.from(contractDataMap.values());
    setPageContractData(contractDataArr);

    setHasFetchedContractData(true);
  };

  const contractHeaderRenderer =
    (contract, advancedSearchText = '') =>
    (renderProps) => {
      const toggleAction = () => {
        setAccordionActiveIndex(renderProps.active ? [] : [renderProps.index]);
        setActiveContractId(contract.id === activeContractId ? '' : contract.id);
      };

      const handleSelectContract = (e) => {
        e.stopPropagation();
        if (!checkedContracts.includes(contract.id)) {
          setCheckedContracts([...checkedContracts, contract.id]);
        } else {
          setCheckedContracts(
            checkedContracts.filter((listContractId) => listContractId !== contract.id),
          );
        }
      };

      return (
        <>
          <div className="w-full flex">
            <PanelContractSummary
              contract={contract}
              idPrefix="order-missing-coe"
              showSubmissionDeadline={true}
              query={advancedSearchText}
              actions={[
                <>
                  <div className="flex flex-row w-full nowrap">
                    <IconButton
                      label=""
                      icon={renderProps.active ? <IconNavArrowUp /> : <IconNavArrowDown />}
                      variant="ghost"
                      size={props.isMobile ? 'small' : 'medium'}
                      onClick={toggleAction}
                    />
                  </div>
                </>,
              ]}
              leftActions={
                !props.canEditWLKContracts
                  ? []
                  : [
                      <Checkbox
                        key="action"
                        id={`wlk-checkbox-${contract.id}`}
                        checked={checkedContracts.includes(contract.id)}
                        onChange={(e) => handleSelectContract(e)}
                      />,
                    ]
              }
              onClickContract={toggleAction}
              showExpiryDate={false}
            />
          </div>
        </>
      );
    };

  /** orders changed to 'any' type due to how the orders are mapping on top of each other awkwardly. */
  const renderREOrderResults: ContractSearchBarHeaderContentRendererFunc = (
    orders: any,
    searchState,
  ) => (
    <Accordion
      mode="single"
      id="accordion-orders-wlk-verification"
      activeIndex={accordionActiveIndex}
      onActivate={(e, ais) => setAccordionActiveIndex(ais)}
      hideFirstSeparator={true}
      hideLastSeparator={true}
    >
      {orders.map((contract) => (
        <AccordionSection
          key={contract.id}
          className="w-full"
          headerRenderer={contractHeaderRenderer(contract, searchState?.searchTerm)}
          label={undefined}
          active={true}
          collapsible={true}
          hideSeparator={true}
        >
          <Panel
            border="1"
            borderColor="gray-300"
            rounded="sm"
            padding="none"
            shadow={true}
            className="rounded bg-white shadow-1 border-2 mb-4 -mt-4"
          >
            <AccordionItemWlkContract
              isActive={true}
              isDisabled={false}
              seller={contract.warrantylink?.form?.seller}
              buyer={contract.warrantylink?.form?.buyer}
              onSaveForm={handleSave}
              contract={contract}
              onSaveAndCancel={() => setAccordionActiveIndex([])}
              isMobile={props.isMobile}
              className="py-2 pl-6 pr-16 md:py-4 md:pl-12 md:pr-20 sm-max:p-6"
              shouldFetchWlkContract={!!searchState} // fetch only if coming from advanced search (when state is provided)
              canEditWLKContracts={props.canEditWLKContracts}
            />
          </Panel>
        </AccordionSection>
      ))}

      {
        verificationActions(
          {
            selectAll: 'drawer-orders-wlk-verification--footer--select-all',
            submit: 'drawer-orders-wlk-verification--footer--submit',
          },
          !!searchState,
        ) // hide the select all button if searchState is defined (advanced search only)
      }
    </Accordion>
  );

  /** the select all and submit button.  used in the header and the footer. */
  const verificationActions = (
    ids: { selectAll: string; submit: string },
    hideSelectAllCheckbox = false,
  ) => (
    <div className="w-full flex flex-row justify-between my-4">
      <Checkbox
        id={ids.selectAll}
        className={classNames(['md:ml-5', hideSelectAllCheckbox && 'invisible'])}
        label={selectAll ? 'Deselect All' : 'Select All'}
        checked={selectAll}
        onChange={(e) => handleSelectAll(e)}
      />

      <Button
        id={ids.submit}
        className="w-1/2 md:w-1/4"
        width="full"
        size="medium"
        label="submit"
        variant="filled"
        disabled={submitDisabled}
        onClick={() => setIsLegalModalActive(true)}
      />
    </div>
  );

  const setAdvancedSearchContractDataWithWLKData = async (contractData) => {
    if (contractData.length === 0) {
      setAdvancedSearchContractData(contractData);
      return;
    }

    const contractIds = contractData.map((contract) => contract.id);
    const request = { limit: contractIds.length, offset: 0, contractId: contractIds.join(',') };
    const wlkData = await WarrantylinkApi.searchWarrantyLinkContracts(request);

    const combinedContractData = contractData.map((contract) => {
      const combined = { ...contract };
      const wlkPart = wlkData.contracts.find((wlk) => wlk.id === contract.id.toString());
      combined.warrantylink = wlkPart?.warrantylink;
      return combined;
    });

    setAdvancedSearchContractData(combinedContractData);
  };

  return (
    <div className="full-height-card w-full">
      <DashboardActionSwitcher
        id="drawer-orders-wlk-verification"
        heading="Verify Orders for WarrantyLink"
        totalContractsCount={totalContracts}
        includeAdvancedSearch={true}
        searchType={searchType}
        onChangeSearchType={changeSearchType}
        contentRenderer={(searchType) => (
          <>
            {isSuccessNotificationActive && (
              <Notification
                id="drawer-orders-wlk-verification--success"
                inline={true}
                status="success"
                className="max-w-full my-4"
                showStatusLabel={false}
                showCloseButton={true}
                autoCloseDelay={5 * SECOND}
                onClose={handleCloseSuccessNotification}
              >
                {successNotificationMessage}
              </Notification>
            )}

            {isErrorNotificationActive && (
              <Notification
                id="drawer-orders-wlk-verification--error"
                inline={true}
                status="error"
                className="max-w-full my-4"
                showStatusLabel={false}
                showCloseButton={true}
                onClose={handleCloseErrorNotification}
              >
                {errorNotificationMessage}
              </Notification>
            )}

            {searchType === DashboardActionSearchType.ShowAll && (
              <>
                {props.canEditWLKContracts &&
                  verificationActions({
                    selectAll: 'drawer-orders-wlk-verification--header--select-all',
                    submit: 'drawer-orders-wlk-verification--header--submit',
                  })}

                {hasFetchedContractData ? (
                  renderREOrderResults(pageContractData)
                ) : (
                  <LoadingIndicator />
                )}
                <Pagination
                  onItemsPerPageChange={null}
                  itemsPerPage={ITEMS_PER_PAGE_ACTION}
                  itemsPerPageOptions={[{ value: '10', label: '10' }]}
                  onPageChange={(page) => setActivePage(page)}
                  totalItems={totalContracts}
                  page={activePage}
                  hideViewAll={true}
                />
              </>
            )}
            {searchType === DashboardActionSearchType.Advanced && (
              <DashboardActionAdvancedSearch
                subtitle="Search for an order number or address"
                unsuccessfulSearchErrorMessage={ADVANCED_SEARCH_NO_RESULTS_MSG}
                globalSearchRestrictions={[GlobalSearchRestrictions.AwaitingWLKSubmissionOnly]}
                searchedContractsRenderer={renderREOrderResults}
                onSearchTextChange={() => {
                  handleCloseSuccessNotification();
                  handleCloseErrorNotification();
                }}
                onSearchResultChange={setAdvancedSearchContractDataWithWLKData}
              />
            )}
          </>
        )}
      />

      {legalModalOffice && (
        <ModalLegalConfirmation
          isActive={isLegalModalActive}
          office={legalModalOffice.initiatingOfficeName}
          firstName={profile.firstName}
          lastName={profile.lastName}
          onClose={() => setIsLegalModalActive(false)}
          onSubmit={() =>
            handleSubmit(
              searchType === DashboardActionSearchType.ShowAll
                ? pageContractData
                : advancedSearchContractData,
            )
          }
        />
      )}
    </div>
  );
};

export default WLKVerification;
