import React, { useEffect, useState } from 'react';
import ZestyApi from '@apis/zesty.apis';
import { ZestyMarketingArticle } from '@apis/models/zesty.api.model';
import CardMarketingArticle from '@components/card/CardMarketingArticle';
import { getBrand } from '@helpers/brand.utils';
import { AHS_AND_HSA } from '@constants/dashBoard-constants';

interface ZestyMarketingArticlesProps {
  id: string;
}

const ZestyMarketingArticles: React.FC<ZestyMarketingArticlesProps> = (props) => {
  const [articles, setArticles] = useState<ZestyMarketingArticle[]>([]);

  /** on load, display load marketing article */
  useEffect(() => {
    ZestyApi.GetMarketingArticles().then((articles) => {
      const activeArticles = articles?.filter(
        (a) =>
          a.enable === '1' &&
          (a.brand.toUpperCase() === getBrand() || a.brand.toUpperCase() === AHS_AND_HSA),
      );
      setArticles(activeArticles);
    });
  }, []);

  return (
    <div className="space-y-4">
      {articles.map((article, idx) => (
        <CardMarketingArticle
          key={article.title}
          id={`${props.id}-${idx}`}
          image={article.image.data[0]?.url}
          title={article.title}
          category={article.category_header}
          content={article.text}
          link={article.link}
          linkText={article.link_text}
        />
      ))}
    </div>
  );
};

export default ZestyMarketingArticles;
