import React, { Component } from 'react';
import CardDashboardQuickLinks from '@components/card/CardDashboardQuickLinks';
import CardDashboardWhatsNew from '@components/card/CardDashboardWhatsNew';
import CardDashboardActionMissingCOE from '@components/card/CardDashboardActionMissingCOE';
import CardDashboardActionExpiring from '@components/card/CardDashboardActionExpiring';
import { ContractDateType, REOrderDataFilterCode, REOrderSearchRequest } from '@apis/models';
import RealEstateOrderApi from '@apis/realestateorder.api';
import CardDashboardActionAwaitingPayment, {
  AWAITING_PAYMENT_FILTERS,
} from '@components/card/CardDashboardActionAwaitingPayment';
import CardDashboardActionClosingSoon from '@components/card/CardDashboardActionClosingSoon';
import { RealEstateStatus } from '@constants/dashboardFilters';
import CardDashBoardActionWLKVerification from '@components/card/CardDashboardActionWLKVerification';
import ZestyMarketingArticles from '@components/zesty/ZestyMarketingArticles';
import { Separator } from '@ftdr/blueprint-components-react';
import { merge } from 'lodash';
import { DateFormat, formatDate, roundDownDate, roundUpDate } from '@helpers/utils';
import { CardDashBoardActionWLKTotalPaymentsCard } from '@components/card/CardDashboardActionWLKTotalPayments';
import { subDays, subYears } from 'date-fns/esm';
import WarrantylinkApi from '@apis/warrantylink.api';
import {
  getProfileWLKOffices,
  getWLKBrokerOffices,
  hasWarrantyLinkOffice,
} from '@services/helpers/profile.offices.helper';
import ProfileContext from '../../context/ProfileContext/index';
import CardDashboardActionMissingBuyerPhone from '@components/card/CardDashboardActionMissingBuyerPhone';
import { UserRoleType } from '@constants/dictionaries';
import CardDashboardActionUpForRenewal from '@components/card/CardDashboardActionUpForRenewal';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';

const LARGE_OFFICE_COUNT_THRESHOLD = 25;

interface WelcomeDashboardProps {
  isMobile: boolean;
}

interface WelcomeDashboardState {
  isQuickLinksCollapsedForMobile: false | boolean;
  expiringCount: number;
  missingCoeCount: number;
  awaitingPaymentCount: number;
  wlkVerificationCount: number;
  closingSoonCount: number;
  wlkPaymentsCount: number;
  hasWLKOffices: boolean;
  missingBuyerPhoneCount: number;
  upForRenewalCount: number;
}

export default class WelcomeDashboardTemplate extends Component<
  WelcomeDashboardProps,
  WelcomeDashboardState
> {
  static contextType = ProfileContext;

  isLargeOfficeCount = false;

  fetchContractCountTokenSource = RealEstateOrderApi.createNewCancelTokenSource();

  RealEstateOrderApiCustom = RealEstateOrderApi.new({
    suppressAllErrorNotifications: true,
    returnCancelErrorAsNull: false,
    cancelToken: this.fetchContractCountTokenSource.token,
  });
  WarrantylinkApiCustom = WarrantylinkApi.new({
    suppressAllErrorNotifications: true,
    returnCancelErrorAsNull: false,
    cancelToken: this.fetchContractCountTokenSource.token,
  });

  constructor(props) {
    super(props);

    this.state = {
      isQuickLinksCollapsedForMobile: true,
      expiringCount: null,
      missingCoeCount: null,
      awaitingPaymentCount: null,
      wlkVerificationCount: null,
      closingSoonCount: null,
      wlkPaymentsCount: null,
      missingBuyerPhoneCount: null,
      upForRenewalCount: null,
      hasWLKOffices: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { profile } = this.context;
    this.isLargeOfficeCount = profile.offices.length > LARGE_OFFICE_COUNT_THRESHOLD;

    const hasWLKOfficesForCount = hasWarrantyLinkOffice(profile);

    this.setState({ hasWLKOffices: hasWLKOfficesForCount });
  }

  componentWillUnmount() {
    this.fetchContractCountTokenSource?.cancel?.('unmounted');
  }

  componentDidMount() {
    if (!this.isLargeOfficeCount) {
      this.fetchAllCounts();
    }
  }

  fetchAllCounts() {
    this.fetchMissingCoeOrdersCount().catch((error) =>
      console.error('Error fetching missing coe order counts... ', error),
    );

    this.fetchExpiringOrdersCount().catch((error) =>
      console.error('Error fetching expired order counts... ', error),
    );

    this.fetchAwaitingPaymentOrdersCount().catch((error) =>
      console.error('Error fetching awaiting payment order counts... ', error),
    );

    this.fetchAwaitingWLKVerificationCount().catch((error) =>
      console.error('Error fetching awaiting wlk order counts... ', error),
    );

    this.fetchMissingBuyerPhoneCount().catch((error) =>
      console.log('Error fetching missing buyer phone counts... ', error),
    );

    this.fetchUpForRenewalCount().catch((error) =>
      console.log('Error fetching up for renewal counts... ', error),
    );

    this.fetchClosingSoonOrdersCount().catch((error) =>
      console.error('Error fetching closing soons order counts... ', error),
    );

    this.fetchWlkPaymentsCount().catch((error) =>
      console.error('Error fetching wlk payment counts... ', error),
    );
  }

  fetchMissingCoeOrdersCount = async () => {
    const request = new REOrderSearchRequest(1);
    request.data.order.statusListingMissingCOEOnly = true;
    const response = await this.RealEstateOrderApiCustom.searchProfileContracts(request);
    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      missingCoeCount: response.meta.totalMissingCOE,
    });
  };
  fetchExpiringOrdersCount = async () => {
    const request = new REOrderSearchRequest(1);
    request.data.order.realEstateStatus = [RealEstateStatus.EXPIRING, RealEstateStatus.EXPIRED];
    const response = await this.RealEstateOrderApiCustom.searchProfileContracts(request);
    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      expiringCount:
        (response.meta.totalStatus.expiring || 0) + (response.meta.totalStatus.expired || 0),
    });
  };

  fetchAwaitingPaymentOrdersCount = async () => {
    const request = new REOrderSearchRequest(1);
    request.data.order.realEstateStatus = AWAITING_PAYMENT_FILTERS;
    const response = await this.RealEstateOrderApiCustom.searchProfileContracts(request);
    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      awaitingPaymentCount: response.meta.total,
    });
  };

  fetchMissingBuyerPhoneCount = async () => {
    const response = await this.RealEstateOrderApiCustom.getProfileContractsMissingBuyerPhone(
      0,
      10,
    );
    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      missingBuyerPhoneCount: response.count,
    });
  };

  fetchUpForRenewalCount = async () => {
    const request = new REOrderSearchRequest(1);
    request.data.filters = [REOrderDataFilterCode.contractRenewal];
    const response = await this.RealEstateOrderApiCustom.searchProfileContracts(request);

    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      upForRenewalCount: response.meta.total,
    });
  };

  fetchAwaitingWLKVerificationCount = async () => {
    const { profile } = this.context;
    const hasWLKOfficesForCount = hasWarrantyLinkOffice(profile);

    this.setState({ hasWLKOffices: hasWLKOfficesForCount });

    if (!hasWLKOfficesForCount) {
      this.setState({ wlkVerificationCount: 0 });
      return;
    }

    // pick all the offices with warrantyLinkEligible = true
    const warrantyLinkEligibleOffices = getProfileWLKOffices(profile);
    const offices = warrantyLinkEligibleOffices?.map((o) => o.id);
    const officeIds = offices.join(',');

    const params: { offset: number; officeId: string; limit: number; agentId?: string } = {
      officeId: officeIds,
      offset: 0,
      limit: 1,
    };

    // add the agent ID to the request only if user is an RE agent or CC agent
    if (
      profile.roleIDType === UserRoleType.RealEstateAgent ||
      profile.roleIDType === UserRoleType.ClosingCompanyAgent
    ) {
      params.agentId = profile.roleID;
    }

    if (!params['officeId']) {
      this.setState({
        wlkVerificationCount: 0,
      });
      return;
    }

    const response = await WarrantylinkApi.searchWarrantyLinkContracts(params);

    // total WLKContractCount is the summation of all the contracts in SAVED or READY status
    const savedCount = response?.meta?.totalStatus['SAVED'];
    const readyCount = response?.meta?.totalStatus['READY'];
    let totalCount: number = 0;

    if (savedCount) {
      totalCount += savedCount;
    }

    if (readyCount) {
      totalCount += readyCount;
    }

    this.setState({
      wlkVerificationCount: totalCount,
    });
  };

  fetchClosingSoonOrdersCount = async () => {
    let request = new REOrderSearchRequest(1);

    const startDate = formatDate(new Date(), DateFormat.ISO8601);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);

    request = merge(request, {
      data: {
        order: { realEstateStatus: [RealEstateStatus.CLOSING_SOON] },
        date: {
          startDate,
          endDate: formatDate(endDate, DateFormat.ISO8601),
          dateType: ContractDateType.EstimatedClosingOfEscrow,
        },
      },
    });

    const response = await this.RealEstateOrderApiCustom.searchProfileContracts(request);
    if (!response) {
      throw new Error('response was null');
    }

    this.setState({
      closingSoonCount: response.meta.total,
    });
  };

  fetchWlkPaymentsCount = async () => {
    const startDate = formatDate(
      subDays(subYears(roundDownDate(new Date()), 1), 1),
      DateFormat.ISO8601,
    );
    const endDate = formatDate(roundUpDate(new Date()), DateFormat.ISO8601);

    const { profile } = this.context;
    const hasWLKOffices = getWLKBrokerOffices(profile).length > 0;

    /** Block API call if the user does not have warranty link offices */
    if (hasWLKOffices) {
      const response = await this.WarrantylinkApiCustom.getWarrantyLinkPaymentsByDate(
        startDate,
        endDate,
      );
      if (!response) {
        throw new Error('response was null');
      }

      this.setState({
        wlkPaymentsCount: parseFloat(response.totalPaymentAmount),
      });
    }
  };

  updateWLKVerificationCount = (count) => {
    this.setState({ wlkVerificationCount: count });
  };

  updateExpiringCount = (count) => {
    this.setState({ expiringCount: count });
  };

  updateMissingBuyerPhoneCount = (count) => {
    this.setState({ missingBuyerPhoneCount: count });
  };

  updateUpForRenewalCount = (count) => {
    this.setState({ upForRenewalCount: count });
  };

  updateMissingCoeCount = (count) => {
    this.setState({ missingCoeCount: count });
  };

  updateAwaitingPaymentCount = (count) => {
    this.setState({ awaitingPaymentCount: count });
  };

  isUpForRenewalVisible = () => {
    const { profile } = this.context;
    return ['RealEstateAgent', 'RealEstateAdmin', 'Broker'].includes(profile.roleIDType);
  };

  renderDashboardCards = () => (
    <>
      <CardDashboardActionAwaitingPayment
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchAwaitingPaymentOrdersCount}
        count={this.state.awaitingPaymentCount}
        updateCount={this.updateAwaitingPaymentCount}
      />
      <CardDashboardActionClosingSoon
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchClosingSoonOrdersCount}
        count={this.state.closingSoonCount}
      />
      <CardDashboardActionMissingCOE
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchMissingCoeOrdersCount}
        count={this.state.missingCoeCount}
        updateCount={this.updateMissingCoeCount}
      />
      <CardDashboardActionMissingBuyerPhone
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchMissingBuyerPhoneCount}
        count={this.state.missingBuyerPhoneCount}
        updateCount={this.updateMissingBuyerPhoneCount}
      />
      {IsFeatureEnabled(Features.RenewalEmails) && getBrand() !== Brand.HSA && (
        <CardDashboardActionUpForRenewal
          hide={!this.isUpForRenewalVisible()}
          isManualFetch={this.isLargeOfficeCount}
          fetchCount={this.fetchUpForRenewalCount}
          count={this.state.upForRenewalCount}
          updateCount={this.updateUpForRenewalCount}
        />
      )}
      <CardDashBoardActionWLKVerification
        hide={!this.state.hasWLKOffices}
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchAwaitingWLKVerificationCount}
        count={this.state.wlkVerificationCount}
        updateCount={this.updateWLKVerificationCount}
      />
      <CardDashboardActionExpiring
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchExpiringOrdersCount}
        count={this.state.expiringCount}
        updateCount={this.updateExpiringCount}
      />
      <CardDashBoardActionWLKTotalPaymentsCard
        isManualFetch={this.isLargeOfficeCount}
        fetchCount={this.fetchWlkPaymentsCount}
        count={this.state.wlkPaymentsCount}
      />
      {/* This code is to fill the horizontal space if there aren't enough cards to fill a single row.
            Otherwise, if there are 1-2 cards on the next row, it will fill all the available width. */}
      {Array.from(Array(2).keys()).map((n) => (
        <div className="dashboard-card-small-grow-fixer" key={n} />
      ))}
    </>
  );

  render() {
    return (
      <>
        {!this.props.isMobile && (
          <div className="flex flex-row gap-6" style={{ marginTop: '4rem' }}>
            <div className="w-full flex flex-col">
              <div className="w-full flex flex-row flex-wrap gap-6 dashboard-card-container">
                {this.renderDashboardCards()}
              </div>
              <Separator orientation="horizontal" gap="xl" />
              <div className="w-full">
                <ZestyMarketingArticles id="dashboard-marketing-article" />
              </div>
            </div>
            <div>
              <div className="sticky-container-top flex flex-col gap-6">
                <CardDashboardQuickLinks canCollapse={false} />
                <CardDashboardWhatsNew />
              </div>
            </div>
          </div>
        )}
        {this.props.isMobile && (
          <>
            <div className="sticky-container-top">
              <CardDashboardQuickLinks
                canCollapse={true}
                isCollapsed={this.state.isQuickLinksCollapsedForMobile}
                setCollapsed={(state) => this.setState({ isQuickLinksCollapsedForMobile: state })}
              />
            </div>
            <div className="flex flex-row justify-center">
              <div
                className="flex flex-row flex-wrap justify-between w-full pt-6"
                style={{ gap: '1.5rem' }}
              >
                <CardDashboardWhatsNew />
                {this.renderDashboardCards()}
              </div>
            </div>
            <div>
              <Separator orientation="horizontal" gap="xl" />
              <div className="w-full">
                <ZestyMarketingArticles id="dashboard-marketing-article" />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
