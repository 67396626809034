import React from 'react';
import Modal from '@components/modal/Modal';
import { Button, Text } from '@ftdr/blueprint-components-react';
import { Address } from '@app/models';
import AddressDisplay from '@components/content/AddressDisplay';

interface Props {
  isActive: boolean;
  id?: string;
  heading?: string;
  message?: string;
  denyButtonLabel?: string;
  confirmButtonLabel?: string;
  onConfirm: () => void;
  onClose?: () => void;
  orderAddress: Address;
}

const ModalDeleteOrder: React.FC<Props> = (props) => {
  const actions = [
    <Button
      key="submit"
      id={`${props.id}__btn-confirm`}
      label={props.confirmButtonLabel}
      onClick={() => props.onConfirm()}
      width="full"
      labelAlign="center"
      size="medium"
    />,
    <Button
      key="cancel"
      id={`${props.id}__btn-deny`}
      variant="ghost"
      label={props.denyButtonLabel}
      onClick={() => props.onClose()}
      width="full"
      labelAlign="center"
      size="medium"
    />,
  ];

  return (
    <Modal
      id={props.id}
      isActive={props.isActive}
      onClose={props.onClose}
      heading={props.heading}
      actions={actions}
    >
      {props.isActive && (
        <div className="space-y-4">
          <Text>{props.message}</Text>
          <Text id={`${props.id}__order-address`}>
            <span className="font-bold">Property Address: </span>
            <AddressDisplay address={props.orderAddress} singleLine={true} />
          </Text>
        </div>
      )}
    </Modal>
  );
};

ModalDeleteOrder.defaultProps = {
  id: 'modal-delete-order',
  heading: 'Delete Order',
  message: 'Are you sure you want to delete this order? You cannot undo this action.',
  denyButtonLabel: 'Close',
  confirmButtonLabel: 'Delete Order',
  onConfirm: () => {
    console.warn('Not implemented');
  },
  onClose: () => {
    console.warn('Not implemented');
  },
};

export default ModalDeleteOrder;
