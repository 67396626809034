import React from 'react';
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import CancelOrder, { CancelOrderProps } from '@components/drawer/subcomponents/CancelOrder';
import { msgsWParams } from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';

type Props = DrawerProps & Omit<CancelOrderProps, ''>;

const DrawerCancelOrder: React.FC<Props> = ({
  contract,
  onOrderCancelled,
  onBackToSearch,
  ...props
}) => {
  const { addSuccessToQueue, addWarningToQueue } = useGlobalAlert();

  const onSuccess = (contractId: string) => {
    addSuccessToQueue(msgsWParams.CANCEL_ORDER_SUCCESS(contractId));
    onOrderCancelled(contractId);
  };

  const onFailure = (contractId: string) => {
    addWarningToQueue(msgsWParams.CANCEL_ORDER_FAILED(contractId));
  };

  return (
    <>
      <Drawer {...props}>
        {props.isActive && (
          <CancelOrder
            heading="Cancel Order"
            contract={contract}
            onOrderCancelled={onSuccess}
            onOrderCancelFailure={onFailure}
            onBackToSearch={onBackToSearch}
          />
        )}
      </Drawer>
    </>
  );
};

export default DrawerCancelOrder;
