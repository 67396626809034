import { useNavigation } from 'react-navi';
import { Button } from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import React from 'react';
import Modal from './Modal';
import NewOrderAccountFailure from '@components/modal/subcomponents/NewOrderAccountFailure';

const ModalNewOrderAccountFailure = (props) => {
  const navigation = useNavigation();

  const actions = [
    <Button
      key="submit"
      size="medium"
      label="Contact Us"
      onClick={() => navigation.navigate(Path.ContactUs)}
    />,
  ];

  return (
    <Modal
      isActive={props.isActive}
      onClose={props.onClose}
      heading="Unable to Create New Order"
      actions={actions}
    >
      {props.isActive && <NewOrderAccountFailure />}
    </Modal>
  );
};

export default ModalNewOrderAccountFailure;
