import React, { useState } from 'react';
import { RealEstateStatus } from '@constants/dashboardFilters';
import QuickLinkSearch from '@components/drawer/subcomponents/QuickLinkSearch';
import { Contract, ContractSummary } from '@apis/models';
import ContractApi from '@apis/contract.api';
import CancelOrder from '@components/drawer/subcomponents/CancelOrder';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { msgsWParams } from '@app/locales/en';

export interface QuickLinkCancelOrderProps {
  onClose: () => void;
}

const QuickLinkCancelOrder: React.FC<QuickLinkCancelOrderProps> = (props) => {
  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();
  const [cancelOrderActive, setCancelOrderActive] = useState(false);
  const [contract, setContract] = useState<Contract>(null);

  const handleClickContract = (selectedContract: ContractSummary) => {
    if (selectedContract) {
      // Contract needs to be fetched every time even if we already have one.
      // We need to retain state for the last contract searched when "back to search results" is clicked.
      ContractApi.getContractDetails([selectedContract.id], true, true)
        .then((fetchedContracts) => {
          const fetchedContract = fetchedContracts[0];

          if (fetchedContract) {
            setContract(fetchedContract);
            setCancelOrderActive(true);
          }
        })
        .catch((e) => {
          console.error('Error fetching contract details...', e);
        });
    }
  };

  const handleBackToSearchResults = () => {
    setCancelOrderActive(false);
    setContract(undefined);
  };

  const onOrderCancelled = (contractId: string) => {
    addSuccessToQueue(msgsWParams.CANCEL_ORDER_SUCCESS(contractId));
    setCancelOrderActive(false);
    setContract(undefined);
    props.onClose(); // close the drawer entirely on successful cancellation
  };

  const onOrderCancelFailure = (contractId: string) => {
    addErrorToQueue(msgsWParams.CANCEL_ORDER_ERROR(contractId));
  };

  return (
    <>
      <div className={cancelOrderActive ? 'hidden' : null}>
        <QuickLinkSearch
          title="Cancel an Order"
          subTitle="Search for an order number or address"
          unsuccessfulSearchErrorMessage="Order Not Found. Please try again, considering spelling and order status."
          includedStatuses={[
            RealEstateStatus.OPEN_ORDER,
            RealEstateStatus.CLOSING_SOON,
            RealEstateStatus.PAYMENT_DUE,
            RealEstateStatus.EXPIRED,
            RealEstateStatus.EXPIRING,
            RealEstateStatus.CLOSED,
            RealEstateStatus.CLOSING_LATER,
            RealEstateStatus.PAYMENT_PAST_DUE,
          ]}
          idPrefix="qlCancelOrder"
          onClickContract={handleClickContract}
        />
      </div>
      {cancelOrderActive && (
        <div>
          <CancelOrder
            contract={contract}
            onOrderCancelled={onOrderCancelled}
            onOrderCancelFailure={onOrderCancelFailure}
            onBackToSearch={handleBackToSearchResults}
          />
        </div>
      )}
    </>
  );
};

export default QuickLinkCancelOrder;
