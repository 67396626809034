import React from 'react';
import { UserDetails } from '@app/models/profile.model';
import { Panel } from '@ftdr/blueprint-components-react';

interface props {
  user?: UserDetails;
  onClick?: any;
}

const CardUserDetails: React.FC<props> = ({ user = {}, onClick }) => {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <Panel>
        <div className="text-primary-400 hover:text-primary-300 font-bold md:text-lg leading-tight flex justify-between items-center">
          {user.firstName} {user.lastName}
        </div>

        <p className="md:mb-1">
          Email: {user.email}
          <br />
          Web ID: {user.faEmail}
          <br />
          UserType: {user.userType}
        </p>

        <p id="offices" className="mb-1">
          Offices:
          <table className="w-full border border-gray-500 p-2 mb-2 mt-2 text-left">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-500 p-1">Office</th>
                <th className="border border-gray-500 p-1">Warranty Link Broker</th>
              </tr>
            </thead>
            <tbody>
              {user.offices &&
                user.offices.map((office) => (
                  <tr key={office.id}>
                    <td className="border border-gray-500 p-1">
                      {office.type} {office.id}
                    </td>
                    <td className="border border-gray-500 p-1">
                      {office.userExtensions?.length > 0 ? 'True' : 'False'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </p>

        <p className="mb-1">
          Registration Date: {user.registrationDate} <br />
          Brand: {user.brand} <br />
          Last Active: {user.lastActiveDate}
          <br />
        </p>
      </Panel>
    </div>
  );
};

export default CardUserDetails;
