import React from 'react';
import Drawer from '@components/drawer/Drawer';
import OrdersSendRenewal from '@components/drawer/subcomponents/OrdersSendRenewal';

interface DrawerOrdersSendRenewalProps {
  isActive: boolean;
  onUpdateOrderCount: any;
  onClose: any;
  initialTotalContracts: number;
  onRenewal?: any;
}

const DrawerOrdersSendRenewal = (props: DrawerOrdersSendRenewalProps) => {
  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <OrdersSendRenewal
          onUpdateOrderCount={props.onUpdateOrderCount}
          initialTotalContracts={props.initialTotalContracts}
          onRenewal={props.onRenewal}
        />
      )}
    </Drawer>
  );
};

export default DrawerOrdersSendRenewal;
