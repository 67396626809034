import React from 'react';
import { useThemeContext } from '@ThemeContext';
import { classNames } from '@utils';
import Tippy from '@tippy.js/react';

/** @deprecated: old SF usage. stop using it */
const TooltipAutocomplete = ({
  results,
  onClickItem = (id) => {},
  disableClick = false,
  ...props
}) => {
  const theme = useThemeContext();

  function onClickItemInternal(id) {
    if (!disableClick) {
      onClickItem(id);
    }
  }

  return (
    // Full list of options
    // https://atomiks.github.io/tippyjs/all-options/
    <>
      {results && (
        <Tippy
          theme="autocomplete"
          arrow={false}
          animation="fade"
          duration={0}
          distance={0}
          interactive={true}
          trigger="focus click"
          placement="bottom-start"
          content={
            <ul>
              {Object.keys(results).map((heading, idx) => (
                <div
                  key={idx}
                  className={classNames([!disableClick && 'auto-complete-item'])}
                  onClick={() => {
                    onClickItemInternal(heading);
                  }}
                >
                  <li className={classNames(['pt-2 pb-1', idx !== 0 && 'mt-2 pt-2 border-t'])}>
                    <div
                      className={classNames([
                        'uppercase font-bold text-xxs tracking-wide underline',
                        theme === 'hsa' ? 'text-primary-400' : 'text-secondary-400',
                      ])}
                    >
                      {heading}
                    </div>
                  </li>

                  {results[heading].map((item) => (
                    <li key={item}>
                      <div
                        className={classNames([
                          'block w-full text-left py-1',
                          theme === 'hsa' ? 'hover:text-primary-400' : 'hover:text-secondary-400',
                        ])}
                        dangerouslySetInnerHTML={{
                          __html: item.replace('John', '<strong>John</strong>'),
                        }}
                      />
                    </li>
                  ))}

                  <li className="pt-1 uppercase text-gray-600 text-xxs tracking-wide" />
                </div>
              ))}
            </ul>
          }
          {...props}
        />
      )}
    </>
  );
};

export const TooltipAutocompleteOrdered = ({
  results,
  onClickItem = (id) => {},
  disableClick = false,
  ...props
}) => {
  const theme = useThemeContext();

  function onClickItemInternal(id) {
    if (!disableClick) {
      onClickItem(id);
    }
  }

  function getContent() {
    const content = [];
    let i = 0;

    results.forEach((details, heading) => {
      const row = (
        <div
          key={i}
          className={classNames([!disableClick && 'auto-complete-item'])}
          onClick={() => {
            onClickItemInternal(heading?.toString());
          }}
        >
          <li className={classNames(['pt-2 pb-1', i !== 0 && 'mt-2 pt-2 border-t'])}>
            <div
              className={classNames([
                'uppercase font-bold text-xxs tracking-wide underline',
                theme === 'hsa' ? 'text-primary-400' : 'text-secondary-400',
              ])}
            >
              {heading}
            </div>
          </li>

          {details.map((item) => (
            <li key={item}>
              <div
                className={classNames([
                  'block w-full text-left py-1',
                  theme === 'hsa' ? 'hover:text-primary-400' : 'hover:text-secondary-400',
                ])}
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            </li>
          ))}

          <li className="pt-1 uppercase text-gray-600 text-xxs tracking-wide" />
        </div>
      );

      content.push(row);
      i += 1;
    });

    return content;
  }

  return (
    // Full list of options
    // https://atomiks.github.io/tippyjs/all-options/
    <>
      {results && (
        <Tippy
          theme="autocomplete"
          arrow={false}
          animation="fade"
          duration={0}
          distance={0}
          interactive={true}
          trigger="focus click"
          placement="bottom-start"
          content={<ul>{getContent()}</ul>}
          {...props}
        />
      )}
    </>
  );
};

export default TooltipAutocomplete;
