import React, { useEffect, useState } from 'react';
import { Notification, Link, Text } from '@ftdr/blueprint-components-react';

export const NotificationDismissalKeys = {
  WELCOME_DASHBOARD_BOTTOM_NOTIFICATION_DISMISSED_KEY:
    'new-dashboard-bottom-notification-dismissed',
  WELCOME_DASHBOARD_TOP_NOTIFICATION_DISMISSED_KEY: 'new-dashboard-top-notification-dismissed',
};

const NotificationInfo = (props) => {
  const [isNotificationDismissed, setIsNotificationDismissed] = useState(false);

  const dismissNotification = () => {
    localStorage.setItem(props.dismissalKey, 'true');
    setIsNotificationDismissed(true);
  };

  useEffect(() => {
    const isDismissalStored = localStorage.getItem(props.dismissalKey) === 'true';
    setIsNotificationDismissed(isDismissalStored);
  }, []);

  return (
    <>
      {isNotificationDismissed || !props.showNotification ? null : (
        <div className={props.className || 'mb-8'}>
          <Notification
            variant="floating"
            status="info"
            inline={true}
            showStatusLabel={false}
            className="flex max-w-full flex-col"
            onClose={dismissNotification}
          >
            <div className="flex flex-col md:flex-row items-center">
              {props.notificationContent && (
                <Text
                  variant="body-long"
                  className="zesty-html"
                  dangerouslySetInnerHTML={{ __html: props.notificationContent }}
                />
              )}
              {props.notificationData?.notification_url && (
                <Link
                  bold={true}
                  color="interactive"
                  href={props.notificationData?.notification_url}
                >
                  {props.notificationData?.link_text}
                </Link>
              )}
            </div>
          </Notification>
        </div>
      )}
    </>
  );
};

export default NotificationInfo;
