import React from 'react';
import Drawer from '@components/drawer/Drawer';
import ActionExpired from '@components/drawer/subcomponents/ActionExpired';
import { isMobileView } from '@utils';

interface IProps {
  isActive: boolean;
  initialContractTotal: number;
  onClose: any;
  updateTotalContracts: any;
}

const DrawerActionExpired = (props: IProps) => {
  const isMobile = isMobileView();

  return (
    <Drawer isActive={props.isActive} onClose={props.onClose}>
      {props.isActive && (
        <ActionExpired
          updateTotalContracts={props.updateTotalContracts}
          isMobile={isMobile}
          initialContractTotal={props.initialContractTotal}
        />
      )}
    </Drawer>
  );
};

export default DrawerActionExpired;
