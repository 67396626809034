import React from 'react';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import ZestyApi from '@apis/zesty.apis';
import FAQAuth from '@pages/FAQ/FAQAuth';
import FAQNoAuth from '@pages/FAQ/FAQNoAuth';

interface state {
  FAQs: FAQ[];
  FAQCategories: FAQCategory[];
}

interface props {
  isAuthed?: boolean;
}

export default class FAQPage extends React.Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      FAQs: [],
      FAQCategories: [],
    };
  }

  componentDidMount(): void {
    ZestyApi.GetFAQs().then((response) => {
      this.setState({ FAQs: response });
    });

    ZestyApi.GetFAQCategories().then((response) => {
      this.setState({ FAQCategories: response });
    });
  }

  render() {
    return this.props.isAuthed ? (
      <FAQAuth FAQs={this.state.FAQs} FAQCategories={this.state.FAQCategories} />
    ) : (
      <FAQNoAuth FAQs={this.state.FAQs} FAQCategories={this.state.FAQCategories} />
    );
  }
}
