import * as c from '@constants/formField-constants';
import { cleanPhone, deleteUndefinedValues, getEmailDomain } from '@helpers/utils';
import { every, isEmpty, values } from 'lodash';
import { UserRoleType } from '@constants/dictionaries';
import ProfileModel from '@app/models/profile.model';
import { isREAgent } from '@helpers/profile.utils';

/**
 * a utility function in order to grab the value used by isTouched/values
 * and making it not case-sentitive or hyphenated.
 * TODO: make all of these validation rules more type-safe and clean up name modifiers (i.e. accountType/account-type/AccountType)
 * @param obj  isTouched or values
 * @param name the key to search for. Will check variations
 */
function get(obj: any, name: string): any {
  // Complete match, just return directly
  if (obj[name]) {
    return obj[name];
  }

  // Doing some strange magic. Not type-safe at all, but to use for now. Refactor logic later.
  const transformedNames: string[] = [
    name[0].toUpperCase() + name.slice(1), // Uppercase the first char
    name[0].toLowerCase() + name.slice(1), // Lowercase the first char
    name.replace(/(_\w)/g, (m) => m[1].toUpperCase()), // camel to kebab case
  ];

  for (const tName of transformedNames) {
    if (obj[tName]) {
      return obj[tName];
    }
  }
}
export const validateName = (inputName: string): boolean => {
  const re = new RegExp("[^ A-Za-z0-9_+-.']"); // Regex for non alphanumeric characters(special characters)
  if (re.test(inputName)) {
    return true;
  }
  return false;
};

interface SellerFormErrors {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  coCustomerEmail: string;
  coCustomerFirstName: string;
  coCustomerLastName: string;
  coCustomerPhone: string;
  coCustomerZipCode: string;
  coCustomerStreetAddress: string;
  coCustomerCity: string;
  coCustomerState: string;
  sellerInfo: string;
  cooperatingAgent: string;
  isCustomCooperatingAgentFlow: boolean;
}

interface BuyerFormErrors {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  coCustomerEmail: string;
  coCustomerFirstName: string;
  coCustomerLastName: string;
  coCustomerPhone: string;
  coCustomerZipCode: string;
  coCustomerStreetAddress: string;
  coCustomerCity: string;
  coCustomerState: string;
  buyerInfo: string;
  cooperatingAgent: string;
  isCustomCooperatingAgentFlow: boolean;
}

export const validateBuyerForm =
  (
    isDoYouHaveABuyerQuestionExcluded: boolean,
    isCustomerContactInfoRequired: boolean,
    profile: ProfileModel,
    initiatingAgent: { email: string; phone: string },
    isRealtorOwned: boolean,
  ) =>
  (values, isTouched): Partial<BuyerFormErrors> => {
    // apply certain rules for admin and agent users only
    let disallowedEmails: string[] = [];
    let disallowedPhones: string[] = [];
    let userEmailMatchError: string, userPhoneMatchError: string;
    if (!isRealtorOwned) {
      // excluded for RO orders
      disallowedEmails = [profile?.email, initiatingAgent?.email].filter((v) => !!v);
      disallowedPhones = [profile?.phoneNumber, initiatingAgent?.phone].filter((v) => !!v);
      userEmailMatchError = isREAgent(profile)
        ? c.BUYER_SELF_EMAIL_MATCHING
        : c.BUYER_SELF_OR_AGENT_EMAIL_MATCHING;
      userPhoneMatchError = isREAgent(profile)
        ? c.BUYER_SELF_PHONE_MATCHING
        : c.BUYER_SELF_OR_AGENT_PHONE_MATCHING;
    }

    let errors: Partial<BuyerFormErrors> = {};

    if (!isTouched) return errors;

    if (isTouched.phone && values.phone) {
      if (!isPhoneNumberValid(values.phone)) {
        errors.phone = c.INVALID_PHONE_NUMBER;
      }
      if (disallowedPhones.some((v) => v && cleanPhone(values.phone) === cleanPhone(v))) {
        errors.phone = userPhoneMatchError;
      }
    }

    // checks for the value of email and then if provided checks for the email-format
    if (isTouched.email && values.email) {
      if (!isEmailValid(values.email)) {
        errors.email = c.INVALID_EMAIL;
      }
      if (disallowedEmails.some((v) => v && values.email.toUpperCase() === v.toUpperCase())) {
        errors.email = userEmailMatchError;
      }
    }

    if (isCustomerContactInfoRequired && (isTouched.phone || isTouched.email)) {
      if (!values.phone && !values.email) {
        errors.phone = c.PHONE_OR_EMAIL_REQUIRED;
      }
    }

    // checks for the value of co-customer's email and then if provided checks for the email-format
    if (isTouched.coCustomerEmail && values.coCustomerEmail) {
      if (values.coCustomerEmail && !isEmailValid(values.coCustomerEmail)) {
        errors.coCustomerEmail = c.INVALID_EMAIL;
      }
    }

    if (isTouched.coCustomerPhone && values.coCustomerPhone) {
      if (!isPhoneNumberValid(values.coCustomerPhone)) {
        errors.coCustomerPhone = c.INVALID_PHONE_NUMBER;
      }
    }

    // checks for the value of street address and then if provided checks for the street address requirements
    if (values.alternateAddressFields || isTouched.streetAddress) {
      const streetAddressError = validateStreetAddress(
        values.streetAddress,
        values.alternateAddressFields,
      );
      if (streetAddressError) {
        errors.streetAddress = streetAddressError;
      }
    }

    // checks for the value of city and then if provided checks for the city requirements
    if (values.alternateAddressFields || isTouched.city) {
      if (!values.city) {
        errors.city = c.CITY_REQUIRED;
      }
    }

    // checks for the value of state and then if provided checks for the state requirements
    if (values.alternateAddressFields || isTouched.state) {
      if (!values.state) {
        errors.state = c.STATE_REQUIRED;
      }
    }

    if (values.alternateAddressFields || isTouched.zipCode) {
      errors.zipCode = validateZipCode(values.zipCode);
    }

    // checks for the value of street address and then if provided checks for the street address requirements
    if (values.coBuyerAlternateAddressFields || isTouched.coCustomerStreetAddress) {
      const streetAddressError = validateStreetAddress(
        values.coCustomerStreetAddress,
        values.coBuyerAlternateAddressFields,
      );
      if (streetAddressError) {
        errors.coCustomerStreetAddress = streetAddressError;
      }
    }

    // checks for the value of city and then if provided checks for the city requirements
    if (values.coBuyerAlternateAddressFields || isTouched.coCustomerCity) {
      if (!values.coCustomerCity) {
        errors.coCustomerCity = c.CITY_REQUIRED;
      }
    }

    // checks for the value of state and then if provided checks for the state requirements
    if (values.coBuyerAlternateAddressFields || isTouched.coCustomerState) {
      if (!values.coCustomerState) {
        errors.coCustomerState = c.STATE_REQUIRED;
      }
    }

    if (values.coBuyerAlternateAddressFields || isTouched.coCustomerZipCode) {
      errors.coCustomerZipCode = validateZipCode(values.coCustomerZipCode);
    }

    // If no selection made, error
    if (!isDoYouHaveABuyerQuestionExcluded && isTouched.buyerInfo) {
      if (!values.buyerInfo || (!values.buyerInfo.yes && !values.buyerInfo.no)) {
        errors.buyerInfo = c.BUYER_RADIO_REQUIRED;
      }
    }

    if (isTouched.cooperatingOffice) {
      if (
        values.cooperatingOffice &&
        !values.cooperatingAgent &&
        !values.isCustomCooperatingAgentFlow
      ) {
        errors.cooperatingAgent = c.COOPERATING_AGENT_REQUIRED;
      }
    }

    errors = deleteUndefinedValues(errors, true);

    return errors;
  };

export const validateSellerForm =
  (
    isDoYouHaveASellerQuestionExcluded: boolean,
    isCustomerContactInfoRequired: boolean,
    profile: ProfileModel,
    initiatingAgent: { email: string; phone: string },
  ) =>
  (values, isTouched): Partial<SellerFormErrors> => {
    // apply certain rules for admin and agent users only
    let disallowedEmails: string[] = [];
    let disallowedPhones: string[] = [];
    let userEmailMatchError: string, userPhoneMatchError: string;
    {
      disallowedEmails = [profile?.email, initiatingAgent?.email].filter((v) => !!v);
      disallowedPhones = [profile?.phoneNumber, initiatingAgent?.phone].filter((v) => !!v);
      userEmailMatchError = isREAgent(profile)
        ? c.SELLER_SELF_EMAIL_MATCHING
        : c.SELLER_SELF_OR_AGENT_EMAIL_MATCHING;
      userPhoneMatchError = isREAgent(profile)
        ? c.SELLER_SELF_PHONE_MATCHING
        : c.SELLER_SELF_OR_AGENT_PHONE_MATCHING;
    }

    let errors: Partial<SellerFormErrors> = {};

    if (!isTouched) return errors;

    if (isTouched.phone && values.phone) {
      if (!isPhoneNumberValid(values.phone)) {
        errors.phone = c.INVALID_PHONE_NUMBER;
      }
      if (disallowedPhones.some((v) => v && cleanPhone(values.phone) === cleanPhone(v))) {
        errors.phone = userPhoneMatchError;
      }
    }

    // checks for the value of email and then if provided checks for the email-format
    if (isTouched.email && values.email) {
      if (!isEmailValid(values.email)) {
        errors.email = c.INVALID_EMAIL;
      }
      if (disallowedEmails.some((v) => v && values.email.toUpperCase() === v.toUpperCase())) {
        errors.email = userEmailMatchError;
      }
    }

    if (isCustomerContactInfoRequired && (isTouched.phone || isTouched.email)) {
      if (!values.phone && !values.email) {
        errors.phone = c.PHONE_OR_EMAIL_REQUIRED;
      }
    }

    // checks for the value of co-customer's email and then if provided checks for the email-format
    if (isTouched.coCustomerEmail && values.coCustomerEmail) {
      if (values.coCustomerEmail && !isEmailValid(values.coCustomerEmail)) {
        errors.coCustomerEmail = c.INVALID_EMAIL;
      }
    }

    if (isTouched.coCustomerPhone && values.coCustomerPhone) {
      if (!isPhoneNumberValid(values.coCustomerPhone)) {
        errors.coCustomerPhone = c.INVALID_PHONE_NUMBER;
      }
    }

    // checks for the value of street address and then if provided checks for the street address requirements
    if (values.alternateAddressFields || isTouched.streetAddress) {
      const streetAddressError = validateStreetAddress(
        values.streetAddress,
        values.alternateAddressFields,
      );
      if (streetAddressError) {
        errors.streetAddress = streetAddressError;
      }
    }

    // checks for the value of city and then if provided checks for the city requirements
    if (values.alternateAddressFields || isTouched.city) {
      if (!values.city) {
        errors.city = c.CITY_REQUIRED;
      }
    }

    // checks for the value of state and then if provided checks for the state requirements
    if (values.alternateAddressFields || isTouched.state) {
      if (!values.state) {
        errors.state = c.STATE_REQUIRED;
      }
    }

    if (values.alternateAddressFields || isTouched.zipCode) {
      errors.zipCode = validateZipCode(values.zipCode);
    }

    // checks for the value of street address and then if provided checks for the street address requirements
    if (values.coSellerAlternateAddressFields || isTouched.coCustomerStreetAddress) {
      const streetAddressError = validateStreetAddress(
        values.coCustomerStreetAddress,
        values.coSellerAlternateAddressFields,
      );
      if (streetAddressError) {
        errors.coCustomerStreetAddress = streetAddressError;
      }
    }

    // checks for the value of city and then if provided checks for the city requirements
    if (values.coSellerAlternateAddressFields || isTouched.coCustomerCity) {
      if (!values.coCustomerCity) {
        errors.coCustomerCity = c.CITY_REQUIRED;
      }
    }

    // checks for the value of state and then if provided checks for the state requirements
    if (values.coSellerAlternateAddressFields || isTouched.coCustomerState) {
      if (!values.coCustomerState) {
        errors.coCustomerState = c.STATE_REQUIRED;
      }
    }

    if (values.coSellerAlternateAddressFields || isTouched.coCustomerZipCode) {
      errors.coCustomerZipCode = validateZipCode(values.coCustomerZipCode);
    }

    // If no selection made, error
    if (!isDoYouHaveASellerQuestionExcluded && isTouched.sellerInfo) {
      if (!values.sellerInfo || (!values.sellerInfo.yes && !values.sellerInfo.no)) {
        errors.sellerInfo = c.SELLER_RADIO_REQUIRED;
      }
    }

    if (isTouched.cooperatingOffice) {
      if (
        values.cooperatingOffice &&
        !values.cooperatingAgent &&
        !values.isCustomCooperatingAgentFlow
      ) {
        errors.cooperatingAgent = c.COOPERATING_AGENT_REQUIRED;
      }
    }

    errors = deleteUndefinedValues(errors, true);

    return errors;
  };

// TODO: Refactor. See: https://ftdr.atlassian.net/browse/ARE-7281
/** validate function can be used to validate the values of the form-fields
it takes two arguments and returns the errors specific to the form */
export default function validate(values: any, isTouched: any) {
  let errors: any = {};

  if (!isTouched) return errors;

  // checks for the value of email and then if provided checks for the email-format
  if (isTouched.email) {
    if (!values.email) {
      errors.email = c.EMAIL_REQUIRED;
    } else if (!isEmailValid(values.email)) {
      errors.email = c.INVALID_EMAIL;
    } else if (
      values.checkInternalEmail &&
      c.INTERNAL_EMAIL_DOMAIN.indexOf(getEmailDomain(values.email)) !== -1
    ) {
      // Do not allow internal emails to be used
      errors.email = true;
    }
  }

  if (isTouched.firstName) {
    if (!values.firstName) {
      errors.firstName = c.FIRST_NAME_REQUIRED;
    }
  }

  if (isTouched.lastName) {
    if (!values.lastName) {
      errors.lastName = c.LAST_NAME_REQUIRED;
    }
  }

  // checks for the value of email and then if provided checks for the email-format
  if (isTouched.otherNotificationEmail) {
    if (!values.otherNotificationEmail) {
      errors.otherNotificationEmail = c.EMAIL_REQUIRED;
    } else if (!isEmailValid(values.otherNotificationEmail)) {
      errors.otherNotificationEmail = c.INVALID_EMAIL;
    } else if (
      values.checkInternalEmail &&
      c.INTERNAL_EMAIL_DOMAIN.indexOf(getEmailDomain(values.otherNotificationEmail)) !== -1
    ) {
      errors.otherNotificationEmail = true;
    }
  }

  // checks for the value of password and then if provided checks for the password requirements
  if (isTouched.password) {
    if (!values.password) {
      errors.password = c.PASSWORD_REQUIRED;
    } else if (values.password.length < c.PASSWORD_MIN_LENGTH) {
      errors.password = c.PASSWORD_MIN_LENGTH_REQUIRED;
    }
  }

  // checks if the values of password and confirm-password matches
  if (isTouched.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = c.PASSWORD_MATCH;
    }
  }

  // checks for the value of phone number and then if provided checks for the phone number requirements
  if (isTouched.phoneNumber) {
    if (!values.phoneNumber) {
      errors.phoneNumber = c.PHONE_NUMBER_REQUIRED;
    } else if (!isPhoneNumberValid(values.phoneNumber)) {
      errors.phoneNumber = c.INVALID_PHONE_NUMBER;
    }
  }

  if (isTouched.phone && values.phone) {
    if (!isPhoneNumberValid(values.phone)) {
      errors.phone = c.INVALID_PHONE_NUMBER;
    }
  }

  if (isTouched.phone && isTouched.email) {
    if (!values.phone && !values.email && values.needEmailOrPhone) {
      errors.email = c.EMAIL_OR_PHONE_REQUIRED;
      errors.phone = c.EMAIL_OR_PHONE_REQUIRED;
    }
  }

  // checks for the value of office name and then if provided checks for the office name requirements
  if (isTouched.officeName) {
    if (!values.officeName) {
      errors.officeName = c.OFFICE_NAME_REQUIRED;
    }
  }

  // checks for the value of a street address and then if provided checks for the street address requirements
  if (values.alternateAddressFields || isTouched.streetAddress) {
    const streetAddressError = validateStreetAddress(
      values.streetAddress,
      values.alternateAddressFields,
    );
    if (streetAddressError) {
      errors.streetAddress = streetAddressError;
    }
  } else if (!isTouched.streetAddress && !values.streetAddress && isTouched.streetAddress != null) {
    errors.streetAddress = c.STREET_ADDRESS_REQUIRED;
  }

  // checks for the value of city and then if provided checks for the city requirements
  if (values.alternateAddressFields || isTouched.city) {
    if (!values.city) {
      errors.city = c.CITY_REQUIRED;
    }
  }

  // checks for the value of zip and then if provided checks for the zip requirements
  if (isTouched.zip) {
    if (!values.zip) {
      errors.zip = c.ZIP_REQUIRED;
    } else if (values.zip && !c.ZIP_PATTERN.test(values.zip)) {
      errors.zip = c.MALFORMED_ZIP_CODE;
    }
  } else if (!isTouched.zip && !values.zip && isTouched.zip != null) {
    errors.zip = c.ZIP_REQUIRED;
  }

  // checks for the value of zip and then if provided checks for the zip requirements
  if (isTouched.zipCode) {
    errors.zipCode = validateZipCode(values.zipCode);
  }

  // checks for the value of state and then if provided checks for the state requirements
  if (values.alternateAddressFields || isTouched.state) {
    if (!values.state) {
      errors.state = c.STATE_REQUIRED;
    }
  }

  // checks for the value of street address and then if provided checks for the street address requirements
  if (values.coBuyerAlternateAddressFields || isTouched.coCustomerStreetAddress) {
    const streetAddressError = validateStreetAddress(
      values.coCustomerStreetAddress,
      values.coBuyerAlternateAddressFields,
    );
    if (streetAddressError) {
      errors.coCustomerStreetAddress = streetAddressError;
    }
  }

  // checks for the value of city and then if provided checks for the city requirements
  if (values.coBuyerAlternateAddressFields || isTouched.coCustomerCity) {
    if (!values.coCustomerCity) {
      errors.coCustomerCity = c.CITY_REQUIRED;
    }
  }

  // checks for the value of zip and then if provided checks for the zip requirements
  if (values.coBuyerAlternateAddressFields || isTouched.coCustomerZipCode) {
    errors.coCustomerZipCode = validateZipCode(values.coCustomerZipCode);
  }

  // checks for the value of state and then if provided checks for the state requirements
  if (values.coBuyerAlternateAddressFields || isTouched.coCustomerState) {
    if (!values.coCustomerState) {
      errors.coCustomerState = c.STATE_REQUIRED;
    }
  }

  // checks for the value of account type and then if provided checks for the account type requirements
  if (
    get(isTouched, 'accountType') ||
    isTouched.RealEstateAgent ||
    isTouched.RealEstateAdmin ||
    isTouched.Broker ||
    isTouched.ClosingCompanyAgent ||
    isTouched.ClosingCompanyAdmin
  ) {
    if (!get(values, 'accountType')) {
      errors.accountType = c.ACCOUNT_TYPE_REQUIRED;
    }
  }

  if (isTouched.narIdentificationNumber) {
    if (values.narIdentificationNumber && !c.NAR_PATTERN.test(values.narIdentificationNumber)) {
      errors.narIdentificationNumber = c.INVALID_NAR_ID;
    }
  }

  // checks for the value of new password and then if provided checks for the new password length
  if (isTouched.newPassword) {
    if (isPasswordValid(values.newPassword)) {
      errors.newPassword = '';
    } else {
      errors.newPassword = c.PASSWORD_MIN_LENGTH;
    }
  }

  // checks if the values of password and confirm-password matches for the my account page
  if (isTouched.confirmNewPassword) {
    if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = c.PASSWORD_MATCH;
    }
  }

  // checks for the value of co-customer's email and then if provided checks for the email-format
  if (isTouched.coCustomerEmail && values.coCustomerEmail) {
    if (values.coCustomerEmail && !isEmailValid(values.coCustomerEmail)) {
      errors.coCustomerEmail = c.INVALID_EMAIL;
    } else if (
      values.checkInternalEmail &&
      c.INTERNAL_EMAIL_DOMAIN.indexOf(getEmailDomain(values.coCustomerEmail)) !== -1
    ) {
      errors.coCustomerEmail = true;
    }
  }

  // checks for the value of co-customer's phone number and then if provided checks for the phone number requirements
  if (isTouched.coCustomerPhoneNumber && values.coCustomerPhoneNumber) {
    if (!isPhoneNumberValid(values.coCustomerPhoneNumber)) {
      errors.coCustomerPhoneNumber = c.INVALID_PHONE_NUMBER;
    }
  }

  if (isTouched.coCustomerPhone && values.coCustomerPhone) {
    if (!isPhoneNumberValid(values.coCustomerPhone)) {
      errors.coCustomerPhone = c.INVALID_PHONE_NUMBER;
    }
  }

  // checks if initiating office is reset or not
  if (isTouched.initiatingOffice) {
    if (!values.initiatingOffice) {
      errors.initiatingOffice = c.INITIATING_OFFICE_REQUIRED;
    }
  }
  // checks for the value of initiating agent and then if provided checks for the initiating agent requirements
  if (isTouched.initiatingAgent) {
    if (!values.initiatingAgent) {
      errors.initiatingAgent = c.INITIATING_AGENT_REQUIRED;
    }
  }
  // checks for the value of agent represents and then if provided checks for the agent represents requirements
  if (isTouched.agentRepresents) {
    if (
      !values.agentRepresents?.buyer &&
      !values.agentRepresents?.seller &&
      !values.agentRepresents?.both
    ) {
      errors.agentRepresents = c.AGENT_REPRESENTS_REQUIRED;
    }
  }

  if (isTouched.zipCode) {
    errors.zipCode = validateZipCode(values.zipCode, false);
  }

  if (isTouched.coCustomerZipCode) {
    errors.coCustomerZipCode = validateZipCode(values.coCustomerZipCode, false);
  }

  // If no selection made, error.
  if (isTouched.buyerInfo) {
    if (!values.buyerInfo?.yes && !values.buyerInfo?.no) {
      // In theory, this should not be needed but above booleans are not getting set correctly for buyers
      if (!values.firstName) {
        errors.buyerInfo = c.BUYER_RADIO_REQUIRED;
      }
    }
  }

  // If no selection made, error
  if (isTouched.hasSellerInfo || isTouched.noSellerInfo) {
    if (!values.sellerInfo || (!values.sellerInfo.yes && !values.sellerInfo.no)) {
      errors.sellerInfo = c.SELLER_RADIO_REQUIRED;
    }
  }

  if (isTouched.cooperatingOffice) {
    if (values.cooperatingOffice && !values.cooperatingAgent) {
      errors.cooperatingAgent = c.COOPERATING_AGENT_REQUIRED;
    }
  }

  errors = deleteUndefinedValues(errors, true);

  return errors;
}

export const allDisallowedEmailsErrors = [
  c.BUYER_SELF_OR_AGENT_EMAIL_MATCHING,
  c.BUYER_SELF_EMAIL_MATCHING,
  c.SELLER_SELF_OR_AGENT_EMAIL_MATCHING,
  c.SELLER_SELF_EMAIL_MATCHING,
];
export const allDisallowedPhoneErrors = [
  c.BUYER_SELF_OR_AGENT_PHONE_MATCHING,
  c.BUYER_SELF_PHONE_MATCHING,
  c.SELLER_SELF_OR_AGENT_PHONE_MATCHING,
  c.SELLER_SELF_PHONE_MATCHING,
];

// Checking For Fields Format validation on submit irrespective of validation is on or off.
export const isAllFieldFormatsValid = (errors) => {
  let isValidFormats = 0;

  // this is just so plain stupid, can we just tear up the customer card already and start over its validation logic? there's like 6 built workarounds stacked together...
  const allEmailErrors = [c.INVALID_EMAIL, ...allDisallowedEmailsErrors];
  const allPhoneErrors = [c.INVALID_PHONE_NUMBER, ...allDisallowedPhoneErrors];

  if (errors.email && allEmailErrors.includes(errors.email)) {
    isValidFormats += 1;
  }
  if (errors.phone && allPhoneErrors.includes(errors.phone)) {
    isValidFormats += 1;
  }
  if (errors.coCustomerEmail && allEmailErrors.includes(errors.coCustomerEmail)) {
    isValidFormats += 1;
  }
  if (errors.coCustomerPhone && allPhoneErrors.includes(errors.coCustomerPhone)) {
    isValidFormats += 1;
  }
  if (errors.coCustomerFirstName || errors.coCustomerLastName) isValidFormats += 1;
  if (errors.zipCode || errors.zipCode === c.MALFORMED_ZIP_CODE) {
    isValidFormats += 1;
  }
  if (errors.coCustomerZipCode || errors.coCustomerZipCode === c.MALFORMED_ZIP_CODE) {
    isValidFormats += 1;
  }

  return isValidFormats === 0;
};

export const containsNoErrors = (errors) => {
  return every(values(errors), isEmpty);
};

export const isAllRequiredValuesPresent = (valueList, isCustomerContactInfoRequired: boolean) => {
  // For on save required validation in seller and buyer card
  if (!valueList) return false;

  if (
    isCustomerContactInfoRequired &&
    !(valueList.firstName && valueList.lastName && (valueList.email || valueList.phone))
  ) {
    return false;
  }

  if (!valueList.firstName || !valueList.lastName) return false;

  if (valueList.coCustomerFirstName && valueList.coCustomerLastName) return true;

  if (valueList.coSellerFields || valueList.coBuyerFields) return false;

  return true;
};

export const isPasswordValid = (password) => {
  if (!password || password.length < c.PASSWORD_MIN_LENGTH) {
    return false;
  }
  return true;
};

export const isNarIdentificationNumberValid = (narId: string) => {
  return !(narId && !c.NAR_PATTERN.test(narId));
};

export const isAccountTypeValid = (accountType: string) => {
  const roleArray: any[] = [
    UserRoleType.SiteAdmin,
    UserRoleType.FieldSales,
    UserRoleType.RealEstateAdmin,
    UserRoleType.ClosingCompanyAdmin,
    UserRoleType.RealEstateAgent,
    UserRoleType.ClosingCompanyAgent,
    UserRoleType.Broker,
    UserRoleType.InternalSalesAgent,
  ];
  return roleArray.includes(accountType);
};

/**
 * Determines if the phone number is valid or not.
 * Follows the following rules:
 * - 10 digits
 * - 1st digit cannot be 0 or 1
 * - 4th digit cannot be 0 or 1
 * - The 5th and 6th digit cannot both be 1
 * - The 4th, 5th, and 6th digit cannot all be 5
 * @param phoneNumber
 */
export const isPhoneNumberValid = (phoneNumber) => {
  // Original check for the length, all numeric, and digit count
  if (!phoneNumber || !c.PHONE_NUMBER_PATTERN.test(phoneNumber)) {
    return false;
  }

  // Begin checking additional rules after clearing up mask formatting
  const phone = cleanPhone(phoneNumber);

  const firstDigit = phone.charAt(0);
  const fourthDigit = phone.charAt(3);
  const fifthDigit = phone.charAt(4);
  const sixthDigit = phone.charAt(5);

  // 1st digit cannot be 0 or 1
  if (['0', '1'].includes(firstDigit)) {
    return false;
  }
  // 4th digit cannot be 0 or 1
  if (['0', '1'].includes(fourthDigit)) {
    return false;
  }
  // 5th and 6th digit cannot all be 1
  if ([fifthDigit, sixthDigit].every((d) => d === '1')) {
    return false;
  }
  // 4th, 5th, and 6th digit cannot all be 5
  if ([fourthDigit, fifthDigit, sixthDigit].every((d) => d === '5')) {
    return false;
  }
  // Skip this mobile number - ARE-10797
  if (phone === '9999999999') {
    return false;
  }

  return true;
};

export const isEmailValid = (email: string) => {
  // Email must exist
  if (!email) {
    return false;
  }
  // Email must meet basic pattern
  if (!c.BASE_EMAIL_PATTERN.test(email)) {
    return false;
  }
  // Email must meet pattern
  if (!c.EMAIL_PATTERN.test(email)) {
    return false;
  }
  // Email must not contain special characters
  for (const char of c.EMAIL_DISALLOWED_SPECIAL_CHARACTERS) {
    if (email.indexOf(char) > -1) {
      return false;
    }
  }

  return true;
};

export const isZipValid = (zip) => {
  if (!zip || !c.ZIP_PATTERN.test(zip)) {
    return false;
  }
  return true;
};

export const stringHasValue = (value) => {
  if (!value || value.trim().length === 0) {
    return false;
  }
  return true;
};

/** validate the street address */
export const validateStreetAddress = (streetAddress1: string, allowPOBox: boolean): string => {
  // street address cannot be empty
  if (!streetAddress1?.trim()) {
    return c.STREET_ADDRESS_REQUIRED;
  }
  // validate PO Box Address
  if (isPOBoxAddress(streetAddress1)) {
    // don't allow PO Box address if not allowed.
    if (!allowPOBox) {
      return c.STREET_ADDRESS_PO_BOX_NOT_ACCEPTABLE;
    }
    return validatePOBoxAddress(streetAddress1);
  }
  // validate regular address

  // require the first word being digits.
  const firstWord = streetAddress1.split(' ')[0];
  const firstWordAsNoDigits = firstWord && !/\d/.test(firstWord);
  if (firstWordAsNoDigits) {
    return c.STREET_ADDRESS_NUMBER_MISSING;
  }
  return null;
};

/** returns true if PO Box Address (starts with PO Box). Otherwise false. */
export const isPOBoxAddress = (streetAddress1: string): boolean => {
  return c.PO_BOX_PATTERN.test(streetAddress1.toLocaleLowerCase());
};

/** returns true if the PO Box Address is one of our acceptable patterns. Otherwise false. */
export const isPOBoxFormatAcceptable = (streetAddress1: string): boolean => {
  return !!(c.PO_BOX_ACCEPTED_PATTERNS as string[]).find((pattern) =>
    streetAddress1.toLocaleLowerCase().includes(pattern.toLocaleLowerCase()),
  );
};

/** Validates if the PO Box is complete. It should be a recognizable pattern and has at least 1 digit */
export const validatePOBoxAddress = (streetAddress1: string): string => {
  if (!isPOBoxAddress(streetAddress1)) {
    return c.STREET_ADDRESS_PO_BOX_INCOMPLETE;
  }
  if (!isPOBoxFormatAcceptable(streetAddress1)) {
    return c.STREET_ADDRESS_PO_BOX_INVALID;
  }
  const hasDigit = /\d/.test(streetAddress1);
  if (!hasDigit) {
    return c.STREET_ADDRESS_PO_BOX_NUMBER_MISSING;
  }
  return null;
};

export const validateZipCode = (zipCode: string, isRequired = true): string => {
  if (isRequired && !zipCode) {
    return c.ZIP_REQUIRED;
  }
  if (!c.ZIP_PATTERN.test(zipCode)) {
    return c.MALFORMED_ZIP_CODE;
  }
  return null;
};
