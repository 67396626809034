import { Button, MaskedInput, Text } from '@ftdr/blueprint-components-react';
import * as c from '@constants/formField-constants';
import React, { useEffect, useState } from 'react';
import { getFormInputErrorId } from '@storybook/addon-links';

const IneligibleDwellingSize = (props) => {
  const [squareFootageValue, setSquareFootageValue] = useState<string>('');
  const [error, setError] = useState(false);
  const handleChange = (e) => {
    setError(false);
    if (e) {
      setSquareFootageValue(e.target.value);
    } else {
      setSquareFootageValue('');
    }
  };
  const handleUpdate = () => {
    if (!squareFootageValue) {
      setError(true);
    } else {
      props.onClose(Number(squareFootageValue.replace(',', '')));
    }
  };
  const handleStartOver = () => {
    props.startOver();
  };

  useEffect(() => {
    props.setActions([
      <Button
        key="update"
        label="Update Size"
        size="medium"
        width="full"
        labelAlign="center"
        onClick={handleUpdate}
        disabled={!squareFootageValue?.length}
      />,
      <Button
        key="cancel"
        label="Cancel"
        variant="ghost"
        size="medium"
        width="full"
        labelAlign="center"
        onClick={handleStartOver}
      />,
    ]);
  }, [squareFootageValue]);

  return (
    <div className="flex flex-col space-y-5">
      <Text variant="heading-06">Property Too Large</Text>
      <Text variant="caption">{`We are unable to offer Home Service Plans for properties over ${props.sqFtMax.toLocaleString()} square feet. If this is an error, please enter the property size to proceed.`}</Text>
      <MaskedInput
        formField={true}
        formFieldMessageId={getFormInputErrorId(`${props.id}--overwritten-dwelling-size`)}
        id={`${props.id}--overwritten-dwelling-size`}
        name="Dwelling Size"
        label="Dwelling Size"
        required={true}
        onChange={handleChange}
        error={error ? c.SQUARE_FOOTAGE_REQUIRED : ''}
        value={props.squareFootageValue}
        autoComplete="off"
        maskOptions={{
          mask: Number,
          scale: 0,
          signed: false,
          thousandsSeparator: ',',
          min: 0,
          max: 9999,
        }}
      />
    </div>
  );
};

export default IneligibleDwellingSize;
