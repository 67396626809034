import React, { useContext, useState } from 'react';
import { noop } from 'lodash';
import ProfileModel from '@app/models/profile.model';
import ProfileContext, { defaultProfile } from '@context/ProfileContext';
import {
  defaultContextData,
  defaultMetaData,
  NewOrderContextData,
  NewOrderMetaData,
} from '@context/NewOrderContext/model';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { Customer, OrderRequest } from '@apis/models/orders.api.model';
import { cleanPhone, DateFormat, formatDate, isProdEnv } from '@helpers/utils';
import { DEFAULT_CUSTOMER_PHONE_TYPE } from '@constants/formField-constants';
import { OrderCustomerFormData } from '@app/models/order/order.model';

interface NewOrderContextModel {
  /** user profile data */
  userProfile: ProfileModel;
  /** context data */
  newOrderData: NewOrderContextData;
  /** replaces the context with the data in arg */
  setNewOrderData: (value: NewOrderContextData) => void;
  /** resets the context data back to default values. e.g. used when user submission compeleted or user cancels out. */
  resetNewOrderData: () => void;
  /** how far along is the user in the new order flow */
  latestStep: NewOrderStep;
  /** updates the latest step */
  setLatestStep: (step: NewOrderStep) => void;
  /** extra data that is necessary for new order that is outside the forms */
  metaData: NewOrderMetaData;
  /** update the new order meta data */
  setMetaData: (value: NewOrderMetaData) => void;
  /** used to convert the new order step's data into a new order request */
  toOrderRequest: (data: NewOrderContextData, meta: NewOrderMetaData) => OrderRequest;
  /** used to verify if the order update call needs to be made or not */
  hasOrderRequestChanged: (orderRequest: OrderRequest) => boolean;

  /** for dev purposes */
  addDebugTool: () => void;
}

const initialStep = NewOrderStep.AgentPropertyInfo;

export const NewOrderContext = React.createContext<NewOrderContextModel>({
  userProfile: defaultProfile,
  newOrderData: defaultContextData,
  setNewOrderData: noop,
  resetNewOrderData: noop,
  latestStep: NewOrderStep.AgentPropertyInfo,
  setLatestStep: noop,
  metaData: defaultMetaData,
  setMetaData: noop,
  addDebugTool: noop,
  toOrderRequest: () => {
    return undefined;
  },
  hasOrderRequestChanged: () => {
    return false;
  },
});

export const NewOrderProvider: React.FC = ({ children }) => {
  const { profile } = useContext(ProfileContext);
  const [data, setData] = useState<NewOrderContextData>(getDevContextData() || defaultContextData);
  const [meta, setMeta] = useState<NewOrderMetaData>(getDevMetaData() || defaultMetaData);
  const [latestStep, setLatestStep] = useState<NewOrderStep>(getDevLatestStep() || initialStep);

  const addDebugTool = () => {
    /** create a snapshot of the new order context */
    (window as any).snapshotNewOrder = () => {
      try {
        localStorage.setItem(DevToolKey.ContextData, JSON.stringify(data));
        localStorage.setItem(DevToolKey.MetaData, JSON.stringify(meta));
        localStorage.setItem(DevToolKey.LatestStep, `${latestStep}`);
        console.debug('new order snapshot created');
      } catch (e) {
        console.error('failed to store snapshot', e);
      }
    };
    /** clear the snapshot to return to original behavior */
    (window as any).clearSnapshotNewOrder = () => {
      try {
        localStorage.removeItem(DevToolKey.ContextData);
        localStorage.removeItem(DevToolKey.MetaData);
        localStorage.removeItem(DevToolKey.LatestStep);
        console.debug('new order snapshot purged');
      } catch (e) {
        console.error('failed to purge snapshot', e);
      }
    };
    /** view current context data */
    (window as any).viewNewOrderContext = () => {
      console.group('NewOrderContext');
      try {
        console.debug('newOrderContextData', data);
        console.debug('newOrderMetaData', meta);
        console.debug('latestStep', `${latestStep}`);
      } catch (e) {
        console.error('failed to view context', e);
      } finally {
        console.groupEnd();
      }
    };
    /** view current context data */
    (window as any).viewOrderRequest = () => {
      console.group('OrderRequest');
      try {
        console.debug('orderID', meta.orderID);
        console.debug('orderRequest', toOrderRequest(data, meta));
      } catch (e) {
        console.error('failed to view order request', e);
      } finally {
        console.groupEnd();
      }
    };
  };

  const reset = () => {
    setData(defaultContextData);
    setLatestStep(initialStep);
    setMeta(defaultMetaData);
  };

  const toCustomer = (data: OrderCustomerFormData): Customer => {
    return data?.firstName
      ? {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.emailAddress,
          phones: [
            {
              phone: cleanPhone(data.phoneNumber),
              phoneType: DEFAULT_CUSTOMER_PHONE_TYPE,
            },
          ],
          addressUUID: data.addressUUID,
        }
      : null;
  };

  const toOrderRequest = (data: NewOrderContextData, meta: NewOrderMetaData): OrderRequest => {
    return {
      propertyAddressUUID: data.addressInfo?.addressUUID,
      estimatedClosingDate:
        data.productFilters?.projectedClosingDate &&
        formatDate(data.productFilters.projectedClosingDate, DateFormat.ISO8601),
      mlsValue: data.propertyInfo?.mlsNumber,
      escrowNumber: data.closingInfo?.closingFileNumber,
      quoteID: meta.quoteID,
      offices: {
        initiatingOfficeRepresents: data.agentInfo.represents,
        initiatingOffice: {
          officeID: data.initiatingAgent?.officeID,
          agentID: data.initiatingAgent?.agentID,
        },
        cooperatingOffice: {
          officeID: data.cooperatingAgent?.officeID,
          agentID: data.cooperatingAgent?.agentID,
        },
        closingOffice: {
          officeID: data.closingAgent?.officeID,
          agentID: data.closingAgent?.agentID,
        },
      },
      customers: {
        buyer: toCustomer(data.buyerInfo),
        seller: toCustomer(data.sellerInfo),
        cobuyer: toCustomer(data.coBuyerInfo),
        coseller: toCustomer(data.coSellerInfo),
      },
      emailDocuments: {
        invoice: [], // should be taken care of in step4 orders ms integration work
        confirmation: [],
      },
    };
  };

  const getCurrentOrderRequest = () => {
    return toOrderRequest(data, meta);
  };

  const hasOrderRequestChanged = (orderRequest: OrderRequest): boolean => {
    return JSON.stringify(getCurrentOrderRequest()) !== JSON.stringify(orderRequest);
  };

  const providerValue: NewOrderContextModel = {
    userProfile: profile,
    newOrderData: data,
    setNewOrderData: (data) => {
      setData(data);
      if (!isProdEnv()) {
        console.debug('context data had been updated', data);
      }
    },
    resetNewOrderData: () => reset(),
    latestStep,
    setLatestStep: (data) => setLatestStep(data),
    metaData: meta,
    setMetaData: (data) => setMeta(data),
    addDebugTool: addDebugTool,
    toOrderRequest,
    hasOrderRequestChanged,
  };

  return <NewOrderContext.Provider value={providerValue}>{children}</NewOrderContext.Provider>;
};

/***********************************************************************************************************
 * developer tool to help skip steps on the new order flow.
 * to use, enter window.snapshotNewOrder() into the console when you want to snapshot the page's initial data.
 * to revert back to original behavior, enter window.clearSnapshotNewOrder() into the console.
 *********************************************************************************************************** */
enum DevToolKey {
  ContextData = 'dev.neworder.context',
  MetaData = 'dev.neworder.meta',
  LatestStep = 'dev.neworder.step',
}

function defaultJSONParser<T>(value: string): T {
  return JSON.parse(value) as T;
}

function getLocalStorageData<T>(key: string, parser: (string) => T = defaultJSONParser): T {
  try {
    const devContext = localStorage.getItem(key);
    if (devContext) {
      console.log('developer has defined override data for key', key);
      return parser(devContext);
    }
  } catch (e) {
    console.warn(`failed to load '${key}'. will proceed with no dev override`, e);
  }
  return undefined;
}

const getDevContextData = () => getLocalStorageData<NewOrderContextData>(DevToolKey.ContextData);
const getDevMetaData = () => getLocalStorageData<NewOrderMetaData>(DevToolKey.MetaData);
const getDevLatestStep = () =>
  getLocalStorageData<NewOrderStep>(DevToolKey.LatestStep, (step) => {
    return step !== undefined && step !== null ? (Number(step) as NewOrderStep) : undefined;
  });
