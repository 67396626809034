import React, { useEffect, useState } from 'react';
import { classNames } from '@utils';
import Tooltip from '@components/tooltip/Tooltip';
import { Panel, Text, IconFormInfo } from '@ftdr/blueprint-components-react';
import { useWindowSize } from 'react-use';

interface Props {
  className?: string;
  heading: string;
  headingHelper?: string;
  variant?: 'shadowed' | 'bordered' | 'none';
  mobileVariant?: 'shadowed' | 'bordered' | 'none';
}

const CardBox: React.FC<Props> = (props) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 480);

  useEffect(() => {
    setIsMobile(width < 480);
  }, [width]);

  return (
    <Panel
      padding="none"
      border={(isMobile ? props.mobileVariant : props.variant) === 'bordered' ? '1' : '0'}
      borderColor="gray-300"
      rounded={isMobile ? 'none' : 'md'}
      shadow={(isMobile ? props.mobileVariant : props.variant) === 'shadowed'}
      className={classNames([props.className])}
    >
      <Panel
        roundedT="md"
        roundedB="none"
        border="0"
        backgroundColor="white"
        padding={isMobile ? 'md' : 'lg'}
        className={classNames(['flex items-center'])}
      >
        <Text variant="heading-04" className="my-0">
          {props.heading}
        </Text>

        {props.headingHelper && (
          <Tooltip placement="right" content={<p className="text-left">{props.headingHelper}</p>}>
            <span className="ml-2">
              <IconFormInfo color="gray" />
            </span>
          </Tooltip>
        )}
      </Panel>

      <div className="overflow-hidden">
        <Panel
          roundedT="none"
          roundedB="md"
          border="0"
          padding={isMobile ? 'lg' : 'xl'}
          paddingT="none"
        >
          {props.children}
        </Panel>
      </div>
    </Panel>
  );
};

CardBox.defaultProps = {
  variant: 'shadowed',
  mobileVariant: 'shadowed',
};

export default CardBox;
