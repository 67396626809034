import React, { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageSizeOption, Notifications } from '@ftdr/blueprint-components-react';
import { addActionsLinkRow, ReAction } from '@components/notification/notificationHelper';

/** Message is what is needed to pass down to the global alert */
export interface Message {
  title?: string;
  message?: string;
  payload?: any;
  messageHTML?: JSX.Element;
  status?: string;
  id?: string;
  className?: string;
  actions?: ReAction[];
  onClose?: any;
  isPersistantNotification?: boolean;
}

/** Certain fields are unnecessary to be set by Message, so we set these values afterwards */
interface MessageExtended extends Message {
  index: number;
  timestamp: Date;
}

type GlobalAlertMethodFn = (msg: Message) => void;

export interface GlobalAlertFns {
  /** Add a general message to the alert queue */
  addMessageToQueue: GlobalAlertMethodFn;
  /** Add an error message to the alert queue */
  addErrorToQueue: GlobalAlertMethodFn;
  /** Add a warning message to the alert queue */
  addWarningToQueue: GlobalAlertMethodFn;
  /** Add a success message to the alert queue */
  addSuccessToQueue: GlobalAlertMethodFn;
}

const DEFAULT_ERROR_MESSAGE: Message = {
  title: 'An error has occurred',
  message: 'Something went wrong, please try again later.',
  status: 'error',
};

const DEFAULT_WARN_MESSAGE: Message = {
  title: 'Warning',
  message: 'Something went wrong, please try again later.',
  status: 'warning',
};

const DEFAULT_SUCCESS_MESSAGE: Message = {
  message: 'Operation succeeded.',
  status: 'success',
};

/** The RxJS subject to assign new values to the queue */
const messageQueueSubject = new BehaviorSubject<Message>(undefined);

/** The queue observable. Maps the subject to the extended value. Subscriptions will receive these changes */
const messageQueue$ = messageQueueSubject.pipe(
  map<Message, MessageExtended>((msg) => {
    if (msg) {
      const index = Math.floor(Math.random() * 10000); // Assign a random number for index lookup
      return { ...msg, index, timestamp: new Date() };
    }
    return undefined;
  }),
);

/** Function returns hooks to add a global alert to the queue.
 * The global alert has no actions attached to it, and should be used only
 * for alerts such as API errors or warnings.
 */

// TODO This doesn't need to be a hook and makes functional vs class component usage confusing
// These should ust be exported functions with the same functionality they offer now
// Housing them in a master object to avoid confusion would be fine
// Not every problem is a nail, don't always use a hammer just because you have access to one
// Hooks are good when you need to be able to alter state et al.
export default function useGlobalAlert(): GlobalAlertFns {
  const addErrorToQueue = (msg: Message = {}): void => {
    messageQueueSubject.next({ ...DEFAULT_ERROR_MESSAGE, ...msg });
  };
  const addWarningToQueue = (msg: Message = {}): void => {
    messageQueueSubject.next({ ...DEFAULT_WARN_MESSAGE, ...msg });
  };
  const addMessageToQueue = (msg: Message = {}): void => {
    messageQueueSubject.next(msg);
  };
  const addSuccessToQueue = (msg: Message = {}): void => {
    messageQueueSubject.next({ ...DEFAULT_SUCCESS_MESSAGE, ...msg });
  };

  return { addMessageToQueue, addErrorToQueue, addWarningToQueue, addSuccessToQueue };
}

export const GlobalAlertModal: React.FC<any> = ({ children }) => {
  /** any payload data that should always be displayed instead of hidden will be in this list */
  const alwaysDisplayPayloadKeys: string[] = ['Trace-ID'];

  const [queue, setQueue] = useState([]);
  const [payloadOpen, setPayloadOpen] = useState([]);

  /** Subscribe to the observable to listen to any new messages being sent in */
  useEffect(() => {
    const subscription = messageQueue$.subscribe((queuedMsg) => {
      if (queuedMsg) {
        setQueue((q) => [...q, queuedMsg]);
      }
    });
    // Upon destroying the component, clean up the subscription to avoid memory leaks
    return function cleanup() {
      console.log('clean up subscription of global alert');
      subscription.unsubscribe();
    };
  }, []);

  /** Upon closing the modal, hide the modal. Then update the queue by removing the message displayed */
  function handleClose(queuedMsg) {
    updatePayloadOpen(queuedMsg.id);
    setQueue(queue.filter((m) => m.index !== queuedMsg.index));
  }

  function updatePayloadOpen(id) {
    const updatedPayload = [...payloadOpen];
    if (updatedPayload[id]) {
      delete updatedPayload[id];
    } else {
      updatedPayload[id] = true;
    }

    setPayloadOpen(updatedPayload);
  }

  const getNotifications = () => {
    if (!queue) {
      return [];
    }

    return queue.map((queuedMsg) => {
      const allChildren = (
        <>
          {queuedMsg.title && (
            <div className="message-label message-label-no-match-color">
              {queuedMsg.title}
              {': '}
            </div>
          )}
          {children}
          {queuedMsg.messageHTML ? queuedMsg.messageHTML : <p>{queuedMsg.message}</p>}
          <div className="text-right text-xs text-gray-500">
            {/* Payload */}
            {queuedMsg.payload && (
              <>
                {/* Timestamp */}
                {payloadOpen && <div>{queuedMsg.timestamp.toISOString()}</div>}

                {Object.keys(queuedMsg.payload).map(
                  (k, idx) =>
                    // display (non-empty) all payload when open, or only a subset when not open
                    queuedMsg.payload[k] &&
                    (payloadOpen || alwaysDisplayPayloadKeys.includes(k)) && (
                      <div key={idx}>
                        {k}={queuedMsg.payload[k]}
                      </div>
                    ),
                )}

                {/* Payload visibility toggle */}
                <div
                  className="hover:text-gray-600 cursor-pointer"
                  onClick={() => updatePayloadOpen(queuedMsg.id)}
                >
                  {payloadOpen[queuedMsg.id] ? (
                    <span>Less &#9650;</span>
                  ) : (
                    <span>More &#9660;</span>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );

      return {
        id: queuedMsg.id || 'global-alert',
        onClose: () => handleClose(queuedMsg),
        inline: true,
        variant: 'floating',
        size: 'medium' as MessageSizeOption,
        showStatusLabel: !queuedMsg.title,
        status: queuedMsg.status || 'info',
        autoCloseDelay:
          queuedMsg.status === 'error' ||
          queuedMsg.status === 'warning' ||
          queuedMsg.isPersistantNotification
            ? 0
            : undefined,
        children: allChildren,
        className: queuedMsg.className || 'max-w-full',
        actions: addActionsLinkRow(...queuedMsg.actions),
      };
    });
  };

  return (
    <>
      <Notifications
        className="z-index-9999"
        //@ts-expect-error TODO: not properly checked for type safety
        notifications={getNotifications()}
      />
    </>
  );
};
