import React from 'react';
import { OrderHistory } from '@app/models';
import { DateFormat, formatDate } from '@helpers/utils';

import { Button, Text, IconClockFull } from '@ftdr/blueprint-components-react';

interface Props {
  onClickViewMore?: () => void;
  history: OrderHistory[];
}

const OrderEditHistoryTable: React.FC<Props> = (props) => {
  return (
    <div className="card mt-8 p-4">
      <Text className="h5 font-bold" variant="heading-06">
        <IconClockFull
          className="inline-block max-w-full align-middle -mt-1 mr-2"
          size={18}
          color="gray"
          title="clock"
        ></IconClockFull>
        Edit History
      </Text>
      {props.history.map((item) => (
        <div key={item.data + item.dateTimeStamp} className="mt-2">
          <p className="mb-1 text-xs leading-tight">
            <Text as="strong" variant="label">
              {formatDate(item.dateTimeStamp, DateFormat.FULL)}
            </Text>{' '}
          </p>
          <Text className="mb-0 mt-0 text-xs leading-tight" variant="helper-text">
            {item.data}
          </Text>
        </div>
      ))}
      <div className="pt-4">
        <Button
          label="Show All History"
          variant="ghost"
          size="small"
          onClick={() => props.onClickViewMore()}
          width="full"
        />
      </div>
    </div>
  );
};

export default OrderEditHistoryTable;
