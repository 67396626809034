// TODO: Move helpers to SimpleFocusTempFix.js

/**
 * Helper function to convert the SF FormCheckbox.options into BDS CheckboxGroup.checkboxes
 * @deprecated only used this for existing FormCheckbox usage being migrated. Don't use this for any other usage.
 * @param parentId - FormCheckbox.id
 * @param checkboxOptionsObj - FormCheckbox.options
 */
export const convertOptionsObjToCheckboxGroupOptions = (
  parentId: string,
  checkboxOptionsObj: any,
) => {
  return Object.keys(checkboxOptionsObj).map((key) => ({
    id: key,
    label: checkboxOptionsObj[key],
    value: key,
  }));
};

/**
 * Helper function to convert the SF FormCheckbox.values into BDS CheckboxGroup.values
 * @deprecated only used this for existing FormCheckbox usage being migrated. Don't use this for any other usage.
 * @param checkboxValuesObj - FormCheckbox.values
 */
export const convertValuesToCheckboxGroupValue = (checkboxValuesObj: any) => {
  return Object.keys(checkboxValuesObj).filter((key) => checkboxValuesObj[key] === true);
};

/**
 * Helper function to convert the BDS.CheckboxGroup.onChange to SF.FormCheckbox.onClick.
 * Bridges the gap for reverse-compatibility for QA testing.
 * @deprecated only use this for existing FormCheckbox usage being migrated. Don't use this for any other new usage.
 * @param selectedItems - CheckboxGroup, the param items from onChange
 * @param checkboxOptionValuesObj - FormCheckbox.values
 * @return ChangeEvent<HTMLInputElement> - the change event that FormCheckbox.onClick used
 */
export const convertCheckboxGroupOnChangeToOnClick = (
  selectedItems: string[],
  checkboxOptionValuesObj: any,
  formCheckboxOnClick: (e?) => void,
) => {
  const existingSelections = Object.keys(checkboxOptionValuesObj).filter(
    (key) => checkboxOptionValuesObj[key] === true,
  );

  // checks each selected item against the existing selections
  // if not included in existing selection, that means that it is a new selection made
  selectedItems.forEach((item) => {
    if (!existingSelections.includes(item)) {
      formCheckboxOnClick({
        target: {
          name: item,
          value: item,
          checked: true,
        },
      });
    }
  });

  // checks each existing selection against the selected items
  // if not included in the selected items, that means that the item was unselected
  existingSelections.forEach((selection) => {
    if (!selectedItems.includes(selection)) {
      formCheckboxOnClick({
        target: {
          name: selection,
          value: selection,
          checked: false,
        },
      });
    }
  });
};
