import { ChangeEvent, useEffect, useState } from 'react';
import { maskPhoneNumber } from './utils';

/**
 * Validate function, takes in a type.  Checks values and touched fields, returns errors
 */
export type ValidateFc<T> = (values?: Partial<T>, isTouched?: Partial<T>) => Partial<T>;
const EmptyValidateFc: ValidateFc<any> = () => {
  return {};
};

/**
 * Helper that accepts input values and assigns them to data objects using the form input's name attribute.
 * The names will be used for general validation rules.
 *
 * To use this helper, it is required to :
 * - name your form input fields with the correct names
 * - include handleBlur, handleSubmit on the template
 * - include errors, values using the field names
 * - case sensitive field names
 *
 * TODO: Partial added to default value, but values should really be full and not partial. Once rest of code that uses this changes, then partial can change
 * */
const useForm = <T>(validate: ValidateFc<T> = EmptyValidateFc, defaultValues: Partial<T> = {}) => {
  const [values, setValues] = useState<Partial<T>>(defaultValues);
  const [errors, setErrors] = useState<Partial<T>>({});
  const [touched, setTouched] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /** Upon changes to input values or error values, when submitting, run validate() */
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && Object.keys(touched).length === 0) {
      validate(); // This is not passing in any params for some reason. Double-check.
    }
  }, [errors, touched]);

  /* handles the onblur event that occurs when an object loses focus */
  const handleBlur = (event) => {
    const update = { ...values, [event.target.name]: event.target?.value?.trim() };
    setValues(update);
    const touch = { ...touched, [event.target.name]: true };
    setTouched(touch);
    setErrors(validate(update, touch));
  };

  /** Runs validate, setting errors in place. Returns boolean if has error or not */
  const revalidate = (): boolean => {
    const errs = validate(values, touched);
    setErrors(errs);
    const hasErrors = Object.keys(errs).length > 0;
    return hasErrors;
  };

  /** Returns true if there are no fields in 'touched' */
  const isUntouched = (): boolean => {
    return Object.keys(touched).length === 0;
  };

  /**
   * Apply touch based on the values given.  This is awkward and should be refactored.
   * Really should be done when initializing for all forms, but may have side-effects. TODO: look into this.
   * Gets each key from values and set them to true.
   * Returns if has errors or not.
   */
  const forceTouchAll = (): { hasErrors: boolean; errors: Partial<T> } => {
    const touch: any = {};
    for (const key of Object.keys(values)) {
      touch[key] = true;
    }
    setTouched(touch);

    // Validate against the newly touched values
    const errs = validate(values, touch);
    setErrors(errs);
    const hasErrors = Object.keys(errs).length > 0;
    return { hasErrors, errors: errs };
  };

  /** Upon change in phone field, update the value to be formatted correctly.
   * TODO Replace Phone Numbers with a mask rather than changing the value.
   * That will make this function redundant
   */
  const handlePhoneFormat = (event) => {
    const formattedValue = maskPhoneNumber(event.target.value);
    const update = { ...values, [event.target.name]: formattedValue };
    setValues(update);
  };

  /** handles the submit event on the form
   * @param event - submit event
   * @return errors - the updated errors for the form
   */
  const handleSubmit = (event) => {
    event.preventDefault(); // prevent browser reload/refresh when submitting inside a form
    setIsSubmitting(true);

    // TODO: this should be called, but don't want to break existing forms right now
    // forceTouchAll(); revalidate();

    if (errors) {
      /** Getting the form fields name and set their values to true.
       * Array.prototype.slice.call() to convert Array-like objects into real arrays
       */
      const fields = Array.prototype.slice
        .call(event.target)
        .filter((el) => el.name)
        .reduce(
          (form, el) => ({
            ...form,
            [el.name]: true,
          }),
          {},
        );
      setTouched(fields);
      const newErrors = validate(values, fields);
      setErrors(newErrors);
      return newErrors;
    } else {
      // TODO on form valid call the method that would handle the logic on button click on its own page
      console.log(values);
      return errors;
    }
  };

  const onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  return {
    handleSubmit,
    values,
    errors,
    handleBlur,
    touched,
    setValues,
    handlePhoneFormat,
    setErrors,
    setTouched,
    revalidate,
    forceTouchAll,
    isUntouched,
    onChangeInput,
  };
};

export default useForm;
