import React from 'react';

const RemoveOffice = () => {
  return (
    <>
      <p>Are you sure you want to remove this office from your account?</p>

      <p>
        You will no longer be listed as an employee, and you won&apos;t have access to any orders
        you created for this office.
      </p>
    </>
  );
};

export default RemoveOffice;
