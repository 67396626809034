import React, { useEffect, useState } from 'react';
import OrderForm from '@components/orders/forms/OrderForm';
import { Input, RadioGroup } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderClosingInfoFormData } from '@app/models/order/order.model';
import * as c from '@constants/formField-constants';
import InitiatingOfficeInput from '@components/input/InitiatingOfficeInput';
import InitiatingAgentInput from '@components/input/InitiatingAgentInput';
import { Office as AppOffice, OfficeType } from '@app/models';
import { Office } from '@apis/models';

export enum OrderFormClosingInfoField {
  closingOffice = 'closingOffice',
  closingOfficeAgent = 'closingOfficeAgent',
}
interface OrderFormClosingInfoProps
  extends OrderFormProps<OrderClosingInfoFormData, OrderFormClosingInfoField> {
  /** ids for the input fields */
  ids: Record<OrderFormClosingInfoField, string>;
  /** the input field labels */
  labels: Record<OrderFormClosingInfoField, string>;
  officeSuggestions?: AppOffice[];
}

const OrderFormClosingInfo: React.FC<OrderFormClosingInfoProps> = (props) => {
  const isUserClosingCompanyAgent = false;
  // const isUserClosingCompanyAgent = props.userType === 'ClosingCompanyAgent';

  const [hasClosingCompany, setHasClosingCompany] = useState(false);

  const [selectedOffice, setSelectedOffice] = useState<Office>(null);
  const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false);
  const [agentSuggestions, setAgentSuggestions] = useState<any[]>([]);

  const onClearOfficeSelection = () => {
    setSelectedOffice(null);
    props.onUpdateFormData({
      ...props.formData,
      closingAgent: {
        officeID: '',
        agentID: '',
        officeName: '',
        agentName: '',
        agentEmail: '',
        franchiseCode: '',
      },
    });
  };

  useEffect(() => {
    if (props.formData.closingAgent.officeID === '') {
      onClearOfficeSelection();
    }
  }, [props.formData.closingAgent.officeID]);

  const onOfficeSelect = (offices: Office[]) => {
    setShowOfficeSuggestions(false);

    const office = offices[0];
    setSelectedOffice(office);
    setAgentSuggestions([]);

    props.onUpdateFormData({
      ...props.formData,
      closingAgent: {
        officeID: office.id,
        agentID: '',
        officeName: office.name,
        agentName: '',
        agentEmail: '',
        franchiseCode: '',
      },
    });
    props.clearFieldError(OrderFormClosingInfoField.closingOffice);
  };

  const onAgentSelect = (values: any) => {
    if (values.initAgentId && values.AgentName) {
      props.onUpdateFormData({
        ...props.formData,
        closingAgent: {
          officeID: props.formData.closingAgent.officeID,
          agentID: values.initAgentId,
          officeName: props.formData.closingAgent.officeName,
          agentName: values.AgentName,
          agentEmail: values.AgentEmail,
          franchiseCode: '',
        },
      });
    }
    props.clearFieldError(OrderFormClosingInfoField.closingOfficeAgent);
  };

  const onClosingCompanyQuestionChange = (value) => {
    setHasClosingCompany(value === 'true');
  };

  return (
    <>
      <div className="pt-8">
        <OrderForm.Container id="closing-info-form" heading="Closing Information">
          <OrderForm.Row>
            <OrderForm.RowItem>
              <RadioGroup
                formField={true}
                id="wb_titleCompanyCheck"
                label={ORDER_FORM_FIELDS.CLOSING_COMPANY__QUESTION}
                required={false}
                orientation="horizontal"
                radios={[
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                  {
                    label: 'No',
                    value: 'false',
                  },
                ]}
                value={`${hasClosingCompany}`}
                onChange={(selected) =>
                  isUserClosingCompanyAgent
                    ? console.log('do nothing')
                    : onClosingCompanyQuestionChange(selected)
                }
              />
            </OrderForm.RowItem>
          </OrderForm.Row>

          {hasClosingCompany && (
            <>
              <OrderForm.Row>
                <OrderForm.RowItem>
                  <InitiatingOfficeInput // mostly untyped
                    id={props.ids.closingOffice}
                    name="closingOffice"
                    label={props.labels.closingOffice}
                    officeList={props.officeSuggestions}
                    selectedOffice={selectedOffice}
                    handleOfficeSelect={(office) => onOfficeSelect(office)}
                    updateOfficeFilter={() => {}} // do nothing with this
                    focusOfficeSelect={() => setShowOfficeSuggestions(true)}
                    errors={props.errors.closingOffice}
                    onOfficeError={() =>
                      props.onValidateField(
                        OrderFormClosingInfoField.closingOffice,
                        props.formData.closingAgent.officeID,
                      )
                    }
                    showOfficeSuggestions={showOfficeSuggestions}
                    setShowOfficeSuggestions={setShowOfficeSuggestions}
                    // below are used for blur event. too strict. boils down to the below.
                    values={{
                      closingOfficeId: props.formData.closingAgent.officeID,
                    }}
                    setValues={() => onClearOfficeSelection()}
                    officeType={OfficeType.ClosingCompany}
                  />
                </OrderForm.RowItem>

                <OrderForm.RowItem>
                  <InitiatingAgentInput
                    id={props.ids.closingOfficeAgent}
                    name="closingAgent"
                    label={props.labels.closingOfficeAgent}
                    selectedOffice={props.formData.closingAgent.officeName}
                    errors={props.errors.closingOfficeAgent}
                    onAgentError={() =>
                      props.onValidateField(
                        OrderFormClosingInfoField.closingOfficeAgent,
                        props.formData.closingAgent.agentID,
                      )
                    }
                    values={{
                      agentOffice: selectedOffice,
                      agentlist: agentSuggestions,
                    }}
                    setValues={(values) => onAgentSelect(values)}
                    onSelectContactChange={() => {}}
                    hasNotApplicableOption={true}
                  />
                </OrderForm.RowItem>
              </OrderForm.Row>

              <OrderForm.Row>
                <OrderForm.RowItem>
                  <div className="w-full sm:w-1/2 py-2">
                    <Input
                      formField={true}
                      id="wb_closingFile"
                      name="closingFileNumber"
                      label={ORDER_FORM_FIELDS.CLOSING_FILE}
                      required={false}
                      value={props.formData.closingFileNumber}
                      autoComplete="off"
                      onBlur={console.log}
                      onChange={(e) =>
                        props.onUpdateFormData({
                          ...props.formData,
                          closingFileNumber: e.target.value,
                        })
                      }
                      maxLength={c.ESCROW_NUMBER_MAX_LENGTH}
                    />
                  </div>
                </OrderForm.RowItem>
              </OrderForm.Row>
            </>
          )}
        </OrderForm.Container>
      </div>
    </>
  );
};

export default OrderFormClosingInfo;
