import React, { createContext, useContext } from 'react';
import { getBrand } from '@helpers/brand.utils';

const ThemeContext = createContext(getBrand().toLowerCase());

export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
/**
 * @deprecated: use brand.utils.ts:getBrand()
 */
export const useThemeContext = () => useContext(ThemeContext);
export default ThemeContext;

/**
 * @deprecated: this is for simplefocus's CSS styling
 */
export function getThemeClass() {
  const theme = getBrand().toLowerCase();
  return `theme-${theme}`;
}
