import { isZipValid, stringHasValue } from '../validation/ValidationRules';
import { DwellingType } from '../apis/models/product.api.model';
import { PlansAndPricesFormData } from '../../templates/misc/PlansAndPricesTemplate';
import QueryParamParserService from './queryParam.service';

export enum PlansAndPricesParamEnum {
  RESIDENT_TYPE = 'residencetype',
  ZIP_CODE = 'zipcode',
  SELLERS_COVERAGE = 'sellerscoverage',
}

class PlansAndPricesQueryParams {
  public data: PlansAndPricesFormData = {
    residenceType: DwellingType.SingleFamilyResidence,
    zipCode: '',
    includeSellersCoverage: false,
  };

  init() {
    const URLParams = QueryParamParserService.getParams();
    this.validateAndTransform(URLParams);
  }

  validateAndTransform(params: any) {
    let residenceType: DwellingType = DwellingType.SingleFamilyResidence,
      zipCode = '',
      includeSellersCoverage = false;

    //get the passed DwellingType enum. If none found, default to SingleFamilyResidence
    const key =
      Object.keys(DwellingType).find(
        (key) => DwellingType[key] === params[PlansAndPricesParamEnum.RESIDENT_TYPE]?.toUpperCase(),
      ) || '';
    residenceType = stringHasValue(key) ? DwellingType[key] : DwellingType.SingleFamilyResidence;

    const isZipCodeValid = isZipValid(params[PlansAndPricesParamEnum.ZIP_CODE]);
    if (isZipCodeValid) {
      zipCode = params[PlansAndPricesParamEnum.ZIP_CODE]?.trim();
    }

    if (params[PlansAndPricesParamEnum.SELLERS_COVERAGE]?.trim() === 'true') {
      includeSellersCoverage = true;
    }

    this.data = {
      residenceType: residenceType,
      zipCode: zipCode,
      includeSellersCoverage: includeSellersCoverage,
    };
  }
}

const PlansAndPricesQueryParamsService = new PlansAndPricesQueryParams();
export default PlansAndPricesQueryParamsService;
