import React from 'react';
import ErrorSvg from '@components/error/errorIcon';

// The Error icon being used for form errors is different than the BDS packaged Icon, so we had to use the exact SVG to make it look identical
// Why is BDS using a different icon than what they expose?
const ErrorText = (props) => {
  return (
    <>
      {props.text && (
        <div className="form-field-bottom-row message-only">
          <div id={props.id} className="message form-field-message">
            <span>
              <ErrorSvg />
            </span>
            <div className="message-content message-content-error message-content-small">
              <div className="message-text">{props.text}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorText;
