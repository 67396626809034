import React from 'react';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { classNames } from '@utils';
import {
  ProgressIndicator,
  Select,
  SelectOption,
  SelectProps,
  Text,
} from '@ftdr/blueprint-components-react';
import _debounce from 'lodash/debounce';
import AddressApi from '@apis/address.api';
import { Address } from '@app/models';
import { validateStreetAddress } from '@services/validation/ValidationRules';

const ADDRESS_LOOKUP_DEBOUNCE_TIME = 200;

/** Props that are mandatory for New Order flow but should not be used anywhere else. Caution on using these, as it was not designed well.
 *  The clean up may be taken care of when we start the New Order UX refresh work.
 *  Note that NewOrder flow needs to use formFieldMessageId, name, and id to maintain existing behavior.
 *  Only put in formFieldMessageId if it was not populated originally.
 *  @deprecated avoid using these fields */
type DeprecatedNewOrderProps = Pick<SelectProps, 'formFieldMessageId'> & {
  /** event fires when user is typing in the input box. primarily used in tandem with UseForm, where the 'touched' state needs to be set.
   * Logic is considered deprecated.
   * @deprecated avoid other than new order */
  onChangeInputNewOrderDeprecated?: (event) => void;
  /** event fires when user is blurring from the input box. primarily used in tandem with UseForm, where the 'touched' state needs to be set.
   * Logic is considered deprecated.
   * @deprecated avoid other than new order */
  onBlurInputNewOrderDeprecated?: (event) => void;
};

type OwnProps = {
  /** address lookup needed for typeahead searching */
  addressLookup: Address; //Required<Pick<Address, 'city'|'state'|'zip'>>
  /** when set to true, PO Box is not allowed in the input. Default true */
  allowPOBox?: boolean;
  /** value for the input */
  value: string;
  /** event for when a street address was selected or if the user blurred from the field with custom input */
  setValue: (address: Address) => void;
  /** error value for the input. If left undefined, shifts to uncontrolled state. otherwise controlled.*/
  error?: string;
  /** event for when the error was updated in the component. used for parent to keep track of error states */
  setError?: (err: string) => void;
  /** event for when the error should be cleared (i.e. user is typing in the field) */
  clearError?: () => void;
  //set street address
  setStreetAddressValue?: (street: string) => void;
};

type Props = OwnProps &
  Partial<Pick<SelectProps, 'label' | 'name' | 'id'>> & // component-defined optional fields
  DeprecatedNewOrderProps;

type SelectionOption = Address & SelectOption; // intention to create a re-usable component with type-casting

type State = {
  /** the suggestion options */
  options: SelectionOption[];
  /** the selection. a state to maintain control on select component. */
  selection: SelectionOption;
  /** text value in input */
  value: string;
  /** error message */
  error: string;
  /** when suggestions are being looked up */
  loading: boolean;
};

/** input for address suggestions with street address validation.
 * makes call to address ms to fetch suggestions. clear suggestions once selected.
 * would automatically open the suggestions if there are suggestions available and input is focused. */
export class AddressSuggestionInput extends React.Component<Props, State> {
  static defaultProps = {
    addressLookup: {},
    label: ORDER_FORM_FIELDS.PROPERTY_ADDRESS,
    allowPOBox: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: '',
      loading: false,
      selection: null,
      error: undefined,
    };
  }

  componentDidMount() {
    // load address selection by default if value is given
    if (this.props.value) {
      this.selectEnteredUserInput(this.props.value);
    }
    if (this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    // load address selection if value was changed
    if (prevProps.value !== this.props.value) {
      this.selectEnteredUserInput(this.props.value);
    }
    // load error state if error was changed (i.e. cleared by parent)
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  /** for a given street address value, create a new selection to load into the component and update states.
   *  @param value the street address value.
   *  @return SelectionOption the selection option for using in any other event handlers if needed. */
  selectEnteredUserInput = (value: string): SelectionOption => {
    const customSelection: SelectionOption = {
      ...this.props.addressLookup,
      streetAddress: value,
      value,
    };
    this.setState({
      selection: customSelection,
      value: value,
    });
    return customSelection;
  };

  /** will clear the error state and send event */
  clearError = (): void => {
    this.setState({ error: '' });
    this.props.clearError?.();
  };

  /** will update the error state and send event */
  updateError = (): void => {
    const error = validateStreetAddress(this.state.value, this.props.allowPOBox);
    this.setState({ error });
    this.props.setError?.(error);
  };

  /** on debounce, get address suggestions and populate the results for selection. */
  loadSuggestions = _debounce((addressInput: string) => {
    this.setState({ options: [] }); // clear the existing items first

    if (addressInput?.length > 0) {
      const addressLookup: Address = {
        ...this.props.addressLookup,
        streetAddress: addressInput,
      };

      this.setState({ loading: true });

      AddressApi.getPossibleAddresses(addressLookup)
        .then((suggestions) =>
          this.setState({
            options: suggestions?.map((opt) => ({ ...opt, value: opt.streetAddress })) || [],
            loading: false,
          }),
        )
        .finally(() =>
          this.setState({
            loading: false,
          }),
        );
    }
  }, ADDRESS_LOOKUP_DEBOUNCE_TIME);

  /** send event for parent to setValue when option has changed. */
  notifyValueChange = (opt: SelectionOption) => {
    const address: Address = {
      ...opt,
    };

    // remove SelectionOption fields not part of address to remove bad behavior
    delete address['value'];

    this.props.setValue(address);
  };

  /** as user is typing the street address, change the input states and perform typeahead lookup. */
  onKeyUpInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const addressInput = e.currentTarget?.value || '';
    // due to it being keyUp, pressing any arrow keys would trigger this. we want to only change when address was updated
    if (addressInput !== this.state.value) {
      this.props.onChangeInputNewOrderDeprecated?.(e); // deprecated

      this.setState({ value: addressInput });
      this.clearError();

      this.loadSuggestions.cancel(); // cancel existing call before continuing
      this.loadSuggestions(addressInput);
    }

    if (this.props.setStreetAddressValue) {
      this.props.setStreetAddressValue(addressInput);
    }
  };

  /** handler for when an option was selected. will update the selection and error. */
  onSelectOption = (opt: SelectionOption) => {
    if (opt) {
      this.setState({
        selection: opt,
        value: opt.value,
      });

      this.updateError();
      this.notifyValueChange(opt);
    }
  };

  /** handler for when the input is blurred. will update the selection and handler.
   * will update the selection based on what user has entered instead of what was selected. */
  onBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    // if blurring from field, make the selection based on what user entered manually instead
    const customSelection = this.selectEnteredUserInput(this.state.value);
    this.props.onBlurInputNewOrderDeprecated?.(e); // deprecated
    this.updateError();
    this.notifyValueChange(customSelection);
  };

  render = () => (
    <Select
      formField={true}
      required={true}
      autoComplete={true}
      autoCompleteFilterPredicate={() => true} // always show the autocomplete results, no matter what
      id={this.props.id}
      formFieldMessageId={this.props.formFieldMessageId}
      name={this.props.name}
      label={this.props.label}
      options={this.state.options}
      selected={this.state.selection}
      error={this.state.error}
      onKeyUp={this.onKeyUpInput}
      onSelect={this.onSelectOption}
      onBlur={this.onBlurInput}
      secondaryAction={
        this.state.loading && (
          <ProgressIndicator size="small" variant="circular" className="absolute right-0" />
        )
      }
      optionsListItemRenderer={(itemProps) => (
        <div
          className={classNames([
            'select-options-list-item',
            itemProps.highlighted
              ? 'select-options-list-item-highlighted'
              : 'select-options-list-item-default',
          ])}
        >
          <div className="text-body-long text-currentcolor line-clamp-none space-x-2">
            {itemProps.option ? (
              <>
                <span id="item--street-address">{itemProps.option.streetAddress}</span>
                <span className="text-gray">
                  {itemProps.option.city}, {itemProps.option.state} {itemProps.option.zip}
                </span>
              </>
            ) : (
              <>{itemProps.option.value}</>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default AddressSuggestionInput;
