import React from 'react';
import Modal from './Modal';
import SiteAdminRetrievePasswordResetLink from '@components/modal/subcomponents/SiteAdminRetrievePasswordResetLink';

const ModalSiteAdminRetrievePasswordResetLink = (props) => {
  return (
    <Modal isActive={props.isActive} onClose={props.onClose} heading="Password Reset Link">
      {props.isActive && <SiteAdminRetrievePasswordResetLink passwordLink={props.passwordLink} />}
    </Modal>
  );
};

export default ModalSiteAdminRetrievePasswordResetLink;
