import React from 'react';
import PlanCard, { PlanCardContent } from '@components/card/PlanCard';
import CardPlanPriceSummary from '@components/card/CardPlanPriceSummary';

interface CoverageContent {
  text: string;
  bolded?: boolean;
}

interface PlanCardsProps {
  onSelectCallback: (pvid: string) => void;
  plans: PlanCardContent[];
  selectedPlanID?: string;
  zestyBased?: boolean;
  /** replace with loading cards */
  loading?: boolean;
  /** count of how many loading cards to display. default 3 */
  loadingCount?: number;
  getIncludedCoverages?: any;
}

const PlanCards: React.FC<PlanCardsProps> = (props) => {
  return (
    <div className="w-full overflow-x-auto pb-8">
      <div className="h-full flex space-x-4 justify-center">
        {props.loading &&
          Array(props.loadingCount)
            .fill(null)
            .map((_, idx) => (
              <PlanCard
                key={`skeleton-${idx}`}
                selected={null}
                price={null}
                coverages={null}
                onSelect={null}
                bestCoverage={null}
                heading={null}
                pvid={null}
                subtext={null}
                loading={true}
                loadingCvgCnt={(idx + 1) * 3}
              />
            ))}
        {!props.loading &&
          props.plans.map((plan) => (
            <PlanCard
              key={plan.pvid}
              pvid={plan.pvid}
              heading={plan.heading}
              subtext={plan.subtext}
              paymentFrequency={plan.paymentFrequency}
              onSelect={() => props.onSelectCallback(plan.pvid)}
              price={plan.price}
              coverages={plan.coverages}
              selected={props.selectedPlanID === plan.pvid}
              bestCoverage={plan.bestCoverage}
              zestyBased={props.zestyBased}
              getIncludedCoverages={(pvid) => props.getIncludedCoverages(pvid)}
            />
          ))}
      </div>
    </div>
  );
};

PlanCards.defaultProps = {
  loadingCount: 3,
};

export default PlanCards;

const mockCoverages: CoverageContent[] = [
  {
    text: 'Cov 1 test test test test',
    bolded: false,
  },
  {
    text: 'Cov 2 test test test test',
    bolded: false,
  },
  {
    text: 'Cov 3 test test test test',
    bolded: true,
  },
  {
    text: 'Cov 4 test test test test one',
    bolded: false,
  },
  {
    text: 'Cov 5 test test test test two',
    bolded: false,
  },
  {
    text: 'Cov 6 test test test test asdfasdfasf',
    bolded: false,
  },
];

export const mockPlans = [
  {
    pvid: '1',
    heading: 'Plan 1',
    subtext: 'Test plan description',
    price: 625,
    coverages: mockCoverages,
    bestCoverage: true,
    selected: false,
  },
  {
    pvid: '2',
    heading: 'Plan 2',
    subtext: 'Test plan 2 description',
    price: 625,
    coverages: mockCoverages,
    bestCoverage: false,
    selected: true,
  },
  {
    pvid: '3',
    heading: 'Plan 3',
    subtext: 'Test plan 3 description',
    price: 625,
    paymentFrequency: 'month',
    coverages: mockCoverages,
    bestCoverage: false,
    selected: false,
  },
];
