import React, { useContext, useEffect, useMemo, useState } from 'react';
import Layout from '@components/layout/Layout';
import {
  GetZipCityStateHandler,
  PlansAndPricesFormData,
  PlansAndPricesFormHandler,
  PlansAndPricesTemplate,
} from '@templates/misc/PlansAndPricesTemplate';
import { PlanPriceSummary } from '@components/card/CardPlanPriceSummary';
import ProfileContext from '@context/ProfileContext';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import {
  CityStates,
  DwellingType,
  ProductAvailabilityRequest,
  ProductAvailabilityResponse,
  ProductFilters,
  ProductPricingDetailsRequest,
  ProductPricingResponse,
} from '@apis/models';
import AddressApi from '@apis/address.api';
import { getBrand } from '@helpers/brand.utils';
import { DEFAULT_AGE } from '@services/helpers';
import ProductApi from '@apis/product.api';
import { ContentBox } from '@components/layout/ContentBox';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import ZestyApi from '@apis/zesty.apis';
import { PlansAndPricesMetaData, ZestyPlansAndPricesCoverage } from '@apis/models/zesty.api.model';
import { OptionalCoverageItem } from '@components/card/CardPlanPriceCoverage';
import { QuoteSummarySelection } from '@components/misc/QuoteSummary';
import { Canceler } from 'axios';
import {
  PNP_GUEST_UNIT_COVERAGE_ID,
  PNP_GUEST_UNIT_NAME_MATCH,
} from '@constants/plansAndPrices-constants';
import PlansAndPricesQueryParamsService from '../../services/queryParamParserService/plansAndPricesQueryParams.service';
import { stringHasValue } from '../../services/validation/ValidationRules';
import { MILITARY_DISCOUNT } from '@constants/newOrder-constants';

export function findMatchRecordByName<T, R>(data: T, search: string): R {
  const key =
    data &&
    Object.keys(data).find((key) =>
      search.toUpperCase().replace(' ', '').includes(key.toUpperCase().replace(' ', '')),
    );
  return key ? data[key] : null;
}

export const mapWYSIWYGBulletToArray = (htmlString: string): string[] => {
  const list: string[] = htmlString.split('<li>').map((str) => str.split('</li>')[0]);
  list.shift(); // remove first element, which is the first <ul> tag
  return list;
};

const ProductApiCustom = ProductApi.new({
  suppressResponseErrorNotification: true,
  returnCancelErrorAsNull: false,
});

/** canceler used when form data has been changed */
let formDataCanceler: Canceler;
/** canceler used when plan selection has been changed */
let planSelectionCanceler: Canceler;
/** canceler used when quote data has been changed */
let quoteLoadCanceler: Canceler;

const PlansAndPrices: React.FC = () => {
  const { addErrorToQueue } = useGlobalAlert();

  const { profile } = useContext(ProfileContext);
  const brand = getBrand();

  const [plans, setPlans] = useState<PlanPriceSummary[]>([]);
  const [tradeServiceFee, setTradeServiceFee] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [initialFormData, setInitialFormData] = useState<PlansAndPricesFormData>(null);
  const [formData, setFormData] = useState<PlansAndPricesFormData>(null);
  const [zipState, setZipState] = useState<string>(null);

  const [loadingPageMeta, setLoadingPageMeta] = useState<boolean>(true);
  const [pageMeta, setPageMeta] = useState<PlansAndPricesMetaData>(null);
  const [loadingCoverageMeta, setLoadingCoverageMeta] = useState<boolean>(true);
  const [coverageMeta, setCoverageMeta] = useState<ZestyPlansAndPricesCoverage[]>([]);
  const [loadingPriceData, setLoadingPriceData] = useState<boolean>(false);
  const [pricingData, setPricingData] = useState<ProductPricingResponse[]>([]);

  const [selectedPlan, setSelectedPlan] = useState<PlanPriceSummary>(null);
  const [selectedCoverageItems, setSelectedCoverageItems] = useState<OptionalCoverageItem[]>([]);

  const [productRequest, setProductRequest] = useState<ProductAvailabilityRequest>(null);
  const [availableProducts, setAvailableProducts] = useState<ProductAvailabilityResponse>(null);

  const [productPricingDetailsRequest, setProductPricingDetailsRequest] =
    useState<ProductPricingDetailsRequest>(null);
  const [isFetchingProduct, setIsFetchingProduct] = useState<boolean>(true);

  /** the list of coverages for the selected plan. */
  const [coveragesList, setCoveragesList] = useState<OptionalCoverageItem[]>([]);
  /** holds the selection used for the quote. */
  const [quoteSummary, setQuoteSummary] = useState<QuoteSummarySelection>(null);
  /** holds coverages for see all coverages */
  const [includedCoveragesMap, setIncludedCoveragesMap] = useState<{
    [pvid: string]: string[];
  }>({});
  const [cityOptions, setCityOptions] = useState<CityStates[]>([]);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [planFilters, setPlanFilters] = useState<ProductFilters>(null);

  /** initialize page data */
  useEffect(() => {
    setLoadingPageMeta(true);
    ZestyApi.GetPlansAndPricesData(brand)
      .then((metaData) => {
        setPageMeta(metaData);
      })
      .catch((e) => {
        console.error('failed to load page meta', e);
      })
      .finally(() => {
        setLoadingPageMeta(false);
      });
    setLoadingCoverageMeta(true);
    ZestyApi.GetPlansAndPricesCoverages(brand)
      .then((data) => {
        setCoverageMeta(data);
      })
      .catch((e) => {
        console.error('failed to load page meta', e);
      })
      .finally(() => {
        setLoadingCoverageMeta(false);
      });
  }, []);

  /** unmount, clear up any cancel if any */
  useEffect(() => {
    return () => {
      formDataCanceler?.('unmounting');
      planSelectionCanceler?.('unmounting');
      quoteLoadCanceler?.('unmounting');
    };
  }, []);

  /** initialize the form data */
  useEffect(() => {
    const initialFormData: PlansAndPricesFormData = {
      zipCode: '',
      includeSellersCoverage: false,
      residenceType: DwellingType.SingleFamilyResidence,
    };

    //check for query string params
    PlansAndPricesQueryParamsService.init();
    const paramInstance = PlansAndPricesQueryParamsService.data;
    initialFormData.residenceType = paramInstance.residenceType;
    initialFormData.zipCode = paramInstance.zipCode;
    initialFormData.includeSellersCoverage = paramInstance.includeSellersCoverage;

    //if the zip is invalid/empty, get it from profile. ResidenceType and sellersCoverage are already set to default values
    if (!stringHasValue(initialFormData.zipCode) && profile) {
      const profileREOffices = getProfileOfficesByType(profile, OfficeType.RealEstate);
      initialFormData.zipCode = profileREOffices?.[0]?.address?.zip;
    }

    setInitialFormData(initialFormData);
  }, [profile]);

  /* handle selecting plan of same type after changes to plan input parameters  */
  useEffect(() => {
    if (coverageMeta) onPlanSelection(getSelectedPlan()); // wait until coverages are loaded
  }, [coverageMeta, plans]); // on plans load or associated coverages load

  /* if residence type or zip state was changed, we want to check restrictions again */
  useEffect(() => {
    setLoadingFilters(true);
    loadPlanFilters(formData?.residenceType, formData?.zipCode, zipState)
      .then(setPlanFilters)
      .finally(() => setLoadingFilters(false));
  }, [formData?.residenceType, zipState]);

  /** When the residence type changes, verify if the sellers coverage selection needs to change as well.
   *  If so, we want to disable the input and set the value to false. Otherwise, re-enable the input. */
  const disableSellersCoverageOption = useMemo(() => {
    const shouldEnableSellersCoverageOption = planFilters?.sellersCoverage;
    return !shouldEnableSellersCoverageOption;
  }, [planFilters]);

  const onSubmit: PlansAndPricesFormHandler = (v) => {
    setFormData(v);
    loadPlans(v);
  };

  const loadPlans = async (data: PlansAndPricesFormData): Promise<void> => {
    setLoading(true);
    setIsFetchingProduct(true);

    setPlans([]);
    setTradeServiceFee(null);

    // Attempt to fetch the state of the zip
    const { city, state: zipState, cityStates } = await getZipCityState(data.zipCode);
    setZipState(zipState);
    if (cityStates) {
      setCityOptions(cityStates);
    }
    // if not a valid zip or state for plans, then stop and return an empty list
    if (!isEligibleZipStateForPlans(data.zipCode, zipState)) {
      console.log('no plans for the ZIP/State', data.zipCode, zipState);
      setLoading(false);
      return;
    }

    try {
      const {
        plans: newPlans,
        serviceFee,
        priceData: newPriceData,
      } = await getPlansAndServiceFee(data, zipState, city);

      setPricingData(newPriceData);
      setPlans(newPlans);
      setTradeServiceFee(serviceFee);
      setLoading(false);
      setIsFetchingProduct(false);
    } catch (e) {
      if (ProductApiCustom.isCancellationError(e)) {
        console.debug('cancellation error', e);
        return;
      }
      console.error('failed to fetch plans', e);
      addErrorToQueue(msgs.PLAN_AND_PRICE_LOAD_FAILED);
      setLoading(false);
      setIsFetchingProduct(false);
    }
  };

  const getZipCityState: GetZipCityStateHandler = async (
    zip: string,
  ): Promise<{
    city: string;
    state: string;
    cityStates: CityStates[];
  }> => {
    try {
      const zipDetails: any = await AddressApi.getZipDetails(zip, false);
      const zipData = zipDetails?.zipCodes?.[0];
      return {
        cityStates: zipDetails?.cityStates || null,
        city: zipData?.defaultCity || null,
        state: zipData?.stateAbbreviation || null,
      };
    } catch (e) {
      console.error('failed to fetch zip details', e);
      return null;
    }
  };

  /** checks if the provided zip and state should be allowed to fetch plans */
  const isEligibleZipStateForPlans = (zip: string, state: string): boolean => {
    // TODO: temporarily allow all zips. Replace with ARE-8569 and make this logic async
    return true;
  };

  const getPlansAndServiceFee = async (
    data: PlansAndPricesFormData,
    state: string,
    city: string,
  ): Promise<{
    plans: PlanPriceSummary[];
    serviceFee: number;
    priceData: ProductPricingResponse[];
  }> => {
    // note, by default, ac coverage is true for non-ac states. this is just how it works
    const productRequest: ProductAvailabilityRequest = getProductAvailabilityRequest(
      data,
      state,
      city,
      true,
    );

    let mappedPlans: PlanPriceSummary[] = [];
    let serviceFee: number = 0;

    // make all the request calls and then start mapping the data
    formDataCanceler?.('fetching new plan data');
    const cancelSource = ProductApiCustom.createNewCancelTokenSource();
    formDataCanceler = cancelSource.cancel;
    const availableProducts = await ProductApiCustom.withRequestConfigAs({
      cancelToken: cancelSource.token,
    }).getProductAvailability(productRequest);
    const productsWithAC = availableProducts?.products || [];

    setProductRequest(productRequest);
    setAvailableProducts(availableProducts);
    // fetch prices for all products
    const priceData: ProductPricingResponse[] = await Promise.all(
      productsWithAC.map((product) =>
        ProductApiCustom.withRequestConfigAs({
          cancelToken: cancelSource.token,
        }).getProductPricingByProductId(product.starPVID, {
          property: productRequest?.property,
          options: {
            contractTermMonths: productRequest.options.contractTermMonths,
            listingTermInDays: product.listingTermInDays,
            earnixRuleID: null,
            initiatingOfficeID: productRequest?.office.id,
            initiatingOfficeFranchiseCode: null,
            specialDiscounts: null,
          },
        }),
      ),
    );

    //
    // start mapping to plan price summary
    //
    mappedPlans = productsWithAC
      .map(
        (productWithAC) =>
          ({
            pvid: productWithAC.starPVID,
            heading: productWithAC.name,
            price: priceData.find((price) => price.product.id === productWithAC.starPVID)?.product
              .price,
            subtext: null, // loaded in the template
            items: null, // loaded in the template
            serviceFee: productWithAC.serviceFees,
            hasSellersCoverage: productWithAC.sellersCoverage,
          }) as PlanPriceSummary,
      )
      .sort((a, b) => a.price - b.price);

    // grab the service fee for the first plan in the list
    serviceFee = mappedPlans[0]?.serviceFee;

    if (serviceFee === 0) {
      console.warn('serviceFee is 0');
    }
    if (!mappedPlans.every((p) => p.serviceFee === serviceFee)) {
      console.warn(
        'serviceFee do not equal in amount',
        mappedPlans.map((p) => p.serviceFee),
      );
    }

    return { plans: mappedPlans, serviceFee, priceData };
  };

  const getProductAvailabilityRequest = (
    data: PlansAndPricesFormData,
    state: string,
    city: string,
    includeACCoverage: boolean,
  ): ProductAvailabilityRequest => {
    return {
      office: {
        id: '111', // arbitrary office id to not get franchise products
      },
      property: {
        squareFootage: 4999,
        age: DEFAULT_AGE,
        residenceType: data.residenceType,
        address1: '111 plans and prices st', // arbitrary street address as it doesn't matter for this page
        address2: '',
        city,
        zip: data.zipCode,
        state,
      },
      options: {
        contractTermMonths: 12,
        contractListTimestamp: '',
        tenant: brand,
        sellersCoverage: data.includeSellersCoverage,
        acCoverage: includeACCoverage,
        roProductsOnly: false,
      },
    };
  };

  const loadPlanFilters = async (
    residenceType: DwellingType,
    zipCode: string,
    zipState: string,
  ): Promise<ProductFilters> => {
    if (residenceType && zipCode && zipState) {
      try {
        return await ProductApi.getFilters({
          age: DEFAULT_AGE,
          squareFootage: 4999,
          brand,
          franchiseCode: '',
          contractListTimestamp: '',
          roProductsOnly: false,
          typeOfResidence: residenceType,
          zip: zipCode,
          state: zipState,
        });
      } catch (e) {
        console.error('failed to load product filters', e);
      }
    }
    return null;
  };

  const getSelectedPlanPricingData = (pricing = pricingData, plan = selectedPlan) => {
    return plan && pricing.find((p) => p.product.id === plan.pvid);
  };

  const loadIncludedCoverages = (pvid: string): Promise<string[]> => {
    const storedCoverages = includedCoveragesMap[pvid];
    if (storedCoverages) {
      return new Promise((resolve) => {
        resolve(storedCoverages);
      });
    } else {
      return ProductApi.getProductCoveragesByProductId(pvid, formData.residenceType).then((res) => {
        const coverages = res.coverages.included
          .map((cvg) => cvg.name)
          .sort((a, b) => a.localeCompare(b));
        const test = includedCoveragesMap;
        test[pvid] = coverages;
        setIncludedCoveragesMap(test);
        return coverages;
      });
    }
  };

  const onPlanSelection = (plan: PlanPriceSummary, data = formData, pricing = pricingData) => {
    if (plan && coverageMeta) {
      setSelectedPlan(plan);

      const productPricingData = getSelectedPlanPricingData(pricing, plan);

      planSelectionCanceler?.('fetching new plan coverage');
      const cancelSource = ProductApiCustom.createNewCancelTokenSource();
      planSelectionCanceler = cancelSource.cancel;
      ProductApiCustom.withRequestConfigAs({ cancelToken: cancelSource.token })
        .getProductCoveragesByProductId(plan.pvid, data.residenceType)
        .then((res) => {
          // map the price and coverage to the coverage list
          const availableCvgs: OptionalCoverageItem[] = res.coverages.optional
            .map<OptionalCoverageItem>((cvg) => {
              const zestyData = coverageMeta.find((c) => c.coverage_id === cvg.starCoverageId);
              return {
                id: cvg.starCoverageId,
                price:
                  productPricingData?.optionalCoverages.find((c) => c.id === cvg.starCoverageId)
                    ?.price || 0,
                icon: zestyData?.optional_coverage_icon,
                name: zestyData?.updated_name || `<b>${cvg.name}</b>`,
                subtext: zestyData?.description || '<i>Not loaded</i>',
              };
            })
            .sort((a, b) => {
              const zestyDataA = coverageMeta.find((c) => c.coverage_id === a.id);
              const zestyDataB = coverageMeta.find((c) => c.coverage_id === b.id);
              return zestyDataA?.display_sequence - zestyDataB?.display_sequence;
            });

          setCoveragesList(availableCvgs);

          // Persist coverage selection if still exists
          const selectedCvgs = selectedCoverageItems.filter(
            (c) => !!availableCvgs.find((availableCvg) => availableCvg.id === c.id),
          );
          setSelectedCoverageItems(selectedCvgs);

          updateQuoteSummary(productPricingData, plan, selectedCvgs);
        })
        .catch((err) => {
          if (ProductApiCustom.isCancellationError(err)) {
            return;
          }
          console.error('failed to get coverage data', err);
        });
    }
  };

  const updateQuoteSummary = (
    priceData: ProductPricingResponse,
    selectedPlan: PlanPriceSummary,
    selectedCoverages: OptionalCoverageItem[],
  ) => {
    const pricingDetailsRequest: ProductPricingDetailsRequest = {
      property: productRequest?.property,
      options: {
        contractTermMonths: availableProducts?.products[0].contractTermMonths,
        listingTermInDays: availableProducts?.products[0].listingTermInDays,
        earnixRuleID: priceData.earnixRuleID,
        initiatingOfficeID: '',
        initiatingOfficeFranchiseCode: null,
        specialDiscounts: null,
      },
      selected: {
        productID: selectedPlan.pvid,
        guestUnitQuantity: selectedCoverages.filter(
          (cvg) =>
            cvg.id === PNP_GUEST_UNIT_COVERAGE_ID ||
            cvg.name.toLowerCase().includes(PNP_GUEST_UNIT_NAME_MATCH),
        ).length
          ? 1
          : 0,
        optionalCoverages: selectedCoverages.map((cvg) => ({ id: cvg.id, quantity: 1 })),
        groupCoverages: null,
        // groupCoverages: selectedPlanCoverages.groupCoverages.map(cvg => ({ id: cvg.id, quantity: cvg.quantity })),
      },
    };

    quoteLoadCanceler?.('fetching new quote summary');
    const cancelSource = ProductApiCustom.createNewCancelTokenSource();
    quoteLoadCanceler = cancelSource.cancel;
    setLoadingPriceData(true);
    ProductApiCustom.withRequestConfigAs({ cancelToken: cancelSource.token })
      .getProductPricingDetails(pricingDetailsRequest)
      .then((res) => {
        setProductPricingDetailsRequest(pricingDetailsRequest);
        setQuoteSummary({
          tax: {
            price: res.pricing.tax,
            rate: res.details.taxRate,
            taxableAmount: res.details.taxableAmount,
          },
          total: res.pricing.totalDueAtClosing,
          plan: {
            id: selectedPlan.pvid,
            name: selectedPlan.heading,
            price: selectedPlan.price,
          },
          coverages: selectedCoverages.map((c) => ({
            id: c.id,
            price: res.optionalCoverages.find((cov) => cov.id === c.id)?.price,
            name: c.name,
          })),
          sellersCoverage: {
            total: res.pricing.sellersCoverage,
            amount: res.details.dailyRate,
            days: res.details.listingDays,
          },
          hasSellersCoverage: selectedPlan.hasSellersCoverage,
          militaryDiscountApplied: res.appliedSpecialDiscounts.includes(MILITARY_DISCOUNT),
          discountAmount: res.pricing.appliedPromotion,
        });
      })
      .catch((err) => {
        if (ProductApiCustom.isCancellationError(err)) {
          return;
        }
        console.error('failed to get quote price', err);
      })
      .finally(() => setLoadingPriceData(false));
  };

  const onCoverageSelection = (cvg: OptionalCoverageItem) => {
    const updatedSelectedCvgs = selectedCoverageItems.filter(
      (selectedCvg) => selectedCvg.id !== cvg.id,
    );
    if (updatedSelectedCvgs.length === selectedCoverageItems.length) {
      updatedSelectedCvgs.push(cvg);
    }
    setSelectedCoverageItems(updatedSelectedCvgs);
    updateQuoteSummary(getSelectedPlanPricingData(), selectedPlan, updatedSelectedCvgs);
  };

  const getSelectedPlan = () => {
    /** default the plan selection to ShieldComplete */
    if (!selectedPlan && plans.length > 0) {
      setSelectedPlan(plans.filter((plan) => plan.heading === 'ShieldComplete')[0]);
    }

    const plan = plans.find((plan) => {
      return plan.pvid === selectedPlan?.pvid || plan.heading === selectedPlan?.heading;
    });

    return plan;
  };

  return (
    <Layout isLoggedIn={true} slug="plans-and-prices">
      <ContentBox
        title="Plans & Prices"
        subtitleHtml={pageMeta?.page_header_subtext}
        applyContainerWidthToHeaderOnly={true}
        className="bg-gray-100"
      >
        <PlansAndPricesTemplate
          loadingPageMeta={!!loadingPageMeta && !!pageMeta}
          loading={loading || isFetchingProduct}
          loadingPrice={loadingPriceData}
          initialFormData={initialFormData}
          formData={formData}
          cityOptions={cityOptions}
          plans={plans}
          tradeServiceFee={tradeServiceFee}
          onSubmit={onSubmit}
          getZipCityState={getZipCityState}
          meta={pageMeta}
          autoSubmitForm={true}
          onPlanSelection={onPlanSelection}
          onCoverageSelection={onCoverageSelection}
          setSelectedCoverageItems={setSelectedCoverageItems}
          selectedPlan={getSelectedPlan()}
          setSelectedPlan={setSelectedPlan}
          optionalCoverages={coveragesList}
          selectedCoverages={selectedCoverageItems}
          quoteSummary={quoteSummary}
          productPricingDetailsRequest={productPricingDetailsRequest}
          getIncludedCoverages={loadIncludedCoverages}
          loadingFilters={loadingFilters}
          disableSellersCoverageOption={disableSellersCoverageOption}
        />
      </ContentBox>
    </Layout>
  );
};

export default PlansAndPrices;
