import React, { useEffect, useState } from 'react';
import AgentAutosuggest from '@components/form/AgentAutosuggest';
import AgentApi from '@apis/agent.api';
import { officeTypeDictionary } from '@constants/dictionaries';
import ModalAddAgentToOffice from '@components/modal/ModalAddAgentToOffice';
import { selectSuggestion } from '@services/autosuggest/autosuggest';
import { fireGAEvent } from '@app/core/tracking.service';
import { ADD_EXISTING_AGENT, ADD_NEW_AGENT } from '@constants/ga-events.constants';

const InitiatingAgentInput: React.FC<any> = (props) => {
  const [typeAheadAddress, setTypeAheadAddress] = useState([]);
  const [selectedAgent, changeSelectedAgent] = useState('');
  const [initiatingOffice, setinitiatingOffice] = useState(props.selectedOffice);
  const [agentList, setAgentList] = useState([]);
  const [isAddAgentModalActive, setIsAddAgentModalActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  function addNewAgentToOffice(agent) {
    setAgentList([...agentList, agent]);
    const updatedValues = {
      ...props.values,
      agentlist: [...props.values.agentlist, agent],
    };
    // Make the selection (done this way so that we are sync up with the auto-suggest logic)
    selectSuggestion(
      undefined,
      { suggestion: agent },
      changeSelectedAgent,
      props.setValues,
      updatedValues,
    );
  }

  async function getAgentsForOffice(office) {
    let agentsList = [];
    if (office && office.id !== '') {
      try {
        // getting list of agent as per selcted office
        const response = await AgentApi.searchAgents(
          {
            officeId: office.id,
            officeType: officeTypeDictionary[office.type],
            activeInactive: 'A',
          },
          {
            tags: { source: 'CardNewOrderAgentInfo#getAgentsForOffice' },
          },
        );
        agentsList = response?.agentsList;
        setIsDisabled(false);
      } catch (err) {
        // ignore, use empty agents list instead
        console.log(err);
        console.log('failed to get agents for office. Using empty agent list instead.');
      }
    } else {
      setIsDisabled(true);
    }
    return agentsList;
  }

  useEffect(() => {
    changeSelectedAgent('');
    const fetchAgentList = async () => {
      try {
        const agentList = await getAgentsForOffice(props.values.agentOffice);
        if (agentList) {
          setAgentList(agentList);
        }
      } catch (err) {
        // ignore, use empty agents list instead
        console.error(err);
        console.log('could not get agents for office, using empty list instead.');
      }
    };
    fetchAgentList().catch(console.error);
  }, [props.values.agentOffice]);

  useEffect(() => {
    if (selectedAgent === '' && !isDisabled) {
      props.setValues({ ...props.values, initAgentId: '' });
      props.onAgentError();
    }

    if (props.values.AgentEmail && props.values.AgentPhone.phoneNumber) {
      props.onSelectContactChange(props.values.AgentEmail, props.values.AgentPhone.phoneNumber);
    } else if (props.values.AgentEmail) {
      props.onSelectContactChange(props.values.AgentEmail, '');
    }
  }, [selectedAgent]);

  return (
    <>
      <div>
        <AgentAutosuggest
          id={props.id}
          isRequired={props.required}
          typeAheadAddress={typeAheadAddress}
          setTypeAheadAddress={setTypeAheadAddress}
          selectedAgent={selectedAgent}
          changeSelectedAgent={changeSelectedAgent}
          agentHelpText={props.errors}
          agentOffice={initiatingOffice}
          agentlist={agentList}
          placeholder={props.label}
          setValues={props.setValues}
          values={props.values}
          addAgentEnabled={true}
          setIsAddAgentModalActive={(e) => setIsAddAgentModalActive(e)}
          isDisabled={isDisabled}
          hasNotApplicableOption={
            props.hasNotApplicableOption ? props.hasNotApplicableOption : false
          }
        />
      </div>
      <ModalAddAgentToOffice
        id="modal-add-initiating-agent"
        isActive={isAddAgentModalActive}
        office={props.values.agentOffice}
        agents={agentList}
        onCancel={() => setIsAddAgentModalActive(false)}
        onSuccess={(agent) => {
          addNewAgentToOffice(agent);
          setIsAddAgentModalActive(false);
        }}
      />
    </>
  );
};

export default InitiatingAgentInput;
