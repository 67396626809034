import React from 'react';
import { ModalProps } from '@components/modal/Modal';
import ModalChoice from './ModalChoice';
import RemoveClosingInfo from '@components/modal/subcomponents/RemoveClosingInfo';

interface Props extends ModalProps {
  onConfirm: () => void;
  onClose: () => void;
}

const ModalRemoveClosingInfo: React.FC<Props> = ({
  id = 'remove-closing-info-modal',
  heading = 'Clear Closing Information',
  ...props
}) => {
  return (
    <ModalChoice
      id={id}
      heading={heading}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      yesText="Continue"
      noText="Cancel"
      {...props}
    >
      {props.isActive && <RemoveClosingInfo />}
    </ModalChoice>
  );
};

export default ModalRemoveClosingInfo;
