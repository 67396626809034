import React from 'react';
import { Link } from '@ftdr/blueprint-components-react';

const SiteAdminRetrievePasswordResetLink = (props) => {
  return (
    <div style={{ overflowWrap: 'break-word' }}>
      <Link href={props.passwordLink}>{props.passwordLink}</Link>
    </div>
  );
};

export default SiteAdminRetrievePasswordResetLink;
