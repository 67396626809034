import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSection,
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
  Notification,
  Pagination,
} from '@ftdr/blueprint-components-react';
import PanelContractSummary from '@components/panel/PanelContractSummary';
import { Contract, REOrder, REOrderDataFilterCode, REOrderSearchRequest } from '@apis/models';
import _RealEstateOrderApi from '@apis/realestateorder.api';
import { isMobileView } from '@utils';
import { ITEMS_PER_PAGE_ACTION } from '@constants/dashBoard-constants';
import DashboardActionSwitcher, {
  DashboardActionSearchType,
} from '@components/drawer/subcomponents/DashboardActionSwitcher';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import { ContractSearchBarHeaderContentRendererFunc } from '@components/drawer/subcomponents/ContractSearchBar';
import { SECOND } from '@helpers/utils';
import msgs from '@app/locales/en';
import AccordionItemSendRenewal from '@components/accordion/AccordionItemSendRenewal';
import { ContractApiSuppressErrors } from '@apis/contract.api';

const RealEstateOrderApi = _RealEstateOrderApi.new({
  suppressAllErrorNotifications: true,
});

const OrdersSendRenewal = (props) => {
  const [hasFetchedContractData, setHasFetchedContractData] = useState(false);
  const [pageContractData, setPageContractData] = useState<REOrder[]>([]);
  const [pageContractDetails, setPageContractDetails] = useState<Contract[]>([]);
  const [totalContracts, setTotalContracts] = useState(props.initialTotalContracts);
  const [activePage, setActivePage] = useState(1);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState([]);
  const [lastCompletedContractId, setLastCompletedContractId] = useState(undefined);
  const [lastCompletedIsSuccess, setLastCompletedIsSuccess] = useState(undefined);
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [errorMessageActive, setErrorMessageActive] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<DashboardActionSearchType>(
    DashboardActionSearchType.ShowAll,
  );

  const isMobile = isMobileView();

  const contractHeaderRenderer =
    (contract, contractDetails, advancedSearchText = '') =>
    (renderProps) => {
      const toggleAction = async () => {
        if (contractDetails.features?.upForRenewal?.canSendPromoEmail) {
          setAccordionActiveIndex(renderProps.active ? [] : [renderProps.index]);
        }
      };

      return (
        <PanelContractSummary
          key={renderProps.key}
          contract={contract}
          contractDetails={contractDetails}
          idPrefix="order-send-renewal"
          actions={[
            <IconButton
              label=""
              key="chevron"
              icon={renderProps.active ? <IconNavArrowUp /> : <IconNavArrowDown />}
              variant="ghost"
              size={isMobile ? 'small' : 'medium'}
              onClick={toggleAction}
            />,
          ]}
          onClickContract={toggleAction}
          showExpiryDate={true}
          showStatus={true}
          showBuyer={true}
          query={advancedSearchText}
          disabled={!contractDetails.features?.upForRenewal?.canSendPromoEmail}
        />
      );
    };

  /** renders the orders into an accordion with action. Shared display. */
  const renderREOrderResults: ContractSearchBarHeaderContentRendererFunc = (
    orders,
    searchState,
  ) => (
    <Accordion
      mode="single"
      id="accordion-orders-send-renewal"
      activeIndex={accordionActiveIndex}
      onActivate={(e, ais) => setAccordionActiveIndex(ais)}
      separatorClassName="hidden"
    >
      {orders.map((contract) => (
        <AccordionSection
          className="w-full"
          headerRenderer={contractHeaderRenderer(
            contract,
            pageContractDetails.find((c) => c?.meta?.ID === contract.id.toString()),
            searchState?.searchTerm,
          )}
          label=""
          key={contract.id}
          disabled={!pageContractDetails.find((c) => c?.meta?.ID === contract.id.toString())?.features?.upForRenewal?.canSendPromoEmail}
        >
          <AccordionItemSendRenewal
            contract={contract}
            contractDetails={pageContractDetails.find(
              (c) => c?.meta?.ID === contract.id.toString(),
            )}
            onCompleteItem={handleCompleteItem}
            onCancel={() => setAccordionActiveIndex([])}
          />
        </AccordionSection>
      ))}
    </Accordion>
  );

  const handleCompleteItem = (contractId, isSuccess) => {
    setHasFetchedContractData(false);
    setActivePage(Math.min(activePage, Math.floor(totalContracts / ITEMS_PER_PAGE_ACTION) + 1));
    setAccordionActiveIndex([]);
    setLastCompletedContractId(contractId);
    setLastCompletedIsSuccess(isSuccess);
    setIsNotificationActive(true);
    if (props.onRenewal && isSuccess) props.onRenewal(contractId);
  };

  const fetchContractData = async () => {
    const request = new REOrderSearchRequest(ITEMS_PER_PAGE_ACTION);
    request.meta.page = activePage - 1;
    request.data.filters = [REOrderDataFilterCode.contractRenewal];
    request.meta.sortBy = 'expirationDate';
    request.meta.asc = true;

    const response = await RealEstateOrderApi.searchProfileContracts(request);
    if (!response) {
      setErrorMessage(msgs.DASHBOARD_ACTION_CONTRACT_LOAD_ERROR_GENERIC.message);
      setErrorMessageActive(true);
      return;
    }

    const contractDetails = await ContractApiSuppressErrors.getContractDetails(
      response.orders.map((o) => o.id.toString()),
      true,
      true,
    );

    setPageContractData(response.orders);
    setPageContractDetails(contractDetails);

    setTotalContracts(response.meta.total);
    props.onUpdateOrderCount(response.meta.total);

    setHasFetchedContractData(true);
  };

  useEffect(() => {
    setAccordionActiveIndex([]);
    setHasFetchedContractData(false);
  }, [activePage]);

  useEffect(() => {
    if (!hasFetchedContractData) {
      fetchContractData();
    }
  }, [hasFetchedContractData]);

  const changeSearchType = (newSearchType: DashboardActionSearchType) => {
    // only when view changes, we want to refresh the state
    if (newSearchType !== searchType) {
      setAccordionActiveIndex([]); // reset selections
      setSearchType(newSearchType);
    }
  };

  return (
    <div className="full-height-card">
      <DashboardActionSwitcher
        id="drawer-orders-send-renewal"
        heading="Eligible for Renewal Promo"
        totalContractsCount={totalContracts}
        includeAdvancedSearch={false}
        searchType={searchType}
        onChangeSearchType={changeSearchType}
        contentRenderer={(searchType) => (
          <>
            {isNotificationActive && (
              <Notification
                inline={true}
                status={lastCompletedIsSuccess ? 'success' : 'error'}
                className="max-w-full mb-4"
                autoCloseDelay={5 * SECOND}
                onClose={() => setIsNotificationActive(false)}
              >
                {lastCompletedIsSuccess ? (
                  <span>Email send request has been submitted and is being processed.</span>
                ) : (
                  <span>Uh oh, there was a problem sending renewal emails. Please try again.</span>
                )}
              </Notification>
            )}

            {errorMessageActive && (
              <Notification
                inline={true}
                status="error"
                className="max-w-full mb-4"
                autoCloseDelay={5 * SECOND}
                onClose={() => {
                  setErrorMessageActive(false);
                  setErrorMessage(undefined);
                }}
              >
                {errorMessage}
              </Notification>
            )}

            {searchType === DashboardActionSearchType.ShowAll && (
              <>
                {hasFetchedContractData ? (
                  renderREOrderResults(pageContractData)
                ) : (
                  <LoadingIndicator />
                )}
                <Pagination
                  onItemsPerPageChange={null}
                  itemsPerPage={ITEMS_PER_PAGE_ACTION}
                  itemsPerPageOptions={[
                    {
                      value: ITEMS_PER_PAGE_ACTION.toString(),
                      label: ITEMS_PER_PAGE_ACTION.toString(),
                    },
                  ]}
                  onPageChange={(page) => setActivePage(page)}
                  totalItems={totalContracts}
                  page={activePage}
                  hideViewAll={true}
                />
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

export default OrdersSendRenewal;
