import '@babel/polyfill';

import './css/root.tailwind.css';
import './css/root.app.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Path from '@constants/paths';
import App from './App';
import { getConfig, loadConfigs } from '@services/app.config.loader';
import CombinedProvider from './context/CombinedContext';
import { initFeatureToggles } from '@app/core/featureToggle';
import { loadPolyfills } from '@helpers/polyfills';

declare let __PROJECT_BUILD_DATE__;
declare let __GIT__VERSION__;
declare let __GIT__COMMITHASH__;
declare let __GIT__BRANCH__;

// TODO Move this out
function serviceConfigs() {
  const configs = [
    {
      nickname: 'FusionAuth',
      authority: getConfig('IdentityProviderBaseUrl'),
      client_id: getConfig('IdentityProviderClientID'),
      debug: true,
      metadata_uri: `${getConfig('IdentityProviderBaseUrl')}${getConfig('IdentityIssuerMetadata')}${getConfig('IdentityProviderTenantID')}`,
      post_logout_redirect_uri: window.location.origin,
      redirect_uri: `${window.location.origin}${Path.Callback}`,
      scope: 'openid email profile',
      extraQueryParams: {
        tenantId: getConfig('IdentityProviderTenantID'),
      },
    },
    {
      nickname: 'Corporate',
      authority: `${getConfig('CorporateBaseUrl')}${getConfig('CorporateTenantID')}`,
      client_id: getConfig('CorporateClientID'),
      debug: true,
      metadata_uri: `${getConfig('CorporateBaseUrl')}${getConfig('CorporateTenantID')}${getConfig('CorporateMetadata')}`,
      post_logout_redirect_uri: window.location.origin,
      redirect_uri: `${window.location.origin}${Path.Callback}`,
      scope: 'openid email profile',
    },
  ];

  return configs;
}

loadConfigs().then(() => {
  initFeatureToggles()
    .then(() => {
      loadPolyfills();
      ReactDOM.render(
        <CombinedProvider serviceConfigs={serviceConfigs()}>
          <App />
        </CombinedProvider>,
        document.getElementById('root'),
      );
      console.log('Build=', new Date(__PROJECT_BUILD_DATE__));
      console.log('Git=', __GIT__VERSION__, __GIT__COMMITHASH__, __GIT__BRANCH__);
    })
    .catch((e) => {
      console.error(e, 'Failed to initialize config');
    });
});
