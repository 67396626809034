import React, { useEffect } from 'react';
import { Radio, Text } from '@ftdr/blueprint-components-react';
import {
  ACTION_ADD_AS_COOPERATING_AGENT,
  ACTION_CONTACT_SUPPORT,
} from '@constants/newOrder-constants';
import { ContractDetail } from '@apis/models';
import { propertyObjectToString } from '@services/helpers';
import { useNavigation } from 'react-navi';
import Path from '@constants/paths';

interface Props {
  setRadio: (string) => void;
  selectedRadio: string;
  contract: ContractDetail;
}

const AssociateAgent: React.FC<Props> = (props) => {
  const navigate = useNavigation();

  useEffect(() => {
    if (!props.contract) {
      navigate.navigate(Path.Dashboard);
      return;
    }
  }, [props.contract]);

  const getLabel = () => {
    let type = '';

    if (props.contract?.initiatingOfficeAgent?.represents === 'SELLER') {
      type = 'Buyer';
    } else {
      type = 'Seller';
    }

    return `Add me as the ${type} Agent on this order`;
  };

  return (
    <>
      <Text variant="heading-06">Order ID: {props.contract.id}</Text>
      <Text variant="heading-06">Address: {propertyObjectToString(props.contract.property)}</Text>
      <div className="w-full px-4 py-2 -mb-1">
        <div>
          <Radio
            label={getLabel()}
            name="cooperatingAgent"
            id="radio-Add-me"
            value={ACTION_ADD_AS_COOPERATING_AGENT}
            checked={props.selectedRadio === ACTION_ADD_AS_COOPERATING_AGENT}
            onChange={(e) => props.setRadio(e.target.value)}
            labelPlacement="right"
            width="auto"
            color="interactive"
          />
        </div>
        <div>
          <Radio
            label="Contact Support"
            name="contact-support"
            id="radio-contact-suppoer"
            value={ACTION_CONTACT_SUPPORT}
            checked={props.selectedRadio === ACTION_CONTACT_SUPPORT}
            onChange={(e) => props.setRadio(e.target.value)}
            labelPlacement="right"
            width="auto"
            color="interactive"
          />
        </div>
      </div>
    </>
  );
};

export default AssociateAgent;
