import React from 'react';
import getBrandConstants from '@constants/brands';
import Path from '@constants/paths';
import { Message } from '@app/core/GlobalAlertModal';
import { getBrand } from '@helpers/brand.utils';

const msgs = {
  CHECK_ZIP_HAS_PLANS_FAILED: {
    title: 'Oops! we have a problem',
    messageHTML: (
      <div>
        We {"couldn't"} tell if your zip has plans available yet due to an error. Please try again
        later or contact our{' '}
        <a href={Path.ContactUs} className="underline">
          support team
        </a>{' '}
        for assistance.
      </div>
    ),
  },
  NEW_ORDER__CREATE_UNVERIFIED_ADDRESS_FAILED: {
    title: 'Something went wrong',
    messageHTML: (
      <div>
        Sorry, your address could not be saved. Please try again in a few minutes or{' '}
        <a href={Path.ContactUs} className="underline">
          Contact Support
        </a>
      </div>
    ),
  },
  BUYER_INFO_REQUIRED: {
    title: 'Buyer Information Required',
    message: `Closing information requires buyer information. Once both pieces of information are filled out, they will be saved.
      Leaving the page will not save the information.`,
  },
  CLOSING_INFO_REQUIRED: {
    title: 'Closing Information Required',
    message: `Buyer information requires closing information. Once both pieces of information are filled out, they will be saved.
      Leaving the page will not save the information.`,
  },
  PLANS_AND_COVERAGES_PENDING_PROPERTY_INFO: {
    message: 'The property details have changed. Please select a new plan.',
  },
  EDIT_ORDER_FLOW_SELLER_INFO_REQUIRED: {
    message: `A plan with seller's coverage requires seller information. Once both pieces of information are filled out, they will be saved.
      Leaving the page will not save the information.`,
  },
  NEW_ORDER_FLOW_SELLER_INFO_REQUIRED: {
    message:
      "A plan with seller's coverage requires seller information. Once both pieces of information are filled out, you can resubmit your order.",
  },
  UPDATE_BUYER_CLOSING_SUCCESS: {
    message: 'The buyer and closing information has been updated.',
  },
  UPDATE_BUYER_SUCCESS: {
    message: 'The buyer information has been updated.',
  },
  UPDATE_BUYER_FAILURE: {
    message: 'The buyer information was not saved. Please resubmit this information.',
  },
  UPDATE_CLOSING_SUCCESS: {
    message: "The contract's closing information has been updated.",
  },
  CLEAR_CLOSING_FAILURE: {
    message: 'The closing information was not cleared. Please resubmit the closing information.',
  },
  UPDATE_CLOSING_FAILURE: {
    message: 'The closing information was not updated. Please resubmit the closing information.',
  },
  DELETE_CLOSING_SUCCESS: {
    message: "Your contract's closing information and buyer information has been cleared.",
  },
  EXTEND_LISTING_SUCCESS: {
    message: 'Listing successfully extended.',
  },
  FAILED_TO_EXTEND_LISTING: {
    title: 'Failed to extend listing',
    message: 'An error occurred in extend listing.',
  },
  FAILED_TO_LOAD_CONTRACT: {
    title: 'Failed to load',
    message: 'An error occurred when loading the contract.',
  },
  FAILED_TO_LOAD_ORDER: {
    title: 'Failed to load',
    message: 'An error occurred when loading the order.',
  },
  UPDATE_AGENT_SUCCESS: {
    message: 'The agent information has been updated.',
  },
  UPDATE_AGENT_FAILURE: {
    message: 'The agent information was not saved. Please resubmit this information.',
  },
  FAILED_TO_LOAD_CONTRACT_PRICING: {
    title: 'Failed to Load Pricing',
    message: "Failed to load the contract's order summary.",
  },
  FAILED_TO_LOAD_CONTRACT_PLAN_COVERAGES: {
    title: 'Failed to Load Plan',
    message: "Failed to load the contract's plans and coverages.",
  },
  FAILED_TO_LOAD_TAX: {
    title: 'Failed to Load Pricing',
    message: 'Failed to load the tax',
  },
  PRODUCT_FILTER_FAILED: {
    id: 'error_no-plan-filter',
    message: 'Something went wrong when loading plan options.',
  },
  NO_PLANS_FOUND: {
    id: 'no-plans-found',
    title: 'No Plans Found',
    message: `We're sorry, but we do not have any plans available for this property.  If you believe this is in error, please check that the address and property details provided are correct or call ${
      getBrandConstants().supportPhone
    }.`,
  },
  NO_PLAN_TERM_SELECTED: {
    id: 'error_no-plan-term-selected',
    message: 'Warranty Term Length must be selected before continuing.',
  },
  NO_PLAN_SELECTED: {
    id: 'error_no-plan-selected',
    message: 'A Plan must be selected before continuing.',
  },
  ADDRESS_CHANGE_NEW_PLAN_REQUIRED: {
    message: 'The property address has changed. Please select a new plan.',
  },
  PROPERTY_CHANGE_NEW_PLAN_REQUIRED: {
    message: 'The property details have changed. Please select a new plan.',
  },
  INITIATING_OFFICE_FRANCHISE_CHANGE_NEW_PLAN_REQUIRED: {
    message: 'The initiating office has changed requiring a new plan selection. Select a new plan.',
  },
  REALTOR_OWNED_PROPERTY_SELECTION_NEW_PLAN_REQUIRED: {
    message: "You've indicated that the property is realtor owned. Please select a new plan.",
  },
  REALTOR_OWNED_PROPERTY_DESELECTION_NEW_PLAN_REQUIRED: {
    message:
      "You've indicated that the property is no longer realtor owned. Please select a new plan.",
  },
  PAYMENT_SUCCESSFUL: {
    id: 'drawer-make-payment__modal--payment-succesful',
    title: 'Payment Submitted',
    message: 'Your payment has successfully been submitted.',
  },
  PAYMENT_FAILED_ERROR: {
    message: `Your payment was unsuccessful. Please resubmit your payment or call ${
      getBrandConstants().supportPhone
    }`,
  },
  PAYMENT_FAILED_CONTACT_SUPPORT_ERROR: {
    title: 'Something went wrong',
    messageHTML: (
      <div>
        We had an issue processing your payment. Please try again or{' '}
        <a href={Path.ContactUs} className="underline">
          contact support
        </a>
      </div>
    ),
  },
  FAILED_TO_LOAD_CONTRACT_BALANCE: {
    message: 'We were unable to calculate the contract balance. Please try again.',
  },
  ADD_AGENT_OFFICE_MISSING: {
    message: 'Something went wrong when trying to load the office.',
  },
  AGENT_EXISTS_IN_OFFICE: {
    message:
      'The selected agent already exists in this office. Please select the agent to continue.',
  },
  ADD_AGENT_OFFICE_INVALID: {
    message: 'We are unable to add an agent to an invalid office. Please select a valid office.',
  },
  PROFILE_EXISTS_ERROR: {
    id: 'profile-exists',
    title: 'Profile Exists',
    message: 'Account already exists with this name and phone number. Contact our support team.',
    actions: [
      {
        href: Path.ContactUs,
        label: 'Contact Us',
      },
    ],
  },
  PROFILE_SUBMIT_ERROR: {
    id: 'profile-submit-error',
    message: 'I’m sorry but our system was not able to submit your request',
  },
  SIGN_WLK_AGREEMENTS_SUCCESS: {
    message: 'Agreement has successfully been signed.',
  },
  SIGN_WLK_AGREEMENTS_FAILURE: {
    message: 'A problem has occurred when signing agreements.',
  },
  PAYMENT_CHECKS_LOAD_FAILURE: {
    message: 'We are unable to load your payment checks. Please try again.',
  },
  SAVE_PROFILE_ERROR: {
    id: 'edit-account-submiterror',
    message: 'Unable to save profile information. Please try again later.',
  },
  PROFILE_UPDATE_SUCCESS: {
    id: 'edit-account-success',
    message: 'Profile Successfully Updated.',
  },
  REMOVE_OFFICE_SUCCESS: {
    id: 'edit-account-office-removed',
    message: 'Office has been removed.',
  },
  REMOVE_OFFICE_ERROR: {
    id: 'edit-account-office-remove-error',
    message: `There has been a system error. Please try again. If issue persists, please contact ${
      getBrandConstants().supportEmail
    }.`,
  },
  EMAIL_SENDING_ERROR: {
    message: 'We are unable to submit your support request. Please try again.',
  },
  WLK_SUBMIT_SUCCESS: {
    message: 'Warranties have been submitted.',
  },
  WLK_SUBMIT_ERROR: {
    message: 'I’m sorry but our system was not able to submit your request.',
  },
  WLK_SUBMIT_ERROR_DASHBOARD: {
    message: 'Uh oh, there was a problem submitting the order for WarrantyLink. Please try again.',
  },
  EMAIL_SENT_SUCCESS: {
    id: 'orders-container--notifications',
    message: 'Emails have been sent.',
  },
  EXTEND_ORDER_ERROR_DASHBOARD: {
    messageHTML: (
      <>
        This Order has Seller’s coverage and one or more service requests submitted, so it cannot be
        extended online. Please call us at {getBrandConstants().supportPhone} or{' '}
        <a href={Path.ContactUs} className="underline">
          contact support
        </a>{' '}
        to extend this Order.
      </>
    ),
  },
  PLAN_AND_PRICE_LOAD_FAILED: {
    id: 'plan-price-load-error',
    message: 'Something went wrong when loading the plans.',
  },
  NO_NEW_CONSTRUCTION_FOR_RO: {
    id: 'no-new-construction-for-ro',
    message:
      'New construction homes are not eligible when the home service plan is ordered for yourself. This selection will be removed from your order',
  },
  SHARE_PLAN_PRICE_QUOTE_FAILED: {
    id: 'share-plan-price-quote-failed',
    messageHTML: (
      <>
        Something went wrong and we are unable to send the email. Please try again or{' '}
        <a href={Path.ContactUs} className="underline">
          Contact Support
        </a>
        .
      </>
    ),
  },
  EMAIL_TAKEN: (email) => {
    return {
      id: 'edit-account-submiterror',
      message: `${email}  is already in use. Enter a new email.`,
    };
  },
  DELETE_FA_USER_SUCCESS: (email) => {
    return {
      message: `Successfully deleted user account for ${email}`,
    };
  },
  DELETE_FA_USER_FAILURE: (email) => {
    return {
      message: `There was an error deleting user account for ${email}`,
    };
  },
  DASHBOARD_ACTION_CONTRACT_LOAD_ERROR_GENERIC: {
    message:
      'We encountered a temporary error and could not complete your request.  Please try again.',
  },
  PAST_CLOSING_DATE_SELECTED: {
    message: "You've indicated that the projected closing date is in the past.",
  } as Message,
  QUOTE_CANCELLATION_NOT_ALLOWED: {
    message: `This order cannot be cancelled.`,
  },
};

export const msgsWParams = {
  WLK_SUBMIT_IDS_SUCCESS: (contractIDs: string[]) => ({
    messageHTML: (
      <div>
        {contractIDs.length === 1 ? (
          <>
            Success! Order <strong>{contractIDs.join('')}</strong> has been updated and verified.
          </>
        ) : (
          <>
            Success! Orders <strong>{contractIDs.join(', ')}</strong> have been updated and
            verified.
          </>
        )}
      </div>
    ),
  }),
  WLK_INVITE_SUCCESS: (email) => {
    return {
      message: `The WarrantyLink invitation was successfully sent to ${email}.`,
    };
  },
  WLK_INVITE_ERROR: (email) => {
    return {
      message: `The WarrantyLink invitation was not sent to ${email}.`,
    };
  },
  WLK_REMOVE_SUCCESS: (removedOffice) => {
    return {
      message: `WarrantyLink was successfully removed from ${removedOffice}.`,
    };
  },
  WLK_REMOVE_ERROR: (removedOffice) => {
    return {
      message: `WarrantyLink was not removed from ${removedOffice}.`,
    };
  },
  DOCUMENTS_SENT_SUCCESS: (recipients) => {
    return {
      id: 'orders-container--notifications',
      message: `Success! The invoice was sent to ${formatEmailMessage(recipients)}`,
    };
  },
  DOCUMENTS_SENT_SUCCESS_BY_DOC_TYPE: (recipients, docType) => {
    return {
      id: 'orders-container--notifications-confirmation',
      message: `Success! The ${docType} was sent to ${formatEmailMessageByDocType(recipients, docType)}`,
    };
  },
  DOCUMENTS_SENT_SUCCESS_RENEWAL: (recipients) => {
    const brand = getBrand();
    let emails: string = '';
    recipients.forEach((r) => {
      if (r.email) {
        emails += `${r.email} `;
      }
    });
    return {
      id: 'send-renewal--success',
      message: `The ${brand} Home Warranty Renewal email has been successfully shared with ${emails}`,
    };
  },
  DOCUMENTS_SENT_FAILURE_RENEWAL: (recipients) => {
    const brand = getBrand();
    let emails: string = '';
    recipients.forEach((r) => {
      if (r.email) {
        emails += `${r.email} `;
      }
    });
    return {
      id: 'send-renewal--failure',
      message: `Not able to send the ${brand} Home Warranty Renewal email with ${emails}. Please check the email addresses are correct.`,
    };
  },
  QUOTE_SHARED_SUCCESS: (recipientEmail: string, orderId: string) => ({
    id: 'share-quote--success',
    messageHTML: (
      <>
        <br />
        Quote emailed to {recipientEmail}. Quote # is <strong>{orderId}</strong>. You can manage all
        your shared quotes from My Quotes page.
      </>
    ),
    isPersistantNotification: true,
  }),
  CANCEL_ORDER_SUCCESS: (contractId: string): Message => ({
    id: 'cancel-contract--success',
    message: `Order ${contractId} has been cancelled.`,
  }),
  CANCEL_ORDER_FAILED: (contractId: string): Message => ({
    id: 'cancel-contract--warning',
    message: `There was an issue cancelling order ${contractId}.`,
  }),
  DELETE_ORDER_SUCCESS: (_id: string): Message => ({
    id: 'delete-order--success',
    message: `The selected order has been deleted.`,
  }),
  DELETE_ORDER_FAILED: (_id: string): Message => ({
    id: 'delete-order--warning',
    message: 'Please try again later.',
  }),
  CANCEL_ORDER_ERROR: (_contractId: string): Message => ({
    id: 'cancel-contract--error',
    message: 'Uh oh, there was a problem cancelling your order. Please try again.',
  }),
  QUOTE_SAVED_SUCCESS: (value) => ({
    id: 'save-quote--success',
    isPersistantNotification: true,
    messageHTML: (
      <>
        <br />
        Quote saved. Quote # is <strong>{value}</strong>. You can manage all your saved quotes from
        My Quotes page.
      </>
    ),
  }),
  QUOTE_SAVED_FAILED: () => ({
    id: 'save-quote--failed',
    message: `Failed to save Quote, Please try again.`,
  }),
  QUOTE_SAVED_PROPERTY_NOT_FOUND: () => ({
    id: 'save-quote--property-not-found',
    message: `We couldn’t find the address you entered, Please try again.`,
  }),
  QUOTE_CANCEL_SUCCESS: (quoteId) => ({
    id: 'cancel-quote--success',
    message: `Quote #${quoteId} successfully cancelled.`,
  }),
  QUOTE_CANCEL_FAILED: () => ({
    id: 'cancel-quote--failed',
    message: `Failed to cancel Quote, Please try again.`,
  }),
  QUOTE_ADDRESS_VALIDATED_SUCCESSFULLLY: () => ({
    id: 'address-validation-success',
    message: `Address validated.`,
  }),
  QUOTE_ADDRESS_UUID_CREATION_FAILED: () => ({
    id: 'address-UUID-cration-failed',
    message: `Address ID can not be created.`,
  }),
  QUOTE_CANCEL_REASON_FETCH_FAILED: () => ({
    id: 'cancel-quote--reason-fetch-failed',
    message: `Failed to fetch cancel reasons, Please try again.`,
  }),
};

export const NO_INCOMPLETE_ORDERS_FOUND = "No 'Not Submitted' orders were found";
export const NO_INCOMPLETE_ORDERS_FOUND_WITH_CRITERIA =
  "No 'Not Submitted' order was found with the entered criteria";
export const INCOMPLETE_ORDER_FOUND = "We found a 'Not Submitted' order for this property";

function formatEmailMessage(recipients) {
  const { length } = recipients;
  switch (length) {
    case 0:
    case null:
    case undefined:
      console.error('No emails returned');
      return '';
    case 1:
      return recipients[0].email;
    default: {
      let emailString = '';
      for (let i = 0; i < length - 1; i++) {
        emailString += `${recipients[i].email} and `;
      }

      emailString += recipients[length - 1].email;

      return emailString;
    }
  }
}

function formatEmailMessageByDocType(recipients, docType) {
  let emailString = '';
  const { length } = recipients;

  for (let i = 0; i < length; i++) {
    if (recipients[i].docs?.includes(docType)) {
      if (emailString.length === 0) {
        emailString += `${recipients[i].email}`;
      } else {
        emailString += ` and ${recipients[i].email}`;
      }
    }
  }
  return emailString;
}

export const CONTENT = {
  BEST_COVERAGE_LABEL: 'Best Coverage',
  PLAN_CARD_SELECTED_BUTTON_LABEL: 'Selected',
  PLAN_CARD_NON_SELECTED_BUTTON_LABEL: 'Select Plan',
};

export default msgs;
