import React, {Component} from 'react';
import {classNames, isMobileView} from '@utils';
import {useCurrentRoute, useNavigation} from 'react-navi';
import {getBrand} from '@helpers/brand.utils';
import CardOrderGrid from '@components/card/CardOrderGrid';
import TagGroup from '@components/tag/TagGroup';
import ListFiltering from '@components/misc/ListFiltering';
import {PER_PAGE_FIFTEEN_RECORDS} from '@constants/pagination-constants';
import * as paginationActions from '@services/pagination/Pagination';
import ProfileContext from '../../context/ProfileContext/index';
import ProfileModel from '@app/models/profile.model';
import {
  AgentSearchRecord,
  Contract,
  Office,
  ProductPricingDetailsRequest,
  REQuote,
  REQuoteSearchMetaStatus,
} from '@apis/models';
import {Navigation, Route} from 'navi';
import {addressToString} from '@services/helpers';
import {FilterChangeEvent, FilterTagItem, OrderSort} from '@components/misc/misc.models';
import {FilterOperation, FilterType, QuoteStatus} from '@constants/dashboardFilters';
import {fireGAEvent} from '@app/core/tracking.service';
import {PLAN_PRICE__CONVERT_ORDER} from '@constants/ga-events.constants';
import {FILTER_BY_QUOTE_NUMBER_NAME_ADDRESS, TOO_MANY_OFFICES,} from '@constants/formField-constants';
import {isAdmin} from '@helpers/profile.utils';
import ZestyApi from '@services/apis/zesty.apis';
import {MerchandisingContent} from '@services/apis/models/zesty.api.model';
import AtypicallySlowOverlay from '@components/loader/AtypicallySlowOverlay';
import {Badge, Button, Dialog, Link, Pagination, Table, Tag, Text,} from '@ftdr/blueprint-components-react';
import SearchBar from '@components/input/SearchBar';
import {formatDateFromString} from '@helpers/utils';
import {quotesStatusDisplay, quotesStatusIDDictionary} from '@constants/dictionaries';
import ActionsDropdown from '@components/button/ActionsDropdown';
import {ContentBox} from '@components/layout/ContentBox';
import {IconTypes} from '@components/card/CardFilterBar';
import {isEmpty} from 'lodash';
import {StatusMenuType} from '@components/filter/OrderStatusFilter';
import DrawerPlanPriceViewQuote from '@components/drawer/DrawerPlanPriceViewQuote';
import DrawerPlanPriceShareQuote from '@components/drawer/DrawerPlanPriceShareQuote';
import DrawerPlanPriceCancelQuote from '@components/drawer/DrawerPlanPriceCancelQuote';
import Path from '@constants/paths';
import {QuoteSummarySelection} from '@components/misc/QuoteSummary';
import {PlansAndPricesFormData} from '@templates/misc/PlansAndPricesTemplate';
import {Quote} from '@apis/models/quote.api.model';
import moment from 'moment';
import 'moment-timezone';
import {getCancelQuoteLocalList, isQuoteLocalCancelling} from '@helpers/localStorage.utils';
import {NewOrderFromSource} from '@pages/order/NewOrder';
import {loadOrderForViewQuote} from '@components/drawer/subcomponents/PlanPriceViewQuote';

// ///////////////////////////////////////////////////////////////////////////////////
/** Timeout period for notifications to be automatically closed */
const NOTIFICATION_TIMEOUT = 3500;
/** # of grid cards to display while on load */
const SKELETON_LOAD_CNT_GRID = 6;

/** Contract Pagination Count */
const PER_PAGE_RECORDS = PER_PAGE_FIFTEEN_RECORDS;
const ATYPICALLY_SLOW_OVERLAY_THRESHOLD = 500; // .5 seconds

let renderFastTimeout;

const noLoadedTooltips = [{ label: 'No items loaded', onClick: null, id: 'no-items-loaded' }];

const CANCELLING_STATUS_TEXT = 'Cancelling...';

interface QuotesState {
  // summary view
  summaryView: Quote[];
  quoteSort: OrderSort;

  // Pagination
  filters: {
    defaultOfficeList: Office[];
    filteredOfficeList: Office[];
  };
  activePage: number;
  pagination: {
    mainOrderList: Contract[];
    totalPages: number;
    isFilteredList: boolean;
    beforeFilterActivePage: number;
  };

  // Drawer
  selectedQuotes: Quote;
  viewQuoteDrawerActive: boolean;
  shareQuoteDrawerActive: boolean;
  shareQuoteProps: {
    productPricingDetailsRequest: ProductPricingDetailsRequest;
    quote: QuoteSummarySelection;
    formData: PlansAndPricesFormData;
    selectedQuote: Quote;
  };
  cancelQuoteDrawerActive: boolean;
  cancelQuoteProps: {
    selectedQuote: Quote;
  };
  // Other
  isNotificationActive: boolean;
  isAtypicallySlow: boolean;

  // merchandising
  merchandising: {
    isLoading: boolean;
    isMerchandiseWidgetActive: boolean;
    merchandisingContent: MerchandisingContent;
  };

  // config
  showFilterModal: boolean;
}

interface QuotesProps {
  orders: Contract[];
  isLoading: boolean;
  isLoadingQuotes: boolean;
  profile: ProfileModel;
  offices: Office[];
  agents: AgentSearchRecord[];
  summaryView: Quote[];
  filterTagItems: FilterTagItem[];
  totalOrders: number;
  totalStatus: REQuoteSearchMetaStatus;
  totalAwaitingWlkSubmission: number;
  searchText: string;

  onFilterChange: (event: FilterChangeEvent, clearFilters?: boolean) => boolean;
  onFilterBatchChange: (events: FilterChangeEvent[]) => boolean;
  onSearchFilterChange: (value: string) => void;
  onRefreshQuotes: () => void;

  hasTooManyOffices: boolean;
}

// React Navi currently does not support router/navigation information for class components.
// Workaround is to wrap the class component with a functional component as a measure.
// See https://github.com/frontarm/navi/issues/80
interface OrdersPropsWithRouteHook extends QuotesProps {
  route: Route;
  isMobile: boolean;
  navigation: Navigation;
}

// React Navi currently does not support router/navigation information for class components.
// Workaround is to wrap the class component with a functional component as a measure.
// See https://github.com/frontarm/navi/issues/80
function QuotesTemplate(props: QuotesProps) {
  const route: Route = useCurrentRoute();
  const navigation = useNavigation();
  return (
    <QuotesTemplateWithRouteHook
      {...props}
      route={route}
      isMobile={isMobileView()}
      navigation={navigation}
    />
  );
}

// TODO Componentize DrawerOrder and its dependency Drawers
export class QuotesTemplateWithRouteHook extends Component<OrdersPropsWithRouteHook, QuotesState> {
  static contextType = ProfileContext;
  profile: ProfileModel;

  // used to check expiring soon status
  expiringStatus = [
    quotesStatusIDDictionary.SAVED.toUpperCase(),
    quotesStatusIDDictionary.SHARED.toUpperCase(),
  ];
  todayInCST: Date;

  constructor(props) {
    super(props);

    const todayCSTStr = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' });
    this.todayInCST = new Date(todayCSTStr);

    // Set default
    this.state = {
      summaryView: [],
      quoteSort: {
        sortBy: 'id',
        asc: false,
      },

      filters: {
        defaultOfficeList: [],
        filteredOfficeList: [],
      },
      activePage: 1,
      pagination: {
        mainOrderList: undefined,
        totalPages: 0,
        isFilteredList: false,
        beforeFilterActivePage: 0,
      },

      selectedQuotes: undefined,

      isNotificationActive: false,
      isAtypicallySlow: false,

      merchandising: {
        isLoading: false,
        isMerchandiseWidgetActive: false,
        merchandisingContent: {
          isActive: false,
          copyText: '',
          merchandisingUrl: '',
          imageIconUrl: '',
          navIconUrl: '',
          ctaText: undefined,
        },
      },
      showFilterModal: false,
      viewQuoteDrawerActive: false,
      shareQuoteDrawerActive: false,
      shareQuoteProps: null,
      cancelQuoteDrawerActive: false,
      cancelQuoteProps: null,
    };

    this.initView = this.initView.bind(this);
    this.initQuoteDataPagination = this.initQuoteDataPagination.bind(this);
    this.initQuoteDataPagination = this.initQuoteDataPagination.bind(this);
    this.initMerchandising = this.initMerchandising.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.onFilterTextChangeHandler = this.onFilterTextChangeHandler.bind(this);
    this.SummaryViewQuoteHandler = this.SummaryViewQuoteHandler.bind(this);
    this.onQuoteUpdate = this.onQuoteUpdate.bind(this);
    this.updateMerchandising = this.updateMerchandising.bind(this);
  }

  /** Add tooltip actions based off of quote  */
  tooltipActions = (quoteId: number, fromOrderDrawer = false) => {
    const quote = this.state.summaryView.find((c) => c.tableId === quoteId);
    if (!quote) return noLoadedTooltips;
    const expired = QuoteStatus.EXPIRED.toUpperCase();
    const converted = quotesStatusIDDictionary.CONVERTED_ORDER.toUpperCase();
    const saved = QuoteStatus.SAVED.toUpperCase();
    const cancelled = QuoteStatus.CANCELLED.toUpperCase();

    const items: { label: string; onClick: () => void; disabled?: boolean; id?: string }[] = [
      {
        label: 'View Quote',
        onClick: () => {
          if (quote?.status) {
            quote.status = quotesStatusIDDictionary[quote.status.toUpperCase()];
          }

          this.setState({ selectedQuotes: quote, viewQuoteDrawerActive: true });
        },
        disabled: fromOrderDrawer || quote.status === converted, // Dont open the drawer again,
        id: 'view_quote',
      },
      {
        label: 'View Order',
        onClick: () => {
          this.showOrderDetails(quote.contractId);
        },
        disabled: quote.status !== converted,
        id: 'view_order',
      },
      {
        label: 'Share Quote',
        onClick: () => {
          this.handleShareQuote(quote.id, quote);
        },
        disabled: quote.status !== saved,
        id: 'share_quote',
      },
      {
        label: 'Convert to Order',
        onClick: () => {
          this.convertToOrder(quote);
        },
        disabled:
          quote.status === expired || quote.status === converted || quote.status === cancelled,
        id: 'convert_to_order',
      },
      {
        label: 'Cancel Quote',
        onClick: () => {
          this.handleCancelQuote(quote.id, quote);
        },
        disabled:
          quote.status === expired || quote.status === converted || quote.status === cancelled,
        id: 'cancel_quote_action',
      },
    ];

    // Remove any items that are disabled. Done this way so we can enhance in future
    // to disable instead of remove, since the ButtonDropdown does not accept disabled as prop value
    // If disabled added to ButtonDropdown, remove map and filter.
    return items
      .filter((i) => !i.disabled)
      .map((i) => ({ label: i.label, onClick: i.onClick, id: i.id }));
  };

  // should we show the current merchandising campaign to the customer?

  getNoticeTypes = () => {
    if (!this.state.selectedQuotes) return {};
    const { projectedClosingDate } = this.state.selectedQuotes.detail.importantDates;
    return {
      Confirmation:
        this.state.selectedQuotes.summary && this.state.selectedQuotes.summary['status'] === 'L'
          ? true
          : true,
      Invoice: !!projectedClosingDate,
    };
  };

  // this is hardcoded for now - TODO find better way to manage this selection (future story)
  showMerchandisingForCustomer = (props: OrdersPropsWithRouteHook = this.props): boolean => {
    return props.offices?.some((office) => {
      return office?.address?.state == 'CA';
    });
  };

  isMerchandisingActive = () => {
    return (
      this.state.merchandising.isMerchandiseWidgetActive &&
      this.state.merchandising.merchandisingContent.isActive
    );
  };

  checkIsExpiring = (value) => {
    if (this.expiringStatus.includes(value.status.toUpperCase())) {
      const momentExpirationDate = moment.utc(value.expirationDate);
      momentExpirationDate.tz('America/Chicago');
      const expirationDateInCST = momentExpirationDate.format('YYYY-MM-DD HH:mm:ss');
      const expirationDate = new Date(expirationDateInCST);
      const diff = expirationDate.valueOf() - this.todayInCST.valueOf();
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

      // Highlight the date in red if the Quote is going to expire in 3 days or less.
      if (diffDays >= 0 && diffDays <= 3) {
        const expiringDateText = (
          <span id="expiring_soon">
            <div>{formatDateFromString(value.expirationDate)}</div>
            <div>{quotesStatusDisplay[quotesStatusIDDictionary.EXPIRING]}</div>
          </span>
        );
        return (
          <Text color="secondary" variant="caption">
            {expiringDateText}
          </Text>
        );
      }
      return <Text variant="caption">{formatDateFromString(value.expirationDate)}</Text>;
    }

    return <Text variant="caption">{formatDateFromString(value.expirationDate)}</Text>;
  };

  shouldEnableIdLink = (quoteId) => {
    const quote = this.props.summaryView.find((quote) => quote.id === quoteId);
    if (!quote) return false;
    const isCancelling = isQuoteLocalCancelling(quote.id);
    const isCancelled = quote.status.toUpperCase() === QuoteStatus.CANCELLED.toUpperCase();
    return !isCancelling || isCancelled;
  };

  // add these column if the user is an admin
  addAdminColumn = () => {
    if (isAdmin(this.profile)) {
      this.columns.push(
        {
          Header: <Text variant="heading-06">Agent</Text>,
          accessor: 'summary.agentName',
          Cell: ({ value }) => <Text variant="caption">{value}</Text>,
          columnOrder: 5,
        },
        {
          Header: <Text variant="heading-06">Office</Text>,
          accessor: 'summary.officeName',
          Cell: ({ value }) => <Text variant="caption">{value}</Text>,
          columnOrder: 6,
        },
      );
    }
  };

  handleShareQuote = async (quoteId: number, selectedQuotes: Quote) => {
    const {
      planPriceFormData: formData,
      quoteSummary,
      productPricingDetailsRequest: productPricingRequest,
    } = await loadOrderForViewQuote(`${selectedQuotes.id}`);
    this.showShareQuote({
      quote: quoteSummary,
      formData,
      productPricingDetailsRequest: productPricingRequest,
      selectedQuote: selectedQuotes,
    });
  };

  handleCancelQuote = (quoteId, selectedQuotes) => {
    this.showCancelQuote({
      selectedQuote: selectedQuotes,
    });
  };

  componentDidMount() {
    this.initView();
    this.initQuoteDataPagination();
    this.initMerchandising();
    this.renderOverlayIfLoadingTooSlow(this.props.isLoading);
    this.addAdminColumn();
  }

  componentDidUpdate(prevProps: QuotesProps, prevState: QuotesState) {
    // TODO: These logs are here temporarily to observe update performance. Remove once completed.
    // Set a timeout if the notification state was changed
    if (this.state.isNotificationActive !== prevState.isNotificationActive) {
      console.log('trigger update, notification timeout');
      this.setNotificationTimeout();
    }
    // Update contracts if it was loaded or if pagination changes
    if (this.props.summaryView !== prevProps.summaryView) {
      console.log('trigger update, init pagination');
      this.initQuoteDataPagination();
    }
    // Update the page if pagination changes
    if (this.state.activePage !== prevState.activePage) {
      console.log('trigger update, update pagination');
      this.initQuoteDataPagination();
    }

    // Update the office list for filters
    if (this.props.offices !== prevProps.offices) {
      console.log('trigger update, offices loaded');
      this.updateOfficeFilterList();
      this.updateMerchandising();
    }

    if (this.props.isLoading !== prevProps.isLoading) {
      this.renderOverlayIfLoadingTooSlow(this.props.isLoading);
    }
  }

  /** Initialize the view depending on user role */
  initView(): void {
    const { profile } = this.context;
    this.profile = profile;
  }

  /** Initialize the quote data for pagination */
  initQuoteDataPagination(): void {
    const { orders, summaryView } = this.props;
    const { pagination } = this.state;
    if (summaryView) {
      const totalPageCount = paginationActions.setTotalPageCount(
        this.props.totalOrders,
        PER_PAGE_RECORDS,
      );

      const quotesWithREStatus: Quote[] = this.updateToREStatus(summaryView);
      this.setState({
        pagination: {
          ...pagination,
          mainOrderList: orders,
          totalPages: totalPageCount,
        },
        summaryView: quotesWithREStatus.slice(0, PER_PAGE_RECORDS),
      });
    }
  }

  deriveFromNewOrderStatus = (order: Quote) => {
    if (order.expirationDate) {
      const expirationDate = moment(order.expirationDate);
      const today = new Date();
      today.setHours(0, 0, 0);

      if (expirationDate.isSameOrAfter(today)) {
        // has future date but not in next 3 days window, set the status to 'Saved'
        order.status = quotesStatusIDDictionary.SAVED;
        order.summary.realEstateStatus = quotesStatusIDDictionary.SAVED;
      }
    }
  };

  updateToREStatus = (orders: Quote[]): Quote[] => {
    const sortOrderArr = [
      quotesStatusIDDictionary.EXPIRING,
      quotesStatusIDDictionary.EXPIRED,
      quotesStatusIDDictionary.SAVED,
      quotesStatusIDDictionary.SHARED,
      quotesStatusIDDictionary.CONVERTED_ORDER,
      quotesStatusIDDictionary.CANCELLED,
    ];

    // check for expiring soon, shared status, converted to order
    orders.forEach((item) => {
      if (item.status === quotesStatusIDDictionary.NEW) {
        this.deriveFromNewOrderStatus(item);
      } else if (item.status === quotesStatusIDDictionary.COMPLETE) {
        item.status = quotesStatusIDDictionary.CONVERTED_ORDER;
        item.summary.realEstateStatus = quotesStatusIDDictionary.CONVERTED_ORDER;
      }
    });

    // Sort in this order - expiring soon, expired, saved, shared, converted to order
    const sortedQuotes: Quote[] = [];
    sortOrderArr.forEach((status) => {
      let items = orders.filter((q) => q.status === status);
      if (items.length > 0) {
        items = items.sort((a, b) =>
          new Date(a.expirationDate) > new Date(b.expirationDate) ? 1 : -1,
        );
        sortedQuotes.push(...items);
      }
    });

    return sortedQuotes;
  };

  initMerchandising(): void {
    if (getBrand().toLowerCase() === 'hsa') {
      return;
    }
    const { merchandising } = this.state;
    this.setState({
      merchandising: {
        ...merchandising,
        isLoading: true,
      },
    });
    ZestyApi.GetMerchandiseData()
      .then((zestyContent) => {
        if (zestyContent == null) {
          console.warn('no merchandising information returned.');
          return;
        }
        const { merchandising } = this.state;
        this.setState({
          merchandising: {
            ...merchandising,
            isLoading: false,
            merchandisingContent: zestyContent,
          },
        });
        this.updateMerchandising();
      })
      .catch(() => {
        console.warn('getting merchandising information failed.');
        const { merchandising } = this.state;
        this.setState({
          merchandising: {
            ...merchandising,
            isLoading: false,
          },
        });
      });
  }

  renderOverlayIfLoadingTooSlow(isLoading: boolean): void {
    if (isLoading) {
      renderFastTimeout = setTimeout(() => {
        this.setState({ isAtypicallySlow: true });
      }, ATYPICALLY_SLOW_OVERLAY_THRESHOLD);
    } else {
      clearTimeout(renderFastTimeout); // cancel any queued renderings
      this.setState({ isAtypicallySlow: false });
    }
  }

  updateMerchandising(): void {
    if (this.showMerchandisingForCustomer(this.props)) {
      const { merchandising } = this.state;
      this.setState({
        merchandising: {
          ...merchandising,
          isMerchandiseWidgetActive: true,
        },
      });
    }
  }

  // Updates the dropdown for filtering by office
  updateOfficeFilterList(): void {
    const { offices } = this.props;
    if (offices) {
      this.setState({ filters: { defaultOfficeList: offices, filteredOfficeList: offices } });
    }
  }

  setViewQuoteDrawerActive(status): void {
    this.setState({ viewQuoteDrawerActive: status });
  }

  setShareQuoteDrawerActive(status): void {
    this.setState({ shareQuoteDrawerActive: status });
  }

  setCancelQuoteDrawerActive(status): void {
    this.setState({ cancelQuoteDrawerActive: status });
  }

  /** Automatically times out notifications */
  setNotificationTimeout(): void {
    if (this.state.isNotificationActive) {
      setTimeout(() => {
        this.setState({ isNotificationActive: false });
      }, NOTIFICATION_TIMEOUT);
    }
  }

  /** Adds a filter into filter list.
   *  TODO: label param is temporary to not break existing functionality.
   *  For new implementations, set label as undefined and event to hold the label.
   *  For existing implementation, the first label param will be set into a default event and not truly handled properly, but at least won't cause any errors.
   */
  addFilter(
    label: string,
    event: FilterChangeEvent = {
      label,
      operation: FilterOperation.ADD,
      payload: undefined,
      type: undefined,
    },
    clearFilters = false,
  ): void {
    if (event.type && event.payload) {
      console.log(event);
      this.props.onFilterChange(event, clearFilters);
      this.setState({ activePage: 1 });
    }
  }

  /** Removes a filter from the list */
  removeFilter(tagItem: FilterTagItem): void {
    // TODO: Move this logic to <Tag> code directly so it can manipulate classes there
    // e.target.classList.add("transition", "opacity-0");
    // Opacity code is removed for now since the filter list is controlled on dashboard, so element gets destroyed immediately instead of after a timeout.

    this.props.onFilterChange({ ...tagItem, operation: FilterOperation.REMOVE });
    this.setState({ activePage: 1 });
  }

  onSearchTextChange(value: string): void {
    this.setState({ activePage: 1 });
    this.props.onSearchFilterChange(value);
  }

  onFilterTextChangeHandler(event): void {
    const { orders } = this.props;

    if (event.target.value !== '') {
      const searchText = event.target.value.toLowerCase();
      this.setState({ activePage: 1 });
      orders.filter((order) => {
        const {
          address: { city, state, address1, zip },
          id,
          agentName,
          officeName,
        } = order.summary;
        const addressString = `${address1}, ${city}, ${state} ${zip}`;
        if (
          addressString.toLowerCase().includes(searchText) ||
          id.includes(searchText) ||
          agentName.includes(searchText) ||
          officeName.toLowerCase().includes(searchText)
        ) {
          return order;
        }
      });
    }
  }

  // Added SummaryViewOrderHandler just to avoid the breaking of existing functionality
  async SummaryViewQuoteHandler(quoteId: number): Promise<void> {
    const quote = this.state.summaryView.find((c) => c.tableId === quoteId);

    if (quote?.status) {
      quote.status = quotesStatusIDDictionary[quote.status.toUpperCase()];
    }

    this.setState({ selectedQuotes: quote, viewQuoteDrawerActive: true });
  }

  /** Removes certain tags from display in the filter */
  filtersForDisplay(filters: FilterTagItem[]): FilterTagItem[] {
    const excludeFilterTypes = ['quoteSort'];
    /** Prevents RE agents from clearing the filter showing only their contracts */
    if (this.props.profile.roleIDType === 'RealEstateAgent') {
      excludeFilterTypes.push('office', 'agent');
    }
    return filters.filter((tag) => !excludeFilterTypes.includes(tag.type));
  }

  renderStatus = (status: string) => {
    const displayStatus = quotesStatusDisplay[status?.toUpperCase()];
    return <Text>{displayStatus}</Text>;
  };

  renderEnhancedStatus = (enhancedStatus) => {
    const displayStatus = quotesStatusDisplay[enhancedStatus?.status?.toUpperCase()];
    if (enhancedStatus?.notice) {
      return (
        <>
          <Text>{displayStatus}</Text>
          <Text color="secondary">{enhancedStatus.notice}</Text>
        </>
      );
    }
    return <Text>{displayStatus}</Text>;
  };

  columns = [
    {
      Header: <Text variant="heading-06">Property Address</Text>,
      accessor: 'address', // accessor is the "key" in the data
      Cell: ({ value }) => (
        <Text variant="caption">
          {addressToString(value.address1, value.address2, value.city, value.state, value.zip)}
        </Text>
      ),
      columnOrder: 1,
    },
    {
      Header: <Text variant="heading-06">Quote #</Text>,
      accessor: 'id',
      Cell: ({ value }) =>
        this.shouldEnableIdLink(value) ? (
          <Link
            color="interactive"
            onClick={() => {
              this.SummaryViewQuoteHandler(value);
            }}
          >
            {value}
          </Link>
        ) : (
          value
        ),
      columnOrder: 2,
    },
    {
      Header: <Text variant="heading-06">Expiration</Text>,
      accessor: 'expirationDateWithStatus',
      Cell: ({ value }) => this.checkIsExpiring(value),
      columnOrder: 4,
    },
    {
      Header: <Text variant="heading-06">Status</Text>,
      accessor: 'enhancedStatus',
      Cell: ({ value }) => {
        return this.renderEnhancedStatus(value);
      },
      columnOrder: 7,
    },
    {
      Header: '',
      accessor: 'tableId',
      disableSortBy: true,
      Cell: ({ value }) =>
        this.shouldEnableIdLink(value) && (
          <ActionsDropdown menuItems={this.tooltipActions(value)} />
        ),
      columnOrder: 8,
    },
  ];

  /** When quote in param updates, trigger a refresh on the quote's data to reload it.
   * If the selected quote matches with the updated quote, then update the selected quote as well.
   */
  onQuoteUpdate(quotes: Quote[]): void {
    if (this.state.selectedQuotes && quotes?.length > 0) {
      const quote = quotes.find((c) => c.summary.id === this.state.selectedQuotes.summary.id);
      if (quote) {
        this.setState({ selectedQuotes: quote });
      }
    }
  }

  filterWarrantyLinkOffices = (officesToFilter) => {
    return officesToFilter.filter((office) => office.warrantyLinkEligible);
  };

  onPageChange = (pageNumber: number) => {
    if (this.state.activePage !== pageNumber) {
      this.addFilter(undefined, {
        label: undefined,
        operation: FilterOperation.PAGE_CHANGE,
        type: FilterType.PAGE,
        payload: { page: pageNumber - 1 },
      });

      this.setState({ activePage: pageNumber });
    }
  };

  getListFiltering = () => {
    return (
      <ListFiltering
        id="quotes-container--filter-dropdowns"
        addFilter={this.addFilter}
        applyFilters={(events) => this.props.onFilterBatchChange(events)}
        onFilterTextChangeHandler={(e) => this.onFilterTextChangeHandler(e)}
        officeList={this.state.filters.filteredOfficeList}
        agentList={this.props.agents}
        userRoleID={this.props.profile.roleID}
        userRoleIDType={this.props.profile.roleIDType}
        activeFilters={this.props.filterTagItems}
        statusAlerts={this.props.totalStatus}
        isMobile={this.props.isMobile}
        totalAwaitingWlkSubmission={this.props.totalAwaitingWlkSubmission}
        statusMenuType={StatusMenuType.Quotes}
      />
    );
  };

  translateWLKStatus = (quote: REQuote) => {
    /**
     * This is going to overwrite the other status displayed in the table.
     * Because of this, only display WLK Eligible on active contracts.
     */
    // Commenting out this temporarely for <ARE-9853></ARE-9853>
    // if (order.awaitingWlkSubmission && order.realEstateStatus === RealEstateStatus.ACTIVE) {
    //   return RealEstateStatus.AWAITING_WL_SUBMISSION;
    // }
    return quote.realEstateStatus;
  };

  getFilterBadge = () => {
    const filterCount = this.props.filterTagItems.filter((item) => !isEmpty(item.label)).length; // some filters we don't display to the user (such as quoteSort) and don't want to include them as a part of the count, this accounts for that

    return filterCount ? (
      <Badge color="interactive" size="small" maxCount={99} className="ml-1-important">
        {filterCount.toString()}
      </Badge>
    ) : null;
  };

  showShareQuote = (shareQuoteProps) => {
    this.setState({ shareQuoteProps, shareQuoteDrawerActive: true });
  };

  showCancelQuote = (cancelQuoteProps) => {
    this.setState({ cancelQuoteProps, cancelQuoteDrawerActive: true });
  };

  convertToOrder = (selectedQuotes) => {
    fireGAEvent(PLAN_PRICE__CONVERT_ORDER);
    this.setState({
      selectedQuotes,
    });
    this.props?.navigation?.navigate(
      `${Path.NewOrder}/${selectedQuotes.id}?from=${NewOrderFromSource.MyQuotes}`,
    );
  };

  showOrderDetails = (contractID: string): void => {
    if (contractID) {
      this.props?.navigation?.navigate(`${Path.MyOrders}/${contractID}`);
    }
  };

  getTableData = () => {
    const cancelList = getCancelQuoteLocalList();
    let orders = this.state.summaryView.map((c) => ({
      ...c,
      realEstateStatus: this.translateWLKStatus(c),
      displayStatus: c.summary.realEstateStatus,
      enhancedStatus: {
        status: c.summary.realEstateStatus,
        notice:
          cancelList.includes(c.summary.id) &&
          c.summary.realEstateStatus.toUpperCase() !== QuoteStatus.CANCELLED.toUpperCase()
            ? CANCELLING_STATUS_TEXT
            : '',
      },
      expirationDateWithStatus: { expirationDate: c.expirationDate, status: c.status },
    }));
    orders = orders.sort((a, b) =>
      new Date(a.expirationDate) > new Date(b.expirationDate) ? 1 : -1,
    );
    return orders;
  };

  render() {
    return (
      <div className="flex flex-row py-0">
        {/* FILTER DROPDOWNS */}

        <div className="flex-1">
          <ContentBox
            title="My Quotes"
            leftContent={
              !this.props.isMobile && (
                <div className="w-48 min-w-48 mr-4">
                  <Text variant="heading-06" className="my-2">
                    Filter Quotes
                  </Text>
                  {this.getListFiltering()}
                </div>
              )
            }
            titleMessage={
              <>
                <b>Manage Your Quotes Here:</b> My Quotes is where you can view your saved quotes,
                share a quote with clients, or convert your saved/shared quotes into orders. Click
                on Actions to get started.
              </>
            }
          >
            <div id="quotes-container" className="relative px-0 pt-0 pb-6">
              {/* TAG CHIPS */}
              <div id="quotes-container--tag-group" className="mb-4 sm:mt-3">
                {this.props.isMobile ? (
                  <Button
                    id="filter-quotes-button"
                    label="Filter Quotes"
                    shape="rounded"
                    variant="outlined"
                    endIcon={this.getFilterBadge()}
                    onClick={() => this.setState({ showFilterModal: true })}
                    width="full"
                    labelAlign="center"
                  />
                ) : (
                  <TagGroup>
                    <div>
                      {this.filtersForDisplay(this.props.filterTagItems).length > 0 && (
                        <div className="float-left">Filtered by:</div>
                      )}
                      <div className="items-center flex flex-wrap">
                        {this.filtersForDisplay(this.props.filterTagItems).map((item, idx) => (
                          <Tag
                            id={`tag-${idx}`}
                            key={item.label}
                            color="interactive"
                            removable
                            onRemove={() => {
                              this.removeFilter(item);
                            }}
                            className="ml-4"
                          >
                            {item.label}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </TagGroup>
                )}
              </div>

              {/* SEARCH FILTER + VIEW TOGGLE */}
              <div
                id="quotes-container--search-container"
                className={classNames(['card', !this.props.isMobile && 'block'])}
              >
                <SearchBar
                  searchText={this.props.searchText}
                  onChange={(value) => this.onSearchTextChange(value)}
                  icon={IconTypes.Filter}
                  placeHolder={FILTER_BY_QUOTE_NUMBER_NAME_ADDRESS}
                  showClearButton={true}
                  outerCSS="p-3"
                />
              </div>

              {!this.state.isAtypicallySlow && (
                <>
                  {/* TABLE VIEW */}
                  {!this.props.isMobile && (
                    <div id="quotes-container--table-container" className="card sm-max:hidden">
                      <Table
                        {...{
                          data: this.getTableData(),
                          columns: this.columns.sort((a, b) => a.columnOrder - b.columnOrder),
                        }}
                        striped="gray"
                        variant="heavy"
                        sortable={true}
                        paginate={false}
                        selectable={false}
                        onRowsSelect={console.log}
                      />
                    </div>
                  )}

                  {/* GRID VIEW */}
                  <div
                    id="quotes-container--grid-container"
                    className={classNames([
                      'sm:flex sm:flex-wrap -mx-2',
                      !this.props.isMobile && 'md:hidden',
                    ])}
                  >
                    {this.props.isLoading ? (
                      Array(SKELETON_LOAD_CNT_GRID)
                        .fill(null)
                        .map((v, idx) => (
                          <div
                            id={`quotes-card-${idx}`}
                            key={idx}
                            className="w-full sm:w-1/2 lg:w-1/3 p-2"
                          >
                            <CardOrderGrid skeleton={true} />
                          </div>
                        ))
                    ) : (
                      <>
                        {this.state.summaryView.map((item, idx) => {
                          return (
                            <div
                              id={`quotes-card-${idx}`}
                              key={idx}
                              className="w-full sm:w-1/2 lg:w-1/3 p-2"
                            >
                              <CardOrderGrid
                                awaitingWlkSubmission={item.awaitingWlkSubmission}
                                status={item.realEstateStatus}
                                enableActions={this.shouldEnableIdLink(item.id)}
                                street={item.address.address1}
                                unit={item.address.address2}
                                city={item.address.city}
                                state={item.address.state}
                                zip={item.address.zip}
                                agent={item.initiatingAgentName}
                                office={item.initiatingOfficeName}
                                menuLinks={this.tooltipActions(item.tableId)}
                                viewOrder={() => this.SummaryViewQuoteHandler(item.tableId)}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}

              <AtypicallySlowOverlay isActive={this.state.isAtypicallySlow} />
              <Pagination
                onItemsPerPageChange={null}
                itemsPerPage={15}
                itemsPerPageOptions={[{ value: '15', label: '15' }]}
                onPageChange={this.onPageChange}
                totalItems={this.props.totalOrders}
                page={this.state.activePage}
                hideViewAll={true}
              />
              {/* If too many offices, we should display a message here */}
              {!this.props.isLoading &&
                !this.state.isAtypicallySlow &&
                this.props.hasTooManyOffices && (
                  <div id="quotes-container--no-results" className="m-5">
                    {TOO_MANY_OFFICES}
                  </div>
                )}

              {/* If no quotes, we should display a message here */}
              {!this.props.isLoading &&
                !this.state.isAtypicallySlow &&
                this.props.summaryView.length === 0 &&
                !this.props.hasTooManyOffices && (
                  <div id="quotes-container--no-results" className="m-5">
                    No Quotes to display at this time. Please visit our{' '}
                    <Link
                      color="interactive"
                      onClick={() => {
                        window.location.assign(Path.PlansAndPrices);
                      }}
                    >
                      Plans and Prices page
                    </Link>{' '}
                    to generate quotes and share with your customers.
                  </div>
                )}
            </div>
          </ContentBox>
        </div>

        <DrawerPlanPriceViewQuote
          isActive={this.state.viewQuoteDrawerActive}
          onClose={() => this.setViewQuoteDrawerActive(false)}
          selectedOrder={this.state.selectedQuotes}
          onShareQuote={this.showShareQuote}
          onConvertToOrder={this.convertToOrder}
          onCancelQuote={this.showCancelQuote}
          onViewOrder={this.showOrderDetails}
        />

        <DrawerPlanPriceShareQuote
          isActive={this.state.shareQuoteDrawerActive}
          onClose={() => this.setShareQuoteDrawerActive(false)}
          quote={this.state.shareQuoteProps?.quote}
          productPricingDetailsRequest={this.state.shareQuoteProps?.productPricingDetailsRequest}
          selectedQuote={this.state.shareQuoteProps?.selectedQuote}
          formData={this.state.shareQuoteProps?.formData}
          isQuotePage={true}
        />

        <DrawerPlanPriceCancelQuote
          isActive={this.state.cancelQuoteDrawerActive}
          onClose={() => this.setCancelQuoteDrawerActive(false)}
          selectedOrder={this.state.cancelQuoteProps?.selectedQuote}
          onRefreshQuotes={this.props.onRefreshQuotes}
        />

        <Dialog
          id="filter-modal"
          header="Filter Quotes"
          modal={true}
          onClose={() => this.setState({ showFilterModal: false })}
          open={this.state.showFilterModal && this.props.isMobile}
          actionsAlign="right"
          actions={[
            <Button
              key="action"
              size="small"
              label="Done"
              onClick={() => this.setState({ showFilterModal: false })}
            />,
          ]}
        >
          <div className="min-w-80">{this.getListFiltering()}</div>
        </Dialog>
      </div>
    );
  }
}

export default QuotesTemplate;
