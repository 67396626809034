import React, { Component } from 'react';
import { FilterOperation, FilterType } from '@constants/dashboardFilters';
import { Checkbox } from '@ftdr/blueprint-components-react';
import { FilterTagItem } from '@components/misc/misc.models';
import { AgentSearchRecord } from '@apis/models';

interface state {}

interface props {
  addFilter: any;
  activeFilters: FilterTagItem[];
  agents: AgentSearchRecord[];
}

class OrderAgentFilter extends Component<props, state> {
  onChange = (agent, event) => {
    const agentName = `${agent.firstName} ${agent.lastName}`;

    this.props.addFilter(`Agent: ${agentName}`, {
      operation: event.target.checked ? FilterOperation.ADD : FilterOperation.REMOVE,
      type: FilterType.AGENT,
      payload: { agent },
      label: `Agent: ${agentName}`,
    });
  };

  render() {
    return (
      <div className="mb-4 max-h-64 overflow-y-auto">
        {this.props.agents.map((agent) => {
          return (
            <div key={agent.realEstateAgentID} className="w-full min-h-6">
              <Checkbox
                checked={this.props.activeFilters.some(
                  (filter) => filter.payload.agent.realEstateAgentID === agent.realEstateAgentID,
                )}
                label={`${agent.firstName} ${agent.lastName}`}
                onChange={(event) => this.onChange(agent, event)}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default OrderAgentFilter;
