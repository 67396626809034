export const enUsLocale = {
  en_US: {
    BDS_MESSAGE_ERROR: 'Error',
    BDS_MESSAGE_SUCCESS: 'Success',
    BDS_MESSAGE_WARNING: 'Warning',
    BDS_MESSAGE_INFO: 'Info',

    BDS_PAGINATION_ITEMS_PER_PAGE: 'Items per page',
    BDS_PAGINATION_ITEMS: 'Items',
    BDS_PAGINATION_OF_ITEMS: ' of {TOTAL_ITEMS} items',
    BDS_PAGINATION_PAGE: 'Page',
    BDS_PAGINATION_VIEW_ALL_ITEMS: 'View All Items',
  },
};

export enum BDSMainColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Interactive = 'interactive',
  Accent = 'accent',
  Gray = 'gray',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}
