import React from 'react';

const ErrorSvg = () => {
  return (
    <svg
      fill="currentcolor"
      viewBox="0 0 18 18"
      className="message-icon-small message-icon-error"
      width="18"
      height="18"
    >
      <path
        fillRule="evenodd"
        d="M1.358 16h15.284a.5.5 0 00.435-.747L9.435 1.768a.5.5 0 00-.87 0L.923 15.253a.5.5 0 00.435.747zM9.5 14h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm0-3h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5z"
      />
    </svg>
  );
};

export default ErrorSvg;
