import { Customer, Order } from '@apis/models/orders.api.model';
import _capitalize from 'lodash/capitalize';
import {
  getAgeByYearBuilt,
  getSquareFootageRangeValue,
  isNewConstructionByYearBuilt,
} from '@services/helpers';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import { Address, DwellingType, ProductPricingDetailsRequest } from '@apis/models';
import { formatDate } from '@helpers/utils';
import { NOT_APPLICABLE_OPTION } from '@services/autosuggest/autosuggest';
import {
  INewOrderLoader,
  NewOrderAgentInfo,
  NewOrderBuyerInfo,
  NewOrderClosingInfo,
  NewOrderCoCustomer,
  NewOrderCooperatingAgent,
  NewOrderCustomer,
  NewOrderData,
  NewOrderPropertyAddress,
  NewOrderPropertyDetails,
  NewOrderSellerInfo,
} from '@helpers/new-order-loader';
import { isAgentOfficeFSBO, isROProduct } from '@helpers/order.utils';
import { QuoteSummarySelection } from '@components/misc/QuoteSummary';
import { MILITARY_DISCOUNT } from '@constants/newOrder-constants';
import { PlansAndPricesFormData } from '@templates/misc/PlansAndPricesTemplate';

class NewOrderLoader implements INewOrderLoader<Order> {
  mapToNewOrderData(order: Order): NewOrderData {
    return {
      agentInfo: this.mapToAgentInfo(order),
      propertyAddr: this.mapToPropertyAddress(order),
      propertyDetails: this.mapToPropertyDetails(order),
      sellerInfo: this.mapToSellerInfo(order),
      buyerInfo: this.mapToBuyerInfo(order),
      closingInfo: this.mapToClosingInfo(order),
    };
  }

  mapToProductPricingDetailsRequest(order: Order): ProductPricingDetailsRequest {
    if (!order.getQuoteID()) {
      return null;
    }
    const { planAndCoverages, pricing, propertyDetails } = order.details;
    const { office } = order.details.officeAgents.initiating;
    const { propertyAddress } = order;

    return {
      options: {
        contractTermMonths: planAndCoverages.product.contractTermMonths,
        earnixRuleID: pricing.earnixRuleID,
        listingTermInDays: pricing.details.listingDays,
        initiatingOfficeFranchiseCode: office.franchiseCode,
        initiatingOfficeID: office.id,
        specialDiscounts: pricing.appliedSpecialDiscounts,
      },
      property: {
        address1: propertyAddress.address1,
        address2: propertyAddress.address2,
        city: propertyAddress.city,
        state: propertyAddress.state,
        zip: propertyAddress.zip,
        age: getAgeByYearBuilt(propertyDetails.yearBuilt),
        squareFootage: propertyDetails.squareFootage,
        residenceType: propertyDetails.residenceType,
      },
      selected: {
        productID: planAndCoverages.product.starPVID,
        optionalCoverages: planAndCoverages.optionalCoverages,
        groupCoverages: planAndCoverages.groupCoverages,
      },
    };
  }

  /** loads from an order's quote the data used to display on quote summary related to the request options */
  mapToPlanPriceFormData(order: Order): PlansAndPricesFormData {
    if (!order.getQuoteID()) {
      return null;
    }
    const { planAndCoverages, propertyDetails } = order.details;
    return {
      zipCode: order.propertyAddress.zip,
      residenceType: propertyDetails.residenceType as DwellingType,
      includeSellersCoverage: planAndCoverages.product.sellersCoverage,
    };
  }

  /** loads from an order's quote the data used to display on quote summary related to pricing */
  mapToQuoteSummary(order: Order): QuoteSummarySelection {
    if (!order.getQuoteID()) {
      return null;
    }
    const { planAndCoverages, pricing } = order.details;
    return {
      plan: {
        id: planAndCoverages.product.starPVID,
        name: planAndCoverages.product.name,
        price: pricing.pricing.productTotal,
      },
      coverages: [...pricing.details.optionalCoverages, ...pricing.details.groupCoverages].map(
        (c) => ({
          id: c.id,
          name: c.name,
          price: c.cost,
        }),
      ),
      tax: {
        price: pricing.pricing.tax,
        rate: pricing.details.taxRate,
        taxableAmount: pricing.details.taxableAmount,
      },
      total: pricing.pricing.totalDueAtClosing,
      sellersCoverage: {
        total: pricing.pricing.sellersCoverage,
        days: pricing.details.listingDays,
        amount: pricing.details.dailyRate,
      },
      hasSellersCoverage: planAndCoverages.product.sellersCoverage,
      discountAmount: pricing.pricing.appliedPromotion,
      militaryDiscountApplied: pricing.appliedSpecialDiscounts.includes(MILITARY_DISCOUNT),
    };
  }

  getRepresents(order: Order): string {
    if (!order.order?.offices?.initiatingOffice) {
      return '';
    }
    return (
      order.order.offices.initiatingOfficeRepresents ||
      order.order.offices.initiatingOffice.represents ||
      ''
    );
  }

  mapToAgentInfo(order: Order): NewOrderAgentInfo {
    const { initiating: details } = order.details?.officeAgents || {};
    const product = order.details?.planAndCoverages?.product;

    const agentRepresents = {
      buyer: false,
      seller: false,
      both: false,
    };
    agentRepresents[this.getRepresents(order).toLocaleLowerCase()] = true;

    return {
      agentRepresents,
      agentList: [],
      initiatingAgent: details?.agent && `${details?.agent.firstName} ${details?.agent.lastName}`,
      AgentId: details?.agent?.realEstateAgentID,
      AgentName: details?.agent && `${details?.agent.firstName} ${details?.agent.lastName}`,
      AgentEmail: details?.agent?.email,
      AgentPhone: {
        phoneNumber: details?.agent?.phoneNumbers?.[0]?.phoneNumber,
      },
      initiatingOffice: details.office?.name,
      office: {
        id: details?.office?.id,
        type: 'RE',
      },
      representing: _capitalize(this.getRepresents(order)),
      agentOffice: {
        id: details?.office?.id,
        type: 'RE',
        franchiseCode: details?.office?.franchiseCode,
      },
      isRealtorOwned: isROProduct(product),
      isFSBO: isAgentOfficeFSBO(details?.office?.id, details?.agent?.realEstateAgentID),
    };
  }

  mapToPropertyAddress(order: Order): NewOrderPropertyAddress {
    const { propertyAddress: address } = order;
    return {
      propertyAddressChanged: true,
      streetAddress: address.address1,
      unit: address.address2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      zipPlus4: address.zipPlus4,
      meta: {
        addressId: address.addressUUID,
      },
    };
  }

  mapToPropertyDetails(order: Order): NewOrderPropertyDetails {
    if (!order.details?.propertyDetails) {
      return null;
    }
    const { yearBuilt, squareFootage, residenceType } = order.details.propertyDetails;
    const currentYear = new Date().getFullYear();
    return {
      age: getAgeByYearBuilt(yearBuilt, currentYear),
      newConstruction: isNewConstructionByYearBuilt(yearBuilt, currentYear),
      notNewConstruction: !isNewConstructionByYearBuilt(yearBuilt, currentYear),
      squareFootage: getSquareFootageRangeValue(squareFootage),
      residenceType: dwellingTypeDictionary[residenceType],
      typeOfResidence: dwellingTypeDictionary[residenceType],
      propertyID: null, // shouldn't be necessary anymore
      mlsNumber: order.order?.mlsValue || '',
    };
  }

  mapToSellerInfo(order: Order): NewOrderSellerInfo {
    if (!order.order?.customers) {
      return null;
    }
    const represents = this.getRepresents(order);
    const { seller, coseller } = order.order.customers;
    const { seller: sellerAddress, coseller: cosellerAddress } =
      order.details.customerMailingAddresses || {};

    return {
      sellerInfo: {
        yes: !!seller?.firstName,
        no: !seller?.firstName,
      },
      coSeller: !!coseller?.firstName,
      coSellerFields: !!coseller?.firstName,
      coSellerAlternateAddressFields: !!cosellerAddress?.address1,
      ...this.mapCustomer(
        seller,
        sellerAddress,
        ['SELLER', 'BOTH'].includes(represents.toLocaleUpperCase()),
      ),
      ...this.mapCoCustomer(coseller, cosellerAddress),
      ...(represents.toLocaleUpperCase() === 'BUYER' && this.mapCooperatingOfficeAgent(order)),
    };
  }

  mapToBuyerInfo(order: Order): NewOrderBuyerInfo {
    if (!order.order?.customers) {
      return null;
    }
    const represents = this.getRepresents(order);
    const { buyer, cobuyer } = order.order.customers;
    const { buyer: buyerAddress, cobuyer: cobuyerAddress } =
      order.details.customerMailingAddresses || {};

    return {
      buyerInfo: {
        yes: !!buyer?.firstName,
        no: !buyer?.firstName,
      },
      alternateAddressFields: !!buyerAddress?.address1,
      coBuyer: !!cobuyer?.firstName,
      coBuyerFields: !!cobuyer?.firstName,
      coBuyerAlternateAddressFields: !!cobuyerAddress?.address1,
      ...this.mapCustomer(
        buyer,
        buyerAddress,
        ['BUYER', 'BOTH'].includes(represents.toLocaleUpperCase()),
      ),
      ...this.mapCoCustomer(cobuyer, cobuyerAddress),
      ...(represents.toLocaleUpperCase() === 'SELLER' && this.mapCooperatingOfficeAgent(order)),
    };
  }

  mapCustomer(customer: Customer, address: Address, needEmailOrPhone: boolean): NewOrderCustomer {
    return {
      customerId: null,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      emailId: null,
      phone: customer?.phones?.[0]?.phone,
      phoneType: customer?.phones?.[0]?.phoneType,
      needEmailOrPhone,

      alternateAddressFields: !!address?.address1,
      streetAddress: address?.address1,
      unit: address?.address2,
      city: address?.city,
      state: address?.state,
      zipCode: address?.zip,
      propertyId: null,
    };
  }

  mapCoCustomer(customer: Customer, address: Address): NewOrderCoCustomer {
    return {
      coCustomerId: null,
      coCustomerFirstName: customer?.firstName,
      coCustomerLastName: customer?.lastName,
      coCustomerEmail: customer?.email,
      coCustomerEmailId: null,
      coCustomerPhone: customer?.phones?.[0]?.phone,
      coCustomerPhoneType: customer?.phones?.[0]?.phoneType,

      coCustomerStreetAddress: address?.address1,
      coCustomerUnit: address?.address2,
      coCustomerCity: address?.city,
      coCustomerState: address?.state,
      coCustomerZipCode: address?.zip,
      coCustomerPropertyId: null,
    };
  }

  mapCooperatingOfficeAgent(order: Order): NewOrderCooperatingAgent {
    const { cooperatingOffice } = order.order?.offices || {};
    const { cooperating: details } = order.details?.officeAgents || {};
    const agentName =
      (details?.agent && `${details.agent.firstName} ${details.agent.lastName}`) ||
      (cooperatingOffice?.agentFirstName &&
        `${cooperatingOffice.agentFirstName} ${cooperatingOffice.agentLastName}`);
    const agentEmail = details?.agent?.email || cooperatingOffice?.agentEmail;
    return {
      office: details?.office,
      cooperatingOffice: details?.office?.name,
      cooperatingAgent: agentName, // agent full name
      AgentId: details?.agent?.realEstateAgentID,
      AgentName: agentName,
      AgentEmail: agentEmail,
    };
  }

  mapToClosingInfo(order: Order): NewOrderClosingInfo {
    const { closing: details } = order.details?.officeAgents || {};
    const { escrowNumber, estimatedClosingDate } = order.order;

    const data = {
      closingFileNumber: escrowNumber,
      closingTitleCompany: details?.office?.name,
      closingTitleAgent: details?.agent && `${details.agent.firstName} ${details.agent.lastName}`,
      titleCompany: {
        yes: !!details?.office,
        no: !details?.office,
      },
      AgentId: details?.agent?.realEstateAgentID,
      AgentEmail: details?.agent?.email,
      closingOffice: details?.office,
      office: details?.office,
      dateUnknown: !estimatedClosingDate,
      projectedClosingDate: formatDate(estimatedClosingDate),
    };

    if (details?.office && !details?.agent) {
      data.closingTitleAgent = `${NOT_APPLICABLE_OPTION.firstName} ${NOT_APPLICABLE_OPTION.lastName}`;
    }

    return data;
  }
}

export default NewOrderLoader;
