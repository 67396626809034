import React, { useEffect, useMemo, useState } from 'react';
import OrderForm from '@components/orders/forms/OrderForm';
import {
  CustomerType,
  OrderAddressInfoFormData,
  OrderCustomerFormData,
} from '@app/models/order/order.model';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { Button, Checkbox, Input, RadioGroup } from '@ftdr/blueprint-components-react';
import EmailInput from '@components/input/EmailInput';
import PhoneInput from '@components/input/PhoneInput';
import OrderFormAddressInfo from './OrderFormAddressInfo';
import { NewOrderContextData } from '@context/NewOrderContext/model';

export enum CustomerEmailPhoneRequirement {
  EmailOrPhoneRequired,
  EmailAndPhoneRequired,
  EmailAndPhoneOptional,
}

export enum OrderFormCustomerInfoField {
  firstName = 'firstName',
  lastName = 'lastName',
  emailAddress = 'emailAddress',
  phoneNumber = 'phoneNumber',
  zip = 'zip',
  streetAddress = 'streetAddress',
  unitNumber = 'unitNumber',
  city = 'city',
  state = 'state',
}

interface OrderFormCustomerInfoProps
  extends OrderFormProps<OrderCustomerFormData, OrderFormCustomerInfoField> {
  ids: Record<OrderFormCustomerInfoField, string>;
  label: string;
  savedData: NewOrderContextData;

  /** buyer/seller uses "Alternative Mailing Address" while co-customers uses "Mailing Address" */
  customerAddressCheckboxLabel?: string;
  /** buyer/seller uses "Alternative Mailing Address" while co-customers uses "Mailing Address" */
  customerAddressLabel?: string;
  /** when validate address is pressed, we want to validate the address form */
  onClickValidateAddress: (address: OrderAddressInfoFormData, customerType: CustomerType) => void;

  /** when set to false, we display a question to the user if they have the customer and display the form.
   * otherwise, when set to true, we always display the customer form with no questions. */
  isCustomerRequired?: boolean;
  /** customer email/phone requirement rules differ per customer. control it via this to change UX */
  isCustomerContactRequired?: CustomerEmailPhoneRequirement;

  /** in event that customer is optional, the radio would have a question id */
  customerQuestionId?: string;
  /** in event that customer is optional, the question input field's name */
  customerQuestionName?: string;
  /** in event that customer is optional, the label for the question */
  customerQuestionLabel?: string;

  /** renders at the top of the container if provided */
  topContentRenderer?: JSX.Element;
  /** renders at the bottom of the customer form if provided, only when customer info is shown */
  customerBottomContentRenderer?: JSX.Element;
  /** Use Alternative Mailing Address is checked, we want to show customer address form */
  toggleIncludeCustomerAddress: () => void;
  showCustomerAddressForm: boolean;
  /** Do you have Buyer/Seller RadioGroup Value*/
  toogleCustomerInfoFormRadio?: (value: boolean) => void;
}

const OrderFormCustomerInfo: React.FC<OrderFormCustomerInfoProps> = (props) => {
  const [showCustomerInfoFormRadio, setShowCustomerInfoFormRadio] = useState<string>('');

  const handleCustomerInfoFormRadio = (value: string) => {
    setShowCustomerInfoFormRadio(value);
    if (value === 'YES') {
      props.toogleCustomerInfoFormRadio(true);
    } else if (value === 'NO') {
      props.toogleCustomerInfoFormRadio(false);
    }
  };

  useEffect(() => {
    if (props.isCustomerRequired) {
      setShowCustomerInfoFormRadio('YES');
    } else {
      // if customer information had been partially entered, then mark it yes. Otherwise mark it with no.
      if (props.formData.firstName !== '') {
        setShowCustomerInfoFormRadio('YES');
      } else {
        setShowCustomerInfoFormRadio('NO');
      }
    }
  }, [props.isCustomerRequired]);

  const onInputBlur = (field: OrderFormCustomerInfoField, value: string) => {
    props.onValidateField(field, value);
  };

  const onInputChange = (field: OrderFormCustomerInfoField, value: string) => {
    props.clearFieldError(field);
    props.onUpdateFormData({
      ...props.formData,
      [field]: value,
    });
  };

  const showPhoneRequired = useMemo(() => {
    return props.isCustomerContactRequired !== CustomerEmailPhoneRequirement.EmailAndPhoneOptional;
  }, [props.isCustomerContactRequired]);

  const showEmailRequired = useMemo(() => {
    return props.isCustomerContactRequired !== CustomerEmailPhoneRequirement.EmailAndPhoneOptional;
  }, [props.isCustomerContactRequired]);

  return (
    <>
      <OrderForm.Container id="customer-info-form" heading={props.label}>
        {props.topContentRenderer}
        {!props.isCustomerRequired && (
          <OrderForm.Row>
            <RadioGroup
              formField={true}
              id={props.customerQuestionId}
              name={props.customerQuestionName}
              label={props.customerQuestionLabel}
              radios={[
                {
                  label: 'Yes',
                  value: 'YES',
                },
                {
                  label: 'No',
                  value: 'NO',
                },
              ]}
              onChange={(value) => handleCustomerInfoFormRadio(value)}
              value={showCustomerInfoFormRadio}
              orientation="horizontal"
            />
          </OrderForm.Row>
        )}
        {(props.isCustomerRequired || showCustomerInfoFormRadio === 'YES') && (
          <>
            <OrderForm.Row>
              <OrderForm.RowItem>
                <Input
                  formField={true}
                  id={props.ids.firstName}
                  name="firstName"
                  inputMode="text"
                  type="text"
                  required={true}
                  value={props.formData.firstName}
                  error={props.errors.firstName}
                  autoComplete="off"
                  onBlur={(e) =>
                    onInputBlur(OrderFormCustomerInfoField.firstName, e.currentTarget.value)
                  }
                  onChange={(e) =>
                    onInputChange(OrderFormCustomerInfoField.firstName, e.currentTarget.value)
                  }
                  label="First Name"
                />
              </OrderForm.RowItem>
              <OrderForm.RowItem>
                <Input
                  formField={true}
                  id={props.ids.lastName}
                  label="Last Name"
                  name="lastName"
                  inputMode="text"
                  type="text"
                  required={true}
                  value={props.formData.lastName}
                  error={props.errors.lastName}
                  autoComplete="off"
                  onBlur={(e) =>
                    onInputBlur(OrderFormCustomerInfoField.lastName, e.currentTarget.value)
                  }
                  onChange={(e) =>
                    onInputChange(OrderFormCustomerInfoField.lastName, e.currentTarget.value)
                  }
                />
              </OrderForm.RowItem>
            </OrderForm.Row>

            {/* email and phoneNumber */}
            <OrderForm.Row>
              <OrderForm.RowItem>
                <EmailInput
                  required={showEmailRequired}
                  formField={true}
                  id={props.ids.emailAddress}
                  name="email"
                  value={props.formData.emailAddress}
                  error={props.errors.emailAddress}
                  autoComplete="off"
                  onBlur={(e) =>
                    onInputBlur(OrderFormCustomerInfoField.emailAddress, e.target.value)
                  }
                  onChangeValue={(email) =>
                    onInputChange(OrderFormCustomerInfoField.emailAddress, email)
                  }
                  label="Email Address"
                  onClearError={() => {}}
                  onError={(error) =>
                    props.onFieldError(OrderFormCustomerInfoField.emailAddress, error)
                  }
                />
              </OrderForm.RowItem>
              <OrderForm.RowItem>
                <PhoneInput
                  required={showPhoneRequired}
                  formField={true}
                  id={props.ids.phoneNumber}
                  name="phone"
                  label="Phone Number"
                  disabled={false}
                  value={props.formData.phoneNumber}
                  error={props.errors.phoneNumber}
                  onClearError={() => {}}
                  onError={(error) => props.onFormError(error)}
                  onBlur={(e) =>
                    onInputBlur(OrderFormCustomerInfoField.phoneNumber, e.target.value)
                  }
                  onChangeValue={(phone) =>
                    onInputChange(OrderFormCustomerInfoField.phoneNumber, phone)
                  }
                />
              </OrderForm.RowItem>
            </OrderForm.Row>

            <div id="alternate-mailing-address">
              <Checkbox
                id={`wb-${props.formData.customerType}AltAddress`}
                label={props.customerAddressCheckboxLabel}
                onChange={props.toggleIncludeCustomerAddress}
                checked={props.showCustomerAddressForm}
              />

              {props.showCustomerAddressForm && (
                <div className="mt-2 mb-4">
                  <OrderFormAddressInfo
                    label={props.customerAddressLabel}
                    ids={props.ids}
                    allowPOBox={true}
                    formData={props.formData}
                    clearFieldError={(fieldName) =>
                      props.clearFieldError(fieldName as unknown as OrderFormCustomerInfoField)
                    }
                    errors={props.errors}
                    onFieldError={(fieldName, error) =>
                      props.onFieldError(fieldName as unknown as OrderFormCustomerInfoField, error)
                    }
                    onUpdateFormData={(addressData) =>
                      props.onUpdateFormData({ ...props.formData, ...addressData })
                    }
                    onValidateField={(fieldName, value) =>
                      props.onValidateField(
                        fieldName as unknown as OrderFormCustomerInfoField,
                        value,
                      )
                    }
                    onFormError={props.onFormError}
                  />
                  <Button
                    label="Validate address to continue"
                    id="wb_validateAddressContinue"
                    variant="outlined"
                    size="medium"
                    onClick={() =>
                      props.onClickValidateAddress(props.formData, props.formData.customerType)
                    }
                  />
                </div>
              )}
            </div>

            {props.customerBottomContentRenderer}
          </>
        )}
      </OrderForm.Container>
    </>
  );
};

OrderFormCustomerInfo.defaultProps = {
  customerAddressCheckboxLabel: 'Use Alternative Mailing Address',
  customerAddressLabel: 'Mailing Address',
  topContentRenderer: <></>,
  customerBottomContentRenderer: <></>,
};

export default OrderFormCustomerInfo;
