import React, { useContext, useState } from 'react';
import LogoAHS from '@static/logo.svg';
import LogoHSA from '@static/logo-hsa.png';
import { useThemeContext } from '@ThemeContext';
import { Button } from '@ftdr/blueprint-components-react';
import FusionAuthApi from '@apis/fuionAuth.api';
import { classNames, isSmallView } from '@utils';
import { clearAuthUser, hardLogout } from '@helpers/auth.utils';
import getBrandConstants from '@constants/brands';
import { fireGAEvent } from '@app/core/tracking.service';
import { ACCOUNT_MANAGEMENT__RESEND_EMAIL } from '@constants/ga-events.constants';

interface Props {
  authService: any;
}

const VerifyEmail: React.FC<Props> = (props) => {
  const theme = useThemeContext();
  const { oidcUser } = props.authService;

  const isSmall = isSmallView();

  if (!oidcUser) {
    clearAuthUser();
    window.location.assign(`/`);
  }

  const email = oidcUser?.profile?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [msgText, setMsgText] = useState<{ text: string; isError?: boolean }>(null);

  clearAuthUser();

  const reSendEmail = () => {
    fireGAEvent(ACCOUNT_MANAGEMENT__RESEND_EMAIL);
    setIsLoading(true);
    FusionAuthApi.reSendEmail(email)
      .then(() => {
        setMsgText({ text: 'Email Sent.' });
      })
      .catch(() => {
        setMsgText({
          text: 'Something went wrong, please try again.',
          isError: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div id="verifyEmail" className="container pt-16">
      <div className="flex justify-center">
        <img
          data-testid={`logo-${theme}`}
          className="h-16"
          src={theme === 'hsa' ? LogoHSA : LogoAHS}
        />
      </div>
      <h3 className="text-center mt-14 h3">Please check your email</h3>
      <p className="mt-5 text-center">
        An email has been sent to your inbox from {getBrandConstants().verificationEmail} with a
        link to verify your email address. If you do not see the verification email in your inbox,
        check your spam folder. Click on the link in the email to continue with the registration
        process.
      </p>
      {msgText?.text && (
        <p
          className={classNames([
            'my-1 text-center font-bold',
            msgText.isError ? 'text-red-300' : 'text-green-300',
          ])}
        >
          {msgText.text}
        </p>
      )}
      <div className="flex items-center justify-center mt-10">
        <Button
          id="verify-resend-email"
          variant="outlined"
          size="small"
          className="md:mr-10"
          disabled={isLoading}
          onClick={reSendEmail}
          label="Resend Email"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
        />
        <Button
          id="verify-logout-btn"
          variant="outlined"
          size="small"
          onClick={hardLogout}
          label="Back to Sign Up"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
