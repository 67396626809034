export const JUST_LOGGED_IN_KEY = 'justLoggedIn';
export const JUST_REGISTERED = 'justRegistered';
export const JUST_LOGGED_IN_BANNER_KEY = 'justLoggedInBannerActive';

export const QUOTE_CANCEL_LIST_KEY = 'cancelQuoteList';

export const setCancelQuoteLocal = (quoteId) => {
  let quoteCancelListString = localStorage.getItem(QUOTE_CANCEL_LIST_KEY) || '[]';
  const quoteIds = JSON.parse(quoteCancelListString);
  if (!quoteIds.includes(quoteId)) {
    quoteIds.push(quoteId);
    quoteCancelListString = JSON.stringify(quoteIds);
    localStorage.setItem(QUOTE_CANCEL_LIST_KEY, quoteCancelListString);
  }
};

export const clearCancelQuoteLocalList = () => {
  localStorage.setItem(QUOTE_CANCEL_LIST_KEY, '[]');
};

export const getCancelQuoteLocalList = () => {
  const quoteCancelListString = localStorage.getItem(QUOTE_CANCEL_LIST_KEY) || '[]';
  return JSON.parse(quoteCancelListString);
};

export const isQuoteLocalCancelling = (quoteId) => {
  const quoteCancelListString = localStorage.getItem(QUOTE_CANCEL_LIST_KEY) || '[]';
  const quoteIds = JSON.parse(quoteCancelListString);
  return quoteIds.includes(quoteId);
};
