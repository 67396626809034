import { BaseAPI } from './_api';
import {
  ProductAndCoveragesResponse,
  ProductAvailabilityByLocationRequest,
  ProductAvailabilityByLocationResponse,
  ProductAvailabilityRequest,
  ProductAvailabilityResponse,
  ProductFilters,
  ProductFiltersRequest,
  ProductPricingDetailsRequest,
  ProductPricingDetailsResponse,
  ProductPricingRequest,
  ProductPricingResponse,
  CreateQuoteResponse,
  ProductPricingDetailsSaveQuoteRequest,
} from './models';
import { coverageNameOverridesDictionary } from '@constants/dictionaries';

export class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      true,
      suppressErrors,
    );
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  sqft(squareFootage: number | string): number {
    let sqft = 4999;
    if (squareFootage === 'between') sqft = 9999;
    else if (squareFootage === 'above') sqft = 10000;

    return sqft;
  }

  /** From an order, get the filters for the plans and coverage. */
  public async getFilters(body: ProductFiltersRequest): Promise<ProductFilters> {
    return this.axios.post<any, ProductFilters>(`${this.getHost()}/product/filters`, body);
  }

  public async savePlansAndCoverages(contractId, planCoverageDetails): Promise<any> {
    const body = {
      id: planCoverageDetails.activePlan,
      selected_optional_coverages: planCoverageDetails.selectedOptionalCoverage
        .filter((coverage) => coverage.meta.coverageId !== '0')
        .map((coverage) => {
          return {
            id: coverage.meta.coverageId,
            count: !coverage.quantity || coverage.quantity === 0 ? 1 : coverage.quantity,
          };
        }),
      selectedOptionalCoverageGroups: planCoverageDetails.selectedOptionalCoverage
        .filter((coverage) => coverage.meta.groupId !== '0')
        .map((coverage) => {
          return {
            id: coverage.meta.groupId,
            count: !coverage.quantity || coverage.quantity === 0 ? 1 : coverage.quantity,
          };
        }),
      guest_unit_count: planCoverageDetails.guestUnitCoverage?.some(
        (coverage) => coverage.value === true,
      )
        ? 1
        : 0,
    };
    return this.axios.patch<any, any>(
      `${this.getHost()}/contract/${contractId}/plan-and-coverages`,
      body,
    );
  }

  /** determine product availability generalized for a location to determine if there are products available */
  public async getProductAvailabilityByLocation(
    req: ProductAvailabilityByLocationRequest,
  ): Promise<ProductAvailabilityByLocationResponse> {
    return this.axios.get<any, ProductAvailabilityByLocationResponse>(
      `${this.getHost()}/products/availability`,
      {
        params: req,
      },
    );
  }

  /** retrieve available products offered for the request */
  public async getProductAvailability(
    body: ProductAvailabilityRequest,
  ): Promise<ProductAvailabilityResponse> {
    return this.axios.post<any, ProductAvailabilityResponse>(
      `${this.getHost()}/products/availability`,
      body,
    );
  }

  /** get the product and coverage information for a given product id */
  public async getProductCoveragesByProductId(
    id: string,
    residenceType: string,
  ): Promise<ProductAndCoveragesResponse> {
    const params = {
      residenceType,
    };
    return this.axios
      .get<any, ProductAndCoveragesResponse>(`${this.getHost()}/products/${id}`, { params })
      .then((res) => {
        res.coverages.included = res.coverages.included.map((cvg) => {
          cvg.name = coverageNameOverridesDictionary[cvg.name] || cvg.name;
          return cvg;
        });
        res.coverages.optional = res.coverages.optional.map((cvg) => {
          cvg.name = coverageNameOverridesDictionary[cvg.name] || cvg.name;
          return cvg;
        });
        return res;
      });
  }

  /** get product and coverage initial pricing information for given product id */
  public async getProductPricingByProductId(
    id: string,
    body: ProductPricingRequest,
  ): Promise<ProductPricingResponse> {
    return this.axios.post<any, ProductPricingResponse>(
      `${this.getHost()}/products/${id}/pricing`,
      body,
    );
  }

  /** get product pricing details, returning the price summary and the individual coverage prices (for quantity/tier calculations) */
  public async getProductPricingDetails(
    body: ProductPricingDetailsRequest,
  ): Promise<ProductPricingDetailsResponse> {
    return this.axios.post<any, ProductPricingDetailsResponse>(
      `${this.getHost()}/products/pricing`,
      body,
    );
  }

  /** createQuote returning the quoteID */
  public async createQuote(body: ProductPricingDetailsRequest): Promise<CreateQuoteResponse> {
    return this.axios.post<any, CreateQuoteResponse>(`${this.getHost()}/products/quote`, body);
  }

  /** createQuote returning the quoteID & orderId */
  public async saveQuote(
    body: ProductPricingDetailsSaveQuoteRequest,
  ): Promise<CreateQuoteResponse> {
    return this.axios.post<any, CreateQuoteResponse>(
      `${this.getHost()}/plans-and-prices/order`,
      body,
    );
  }
}

const ProductApi = new API();
export const ProductApiSuppressErrors = new API(true);
export default ProductApi;
