import React, { Component } from 'react';

// Components
import { Button } from '@ftdr/blueprint-components-react';
import CardRadio from '../../components/card/CardRadio';
import CardRadioItem from '../../components/card/CardRadioItem';
import { EXISTING_USER_WARNING } from '../../constants/formField-constants';
import Path from '@constants/paths';
import useGlobalAlert, { GlobalAlertFns, Message } from '@app/core/GlobalAlertModal';

interface IProps {
  type?: string;
  handleUserSelectionChange: (selectedAgentIndex: number) => void;
  continueHandler: (...args: any[]) => any;
  noneOfTheseBtnHandler: (...args: any[]) => any;
  closeModalHandler: (...args: any[]) => any;
  proceedToLogin: (...args: any[]) => any;
  isExistingUser: boolean;
  agentsList: any[];
  isLoading: boolean;
}

interface State {
  selectedUserIndex: number;
}

interface IPropsWithAlertHook extends IProps {
  globalAlert: GlobalAlertFns;
}

export function ExistingUserTemplate(props: IProps) {
  const globalAlert: GlobalAlertFns = useGlobalAlert();
  return <ExistingUserTemplateWitAlertHook {...props} globalAlert={globalAlert} />;
}

class ExistingUserTemplateWitAlertHook extends Component<IPropsWithAlertHook, State> {
  existingUserWarning: Message;

  constructor(props: IPropsWithAlertHook) {
    super(props);
    this.state = {
      selectedUserIndex: -1,
    };
    this.existingUserWarning = {
      onClose: () => {}, // todo: need to figure out how to override the default.
      message: EXISTING_USER_WARNING,
      actions: [
        {
          href: Path.ContactUs,
          label: 'Contact Us',
        },
        {
          onClick: props.proceedToLogin,
          label: 'Proceed to Login',
        },
      ],
    };
  }

  componentDidMount() {
    this.warnOnExistingUser();
  }

  componentDidUpdate() {
    this.warnOnExistingUser();
  }

  handleUserSelectionChange = (index: number) => {
    this.setState({
      selectedUserIndex: index,
    });
    this.props.handleUserSelectionChange(index);
  };

  warnOnExistingUser() {
    if (this.props.isExistingUser) {
      this.props.globalAlert.addWarningToQueue(this.existingUserWarning);
    }
  }

  render() {
    const { props } = this;
    return (
      <>
        <h1 className="h3">{props.type === 'self' ? 'Is this you?' : 'Is this them?'}</h1>

        <p className="mt-2 text-primary-400">
          {props.type === 'self'
            ? 'We found possible matches for your information in our database.'
            : 'We found possible matches for this employee in our database.'}
        </p>

        <div className="my-8">
          <p>{props.agentsList.length} possible matches found</p>
          <CardRadio onClick={this.handleUserSelectionChange}>
            {props.agentsList.map((agent, idx) => {
              let officeDetailString = this.props.isLoading ? 'LOADING....' : 'NA';
              if (agent.officeDetails && agent.officeDetails.name) {
                officeDetailString = agent.officeDetails.name;
                if (agent.agentType === 'RealEstate')
                  officeDetailString = `Real Estate Agent at ${officeDetailString}`;
                else officeDetailString = `Closing Agent at ${officeDetailString}`;
              }
              return (
                <CardRadioItem
                  key={idx}
                  title={`${agent.firstName} ${agent.lastName}`}
                  leftContent={<p>{officeDetailString}</p>}
                  rightContent={
                    <p>
                      <span className="xs-max:hidden block truncate">{agent.email}</span>
                      {agent.phoneNumbers.length > 0 && agent.phoneNumbers[0].phoneNumber}
                    </p>
                  }
                />
              );
            })}
          </CardRadio>
        </div>

        <div className="md:flex md:items-center md:flex-row-reverse">
          <div>
            <Button
              id="continue-btn"
              className="sm-max:block md:ml-4"
              variant="filled"
              label="Continue"
              color="interactive"
              disabled={this.state.selectedUserIndex === -1}
              onClick={props.continueHandler}
              width="full"
              labelAlign="center"
            />
          </div>
          <div>
            <Button
              id="noneOfThese-btn"
              className="sm-max:block sm-max:mt-4"
              variant="outlined"
              label={
                props.type === 'self' ? 'None of these are me' : 'None of these are my employee'
              }
              color="interactive"
              disabled={false}
              onClick={props.noneOfTheseBtnHandler}
              width="full"
              labelAlign="center"
            />
          </div>
        </div>
      </>
    );
  }
}

export default ExistingUserTemplate;
