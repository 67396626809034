import * as app from '@app/models';
import { OrderHistory } from '@app/models';
import * as mockDataService from '@services/orders.service';
import { getBrand } from '@helpers/brand.utils';
import { AxiosError, Canceler } from 'axios';
import { BaseAPI } from './_api';
import {
  CancelListingRequest,
  Contract,
  ContractBalance,
  ContractDocumentType,
  ContractEmailDocsRequest,
  ContractPlanCoverages,
  ContractPricing,
  ContractSearchByPropertyResponse,
  CreateOrderRequest,
  EditOrderClosingInfo,
  EditOrderPropertyDetails,
  GetContractDocumentResponse,
  GetContractIDsResponse,
  GetContractsResponse,
  GlobalSearchContractRequest,
  MakePaymentResponse,
  REOrderSearchResponse,
  SearchContractByPropertyRequest,
  UpsertOrderAgent,
  UpsertOrderCustomerRequest,
} from './models';
import {
  DateSortable,
  sortByDate,
  translateNewOrderErrorMessages,
  translateREOrderSearchResponse,
  translateToOrderModel,
} from './utils/contract.api.utils';
import { coverageNameOverridesDictionary } from '@constants/dictionaries';
import { publishToRefreshedContracts } from '@app/core/refreshContract.service';

export class API extends BaseAPI {
  constructor(suppressErrors = false) {
    super(
      'app-ms',
      true,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Brand-Name': getBrand(),
        },
      },
      true,
      suppressErrors,
    );
  }

  /** Healthcheck */
  public async healthcheck(): Promise<any> {
    return this.axios.get(`${this.getHost()}/healthcheck`);
  }

  public async chargeACH(request, contractId: number): Promise<MakePaymentResponse> {
    return this.axios
      .post(`${this.getHost()}/contract/${contractId}/charge-ach`, request)
      .then((res) => {
        console.log('axios call res:', res);
        return res;
      })
      .catch((err) => {
        console.error('Error in charge-ach = ', err);
        return null;
      });
  }

  public async chargeCC(request, contractId: number): Promise<MakePaymentResponse> {
    return this.axios
      .post(`${this.getHost()}/contract/${contractId}/charge-cc`, request)
      .then((res) => {
        console.log('axios call res:', res);
        return res;
      })
      .catch((err) => {
        console.error('Error in charge-cc = ', err);
        return null;
      });
  }

  /** Get contract ids for a given user role. */
  public async getContractIdsForUser(): Promise<string[]> {
    return this.axios
      .get<any, GetContractIDsResponse>(`${this.getHost()}/profile/contracts`)
      .then((res) => res.contracts);
  }

  /** Returns the full contract information by its contract ids. */
  public async getContractDetails(
    contractIds: string[],
    withFeatures = false,
    withDetails = false,
    opts = {},
  ): Promise<Contract[]> {
    const params = {
      ids: contractIds.join(','),
      includeFeature: withFeatures,
      includeDetail: withDetails,
    };
    return this.axios
      .get<any, GetContractsResponse>(`${this.getHost()}/contracts`, { params, ...opts })
      .then((res) => {
        if (res) {
          return res.contracts.map(translateToOrderModel);
        }
        return [];
      })
      .then((contracts) => {
        publishToRefreshedContracts(contracts);
        return contracts;
      })
      .catch((err) => {
        console.error('Error in call = ', err);
        return err;
      });
  }

  /** Given an address, search to see if there are any existing contract and return. */
  public async searchContractByAddress(
    address: app.Address,
  ): Promise<ContractSearchByPropertyResponse> {
    const body: SearchContractByPropertyRequest = {
      address: {
        address1: address.streetAddress,
        address2: address.unit,
        city: address.city,
        state: address.state,
        zip: address.zip,
      },
    };
    return this.axios
      .post<any, ContractSearchByPropertyResponse>(
        `${this.getHost()}/contract/search/property`,
        body,
      )
      .then((res) => {
        return res.contractID.length > 0 ? res : <ContractSearchByPropertyResponse>{};
      })
      .catch(() => {
        // Return as empty contract for now if failed to find.
        // Should check status codes in the future.
        return <ContractSearchByPropertyResponse>{};
      });
  }

  /**
   * Note: This function should be used on temporary basis untill contract list/detail apis are ready.
   * TODO: app.Order model is not finalized yet as per mockdata returned
   * @deprecated: TODO: Delete this
   */
  public async searchContractByIdTemp(contractId: string): Promise<any> {
    return this.axios
      .post('')
      .then((res) => {
        return res;
      })
      .catch((e) => {
        // TODO: Errors need to be handled according to status codes
        console.log('Error in fetch order api error, returning mock data');
        return mockDataService.getMockData();
      });
  }

  public async emailContractDocuments(request, contractId: number): Promise<any> {
    const body: ContractEmailDocsRequest = {
      docEmails: request.docEmails,
    };
    return this.axios
      .post<any, any>(`${this.getHost()}/contract/${contractId}/email-documents`, body)
      .then((res) => {
        // note: here if the api is successful then the res is blank so
        // so returning success as string to let us know in the component
        return 'success';
      })
      .catch((err) => {
        // TODO: Errors need to be handled according to status codes
        console.log('Error in emailContractDocuments = ', err);
        return null;
      });
  }

  /** Submits a new order to be created */
  public async submitNewOrder(request: CreateOrderRequest): Promise<any> {
    request.createSource = 'RE Portal';
    return this.axios
      .post<any, any>(`${this.getHost('app-ms')}/contract/create`, request)
      .then((res) => {
        if (res && res.contract) {
          return res;
        }
        return translateNewOrderErrorMessages(res);
      });
  }

  public async getContractBalance(contractId: string): Promise<ContractBalance> {
    return this.axios.get<any, any>(
      `${this.getHost('app-ms')}/contract/${contractId}/payment-balance`,
    );
  }

  public async extendListing(contractId: string): Promise<boolean> {
    return this.axios
      .post(`${this.getHost()}/contract/${contractId}/extend-listing`, {})
      .then(() => {
        return true; // Success has blank response
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred in extend listing = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  /** Retrieve the blob PDF values of contract document */
  public async getContractDocument(
    contractId: string,
    docTypes: ContractDocumentType[],
  ): Promise<GetContractDocumentResponse> {
    return this.axios.get(`${this.getHost()}/contract/${contractId}/documents`, {
      params: {
        types: docTypes.join(','),
      },
    });
  }

  public async cancelListing(contractId: string, data: CancelListingRequest): Promise<boolean> {
    return this.axios
      .post(`${this.getHost()}/contract/${contractId}/cancel-listing`, data)
      .then(() => {
        return true; // Success has blank response
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred in extend listing = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  /** Update the closing information and MLS number */
  public async updateContractClosingAndMLSNumber(
    contractId: string,
    data: EditOrderClosingInfo,
  ): Promise<boolean> {
    return this.axios
      .put(`${this.getHost()}/contract/${contractId}/closing`, data)
      .then(() => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.error('Error occurred in update closing and MLS = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  public async saveBuyerPhone(contractId: string, buyerPhone: string): Promise<boolean> {
    return this.axios
      .post(`${this.getHost()}/contract/${contractId}/save-buyer-phone`, { buyerPhone })
      .then(() => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.error('Error occurred in saving buyer phone = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  /** Remove the closing information and buyer information */
  public async removeContractClosingAndBuyer(contractId: string): Promise<boolean> {
    return this.axios
      .delete(`${this.getHost()}/contract/${contractId}/closing`)
      .then(() => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.error('Error occurred in deleting closing and buyer = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  public async upsertCustomerInfo(
    contractId: string,
    data: UpsertOrderCustomerRequest,
  ): Promise<boolean> {
    return this.axios
      .put(`${this.getHost()}/contract/${contractId}/customer`, data)
      .then(() => {
        return true; // Success has blank response
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred in customer info upsert = ', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  /** searches using global search. returns the promise and a canceler function for cancellation strategy.
   * if no limit is provided, defaults to 16 */
  public searchGlobalContracts(
    req: GlobalSearchContractRequest,
  ): [() => Promise<REOrderSearchResponse>, Canceler] {
    const cancelTokenSource = this.getCancelTokenSource();

    if (!req.limit) {
      req.limit = 16;
    }

    const promise = async () => {
      return this.axios.post<any, REOrderSearchResponse>(
        `${this.getHost()}/contract/search/global`,
        req,
        {
          cancelToken: cancelTokenSource.token,
        },
      ).then((response) => {
        return translateREOrderSearchResponse(response);
      });
    };

    return [promise, cancelTokenSource.cancel];
  }

  public async getContractEditHistory(contractID: string): Promise<OrderHistory[]> {
    return this.axios
      .get<any, any>(`${this.getHost()}/contract/${contractID}/editHistory`)
      .then((res) => {
        const sortedByDate = sortByDate(res.history as DateSortable[]);
        return sortedByDate;
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred: Fetching Edit History', err);
      });
  }

  public async updateCooperatingAgentInfo(
    contractID: string,
    data: UpsertOrderAgent,
  ): Promise<boolean> {
    return this.axios
      .put<any, any>(`${this.getHost()}/contract/${contractID}/cooperatingAgent`, data)
      .then((res) => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred while updating cooperating agent on contract', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  public async updateAgentInfo(contractID: string, data: UpsertOrderAgent): Promise<boolean> {
    return this.axios
      .put<any, any>(`${this.getHost()}/contract/${contractID}/agent`, data)
      .then((res) => {
        return true;
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred while updating agent on contract', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }

  public async getContractPricing(contractID: string): Promise<ContractPricing> {
    return this.axios.get<any, ContractPricing>(`${this.getHost()}/contract/${contractID}/pricing`);
  }

  public async getContractPlanCoverages(contractID: string): Promise<ContractPlanCoverages> {
    return this.axios
      .get<
        any,
        ContractPlanCoverages
      >(`${this.getHost()}/contract/${contractID}/plan-and-coverages`)
      .then((res) => {
        res.optionalCoverages = res.optionalCoverages.map((cvg) => {
          cvg.name = coverageNameOverridesDictionary[cvg.name] || cvg.name;
          return cvg;
        });
        return res;
      });
  }

  public async editContract(contractID: string, data: EditOrderPropertyDetails): Promise<boolean> {
    return this.axios
      .patch<any, any>(`${this.getHost()}/contract/${contractID}`, data)
      .then((res) => {
        return res;
      })
      .catch((err: AxiosError<any>) => {
        console.log('Error occurred while updating property details on contract', err);
        if (err.isAxiosError) {
          throw err.response.data;
        }
        throw err;
      });
  }
}

const ContractApi = new API();
export const ContractApiSuppressErrors = new API(true);
export default ContractApi;
