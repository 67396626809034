import { Contract } from '@apis/models/contract.api.model';
import { ContractModelVariant } from '@app/models/contract.model';
import { Recipient, Type as DocumentType } from '@components/SendDocuments';
import { getContractRecipientsForNotifications } from '@helpers/order.utils';
import { get, isEmpty, map } from 'lodash';
import { compose } from 'lodash/fp';
import { NewOrderContextData } from '@context/NewOrderContext/model';

interface ContractModelTypeMapping {
  type: RecipientType;
  emailGetter: string | ((contract: ContractType) => string);
  nameGetter: string | ((contract: ContractType) => string);
}

export interface ContractRecipientData extends Contract {
  cooperatingAgentInvited?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export enum RecipientType {
  Buyer = 'Buyer',
  CoBuyer = 'Co-Buyer',
  Seller = 'Seller',
  CoSeller = 'Co-Seller',
  InitiatingAgent = 'Initiating Agent',
  Other = 'Other',
  Myself = 'Myself',
  CooperatingAgent = 'Cooperating Agent',
  CooperatingAgentInvited = 'Cooperating Agent Invited',
  ClosingAgent = 'Closing Agent',
}

type RecipientMapper = (recipient: Recipient) => Recipient;
type ContractType = Contract | any;
type TypeMapper = (mapping: ContractModelTypeMapping) => Recipient;

class RecipientsGenerator {
  existing: Recipient[];
  userEmail: string;
  userFullName: string;

  static CONTRACT_MODEL_TYPE_MAPPINGS: { [key: number]: ContractModelTypeMapping[] } = {
    [ContractModelVariant.API]: [
      {
        type: RecipientType.Buyer,
        emailGetter: 'Buyer.email',
        nameGetter: 'Buyer.name',
      },
      {
        type: RecipientType.CoBuyer,
        emailGetter: 'CoBuyer.email',
        nameGetter: 'CoBuyer.name',
      },
      {
        type: RecipientType.Seller,
        emailGetter: 'Seller.email',
        nameGetter: 'Seller.name',
      },
      {
        type: RecipientType.CoSeller,
        emailGetter: 'CoSeller.email',
        nameGetter: 'CoSeller.name',
      },
      {
        type: RecipientType.InitiatingAgent,
        emailGetter: 'InitiatingAgent.email',
        nameGetter: 'InitiatingAgent.name',
      },
      {
        type: RecipientType.ClosingAgent,
        emailGetter: 'ClosingAgent.email',
        nameGetter: 'ClosingAgent.name',
      },
      {
        type: RecipientType.CooperatingAgent,
        emailGetter: 'CooperatingAgent.email',
        nameGetter: 'CooperatingAgent.name',
      },
      {
        type: RecipientType.CooperatingAgentInvited,
        emailGetter: 'CooperatingAgentInvited.email',
        nameGetter: 'CooperatingAgentInvited.name',
      },
    ],
    [ContractModelVariant.App]: [
      {
        type: RecipientType.Buyer,
        emailGetter: 'buyerInfo.email',
        nameGetter: (contract) =>
          `${contract.buyerInfo.firstName || ''} ${contract.buyerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.CoBuyer,
        emailGetter: 'buyerInfo.coCustomerEmail',
        nameGetter: (contract) =>
          `${contract.buyerInfo.coCustomerFirstName || ''} ${contract.buyerInfo.coCustomerLastName || ''}`.trim(),
      },
      {
        type: RecipientType.Seller,
        emailGetter: 'sellerInfo.email',
        nameGetter: (contract) =>
          `${contract.sellerInfo.firstName || ''} ${contract.sellerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.CoSeller,
        emailGetter: 'sellerInfo.coCustomerEmail',
        nameGetter: (contract) =>
          `${contract.sellerInfo.coCustomerFirstName || ''} ${contract.sellerInfo.coCustomerLastName || ''}`.trim(),
      },
      {
        type: RecipientType.InitiatingAgent,
        emailGetter: 'agentInfo.AgentEmail',
        nameGetter: 'agentInfo.initiatingAgent',
      },
      {
        type: RecipientType.ClosingAgent,
        emailGetter: 'closingInfo.AgentEmail',
        nameGetter: (contract) =>
          `${contract.closingInfo.userDetails.firstName || ''} ${contract.closingInfo.userDetails.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.CooperatingAgent,
        emailGetter: (contract) =>
          RecipientsGenerator.getCooperatingAgentInfoForAppContract(contract).email,
        nameGetter: (contract) =>
          RecipientsGenerator.getCooperatingAgentInfoForAppContract(contract).name,
      },
    ],
    [ContractModelVariant.NewOrderContextData]: [
      {
        type: RecipientType.Buyer,
        emailGetter: 'buyerInfo.emailAddress',
        nameGetter: (contract) =>
          `${contract.buyerInfo.firstName || ''} ${contract.buyerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.CoBuyer,
        emailGetter: 'coBuyerInfo.emailAddress',
        nameGetter: (contract) =>
          `${contract.coBuyerInfo.firstName || ''} ${contract.coBuyerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.Seller,
        emailGetter: 'sellerInfo.emailAddress',
        nameGetter: (contract) =>
          `${contract.sellerInfo.firstName || ''} ${contract.sellerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.CoSeller,
        emailGetter: 'coSellerInfo.emailAddress',
        nameGetter: (contract) =>
          `${contract.coSellerInfo.firstName || ''} ${contract.coSellerInfo.lastName || ''}`.trim(),
      },
      {
        type: RecipientType.InitiatingAgent,
        emailGetter: 'initiatingAgent.agentEmail',
        nameGetter: 'initiatingAgent.agentName',
      },
      {
        type: RecipientType.ClosingAgent,
        emailGetter: 'closingInfo.closingAgent.agentEmail',
        nameGetter: 'closingInfo.closingAgent.agentName',
      },
      {
        type: RecipientType.CooperatingAgent,
        emailGetter: 'cooperatingAgent.agentEmail',
        nameGetter: 'cooperatingAgent.agentName',
      },
    ],
  };

  static CONFIRMATION_DEFAULTS: RecipientType[] = [
    RecipientType.Myself,
    RecipientType.InitiatingAgent,
    RecipientType.CooperatingAgent,
    RecipientType.CooperatingAgentInvited,
  ];

  static INVOICE_DEFAULTS: RecipientType[] = [
    ...RecipientsGenerator.CONFIRMATION_DEFAULTS,
    RecipientType.ClosingAgent,
  ];

  static RENEWAL_DEFAULTS: RecipientType[] = [
    RecipientType.Buyer,
    RecipientType.CoBuyer,
    RecipientType.InitiatingAgent,
    RecipientType.Myself,
  ];

  constructor(existing, userEmail, userFullName) {
    this.existing = existing || [];
    this.userEmail = userEmail;
    this.userFullName = userFullName;
  }

  fromAPIContract = (contract: ContractRecipientData): Recipient[] => {
    return compose(
      this.withExistingSelections,
      this.withDefaults,
      this.withOthers,
      this.withSelf,
      this.getRawAPIContractRecipients,
    )(contract);
  };

  fromAppContract = (contract: Contract): Recipient[] => {
    return compose(
      this.withExistingSelections,
      this.withDefaults,
      this.withOthers,
      this.withSelf,
      this.getRawAppContractRecipients,
    )(contract);
  };

  fromNewOrderContext = (data: NewOrderContextData): Recipient[] => {
    return compose(
      this.withExistingSelections,
      this.withDefaults,
      this.withOthers,
      this.withSelf,
      this.getRawNewOrderRecipients,
    )(data);
  };

  private generateRecipient = (from: ContractType): TypeMapper => {
    return ({ type, emailGetter, nameGetter }): Recipient => {
      const email = typeof emailGetter === 'function' ? emailGetter(from) : get(from, emailGetter);
      const name = typeof nameGetter === 'function' ? nameGetter(from) : get(from, nameGetter);

      return { name, email, type, selectedTypes: [] };
    };
  };

  private build = (from: Contract | any, type: ContractModelVariant): Recipient[] => {
    const getters = RecipientsGenerator.CONTRACT_MODEL_TYPE_MAPPINGS[type];

    const recipients = map(getters, this.generateRecipient(from));

    return recipients.filter((x) => !isEmpty(x.email));
  };

  private getRawAPIContractRecipients = (contract: Contract): Recipient[] => {
    const from = getContractRecipientsForNotifications(contract, null);
    const recipients = this.build(from, ContractModelVariant.API);

    return recipients;
  };

  private getRawAppContractRecipients = (contract): Recipient[] => {
    const recipients = this.build(contract, ContractModelVariant.App);

    return recipients;
  };

  private getRawNewOrderRecipients = (data): Recipient[] => {
    const recipients = this.build(data, ContractModelVariant.NewOrderContextData);
    return recipients;
  };

  private mergeExisting = (target: Recipient): Recipient => {
    const existingRecipient = this.existing.find((f) => f.type === target.type);

    if (existingRecipient) target.selectedTypes = existingRecipient.selectedTypes;

    return target;
  };

  private withExistingSelections = (recipients: Recipient[]): Recipient[] => {
    return map(recipients, this.mergeExisting);
  };

  private withOthers = (recipients: Recipient[]): Recipient[] => {
    const others = this.existing.filter((x) => x.type === RecipientType.Other);

    if (!isEmpty(others)) return [...recipients, ...others];

    return [...recipients, { name: '', email: '', type: RecipientType.Other, selectedTypes: [] }];
  };

  private withSelf = (recipients: Recipient[]): Recipient[] => {
    return [
      ...recipients,
      {
        name: this.userFullName,
        email: this.userEmail,
        type: RecipientType.Myself,
        selectedTypes: [],
      },
    ];
  };

  private addDefaultsFor = (
    document: DocumentType,
    targetTypes: RecipientType[],
  ): RecipientMapper => {
    return (r: Recipient): Recipient => {
      if (targetTypes.includes(r.type)) {
        r.selectedTypes = [...r.selectedTypes, document];
      }

      return r;
    };
  };

  private withInvoiceDefaults = (recipients: Recipient[]): Recipient[] => {
    return map(recipients, this.addDefaultsFor(DocumentType.Invoice, []));
  };

  private withConfirmationDefaults = (recipients: Recipient[]): Recipient[] => {
    return map(
      recipients,
      this.addDefaultsFor(
        DocumentType.Confirmation,
        RecipientsGenerator.CONFIRMATION_DEFAULTS, // new
      ),
    );
  };

  private withRenewalDefaults = (recipients: Recipient[]): Recipient[] => {
    return map(
      recipients,
      this.addDefaultsFor(DocumentType.Renewal, RecipientsGenerator.RENEWAL_DEFAULTS),
    );
  };

  private withDefaults = (recipients: Recipient[]): Recipient[] => {
    return compose(this.withConfirmationDefaults, this.withInvoiceDefaults)(recipients);
  };

  private static getCooperatingAgentInfoForAppContract = (contract): any => {
    const sellerName = `${contract.sellerInfo?.firstName} ${contract.sellerInfo?.lastName}`;
    const buyerName = `${contract.buyerInfo?.firstName} ${contract.buyerInfo?.lastName}`;
    const sellerEmail = contract.sellerInfo?.email;
    const buyerEmail = contract.buyerInfo?.email;

    if (contract.agentInfo?.representing === 'Buyer' && sellerEmail) {
      return { name: sellerName, email: sellerEmail };
    }
    if (contract.agentInfo?.representing === 'Seller' && buyerEmail) {
      return { name: buyerName, email: buyerEmail };
    }

    return { name: '', email: '' };
  };
}

export default RecipientsGenerator;
