export interface OfficeSearchRequest {
  type?: string;
  brand?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
}

export enum OfficeTypeCode {
  RealEstate = 'RE',
  ClosingCompany = 'CC',
}

export interface OfficeList {
  offices: Office[];
}

export interface Office {
  id: string;
  name: string;
  type: OfficeTypeCode;
  brand: string;
  active: boolean | string; // should be boolean, but using string
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  phones: {
    fax: string;
    office: string;
  };
  compositeSalesRank: string;
  franchiseCode: string;
  territoryCode: string;
  warrantyLinkEligible: boolean;
  warrantyLinkStatus: string; // TODO: circle back and refine to use enum
  email: string;
  homeOfficeId?: string;
  taxIndicator?: string;
}
